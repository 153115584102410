/** @format */

import React from 'react'

import { Spin } from 'antd'

import { LoadingOutlined } from '@ant-design/icons'

import './style.scss'

export default function LoadingPharmacies() {
	return (
		<Spin
			indicator={
				<LoadingOutlined className='ph-electronic-pharmacy-product-list-loader-container-image' />
			}
			className='ph-electronic-pharmacy-product-list-loader-container'
		/>
	)
}
