/** @format */

import axios from 'axios'

import Notify from '../../../Notify'

import { ENV_COMMON, ENV_COUNTRY } from '../../Variables/Enviroment'

export const AddressSetLocation = async (item, alias) => {
	let returnResponse
	let splitAddress
	await axios({
		method: 'GET',
		url: `${ENV_COMMON}/location/geocoding/${ENV_COUNTRY}`,
		params: {
			q: `${item}`,
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				if (alias) {
					let newAdress
					newAdress = {
						location: {
							formatted_address: response.data.data[0].address_complete,
							geometry: {
								location: {
									lat: response.data.data[0].lat,
									lng: response.data.data[0].lng,
								},
							},
						},
					}
					returnResponse = newAdress
				} else {
					splitAddress = response.data.data[0].address_complete.split(',')
					let geometry = {
						lat: response.data.data[0].lat,
						lng: response.data.data[0].lng,
						address: response.data.data[0].address_complete,
						street: splitAddress[0],
						commune: splitAddress[1],
						region: splitAddress[2],
					}
					localStorage.setItem('locationStore', JSON.stringify(geometry))
					returnResponse = JSON.parse(localStorage.getItem('locationStore'))
				}
			} else {
				Notify({
					title: `Error ${response.data.statusCode}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `${response.data.message}`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				})
			}
		})
		.catch(() => {
			Notify({
				title: `Error`,
				colorTitle: 'ph-main-notification-error-title',
				description: 'Problemas con el servicio: Search Google Geocoding',
				time: 3,
				image: 'error',
				placement: 'topRight',
			})
		})
	return returnResponse
}
