/** @format */
const isAlphabetic = (str) => /^[a-zA-Z]+$/.test(str);

export const VALIDATION = {
	repeat_password: [
		{
			required: true,
			message: 'Este campo es requerido de forma obligatoria',
		},
		(formInstance) => {
			return {
				validator: (_, value) => {
					const newPassword = formInstance.getFieldValue('new_password');
					if (newPassword && value !== newPassword) {
						return Promise.reject(new Error('Las contraseñas no coinciden'));
					}
					return Promise.resolve();
				},
			};
		},
	],
	password: [
		{
			required: true,
			message: 'Ingresa tu contraseña',
		},
		{
			validator: (_, value) => {
				if (value && value.length >= 4) {
					return Promise.resolve();
				}
				return Promise.reject(new Error('Mínimo 4 carácteres!'));
			},
		},
	],
	repeat_email: [
		{
			required: true,
			message: 'Ingresa tu correo electrónico',
		},
		(formInstance) => {
			return {
				validator: (_, value) => {
					const repeatEmail = formInstance.getFieldValue('email');
					if (repeatEmail && value !== repeatEmail) {
						return Promise.reject(new Error('Los correos no coinciden'));
					}
					return Promise.resolve();
				},
			};
		},
	],
	min_character: [
		{
			validator: (_, value) => {
				if (value && value.length >= 3) {
					return Promise.resolve();
				}
				return Promise.reject(new Error('Mínimo 3 carácteres!'));
			},
		},
	],

	first_name: [
		{
			required: true,
			message: 'Ingresa tu nombre',
		},

		{
			validator: (_, value) => {
				if (value && value.length >= 3) {
					return Promise.resolve();
				}
				return Promise.reject(new Error('Mínimo 3 carácteres!'));
			},
		},
	],
	last_name: [
		{
			required: true,
			message: 'Ingresa tu apellido',
		},
		{
			validator: (_, value) => {
				if (value && value.length >= 3) {
					return Promise.resolve();
				}
				return Promise.reject(new Error('Mínimo 3 carácteres!'));
			},
		},
	],
	termsAndConditions: [
		{
			required: true,
			message: 'Acepta las Condiciones para continuar',
		},
		({ getFieldValue }) => ({
			validator(_, value) {
				const isChecked = getFieldValue('termsAndConditions');
				if (!isChecked) {
					return Promise.reject(new Error('Acepta las Condiciones para continuar'));
				}
				return Promise.resolve();
			},
		}),
	],
	alphabetic_name: [
		{
			required: true,
			message: 'Ingresa tu nombre',
		},
		{
			validator: (_, value) => {
				if (value && isAlphabetic(value) && value.length >= 3) {
					return Promise.resolve();
				}
				return Promise.reject(new Error('Mínimo 3 carácteres'));
			},
		},
	],
	alphabetic_last_name: [
		{
			required: true,
			message: 'Ingresa tu apellido',
		},
		{
			validator: (_, value) => {
				if (value && isAlphabetic(value) && value.length >= 3) {
					return Promise.resolve();
				}
				return Promise.reject(new Error('Mínimo 3 carácteres'));
			},
		},
	],
	required_length: [
		{
			required: true,
			message: 'Este campo es requerido de forma obligatoria',
		},
		({ getFieldValue }) => ({
			validator(_, value) {
				if (value && value.length >= 4) {
					return Promise.resolve();
				}
				return Promise.reject(new Error('El campo debe contener al menos 6 caracteres'));
			},
		}),
	],
	required: [
		{
			required: true,
			message: 'Este campo es requerido de forma obligatoria',
		},
	],
	phone: [
		{
			required: true,
			message: 'Este campo es requerido de forma obligatoria',
		},
		(formInstance) => {
			return {
				validator: (rule, value) => {
					if (!value) return Promise.reject(`Foarmato no válido para`);

					let _phone = value.replaceAll(' ', '');
					_phone = _phone.replaceAll('-', '');
					_phone = _phone.replaceAll('+', '');
					if (value.test(_phone) === true) {
						return Promise.resolve();
					}

					return Promise.reject(`Foarmato no válido para`);
				},
			};
		},
	],
	phone_number: [
		{
			required: true,
			message: 'Ingresa tu número telefónico',
		},
		{
			validator: (_, value) => {
				const regex = /^[0-9]+$/;
				if (value && regex.test(value) && value.length === 9) {
					return Promise.resolve();
				}
				return Promise.reject(new Error('El número incompleto'));
			},
		},
	],
	phone_number_edition: [
		{
			validator: (_, value) => {
				const regex = /^[0-9]+$/;
				if (value && regex.test(value) && value.length === 9) {
					return Promise.resolve();
				}
				return Promise.reject(new Error('El número debe contener 9  dígitos y solo pueden ser números'));
			},
		},
	],

	message: [
		{
			required: true,
			message: 'Ingresa el mensaje',
		},
		{
			validator: (_, value) => {
				if (value && value.length >= 20 && value.length <= 1500) {
					return Promise.resolve();
				}

				return Promise.reject(new Error('El mensaje ha de tener un mínimo de 20 y un máximo de 1500 caracteres'));
			},
		},
	],
	trx_id: [
		{
			validator: (_, value) => {
				if (!value) {
					return Promise.resolve();
				}

				const numericValue = Number(value);
				if (Number.isFinite(numericValue) && !isNaN(numericValue)) {
					return Promise.resolve();
				}

				return Promise.reject(new Error('La solicitud debe ser numérica'));
			},
		},
	],

	geoloc_lat: [
		{
			required: true,
			message: 'Ingrese una dirección',
		},
		{
			validator: (rule, value) => {
				if (value > -90 && value < 90) {
					return Promise.resolve();
				}
				return Promise.reject(`Latitud debe estar entre -90 y 90`);
			},
		},
	],
	geoloc_lng: [
		{
			required: true,
			message: 'Ingrese una dirección',
		},
		{
			validator: (rule, value) => {
				if (value > -180 && value < 180) {
					return Promise.resolve();
				}
				return Promise.reject(`Longitud debe estar entre -180 y 180`);
			},
		},
	],
	rut: [
		{
			required: true,
			message: 'Ingresa tu RUT',
		},
		{
			validator: (_, value) => {
				const regex = /^[0-9]+$/;
				if (value && regex.test(value) && value.length === 9) {
					return Promise.resolve();
				}
				return Promise.reject(new Error('Por favor ingresa un RUT válido de 9 dígitos. Si tu RUT es menor a 10 millones agrega un 0 al inicio.'));
			},
		},
	],
	rut_edition: [
		{
			validator: (_, value) => {
				const regex = /^[0-9]+$/;
				if (value && regex.test(value) && value.length === 9) {
					return Promise.resolve();
				}
				return Promise.reject(new Error('Por favor ingresa un RUT válido de 9 dígitos. Si tu RUT es menor a 10 millones agrega un 0 al inicio.'));
			},
		},
	],

	email: [
		{
			required: true,
			message: 'Ingresa tu correo electrónico',
		},
		{
			type: 'email',
			message: 'Ingresa un correo válido',
		},
	],
	email_format: [
		{
			type: 'email',
			message: 'Ingresa un correo válido',
		},
	],

	date: [
		{
			validator: (_, value) => {
				const regex = /^\d{2}\/\d{2}\/\d{4}$/;

				if (!value || !regex.test(value)) {
					return Promise.reject(new Error('Por favor ingresa una fecha con el formato dd/mm/yyyy'));
				}

				const [day, month, year] = value.split('/').map(Number);

				if (day < 1 || day > 31) {
					return Promise.reject(new Error('El día debe estar entre 01 y 31'));
				}

				if (month < 1 || month > 12) {
					return Promise.reject(new Error('El mes debe estar entre 01 y 12'));
				}

				if (year < 1925) {
					return Promise.reject(new Error('El año debe ser mayor a 1925'));
				}

				const eighteenYearsAgo = new Date();
				eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
				const selectedDate = new Date(year, month - 1, day);

				if (selectedDate > eighteenYearsAgo) {
					return Promise.reject(new Error('Debes ser mayor de 18 años'));
				}

				return Promise.resolve();
			},
		},
	],
	number: [
		{
			required: true,
			message: 'Este campo es requerido de forma obligatoria',
		},
		{
			validator: (_, value) => {
				if (!isNaN(value)) {
					return Promise.resolve();
				}
				return Promise.reject(new Error('Por favor ingresa un número válido'));
			},
		},
	],
};
