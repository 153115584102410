/** @format */

import React, { useState, useEffect } from 'react';

import Images from '../../../../../../components/Commons/Images';

import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Modal from 'antd/lib/modal';

import { ENV_COUNTRY, ENV_IMG } from '../../../../../../components/Commons/Hooks/Variables/Enviroment';

import './style.scss';

import translates from './translates.json';

export default function RecipeImage(props) {
	const [isImage, setImage] = useState(true);
	const [isImagePreview, setImagePreview] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const handleAddProductImage = (item) => {
		var file = item;
		var allowedExtensions = /(.pdf)$/i;
		if (allowedExtensions.exec(file)) {
			setImage(false);
			return false;
		}
	};
	const handleShowImageRecipe = (item) => {
		setIsModalVisible(true);
		setImagePreview(item);
	};
	useEffect(() => {
		handleAddProductImage(props.item.recipe);
	}, [props.item.recipe]);
	return (
		<>
			<div className="ph-electronic-recipe-product-main-container">
				{isImage ? (
					<div className="ph-electronic-recipe-product-title-main-container" onClick={() => handleShowImageRecipe(props.item.recipe)}>
						<Row>
							<Col span={24}>
								<div className="ph-electronic-recipe-product-title-container">
									<Images
										classContent={'ph-electronic-recipe-product-image-container'}
										classImage={'ph-electronic-recipe-product-image'}
										default={`${ENV_IMG}/react-checkout/electronic-prescription/receta-medica-icon.png`}
										title={'Electronic Prescription Image'}
									/>
									<h4 className="ph-electronic-recipe-product-title">Receta Médica</h4>
								</div>
							</Col>
							<Col xs={24} sm={24} md={18} lg={18} xl={18} className="ph-electronic-recipe-product-preview-title-main-container">
								<h3 className="ph-electronic-recipe-product-description">Has Click en el recuadro para una vista previa</h3>
							</Col>
							<Col xs={24} sm={24} md={6} lg={6} xl={6} className="ph-electronic-recipe-product-preview-image-main-container">
								<Images
									classContent={'ph-electronic-recipe-product-preview-image-container'}
									classImage={'ph-electronic-recipe-product-preview-image'}
									default={props.item.recipe}
									title={props.item.editable_name}
								/>
							</Col>
						</Row>
					</div>
				) : (
					<>
						<a href={props.item.recipe} rel="noopener noreferrer" target="_blank">
							<Row>
								<Col span={24}>
									<div className="ph-electronic-recipe-product-title-container">
										<Images
											classContent={'ph-electronic-recipe-product-image-container'}
											classImage={'ph-electronic-recipe-product-image'}
											webp={`${ENV_IMG}/react-checkout/electronic-prescription/receta-medica-icon.webp`}
											jp2={`${ENV_IMG}/react-checkout/electronic-prescription/receta-medica-icon.jp2`}
											jxr={`${ENV_IMG}/react-checkout/electronic-prescription/receta-medica-icon.jxr`}
											default={`${ENV_IMG}/react-checkout/electronic-prescription/receta-medica-icon.png`}
											title={'Electronic Prescription Image'}
										/>
										<h4 className="ph-electronic-recipe-product-title">{translates[ENV_COUNTRY]} Medica Electrónica</h4>
									</div>
								</Col>
								<Col span={24}>
									<h3 className="ph-electronic-recipe-product-description">Has Click en el recuadro para ver el Recipe</h3>
								</Col>
							</Row>
						</a>
					</>
				)}
			</div>
			<Modal
				wrapClassName="ph-electronic-recipe-product-modal-container"
				title="Vista Previa de la Receta"
				visible={isModalVisible}
				onOk={() => setIsModalVisible(false)}
				onCancel={() => setIsModalVisible(false)}
				okText="Confirmar"
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
			>
				{isImagePreview && (
					<Images
						classContent={'ph-electronic-recipe-product-image-container'}
						classImage={'ph-electronic-recipe-product-modal-image'}
						default={isImagePreview}
						title={'Electronic Prescription Image'}
					/>
				)}
			</Modal>
		</>
	);
}
