/** @format */

import React from 'react';
import { Modal, Button } from 'antd';

import './style.scss';

export default function ModalLogOut(props) {
	const handleLogOut = async (event) => {
		localStorage.removeItem('userToken');
		localStorage.removeItem('userFavorites');
		localStorage.removeItem('userMainInfo');
		localStorage.removeItem('userFavorites');
		localStorage.removeItem('cart');
		localStorage.removeItem('userEmail')
		props.redirect.push('/auth/login');
	};

	return (
		<>
			<Modal
				wrapClassName="ph-profile-modal-container"
				centered
				title="Cerrar Sesión"
				visible={props.visible}
				onCancel={() => props.showModalLogOut(false)}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
				okText=""
				cancelText=""
				footer={false}
			>
				<p className="ph-profile-modal-title">¿Estas seguro que quieres Cerrar Sesión?</p>
				<p className="ph-profile-modal-description">¡No olvides visitarnos pronto!</p>
				<Button className="ph-profile-modal-button" onClick={() => handleLogOut()}>
					Aceptar
				</Button>
			</Modal>
		</>
	);
}
