/** @format */

import React from 'react'

import { Col } from 'antd'

import InputField from '../../../../../../../components/Commons/Input'

import { ENV_COUNTRY } from '../../../../../../../components/Commons/Hooks/Variables/Enviroment'

import './style.scss'

export default function AditionalForm() {
	if (ENV_COUNTRY === 'MX') {
		return (
			<>
				<Col
					xs={24}
					sm={24}
					md={12}
					lg={12}
					xl={12}
					className='ph-withdrawal-aditional-info-form-container'>
					<InputField
						className={'ph-withdrawal-aditional-info-field-input'}
						inputName={'zip_code_del'}
						inputNameLabel={'Código Postal'}
						inputNameRule={true}
						inputNameMessage={'Debe colocar un código postal'}
						inputNameType={'text'}
						inputNameRules={'rulesRequired'}
					/>
				</Col>
				<Col
					xs={24}
					sm={24}
					md={12}
					lg={12}
					xl={12}
					className='ph-withdrawal-aditional-info-form-container'>
					<InputField
						className={'ph-withdrawal-aditional-info-field-input'}
						inputName={'city_del'}
						inputNameLabel={'Ciudad'}
						inputNameRule={true}
						inputNameMessage={'Debe colocar una Ciudad'}
						inputNameType={'text'}
						inputNameRules={'rulesRequired'}
					/>
				</Col>
				<Col
					xs={24}
					sm={24}
					md={12}
					lg={12}
					xl={12}
					className='ph-withdrawal-aditional-info-form-container'>
					<InputField
						className={'ph-withdrawal-aditional-info-field-input'}
						inputName={'colony_del'}
						inputNameLabel={'Colonia'}
						inputNameRule={true}
						inputNameMessage={'Debe colocar una Colonia'}
						inputNameType={'text'}
						inputNameRules={'rulesRequired'}
					/>
				</Col>
				<Col
					xs={24}
					sm={24}
					md={12}
					lg={12}
					xl={12}
					className='ph-withdrawal-aditional-info-form-container'>
					<InputField
						className={'ph-withdrawal-aditional-info-field-input'}
						inputName={'province_del'}
						inputNameLabel={'Provincia'}
						inputNameRule={true}
						inputNameMessage={'Debe colocar una Provincia'}
						inputNameType={'text'}
						inputNameRules={'rulesRequired'}
					/>
				</Col>
				<Col
					xs={24}
					sm={24}
					md={12}
					lg={12}
					xl={12}
					className='ph-withdrawal-aditional-info-form-container'>
					<InputField
						className={'ph-withdrawal-aditional-info-field-input'}
						inputName={'between_street_del'}
						inputNameLabel={'Calle referencial'}
						inputNameRule={true}
						inputNameType={'text'}
					/>
				</Col>
			</>
		)
	} else {
		return ''
	}
}
