/** @format */

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import MetaDescription from '../../components/Commons/MetaDescription';
import Loading from '../../components/Commons/Loading';
import Elastic from '../../components/Elastic';
import TimelinePages from '../../components/TimelinePages';
import { HandleFacets } from '../../components/Elastic/functions/HandleFacets';
import { Country } from './country';
import './style.scss';
import GetSidebarBanner from './services';

export default function SearchResult(props) {
	const { t } = useTranslation('global');

	const params = useLocation();
	const [isQuery] = useState(qs.parse(params.search.slice(1)));
	const [isbanners, setBanners] = useState(null);

	useEffect(() => {
		window.scrollTo(0, 0);
		GetSidebarBanner().then((response) => {
			if (response) {
				setTimeout(() => {
					setBanners(response);
				}, 600);
			}
		});
		if (isQuery.voucher) {
			localStorage.setItem('validVoucher', isQuery.voucher);
		}
	}, [isQuery.voucher]);

	const handleFilters = () => {
		const query = props.match.params.query ? props.match.params.query : '';
		const filters = Country.handleFilters(props.match.params);
		const index = Country.handleElasticPrecision(query);
		const sort = Country.handleSort();
		const facets = HandleFacets('result');
		const params = { filters, query, index, facets, sort };
		return params;
	};

	return (
		<>
			<MetaDescription
				title={`${props.match.params.query} - ${t('main.metaTitle')}`}
				name={'description'}
				content={`${props.match.params.query}`}
				canonical={`${props.match.params.query}`}
				robots={true}
			/>
			{isbanners ? (
				<>
					<div className="ph-results-global-container">
						<TimelinePages item={props.match.params} />
					</div>
					<Elastic sidebarBanners={isbanners} params={handleFilters()} showSearchBox={false} />
				</>
			) : (
				<Loading />
			)}
		</>
	);
}
