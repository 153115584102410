/** @format */

import axios from 'axios'

import Notify from '../../../../components/Commons/Notify'

import {
	ENV_QUOTATION,
	ENV_COUNTRY,
} from '../../../../components/Commons/Hooks/Variables/Enviroment'

export async function serviceDigital(item) {
	return await axios({
		method: 'POST',
		url: `${ENV_QUOTATION}/${ENV_COUNTRY}`,
		data: {
			pharmacy: 'pharol',
			quotation_type: 'digital-recipe',
			recipe: item.recipe,
			user_info: {
				first_name: item.first_name,
				last_name: item.last_name,
				email: item.email,
				rut: item.rut,
				phone: item.phone.replace(/[+()/\s/]/g, ''),
				location: {
					lat: item.lat,
					lng: item.lng,
					street: item.street,
					address: item.address,
					commune: item.commune,
					region: item.region,
				},
			},
			item_products: [],
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				return response.data.data
			} else {
				Notify({
					title: `Error`,
					colorTitle: 'ph-main-notification-error-title',
					description: `${response.data.message}...`,
					time: 5,
					image: 'error',
					placement: 'topRight',
				})
			}
		})
		.catch(() => {
			Notify({
				title: `Error Servicio`,
				colorTitle: 'ph-main-notification-error-title',
				description: 'Problemas con el servicio - RME',
				time: 5,
				image: 'error',
				placement: 'topRight',
			})
		})
}
