/** @format */

import { setGlobal } from 'reactn';
import { ProductAdapter } from '../../../../_config/adapters/google-tag-manager/product.adapter';
import { Events } from '../../../../_config/adapters/google-tag-manager/events.constant';
import { amountProductsCart } from '../../../../components/Commons/Hooks/Functions/AmountProductsCart';

export default function BagDeleteProduct() {
	const handleDeleteProduct = (cart, product) => {
		const index = cart.findIndex((search) => {
			if (search.objectID) return search.objectID === product.objectID;
			else return search.id === product.id;
		});
		if (index !== -1) {
			cart.forEach((item) => {
				if (item.pharmacy === cart[index].pharmacy) delete item.type_retirement;
			});
			cart.splice(index, 1);
			localStorage.setItem('cart', JSON.stringify(cart));
			ProductAdapter({ event: Events.remove_from_cart, product: product, quantity: product.quantity });
			setGlobal(() => ({ carQuantity: amountProductsCart() }));
			return cart;
		}
		return null;
	};

	return {
		handleDeleteProduct,
	};
}
