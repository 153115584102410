/** @format */

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import MetaDescription from '../../../../components/Commons/MetaDescription';

import TitleComplaint from '../../components/TitleComplaint';
import Card from './components/Card';

import { serviceGetClaim } from './services';

import './style.scss';

export default function ComplaintBookSearch(props) {
	const { t } = useTranslation('global');
	const [claim, setClaim] = useState({});

	const handleSearchClaim = (item) => {
		if (item.trim().length > 0) {
			serviceGetClaim({ claim_id: item }).then((res) => setClaim(res));
		}
	};

	return (
		<>
			<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			<div className="ph-complaint-book-container">
				<div className="ph-complaint-book-inner-container">
					<TitleComplaint
						title={'Reclamos y sugerencias - Búsqueda'}
						description={`Volver - ${props.match.params.pharmacy}`}
						image={`react-pharmacy/complaint-book/claim-icon`}
						link={`/complaint-book/menu/${props.match.params.pharmacy}`}
					/>
					<Input.Search className="ph-complaint-book-search" placeholder="Inserte ID del reclamo" onSearch={handleSearchClaim} enterButton />
					{'claim_id' in claim && <Card {...claim} />}
				</div>
			</div>
		</>
	);
}
