/** @format */

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalConsumer } from '../../../../../../components/Commons/Context/Global';
import { BagConsumer } from '../../../../../../components/Commons/Context/Bag';

import { Form, Radio, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import Images from '../../../../../../components/Commons/Images';
import translates from '../../../../../../components/Commons/translates';

import { ENV_IMG, ENV_COUNTRY } from '../../../../../../components/Commons/Hooks/Variables/Enviroment';

import { BagValidateDelivery } from '../../../../functions/BagValidateDelivery';

import BagTotal from '../../../../functions/BagTotal';

import BagRecipeFilter from '../../../../functions/BagRecipeFilter';

import './style.scss';

export default function BagTypeDelivery(props) {
	const { t } = useTranslation('global')
	const { setBag } = GlobalConsumer();
	const { isBagData, isBagRetirement, isBagRetirementPharmacies, isBagDeliveryPharmacies, setBagData, setBagRetirement, setBagTotalCart, setBagRecipe, setBagFilterProducts } = BagConsumer();

	const { handleCheckRetirement } = BagValidateDelivery();
	const { handleBagTotal } = BagTotal();

	const [deliveryForm] = Form.useForm();

	const handleSetDeliveryStatus = async (item) => {
		const bag = JSON.parse(localStorage.getItem('cart'));
		bag.forEach((element) => {
			if (props.item.name === element.pharmacy) element.type_retirement = item;
		});
		const locals_group = isBagData.locals_group.map((element) => {
			if (props.item.name === element.name) {
				if (item === 'domicilio') {
					return (element = isBagDeliveryPharmacies[props.item.name]);
				} else if (item === 'farmacia') {
					return (element = isBagRetirementPharmacies[props.item.name]);
				}
			}
			return element;
		});
		const bagData = { ...isBagData, locals_group: locals_group };
		setBagRetirement(handleCheckRetirement(bag));
		setBagData({ ...bagData });
		setBagTotalCart(handleBagTotal(bagData));
		const filter = BagRecipeFilter(bag);
		setBagRecipe(filter.some((item) => !item.recipe));
		setBagFilterProducts(filter);
		localStorage.setItem('cart', JSON.stringify([...bag]));
		setBag([...bag]);
	};

	useEffect(() => {
		deliveryForm.setFieldsValue({
			[`type_retirement_${props.item.name}`]: isBagRetirement && isBagRetirement[props.item.name] && isBagRetirement[props.item.name].type_retirement,
		});
	}, [props.item.name, isBagRetirement, deliveryForm]);

	return (
		<Form className="ph-bag-type-delivery-radio-button-form-container" form={deliveryForm}>
			<Form.Item name={`type_retirement_${props.item.name}`}>
				<Radio.Group className="ph-bag-type-delivery-radio-button-container">
					<Radio
						disabled={Object.keys(isBagDeliveryPharmacies).length === 0 || isBagDeliveryPharmacies[props.item.name] === false || isBagDeliveryPharmacies[props.item.name] === null}
						className={`ph-bag-type-delivery-radio-button-inner-container ${
							isBagDeliveryPharmacies[props.item.name] === false || isBagDeliveryPharmacies[props.item.name] === null
								? 'ph-bag-type-delivery-radio-button-border-disabled'
								: 'ph-bag-type-delivery-radio-button-border-active'
						}`}
						value={'domicilio'}
						onClick={() => handleSetDeliveryStatus('domicilio')}
					>
						<div className="ph-bag-type-delivery-radio-button-select-container">
							<Images
								classContent={'ph-bag-type-delivery-delivery-image-1-container'}
								classImage={'ph-bag-type-delivery-delivery-image-1'}
								webp={`${ENV_IMG}/react-type-delibery/despacho-domicilio.webp`}
								jp2={`${ENV_IMG}/react-type-delibery/despacho-domicilio.jp2`}
								jxr={`${ENV_IMG}/react-type-delibery/despacho-domicilio.jxr`}
								default={`${ENV_IMG}/react-type-delibery/despacho-domicilio.png`}
								title={'despacho a domicilio'}
							/>
							<div className="ph-bag-type-delivery-delivery-title-container">
								<h4 className="ph-bag-type-delivery-delivery-title">{t('cart.delivery')}</h4>
								<h4 className="ph-bag-type-delivery-delivery-subtitle">Domicilio</h4>
							</div>
						</div>
						{isBagDeliveryPharmacies[props.item.name] ? (
							''
						) : isBagDeliveryPharmacies[props.item.name] == null ? (
							<Spin className="ph-bag-type-delivery-radio-loading-button" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
						) : (
							<div className="ph-bag-type-delivery-radio-no-delivery-title"></div>
						)}
					</Radio>
					<Radio
						disabled={Object.keys(isBagRetirementPharmacies).length === 0 || isBagRetirementPharmacies[props.item.name] === false || isBagRetirementPharmacies[props.item.name] === null}
						className={`ph-bag-type-delivery-radio-button-inner-container ${
							isBagRetirementPharmacies[props.item.name] === false || isBagRetirementPharmacies[props.item.name] === null
								? 'ph-bag-type-delivery-radio-button-border-disabled'
								: 'ph-bag-type-delivery-radio-button-border-active'
						}`}
						value={'farmacia'}
						onClick={() => handleSetDeliveryStatus('farmacia')}
					>
						<div className="ph-bag-type-delivery-radio-button-select-container">
							<Images
								classContent={'ph-bag-type-delivery-delivery-image-1-container'}
								classImage={'ph-bag-type-delivery-delivery-image-1'}
								webp={`${ENV_IMG}/react-type-delibery/retiro-farmacia.webp`}
								jp2={`${ENV_IMG}/react-type-delibery/retiro-farmacia.jp2`}
								jxr={`${ENV_IMG}/react-type-delibery/retiro-farmacia.jxr`}
								default={`${ENV_IMG}/react-type-delibery/retiro-farmacia.png`}
								title={`retiro en ${translates.pharmacy[ENV_COUNTRY]}`}
							/>
							<div className="ph-bag-type-delivery-delivery-title-container">
								<h4 className="ph-bag-type-delivery-delivery-title">Retiro</h4>
								<h4 className="ph-bag-type-delivery-delivery-subtitle">Gratis</h4>
							</div>
						</div>
						{isBagRetirementPharmacies[props.item.name] ? (
							''
						) : isBagRetirementPharmacies[props.item.name] == null ? (
							<Spin className="ph-bag-type-delivery-radio-loading-button" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
						) : (
							<div className="ph-bag-type-delivery-radio-no-delivery-title"></div>
						)}
					</Radio>
				</Radio.Group>
			</Form.Item>
		</Form>
	);
}
