/** @format */

import axios from 'axios';

import { setGlobal } from 'reactn';

import Notify from '../../../../../../../../components/Commons/Notify';

import { ENV_PBM } from '../../../../../../../../components/Commons/Hooks/Variables/Enviroment';

const setPBMObject = (item, data) => {
	const cart = JSON.parse(localStorage.getItem('cart'));
	const index = cart.findIndex((element) => {
		if (element.objectID) return element.objectID === item.objectID;
		else return element.id === item.id;
	});
	cart[index].pbm_data = data;
	localStorage.setItem('cart', JSON.stringify(cart));
	setGlobal({ cart: cart });
	Notify({
		title: `¡Aviso!`,
		colorTitle: 'ph-main-notification-success-title',
		description: `Adjudicación PBM exitosa de su producto.`,
		time: 8,
		image: 'warning',
		placement: 'topRight',
	});
	return cart;
};

const servicesPBM = {
	async deletePBMObject(item) {
		const cart = JSON.parse(localStorage.getItem('cart'));
		const index = cart.findIndex((element) => {
			if (element.objectID) return element.objectID === item.objectID;
			else return element.id === item.id;
		});
		delete cart[index].pbm_data;
		localStorage.setItem('cart', JSON.stringify(cart));
		setGlobal({ cart: cart });
		Notify({
			title: `¡Aviso!`,
			colorTitle: 'ph-main-notification-success-title',
			description: `Eliminada adjudicación PBM de su producto.`,
			time: 8,
			image: 'warning',
			placement: 'topRight',
		});
		return cart;
	},
	async checkProductsPBM(item, data) {
		let returnResponse;
		item.forEach((data) => {
			if (data.interface_pbm) {
				if (data.interface_pbm.length > 0) {
					returnResponse = true;
				}
			}
		});
		if (returnResponse && !data) {
			Notify({
				title: `¡Bien!`,
				colorTitle: 'ph-main-notification-success-title',
				description: `En tu bolsa de compras dispones de productos con beneficio de Convenio PBM.`,
				time: 6,
				image: 'warning',
				placement: 'topRight',
			});
		}
		return returnResponse;
	},

	async getFormsFields(item) {
		let returnResponse;
		await axios({
			method: 'GET',
			url: `${ENV_PBM}/agreement/required-fields/${item}`,
		})
			.then((response) => {
				if (response.data.status === 200) {
					returnResponse = response.data.data;
				} else {
					Notify({
						title: `Error en Servicio PBM:`,
						colorTitle: 'ph-main-notification-error-title',
						description: `Consulte con su proveedor de servicios.`,
						time: 3,
						image: 'error',
						placement: 'topRight',
					});
				}
			})
			.catch(() => {
				Notify({
					title: `Problemas de Conexión`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Revisa tu conexión o ponte en contacto con nosotros.`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			});
		return returnResponse;
	},

	async getPBMAgreement(item, data, element) {
		let returnResponse;
		var date = new Date().toISOString().substr(0, 10);
		date = date.split('-').reverse().join('-');
		const cleanRut = (item) => {
			let rutSplitted = item.split('-');
			rutSplitted[1] = rutSplitted[1].toUpperCase();
			rutSplitted = rutSplitted.join('-');
			rutSplitted = rutSplitted.replace(/[-.]/g, '');
			return rutSplitted;
		};
		if (item.BENEF_RUT) {
			item.BENEF_RUT = cleanRut(item.BENEF_RUT);
		}
		if (item.MEDICO_RUT) {
			item.MEDICO_RUT = cleanRut(item.MEDICO_RUT);
		}
		if (item.BENEF_TEL) {
			item.BENEF_TEL = item.BENEF_TEL.replace(/[+()/\s/]/g, '');
		}
		if (item.FECHA_RECETA) {
			item.FECHA_RECETA = item.FECHA_RECETA.replace(/[/]/g, '-');
		}
		await axios({
			method: 'POST',
			url: `${ENV_PBM}/award-query`,
			data: {
				id_agreement: element.convenio,
				date_selected: date,
				code_internal: data.code_internal,
				name: data.name,
				quantity: data.quantity,
				unit_price: data.unit_price,
				pbm_fields: item,
			},
		})
			.then(async (response) => {
				if (response.data.status === 200) {
					if (response.data.data.pbm_data.pbm_award_query.productos) {
						if (response.data.data.pbm_data.pbm_award_query.productos[0].status === 'R') {
							Notify({
								title: `Error: PBM`,
								colorTitle: 'ph-main-notification-error-title',
								description: `${response.data.data.pbm_data.pbm_award_query.productos[0].rechazo}`,
								time: 5,
								image: 'error',
								placement: 'topRight',
							});
						} else if (response.data.data.pbm_data.pbm_award_query.productos[0].status === 'A' || response.data.data.pbm_data.pbm_award_query.productos[0].status === 'C') {
							if (response.data.data.pbm_data.pbm_award_query.productos[0].rechazo) {
								Notify({
									title: `Error: PBM`,
									colorTitle: 'ph-main-notification-error-title',
									description: `${response.data.data.pbm_data.pbm_award_query.productos[0].rechazo}`,
									time: 5,
									image: 'error',
									placement: 'topRight',
								});
							} else {
								response.data.data.pbm_data.pbm_source_input.name = element.name;
								response.data.data.pbm_data.pbm_source_input.discount = element.discount;
								const responsePBM = await setPBMObject(data, response.data.data.pbm_data);
								returnResponse = {
									messajes: response.data.data.pbm_data.pbm_award_query.mensajes,
									cart: responsePBM,
								};
							}
						} else {
							Notify({
								title: `Advertencia: PBM`,
								colorTitle: 'ph-main-notification-error-title',
								description: 'Ha ocurrido una respuesta inesperada, comuníquese con su proveedor de cupones.',
								time: 5,
								image: 'error',
								placement: 'topRight',
							});
						}
					} else {
						Notify({
							title: `Error en Autorizar PBM`,
							colorTitle: 'ph-main-notification-error-title',
							description: 'Consulte con su proveedor de servicios.',
							time: 5,
							image: 'error',
							placement: 'topRight',
						});
					}
				} else {
					Notify({
						title: `Error en Autorizar PBM`,
						colorTitle: 'ph-main-notification-error-title',
						description: 'Consulte con su proveedor de servicios.',
						time: 5,
						image: 'error',
						placement: 'topRight',
					});
				}
			})
			.catch(() => {
				Notify({
					title: `Problemas de Conexión`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Revisa tu conexión o ponte en contacto con nosotros.`,
					time: 5,
					image: 'error',
					placement: 'topRight',
				});
			});
		return returnResponse;
	},
};

export default servicesPBM;
