/** @format */

import axios from 'axios'

import Notify from '../../../../components/Commons/Notify'

import {
	ENV_QUOTATION,
	ENV_COUNTRY,
} from '../../../../components/Commons/Hooks/Variables/Enviroment'

export async function serviceRequestPreorder(item) {
	let returnResponse
	await axios({
		method: 'POST',
		url: `${ENV_QUOTATION}/${ENV_COUNTRY}`,
		data: item,
	})
		.then(function (response) {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data.short_code
			} else if (response.data.statusCode === 400) {
				Notify({
					title: `${response.data.error} - ${response.data.statusCode}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `${response.data.message}`,
					time: 6,
					image: 'error',
					placement: 'topRight',
				})
			} else {
				Notify({
					title: `Error!`,
					colorTitle: 'ph-main-notification-error-title',
					description: 'ha ocurrido un error inesperado, intente más tarde...',
					time: 6,
					image: 'error',
					placement: 'topRight',
				})
			}
		})
		.catch(() => {
			Notify({
				title: `Error Servicio REACT_APP_FUNCTION_QUOTATON`,
				colorTitle: 'ph-main-notification-error-title',
				description: 'Problemas con el servicio, QUOTATION.',
				time: 3,
				image: 'error',
				placement: 'topRight',
			})
		})
	return returnResponse
}
