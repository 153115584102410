/** @format */

import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Col, Row, Layout } from "antd";

import moment from "moment";

import { setGlobal } from "reactn";

import Loading from "../../components/Commons/Loading";
import Spacer from "../../components/Commons/Spacer";

import CardInfo from "./components/CardInfo";
import CardLink from "./components/CardLink";
import ModalLogOut from "./components/ModalLogOut";
import profileDataService from "./service.js";

import { ENV_ROOT_PHARMACY } from "../../components/Commons/Hooks/Variables/Enviroment";

import { GetUserGeneralInfo } from "../../components/Commons/Hooks/Functions/GetUserGeneralInfo";
import { GetFavoritesList } from "../../components/Commons/Favorites/Functions";
import PurchaseHistoryServices from "./pages/purchase-history/services";
import { GetRecipes } from "../../components/Commons/Hooks/Functions/GetRecipes";

import "./style.scss";
import CountryValidator from "../../components/Commons/CountryValidator";

const { Content } = Layout;

export default function Profile() {
  let history = useHistory();

  const [mainUserInfo] = useState(
    JSON.parse(localStorage.getItem("userMainInfo"))
  );
  const [isLocation] = useState(
    JSON.parse(localStorage.getItem("locationStore"))
  );
  const [isEmail] = useState(localStorage.getItem("userEmail"));
  const [isUserToken] = useState(localStorage.getItem("userToken"));
  const [profile, setProfile] = useState(null);
  const [visible, setVisible] = useState(false);
  const [infoCards, setInfoCards] = useState({
    "pharol-usuario-login": null,
    favorites2: null,
    "pharol-bolsa-compras": null,
    "pharol-recipes": null,
    "pharol-location": null,
    "pharol-lock": true,
  });

  const handleShowModalLogOut = (item) => {
    setVisible(item);
  };

  useEffect(() => {
    const userNotifications = JSON.parse(
      localStorage.getItem("userNotifications")
    );
    const date = new Date();
    const formatUTC = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
    const formatDate = moment(formatUTC).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
    const dateNow = moment(formatDate, "YYYY-MM-DD HH:mm:ss");

    let timeDistanceSuggestion;

    if (userNotifications?.dateSuggestion) {
      const dateSuggestionStored = moment(
        userNotifications?.dateSuggestion,
        "YYYY-MM-DD HH:mm:ss"
      );
      timeDistanceSuggestion = dateNow.diff(dateSuggestionStored, "m");
    }
    setTimeout(() => {
      if (isLocation.default && timeDistanceSuggestion >= 10) {
        setGlobal(() => ({ GlobalModalSuggestion: true }));
      }
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    profileDataService().then(async (response) => {
      if (response) {
        setProfile(response);

        const userData = await GetUserGeneralInfo(isEmail);
        setInfoCards((prevState) => ({
          ...prevState,
          "pharol-usuario-login": userData.info,
          "pharol-location": userData?.address?.slice(0, 2),
        }));

        const userFavorites = await GetFavoritesList(isUserToken, 1);
        setInfoCards((prevState) => ({
          ...prevState,
          favorites2: userFavorites?.data?.slice(0, 2),
        }));

        const userPurchases = await PurchaseHistoryServices();
        setInfoCards((prevState) => ({
          ...prevState,
          "pharol-bolsa-compras": userPurchases?.slice(0, 2),
        }));

        const userRecipes = await GetRecipes();
        setInfoCards((prevState) => ({
          ...prevState,
          "pharol-recipes": userRecipes?.data?.slice(0, 2),
        }));
      }
    });
  }, [isEmail, isUserToken]);

  if (!profile) return <Loading />;

  return (
    <>
      <Content className="ph-profile-global-container">
        <div className="ph-profile-inner-container">
          <div className="ph-profile-title-container">
            <div className="ph-profile-title-inner-container">
              <h2 className="ph-profile-main-subtitle">Mi cuenta</h2>
              <h2 className="ph-profile-main-title">
                {mainUserInfo?.first_name} {mainUserInfo?.last_name}{" "}
                <span className="ph-profile-main-title-span">
                  ¡Te damos la bienvenida!
                </span>
              </h2>
            </div>

            <Spacer />
            <h4
              className="ph-profile-main-link"
              onClick={() => handleShowModalLogOut(true)}
            >
              Cerrar sesión
            </h4>
          </div>
          <Row>
            {profile.cards &&
              profile.cards.map((item, i) => (
                <React.Fragment key={i}>
                  {ENV_ROOT_PHARMACY !== "pharol" &&
                  item.title === "Favoritos" ? (
                    ""
                  ) : (
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                      <Link to={item.link}>
                        <CardInfo
                          item={item}
                          infoCards={infoCards}
                          isEmail={isEmail}
                        />
                      </Link>
                    </Col>
                  )}
                </React.Fragment>
              ))}
          </Row>
          <h4
            className="ph-profile-main-link-responsive"
            onClick={() => handleShowModalLogOut(true)}
          >
            Cerrar sesión
          </h4>
        </div>
        <CountryValidator
          countries={[]}
          component={
            <div className="ph-profile-main-container">
              <div className="ph-profile-inner-container">
                <Row>
                  {profile.footers &&
                    profile.footers.map(function (item, i) {
                      return (
                        <Col xs={24} sm={12} md={8} lg={8} xl={8} key={i}>
                          <CardLink title={item.title} links={item.links} />
                        </Col>
                      );
                    })}
                </Row>
                <div className="ph-profile-separator-footer-spacer"></div>
              </div>
            </div>
          }
        />
      </Content>
      <ModalLogOut
        showModalLogOut={handleShowModalLogOut}
        visible={visible}
        redirect={history}
      />
    </>
  );
}
