/** @format */

import React, { Component } from 'react'

import './style.scss'

export default class ProducQuote extends Component {
	render() {
		return (
			<>
				<div
					className={`${
						this.props.classList
							? 'ph-product-list-quote-title-main-container'
							: ''
					} ph-product-quote-title-main-container`}>
					{!this.props.alternatives && (
						<p
							className={`${
								this.props.classList ? 'ph-product-list-quote-description' : ''
							} ph-product-quote-description`}>
							Ver disponibilidad del producto por farmacias.
						</p>
					)}
				</div>
			</>
		)
	}
}
