/** @format */

import React, { Component } from 'react'

import { Col, Row, Timeline, Progress } from 'antd'
import { ClockCircleOutlined, CheckCircleOutlined, BellOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

import Images from '../../../../../../components/Commons/Images'

import TypeRetirementTracking from './components/TypeRetirementTracking'

import './style.scss'

export default class OrderDetailTracking extends Component {
	handleProgressBarTracking = (item) => {
		let stepTrackingActives = 0
		item.forEach((element) => {
			if (element.active === true) {
				stepTrackingActives++
			}
		})
		return (stepTrackingActives * 100) / item.length
	}

	formatDate = (date) =>{
		const dateObj = new Date(date)

		const day = dateObj.getDate()
		const month = dateObj.getMonth() + 1
		const year = dateObj.getFullYear()

		const hours = dateObj.getHours()
		const minutes = dateObj.getMinutes()
		const seconds = dateObj.getSeconds()

		const amPm = hours >= 12 ? 'pm' : 'am'
		const hours12 = hours % 12 || 12

		const fomatedDay = day < 10 ? '0' + day : day;
  		const formatedMonth = month < 10 ? '0' + month : month;

		const formatedDate = `${fomatedDay}/${formatedMonth}/${year}, ${hours12}:${minutes}:${seconds} ${amPm}`;

		return formatedDate
	}

	render() {
		return (
			<>
				<div className='ph-order-detail-tracking-main-container'>
					<Progress
						percent={this.handleProgressBarTracking(this.props.list.process)}
						showInfo={false}
					/>
					<div className='ph-order-detail-tracking-container'>
						<Timeline>
							{this.props.list.process.map((item, i) => {
									return (
										(item.active || (!item.active && this.props.list.status.status !== 8)) &&
										<div key={i}>
											<>
												<Timeline.Item
													color={
														item.active
															? 'ph-order-detail-tracking-container-step-active'
															: 'ph-order-detail-tracking-container-step-disabled'
													}
													dot={
														item.active ? (
															<CheckCircleOutlined />
														) : (
															<ClockCircleOutlined />
														)
													}>
													<div
														className={`${
															item.active
																? 'tracking-status-active'
																: 'tracking-status-disabled'
														} ph-order-detail-tracking-inner-container`}>
														<Row>
															<Col xs={24} sm={24} md={8} lg={8} xl={8}>
																<div className='ph-order-detail-tracking-date-container'>
																	{item.active ? (
																		<h3 className='ph-order-detail-tracking-date-title'>
																			{item.date}
																		</h3>
																	) : (
																		<BellOutlined className='ph-order-detail-tracking-date-icon' />
																	)}
																</div>
															</Col>
															<Col xs={24} sm={24} md={16} lg={16} xl={16}>
																<div>
																	<h3 className='ph-order-detail-tracking-list-inner-title'>
																		{item.description}
																	</h3>
																	<h3 className='ph-order-detail-tracking-list-inner-description'>
																		Estatus: {item.status}
																	</h3>
																	{item.image && (
																		<Images
																			classContent={
																				'ph-order-detail-tracking-list-inner-description-image-container'
																			}
																			classImage={`${
																				!item.active
																					? 'tracking-status-image-disabled'
																					: ''
																			} ph-order-detail-tracking-list-inner-description-image`}
																			webp={item.image.webp}
																			jp2={item.image.jp2}
																			jxr={item.image.jxr}
																			default={item.image.default}
																			alt={item.description}
																			title={item.description}
																		/>
																	)}
																</div>
															</Col>
														</Row>
													</div>
												</Timeline.Item>
											</>
										</div>
									)
								})}
								{this.props.list.status.status === 8 && 
									<>	
									<Timeline.Item
										color='ph-order-detail-tracking-container-step-canceled'
										dot={<ExclamationCircleOutlined />}
									>
										<div
											className={`ph-order-detail-tracking-inner-container`}>
											<Row>
												<Col xs={24} sm={24} md={8} lg={8} xl={8}>
													<div className='ph-order-detail-tracking-date-container'>
														{true ? (
															<h3 className='ph-order-detail-tracking-date-title'>
																{this.formatDate(this.props.list.status.date_process)}
															</h3>
														) : (
															<BellOutlined className='ph-order-detail-tracking-date-icon' />
														)}
													</div>
												</Col>
												<Col xs={24} sm={24} md={16} lg={16} xl={16}>
													<div>
														<h3 className='ph-order-detail-tracking-list-inner-title'>
														{this.props.list.status.description}
														</h3>
														<h3 className='ph-order-detail-tracking-list-inner-description'>
															Estatus: {this.props.list.status.status}
														</h3>
													</div>
												</Col>
											</Row>
										</div>

									</Timeline.Item>
								</>
								}
						</Timeline>
						<TypeRetirementTracking item={this.props.list} />
					</div>
				</div>
			</>
		)
	}
}
