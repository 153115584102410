/** @format */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ENV_COUNTRY } from '../../components/Commons/Hooks/Variables/Enviroment';
import translates from '../../components/Commons/translates';
import Images from '../../components/Commons/Images';
import MetaDescription from '../../components/Commons/MetaDescription';

import './style.scss';
import CountryValidator from '../../components/Commons/CountryValidator';
import { Country } from '../../_config/constants/image-country.constant';

export default function Building(props) {
	const { t } = useTranslation('global');

	return (
		<>
			<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			<section className="ph-building-global-container">
				<section className="ph-building-inner-container">
					<div className="ph-building-main-container">
						<CountryValidator 
							countries={['CL']}
							component={
								<Images
									classContent={'ph-building-img-container'}
									classImage={'ph-building-img'}
									default={'https://storage.googleapis.com/master-image-bucket/building/Ilustraciones%20Sube%20tu%20Farmacia_Mesa%20de%20trabajo%201%20copia%2012.png'}
								/>
							}
						/>
						<CountryValidator 
							countries={['AR']}
							component={
								<Images
									classContent={'ph-building-img-container'}
									classImage={'ph-building-img'}
									default={Country.AR.cerrado}
								/>
							}
						/>
						<h1 className="ph-building-title">
							¡La {translates.pharmacy[ENV_COUNTRY]} <span className="ph-building-sub-title">{props.match.params.pharmacy}</span> se encuentra en construcción!
						</h1>
						<h4 className="ph-building-description">
							La {translates.pharmacy[ENV_COUNTRY].charAt(0).toLowerCase() + translates.pharmacy[ENV_COUNTRY].slice(1)}{' '}
							<span className="ph-building-sub-description">{props.match.params.pharmacy}</span> actualmente se encuentra en construcción, muy pronto podrás disfrutar de ella.
						</h4>
						<div className="ph-building-payment-button-container">
							<a className="ph-building-payment-button" href="/">
								<span className="ph-building-payment-button-span">Volver al Inicio</span>
							</a>
						</div>
						<div className="ph-building-payment-button-container"></div>
					</div>
				</section>
			</section>
		</>
	);
}
