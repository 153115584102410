/** @format */

import React from 'react'

import { ENV_COUNTRY } from '../../../../components/Commons/Hooks/Variables/Enviroment'

import './style.scss'

export default function BagAlliesInfo() {
	return (
		<div className='ph-bag-allies-info-container'
			style={{ display : ENV_COUNTRY === 'AR' && 'none'}}
		>
			<h2 className='ph-bag-allies-info-title'>¿No tienes Receta Medica?</h2>
			<h3 className='ph-bag-allies-info-description'>Consulta con nuestros partners</h3>
			<h4>
				<a
					className='ph-bag-allies-info-link'
					href='https://www.medismart.live/cl/'
					target='_blank'
					rel='noreferrer'>
					Medismart
				</a>{' '}
				y{' '}
				<a
					className='ph-bag-allies-info-link'
					href='https://www.mediclic.cl/'
					target='_blank'
					rel='noreferrer'>
					Mediclick
				</a>
			</h4>
		</div>
	)
}
