import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import CustomCard from '../../v2-components/Antd/Card';
import CustomLink from '../../v2-components/Antd/Link';
import CustomLinkOnCLick from '../../v2-components/Antd/LinkOnClick';
import { LOCAL_STORAGE } from '../../v2-config/constants/local-storage.constant';
import { GetLocalStorageValue } from '../../_config/adapters/local-storage/get-local-storage-value.adapter';
import './style.scss';
export default function ProfilePage() {
	const handleLogout = async () => {
		localStorage.removeItem(LOCAL_STORAGE.USER_TOKEN);
		localStorage.removeItem(LOCAL_STORAGE.USER_FAVORITES);
		localStorage.removeItem(LOCAL_STORAGE.USER_MAIN_INFO);
		localStorage.removeItem(LOCAL_STORAGE.CART);
		localStorage.removeItem(LOCAL_STORAGE.USER_EMAIL);
		window.location.href = '/';
	};


	useEffect(()=>{
		window.scroll(0,0)
	},[])

	const { first_name } = GetLocalStorageValue('userMainInfo');

	return (
		<>
			<Row className="profile-content" justify="center">
				<Col span={22}>
					<Row justify="left">
						<CustomLink style={{ marginBottom: '10px' }} className={'link-primary'} to="/">
							¡Hola {first_name}, te damos la bienvenida!
						</CustomLink>
					</Row>
					<Row justify="left">
						<CustomLink style={{ marginBottom: '10px' }} className={'link-primary'} to="/">
							Volver al inicio
						</CustomLink>
					</Row>
					<Row justify="left" gutter={[16, 16]}>
						<Col md={8} xs={24}>
							<CustomCard className={'card-primary'} title="Datos Personales">
								<p>Revisa y modifica tus datos personales.</p>
								<CustomLink className={'link-primary'} to="/profile-info/personal">
									Click Aqui
								</CustomLink>
							</CustomCard>
						</Col>
						<Col md={8} xs={24}>
							<CustomCard className={'card-primary'} title="Historial de Compras">
								<p>Revisa el estado de tus compras realizadas</p>
								<CustomLink className={'link-primary'} to="/profile-info/shopping-history">
									Click Aqui
								</CustomLink>
							</CustomCard>
						</Col>
						<Col md={8} xs={24}>
							<CustomCard className={'card-primary'} title="Historial de solicitudes">
								<p>Revisa el estado de tus solicitudes realizadas</p>
								<CustomLink className={'link-primary'} to="/profile-info/reserve-history">
									Click Aqui
								</CustomLink>
							</CustomCard>
						</Col>
						<Col md={8} xs={24}>
							<CustomCard className={'card-primary'} title="Modificar Contraseña">
								<p>Modifica tu contraseña cuando lo necesites</p>
								<CustomLink className={'link-primary'} to="/profile-info/change-password">
									Click Aqui
								</CustomLink>
							</CustomCard>
						</Col>
						<Col md={8} xs={24}>
							<CustomCard className={'card-primary'} title="Cerrar sesión">
								<p>¡Esperamos verte pronto!</p>
								<CustomLinkOnCLick className={'link-primary'} onClick={() => handleLogout()}>
									Cerrar sesión
								</CustomLinkOnCLick>
							</CustomCard>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
}
