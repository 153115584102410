import { Row } from 'antd';

export const reasonRecipe = (status) => {
	if (status?.status === 300) {
		return (
			<Row justify="left">
				<p>Motivo de rechazo: {status?.reason}</p>
			</Row>
		);
	}
	return null;
};
