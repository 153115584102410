import { Row, Col } from 'antd';
import useWindowScreen from '../../v2-custom-hooks/useWindowsScreen.hook';
import CardProductDesktop from '../landing/components/CardProduct/CardProductDesktop';
import CardProductMobile from '../landing/components/CardProduct/CardProductMobile';
import { Service } from './service';
import { IsMobile } from '../../_config/utils/is-mobile.util';
import { checkScreen } from '../landing/handle/check-screen.handle';
import './styles.scss';

export default function LandingExamplePage() {
	const widthScreen = useWindowScreen();

	return (
		<Row className="landing-container">
			<Row className="landing-title-container">
				<h2 className="landing-title">Mix de productos</h2>
			</Row>
			<Row>
				<Col span={checkScreen(widthScreen)}>
					{!IsMobile(widthScreen) ? (
						<Row className="landing-cards-container">
							{new Service().getMix().map((product, key) => (
								<CardProductDesktop key={`desktop-${key}`} product={product} />
							))}
						</Row>
					) : (
						<Row>
							{new Service().getMix().map((product, key) => (
								<CardProductMobile key={`mobile-${key}`} product={product} />
							))}
						</Row>
					)}
				</Col>
			</Row>
		</Row>
	);
}
