/** @format */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { setGlobal, useGlobal } from 'reactn';
import { ReactComponent as IconUser } from '../../../../../../../../v2-config/icons-svg/user.svg';
import CustomLink from '../../../../../../../../v2-components/Antd/Link';
import './style.scss';

const LoginUser = () => {
	const [isEmail] = useState(localStorage.getItem('userEmail'));
	const [isUserInfo] = useGlobal('userMainInfo');

	useEffect(() => {
		setGlobal(() => ({
			userMainInfo: JSON.parse(localStorage.getItem('userMainInfo')),
		}));
	}, []);

	return (
		<>
			<div>
				{isEmail ? (
					<Link className="ph-loginuser-link-container" to="/profile-info" rel="noopener noreferrer">
						<span className={`ph-loginuser-text-logged`}>
							¡Hola {isUserInfo?.first_name} {isUserInfo?.last_name}!
						</span>
						<IconUser className={'icons-theme'} />
					</Link>
				) : (
					<CustomLink className={'link-primary ph-loginuser-link-container'} to="/auth/login">
						Iniciar Sesión
					</CustomLink>
				)}
			</div>
		</>
	);
};
export default LoginUser;
