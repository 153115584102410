/** @format */

import { Row, Col } from 'antd';
import React from 'react';
import { setGlobal, useGlobal } from 'reactn';
import { GlobalConsumer } from '../../Context/Global';
import CustomButton from '../../../../v2-components/Antd/Button';
import CustomModal from '../../../../v2-components/Antd/Modal';
import './style.scss';

export default function ModalSuggestAddress() {
	const { setModalAddress } = GlobalConsumer();
	const [modalSuggestion] = useGlobal('GlobalModalSuggestion');
	const handleOpenModalAddress = () => {
		setGlobal(() => ({ GlobalModalSuggestion: false }));
		setModalAddress(true);
	};

	const handleCloseModal = () => {
		let userNotifications = JSON.parse(localStorage.getItem('userNotifications'));
		let date = new Date();
		let dateUTC = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toJSON();
		userNotifications = {
			...userNotifications,
			welcomeNotification: true,
			dateSuggestion: dateUTC,
		};
		localStorage.setItem('userNotifications', JSON.stringify(userNotifications));
		setGlobal(() => ({ GlobalModalSuggestion: false }));
	};

	return (
		<CustomModal wrapClassName="ph-modal-global-suggest-address-container" centered width={375} maskClosable={false} visible={modalSuggestion}>
			<div className="ph-modal-global-suggest-artifact"></div>
			<h4 className="ph-modal-global-suggest-address-description">Usaremos esta dirección para calcular la distancia de las farmacias a tu domicilio.</h4>
			<Row gutter={[16, 16]} justify={'center'}>
				<Col md={12} xs={24}>
					<CustomButton className="button-tertiary" block onClick={() => handleCloseModal()}>
						Ok
					</CustomButton>
				</Col>
				<Col md={12} xs={24}>
					<CustomButton className="button-primary" block onClick={() => handleOpenModalAddress()}>
						Actualizar
					</CustomButton>
				</Col>
			</Row>
		</CustomModal>
	);
}
