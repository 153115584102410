/** @format */

import axios from 'axios'

import Notify from '../../../../../../components/Commons/Notify'

import {
	ENV_SERVICES,
	ENV_COUNTRY,
} from '../../../../../../components/Commons/Hooks/Variables/Enviroment'

export async function ServicePrice(item, data) {
	let returnResponse
	const info = {
		country: ENV_COUNTRY,
		provider: data.provider,
		service_group: data.service_group,
		code_internal: `${item.supplies}-${item.plan}-${item.month_controls}x${item.months_listed}-${item.technology}`,
		condition: {
			commune: item.commune,
		},
	}
	await axios({
		method: 'POST',
		url: `${ENV_SERVICES}/price`,
		data: info,
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data
			} else {
				Notify({
					title: `Advertencia:`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Ha ocurrido un error`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				})
			}
		})
		.catch(() => {
			Notify({
				title: `Error Servicio`,
				colorTitle: 'ph-main-notification-error-title',
				description: `Verifique su conexión a Internet.`,
				time: 3,
				image: 'error',
				placement: 'topRight',
			})
		})
	return returnResponse
}
