/** @format */

import React from 'react'

import Images from '../../../../../../../../components/Commons/Images'

import './style.scss'

export default function BagTypeProduct(props) {
	return (
		<div className='ph-bag-type-receipt-main-container'>
			<Images
				classContent={'ph-bag-type-receipt-image-container'}
				classImage={'ph-bag-type-receipt-image'}
				default={props.data.image}
				title={props.data.title}
				alt={props.data.title}
			/>
		</div>
	)
}
