/** @format */

import React, { Component } from 'react'

export default class QuotePharmaciesStock extends Component {
	render() {
		return (
			<>
				<div className='ph-product-detail-quote-main-title-stock-container'>
					<h3 className='ph-product-detail-quote-main-title-stock-title'>
						Stock:
					</h3>
					<h3 className='ph-product-detail-quote-main-title-stock-description'>
						<>
							{this.props.stock > 3 && (
								<span className='ph-product-detail-quote-main-title-stock-description-green'>
									Disponible
								</span>
							)}
							{this.props.stock <= 3 && this.props.stock > 0 && (
								<span className='ph-product-detail-quote-main-title-stock-description-orange'>
									Pocas Unidades
								</span>
							)}
							{this.props.stock <= 0 && (
								<span className='ph-product-detail-quote-main-title-stock-description-red'>
									No Disponible
								</span>
							)}
						</>
					</h3>
				</div>
			</>
		)
	}
}
