/** @format */

import { Enviroment } from '../../_config/constants/enviroment-v2.constant';
import { AxiosAdapter } from '../../_config/adapters/axios/axios.adapter';
import { Http } from '../../_config/adapters/axios/axios.protocols';

export default async function GetLaboratory(laboratory) {
	const axiosRequest = new AxiosAdapter({
		method: Http.GET,
		url: `${Enviroment.FUNCTION_FRONT_COMMON}/laboratory/${Enviroment.COUNTRY}/${laboratory}`,
	});

	return await axiosRequest
		.request()
		.then((response) => {
			return response;
		})
		.catch((e) => {
			return e;
		});
}
