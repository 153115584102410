/** @format */

import { Col, Form, Input, Modal, Row, Select, Button } from 'antd';
import React, { useState } from 'react';
import { useGlobal } from 'reactn';
import { RutFormat } from '../../../../components/Commons/Hooks/Functions/RutFormat';
import InputField from '../../../../components/Commons/Input';
import InputPhone from '../../../../components/Commons/InputCountry/Phone';
import InputRut from '../../../../components/Commons/InputCountry/Rut';
import Notify from '../../../../components/Commons/Notify';
import { ServiceComplaintBookRequest } from './services';

import './style.scss';

export default function ModalCreateComplaint(props) {
	const [formComplaint] = Form.useForm();
	const [iscomplaintCreateModal, setcomplaintCreateModal] = useGlobal('complaintCreateModal');
	const [isLoading, setLoading] = useState(false);
	const handleSumit = (item) => {
		item = {
			...item,
			trx_id: item.trx_id || '',
			pharmacy: props.isPharmacy.name,
			cc: props.isPharmacy.complaint_email,
		};

		if (item.trx_id.trim() === '') delete item.trx_id;

		item.phone = item.phone.replace(/[+()/\s/]/g, '');
		item.rut = RutFormat(item.rut);

		setLoading(true);
		ServiceComplaintBookRequest(item).then((response) => {
			setLoading(false);
			if (response) {
				formComplaint.resetFields();
				setcomplaintCreateModal(false);
				Notify({
					title: 'Solicitud enviada',
					description: 'El personal de la farmacia se pondrá en contacto contigo',
					colorTitle: 'ph-main-notification-warning-title',
					image: 'warning',
					placement: 'topRight',
					time: 5,
				});
			}
		});
	};

	return (
		<>
			<Modal
				wrapClassName="ph-complaint-modal-container"
				centered
				width="800px"
				title="Crear Solicitud"
				visible={iscomplaintCreateModal}
				onCancel={() => setcomplaintCreateModal(false)}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
				okText=""
				cancelText=""
				footer={false}
			>
				<div className="ph-complaint-modal-info-container">
					<div className="ph-complaint-modal-info-inner-container">
						<p className="ph-complaint-modal-title">Creación de Solicitud</p>
						<p className="ph-complaint-modal-description">Ingresa la información requerida a continuación:</p>
						<Form name="complaint_book" form={formComplaint} onFinish={handleSumit}>
							<Row>
								<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-complaint-modal-info-form-container">
									<InputField
										className={'ph-complaint-modal-info-field-input'}
										inputName={'first_name'}
										inputNameLabel={'Nombre'}
										inputNameRule={true}
										inputNameMessage={'Ingresa tu nombre'}
										inputNameType={'text'}
										inputNameRules={'rulesFirstName'}
									/>
								</Col>
								<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-complaint-modal-info-form-container">
									<InputField
										className={'ph-complaint-modal-info-field-input'}
										inputName={'last_name'}
										inputNameLabel={'Apellido'}
										inputNameRule={true}
										inputNameMessage={'Ingresa tu apellido'}
										inputNameType={'text'}
										inputNameRules={'rulesLastName'}
									/>
								</Col>
								<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-complaint-modal-info-form-container">
									<InputPhone classInput={'ph-withdrawal-personal-data-field-input'} />
								</Col>
								<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-complaint-modal-info-form-container">
									<InputRut classInput={'ph-withdrawal-personal-data-field-input'} />
								</Col>
								<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-complaint-modal-info-form-container">
									<InputField
										className={'ph-complaint-modal-info-field-input'}
										inputName={'email'}
										inputNameLabel={'Correo electrónico'}
										inputNameRule={true}
										inputNameMessage={'E-mail es obligatorio'}
										inputNameType={'text'}
										inputNameRules={'rulesEmail'}
									/>
								</Col>
								<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-complaint-modal-info-form-container">
									<Form.Item name="type">
										<Select
											options={[
												{ label: 'Seleccione', value: '' },
												{ label: 'Reclamo', value: 'Reclamo' },
												{ label: 'Sugerencia', value: 'Sugerencia' },
											]}
											className="ph-complaint-modal-info-field-select"
											placeholder="Tipo de incidencia"
										></Select>
									</Form.Item>
								</Col>
								<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-complaint-modal-info-form-container">
									<InputField
										className={'ph-complaint-modal-info-field-input'}
										inputName={'trx_id'}
										inputNameLabel={'Número tu Orden'}
										inputNameMessage={'Número de Orden Obligatorio'}
										inputNameType={'text'}
									/>
								</Col>
								<hr className="ph-complaint-modal-info-hr"></hr>
								<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-complaint-modal-info-form-container">
									<InputField
										className={'ph-complaint-modal-info-field-input'}
										inputName={'subject'}
										inputNameLabel={'Asunto'}
										inputNameRule={true}
										inputNameMessage={'El asunto es Obligatorio'}
										inputNameType={'text'}
										inputNameRules={'rulesRequired'}
									/>
								</Col>
								<Col xs={24} sm={24} md={24} lg={24} xl={24} className="ph-complaint-modal-info-form-container">
									<Form.Item name={'message'}>
										<Input.TextArea className={'ph-complaint-modal-info-field-input'} placeholder={'Mensaje'} name={'message'} type={'text'} rows={5} />
									</Form.Item>
								</Col>
							</Row>
							<Form.Item>
								<div className="ph-complaint-modal-info-button-container">
									<Button className="ph-complaint-modal-info-button" type="primary" htmlType={'submit'} loading={isLoading}>
										Enviar
									</Button>
								</div>
							</Form.Item>
						</Form>
					</div>
				</div>
			</Modal>
		</>
	);
}
