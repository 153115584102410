import CustomPagination from '../../../v2-components/Antd/Pagination';
import { paginationFilter } from './pagination-filter.handle';
import { Enviroment } from '../../../_config/constants/enviroment-v2.constant';
import { Country } from '../../../_config/constants/image-country.constant';
import CustomImage from '../../../v2-components/Antd/Image';

export const showPagination = (component, data, pagination, handle) => {
	if (data?.length === 0) return <CustomImage style={{ width: '60%', margin: '0px auto' }} src={Country[Enviroment.COUNTRY].sinStock} />;

	return (
		<>
			<CustomPagination
				selectComponentClass={component}
				current={pagination?.current}
				pageSize={pagination?.size}
				total={pagination?.total_items}
				onChange={handle}
				showSizeChanger={false}
			/>
			{paginationFilter(pagination?.current, pagination.items_page, pagination?.total_items)}
		</>
	);
};
