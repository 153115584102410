/** @format */

import React, { useEffect, useState, createContext, useContext } from 'react'

const AppContext = createContext()

export const GlobalProvider = (props) => {
	const [isBag, setBag] = useState(JSON.parse(localStorage.getItem('cart')))
	const [isLocation, setLocation] = useState(JSON.parse(localStorage.getItem('locationStore')))
	const [isLoginUser, setLoginUser] = useState({ email: localStorage.getItem('userEmail'), token: localStorage.getItem('userToken') })
	const [isMobile, setMobile] = useState(false)
	const [isModalAddress, setModalAddress] = useState(false)
	const [isQuotationType, setQuotationType] = useState(null)

	useEffect(() => {
		if (window.innerWidth < 992) setMobile(true)
	}, [])

	const value = {
		isBag,
		isLocation,
		isLoginUser,
		isMobile,
		isModalAddress,
		isQuotationType,
		setBag,
		setLocation,
		setLoginUser,
		setModalAddress,
		setQuotationType,
	}
	return <AppContext.Provider value={value} {...props} />
}

export const GlobalConsumer = () => {
	const context = useContext(AppContext)
	if (!context) {
		throw new Error('GlobalConsumer debe estar dentro de proveedor AppContext')
	}
	return context
}
