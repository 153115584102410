/** @format */

import React, { Component } from 'react';

import { Row, Col } from 'antd';

import Images from '../../../../../../components/Commons/Images';

import { formatPrice } from '../../../../../../components/Commons/Hooks/Functions/FormatPrice';

import { ENV_COUNTRY, ENV_IMG_ROUTE } from '../../../../../../components/Commons/Hooks/Variables/Enviroment';

import './style.scss';

export default class ProductDetail extends Component {
	render() {
		return (
			<>
				{this.props.product.map((product, i) => (
					<div className="ph-detail-list-product-container" key={i}>
						<Row>
							<Col span={6}>
								<Images
									classContent={'ph-detail-list-product-image-container'}
									classImage={'ph-detail-list-product-image'}
									webp={`${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${product.id_pharol}.webp`}
									//jp2={`${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${props.item.id_pharol}.jp2`}
									//jxr={`${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${props.item.id_pharol}.jxr`}
									default={`${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${product.id_pharol}.png`}
									alt={product.editable_name}
									title={product.editable_name}
								/>
							</Col>
							<Col span={18}>
								<h4 className="ph-detail-list-product-title">{product.editable_name}</h4>
								{this.props.typeRetirement && <h5 className="ph-detail-list-product-pharmacy-title">{product.name_edited_pharmacy}</h5>}
								{product.interface && (
									<>
										{product.interface.promotions && (
											<h5 className="ph-detail-list-product-promotion">
												Promoción: <span className="ph-detail-list-product-promotion-title">{product.interface.promotions.title}</span>
											</h5>
										)}
									</>
								)}
								{product.interface && (
									<>
										{product.interface.promotions && (
											<>
												{product.valid_data_payment && (
													<>{product.valid_data_payment.discount < 0 && <h5 className="ph-detail-list-product-quantity">Descuento: {formatPrice(product.valid_data_payment.discount)}</h5>}</>
												)}
											</>
										)}
									</>
								)}
								{product.interface && (
									<>
										{product.interface.on_demand && (
											<div className="ph-checkout-detail-on-demand-main-container">
												<h4 className="checkout-detail-on-demand-title">{product.interface.on_demand.title}</h4>
												<Images
													classContent={'checkout-detail-on-demand-image-container'}
													classImage={'checkout-detail-on-demand-image'}
													webp={product.interface.on_demand.image.webp}
													jxr={product.interface.on_demand.image.jxr}
													jp2={product.interface.on_demand.image.jp2}
													default={product.interface.on_demand.image.png}
													alt={product.interface.on_demand.title}
													title={product.interface.on_demand.title}
												/>
											</div>
										)}
									</>
								)}
								{product.interface && (
									<>
										{product.interface.magistral && (
											<div className="ph-checkout-detail-on-demand-main-container">
												<h4 className="checkout-detail-on-demand-title">{product.interface.magistral.title}</h4>
												<Images
													classContent={'checkout-detail-on-demand-image-container'}
													classImage={'checkout-detail-on-demand-image'}
													webp={product.interface.magistral.image.webp}
													jxr={product.interface.magistral.image.jxr}
													jp2={product.interface.magistral.image.jp2}
													default={product.interface.magistral.image.png}
													alt={product.interface.magistral.title}
													title={product.interface.magistral.title}
												/>
											</div>
										)}
									</>
								)}
								<h5 className="ph-detail-list-product-quantity">Cantidad: {product.quantity}</h5>
								<h5 className="ph-detail-list-product-price-unity">Precio por unidad: {formatPrice(product.unit_price)}</h5>
								{product.pbm_data && (
									<>
										{product.pbm_data.pbm_award_query && (
											<>
												{product.pbm_data.pbm_award_query.productos && (
													<>
														{product.pbm_data.pbm_award_query.productos.length > 0 && (
															<h5 className="ph-detail-list-product-price-unity">Descuento PBM: {formatPrice(product.pbm_data.pbm_award_query.productos[0].descuento)}</h5>
														)}
													</>
												)}
											</>
										)}
									</>
								)}
								<h5 className="ph-detail-list-product-total">Total: {formatPrice(product.total)}</h5>
							</Col>
						</Row>
					</div>
				))}
			</>
		);
	}
}
