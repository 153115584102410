/** @format */

import React from 'react'

import { Spin, Modal } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import './style.scss'

export default function ModalLoadingServices(props) {
	return (
		<Modal
			wrapClassName='ph-retirement-services-loading-modal'
			centered
			title='Cerrar Sesión'
			visible={props.visible}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			okText=''
			cancelText=''
			width={270}
			header={false}
			footer={false}>
			<h4 className='ph-retirement-services-loading-modal-title'>Procesando sus datos</h4>
			<h4 className='ph-retirement-services-loading-modal-subtitle'>Espere...</h4>
			<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
		</Modal>
	)
}
