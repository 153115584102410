/** @format */

import Notify from "../../../../components/Commons/Notify";

import { setGlobal } from "reactn";

import { ENV_ISO_CURRENCY } from "../../../../components/Commons/Hooks/Variables/Enviroment";

import { amountProductsCart } from "../../../../components/Commons/Hooks/Functions/AmountProductsCart";

import { PixelFacebook } from "../../../../components/Commons/Hooks/Functions/PixelFacebook";

export default function BagAddProduct() {
  const handleAddProduct = (cart, product, quantity) => {
    if (product.pbm_data) {
      Notify({
        title: `¡Advertencia!`,
        colorTitle: "ph-main-notification-error-title",
        description:
          "Tienes un convenio PBM asociado a este producto, si deseas modificar la cantidad, debes eliminar primero la adjudicacion.",
        time: 3,
        image: "warning",
        placement: "topRight",
      });
      return;
    }
    const index = cart.findIndex((search) => {
      if (search.objectID) return search.objectID === product.objectID;
      else return search.id === product.id;
    });
    if (index !== -1) {
      let auxQuantity = null;
      auxQuantity = cart[index].quantity + quantity;
      if (auxQuantity > product.limit_stock) {
        Notify({
          title: `Advertencia!`,
          colorTitle: "ph-main-notification-warning-title",
          description: `¡Solo podemos dispensar ${product.limit_stock} unidades de este producto!`,
          time: 3,
          image: "error",
          placement: "topRight",
        });
        return;
      } else {
        cart[index].quantity = cart[index].quantity + quantity;
        cart[index].sub_total = cart[index].quantity * cart[index].unit_price;
      }
    } else {
      product.quantity = quantity;
      product.unit_price = product.price;
      product.sub_total = product.price * quantity;
      cart.push(product);
    }
    cart.forEach((item) => {
      if (item.pharmacy === cart[index]?.pharmacy) delete item.type_retirement;
    });
    PixelFacebook("AddToCart", {
      content_ids: product.id_pharol,
      content_type: "product",
      content_name: product.name,
      currency: ENV_ISO_CURRENCY,
      value: parseInt(product.price).toFixed(2),
    });
    localStorage.setItem("cart", JSON.stringify(cart));
    setGlobal(() => ({ carQuantity: amountProductsCart() }));
    return cart;
  };

  return {
    handleAddProduct,
  };
}
