/** @format */

import React, { Component } from "react";

import Images from "../../../Images";

import Card from "antd/lib/card";

import "./style.scss";

export default class Boxes extends Component {
  render() {
    return (
      <>
        {this.props.domain === true ? (
          <a
            href={`pharmacies/${this.props.detail.slug}`}
            rel="noopener noreferrer"
          >
            <Card className="ph-banner-container">
              <Images
                classImage={"ph-banner-image"}
                default={this.props.detail.image.default}
                webp={this.props.detail.image.webp}
                jp2={this.props.detail.image.jp2}
                jxr={this.props.detail.image.jxr}
                jpg={this.props.detail.image.jpg}
                alt={this.props.detail.name}
                title={this.props.detail.name}
              />
            </Card>
          </a>
        ) : (
          <a href={`${this.props.detail.slug}`} rel="noopener noreferrer">
            <Card className="ph-banner-container">
              <Images
                classImage={"ph-banner-image"}
                default={this.props.detail.image.default}
                webp={this.props.detail.image.webp}
                jp2={this.props.detail.image.jp2}
                jxr={this.props.detail.image.jxr}
                jpg={this.props.detail.image.jpg}
                alt={this.props.detail.name}
                title={this.props.detail.name}
              />
            </Card>
          </a>
        )}
      </>
    );
  }
}
