export const handleProductAdapter = (product) => {
	return {
		name: product.name,
		laboratory: product.laboratory,
		pharmacy: product.pharmacy,
		stock: product.stock,
		original_image: product.original_image,
		price: product.price,
		promotions: product.promotions,
		promotion_price: product.promotion_price,
		promotion_title: product.promotions?.rules?.description,
		recipe_icon: product.promotions?.image,
		pharmacy_image: product.pharmacy_image,
		prescription_type: product.prescription_type,
		min_price: product.min_price,
		category: product.category,
		available: product.available,
		validate_recipe: product.validate_recipe.toLowerCase(),
		slug: product.slug,
	};
};
