export const checkPharmacy = {
	CL: {
		handle: (props) => {
			return props.despatch_info.some((iterator) => iterator.title === 'Retiro');
		},
	},
	AR: {
		handle: (props) => {
			return props.despatch_info.some((iterator) => iterator.title === 'Retiro');
		},
	},
};
