/** @format */

import React from 'react'

import { Steps } from 'antd'

import './style.scss'

export default function SteperService(props) {
	return (
		<Steps
			className='ph-stepper-service-main-container ant-steps-horizontal'
			size='small'
			current={props.step}
			direction='horizontal'>
			<Steps.Step title='Entrega' />
			<Steps.Step title='Pago' />
		</Steps>
	)
}
