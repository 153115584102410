import { Events } from './events.constant';
export const PurchaseAdapter = async ({ event = Events.view_item, order }) => {
	console.log('PurchaseAdapter', { event, order });
	const itemsMap = await order.products.map((iterator, key) => {
		return {
			item_name: iterator.editable_name,
			item_id: `${iterator.pharmacy}-${iterator.id_pharol}`,
			item_brand: iterator.laboratory,
			item_category: iterator.master_category,
			item_category2: iterator.category,
			item_category3: iterator.sub_category,
			quantity: iterator.quantity,
			price: iterator.price,
			index: key,
		};
	});
	window.dataLayer.push({ ecommerce: null });
	window.dataLayer.push({
		event: event,
		ecommerce: {
			transaction_id: order.trx_id,
			value: order.total,
			shipping: order.total_delivery,
			items: itemsMap,
		},
	});
};
