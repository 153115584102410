/** @format */

import React from 'react'

import { Form, Input } from 'antd'

import './style.scss'
import { rulesValidation } from './rules'

export default function InputField(props) {
	return (
		<Form.Item name={props.inputName} rules={rulesValidation[props.inputNameRules]}>
			<Input
				className={props.className}
				size='large'
				prefix={props.inputNameIcon}
				type={props.inputNameType}
				placeholder={props.inputNameLabel}
				maxLength={props.maxLength}
				onChange={props.onChange}
				name={props.inputName}
				defaultValue={props.defaultValue}
				disabled={props.disabled}
				min={props.min}
				max={props.max}
			/>
		</Form.Item>
	)
}
