/** @format */

import React from 'react'

import { Route } from 'react-router-dom'

import { BagProvider } from '../../../../components/Commons/Context/Bag'

export default function BagContext(props) {
	return (
		<BagProvider>
			<Route {...props} />
		</BagProvider>
	)
}
