/** @format */

import config from './engine.json';

import { sortInfo } from './data';

export function getConfig() {
	return config;
}

function toLowerCase(string) {
	if (string) return string.toLowerCase();
}

export function getTitleField() {
	return getConfig().titleField || 'title';
}

export function getUrlField() {
	return getConfig().urlField;
}

export function getThumbnailField() {
	return getConfig().thumbnailField;
}

export function getFacetFields(config) {
	return config.facets || [];
}

export function getSortFields() {
	return getConfig().sortFields || [];
}

export function getResultTitle(result) {
	const titleField = getTitleField();

	return result.getSnippet(titleField);
}

export function stripUnnecessaryResultFields(resultFields) {
	return Object.keys(resultFields).reduce((acc, n) => {
		if (['_meta', 'id', toLowerCase(getTitleField()), toLowerCase(getUrlField()), toLowerCase(getThumbnailField())].includes(toLowerCase(n))) {
			return acc;
		}

		acc[n] = resultFields[n];
		return acc;
	}, {});
}

export function buildSearchOptionsFromConfig() {
	const config = getConfig();
	const searchFields = (config.searchFields || config.fields || []).reduce((acc, n) => {
		acc = acc || {};
		acc[n] = {};
		return acc;
	}, undefined);

	const resultFields = (config.resultFields || config.fields || []).reduce((acc, n) => {
		acc = acc || {};
		acc[n] = {
			raw: {},
			snippet: {
				size: 100,
				fallback: true,
			},
		};
		return acc;
	}, undefined);

	if (config.urlField) {
		resultFields[config.urlField] = {
			raw: {},
			snippet: {
				size: 100,
				fallback: true,
			},
		};
	}

	if (config.thumbnailField) {
		resultFields[config.thumbnailField] = {
			raw: {},
			snippet: {
				size: 100,
				fallback: true,
			},
		};
	}

	if (config.titleField) {
		resultFields[config.titleField] = {
			raw: {},
			snippet: {
				size: 100,
				fallback: true,
			},
		};
	}

	const searchOptions = {};
	searchOptions.result_fields = resultFields;
	searchOptions.search_fields = searchFields;
	return searchOptions;
}

export function buildFacetConfigFromConfig(config) {
	//const config2 = getConfig()

	const facets = (config.facets || []).reduce((acc, n) => {
		acc = acc || {};
		acc[n] = {
			type: 'value',
			size: 100,
		};
		return acc;
	}, undefined);

	return facets;
}

export function buildSortOptionsFromConfig() {
	const config = getConfig();
	return [
		{
			name: 'Mayor Relevancia',
			value: '',
			direction: '',
		},
		...(config.sortFields || []).reduce((acc, sortField) => {
			acc.push({
				name: `Mayor ${sortInfo[sortField]}`,
				value: sortField,
				direction: 'desc',
			});
			acc.push({
				name: `Menor ${sortInfo[sortField]}`,
				value: sortField,
				direction: 'asc',
			});
			return acc;
		}, []),
	];
}

export function buildAutocompleteQueryConfig() {
	const querySuggestFields = getConfig().querySuggestFields;
	if (!querySuggestFields || !Array.isArray(querySuggestFields) || querySuggestFields.length === 0) {
		return {};
	}

	return {
		suggestions: {
			types: {
				documents: {
					fields: getConfig().querySuggestFields,
				},
			},
		},
	};
}
