/** @format */

import React, { Component } from 'react';

import './style.scss';
import { ENV_COUNTRY } from '../../../Hooks/Variables/Enviroment';
import { ENVIROMENT } from '../../../../../v2-config/constants/enviroment.constant';

export default class QuotePharmaciesStock extends Component {
	render() {
		return (
			<>
				<div className="ph-product-detail-quote-main-title-stock-container">
					<h3 className="ph-product-detail-quote-main-title-stock-title">Stock:</h3>

					<h3 className="ph-product-detail-quote-main-title-stock-description">
						{!this.props.detail.on_demand && (
							<>
								{this.props.detail.stock > 3 && (
									<span
										className={
											ENV_COUNTRY === 'AR'
												? 'ph-product-detail-quote-main-title-stock-description-green-arg'
												: 'ph-product-detail-quote-main-title-stock-description-green'
										}
									>
										{`${ENVIROMENT.ROOT_RESERVE ? 'Por confirmar' : 'Disponible'}`}
									</span>
								)}
								{this.props.detail.stock <= 3 && this.props.detail.stock > 0 && (
									<span
										className={`${
											ENV_COUNTRY === 'AR'
												? 'ph-product-detail-quote-main-title-stock-description-pink'
												: 'ph-product-detail-quote-main-title-stock-description-orange'
										}`}
									>
										{`${ENVIROMENT.ROOT_RESERVE ? 'Por confirmar' : 'Pocas unidades'}`}
									</span>
								)}
								{this.props.detail.stock <= 0 && (
									<span className="ph-product-detail-quote-main-title-stock-description-red">
										{`${ENVIROMENT.ROOT_RESERVE ? 'Por confirmar' : 'No disponible'}`}
									</span>
								)}
							</>
						)}
						{this.props.detail.on_demand && <span className="ph-product-detail-quote-main-title-stock-description-green">Sola a pedido</span>}
					</h3>
				</div>
			</>
		);
	}
}
