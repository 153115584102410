/** @format */

import React, { useState, useEffect } from 'react';

import { Row, Col, Modal, Form, Button } from 'antd';

import { FilePdfOutlined } from '@ant-design/icons';

import Images from '../../../../../../components/Commons/Images';
import InputField from '../../../../../../components/Commons/Input';
import UploadImage from '../../../../../../components/Commons/UploadImage';
import { rulesValidation } from '../../../../../../components/Commons/Input/rules';

import { uploadSingleImageService } from '../../../../../../components/Commons/Hooks/Functions/UploadImages';

import { GetRecipes } from '../../../../../../components/Commons/Hooks/Functions/GetRecipes';
import { SaveRecipes } from '../../../../../../components/Commons/Hooks/Functions/SaveRecipes';

import { EditRecipes } from './services';

import './style.scss';
import CustomButton from '../../../../../../v2-components/Antd/Button';

export default function ModalRecipes(props) {
	const [recipeForm] = Form.useForm();
	const [isLoading, setLoading] = useState(false);
	const [isImage, setImage] = useState(null);

	const handleUploadImage = async (image) => {
		let urlImage = await uploadSingleImageService(image, 'recipe');
		urlImage = urlImage?.data?.data[0]?.url;
		return urlImage;
	};

	const handleRecipes = async (item) => {
		setLoading(true);

		let image = props.modal?.data?.recipe ? props.modal?.data?.recipe : await handleUploadImage(isImage);

		if (props.modal?.type === 'Crear') {
			await SaveRecipes(item.alias, image).then(async (response) => {
				if (response) {
					await GetRecipes().then((response) => {
						if (response) {
							props.setRecipeData(response);
						}
					});
				}
			});
		} else {
			await EditRecipes(props.modal?.data?.recipe_id, item.alias, image).then(async (response) => {
				if (response) {
					await GetRecipes().then((response) => {
						if (response) {
							props.setRecipeData(response);
						}
					});
				}
			});
		}
		setLoading(false);
		handleCloseModal();
	};

	const handleRemoveImage = () => {
		setImage(null);
		recipeForm.setFieldsValue({
			recipe: null,
		});
	};

	const handleAddImage = (item) => {
		setImage(item);
	};

	const handleCloseModal = () => {
		recipeForm.setFieldsValue({
			alias: null,
			recipe: null,
		});
		props.setVisible({ modal: false, type: null, data: null });
	};

	useEffect(() => {
		if (props.modal?.data) {
			recipeForm.setFieldsValue({
				alias: props.modal.data.name,
			});
		}
	}, [props.modal, recipeForm]);

	return (
		<>
			<Modal
				wrapClassName="ph-modal-recipes-modal-container"
				title="Gestión de Recetas"
				visible={props.modal.modal}
				onCancel={() => handleCloseModal()}
				onOk={''}
				destroyOnClose={true}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
				width="600px"
			>
				<h3 className="ph-modal-recipes-modal-main-title">{props.modal?.type}</h3>
				<h3 className="ph-modal-recipes-modal-title">¡Guarda Recetas Médicas para tus próximas compras de productos!</h3>
				<Form onFinish={handleRecipes} name="recipe_form" form={recipeForm}>
					<InputField
						className={'ph-modal-recipes-field-input'}
						inputName={'alias'}
						inputNameLabel={'Ingresa un alias ó nombre'}
						inputNameType={'text'}
						inputNameRule={true}
						inputNameMessage={'Ingresa tu alias......'}
						inputNameRules={'rulesAlias'}
					/>
					{props.modal?.data?.recipe ? (
						<Row className="ph-modal-recipes-modal-preview-image-main-container">
							<Col xs={24} sm={12} md={12} lg={12} xl={12}>
								<div onClick={() => props.previewModal({ visible: true, image: props.modal.data.recipe })}>
									{props.modal.data.recipe.endsWith('.pdf') ? (
										<div className="ph-checkout-receipt-modal-process-pdf-main-container">
											<div className="ph-checkout-receipt-modal-process-pdf-container">
												<FilePdfOutlined className="ph-checkout-receipt-modal-process-pdf-icon" />
											</div>
											<a className="ph-checkout-receipt-modal-process-pdf-link" href={props.modal.data.recipe} target="_blank" rel="noreferrer">
												{props.modal.data.recipe}
											</a>
										</div>
									) : (
										<Images
											classContent={'ph-modal-recipes-modal-preview-image-container'}
											classImage={'ph-modal-recipes-modal-preview-image'}
											default={props.modal.data.recipe}
										/>
									)}
								</div>
							</Col>
							<Col xs={24} sm={12} md={12} lg={12} xl={12} className="ph-modal-recipes-modal-preview-button-container">
								<Button
									className="ph-modal-recipes-modal-preview-button"
									onClick={() => props.setVisible({ modal: true, type: 'Editar', data: { ...props.modal.data, recipe: null } })}
								>
									Subir Nueva Receta
								</Button>
							</Col>
						</Row>
					) : (
						<Form.Item name={'recipe'} rules={rulesValidation.rulesRecipe}>
							<div className="ph-modal-recipes-modal-upload-image-container">
								<UploadImage type={'recipe'} title={'Receta'} addProductImage={(data) => handleAddImage(data)} removeProductImage={() => handleRemoveImage()} />
							</div>
						</Form.Item>
					)}
					<CustomButton key="submit" htmlType="submit" loading={isLoading} className={'button-primary'}>
						Aceptar
					</CustomButton>
				</Form>
			</Modal>
		</>
	);
}
