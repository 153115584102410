import React from 'react';
import { Row, Col, Skeleton } from 'antd';
import { Config } from './constant/config.constant';
import './style.scss';
export default function CustomLoading(props) {
	return (
		<>
			<br></br>
			<Row justify="center">
				<Col span={18}>
					<Row justify="center">
						<Skeleton
							active={Config.active}
							paragraph={{
								rows: 10,
							}}
						/>
					</Row>
				</Col>
			</Row>

			<br></br>
			<br></br>
		</>
	);
}
