/** @format */

import React from 'react'

// import Images from '../../../Commons/Images'

export default function BannerImages(props) {
	return (
		<>
			{/*
				REVISAR
				props.sidebarBanners?.length > 0 && (
				<>
					{props.sidebarBanners.map((item, i) => {
						return (
							<a href={item.slug} rel='noopener noreferrer' target='_blank' key={i}>
								<Images
									classContent={'ph-elastic-sidebar-banner-info-image-container'}
									classImage={'ph-elastic-sidebar-banner-info-image'}
									default={this.props.item.image_web}
									title={'Pharmacy Image'}
									alt={'Pharmacy Image'}
								/>
							</a>
						)
					})}
				</>
				)*/}
		</>
	)
}
