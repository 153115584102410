/** @format */

import React from 'react'

import Promotions from './components/Promotions'
import Price from './components/Price'

import './style.scss'

export default function QuotePharmaciesPriceDetail(props) {
	return (
		<div className='ph-electronic-quote-pharmacy-price-promotion-container'>
			<Price item={props.item} />
			<Promotions item={props.item} />
		</div>
	)
}
