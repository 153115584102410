/** @format */

import React from 'react'

import { Spin, Modal } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import Images from '../../../../../../components/Commons/Images'

import { ENV_IMG_HEADER } from '../../../../../../components/Commons/Hooks/Variables/Enviroment'

import './style.scss'

export default function ModalCalculate(props) {
	return (
		<Modal
			wrapClassName='ph-cart-payment-services-modal'
			centered
			title='Cerrar Sesión'
			visible={props.visible}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			okText=''
			cancelText=''
			width={270}
			header={false}
			footer={false}>
			<Images
				classContent={'ph-cart-payment-services-modal-image-container'}
				classImage={'ph-cart-payment-services-modal-image'}
				webp={`${ENV_IMG_HEADER}/pharol-bolsa-compras.webp`}
				jp2={`${ENV_IMG_HEADER}/pharol-bolsa-compras.jp2`}
				jxr={`${ENV_IMG_HEADER}/pharol-bolsa-compras.jxr`}
				default={`${ENV_IMG_HEADER}/pharol-bolsa-compras.png`}
				title={''}
			/>
			<h4 className='ph-cart-payment-services-modal-title'>Calculando...</h4>
			<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
		</Modal>
	)
}
