/** @format */

import React from 'react';
import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';

import Images from '../../../../components/Commons/Images';
import MetaDescription from '../../../../components/Commons/MetaDescription';
import { ENV_IMG } from '../../../../components//Commons/Hooks/Variables/Enviroment';
import CustomButton from '../../../../v2-components/Antd/Button';

import './style.scss';

const RequestPreorderSuccess = (props) => {
	const { t } = useTranslation('global');
	const history = useHistory();

	const handleRedirect = () => {
		history.push('/');
	};

	return (
		<div className="ph-request-preorder-success-global-container">
			<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			<section className="ph-request-preorder-success-main-container">
				<div className="ph-request-preorder-success-inner-container">
					<Images
						classContent={'ph-request-preorder-succes-img-container'}
						classImage={'ph-request-preorder-succes-img'}
						webp={`${ENV_IMG}/react-checkout/master-recipe/iconMagistral.webp`}
						jp2={`${ENV_IMG}/react-checkout/master-recipe/iconMagistral.jxr`}
						jxr={`${ENV_IMG}/react-checkout/master-recipe/iIconMagistral.jp2`}
						default={`${ENV_IMG}/react-checkout/master-recipe/iconMagistral.png`}
					/>
					<h1 className="ph-request-preorder-success-succes-title">¡Preorden procesada exitosamente!</h1>
					<h4 className="ph-request-preorder-success-description-main">Enviaremos a tu correo la cotización de las farmacias que cuenten con este servicio.</h4>
					<p className="ph-request-preorder-success-paraghaf">
						Número de Consulta: <strong className="ph-request-preorder-success-number">{props.match.params.id}</strong>
					</p>
					<h4 className="ph-request-preorder-success-description-span">
						<span className="ph-request-preorder-success-description-span-i">*</span>
						{t('checkout.transaction.error.description')}
						<a href={`mailto:${t('main.email')}`}>
							<span className="ph-request-preorder-success-description-contact">{t('main.email')}</span>
						</a>
					</h4>
					<div className="ph-request-preorder-success-payment-button-container">
						<CustomButton className={'button-primary'} onClick={handleRedirect}>
							Volver al Inicio
						</CustomButton>
					</div>
				</div>
			</section>
		</div>
	);
};

export default RequestPreorderSuccess;
