export class AxiosInterceptor {
	constructor(response) {
		this.response = response || [];
	}

	async pipe() {
		try {
			if (this.response.data.statusCode === 200 || this.response.data.statusCode === 201) {
				return this.response.data.data;
			}
			throw this.response.data;
		} catch (error) {
			throw error;
		}
	}
}
