/** @format */

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobal } from 'reactn';
import CustomButton from '../../../../../v2-components/Antd/Button';
import AddProductCart from '../../../Hooks/Functions/AddProductCart';
import './style.scss';
export default function ProductButton(props) {
	const history = useHistory();
	const [isQuantity, setQuantity] = useGlobal('quantityProduct');
	const { handleAddProductCart } = AddProductCart();
	const particularQuantity = props.quantity || isQuantity;
	const isDisabled = props.detail.stock > 0 ? false : true;

	const handleAddProductCartActions = () => {
		handleAddProductCart(props.detail, particularQuantity, props.alternatives ? props.alternatives : null);
		if (window.location.pathname.match(/cart/)) {
			setTimeout(() => {
				history.go(0);
			}, 500);
		}
	};

	useEffect(() => {
		setQuantity(1);
	}, [setQuantity]);

	return (
		<div className={`${props.classList ? 'ph-product-list-button-container' : ''} ph-product-button-container`}>
			<>
				{props.detail.stock > 0 && (
					<CustomButton className={'button-secondary'} onClick={() => handleAddProductCartActions()} disabled={isDisabled}>
						Agregar
					</CustomButton>
				)}
			</>
		</div>
	);
}
