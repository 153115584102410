/** @format */

import React, { Component } from 'react'

import { Link } from 'react-router-dom'

import './style.scss'

export default class CardProfileLink extends Component {
	render() {
		return (
			<div className='ph-profile-link-card'>
				<h3 className='ph-profile-link-card-title'>{this.props.title}</h3>
				{this.props.links.map((item, index) => (
					<div key={index}>
						{item.local ? (
							<Link className='ph-profile-link-card-description' to={item.link}>
								{item.title}
							</Link>
						) : (
							<a
								href={item.link}
								className='ph-profile-link-card-description'
								target='_blank'
								rel='noopener noreferrer'>
								{item.title}
							</a>
						)}
					</div>
				))}
			</div>
		)
	}
}
