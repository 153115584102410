/** @format */

import React, { Component } from "react";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import Carousel from "antd/lib/carousel";

import Images from "../Images";

import "./style.scss";

function Arrow(item) {
  let { onClick } = item;
  return (
    <div
      className={`ph-carouselbanner ph-arrow-${item.sideArrow}`}
      onClick={onClick}
    >
      {item.iconArrow}
    </div>
  );
}

export default class Sliders extends Component {
  render() {
    const bannersCart = this.props.bannersCart;
    const settings = {
      autoplay: true,
      pauseOnHover: false,
      dots: false,
      arrows: true,
      effect: "fade",
      autoplaySpeed: 11000,
      nextArrow: <Arrow sideArrow="right" iconArrow={<RightOutlined />} />,
      prevArrow: <Arrow sideArrow="left" iconArrow={<LeftOutlined />} />,
    };
    return (
      <>
        <Carousel
          className={`${
            bannersCart ? "ph-carouselbanner-cart" : ""
          } ph-carouselbanner-container ${
            this.props.mobile ? "ph-carouselbanner-mobile-container" : ""
          } `}
          {...settings}
        >
          {this.props.listSliders.map(function (item, i) {
            return (
              <div key={i} className={`slider-position-${i}`}>
                <a href={item.slug} rel="noopener noreferrer">
                  <Images
                    classImage={"ph-carouselbanner-image"}
                    default={item.image.jpg}
                    webp={item.image.webp}
                    jp2={item.image.jp2}
                    jxr={item.image.jxr}
                    alt={item.name}
                    title={item.name}
                  />
                </a>
              </div>
            );
          })}
        </Carousel>
      </>
    );
  }
}
