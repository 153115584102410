import { useEffect } from 'react';
import { Col, Row } from 'antd';
import CustomCard from '../../v2-components/Antd/Card';
import CustomLink from '../../v2-components/Antd/Link';

import './style.scss';
export default function ClaimsPage() {
	useEffect(()=>{
		window.scroll(0,0)
	},[])

	return (
		<>
			<Col className='claim-container'>
				<Row justify="left" className='title-container'>
					<h3>Reclamos y sugerencias</h3>
					<CustomLink className={'link-primary'} to="/">
						Volver
					</CustomLink>
				</Row>
				<Row justify="center" className="claims-content">
					<Row justify="center" gutter={[16, 16]}>
						<Col md={8} xs={23} className='claims-cards-container'>
							<CustomCard className={'card-primary'} title="Crear Solicitud">
								<p>Aqui podrás crear un nuevo reporte con el requerimiento que necesites que sea atendido.</p>
								<CustomLink className={'link-primary'} to="/claims/create">
									Click Aqui
								</CustomLink>
							</CustomCard>
						</Col>
						<Col md={8} xs={23} className='claims-cards-container'>
							<CustomCard className={'card-primary'} title="Buscar Solicitud">
								<p>Busca una solicitud realizada por número de RUT o su código correspondiente asignado</p>
								<CustomLink className={'link-primary'} to="/claims/search">
									Click Aqui
								</CustomLink>
							</CustomCard>
						</Col>
						<Col md={8} xs={23} className='claims-cards-container'>
							<CustomCard className={'card-primary'} title="Libro de Reclamos">
								<p>Consulta un rango de fechas de solicitudes o reclamos realizados por los clientes</p>
								<CustomLink className={'link-primary'} to="/claims/book">
									Click Aqui
								</CustomLink>
							</CustomCard>
						</Col>
					</Row>
				</Row>
			</Col>
		</>
	);
}
