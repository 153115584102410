/** @format */

import { Col } from 'antd';
import qs from 'qs';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { ENV_COUNTRY } from '../../components/Commons//Hooks/Variables/Enviroment';
import MetaDescription from '../../components/Commons/MetaDescription';
import Elastic from '../../components/Elastic';
import { HandleFacets } from '../../components/Elastic/functions/HandleFacets';
import SlidersInternal from '../../v2-components/SlidersInternal';
import { ENVIROMENT } from '../../v2-config/constants/enviroment.constant';
import useWindowScreen from '../../v2-custom-hooks/useWindowsScreen.hook';
import { IsMobile } from '../../_config/utils/is-mobile.util';
import { Country } from './country';
// import NewsModal from '../home/components/NewsModal';
import PopUpModal from '../../v2-components/PopUp';

export default function Pharmacy(props) {
	const widthScreen = useWindowScreen();
	const params = useLocation();
	const location =  useLocation()
	const [isQuery] = useState(qs.parse(params.search.slice(1)));
	const [isPopUpModalShown, setIsPopUpModalShown] = useState(false);

	const canonicalUrl = `${window.location.origin}${location.pathname}`;

	useEffect(() => {
		window.scroll(0, 0);
	}, [params]);

	useEffect(() => {
		const modalShown = localStorage.getItem('isPopUpModalShown');
		if (!modalShown) {
			setIsPopUpModalShown(true);
			localStorage.setItem('isPopUpModalShown', 'true');
		}
	}, []);

	const handleFilters = () => {
		const query = isQuery.q ? isQuery.q : '';
		const filters = Country[ENV_COUNTRY].handleFilters(props.match.params);
		const index = Country[ENV_COUNTRY].handleElasticPrecision(query);
		const sort = Country[ENV_COUNTRY].handleSort();
		const facets = HandleFacets();
		const params = { filters, query, index, facets, sort };
		return params;
	};
	return (
		<>
			<Helmet>
				<link rel="canonical" href={canonicalUrl} />
			</Helmet>
			<MetaDescription title={`${ENVIROMENT.ROOT_NAME}`} name={'description'} content={`descripcion`} robots={true} />
			<Col span={24}>
				{!IsMobile(widthScreen) ? (
					<SlidersInternal template={'home'} location={'sliders-desktop'} />
				) : (
					<SlidersInternal template={'home'} location={'sliders-mobile'} />
				)}
			</Col>
			<Elastic params={handleFilters()} placeholcerSearchBox={'Buscar en esta farmacia'} template={'home'} location={'sidebar'} />
			{isPopUpModalShown && <PopUpModal template={'home'} location={'popup'} />}
			{/* <NewsModal newsData /> Viejo popup*/}
		</>
	);
}
