/** @format */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGlobal, setGlobal } from 'reactn';

import { useHistory } from 'react-router-dom';

import { Row, Col, Form } from 'antd';

import Loading from '../../../../components/Commons/Loading';
import MetaDescription from '../../../../components/Commons/MetaDescription';
import TitleBox from '../../../../components/TitleBox';
import Countdown from '../../../../components/Countdown';

import PaymentDetailService from '../../components/PaymentDetailService';
import StepperService from '../../components/StepperService';

import ButtonRetirement from './components/ButtonRetirement';
import LoadingService from './components/LoadingService';

import PersonalData from '../retirement/components/PersonalData';

import { GetUserData } from '../../../../components/Commons/Hooks/Functions/GetUserData';

import { GetOrder } from './services';

import './style.scss';

export default function ServiceRetirement() {
	const { t } = useTranslation('global');
	const history = useHistory();
	const [form] = Form.useForm();
	const [isRetirementData, setRetirementData] = useGlobal('retirementData');
	const [isUserAddress] = useState(JSON.parse(localStorage.getItem('locationStore')));
	const [isUserEmail] = useState(localStorage.getItem('userEmail'));
	const [personalData, setPersonalData] = useState({});
	const [billing, setBilling] = useState({
		modal: false,
		check: false,
	});
	const [isMexForm] = useGlobal('mexForm');
	const [isVisible, setVisible] = useState(false);

	const handleSubmitRetirementService = async (item) => {
		setGlobal({ retirementServiceButtonLoading: true });
		setVisible(true);
		await GetOrder(isRetirementData, isUserAddress, item, billing.check, isMexForm).then((response) => {
			setGlobal({ retirementServiceButtonLoading: false });
			setVisible(false);
			if (response) {
				localStorage.setItem('paymentData', JSON.stringify(response));
				return history.push('/checkout/payment');
			}
		});
	};

	const handlePersonalForm = (name, item) => {
		setPersonalData((previousState) => ({
			...previousState,
			[name]: item,
		}));
	};

	useEffect(() => {
		let info = JSON.parse(localStorage.getItem('retirementData'));
		const userCheckout = JSON.parse(localStorage.getItem('info_check'));
		form.setFieldsValue({
			...userCheckout,
			cologne_personal: userCheckout?.colony,
			comune_personal: userCheckout?.commune,
			street_name_personal: userCheckout?.street,
			street_number_personal: userCheckout?.street_number,
			city_personal: userCheckout?.city,
			province_personal: userCheckout?.province,
			postal_code_personal: userCheckout?.zip_code,
			between_street_personal: userCheckout?.between_street,
			comune: isUserAddress.commune,
			street_name: isUserAddress.street,
			email: isUserEmail ? isUserEmail : userCheckout?.email,
		});
		setPersonalData({
			...userCheckout,
			firstName: userCheckout?.first_name,
			lastName: userCheckout?.last_name,
			cologne: userCheckout?.colony,
			comune: userCheckout?.commune,
			street: userCheckout?.street,
			street_number: userCheckout?.street_number,
			city: userCheckout?.city,
			province: userCheckout?.province,
			postal_code: userCheckout?.zip_code,
			between_street: userCheckout?.between_street,
		});
		setRetirementData(info);
		if (isUserEmail) {
			GetUserData(isUserEmail).then((response) => form.setFieldsValue(response));
		}
	}, [form, isUserEmail, isUserAddress, setRetirementData]);

	if (isRetirementData) {
		return (
			<>
				<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
				<div className="ph-retirement-service-global-container">
					<div className="ph-retirement-service-main-container">
						<StepperService step={0} />
						<div className="ph-retirement-service-titlebox-countdown-container">
							<TitleBox image={'pharol-tracking-search'} title={'Entrega'} checkout={true} />
							<Countdown service={isRetirementData.services[0]} />
						</div>
						<Form onFinish={handleSubmitRetirementService} name="retirement_form" form={form}>
							<Row>
								<Col xs={24} sm={24} md={24} lg={17} xl={17}>
									<PersonalData form={form} personalData={personalData} billing={billing} setBilling={setBilling} handlePersonalForm={handlePersonalForm} />
								</Col>
								<Col xs={24} sm={24} md={24} lg={7} xl={7} className="ph-retirement-service-box-global-container">
									<div className="ph-retirement-service-box-main-container">
										<PaymentDetailService item={isRetirementData} />
										<ButtonRetirement item={isRetirementData} />
									</div>
								</Col>
							</Row>
						</Form>
					</div>
				</div>
				<LoadingService visible={isVisible} />
			</>
		);
	} else {
		return <Loading />;
	}
}
