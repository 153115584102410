/** @format */

import axios from 'axios'

import Notify from '../../../../components/Commons/Notify'

import {
	ENV_COUNTRY,
	ENV_FUNCTION_CLAIM,
} from '../../../../components/Commons/Hooks/Variables/Enviroment'

export async function ServiceComplaintBookRequest(item) {
	return await axios({
		method: 'POST',
		url: `${ENV_FUNCTION_CLAIM}/create-claim`,
		data: {
			...item,
			country: ENV_COUNTRY,
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				return response.data.data
			} else {
				Notify({
					title: `Advertencia:`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Problemas al enviar Reclamo.`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				})
			}
		})
		.catch(() => {
			Notify({
				title: `Error Servicio`,
				colorTitle: 'ph-main-notification-error-title',
				description: `Verifique su conexión a Internet.`,
				time: 3,
				image: 'error',
				placement: 'topRight',
			})
		})
}
