/** @format */

import { useCallback } from 'react'

export function BagValidateDelivery() {
	const handleCheckRetirement = useCallback((cart) => {
		let validator = {}
		let pharmacyIndex
		cart.forEach((item) => {
			if (pharmacyIndex !== item.pharmacy) {
				const filter = cart.filter((element) => {
					if (element.pharmacy === item.pharmacy) return element
					else return false
				})
				const deliverySelected = filter.some((element) => !element.type_retirement)
				validator = { ...validator, [item.pharmacy]: { info: filter, deliveryStatus: !deliverySelected, type_retirement: !deliverySelected ? item.type_retirement : null } }
			}
			pharmacyIndex = item.pharmacy
		})
		return validator
	}, [])

	return {
		handleCheckRetirement,
	}
}
