/** @format */

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ArrayPharmacyProductAdapter } from '../../../../../../_config/adapters/google-tag-manager/array-pharmacy-product.adapter';
import { Events } from '../../../../../../_config/adapters/google-tag-manager/events.constant';
import { BagConsumer } from '../../../../../../components/Commons/Context/Bag';
import { GlobalConsumer } from '../../../../../../components/Commons/Context/Global';
import { GetUserData } from '../../../../../../components/Commons/Hooks/Functions/GetUserData';
import { ENV_COUNTRY } from '../../../../../../components/Commons/Hooks/Variables/Enviroment';
import CustomButton from '../../../../../../v2-components/Antd/Button';
import { handleCheckDelivery } from '../../../../functions/BagCalculation/components/BagCheckDelivery';
import { handleDispatch } from '../../../../functions/BagCalculation/components/BagDispatch';
import { handleLocals } from '../../../../functions/BagCalculation/components/BagLocals';
import { handleNotification } from '../../../../functions/BagCalculation/components/BagNotification';
import { handleStock } from '../../../../functions/BagCalculation/components/BagStock';
import { Calculation } from '../../../../functions/BagCalculation/services';
import { handleCountPharmacies } from '../../../../functions/BagCountPharmacies';
import { BagValidateDelivery } from '../../../../functions/BagValidateDelivery';
import BagModalCalculate from '../../../BagModalCalculate';
import { Country } from './country';
import './style.scss';

export default function BagCalculate(props) {
	let history = useHistory();

	const { isBag } = GlobalConsumer();
	const { isBagRecipe, setBagRecipeModal, setBagData, setBagCalculateModal, isBagRequest, isBagRecipeChecked, setFirstStepError } = BagConsumer();

	const [isDisable, setDisable] = useState(false);
	const [customerCoverageType, setCustomerCoverageType] = useState('particular');

	const { handleCheckRetirement } = BagValidateDelivery();

	const handleVerifyVoucherFields = () => {
		const fields = props.cartForm.getFieldsValue(true);
		for (const clave in fields) {
			if (!fields[clave]) delete fields[clave];
		}
		return fields;
	};

	const handleMainCalculate = async () => {
		const cart = JSON.parse(localStorage.getItem('cart'));
		const location = JSON.parse(localStorage.getItem('locationStore'));
		const email = localStorage.getItem('userEmail');
		const userToken = localStorage.getItem('userToken');
		const locals = await handleLocals(isBag);
		let products = await handleCheckDelivery(cart);
		const checkDelivery = Object.values(handleCheckRetirement(cart));
		let deliveryValid;

		checkDelivery.forEach((item) => {
			if (!item.deliveryStatus) deliveryValid = item;
		});
		if (deliveryValid?.deliveryStatus === false) {
			return handleNotification('Error', 'error', `¡Seleccione un tipo de entrega para la farmacia ${deliveryValid.info[0].pharmacy}!`);
		}

		if (!isBagRecipeChecked) {
			if (ENV_COUNTRY === 'AR') {
				setFirstStepError(true);
				return handleNotification(
					'Error',
					'error',
					`Es necesario para continuar, que confirmes que al retirar tus productos harás entrega de las recetas requeridas.`,
				);
			}
		}

		console.log('location', location);
		if (location?.default === true) {
			return handleNotification('Error', 'error', `¡Debe agregar su dirección para poder avanzar!`);
		}

		setBagCalculateModal(true);
		setDisable(true);

		let userData;
		if (email) userData = await GetUserData(email);

		const voucherPharmacies = handleVerifyVoucherFields();

		await Calculation(products, location, email, userToken, voucherPharmacies, locals, 'farmacia', userData, true, handleCountPharmacies()).then(
			async (response) => {
				setBagCalculateModal(false);
				setDisable(false);
				if (response) {
					setBagData(response);
					if (response.valid_code === false) return handleNotification('Error', 'error', '¡El cupón ingresado no es válido. Intenta nuevamente!');

					const checkStock = handleStock(response.products);
					if (checkStock) {
						return handleNotification('Error', 'error', 'Algunos productos estan sin stock.');
					}
					if (location.default === true) {
						return handleNotification('Error', 'error', 'Debe agregar la dirección de entrega para continuar.');
					}

					const checkDispatch = await handleDispatch(response.locals_group);

					if (!checkDispatch) return;

					if (response.voucher_code) localStorage.setItem('voucherCode', response.voucher_code);
					localStorage.setItem('retirementData', JSON.stringify(response));
					localStorage.setItem('datePay', JSON.stringify(moment().add(5, 'minutes')));
					ArrayPharmacyProductAdapter({ event: Events.begin_checkout, products: cart });
					return history.push('/checkout/retirement');
				}
			},
		);
	};

	useEffect(() => {
		localStorage.getItem('customerCoverage') && setCustomerCoverageType(JSON.parse(localStorage.getItem('customerCoverage')).coverage_type); //PARCHE FABO: Rompia por que no existia 'customerCoverage' ahora se valida antes.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('customerCoverage'), isBagRecipe]);

	return (
		<>
			{Country[ENV_COUNTRY].handleValidateButton(customerCoverageType, isBagRecipe) === true ? (
				<CustomButton className={'button-primary'} onClick={() => setBagRecipeModal(true)} disabled={isBagRequest}>
					SUBIR RECETAS
				</CustomButton>
			) : (
				<CustomButton className={'button-primary'} onClick={() => handleMainCalculate()} disabled={isDisable || isBagRequest}>
					{!isDisable ? 'SIGUIENTE' : 'ESPERE...'}
				</CustomButton>
			)}
			<BagModalCalculate />
		</>
	);
}
