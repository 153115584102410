/** @format */

import axios from 'axios';

import Notify from '../../../../components/Commons/Notify';

import { ENV_FUNCTION_CLAIM } from '../../../../components/Commons/Hooks/Variables/Enviroment';

export async function serviceGetMessages(item) {
	return await axios({
		method: 'GET',
		url: `${ENV_FUNCTION_CLAIM}/messages/${item}`,
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				return response.data.data;
			} else {
				Notify({
					title: `Problemas de Conexión`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Revisa tu conexión o ponte en contacto con nosotros.`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			}
		})
		.catch((error) => {
			Notify({
				title: `Problemas de Conexión`,
				colorTitle: 'ph-main-notification-error-title',
				description: `Revisa tu conexión o ponte en contacto con nosotros.`,
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
		});
}

export async function serviceSendMessage(item) {
	return await axios({
		method: 'POST',
		url: `${ENV_FUNCTION_CLAIM}/messages/user-response`,
		data: item,
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				return response.data.data;
			} else {
				Notify({
					title: `Error: ${response.data.error}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Problemas con el servicio:   ${response.data.message}`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			}
		})
		.catch((error) => {
			Notify({
				title: `Problemas de Conexión`,
				colorTitle: 'ph-main-notification-error-title',
				description: `Revisa tu conexión o ponte en contacto con nosotros.`,
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
		});
}
