/** @format */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Col, Button } from 'antd';

import { UnlockOutlined } from '@ant-design/icons';

import Loading from '../../../../components/Commons/Loading';
import Spacer from '../../../../components/Commons/Spacer';
import MetaDescription from '../../../../components/Commons/MetaDescription';

import { formatPrice } from '../../../../components/Commons/Hooks/Functions/FormatPrice';

import TitleBox from '../../../../components/TitleBox';
import Stepper from '../../../../components/Steper';
import Countdown from '../../../../components/Countdown';

import PaymentDetail from '../../components/PaymentDetail';
import CardOnDemand from '../../components/CardOnDemand';

import Product from './components/Product';
import MapRoutes from './components/MapRoutes';

import servicesDetail from './services';

import './style.scss';

export default function Detail() {
	const { t } = useTranslation('global');
	const [isUserAddress] = useState(JSON.parse(localStorage.getItem('locationStore')));
	const [isDetailData] = useState(localStorage.getItem('recipeData') ? JSON.parse(localStorage.getItem('recipeData')) : JSON.parse(localStorage.getItem('detailData')));
	const [isDetailVerifiedData, setDetailVerifiedData] = useState(null);
	const [isMobile, setMobile] = useState(false);
	const [isDateHomeDelivery, setDateHomeDelivery] = useState(null);

	useEffect(() => {
		if (isDetailData) {
			if (window.innerWidth < 992) {
				setMobile(true);
			}
			servicesDetail.getOrder(isDetailData).then((response) => {
				if (response) {
					setDetailVerifiedData(response);
					response.groups_products.forEach((item) => {
						if (item.type_calculation === 'normal' || item.type_calculation === 'minsal') {
							setDateHomeDelivery(item.schedule_selected);
						}
					});
					servicesDetail
						.userActiveCampaingService({
							email: response.order.info.email,
							first_name: response.order.info.first_name,
							last_name: response.order.info.last_name,
						})
						.then(() => {});
				}
			});
		} else {
			window.location.href = '/';
		}
	}, [isDetailData]);

	const handlePaymentDetail = async () => {
		localStorage.setItem('paymentData', JSON.stringify(isDetailVerifiedData.order._id));
		return (window.location.href = '/checkout/payment');
	};

	if (!isDetailVerifiedData) {
		return <Loading />;
	} else {
		return (
			<>
				<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
				<div className="ph-detail-global-container">
					<div className="ph-detail-main-container">
						<Stepper step={3} />
						<div className="ph-detail-titlebox-countdown-container">
							<TitleBox image={'pharol-location'} title={'Resumen'} checkout={true} />
							<Countdown />
						</div>
						<Row>
							<Col xs={24} sm={24} md={24} lg={17} xl={17}>
								{isDetailVerifiedData.order.info.type_retirement === 'farmacia' ? (
									<div className="ph-detail-list-main-container">
										{isDetailVerifiedData.groups_products.map((item, index) => (
											<div className="ph-detail-list-container" key={index}>
												<h3 className="ph-detail-list-container-pharmacy-title">Farmacias {item.pharmacy}:</h3>
												<Row>
													<Col xs={24} sm={24} md={11} lg={11} xl={11} className="ph-detail-list-map-container">
														<MapRoutes
															pharmacyLocation={{
																lat: item.location.lat,
																lng: item.location.lng,
															}}
															userLocation={isUserAddress}
															center={[item.location.lat, item.location.lng]}
															zoom={14}
															title={'User waypoints'}
															description={'Route.'}
														/>
														<h4 className="ph-detail-list-map-description">
															Local: {item.commune}, {item.region}, {item.country} - {item.name_local} - {item.direction}, {item.distance_text}, {item.duration}
														</h4>
													</Col>
													<Col xs={24} sm={24} md={13} lg={13} xl={13}>
														{item.schedule_selected !== 'on_demand on_demand' && (
															<>
																<h4 className="ph-detail-list-detail-title">Horario Seleccionaado:</h4>
																<h5 className="ph-detail-list-detail-subtitle">{item.schedule_selected}</h5>
															</>
														)}
														<h3 className="ph-detail-list-detail-description">Productos:</h3>
														<Product product={item.products} />
													</Col>
												</Row>
												<h3 className="ph-detail-list-total-pharmacy">
													Total a pagar en Farmacias {item.name_pharmacy}: <span className="ph-detail-list-total-pharmacy-subtitle">{formatPrice(item.payment.total)}</span>
												</h3>
											</div>
										))}
									</div>
								) : (
									<div className="ph-detail-list-delivery-container">
										<Row>
											<Col xs={24} sm={24} md={11} lg={11} xl={11} className="ph-detail-list-map-container">
												<MapRoutes
													multiple={true}
													pharmacyLocation={isDetailVerifiedData.groups_products}
													userLocation={isUserAddress}
													center={[isUserAddress.lat, isUserAddress.lng]}
													zoom={14}
													title={'User waypoints'}
													description={'Route.'}
												/>
												<h3 className="ph-detail-list-map-description">{isDetailVerifiedData.order.info.location}</h3>
											</Col>
											<Col xs={24} sm={24} md={13} lg={13} xl={13}>
												{isDateHomeDelivery && (
													<>
														<h4 className="ph-detail-list-detail-title">Horario Seleccionado:</h4>
														<h5 className="ph-detail-list-detail-subtitle">{isDateHomeDelivery}</h5>
													</>
												)}
												<h3 className="ph-detail-list-detail-description">Productos:</h3>
												<Product product={isDetailVerifiedData.order.products} typeRetirement={true} />
											</Col>
										</Row>
									</div>
								)}
							</Col>
							<Col xs={24} sm={24} md={24} lg={7} xl={7} className="ph-detail-box-global-container">
								<CardOnDemand />
								<div className="ph-detail-box-main-container">
									<PaymentDetail address={isUserAddress} item={isDetailData} />
									<div className="ph-checkout-detail-button-main-container">
										{isMobile && (
											<>
												<div>
													<h3 className="ph-checkout-detail-button-mobile-price-main-title">Subtotal:</h3>
													<h3 className="ph-checkout-detail-button-mobile-price-title">
														<span className="ph-checkout-detail-button-mobile-price-description">$</span>
														{isDetailData.total}
													</h3>
												</div>
												<Spacer />
											</>
										)}
										<div className="ph-checkout-detail-button-container">
											<Button
												icon={<UnlockOutlined />}
												className="ph-checkout-detail-button-1"
												onClick={() => {
													handlePaymentDetail();
												}}
											/>
											<Button
												className="ph-checkout-detail-button-2"
												htmlType={'submit'}
												onClick={() => {
													handlePaymentDetail();
												}}
											>
												SIGUIENTE
											</Button>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</>
		);
	}
}
