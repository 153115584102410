/** @format */

import React, { Component } from 'react'

import './style.scss'

export default class ListInfoDetail extends Component {
	render() {
		return (
			<>
				<div className='ph-list-info-detail-profile-cardinfo-container'>
					<h3 className='ph-list-info-detail-profile-cardinfo-title'>{this.props.title}</h3>
					<h3 className='ph-list-info-detail-profile-cardinfo-description'>
						{this.props.info ? (
							<>{this.props.description}</>
						) : (
							<span className='ph-list-info-detail-profile-cardinfo-inner-description'>
								Dato no especificado.
							</span>
						)}
						{this.props.info}
					</h3>
				</div>
			</>
		)
	}
}
