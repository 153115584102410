/** @format */

export const ENV_DOPPLER_ENVIRONMENT = `${process.env.REACT_APP_ENVIRONMENT}`
export const ENV_CHAT_COUNTRY = `${process.env.REACT_APP_CHAT_COUNTRY}`
export const ENV_COUNTRY = `${process.env.REACT_APP___COUNTRY}`
export const ENV_ROOT_PHARMACY = `${process.env.REACT_APP_ROOT_PHARMACY}`
export const ENV_ADDRESS = `${process.env.REACT_APP_ADDRESS}`
export const ENV_COMMON = `${process.env.REACT_APP_FUNCTION_FRONT_COMMON}`
export const ENV_CALCULATION = `${process.env.REACT_APP_FUNCTION_CALCULATION}`
export const ENV_CHECKOUT = `${process.env.REACT_APP_FUNCTION_CHECKOUT}`
export const ENV_SCHEDULE = `${process.env.REACT_APP_FUNCTION_SCHEDULE}`
export const ENV_TRANSACTION = `${process.env.REACT_APP_FUNCTION_SUCCESS_TRANSACTION}`
export const ENV_TRACKING = `${process.env.REACT_APP_FUNCTION_PANEL_TRACKING}`
export const ENV_USER = `${process.env.REACT_APP_FUNCTION_USER}`
export const ENV_BASE = `${process.env.REACT_APP_BASE}`
export const ENV_EMAIL = `${process.env.REACT_APP_FUNCTION_EMAIL}`
export const ENV_ELEC_PRESCRIPTION = `${process.env.REACT_APP_FUNCTION_ELECTRONIC_PRESCRIPTION}`
export const ENV_PUNTO_PAGO = `${process.env.REACT_APP_FUNCTION_PUNTO_PAGO}`
export const ENV_COLMENA = `${process.env.REACT_APP_FUNCTION_COLMENA}`
export const ENV_CAMPAIGN = `${process.env.REACT_APP_FUNCTION_ACTIVE_CAMPAIGN}`
export const ENV_GOOGLE_TAG = `${process.env.REACT_APP_GOOGLE_TAG_MANAGER}`
export const ENV_GOOGLE_ANALITYCS = `${process.env.REACT_APP_GOOGLE_ANALITYCS}`
export const ENV_GOOGLE_ANALITYCS_4 = `${process.env.REACT_APP_GOOGLE_ANALITYCS_4}`
export const ENV_FACEBOOK_PIXEL = `${process.env.REACT_APP_FACEBOOK_PIXEL}`
export const ENV_FACEBOOK_DOMAIN_VERIFICATION = `${process.env.REACT_APP_FACEBOOK_DOMAIN_VERIFICATION}`
export const ENV_GOOGLE_SITE_VERIFICATION = `${process.env.REACT_APP_GOOGLE_SITE_VERIFICATION}`
export const ENV_ISO_CURRENCY = `${process.env.REACT_APP_ISO_CURRENCY}`
export const ENV_BLOG = `${process.env.REACT_APP_BLOG_LINK}`
export const ENV_PBM = `${process.env.REACT_APP_FUNCTION_PBM}`
export const ENV_IMG = `${process.env.REACT_APP_GENERAL_STORAGE_IMAGES}`
export const ENV_QUOTATION = `${process.env.REACT_APP_FUNCTION_QUOTATION}`
export const ENV_FAVORITES = `${process.env.REACT_APP_FUNCTION_FAVORITE_PRODUCTS}`
export const ENV_SERVICES = `${process.env.REACT_APP_FUNCTION_SERVICES}`
export const ENV_IMG_BUCKET = `${process.env.REACT_APP_GENERAL_STORAGE_IMAGES}`
export const ENV_IMG_HOME = `${process.env.REACT_APP_GENERAL_STORAGE_IMAGES}/react-home`
export const ENV_IMG_HEADER = `${process.env.REACT_APP_GENERAL_STORAGE_IMAGES}/react-header`
export const ENV_IMG_FOOTER = `${process.env.REACT_APP_GENERAL_STORAGE_IMAGES}/react-footer`
export const ENV_IMG_SOCIAL = `${process.env.REACT_APP_GENERAL_STORAGE_IMAGES}/redes-sociales`
export const ENV_IMG_DELIVERY = `${process.env.REACT_APP_GENERAL_STORAGE_IMAGES}/react-type-delibery`
export const ENV_IMG_PRODUCT = `${process.env.REACT_APP_GENERAL_STORAGE_IMAGES}/react-product`
export const ENV_IMG_CONTACT = `${process.env.REACT_APP_GENERAL_STORAGE_IMAGES}/react-contact`
export const ENV_IMG_STATIC = `${process.env.REACT_APP_GENERAL_STORAGE_IMAGES}/react-page-static`
export const ENV_IMG_ROUTE = `${process.env.REACT_APP_IMAGE_ROUTE}`
export const ENV_FACEBOOK_LOGIN = `${process.env.REACT_APP_FACEBOOK_LOGIN}`
export const ENV_GOOGLE_LOGIN = `${process.env.REACT_APP_GOOGLE_LOGIN}`
export const ENV_PHAROLGO_TRACKING = `${process.env.REACT_APP_PHAROLGO_TRACKING}`
export const ENV_PHAROLGO = `${process.env.REACT_APP_FUNCTION_PHAROL_GO}`
export const ENV_AGREEMENT = `${process.env.REACT_APP_FUNCTION_AGREEMENT}`
export const ENV_FUNCTION_CLAIM = `${process.env.REACT_APP_FUNCTION_CLAIM}`
export const ENV_PIXELS_MANAGEMENT = `${process.env.REACT_APP_FUNCTION_PIXEL}`
export const ENV_HOTJAR_ID = `${process.env.REACT_APP_HOTJAR_ID}`
export const ENV_ELASTIC_KEY = process.env[`REACT_APP_ELASTIC_${ENV_COUNTRY}_KEY`]
export const ENV_ELASTIC_ENVIROMENT = process.env[`REACT_APP_ELASTIC_${ENV_COUNTRY}_PROJECT`]
export const ENV_ELASTIC_INDEX = process.env[`REACT_APP_ELASTIC_${ENV_COUNTRY}_APP_PRODUCTS`]
export const ENV_ELASTIC_INDEX_SEARCH = process.env[`REACT_APP_ELASTIC_${ENV_COUNTRY}_APP_SEARCH_PRODUCTS`]
// REVISAR export const ENV_ELASTIC_HIGH_INDEX = process.env[`REACT_APP_ELASTIC_${ENV_COUNTRY}_APP_PRODUCTS_HIGH`]
// REVISAR export const ENV_ELASTIC_HIGH_INDEX_SEARCH = process.env[`REACT_APP_ELASTIC_${ENV_COUNTRY}_APP_SEARCH_PRODUCTS_HIGH`]
// REVISAR export const ENV_ELASTIC_LOW_INDEX = process.env[`REACT_APP_ELASTIC_${ENV_COUNTRY}_APP_PRODUCTS_LOW`]
// REVISAR export const ENV_ELASTIC_LOW_INDEX_SEARCH = process.env[`REACT_APP_ELASTIC_${ENV_COUNTRY}_APP_SEARCH_PRODUCTS_LOW`]
