/** @format */

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import Images from '../../../Images';
import { ModalTypeRecipes } from '../../../Modals/ModalTypeRecipes';

import { Enviroment } from '../../../../../_config/constants/enviroment-v2.constant';
import { ENV_IMG_ROUTE } from '../../../Hooks/Variables/Enviroment';

import { linkToDetailProduct } from '../../../Hooks/Functions/LinkToDetailProduct';

import Logos from '../Logos';

import { FavoriteActions } from '../../../Favorites/Components';
import SquareProductImage from '../../../../../v2-components/SquareProductImage';
import { imagePrescription } from './constant/image-prescription.constant';
import './style.scss';

export default function ProductImage(props) {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleTypeRecipes = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	return (
		<>
			<div className="ph-product-image-main-container">
				{props.detail.prescryption_type && (props.detail.master_category === 'Medicamentos' || props.detail.slug_master_category === 'medicamentos') && (
					<div onClick={handleTypeRecipes}>
						<Images
							classContent={`ph-product-type-prescription-image-icon-container ${props.classPrescriptionIcon ? props.classPrescriptionIcon : ''}`}
							className={'ph-product-type-prescription-image-icon'}
							default={imagePrescription[Enviroment.COUNTRY][props.detail.prescryption_type]}
							title={props.detail.editable_name}
						/>
					</div>
				)}
				{!props.alternatives && (
					<>
						<FavoriteActions item={props.detail} listProductFavorite={props.listProductFavorite} />
						<Logos
							promotionImgClass={'ph-product-promotion-image'}
							cpImgClass={'ph-product-promotion-cp-image'}
							cenabastImgClass={'ph-product-promotion-cenabast-image'}
							detail={props.detail}
						/>
					</>
				)}
				{props.detail.promotion_price && !props.detail.pharmacy && (
					<>
						<Logos promotionImgClass={'ph-product-promotion-image'} detail={props.detail} />
					</>
				)}
				<div className="ph-product-image-container">
					<NavLink to={linkToDetailProduct(props.detail)}>
						<SquareProductImage
							className={'ph-product-image'}
							default={`${ENV_IMG_ROUTE}/products/${Enviroment.COUNTRY}/200x200/${props.detail.id_pharol}.png`}
							alt={props.detail.editable_name}
							title={props.detail.editable_name}
						/>
					</NavLink>
				</div>
			</div>
			<ModalTypeRecipes isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} />
		</>
	);
}
