/**
 * Con links internos
 */
import React, { useEffect, useState, Fragment } from 'react';
import { Services } from './services';
import CustomCarousel from '../Antd/Carousel';
import './style.scss';

export default function Box(props) {
	const [box, setBox] = useState(null);
	const [isLoading, setLoading] = useState(true);

	console.log('props', props);
	useEffect(() => {
		new Services().getTempplate({ template: props.template, location: props.location }).then((response) => {
			console.log('box', response);
			if (response.length > 0) {
				setBox(response);
				setLoading(false);
			}
		});
	}, [props.template, props.location]);

	if (isLoading) {
		return Fragment;
	}

	return (
		<>
			<div className="box-container">
				<CustomCarousel autoplay={true} >
					{box.map((item, index) => (
						<div key={index} onClick={() => window.open(item.link)}>
							<img src={item.image} alt={item.template} className="ph-popup-v2-card-image" />
						</div>
					))}
				</CustomCarousel>
			</div>
		</>
	);
}
