/** @format */

import axios from 'axios';

import Notify from '../../../Notify';

import { ENV_USER } from '../../Variables/Enviroment';

export const GetUserGeneralInfo = async (item) => {
	let returnResponse;
	await axios({
		method: 'POST',
		url: `${ENV_USER}/user/find-user`,
		data: {
			email: item,
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data;
			} else {
				Notify({
					title: `Error: ${response.data.statusCode}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Problemas con el servicio:   ${response.data.message}`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			}
		})
		.catch(() => {
			Notify({
				title: `Problemas de Conexión`,
				colorTitle: 'ph-main-notification-error-title',
				description: `Revisa tu conexión o ponte en contacto con nosotros.`,
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
};
