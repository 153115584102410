/** @format */

import React from 'react';

import { Col } from 'antd';

import Images from '../../../../../../../../../../components/Commons/Images';

import './style.scss';

export default function ProductDispatchMediclic() {
	return (
		<Col span={24} className="ph-product-detail-quote-mediclic-container">
			<a className="ph-product-detail-quote-mediclic-inner-container" href={'https://consultas.mediclic.cl/Registro?RPromo=PHAROLCL&RPais=Chile'} target="_blank" rel="noopener noreferrer">
				<span className="ph-product-detail-quote-mediclic-title">¿No tienes Receta?</span>
				<span className="ph-product-detail-quote-mediclic-subtitle">Antes de medicarte, consulta con un médico en</span>
				<Images
					classContent={'ph-product-detail-quote-mediclic-image-container'}
					classImage={'ph-product-detail-quote-mediclic-image'}
					default={`https://cdn.shortpixel.ai/client/to_webp,q_lossy,ret_img/https://www.mediclic.cl/wp-content/themes/mediclic/img/mediclic-color.png`}
					title={'Mediclic'}
					alt={'Mediclic'}
				/>
			</a>
		</Col>
	);
}
