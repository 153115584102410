/** @format */

import React, { useState } from 'react'

import Select from 'antd/lib/select'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Button from 'antd/lib/button'

import Images from '../../../../components/Commons/Images'

import PharmacyScheduleFunction from '../../../../components/HooksCheckout/Functions/PharmacySchedule'

import TimeLocal from './components/TimeLocal'

import './style.scss'

const { Option } = Select

export default function PharmacySelect(props) {
	const [isDate, setDate] = useState(null)
	const [isTime, setTime] = useState(null)

	const handleSelectLocal = async (item, data) => {
		await PharmacyScheduleFunction(item, data, props.order).then((response) => {
			if (response) {
				props.order.info.pharmacy.forEach((element) => {
					if (item === element.pharmacy) {
						setDate(response)
					}
				})
			}
		})
	}

	const handleDateSelected = (item) => {
		props.setInfoSelected((pvSt) => ({
			...pvSt,
			date: !!item ? item : '',
		}))
		isDate.forEach((element) => {
			if (item === element.date) {
				setTime(element.timers)
			}
		})
	}

	const handleTimeSelected = (item) => {
		props.setInfoSelected((pvSt) => ({
			...pvSt,
			time: !!item ? item : '',
		}))
	}

	return (
		<>
			{props.order.info.pharmacy.map((item, index) => (
				<div className='ph-objected-recipe-pharmacy-list-global-container' key={index}>
					<Row className='ph-recipe-objeted-pharmacy-list-main-container'>
						<Col xs={18}>
							<div className='ph-recipe-objeted-pharmacy-list-container'>
								<Images
									classContent={'ph-recipe-objeted-pharmacy-image-container'}
									classImage={'ph-recipe-objeted-pharmacy-image'}
									default={item.image}
									alt={item.name_editable}
									title={item.name_editable}
								/>
								<h3 className='ph-recipe-objeted-pharmacy-list-title'>
									Farmacia {item.name_pharmacy}
								</h3>
							</div>
							<h3 className='ph-recipe-objeted-pharmacy-list-description'>
								Farmacias disponibles para su retiro...
							</h3>
						</Col>
						<Col xs={6} className='ph-recipe-objeted-pharmacy-btn-container'>
							<Button
								onClick={() => handleSelectLocal(item.pharmacy, item.local)}
								className='ph-recipe-objeted-pharmacy-btn'>
								Seleccionar
							</Button>
						</Col>
						{item.schedule.map((element, i) => (
							<TimeLocal element={element} key={i} />
						))}
					</Row>
					<div className='ph-recipe-objeted-pharmacy-inputs-container'>
						{isDate && (
							<Select
								className='ph-recipe-objeted-pharmacy-local-selector'
								size='large'
								allowClear
								placeholder='Selecciona una Fecha'
								onChange={(value) => handleDateSelected(value, item)}>
								{isDate.map((element, i) => (
									<Option value={element.date} key={i}>
										{element.date}
									</Option>
								))}
							</Select>
						)}
						{isTime && (
							<Select
								className='ph-recipe-objeted-pharmacy-local-selector'
								size='large'
								allowClear
								placeholder='Selecciona el Horario'
								onChange={(value) => handleTimeSelected(value, item)}>
								{isTime.map((element, i) => (
									<Option value={`${element.start} - ${element.finish}`} key={i}>
										{`${element.start} - ${element.finish}`}
									</Option>
								))}
							</Select>
						)}
					</div>
				</div>
			))}
		</>
	)
}
