import { Enviroment } from '../constants/enviroment-v2.constant';
export const Translation = {
	main: {
		company: Enviroment.ROOT_NAME,
		metaTitle: `${Enviroment.ROOT_NAME} Online`,
		metaContent: Enviroment.ROOT_NAME,
		email: 'contacto@' + Enviroment.ROOT_PHARMACY + '.cl',
		baseURL: 'pharol.cl',
	},
	header: {
		title: 'header CL',
	},
	welcome: {
		title: 'Te damos la bienvenida a ' + Enviroment.ROOT_NAME,
		text: '¿Sabías que iniciando sesión tendrás descuentos exclusivos en despachos y en productos con convenio?',
	},
	home: {
		featured: 'Productos destacados',
		featuredCategory: 'Categorías más buscadas',
		featuredExclusive: 'Ofertas Exclusivas',
		featuredBannerBox: 'Servicios en Pharol',
		featuredPharmacies: 'Farmacias asociadas',
	},
	footer: {
		title: 'fotter CL',
	},
	laboratories: {
		title: 'laboratories CL',
	},
	contact: {
		title: 'retirement CL',
	},
	cart: {
		title: 'cart CL',
		modalTitle: 'Procesando tu Bolsa de Compras…',
		recipeAgreeTitle:
			'La cantidad de unidades quedará sujeta a lo indicado en la receta médica. La receta será revisada y validada por un químico farmacéutico una vez realizada la compra y deberá ser entregada al recibir el producto en caso de productos con receta retenida.',
		recipeMainTitle: 'Sube tus Recetas Médicas',
		delivery: 'Despacho',
	},
	tracking: {
		title: '¡Rastrea tu Orden!',
		subTitle: 'Ingresa tu RUT y N° de Orden:',
		errorDescription: 'RUT ingresado y RUT de la orden no coinciden.',
		personalId: 'RUT:',
	},
	register: {
		TyCLink: 'policies',
		itemDescription1: 'Recibe tus pedidos a domicilio con tarifas preferenciales.',
		itemDescription2: 'Guarda tus productos de uso frecuente en “Mis Favoritos” para cotizar y comparar de forma rápida y simple.',
		itemDescription3: 'Accede a nuestras promociones y ofertas de primera mano.',
		itemDescription4: 'Obtén mensualmente un cupón de descuento de libre disposición.',
		itemDescription5: 'Ingresa tus direcciones más frecuentes para facilitar la búsqueda y entrega.',
		alertDescription: '¡Estás a tan solo un paso de disfrutar de todos nuestros beneficios! Revisa tu correo electrónico, para activar tu cuenta.',
	},
	prescryptionType: {
		NP: 'Venta Directa',
		WP: 'Presentación de Receta Médica',
		APR: 'Presentación de Receta Médica',
		MRE: 'Presentación de Receta Médica',
		RMRSCS: 'Receta Médica Retenida',
		RMRCCS: 'Receta Médica Cheque',
	},
	retirement: {
		title: 'retirement CL',
	},
	error: {
		title: 'En estos momentos no tenemos stock del producto que buscas',
	},
	modal: {
		typeRecipes: {
			title: {
				NP: 'Venta Directa',
				WP: 'Presentación de Receta Médica',
				APR: 'Presentación de Receta Médica',
				MRE: 'Presentación de Receta Médica',
				RMRSCS: 'Receta Médica Retenida',
				RMRCCS: 'Receta Médica Cheque',
			},
		},
	},
	profile: {
		backToAcountLink: 'Volver a mi cuenta',
		address: {
			description: 'No tienes Direciones Guardadas',
		},
		favorites: {
			description: 'No tienes Favoritos Guardadas',
		},
		purchase: {
			description: 'No tienes compras',
		},
		recipes: {
			description: 'No tienes Recetas Guardadas',
		},
		password: {
			description: 'Para proteger tu cuenta, cambia a menudo tu contraseña de acceso para una mayor seguridad en tus datos personales.',
		},
	},
	checkout: {
		transaction: {
			error: {
				description: 'Si tiene alguna duda contáctanos por el chat o por ',
			},
			success: {
				title: {
					success: '¡Gracias por tu compra!',
					error: 'Tu compra no pudo procesarse correctamente...',
				},
				description: 'De no recibir la orden de compra en tu correo, contáctanos por el chat o por ',
				numberOrder: 'Número de orden de compra',
			},
		},
	},
	login: {
		subtitle: 'Accede a tu cuenta y a tus beneficios',
		registerLink: 'Haz click aquí.',
	},
	address: {
		modal: {
			title: '¡Ingresa una dirección para optimizar tu búsqueda!',
			subtitle: 'Escribe y selecciona tu dirección',
			formInput: 'Ingresa calle y número...',
		},
	},
};
