/** @format */

import React, { useState, createContext, useContext } from 'react'

const AppContext = createContext()

export const BagProvider = (props) => {
	const [isBagRecipe, setBagRecipe] = useState(false)
	const [isBagRecipeChecked, setBagRecipeChecked] = useState(false)
	const [isFirstStepError, setFirstStepError] = useState(false)
	const [isBagRecipeModal, setBagRecipeModal] = useState(false)
	const [isBagRecipePreview, setBagRecipePreview] = useState(false)
	const [isBagRecipePreviewModal, setBagRecipePreviewModal] = useState(false)
	const [isBagData, setBagData] = useState(null)
	const [isBagVoucher, setBagVoucher] = useState(null)
	const [isBagRetirement, setBagRetirement] = useState(null)
	const [isBagCalculateModal, setBagCalculateModal] = useState(false)
	const [isBagRetirementPharmacies, setBagRetirementPharmacies] = useState({})
	const [isBagDeliveryPharmacies, setBagDeliveryPharmacies] = useState({})
	const [isBagTotalCart, setBagTotalCart] = useState(0)
	const [isBagFilterProducts, setBagFilterProducts] = useState([])
	const [isBagCollapse, setBagCollapse] = useState(false)
	const [isLoadingGroup, setLoadingGroup] = useState([])
	const [isBagRequest, setBagRequest] = useState(false)
	const [isElectronicPrescription, setElectronicPrescription] = useState(null)

	const value = {
		isBagRecipe,
		isBagRecipeChecked,
		isFirstStepError,
		isBagRecipeModal,
		isBagRecipePreview,
		isBagRecipePreviewModal,
		isBagData,
		isBagVoucher,
		isBagRetirement,
		isBagCalculateModal,
		isBagRetirementPharmacies,
		isBagDeliveryPharmacies,
		isBagTotalCart,
		isBagFilterProducts,
		isBagCollapse,
		isLoadingGroup,
		isBagRequest,
		isElectronicPrescription,
		setBagRecipe,
		setBagRecipeChecked,
		setFirstStepError,
		setBagRecipeModal,
		setBagRecipePreview,
		setBagRecipePreviewModal,
		setBagData,
		setBagVoucher,
		setBagRetirement,
		setBagCalculateModal,
		setBagRetirementPharmacies,
		setBagDeliveryPharmacies,
		setBagTotalCart,
		setBagFilterProducts,
		setBagCollapse,
		setLoadingGroup,
		setBagRequest,
		setElectronicPrescription,
	}
	return <AppContext.Provider value={value} {...props} />
}

export const BagConsumer = () => {
	const context = useContext(AppContext)
	if (!context) {
		throw new Error('BagConsumer debe estar dentro de proveedor AppContext')
	}
	return context
}
