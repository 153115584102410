/** @format */

import React, { useEffect, useState } from 'react';

import { setGlobal, useGlobal } from 'reactn';
import { useTranslation } from 'react-i18next';

import { GlobalConsumer } from '../../../../components/Commons/Context/Global';
import { BagConsumer } from '../../../../components/Commons/Context/Bag';

import { Row, Col, Form } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';

import MetaDescription from '../../../../components/Commons/MetaDescription';
import Notify from '../../../../components/Commons/Notify';
import Address from '../../../../components/Commons/Header/components/Address';

import CheckoutTimeLine from '../../../../components/CheckoutTimeLine';
import CheckoutGlobalTitle from '../../../../components/CheckoutGlobalTitle';

import { RemoveLocalStorage } from '../../../../components/HooksCheckout/Functions/RemoveLocalStorage';

import BagMainInfo from '../../components/BagMainInfo';
import BagMainInfoMobile from '../../components/BagMainInfoMobile';
import BagAlliesInfo from '../../components/BagAlliesInfo';
import BagProductsGroup from '../../components/BagProductsGroup';
import BagError from '../../components/BagError';
import BagBanners from '../../components/BagBanners';
import BagModalCalculate from '../../components/BagModalCalculate';

import { BagValidateDelivery } from '../../functions/BagValidateDelivery';
import { BagCalculation } from '../../functions/BagCalculation';
import BagRecipeFilter from '../../functions/BagRecipeFilter';

import BagTotal from '../../functions/BagTotal';

import ElectronicProductcs from './components/ElectronicProductcs';
import NoProducts from './components/NoProducts';
import Loading from './components/Loading';

import { BagMerge } from '../../functions/BagMergeLocals';

import { BagServiceRME, BagServiceOnDemand } from './services';

import BagAddProduct from '../../functions/BagAddProduct';

import './style.scss';

export default function BagElectronicCart(props) {
	const [isAddedCart] = useGlobal('onDemandCart');
	const { t } = useTranslation('global');

	const [isType] = useState(props.match.params.type);

	const [isLocation] = useState(JSON.parse(localStorage.getItem('locationStore')));

	const { isBag, isMobile, setBag } = GlobalConsumer();
	const {
		isBagData,
		isBagCollapse,
		isElectronicPrescription,
		setBagData,
		setBagRetirement,
		setBagCalculateModal,
		setBagRetirementPharmacies,
		setBagTotalCart,
		setElectronicPrescription,
		setBagRecipe,
		setBagFilterProducts,
	} = BagConsumer();

	const [cartForm] = Form.useForm();

	const { handleCheckRetirement } = BagValidateDelivery();
	const { handleBagTotal } = BagTotal();

	const { handleAddProduct } = BagAddProduct();

	useEffect(() => {
		RemoveLocalStorage();
	}, []);

	useEffect(() => {
		setBagCalculateModal(true);
		let mainBag = isBag;
		let requestService;
		if (isType === 'on_demand') requestService = BagServiceOnDemand;
		else requestService = BagServiceRME;
		requestService(props.match.params.id).then((response) => {
			if (response) {
				setElectronicPrescription(response);
				if (isType === 'on_demand') {
					setGlobal({ address: response.user_info.location });
					localStorage.setItem('locationStore', JSON.stringify(response.user_info.location));
				} else if (isType === 'refer_pharmacies') {
					let CheckStock = false;
					if (response.products.length > 0) {
						response.products.forEach((item) => {
							if (item.pharmacies?.length > 0) {
								item.pharmacies.forEach((element) => {
									const even = (product) => product.id === element.id;
									if (element.quantity > 9 || element.stock <= 0) {
										CheckStock = true;
									} else if (!isBag.some(even)) {
										mainBag = handleAddProduct(isBag, element, item.quantity ? item.quantity : 1);
									}
								});
							}
						});
					}
					if (CheckStock) {
						Notify({
							title: `¡Aviso!`,
							colorTitle: 'ph-main-notification-warning-title',
							description: `Algunos productos no pudieron ser agregados; por falta de disponibilidad o límite de compra.`,
							time: 10,
							image: 'warning',
							placement: 'topRight',
						});
					}
				} else setGlobal({ address: isLocation });
				if (mainBag.length > 0) {
					BagCalculation('farmacia', false).then(async (response) => {
						setBagCalculateModal(false);
						if (response) {
							const localsMerged = await BagMerge(response.locals_group);
							const responseBag = { ...response, locals_group: localsMerged };

							let checkRetirement = handleCheckRetirement(mainBag);
							setBagTotalCart(handleBagTotal(responseBag));
							let pharmacies = {};
							responseBag.locals_group.forEach((item) => {
								if (item.payment.discount !== 0) {
									Notify({
										title: `¡Bien!`,
										colorTitle: 'ph-main-notification-warning-title',
										description: `Estás disfrutando de un descuento en ${item.name_editable}. El monto se refleja al seleccionar el tipo de entrega.`,
										time: 5,
										image: 'warning',
										placement: 'topRight',
									});
								}
								pharmacies = {
									...pharmacies,
									[item.name]: item?.locals.length > 0 ? item : false,
								};
								if (pharmacies[item.name] === false) {
									checkRetirement[item.name] = {
										info: [],
										deliveryStatus: false,
										type_retirement: null,
									};
									let bag = mainBag;
									bag.forEach((element) => {
										if (item.name === element.pharmacy) delete element.type_retirement;
									});
									localStorage.setItem('cart', JSON.stringify(bag));
									setBag(bag);
								} else {
									const filter = BagRecipeFilter(mainBag);
									setBagRecipe(filter.some((item) => !item.recipe));
									setBagFilterProducts(filter);
								}
							});
							setBagRetirement({ ...checkRetirement });
							setBagRetirementPharmacies(pharmacies);
							setBagData({ ...responseBag });
						} else setBagData(false);
					});
				} else {
					setBagCalculateModal(false);
				}
			} else {
				setBagCalculateModal(false);
				setElectronicPrescription(false);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleCheckRetirement, setBagRetirementPharmacies, setBagCalculateModal, setBagData, setBagRetirement, cartForm]);

	if (isBagData === false)
		return (
			<BagError
				data={{
					title: '¡Ha ocurrido un error!',
					description: 'Comuníquese con nuestro personal.',
				}}
			/>
		);

	if (isElectronicPrescription === false)
		return (
			<BagError
				data={{
					title: '¡Ha ocurrido un error!',
					description: 'Comuníquese con nuestro personal.',
				}}
			/>
		);

	return (
		<>
			<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			<div className="ph-bag-electronic-global-container">
				<div className="ph-bag-electronic-main-container">
					<CheckoutTimeLine />
					<div className="ph-bag-electronic-main-title-container">
						<CheckoutGlobalTitle image={'bag'} title={'Tu Bolsa'} subtitle1={'Total'} subtitle2={'productos'} />
						<div className="ph-bag-electronic-countdown-static-container">
							<ClockCircleOutlined className="ph-bag-electronic-countdown-static-icon" />
							<span className="ph-bag-electronic-countdown-static-description">5:00</span>
						</div>
					</div>
					<Row>
						<Col xs={24} sm={24} md={24} lg={17} xl={17}>
							{isBag.length <= 0 ? <NoProducts /> : <>{isBagData ? <BagProductsGroup cartForm={cartForm} /> : <Loading />}</>}
							{isElectronicPrescription ? (
								<ElectronicProductcs cartForm={cartForm} isType={isType} isAddedCart={isAddedCart} isMobile={isMobile} products={isElectronicPrescription} />
							) : (
								<Loading />
							)}
						</Col>
						<Col xs={24} sm={24} md={24} lg={7} xl={7}>
							<div className={`${isBagCollapse ? 'ph-bag-main-info-box-collapsed' : 'ph-bag-main-info-box-collapse'} ph-bag-electronic-right-side-container`}>
								<BagMainInfo isType={isType} cartForm={cartForm} />
							</div>
							{isMobile && <BagMainInfoMobile cartForm={cartForm} />}
							<BagAlliesInfo />
						</Col>
					</Row>
					<BagBanners />
				</div>
			</div>
			<BagModalCalculate />
			<Address />
		</>
	);
}
