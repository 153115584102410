/** @format */

import axios from 'axios';

import Notify from '../../../../../../components/Commons/Notify';

import { ENV_COMMON, ENV_COUNTRY, ENV_ROOT_PHARMACY } from '../../../../../../components/Commons/Hooks/Variables/Enviroment';

import { ScreenWidth } from '../../../../../../components/Commons/Hooks/Functions/ScreenWidth';

export async function serviceQuoteGetPharmacies(item) {
	return await axios({
		method: 'GET',
		url: `${ENV_COMMON}/product/${ENV_COUNTRY}/${item}/locals`,
		params: {
			mobile: ScreenWidth(),
			root_pharmacy: ENV_ROOT_PHARMACY,
			lat: '-33.5264495',
			lng: '-70.66135109999999',
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				return response.data.data;
			} else {
				// PARCHE
				return [];
			}
		})
		.catch(() => {
			Notify({
				title: `Error`,
				colorTitle: 'ph-main-notification-error-title',
				description: 'Verifique tu conexión a internet.',
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
		});
}
