export const checkUnit = (props) => {
	const number = parseInt(props?.unit_of_measurement?.match(/\d+/), 10);
	if (props?.promotions && number === 100) {
		return (props?.promotion_price / props?.quantity_per_container) * number;
	}

	if (props?.promotions && number !== 100) {
		return props?.promotion_price / props?.quantity_per_container;
	}

	return number && number === 100 ? (props?.price / props?.quantity_per_container) * number : props?.price / props?.quantity_per_container;
};
