/** @format */

import axios from 'axios'

import Notify from '../../../components/Commons/Notify'

import {
	ENV_USER,
	ENV_COUNTRY,
	ENV_ROOT_PHARMACY,
} from '../../../components/Commons/Hooks/Variables/Enviroment'

export default async function recoverPasswordService(item, redirect) {
	let returnResponse
	await axios({
		method: 'POST',
		url: `${ENV_USER}/user/restore-password/${ENV_COUNTRY}`,
		data: {
			email: item.email.toLowerCase(),
		},
		params: {
			root_pharmacy: ENV_ROOT_PHARMACY,
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				Notify({
					title: 'Contraseña Enviada',
					colorTitle: 'ph-main-notification-success-title',
					description: 'Su contraseña nueva fue enviada a su correo electrónico.',
					time: 5,
					image: 'warning',
					placement: 'topRight',
				})
				redirect.push('/auth/login')
			} else {
				Notify({
					title: 'Problema con Email',
					colorTitle: 'ph-main-notification-error-title',
					description: 'Verifique que su correo correo electrónico este regisrado.',
					time: 5,
					image: 'error',
					placement: 'topRight',
				})
			}
			returnResponse = response
		})
		.catch(() => {
			Notify({
				title: `Error Servicio`,
				colorTitle: 'ph-main-notification-error-title',
				description: 'Problemas con el servicio - FUNCTION_USER restorePassword',
				time: 5,
				image: 'error',
				placement: 'topRight',
			})
		})
	return returnResponse
}
