/** @format */

import { Col, Row } from 'antd';
import React, { Fragment } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { ErrorBoundary, Paging, Results, SearchBox, SearchProvider, Sorting, WithSearch } from '@elastic/react-search-ui';
import { Layout } from '@elastic/react-search-ui-views';
import '@elastic/react-search-ui-views/lib/styles/styles.css';
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector';
import { useState } from 'react';
import { GlobalConsumer } from '../Commons/Context/Global';
import { ENV_COUNTRY, ENV_ELASTIC_ENVIROMENT, ENV_ELASTIC_KEY } from '../Commons/Hooks/Variables/Enviroment';
import Loading from '../Commons/Loading';
import ProductList from '../Commons/Product/Card/List';
import ProductRectangle from '../Commons/Product/Card/Rectangle';
import Actions from './components/Actions';
import { buildAutocompleteQueryConfig, buildFacetConfigFromConfig, buildSearchOptionsFromConfig, buildSortOptionsFromConfig } from './components/Config';
import Error from './components/Error';
import FacetsInfo from './components/FacetsInfo';
import MobileDrawer from './components/MobileDrawer';
import PageInfo from './components/PageInfo';
import { HandleFormatResult } from './functions/HandleFormatResult';

import Box from '../../v2-components/Box';
import './style.scss';

export default function Elastic(props) {
	const { isMobile } = GlobalConsumer();
	const [query, setQuery] = useState(props.params.query);
	const [filters] = useState(props.params.filters);
	const [sort, setSort] = useState(props.params.sort);
	const [facets] = useState(props.params.facets);
	console.log('props elastic',props)

	const connector = new AppSearchAPIConnector({
		searchKey: ENV_ELASTIC_KEY,
		engineName: props.params.index,
		endpointBase: ENV_ELASTIC_ENVIROMENT,
	});

	const config = {
		searchQuery: {
			query: query,
			filters: filters,
			sort: sort,
			disjunctiveFacets: facets,
			facets: buildFacetConfigFromConfig(props.params),
			...buildSearchOptionsFromConfig(),
		},
		autocompleteQuery: buildAutocompleteQueryConfig(),
		apiConnector: connector,
		alwaysSearchOnInitialLoad: true,
	};
	const hanldeWritingSearch = (e) => {
		setQuery(e);
		setSort(undefined);
	};

	return (
		<div className="ph-elastic-global-container">
			<SearchProvider config={config}>
				<WithSearch mapContextToProps={({ wasSearched }) => ({ wasSearched })}>
					{({ wasSearched }) => {
						return (
							<ErrorBoundary
								children={
									<Layout
										sideContent={
											<>
												{!isMobile && (
													<>
														<Row className="ph-elastic-searchbox-container">
															{props.showSearchBox && (
																<>
																	<SearchBox
																		onSubmit={hanldeWritingSearch}
																		inputView={({ getAutocomplete, getInputProps, getButtonProps }) => (
																			<>
																				<div className="sui-search-box__wrapper">
																					<input
																						{...getInputProps({
																							placeholder: props.placeholcerSearchBox,
																						})}
																					/>
																					{getAutocomplete()}
																				</div>
																				<button {...getButtonProps()}>
																					<SearchOutlined />
																				</button>
																			</>
																		)}
																	/>
																</>
															)}
														</Row>

														<FacetsInfo params={props.params} subCategory={props.subCategory} category={props.category} showSearchBox={props.showSearchBox} />
														<Col span={24}>
															<Box template={props.template} location={props.location} />
														</Col>
													</>
												)}
												{isMobile && (
													<>
														<MobileDrawer
															params={props.params}
															subCategory={props.subCategory}
															category={props.category}
															sidebarBanners={props.sidebarBanners}
														/>
													</>
												)}
											</>
										}
										bodyContent={
											<>
												{wasSearched ? (
													<Row className="ph-elastic-results-main-container">
														<Results
															view={(result) => {
																if (result?.children?.length > 0) {
																	return result.children.map((item, index) => (
																		<Fragment key={index}>
																			{console.log('item elastic', item)}
																			{isMobile ? (
																				<ProductList detail={HandleFormatResult(item.props.result)} />
																			) : (
																				<Col xs={24} sm={12} md={8} lg={6} xl={6} className="ph-elastic-results-card-container">
																					<ProductRectangle detail={HandleFormatResult(item.props.result)} />
																				</Col>
																			)}
																		</Fragment>
																	));
																} else {
																	return <Error params={props.params} />;
																}
															}}
														/>
													</Row>
												) : (
													<Loading />
												)}
											</>
										}
										bodyHeader={
											<>
												{wasSearched && (
													<>
														<div className="ph-elastic-main-Header-main-container">
															{isMobile && (
																<>
																	<Row>
																		{props.showSearchBox && (
																			<>
																				<SearchBox
																					onSubmit={hanldeWritingSearch}
																					inputView={({ getAutocomplete, getInputProps, getButtonProps }) => (
																						<>
																							<div className="sui-search-box__wrapper">
																								<input
																									{...getInputProps({
																										placeholder: props.placeholcerSearchBox,
																									})}
																								/>
																								{getAutocomplete()}
																							</div>
																							<button {...getButtonProps()}>
																								<SearchOutlined />
																							</button>
																						</>
																					)}
																				/>
																			</>
																		)}
																	</Row>
																</>
															)}
															<Row style={{ justifyContent: ENV_COUNTRY === 'AR' && 'center' }}>
																<Col xs={24} sm={12} md={12} lg={12} xl={12} className={!isMobile && 'ph-elastic-main-Header-sorting-container'}>
																	{!isMobile && <PageInfo />}

																	<Sorting label={'Ordenar'} sortOptions={buildSortOptionsFromConfig()} />
																</Col>

																{isMobile && (
																	<>
																		<Col xs={24} sm={12} md={12} lg={12} xl={12}>
																			<div className="ph-elastic-main-header-right-container">
																				<Actions />
																			</div>
																		</Col>
																	</>
																)}
															</Row>
														</div>
													</>
												)}
											</>
										}
										bodyFooter={
											<>
												{wasSearched && (
													<div className="ph-elastic-footer-main-container">
														<div
															className="ph-elastic-pagination-main-container"
															onClick={() => {
																window.scrollTo(0, 0);
															}}
														>
															<Paging />
														</div>
														<div className="ph-elastic-footer-page-info-container">
															<PageInfo />
														</div>
													</div>
												)}
												{isMobile && (
													<>
														<Col span={24} style={{marginTop:'15px'}}>
															<Box template={props.template} location={props.location} />
														</Col>
													</>
												)}
											</>
										}
									/>
								}
							/>
						);
					}}
				</WithSearch>
			</SearchProvider>
		</div>
	);
}
