/** @format */

import React, { Component } from 'react'

import Spacer from '../../../Spacer'

import PromotionDiscount from './components/PromotionDiscount'
import PriceDetailProduct from './components/PriceDetailProduct'

import './style.scss'

export default class ProductPrice extends Component {
	render() {
		return (
			<>
				<div
					className={`${this.props.cartProducts ? 'ph-cartproducts-price-main-container' : ''} ${
						this.props.alternatives ? 'ph-cartproducts-price-main-container-alternatives' : ''
					} ph-product-price-main-container`}>
					<div
						className={`${this.props.cartProducts ? 'ph-cartproducts-price-container' : ''} ${this.props.eCart ? 'ph-electronic-cartproducts-price-container' : ''} ph-product-price-container`}>
						<PriceDetailProduct detail={this.props.detail} />
						{this.props.detail.pharmacy && (
							<>
								{this.props.detail.interface && (
									<>
										{this.props.detail.interface.promotions && (
											<>
												{(this.props.detail.interface.promotions.description_promotion ||
													this.props.detail.interface.promotions.percentaje_promotion ||
													this.props.detail.interface.promotions.price_promotion) && <Spacer />}
												<PromotionDiscount promotion={this.props.detail.interface.promotions} />
											</>
										)}
									</>
								)}
							</>
						)}
					</div>
				</div>
			</>
		)
	}
}
