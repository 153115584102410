/** @format */

import React from "react";

import { Button } from "antd";

import AddProductCart from "../../../../../../../../../../components/Commons/Hooks/Functions/AddProductCart";

import "./style.scss";

export default function QuoteAddButton(props) {
  const { handleAddProductCart } = AddProductCart();

  const hableAlternativeProduct = (item) => {
    let result = item.slug.split("-");
    window.location.href = `/results/${result[0]}`;
  };

  return (
    <div className="ph-product-detail-comparator-button-container">
      {props.item.stock > 0 && (
        <Button
          className="ph-product-detail-quote-button"
          onClick={() => {
            if (props.pharolPriceCheck)
              props.handleSubscriptionPharolPrice(props.item.pharmacy);

            handleAddProductCart(props.item, 1);
          }}
        >
          Agregar
        </Button>
      )}
      {(props.item.stock <= 0 || props.item.stock === undefined) && (
        <Button
          className="ph-product-detail-alternative-button"
          onClick={() => hableAlternativeProduct(props.item)}
        >
          Alternativas
        </Button>
      )}
    </div>
  );
}
