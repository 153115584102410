/** @format */

import React from 'react';

import Images from '../../../../../../components/Commons/Images';

import './style.scss';

export default function NoProducts() {
	return (
		<div className="ph-bag-electronic-cart-no-producs-main-container">
			<div className="ph-bag-electronic-cart-no-producs-container">
				<Images classContent={''} classImage={'ph-bag-electronic-cart-no-products-image'} default={'https://storage.googleapis.com/master-image-bucket/bag/bolsa-triste.png'} title={'bolsa vacia'} />
				<h3 className="ph-bag-electronic-cart-no-products-title">¡Tu Bolsa está triste!</h3>
				<h3 className="ph-bag-electronic-cart-no-products-subtitle">...aún no agregas ningún producto.</h3>
			</div>
		</div>
	);
}
