/** @format */

import { Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalTypeRecipes } from '../../../../../../../../components/Commons/Modals/ModalTypeRecipes';
import CustomImage from '../../../../../../../../v2-components/Antd/Image';
import DescriptionTypeReceipt from '../../../../../../components/Title/components/DescriptionTypeReceipt';
import './style.scss';

export default function ProductQuoteTitle(props) {
	const { t } = useTranslation('global');
	const [imgFavorites] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {}, [imgFavorites]);

	const handleTypeRecipes = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	return (
		<>
			<Row className="ph-product-detail-quote-title-main-container">
				<div className="ph-product-detail-quote-title-info-container">
					<h3 className="ph-product-detail-quote-title-info-title">
						Código {props.detailPharmacy ? props.item.details[0].name_edited_pharmacy : `${t('main.company')}`}:
						<span className="ph-product-detail-quote-title-info-inner-title">
							{props.detailPharmacy ? props.item.details[0].code_internal : props.item.details[0].id_pharol}
						</span>
					</h3>
					<div className="ph-product-detail-quote-title-info-name-favorite-container">
						<h1 className="ph-product-detail-quote-title-info-main-title">{props.item.details[0].editable_name}</h1>
						<div className="ph-product-detail-quote-icon-container"></div>
					</div>
					<div className="ph-product-detail-quote-title-info-subtitle-hashtag-container">
						<div className="ph-product-detail-quote-title-info-main-subtitle">
							Laboratorio:
							{props.item.details[0].laboratory}
						</div>
					</div>
				</div>
			</Row>
			{!props.desktop && props.item.details[0].interface?.prescryption_type && props.item.details[0].master_category === 'Medicamentos' && (
				<div className="ph-product-detail-type-recepit-main-container" onClick={handleTypeRecipes} style={{ cursor: 'pointer' }}>
					<DescriptionTypeReceipt
						title={t(`prescryptionType.${props.item.details[0].prescryption_type}`)}
						description={props.item.details[0].interface.prescryption_type.type}
						image={props.item.details[0].interface.prescryption_type.image}
					/>
					{props.item.details[0].bioequivalent === '1' && (
						<div style={{ display: 'flex', alignItems: 'center', marginTop: '13px' }}>
							<CustomImage width={30} src={props?.item?.details[0]?.interface?.bioequivalent?.image} />
							<div className="ph-product-detail-type-recepit-title" style={{ marginLeft: '15px' }}>
								Producto Bioequivalente
							</div>
						</div>
					)}
				</div>
			)}
			<ModalTypeRecipes isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} />
		</>
	);
}
