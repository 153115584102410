/** @format */

import { Row, Col, notification, Button } from "antd";

import userIcon from "../Assets/Icons/Filled/Enabled/user.svg";
import userArgIcon from "../Assets/Icons/Filled/Enabled/Argentina/user1-1.png";

import Images from "../Images";

import "./style.scss";
import { ENV_COUNTRY } from "../Hooks/Variables/Enviroment";

export default function openNotification(props) {
  const keyNotify = props.key ? props.key : null;
  return notification.open({
    key: keyNotify,
    duration: props.time,
    className: "ph-notification-global-container",
    description: (
      <section>
        <Row className="ph-notification-main-container">
          <Col className="ph-notification-img-global-container">
            <Images
              classContent={"ph-notification-image-container"}
              classImage={"ph-notification-image"}
              default={ ENV_COUNTRY === "AR" ? userArgIcon : userIcon}
            />
          </Col>
          <Col className="ph-notification-text-global-container">
            <h1 className={`${props.colorTitle} ph-notification-title`}>
              {props.title}
            </h1>
            <p className="ph-notification-description">{props.description}</p>
            {!!props.buttonFunction && (
              <Button
                className="ph-notification-button"
                onClick={props.buttonFunction}
              >
                Aceptar
              </Button>
            )}
          </Col>
        </Row>
      </section>
    ),
    placement: props.placement,
  });
}
