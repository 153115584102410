/** @format */

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import CountryValidator from '../../../../../../../../components/Commons/CountryValidator'
import { Col, Row } from 'antd';

import Images from '../../../../../../../../components/Commons/Images';

import { ENV_IMG_HEADER } from '../../../../../../../../components/Commons/Hooks/Variables/Enviroment';

import ListInfoDetail from '../ListInfoDetail';
import './style.scss';

class CardInfoProfile extends Component {
	render() {
		const { t } = this.props
		return (
			<>
				<Row>
					<Col xs={24} sm={24} md={24} lg={this.props.columnsProfileMain} xl={this.props.columnsProfileMain} className="ph-card-detail-profile-info-container">
						<div className="ph-card-detail-profile-info-inner-container">
							<Images
								classContent={'ph-card-detail-profile-info-image-container'}
								classImage={'ph-card-detail-profile-info-image'}
								webp={`${ENV_IMG_HEADER}/pharol-usuario-logout.webp`}
								jp2={`${ENV_IMG_HEADER}/pharol-usuario-logout.jp2`}
								jxr={`${ENV_IMG_HEADER}/pharol-usuario-logout.jxr`}
								default={`${ENV_IMG_HEADER}/pharol-usuario-logout.png`}
								title={'user logout'}
							/>
							<div className="ph-card-detail-profile-info-title-container">
								<h3 className="ph-card-detail-profile-info-subtitle">{this.props.list.first_name + ' ' + this.props.list.last_name}</h3>
							</div>
							<h3 className="ph-card-detail-profile-info-description">¡Bienvenido!</h3>
						</div>
					</Col>
					<Col xs={24} sm={24} md={24} lg={this.props.columnsProfileInfo} xl={this.props.columnsProfileInfo}>
						<div className="ph-card-detail-profile-info-main-container">
							<ListInfoDetail title={'Nombre y Apellido:'} info={this.props.list.first_name + ' ' + this.props.list.last_name} />
							<ListInfoDetail title={'Correo Electrónico:'} info={this.props.list.email} />
							<ListInfoDetail title={'Teléfono:'} info={this.props.list.phone} />
							<ListInfoDetail title={t('tracking.personalId')} info={this.props.list.rut} />
							<CountryValidator
								countries={['CL']}
								component={
									<ListInfoDetail title={'Género:'} info={this.props.list.sex} />
								}
							/>	
							<ListInfoDetail title={'Dirección:'} info={this.props.list.location} />
						</div>
					</Col>
				</Row>
			</>
		);
	}
}

export default withTranslation('global')(CardInfoProfile)