import React from 'react';
import { Descriptions } from 'antd';
import './style.scss';

export default function CustomDescriptions(props) {
	return (
		<>
			<Descriptions {...props}>
				{props.items.map((item) => (
					<Descriptions.Item key={item.key} label={item.label}>
						{item.children}
					</Descriptions.Item>
				))}
			</Descriptions>
		</>
	);
}
