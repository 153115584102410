/** @format */

import React from 'react';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { withRouter } from 'react-router-dom';
import Footer from '../../v2-components/Footer';
import { ENVIROMENT } from '../../v2-config/constants/enviroment.constant';
import { GlobalProvider } from '../Commons/Context/Global';
import HeaderMain from '../Commons/Header/Main';
import HeaderSecondary from '../Commons/Header/Secondary';
import ModalSuggestAddress from '../Commons/Modals/ModalSuggestAddress';
import ModalWelcome from '../Commons/Modals/ModalWelcome';
import { LAYOUT } from './constants';
import { typeHeader } from './handle/type-header.handle';
import { typeAuth } from './handle/type-auth.handle';
import { typeMain } from './handle/type-main.handle';

const queryClient = new QueryClient();

function Layout(props) {
	return (
		<QueryClientProvider client={queryClient}>
			<GlobalProvider>
				<Helmet>
					<meta name="facebook-domain-verification" content={ENVIROMENT.FACEBOOK_DOMAIN_VERIFICATION} />
				</Helmet>
				{typeHeader({ path: props.location.pathname }) === LAYOUT.MAIN && (
					<>
						<HeaderMain />
					</>
				)}
				{typeHeader({ path: props.location.pathname }) === LAYOUT.CHECKOUT && (
					<>
						<HeaderSecondary />
					</>
				)}

				{typeMain({ path: props.location.pathname }) !== LAYOUT.MAIN && (
					<>
						<div>{props.children}</div>
					</>
				)}
				{typeMain({ path: props.location.pathname }) === LAYOUT.MAIN && (
					<>
						<div className="main-content">{props.children}</div>
					</>
				)}

				{typeAuth({ path: props.location.pathname }) === LAYOUT.MAIN && (
					<>
						<Footer />
					</>
				)}
				<ModalWelcome />
				<ModalSuggestAddress />
			</GlobalProvider>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	);
}

export default withRouter(Layout);
