/** @format */

import { Button, Col, Form, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { setGlobal } from 'reactn';
import { ReactComponent as IconSearch } from '../../../../../v2-config/icons-svg/search.svg';
import { PixelFacebook } from '../../../Hooks/Functions/PixelFacebook';
import { ENV_COUNTRY, ENV_IMG_ROUTE } from '../../../Hooks/Variables/Enviroment';
import Images from '../../../Images';
import Notify from '../../../Notify';
import Stock from './components/Stock';
import { SearchProducts } from './service';

import './style.scss';

const SearchComponent = () => {
	let location = useLocation();
	const [form] = Form.useForm();
	const [isResult, setResult] = useState([]);
	const [isFocus, setFocus] = useState(false);

	const handleSearchList = (item, data) => {
		setFocus(true);
		if (item?.target?.value?.length >= 2) {
			SearchProducts(item.target.value, data).then((response) => {
				console.log('respnse', response);
				setResult(response);
			});
		}
	};

	const handleNotificationSearch = () => {
		Notify({
			title: `Búsqueda de Productos`,
			colorTitle: 'ph-main-notification-warning-title',
			description: 'Debe ingresar un nombre de producto para buscar.',
			time: 3,
			image: 'error',
			placement: 'topRight',
		});
		return;
	};

	const handleSearchResult = async (item) => {
		if (item.inputSearch) {
			if (item.inputSearch.trim() === '') {
				handleNotificationSearch();
			} else {
				PixelFacebook('Search', { search_string: item.inputSearch });
				window.location.href = window.location.origin + `/results/${item.inputSearch}`;
			}
		} else {
			handleNotificationSearch();
		}
	};

	const handleCleanList = (item) => {
		setGlobal({ searchDrawer: false });
		form.setFieldsValue({
			inputSearch: '',
		});
		setResult([]);
	};

	const handleInputSearchClass = () => {
		if (navigator.userAgent.toLowerCase().indexOf('iphone') !== -1) {
			return 'ph-search-header-input-iphone';
		} else {
			return 'ph-search-header-input-windows';
		}
	};

	useEffect(() => {
		form.setFieldsValue({
			inputSearch: '',
		});
		setResult([]);
	}, [location.pathname, form]);

	return (
		<>
			<div className="ph-search-global-container">
				<div
					className="ph-search-header-main-container"
					tabIndex="0"
					onFocus={() => setFocus(true)}
					onBlur={() =>
						setTimeout(() => {
							setFocus(false);
						}, 250)
					}
				>
					<div className="ph-search-header-container">
						<Form onFinish={handleSearchResult} name="main_search" autoComplete="off" form={form}>
							<Form.Item className="ph-search-header-form-item" name="inputSearch">
								<Input
									type="text"
									onClick={() => setFocus(true)}
									onChange={(item) => handleSearchList(item, 'pharol')}
									placeholder="Búsqueda en productos..."
									className={`${handleInputSearchClass()} ph-search-header`}
								/>
							</Form.Item>
							<Form.Item className="ph-search-header-button-container">
								<Button className="ph-search-header-button" htmlType="submit">
									<IconSearch className={'icons-theme-search'} />
								</Button>
							</Form.Item>
						</Form>
					</div>
					{isResult.length > 0 && (
						<ul className={`ph-search-header-list-main-container`} style={isFocus ? { display: 'block' } : { display: 'none' }}>
							{isResult.map((item, index) => (
								<li className="ph-search-header-list" onClick={() => handleCleanList(item)} key={index}>
									<NavLink className="ph-search-header-list-description" to={`/products/${item.slug}-farmacias-${item.pharmacy}`}>
										<Row>
											<Col span={2} className="ph-search-header-images-search-content">
												<Images
													classContent={'ph-search-header-list-image-container'}
													classImage={'ph-search-header-list-image'}
													// REVISAR:
													//webp={`${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${item.id_pharol}.webp`}
													//jp2={`${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${props.item.id_pharol}.jp2`}
													//jxr={`${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${props.item.id_pharol}.jxr`}
													default={`${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${item.id_pharol}.png`}
													title={item.editable_name}
												/>
											</Col>
											<Col span={22} className="ph-search-header-titles-search-content">
												<h3 className="ph-search-header-titles-search-h3">
													{item.editable_name}
													{item.cenabast === 'Cenabast' && (
														<>
															<span>{' (cenabast)'}</span>
														</>
													)}
												</h3>
												{item.available > 0 ? (
													<p className="ph-search-header-titles-search-p">
														<span>Laboratorio {item.laboratory}</span>
														{ENV_COUNTRY !== 'AR' && `, consultar en `}
														<span className="ph-search-header-titles-search-contents-p-span" style={{ display: ENV_COUNTRY === 'AR' && 'none' }}>
															{item.available} Farmacia(s)
														</span>
													</p>
												) : (
													<p className="ph-search-header-titles-search-p">
														<span> Laboratorio {item.laboratory}</span>
													</p>
												)}
												{item.stock && <Stock stock={item.stock} />}
											</Col>
										</Row>
									</NavLink>
								</li>
							))}
						</ul>
					)}
				</div>
			</div>
		</>
	);
};
export default SearchComponent;
