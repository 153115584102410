/** @format */

import axios from 'axios'

import Notify from '../../../Notify'

import { ENV_COMMON, ENV_COUNTRY, ENV_ROOT_PHARMACY } from '../../../Hooks/Variables/Enviroment'
import { ScreenWidth } from '../../../Hooks/Functions/ScreenWidth'

export const GetProduct = async (item, data) => {
	let returnResponse
	await axios({
		method: 'GET',
		url: `${ENV_COMMON}/product/${ENV_COUNTRY}/${item}`,
		params: {
			mobile: ScreenWidth(),
			root_pharmacy: ENV_ROOT_PHARMACY,
			lat: data.lat,
			lng: data.lng,
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data
			} else {
				Notify({
					title: `Error`,
					colorTitle: 'ph-main-notification-error-title',
					description: '¡Ha ocurrido un error verificando la disponibilidad del producto!',
					time: 3,
					image: 'error',
					placement: 'topRight',
				})
			}
		})
		.catch(() => {
			Notify({
				title: `Error`,
				colorTitle: 'ph-main-notification-error-title',
				description: 'Verifique su conexión a internet.',
				time: 3,
				image: 'error',
				placement: 'topRight',
			})
		})
	return returnResponse
}
