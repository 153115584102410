/** @format */

import axios from 'axios'

import Notify from '../../../../components/Commons/Notify'

import {
	ENV_AGREEMENT,
	ENV_COUNTRY,
} from '../../../../components/Commons/Hooks/Variables/Enviroment'

export const serviceGetAgreementCustomer = async (item) => {
	return await axios({
		method: 'GET',
		url: `${ENV_AGREEMENT}/agreement-customer/${ENV_COUNTRY}/${item.rut}/${item.pharmacy}/000352`,
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				return response.data.data
			} else {
				return response.data
			}
		})
		.catch(() => {
			Notify({
				title: `Error`,
				colorTitle: 'ph-main-notification-error-title',
				description: 'Verifique su conexión a internet.',
				time: 3,
				image: 'error',
				placement: 'topRight',
			})
		})
}
