/** @format */

import React, { useEffect, useState } from 'react';
import { ENV_COUNTRY } from '../../../../../../components/Commons/Hooks/Variables/Enviroment';
import { setGlobal } from 'reactn';
import { Col, Row, Modal, Form, Select, Input, Button } from 'antd';
import { rulesValidationMask } from '../../../../../../components/Commons/InputMask/rules';
import { updateUserDataService } from '../../../../../../components/Commons/Hooks/Functions/UpdateUserData';
import CountryValidator from '../../../../../../components/Commons/CountryValidator';
import InputRut from '../../../../../../components/Commons/InputCountry/Rut';
import InputPhone from '../../../../../../components/Commons/InputCountry/Phone';
import InputField from '../../../../../../components/Commons/Input';
import InputMask from '../../../../../../components/Commons/InputMask';
import data from '../../data.json';
import './style.scss';

const { Option } = Select;

export default function ModalEditProfile(props) {
	const [form] = Form.useForm();
	const [isLoadingUserUpdate, setLoadingUserUpdate] = useState(false);
	const [date] = useState(new Date());
	const [isFieldDisabled, setIsFieldDisabled] = useState(true);

	const handleUpdateUserData = async (event) => {
		event.preventDefault();
		const data = form.getFieldsValue();
		setLoadingUserUpdate(true);
		updateUserDataService(data).then((response) => {
			if (response) {
				setGlobal({ userProfile: response });
				setGlobal(() => ({ userMainInfo: data }));
				localStorage.setItem('userMainInfo', JSON.stringify(response));
			}
			setLoadingUserUpdate(false);
			props.editProfileModal(false);
		});
	};

	const isButtonValid = () => {
		form
			.validateFields()
			.then(() => {
				setIsFieldDisabled(false);
			})
			.catch(() => {
				setIsFieldDisabled(true);
			});
	};

	useEffect(() => {
		form
			.validateFields()
			.then(() => {
				setIsFieldDisabled(false);
			})
			.catch(() => {
				setIsFieldDisabled(true);
			});
	}, [form]);

	return (
		<>
			<Modal
				wrapClassName="ph-edit-profile-modal-container"
				width={600}
				title="Editar Datos Personales"
				centered
				visible={props.visible}
				footer={null}
				onCancel={() => props.editProfileModal(false)}
				maskClosable={false}
			>
				<Form
					className="ph-edit-profile-form-main-container"
					onFinish={() => console.log('hola mundo')}
					form={form}
					initialValues={{
						email: props.email,
						first_name: props.list && props.list.first_name ? props.list.first_name : '',
						last_name: props.list && props.list.last_name ? props.list.last_name : '',
						phone: props.list && props.list.phone ? props.list.phone : '',
						sex: props.list && props.list.sex ? props.list.sex : '',
						rut: props.list && props.list.rut ? props.list.rut : '',
						age: props.list && props.list.age ? props.list.age : '',
					}}
					layout={'vertical'}
					onChange={isButtonValid}
				>
					<Row>
						<Col xs={24} sm={12} md={12} lg={12} xl={12}>
							<Form.Item label="Nombre" name="first_name">
								<InputField
									className={'ph-edit-profile-field-input'}
									inputName={'first_name'}
									inputNameLabel={'Nombre'}
									inputNameRule={true}
									inputNameMessage={'Ingresa tu nombre'}
									inputNameType={'text'}
									inputNameIcon={''}
									inputNameRules={'rulesFirstName'}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={12} md={12} lg={12} xl={12}>
							<Form.Item label="Apellido" name="last_name">
								<InputField
									className={'ph-edit-profile-field-input'}
									inputName={'last_name'}
									inputNameLabel={'Apellido'}
									inputNameRule={true}
									inputNameMessage={'Ingresa tu apellido'}
									inputNameType={'text'}
									inputNameIcon={''}
									inputNameRules={'rulesLastName'}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={12} md={12} lg={12} xl={12}>
							<Form.Item label="E-mail" name="email">
								<Input disabled className={'ph-edit-profile-field-input-email'} />
							</Form.Item>
						</Col>
						<Col xs={24} sm={12} md={12} lg={12} xl={12}>
							<InputPhone label={'Teléfono'} classInput={''} />
						</Col>
						<CountryValidator
							countries={['CL']}
							component={
								<Col xs={24} sm={12} md={12} lg={12} xl={12}>
									<Form.Item label="Sexo" name="sex">
										<Select className="ph-edit-profile-field-selector" size="large" style={{ width: '100%' }} allowClear>
											<Option value="Masculino">Masculino</Option>
											<Option value="Femenino">Femenino</Option>
										</Select>
									</Form.Item>
								</Col>
							}
						/>
						<Col xs={24} sm={12} md={12} lg={12} xl={12}>
							<InputRut label={data[ENV_COUNTRY].title_document_abbreviation} classInput={''} />
						</Col>
						<Col xs={24} sm={12} md={12} lg={12} xl={12}>
							<Form.Item label="Fecha de nacimiento" name="age" rules={rulesValidationMask.rulesAge(date)}>
								<InputMask mask="99/99/9999" placeholder="31/12/2021" />
							</Form.Item>
						</Col>
					</Row>

					<Button
						disabled={isFieldDisabled}
						className={isFieldDisabled ? 'ph-edit-profile-modal-button-disabled' : 'ph-edit-profile-modal-button'}
						htmlType="submit"
						onClick={handleUpdateUserData}
						loading={isLoadingUserUpdate}
					>
						Actualizar
					</Button>
				</Form>
			</Modal>
		</>
	);
}
