/** @format */

import React from 'react';

// import { Link } from 'react-router-dom'

// import { Col } from 'antd'
// import CountryValidator from '../../../../../../components/Commons/CountryValidator'

// import Images from '../../../../../../components/Commons/Images'

// import { TagsOutlined } from '@ant-design/icons'

// import { ENV_COUNTRY } from '../../../../../../components/Commons/Hooks/Variables/Enviroment'

// import data from './data.json'

import './style.scss';

export default function Contact(props) {
	return <></>;
	// return (
	// 	<Col span={24} className='ph-product-detail-contact-container'>
	// 		{props.service ? (
	// 			<>
	// 				<Images
	// 					classContent={'ph-product-detail-contact-image-container'}
	// 					classImage={'ph-product-detail-contact-image'}
	// 					default={props.item.details[0].service_provider.logo}
	// 					title={props.item.editable_name}
	// 					alt={props.item.editable_name}
	// 				/>
	// 				<h3 className='ph-product-detail-contact-title'>
	// 					Si tienes alguna duda comunícate al {props.item.details[0].service_provider.phone}
	// 					. Servicios {props.item.details[0].service_provider.editable_name}
	// 				</h3>
	// 			</>
	// 		) : (
	// 			<>
	// 				<CountryValidator
	// 					countries={['CL']}
	// 					component={
	// 						<>
	// 						<TagsOutlined className='ph-product-detail-contact-icon' />
	// 						<h3 className='ph-product-detail-contact-title'>
	// 							Si tienes alguna duda <Link to='/contact'>contáctanos</Link> o llámanos al{' '}
	// 							{data[ENV_COUNTRY].phone}
	// 						</h3>
	// 						</>
	// 					}
	// 				/>
	// 			</>
	// 		)}
	// 	</Col>
	// )
}
