/** @format */

export default function BagRecipeFilter(item) {
	const filter = item.filter(
		(element) =>
			element.prescription_type === 'WP' ||
			element.prescription_type === 'RP' ||
			element.prescription_type === 'RCH' ||
			element.prescription_type === 'RRCS' ||
			element.prescription_type === 'APR' ||
			element.prescription_type === 'RMRSCS' ||
			element.prescription_type === 'RMRCCS' ||
			element.prescription_type === 'MRE'
	)
	return filter
}
