/** @format */

import React, { Component } from 'react'

import Spin from 'antd/lib/spin'
import { LoadingOutlined } from '@ant-design/icons'

import './style.scss'

export default class loading extends Component {
	render() {
		return (
			<>
				<div className='ph-loading-container'>
					<Spin
						indicator={<LoadingOutlined className='ph-loading-image' />}
						className='ph-loading-icon'
					/>
				</div>
			</>
		)
	}
}
