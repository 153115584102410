/** @format */

import axios from 'axios';

import Notify from '../../../Notify';

import { ENV_USER } from '../../Variables/Enviroment';

export async function SaveRecipes(name, image) {
	const token = localStorage.getItem('userToken');
	let returnResponse;
	let data = {
		info: {
			name: name,
			recipe: image,
		},
	};
	await axios({
		headers: {
			Authorization: 'Bearer ' + token,
		},
		method: 'POST',
		url: `${ENV_USER}/recipe`,
		data: data,
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data;
			} else {
				Notify({
					title: `Error: ${response.data.error}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Problemas con el servicio:   ${response.data.message}`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			}
		})
		.catch(() => {
			Notify({
				title: `Problemas de Conexión`,
				colorTitle: 'ph-main-notification-error-title',
				description: `Revisa tu conexión o ponte en contacto con nosotros.`,
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
}

export async function SaveMultipleRecipes(item) {
	const token = localStorage.getItem('userToken');
	await Promise.all(
		item.map(async (product) => {
			let data = {
				info: {
					name: product.editable_name,
					recipe: product.recipe,
				},
			};
			await axios({
				headers: {
					Authorization: 'Bearer ' + token,
				},
				method: 'POST',
				url: `${ENV_USER}/recipe`,
				data,
			}).then((response) => {});
		}),
	);
}
