/** @format */

import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import moment from 'moment';

import { Form, Row, Col, DatePicker } from 'antd';

import MetaDescription from '../../../../components/Commons/MetaDescription';
import Images from '../../../../components/Commons/Images';
import Loading from '../../../../components/Commons/Loading';

import TitleComplaint from '../../components/TitleComplaint';

import List from './components/List';
import Paginator from './components/Paginator';

import { serviceGetAllClaims } from './services';

import './style.scss';
import CustomButton from '../../../../v2-components/Antd/Button';

export default function ComplaintBookHistory(props) {
	const { page, pharmacy } = useParams();
	const [complaint, setComplaint] = useState({});
	const [loading, setLoading] = useState(true);
	const [isLoadingForm, setLoadingForm] = useState(false);

	const handleFetch = useCallback(async (item, page, limit) => {
		return await serviceGetAllClaims(item, page, limit);
	}, []);

	useEffect(() => {
		let mounted = true;
		handleFetch(
			{
				pharmacy,
				start_date: '',
				end_date: '',
			},
			page,
			10,
		).then((response) => {
			if (!mounted) return;
			setComplaint(response);
			setLoading(false);
		});
		return () => {
			mounted = false;
		};
	}, [handleFetch, pharmacy, page]);

	const handleFormDate = (item) => {
		setLoadingForm(true);
		handleFetch(
			{
				pharmacy,
				start_date: item.start_date === null || item.start_date === undefined ? '' : moment(item.start_date).format('DD-MM-YYYY'),
				end_date: item.end_date === null || item.end_date === undefined ? '' : moment(item.end_date).format('DD-MM-YYYY'),
			},
			page,
			10,
		).then((response) => {
			setLoadingForm(false);
			setComplaint(response);
			setLoading(false);
		});
	};

	return (
		<>
			<MetaDescription
				title={`Lista de Reclamos - Pharol | Red de farmacias`}
				name={'description'}
				content={'Red de Farmacias Online | Busca y compra tus remedios o medicamentos a precios bajos con despacho a domicilio o retiro express...'}
			/>
			<div className="ph-complaint-book-container">
				<div className="ph-complaint-book-inner-container">
					<TitleComplaint
						title={'Reclamos y sugerencias - Historial'}
						description={`Volver - ${props.match.params.pharmacy}`}
						image={`react-header/pharol-tracking-search`}
						link={`/complaint-book/menu/${props.match.params.pharmacy}`}
					/>
					<div className="ph-complaint-book-main-container">
						<Form name="search_date" onFinish={handleFormDate}>
							<Row>
								<Col xs={24} sm={24} md={6} lg={6} xl={6}>
									<Form.Item name="start_date">
										<DatePicker className="ph-complaint-book-picker-field" />
									</Form.Item>
								</Col>
								<Col xs={24} sm={24} md={6} lg={6} xl={6}>
									<Form.Item name="end_date">
										<DatePicker className="ph-complaint-book-picker-field" />
									</Form.Item>
								</Col>
								<Col>
									<CustomButton loading={isLoadingForm} className={'button-primary'} htmlType="submit">
										Buscar
									</CustomButton>
								</Col>
							</Row>
						</Form>
						{loading ? (
							<Loading />
						) : (
							<>
								{complaint.data.length > 0 ? (
									<>
										<List complaint={complaint.data} />
										<Paginator
											route={`/complaint-book/history`}
											page={page}
											pharmacy={pharmacy}
											totalItems={complaint.totalItems}
											itemsPerPage={complaint.itemsPerPage}
										/>
									</>
								) : (
									<div className="ph-complaint-book-no-results-container">
										<div>
											<Images
												classContent={'ph-complaint-book-no-results-image-container'}
												classImage={'ph-complaint-book-no-results-image'}
												default={`https://storage.googleapis.com/master-image-bucket/404/ICONO-35.png`}
												title="pharol image"
											/>
											<h2 className="ph-complaint-book-no-results-title">¡Lo sentimos!</h2>
											<h3 className="ph-complaint-book-no-results-subtitle">No hay Resultados para mostrar.</h3>
										</div>
									</div>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
