/** @format */

import React, { useEffect } from 'react';

import moment from 'moment';

import { Row, Col, Collapse, Button } from 'antd';

import { BagConsumer } from '../../../../../../components/Commons/Context/Bag';

import Images from '../../../../../../components/Commons/Images';
import Spacer from '../../../../../../components/Commons/Spacer';
import Price from '../../../../../../components/Commons/Product/components/Price';

import { ENV_COUNTRY, ENV_IMG, ENV_IMG_ROUTE } from '../../../../../../components/Commons/Hooks/Variables/Enviroment';

import translates from '../../../../../../components/Commons/translates';

import HandleAddProductElectronic from '../../../../functions/BagAddProductElectronic';

import PanelTitle from './components/PanelTitle';
import Stock from './components/Stock';

import './style.scss';

export default function ElectroniProducts(props) {
	const { handleAddProductCartElectronic, open, setOpen } = HandleAddProductElectronic();
	const { isBagRequest } = BagConsumer();

	const handleCollapse = (item) => setOpen(item);

	const hableAlternativeProduct = (item) => {
		let result = item.slug.split('-');
		window.location.href = `/results/${result[0]}`;
	};

	useEffect(() => {
		let propsCollapseProducts = props.products.products;
		let productCollapse = [];
		let n = 0;
		propsCollapseProducts.forEach(() => {
			productCollapse.push(n.toString());
			n = n + 1;
		});
		setOpen(productCollapse);
	}, [props.products.products, setOpen]);

	useEffect(() => {
		if (props.isType === 'refer_pharmacies') setOpen([]); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="ph-bag-electronic-cart-presciption-producs-main-container">
			<div className="ph-bag-electronic-cart-presciption-producs-title-container">
				<Images
					classContent={'ph-bag-electronic-cart-presciption-producs-title-image-container'}
					classImage={'ph-bag-electronic-cart-presciption-producs-title-image'}
					webp={`${ENV_IMG}/react-checkout/electronic-prescription/receta-medica-icon.webp`}
					jp2={`${ENV_IMG}/react-checkout/electronic-prescription/receta-medica-icon.jp2`}
					jxr={`${ENV_IMG}/react-checkout/electronic-prescription/receta-medica-icon.jxr`}
					default={`${ENV_IMG}/react-checkout/electronic-prescription/receta-medica-icon.png`}
					title={'Electronic Prescription Image'}
				/>
				<h3 className="ph-bag-electronic-cart-presciption-producs-title">Selecciona los productos de tu bolsa:</h3>
			</div>
			<Collapse className="ph-bag-electronic-cart-presciption-collapse-container" activeKey={open} onChange={handleCollapse} expandIconPosition={'right'}>
				{props.products.products.map((item, index) => (
					<Collapse.Panel onChange={() => setOpen(() => [index])} header={<PanelTitle item={item} />} key={index}>
						<Row>
							<Col xs={24} sm={24} md={4} lg={4} xl={4}>
								<Images
									classContent={'ph-bag-electronic-cart-presciption-producs-panel-collapse-image-container'}
									classImage={'ph-bag-electronic-cart-presciption-producs-panel-collapse-image'}
									webp={item.id_pharol === undefined ? item.image : `${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${item.id_pharol}.webp`}
									default={item.id_pharol === undefined ? item.image : `${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${item.id_pharol}.png`}
									title={item.meta_title}
								/>
							</Col>
							<Col xs={24} sm={24} md={20} lg={20} xl={20}>
								<div className="ph-bag-electronic-cart-presciption-panel-container">
									<div className="ph-bag-electronic-cart-presciption-product-description-container">
										{item.laboratory && item.laboratory !== '-' && <h4 className="ph-bag-electronic-cart-presciption-product-description-laboratory">{item.laboratory}</h4>}
										<h4 className="ph-bag-electronic-cart-presciption-product-description-title">{item.meta_title}</h4>
										<h4 className="ph-bag-electronic-cart-presciption-product-description-type">
											Code Internal: <span className="ph-bag-electronic-cart-presciption-product-description-subtype">{item.id_pharol}</span>
										</h4>
										{item.interface && (
											<>
												{item.interface.on_demand && (
													<div className="ph-bag-electronic-cart-presciption-product-description-on-demand-container">
														<h4 className="ph-bag-electronic-cart-presciption-product-description-on-demand">{item.interface.on_demand.title}</h4>
														<Images
															classContent={'ph-bag-electronic-cart-presciption-producs-title-image-on-demand-container'}
															classImage={'ph-bag-electronic-cart-presciption-producs-title-on-demand-image'}
															webp={item.interface.on_demand.image.webp}
															jp2={item.interface.on_demand.image.jp2}
															jxr={item.interface.on_demand.image.jxr}
															default={item.interface.on_demand.image.png}
															title={'Contra pedido'}
														/>
													</div>
												)}
												{item.interface.magistral && (
													<div className="ph-bag-electronic-cart-presciption-product-description-on-demand-container">
														<h4 className="ph-bag-electronic-cart-presciption-product-description-on-demand">{item.interface.magistral.title}</h4>
														<Images
															classContent={'ph-bag-electronic-cart-presciption-producs-title-image-on-demand-container'}
															classImage={'ph-bag-electronic-cart-presciption-producs-title-on-demand-image'}
															webp={item.interface.magistral.image.webp}
															jp2={item.interface.magistral.image.jp2}
															jxr={item.interface.magistral.image.jxr}
															default={item.interface.magistral.image.png}
															title={'Contra pedido'}
														/>
													</div>
												)}
											</>
										)}
									</div>
								</div>
							</Col>
							<Col span={24}>
								<h4 className="ph-bag-electronic-cart-presciption-pharmacies-title">{translates.pharmacies[ENV_COUNTRY]} disponibles:</h4>
							</Col>
							<Col span={24}>
								<div className="ph-bag-electronic-cart-presciption-pharmacies-container">
									{item.pharmacies.map((element, i) => (
										<div className="ph-bag-electronic-cart-presciption-panel-pharmacy-container" key={i}>
											<div className="ph-bag-electronic-cart-presciption-panel-pharmacy-title-description">
												<div className="ph-bag-electronic-cart-presciption-panel-pharmacy-title-inner-description">
													<Images
														classContent={'ph-bag-electronic-cart-presciption-panel-pharmacy-image-container'}
														classImage={'ph-bag-electronic-cart-presciption-panel-pharmacy-image'}
														default={element.pharmacy_image}
														title={'Electronic Prescription Image'}
													/>
													<h4 className="ph-bag-electronic-cart-presciption-panel-pharmacy-title">{element.name_edited_pharmacy}</h4>
												</div>
												{element.interface.on_demand || element.interface.magistral ? (
													<div className="ph-bag-electronic-cart-presciption-panel-pharmacy-on-demand-container">
														{element.interface_quotation && (
															<div>
																<h3 className="ph-bag-electronic-cart-presciption-panel-pharmacy-on-demand-title">Fecha de llegada a la {translates.pharmacies[ENV_COUNTRY]}: </h3>
																<div className="ph-bag-electronic-cart-presciption-panel-pharmacy-on-demand-inner-container">
																	<h3 className="ph-bag-electronic-cart-presciption-panel-pharmacy-on-demand-title">
																		{moment(element.interface_quotation.schedule_selected).format('DD/MM/YYYY, h:mm:ss a')}
																	</h3>
																	{element.interface.on_demand && (
																		<Images
																			classContent={'ph-bag-electronic-cart-presciption-panel-pharmacy-on-demand-image-container'}
																			classImage={'ph-bag-electronic-cart-presciption-panel-pharmacy-on-demand-image'}
																			webp={element.interface.on_demand.image.webp}
																			jp2={element.interface.on_demand.image.jp2}
																			jxr={element.interface.on_demand.image.jxr}
																			default={element.interface.on_demand.image.default}
																			title={element.interface.on_demand.title}
																		/>
																	)}
																	{element.interface.magistral && (
																		<Images
																			classContent={'ph-bag-electronic-cart-presciption-panel-pharmacy-on-demand-image-container'}
																			classImage={'ph-bag-electronic-cart-presciption-panel-pharmacy-on-demand-image'}
																			webp={element.interface.magistral.image.webp}
																			jp2={element.interface.magistral.image.jp2}
																			jxr={element.interface.magistral.image.jxr}
																			default={element.interface.magistral.image.default}
																			title={element.interface.magistral.title}
																		/>
																	)}
																</div>
															</div>
														)}
													</div>
												) : (
													<Stock stock={element.stock} />
												)}
											</div>
											{!props.isMobile && (
												<>
													<Spacer />
													<div className="ph-bag-electronic-cart-presciption-panel-pharmacy-price">
														<Price eCart={true} detail={element} />
													</div>
												</>
											)}
											<Spacer />
											<div className="ph-bag-electronic-cart-presciption-panel-pharmacy-button-container">
												{props.isMobile && <Price eCart={true} detail={element} />}
												{element.stock > 0 ? (
													<Button
														className="ph-bag-electronic-cart-presciption-panel-pharmacy-button"
														onClick={() => {
															handleAddProductCartElectronic(index, element, element.interface_quotation ? element.interface_quotation.quantity : 1, 'prescription', null, props.cartForm);
														}}
														disabled={element.stock <= 0 && element.on_demand !== true ? true : false || props.isAddedCart || isBagRequest}
													>
														AGREGAR
													</Button>
												) : (
													<Button
														disabled={isBagRequest}
														className="ph-bag-electronic-cart-presciption-panel-pharmacy-button-alternative"
														onClick={() => {
															hableAlternativeProduct(element);
														}}
													>
														ALTERNATIVAS
													</Button>
												)}
											</div>
										</div>
									))}
								</div>
							</Col>
						</Row>
					</Collapse.Panel>
				))}
			</Collapse>
		</div>
	);
}
