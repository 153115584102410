import { AxiosAdapter } from '../../../../_config/v2/adapters/axios/axios.adapter';
import { Http } from '../../../../_config/v2/adapters/axios/axios.protocols';
import { Enviroment } from '../../../../_config/constants/enviroment-v2.constant';

export class Service {
	async getPurchaseHistory(token, page) {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${Enviroment.API_PHARMACY}/purchase/history-by-email`,
			params: { page: page },
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}
}
