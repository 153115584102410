/** @format */

import React, { Component } from 'react';

import Spacer from '../../../../../../components/Commons/Spacer';

import { formatPrice } from '../../../../../../components/Commons/Hooks/Functions/FormatPrice';

import './style.scss';

export default class DescriptionPrices extends Component {
	render() {
		return (
			<h3 className={`${this.props.deliveryTotal ? 'ph-checkout-box-subtotal-price-delivery' : 'ph-checkout-box-subtotal-price'}`}>
				{this.props.title}
				<Spacer />
				<span
					className={`${this.props.colorDiscount ? 'ph-checkout-box-subtotal-promotion' : ''} ${this.props.colorVoucher ? 'ph-checkout-box-subtotal-voucher' : ''} ph-checkout-box-subtotal-price-info`}
				>
					{formatPrice(this.props.price)}
				</span>
			</h3>
		);
	}
}
