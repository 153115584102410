import React from 'react';
import { Steps } from 'antd';
import './style.scss';
const { Step } = Steps;
export default function CustomSteps(props) {
	return (
		<>
			<Steps {...props}>
				{props.items.map((item, index) => {
					return <Step key={`step-${index}`} {...item} />;
				})}
			</Steps>
		</>
	);
}
