import { FormatNumber } from '../../../../../../../../../_config/utils/format-number.util';
export const pricePharmacy = {
	CL: {
		handle: () => {
			return FormatNumber.CL.handleFormat(0)
		},
	},
	AR: {
		handle: () => {
			return 'Gratis';
		},
	},
};
