/** @format */

import React from 'react';
import renderHTML from 'react-render-html';
import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';

import MetaDescription from '../../../../components/Commons/MetaDescription';
import MainTitlePage from '../../../../components/Commons/MainTitlePage';
import './style.scss';

import data from './data.json';

export default function FrequentQuestionsCL(props) {
	const { t } = useTranslation('global');

	return (
		<div className="ph-frequent-questions-global-container">
			<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			<div className="ph-frequent-questions-title-page-container">
				<MainTitlePage rootImage={true} title={'Preguntas Frecuentes'} descriptionPage={'Información segura para tu compra'} image={'react-page-static/asked-questions/frequentQuestions'} />
			</div>

				<Collapse bordered={false} accordion>
					{data.map((item, key) => (
						<Collapse.Panel header={item.title} key={key}>
								{renderHTML(item.description.replace(/<h1.*<\/h1> /i, ''))}
						</Collapse.Panel>

					))}
				</Collapse>
		</div>
	);
}
