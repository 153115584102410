/** @format */

import React, { useState } from 'react';

import { Row, Col, Button, Modal } from 'antd';

import { FilePdfOutlined } from '@ant-design/icons';

import Images from '../../../../../../components/Commons/Images';

import { GetRecipes } from '../../../../../../components/Commons/Hooks/Functions/GetRecipes';
import { ENV_IMG_HEADER } from '../../../../../../components/Commons/Hooks/Variables/Enviroment';

import './style.scss';

export default function RecipeList(props) {
	const [isRecipeList, setRecipeList] = useState(null);
	const [isVisible, setVisible] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [isPreviewModal, setPreviewModal] = useState({ visible: false, image: null });

	const handleCloseModal = () => {
		setVisible(false);
	};

	const handleRecipeList = async () => {
		if (!isRecipeList) {
			setLoading(true);
			await GetRecipes().then((response) => {
				if (response) {
					setVisible(true);
					setRecipeList(response);
					setLoading(false);
				}
			});
		} else {
			setVisible(true);
		}
	};

	const handleAddListRecipes = (item) => {
		let products = [...props.filteredProductsRecipe];
		products.forEach((element) => {
			if (element.objectID === props.item.objectID) {
				element.recipe = item.recipe;
				element.recipe_save = true;
				delete element.recipe_will_save;
			}
		});
		props.handlefilteredProducts(products);
		setVisible(false);
	};

	if (localStorage.getItem('userEmail') && localStorage.getItem('userToken')) {
		return (
			<>
				<div className="ph-modal-checkout-recipes-modal-button-container" onClick={() => handleRecipeList()}>
					<div>
						<Images
							classContent={'ph-modal-checkout-recipes-modal-button-image-container'}
							classImage={'ph-modal-checkout-recipes-modal-button-image'}
							webp={`${ENV_IMG_HEADER}/pharol-recipes.webp`}
							jp2={`${ENV_IMG_HEADER}/pharol-recipes.jp2`}
							jxr={`${ENV_IMG_HEADER}/pharol-recipes.jxr`}
							default={`${ENV_IMG_HEADER}/pharol-recipes.png`}
							title={'address logo gris'}
						/>
					</div>
					<Button loading={isLoading} className="ph-modal-checkout-recipes-modal-button">
						Recetas <br />
						Guardadas
					</Button>
				</div>
				<Modal
					wrapClassName="ph-modal-checkout-recipes-modal-container"
					title="Gestión de Recetas"
					visible={isVisible}
					onCancel={() => handleCloseModal()}
					onOk={''}
					cancelButtonProps={{ style: { display: 'none' } }}
					okButtonProps={{ style: { display: 'none' } }}
					width="500px"
				>
					<h3 className="ph-modal-checkout-recipes-modal-title">Selecciona una receta de la lista:</h3>
					{isRecipeList?.data?.length > 0 ? (
						<>
							<div className="ph-modal-checkout-recipes-list-main-container">
								{isRecipeList?.data?.map((item) => (
									<div className="ph-modal-checkout-recipes-list-container" key={item.recipe_id}>
										<Row>
											<Col span={14} className="ph-modal-checkout-recipes-list-title-container">
												<h4 className="ph-modal-checkout-recipes-list-title">NOMBRE:</h4>
												<h3 className="ph-modal-checkout-recipes-list-subtitle">{item.name}</h3>
											</Col>
											<Col span={5} className="ph-modal-checkout-recipes-list-button-container">
												<Button className="ph-modal-checkout-recipes-list-second-button" onClick={() => setPreviewModal({ visible: true, image: item.recipe })}>
													<h3 className="ph-modal-checkout-recipes-list-button-second-title">Ver Receta</h3>
												</Button>
											</Col>
											<Col span={5} className="ph-modal-checkout-recipes-list-button-container">
												<Button className="ph-modal-checkout-recipes-list-button" onClick={() => handleAddListRecipes(item)}>
													<h3 className="ph-modal-checkout-recipes-list-button-title">Agregar</h3>
												</Button>
											</Col>
										</Row>
									</div>
								))}
							</div>
						</>
					) : (
						<div className="ph-modal-checkout-recipes-list-no-data-container">
							<h3 className="ph-modal-checkout-recipes-list-no-data-title">no se encontraron recetas</h3>
						</div>
					)}
				</Modal>
				<Modal
					wrapClassName="ph-checkout-receipt-modal-container"
					visible={isPreviewModal.visible}
					title={'Vista Previa:'}
					footer={null}
					onCancel={() => setPreviewModal({ visible: false, image: null })}
				>
					{isPreviewModal?.image && (
						<>
							{isPreviewModal.image.endsWith('.pdf') ? (
								<>
									<div className="ph-checkout-prescription-modal-pdf-container">
										<FilePdfOutlined className="ph-checkout-prescription-modal-pdf-icon" />
									</div>
									<a href={isPreviewModal.image} target="_blank" rel="noreferrer">
										{isPreviewModal.image}
									</a>
								</>
							) : (
								<img alt="example" style={{ width: '100%' }} src={isPreviewModal.image} />
							)}
						</>
					)}
				</Modal>
			</>
		);
	} else {
		return '';
	}
}
