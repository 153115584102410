/**
 * Con links internos
 */
import React, { useEffect, useState, Fragment } from 'react';
import CustomCarousel from '../Antd/Carousel';
import { Services } from './services';
import './style.scss';

export default function SlidersInternal(props) {
	const [sliders, setSliders] = useState([]);
	const [isLoading, setLoading] = useState(true);

	useEffect(() => {
		new Services().getTempplate({ template: props.template, location: props.location }).then((response) => {
			console.log('sliders', response);
			if (response.length > 0) {
				setSliders([...response]);
				setLoading(false);
			}
		});
	}, [props.template, props.location]);

	if (isLoading) {
		return Fragment;
	}

	return (
		<CustomCarousel autoplay={true} className={'slider-internal'}>
			{sliders.map((item, index) => (
				<div key={index} onClick={() => window.open(item.link)}>
					<img src={item.image} alt={item.template} />
				</div>
			))}
		</CustomCarousel>
	);
}
