import { Enviroment } from '../../_config/constants/enviroment-v2.constant';
export const Country = {
	handleElasticPrecision: () => {
		return Enviroment.ELASTIC_CL_APP_PRODUCTS;
	},
	handleFilters: (params) => {
		let filters = [];
		filters.push({
			field: 'pharmacy',
			values: [Enviroment.ROOT_PHARMACY],
		});
		filters.push({
			field: 'price',
			values: [0],
			type: 'none',
		});
		return filters;
	},
	handleSort: () => {
		return;
	},
};
