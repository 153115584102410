/** @format */

import React from 'react'

import { Form } from 'antd'

import { ENV_COUNTRY } from '../../Hooks/Variables/Enviroment'
import InputMask from '../../InputMask'

import { rulesValidationMask } from '../../InputMask/rules'
import OptionsByCountry from './data.json'

class InputPhone extends React.Component {
	render() {
		console.log('inputPhone',)
		return (
			<>
				<Form.Item
					label={this.props.label}
					name={`${this.props.inputPhone ? this.props.inputPhone : 'phone'}`}
					rules={rulesValidationMask.rulesPhone(
						OptionsByCountry[ENV_COUNTRY].len
					)}>
					<InputMask
						prefix={OptionsByCountry[ENV_COUNTRY].flag}
						maskstyle={this.props.classInput}
						mask={OptionsByCountry[ENV_COUNTRY].mask}
						placeholder={OptionsByCountry[ENV_COUNTRY].mask}
						onChange={this.props.onChange}
					/>
				</Form.Item>
			</>
		)
	}
}

export default InputPhone
