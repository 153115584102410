import { Enviroment } from '../../../../../../_config/constants/enviroment-v2.constant';
export const handleAddressContainer = (location) => {
	let className = 'ph-address-container';
	if (location.pathname.match(/bag-electronic-prescription/)) {
		className = className + ' ph-address-d-none ph-address-container';
	}

	if (location.pathname.match(/product-check/)) {
		className = ' ph-address-d-none ph-address-container';
	}

	if (Enviroment.COUNTRY === 'AR') {
		className = className + ' ph-address-container-arg';
	}

	return className;
};
