/** @format */

import React from 'react';
import { useLocation } from 'react-router-dom';
import NavBarDesktop from './components/NavBarDesktop';
import NavbarMobile from './components/NavbarMobile';
import NavbarMobileTabs from './components/NavbarMobileTabs';
import { GlobalConsumer } from '../../Context/Global';
import { useQuery } from 'react-query';
import { MenuInit } from './services';

const MainHeader = () => {
	const location = useLocation();
	const { isMobile } = GlobalConsumer();

	 const { data, status } = useQuery('query-common', MenuInit)
	return (
		<>
			{!isMobile ? (
				<NavBarDesktop navBar={data} status={status}/>
			) : (
				<>
					<NavbarMobile navBar={data} status={status}/>
					{!location.pathname.match(/products/) && <NavbarMobileTabs />}
				</>
			)}
		</>
	);
};
export default MainHeader;
