/** @format */

import { useState } from "react";
import { setGlobal } from "reactn";

import AddProductCart from "../../../../components/Commons/Hooks/Functions/AddProductCart";

import { BagConsumer } from "../../../../components/Commons/Context/Bag";
import { GlobalConsumer } from "../../../../components/Commons/Context/Global";
import { GetUserData } from "../../../../components/Commons/Hooks/Functions/GetUserData";

import { handleLocals } from "../BagCalculation/components/BagLocals";
import { Calculation } from "../BagCalculation/services";

import { BagMergeLocals } from "../BagMergeLocals";
import { handleCountPharmacies } from "../../functions/BagCountPharmacies";

import BagTotal from "../BagTotal";

export default function HandleAddProductElectronic() {
  const { handleBagTotal } = BagTotal();
  const { handleAddProductCart } = AddProductCart();
  const { isLocation, isLoginUser, setBag } = GlobalConsumer();
  const {
    isBagData,
    isElectronicPrescription,
    isBagDeliveryPharmacies,
    isBagRetirement,
    isBagRetirementPharmacies,
    setBagData,
    setBagRetirement,
    setBagDeliveryPharmacies,
    setLoadingGroup,
    setBagRetirementPharmacies,
    setBagTotalCart,
    setBagRequest,
    setElectronicPrescription,
  } = BagConsumer();

  const [open, setOpen] = useState([]);

  const handleElectronicVerification = (item) => {
    if (isElectronicPrescription?.referal) {
      localStorage.setItem(
        "ePrescriptionToken",
        JSON.stringify(isElectronicPrescription.referal)
      );
    }
    item.forEach((element) => {
      if (element.interface?.on_demand || item.interface?.magistral) {
        localStorage.setItem(
          "onDemandData",
          JSON.stringify(isElectronicPrescription.user_info)
        );
        setGlobal(() => ({
          onDemandCart: true,
        }));
      }
    });
  };

  const handleVerifyVoucherFields = (cartForm) => {
    const fields = cartForm.getFieldsValue(true);
    for (const clave in fields) {
      if (!fields[clave]) delete fields[clave];
    }
    return fields;
  };

  const handleAddProductCartElectronic = async (
    indexCollapse,
    product,
    quantity,
    electronicType,
    productSelected = null,
    cartForm
  ) => {
    setBagRequest(true);
    if (electronicType === "quote") {
      product.interface = {
        ...product.interface,
        ...productSelected.interface,
      };
      product.interface_quotation = { ...productSelected.interface_quotation };
      product.code_minsal = productSelected.code_minsal;
      product["recipe"] = productSelected.recipe;
      /*
			if (!JSON.parse(localStorage.getItem('ePrescriptionToken'))) {
				localStorage.setItem('ePrescriptionToken', JSON.stringify(isElectronicPrescription.referal))
			}
			*/
      let electronicCopy = JSON.parse(JSON.stringify(isElectronicPrescription));
      electronicCopy.products[indexCollapse].selected = true;
      setElectronicPrescription(electronicCopy);
    }

    handleAddProductCart(product, quantity, true);
    indexCollapse = indexCollapse.toString();
    let collapseItems = open.sort();
    collapseItems = collapseItems.filter(
      (element) => element !== `${indexCollapse}`
    );
    setOpen(collapseItems);
    const products = JSON.parse(localStorage.getItem("cart"));
    handleElectronicVerification(products);

    const filterProducts = products.filter(
      (element) => element.pharmacy === product.pharmacy
    );
    setLoadingGroup((prevState) => ({
      ...prevState,
      [product.pharmacy]: true,
    }));
    filterProducts.forEach((item) => {
      delete item.type_retirement;
    });
    let localGroupPharmacy;

    let userData;
    if (isLoginUser?.email) userData = await GetUserData(isLoginUser.email);

    const voucherPharmacies = handleVerifyVoucherFields(cartForm);

    await Calculation(
      filterProducts,
      isLocation,
      isLoginUser.email,
      isLoginUser.token,
      voucherPharmacies,
      handleLocals(filterProducts),
      "domicilio",
      userData,
      false,
      handleCountPharmacies()
    ).then(async (response) => {
      let responseService;
      if (response?.locals_group?.length > 1) {
        const localsGroup = await BagMergeLocals(response.locals_group);
        responseService = { ...response, locals_group: [localsGroup] };
      } else responseService = response;

      let deliveryPharmacies = isBagDeliveryPharmacies;
      deliveryPharmacies[filterProducts[0].pharmacy] =
        responseService && responseService.locals_group[0].locals.length > 0
          ? responseService.locals_group[0]
          : false;
      setBagDeliveryPharmacies({ ...deliveryPharmacies });
    });

    await Calculation(
      filterProducts,
      isLocation,
      isLoginUser.email,
      isLoginUser.token,
      voucherPharmacies,
      handleLocals(filterProducts),
      "farmacia",
      userData,
      false,
      handleCountPharmacies()
    ).then(async (response) => {
      let responseService;
      if (response?.locals_group?.length > 1) {
        const localsGroup = await BagMergeLocals(response.locals_group);
        responseService = { ...response, locals_group: [localsGroup] };
      } else responseService = response;

      localGroupPharmacy = responseService;
      let retirementPharmacies = isBagRetirementPharmacies;
      retirementPharmacies[filterProducts[0].pharmacy] =
        responseService && responseService.locals_group[0].locals.length > 0
          ? responseService.locals_group[0]
          : false;
      setBagRetirementPharmacies({ ...retirementPharmacies });
    });

    let validRetirement = isBagRetirement;
    let locals_group;
    let bagData;
    if (isBagData) {
      locals_group = isBagData.locals_group.map((item) => {
        if (item.name === filterProducts[0].pharmacy) {
          return localGroupPharmacy.locals_group[0];
        } else {
        }
        return item;
      });
      const filteredNewProduct = isBagData.locals_group.filter(
        (element) => element.name === localGroupPharmacy.locals_group[0].name
      );
      if (filteredNewProduct.length <= 0)
        locals_group = [...locals_group, localGroupPharmacy.locals_group[0]];

      validRetirement[filterProducts[0].pharmacy] = {
        info: filterProducts,
        deliveryStatus: false,
        type_retirement: null,
      };
      bagData = { ...isBagData, locals_group: [...locals_group] };
    } else {
      validRetirement = {
        [localGroupPharmacy.locals_group[0].name]: {
          info: filterProducts,
          deliveryStatus: false,
          type_retirement: null,
        },
      };
      bagData = { ...localGroupPharmacy };
    }

    setBagRetirement({ ...validRetirement });
    setBagData(bagData);
    setBagTotalCart(handleBagTotal(bagData));
    setBag(products);
    setLoadingGroup((prevState) => ({
      ...prevState,
      [product.pharmacy]: false,
    }));
    setBagRequest(false);
  };

  const handleDeleteProductFromCartElectronic = async (
    product,
    productsSelected,
    setProductsSelected
  ) => {
    const selectedIndex = productsSelected.findIndex(
      (search) => parseInt(search.id_pharol) === product.id_pharol
    );
    if (selectedIndex >= 0) {
      let electronicCopy = { ...isElectronicPrescription };
      let productSelectedCopy = [...productsSelected];
      productSelectedCopy.splice(selectedIndex, 1).filter((n) => n);
      electronicCopy.products[selectedIndex].selected = false;
      setProductsSelected(productSelectedCopy);
      setElectronicPrescription(electronicCopy);
    }
    return;
  };

  return {
    handleAddProductCartElectronic,
    handleDeleteProductFromCartElectronic,
    open,
    setOpen,
  };
}
