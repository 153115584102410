/** @format */

import React from 'react';
// import { useHistory } from 'react-router-dom'

// import { ENV_COUNTRY } from '../../Hooks/Variables/Enviroment'

// import { default as iconLineal } from '../../Assets/Icons/Lineals/Disabled/favorite.svg'
// import { default as iconFilled } from '../../Assets/Icons/Filled/Enabled/favorite.svg'
// import { default as iconFilledArg } from '../../Assets/Icons/Filled/Enabled/Argentina/favoritos1-1-verde.png'

// import { DeleteFavorites, AddFavorites, FavoritesNotification } from '../Functions'

// import './style.scss'

export const FavoriteActions = (props) => {
	// const history = useHistory()
	// const [isImgFavorites, setImgFavorites] = useState(null)
	// const [isFavoritesAction, setFavoritesAction] = useState(null)
	// const [isFavorites] = useState(JSON.parse(localStorage.getItem('userFavorites')))
	// const ambientIcon = ENV_COUNTRY === 'AR' ? iconFilledArg : iconFilled

	// const handleFavorites = (item) => {
	// 	const userToken = localStorage.getItem('userToken')
	// 	if (isFavoritesAction === 'add') {
	// 		setImgFavorites(ambientIcon)
	// 		setFavoritesAction('delete')
	// 		AddFavorites(userToken, item).then((response) => {
	// 			if (response) {
	// 				localStorage.setItem('userFavorites', JSON.stringify(response))
	// 			}
	// 		})
	// 	} else if (isFavoritesAction === 'delete') {
	// 		setImgFavorites(iconLineal)
	// 		setFavoritesAction('add')
	// 		DeleteFavorites(userToken, item.id_pharol).then((response) => {
	// 			if (response) {
	// 				localStorage.setItem('userFavorites', JSON.stringify(response))
	// 			}
	// 		})
	// 	} else if (isFavoritesAction === 'unautorized') {
	// 		FavoritesNotification(history)
	// 	}
	// }

	// useEffect(() => {
	// 	if (isFavorites) {
	// 		if (isFavorites.indexOf(props.item.id_pharol) !== -1) {
	// 			setImgFavorites(ambientIcon)
	// 			setFavoritesAction('delete')
	// 		} else {
	// 			setImgFavorites(iconLineal)
	// 			setFavoritesAction('add')
	// 		}
	// 	} else {
	// 		setImgFavorites(iconLineal)
	// 		setFavoritesAction('unautorized')
	// 	}
	// }, [isFavorites, props.item.id_pharol, ambientIcon])

	// return (
	// 	<>
	// 		{isImgFavorites !== null && (
	// 			<>
	// 				<div
	// 					className={`${
	// 						props.listProductFavorite ? 'ph-product-favorite-actions-list-main-container' : ''
	// 					} ph-product-favorite-actions-main-container`}
	// 					onClick={() => handleFavorites(props.item)}>

	// 				</div>
	// 			</>
	// 		)}
	// 	</>
	// )

	return <></>;
};
