import { BucketMarketplace } from './bucket.constant';
import pharol_generic from './img/pharol_generic.png';
import bienvenida from './img/welcome/bienvenida.png';
import cerrado from './img/welcome/cerrado.png';
import sinStock from './img/welcome/sin_stock.png';

export const Country = {
	CL: {
		sectionLogin: BucketMarketplace + 'logo-x.jpg',
		pharolGeneric: pharol_generic, //PARCHE FABO
	},
	AR: {
		sectionLogin: BucketMarketplace + 'logo-x.jpg',
		bienvenida: bienvenida, //PARCHE FABO
		cerrado: cerrado, //PARCHE FABO
		sinStock: sinStock, //PARCHE FABO
	},
};
