/** @format */

import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { Row, Col, Form, Select, Checkbox } from 'antd';

import InputPhone from '../../../../../../components/Commons/InputCountry/Phone';
import InputRut from '../../../../../../components/Commons/InputCountry/Rut';
import InputField from '../../../../../../components/Commons/Input';
import InputMask from '../../../../../../components/Commons/InputMask';

import { rulesValidationMask } from '../../../../../../components/Commons/InputMask/rules';

import { ENV_COUNTRY } from '../../../../../../components/Commons/Hooks/Variables/Enviroment';

import BillModal from './BillModal';

import './style.scss';

const { Option } = Select;

export default function CheckoutPersonalData(props) {
	const [date] = useState(new Date());
	const [typeHouse, setTypeHouse] = useState(null);

	return (
		<>
			<div className="ph-retirement-personal-data-global-container">
				<h3 className="ph-retirement-personal-data-title">Datos Personales:</h3>
				<Row>
					<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-retirement-personal-data-form-container">
						<InputField
							className={'ph-retirement-personal-data-field-input'}
							inputName={'first_name'}
							inputNameLabel={'Nombre'}
							inputNameRule={true}
							inputNameMessage={'Ingresa tu nombre'}
							inputNameType={'text'}
							inputNameRules={'rulesFirstName'}
							onChange={(item) => props.handlePersonalForm('firstName', item.target.value)}
						/>
					</Col>
					<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-retirement-personal-data-form-container">
						<InputField
							className={'ph-retirement-personal-data-field-input'}
							inputName={'last_name'}
							inputNameLabel={'Apellido'}
							inputNameRule={true}
							inputNameMessage={'Ingresa tu apellido'}
							inputNameType={'text'}
							inputNameRules={'rulesLastName'}
							onChange={(item) => props.handlePersonalForm('lastName', item.target.value)}
						/>
					</Col>
					<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-retirement-personal-data-form-container">
						<InputField
							className={'ph-retirement-personal-data-field-input'}
							inputName={'email'}
							inputNameLabel={'Correo electrónico'}
							inputNameRule={true}
							inputNameMessage={'E-mail es obligatorio'}
							inputNameType={'text'}
							inputNameRules={'rulesEmail'}
							onChange={(item) => props.handlePersonalForm('email', item.target.value)}
						/>
					</Col>
					<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-retirement-personal-data-form-container">
						<InputPhone classInput={'ph-retirement-personal-data-field-input'} onChange={(item) => props.handlePersonalForm('phone', item.target.value)} />
					</Col>

					<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-retirement-personal-data-form-container">
						<Form.Item
							name="sex"
							rules={[
								{
									required: true,
									message: 'Introduzca su Género',
								},
							]}
						>
							<Select placeholder="Género" className="ph-retirement-personal-data-field-selector" size="large" style={{ width: '100%' }} onChange={(item) => props.handlePersonalForm('sex', item)}>
								<Option value="Masculino">Masculino</Option>
								<Option value="Femenino">Femenino</Option>
							</Select>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-retirement-personal-data-form-container">
						<InputRut classInput={'ph-retirement-personal-data-field-input'} onChange={(item) => props.handlePersonalForm('rut', item.target.value)} />
					</Col>
					<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-retirement-personal-data-form-container">
						<Form.Item name="age" rules={rulesValidationMask.rulesAge(date)}>
							<InputMask className="ph-retirement-personal-data-field-input" mask="99/99/9999" placeholder="31/12/2021" onChange={(item) => props.handlePersonalForm('birth', item.target.value)} />
						</Form.Item>
					</Col>
					{ENV_COUNTRY === 'MX' && (
						<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-retirement-personal-data-form-container">
							<Form.Item name="curp" rules={rulesValidationMask.rulesCURP}>
								<InputMask
									className={'ph-retirement-personal-data-field-input'}
									mask="aaaa999999aaaaaa*9"
									placeholder="CURP"
									onChange={(item) => props.handlePersonalForm('curp', item.target.value)}
								/>
							</Form.Item>
						</Col>
					)}
				</Row>
				{ENV_COUNTRY === 'MX' && (
					<>
						<h3 className="ph-retirement-personal-data-title">Dirección personal:</h3>
						<Row>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-retirement-personal-data-form-container">
								<InputField
									className={'ph-retirement-personal-data-field-input'}
									inputName={'cologne_personal'}
									inputNameLabel={'Colonia'}
									inputNameRule={true}
									inputNameMessage={'Debe colocar una Colonia'}
									inputNameType={'text'}
									inputNameRules={'rulesRequired'}
									onChange={(item) => props.handlePersonalForm('cologne', item.target.value)}
								/>
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-retirement-personal-delivery-form-container">
								<InputField
									className={'ph-retirement-personal-delivery-field-input'}
									inputName={'comune_personal'}
									inputNameLabel={'Municipio'}
									inputNameRule={true}
									inputNameMessage={'Ingrese su Comuna'}
									inputNameType={'text'}
									inputNameRules={'rulesRequired'}
									disabled={false}
									onChange={(item) => props.handlePersonalForm('comune', item.target.value)}
								/>
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-retirement-personal-data-form-container">
								<InputField
									className={'ph-retirement-personal-delivery-field-input'}
									inputName={'street_name_personal'}
									inputNameLabel={'Calle'}
									inputNameRule={true}
									inputNameMessage={'Ingresa la calle'}
									inputNameType={'text'}
									inputNameRules={'rulesRequired'}
									disabled={false}
									onChange={(item) => props.handlePersonalForm('street', item.target.value)}
								/>
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-retirement-personal-data-form-container">
								<InputField
									className={'ph-retirement-personal-delivery-field-input'}
									inputName={'street_number_personal'}
									inputNameLabel={'No. de calle'}
									inputNameRule={true}
									inputNameMessage={'Ingrese su No. de calle'}
									inputNameType={'text'}
									inputNameRules={'rulesRequired'}
									onChange={(item) => props.handlePersonalForm('street_number', item.target.value)}
								/>
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-retirement-personal-delivery-form-container">
								<Form.Item
									name="type_house_personal"
									rules={[
										{
											required: true,
											message: 'Tipo de vivienda obligatorio',
										},
									]}
								>
									<Select
										placeholder="Tipo de vivienda"
										className="ph-retirement-personal-delivery-selector"
										size="large"
										style={{ width: '100%' }}
										onChange={(value) => {
											setTypeHouse(value);
											props.handlePersonalForm('type_house', value);
										}}
									>
										<Option value="casa">Casa</Option>
										<Option value="departamento">Departamento</Option>
									</Select>
								</Form.Item>
							</Col>
							{typeHouse === 'departamento' && (
								<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-retirement-personal-delivery-form-container">
									<InputField
										className={'ph-retirement-personal-delivery-field-input'}
										inputName={'number_department_personal'}
										inputNameLabel={'No. Departamento'}
										inputNameRule={true}
										inputNameMessage={'Ingresa el No. de su departamento'}
										inputNameType={'text'}
										inputNameRules={'rulesRequired'}
										onChange={(item) => props.handlePersonalForm('number_department', item.target.value)}
									/>
								</Col>
							)}
							<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-retirement-personal-data-form-container">
								<InputField
									className={'ph-retirement-personal-data-field-input'}
									inputName={'city_personal'}
									inputNameLabel={'Ciudad'}
									inputNameRule={true}
									inputNameMessage={'Debe colocar una Ciudad'}
									inputNameType={'text'}
									inputNameRules={'rulesRequired'}
									onChange={(item) => props.handlePersonalForm('city', item.target.value)}
								/>
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-retirement-personal-data-form-container">
								<InputField
									className={'ph-retirement-personal-data-field-input'}
									inputName={'province_personal'}
									inputNameLabel={'Estado'}
									inputNameRule={true}
									inputNameMessage={'Debe colocar una Provincia'}
									inputNameType={'text'}
									inputNameRules={'rulesRequired'}
									onChange={(item) => props.handlePersonalForm('province', item.target.value)}
								/>
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-retirement-personal-data-form-container">
								<InputField
									className={'ph-retirement-personal-data-field-input'}
									inputName={'postal_code_personal'}
									inputNameLabel={'Código Postal'}
									inputNameRule={true}
									inputNameMessage={'Debe colocar un código postal'}
									inputNameType={'text'}
									inputNameRules={'rulesRequired'}
									onChange={(item) => props.handlePersonalForm('postal_code', item.target.value)}
								/>
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-retirement-personal-data-form-container">
								<InputField
									className={'ph-retirement-personal-data-field-input'}
									inputName={'between_street_personal'}
									inputNameLabel={'Calle referencial'}
									inputNameRule={true}
									inputNameType={'text'}
									onChange={(item) => props.handlePersonalForm('between_street', item.target.value)}
								/>
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-retirement-personal-data-form-container">
								<InputField
									className={'ph-retirement-personal-delivery-field-input'}
									inputName={'reference_personal'}
									inputNameLabel={'Referencia'}
									inputNameRule={false}
									inputNameType={'text'}
									onChange={(item) => props.handlePersonalForm('reference', item.target.value)}
								/>
							</Col>
						</Row>
					</>
				)}
				<Row>
					<Col span={24}>
						<div className="ph-retirement-personal-data-agree-main-container">
							<Form.Item
								className="ph-retirement-personal-data-agree-container"
								name="agreement"
								valuePropName="checked"
								rules={[
									{
										validator: (_, value) => (value ? Promise.resolve() : Promise.reject('Acepta las condiciones para continuar.')),
									},
								]}
							>
								<Checkbox></Checkbox>
							</Form.Item>
							<h4 className="ph-retirement-personal-data-agree-title">
								Acepto los
								<Link className="ph-retirement-personal-data-agree-link" to="/terms-conditions">
									{' '}
									Términos, Condiciones
								</Link>{' '}
								y
								<Link className="ph-retirement-personal-data-agree-link" to="/politics">
									{' '}
									Políticas de Privacidad
								</Link>
							</h4>
							{ENV_COUNTRY === 'MX' && <BillModal date={date} personalData={props.personalData} billing={props.billing} setBilling={props.setBilling} />}
						</div>
					</Col>
				</Row>
			</div>
		</>
	);
}
