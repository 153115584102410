import { Events } from './events.constant';
export const ProductAdapter = ({ event = Events.view_item, product, position = 1, quantity = 1 }) => {
	console.log('ProductAdapter', { event, product, position, quantity });

	window.dataLayer.push({ ecommerce: null });
	window.dataLayer.push({
		event: event,
		ecommerce: {
			items: [
				{
					item_name: product.editable_name,
					item_id: `${product.pharmacy}-${product.code_internal}`,
					item_brand: product.laboratory,
					item_category: product.master_category,
					item_category2: product.category,
					item_category3: product.sub_category,
					index: position,
					quantity: quantity,
					price: product.price,
				},
			],
		},
	});
};
