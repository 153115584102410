/** @format */

import React, { useState, useEffect, useRef, createRef, useCallback } from 'react';
import { useParams } from 'react-router';
import { useGlobal } from 'reactn';
import { Row, Col, Select, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { Enviroment } from '../../../../../../../../_config/constants/enviroment-v2.constant';
import { ArrayPharmacyProductAdapter } from '../../../../../../../../_config/adapters/google-tag-manager/array-pharmacy-product.adapter';
import { Events } from '../../../../../../../../_config/adapters/google-tag-manager/events.constant';

import Images from '../../../../../../../../components/Commons/Images';
import { formatPrice } from '../../../../../../../../components/Commons/Hooks/Functions/FormatPrice';
import { ENV_COUNTRY, ENV_IMG } from '../../../../../../../../components/Commons/Hooks/Variables/Enviroment';

import LoadingPharmacies from './components/LoadingPharmacies';
import Stock from './components/Stock';
// import Dispatch from './components/Dispatch';
import PriceProduct from './components/PriceProduct';
import ButtonAdd from './components/ButtonAdd';
import Promotions from './components/ProductPromotion/components/Promotions/index';
// import PromotionPrice from './components/ProductPromotion/components/Price';

import { checkDelivery } from './utils/check-delivery.util';
import { checkPharmacy } from './utils/check-pharmacy.util';
import { pricePharmacy } from './utils/price-pharmacy.util';

import { GetListPharmacies } from './services';
import './style.scss';

export default function QuotePharmacies(props) {
	const params = useParams();
	const address = JSON.parse(localStorage.getItem('locationStore'));
	const pharmacyCollapse = useRef([]);
	const [isListPharmacies, setListPharmacies] = useState(false);
	const [isPharmaciesNoSort, setPharmaciesNoSort] = useState(null);
	const [isPharmacies, setPharmacies] = useGlobal('pharmaciesList');
	const [showFiltersMobile] = useState(false);
	const [loadingPharmacyCollapse, setLoadingPharmacyCollapse] = useState(false);
	const [isAddress] = useState({
		lat: address.lat.toString(),
		lng: address.lng.toString(),
	});

	const handleOrderPharmacy = async (item) => {
		let pharmacySorted;
		const copy = [...isPharmacies];
		if (item === 'asc') {
			function compare(a, b) {
				if (a.pharmacy < b.pharmacy) {
					return 1;
				}
				if (a.pharmacy > b.pharmacy) {
					return -1;
				}
				return 0;
			}
			pharmacySorted = copy.sort(compare);
		} else if (item === 'desc') {
			function compare(a, b) {
				if (a.pharmacy < b.pharmacy) {
					return -1;
				}
				if (a.pharmacy > b.pharmacy) {
					return 1;
				}
				return 0;
			}
			pharmacySorted = copy.sort(compare);
		} else if (item === 'priceDesc') {
			function compare(a, b) {
				if (a.price < b.price) {
					return 1;
				}
				if (a.price > b.price) {
					return -1;
				}
				return 0;
			}
			pharmacySorted = copy.sort(compare);
		} else if (item === 'priceAsc') {
			function compare(a, b) {
				if (a.price < b.price) {
					return -1;
				}
				if (a.price > b.price) {
					return 1;
				}
				return 0;
			}
			pharmacySorted = copy.sort(compare);
		} else if (item === 'stockAsc') {
			function compare(a, b) {
				if (a.stock < b.stock) {
					return 1;
				}
				if (a.stock > b.stock) {
					return -1;
				}
				return 0;
			}
			pharmacySorted = copy.sort(compare);
		} else if (item === 'stockDesc') {
			function compare(a, b) {
				if (a.stock < b.stock) {
					return -1;
				}
				if (a.stock > b.stock) {
					return 1;
				}
				return 0;
			}
			pharmacySorted = copy.sort(compare);
		} else if (item === 'locationDesc') {
			function compare(a, b) {
				if (a.nearest_local && b.nearest_local) {
					if (parseFloat(a.nearest_local.distance) < parseFloat(b.nearest_local.distance)) {
						return -1;
					}
					if (parseFloat(a.nearest_local.distance) > parseFloat(b.nearest_local.distance)) {
						return 1;
					}
					return 0;
				}
			}
			pharmacySorted = copy.sort(compare);
		} else if (item === 'locationAsc') {
			function compare(a, b) {
				if (a.nearest_local && b.nearest_local) {
					if (parseFloat(a.nearest_local.distance) > parseFloat(b.nearest_local.distance)) {
						return -1;
					}
					if (parseFloat(a.nearest_local.distance) < parseFloat(b.nearest_local.distance)) {
						return 1;
					}
					return 0;
				}
			}
			pharmacySorted = copy.sort(compare);
		}

		setPharmacies(pharmacySorted);
	};

	const handlePharmaciesRadius = async (item) => {
		const copy = [...isPharmaciesNoSort];
		console.log('copy', copy, 'distance', item);
		const filterByDistance = await copy.filter((element) => element.nearest_local.distance < item);
		console.log('handlePharmaciesRadius', filterByDistance);
		setPharmacies(filterByDistance);
	};

	const queryPharmacies = useCallback(
		() =>
			GetListPharmacies(params.id, isAddress).then((response) => {
				if (response) {
					pharmacyCollapse.current = response.map((_, i) => pharmacyCollapse.current[i] ?? createRef());
					setPharmaciesNoSort(response);
					const filterPharmacies = response.filter((iterator) => iterator.stock > 0);
					setPharmacies(filterPharmacies);
					ArrayPharmacyProductAdapter({ event: Events.view_item, products: filterPharmacies });
					if (filterPharmacies.length > 0) {
						setListPharmacies(true);
					}
				}
			}),
		[params.id, isAddress, setPharmacies],
	);

	useEffect(() => {
		(async () => {
			setLoadingPharmacyCollapse(true);
			await queryPharmacies();
			setLoadingPharmacyCollapse(false);
		})();
	}, [queryPharmacies]);

	const handleCollapseClick = (element, i) => {
		console.log('handleOnLoadCollapse', window.innerWidth);
		let content = pharmacyCollapse.current[i].current;
		if (window.innerWidth > 992) {
			if (content.style.maxHeight) {
				content.style.maxHeight = null;
				element.currentTarget.classList.remove('active-collapsed');
			} else {
				element.currentTarget.classList.add('active-collapsed');
				content.style.maxHeight = content.scrollHeight + 'px';
			}
		}
	};
	const handleCollapse = (element, i) => {
		if (window.innerWidth < 992) {
			let content = pharmacyCollapse.current[i].current;
			element.currentTarget.classList.add('active-collapsed');
			content.style.maxHeight = content.scrollHeight + 'px';
		}
	};

	return (
		<>
			<div className="ph-product-detail-quote-pharmacy-main-title-container">
				<div className="ph-product-detail-quote-pharmacy-main-title-inner-container">
					<h3 className="ph-product-detail-quote-pharmacy-main-title">Farmacias Disponibles</h3>
				</div>
				<div className={`ph-product-detail-quote-pharmacy-filters-container ${showFiltersMobile ? 'ph-product-detail-quote-pharmacy-show-filters' : ''}`}>
					{ENV_COUNTRY !== 'AR' && (
						<div className="ph-product-detail-quote-pharmacy-input-slider-container">
							<h4 className="ph-product-detail-quote-pharmacy-title">Filtrar por:</h4>
							<Select onChange={handlePharmaciesRadius} placeholder="Seleccionar distancia" className="ph-product-detail-quote-select">
								<Select.Option value={5}>Hasta 5 km</Select.Option>
								<Select.Option value={10}>Hasta 10 km</Select.Option>
								<Select.Option value={20}>Hasta 20 km</Select.Option>
								<Select.Option value={30}>Hasta 30 km</Select.Option>
								<Select.Option value={40}>Hasta 40 km</Select.Option>
								<Select.Option value={100}>Hasta 100 km</Select.Option>
							</Select>
						</div>
					)}
					<div className="ph-product-detail-quote-select-container">
						<h4 className="ph-product-detail-quote-pharmacy-title">Ordenar por:</h4>
						<Select onChange={handleOrderPharmacy} defaultValue="locationDesc" className="ph-product-detail-quote-select">
							<Select.Option value="locationDesc">Menor Distancia</Select.Option>
							<Select.Option value="locationAsc">Mayor Distancia</Select.Option>
							<Select.Option value="asc">Farmacias Ascendente</Select.Option>
							<Select.Option value="desc">Farmacias Descendente</Select.Option>
							<Select.Option value="priceAsc">Mayor Precio</Select.Option>
							<Select.Option value="priceDesc">Menor Precio</Select.Option>
							<Select.Option value="stockAsc">Mayor Stock</Select.Option>
							<Select.Option value="stockDesc">Menor Stock</Select.Option>
						</Select>
					</div>
				</div>
			</div>
			{isPharmacies && !loadingPharmacyCollapse ? (
				<div
					className={`${
						isListPharmacies.length > 0 ? 'ph-product-detail-quote-pharmacy-is-list' : 'ph-product-detail-quote-pharmacy-no-list'
					} ph-product-detail-quote-pharmacy-list-global-container`}
				>
					{isPharmacies.length > 0 ? (
						<>
							{isPharmacies.map(function (item, i) {
								return (
									<React.Fragment key={i}>
										<Row className="ph-product-detail-quote-pharmacy-list-main-container">
											<Col md={5} xs={24} className="ph-product-detail-quote-pharmacy-list-pharma" onLoad={(e) => handleCollapse(e, i)}>
												<div className="ph-product-detail-quote-pharmacy-list-pharmacy-mobile-header">
													<div className="ph-product-detail-quote-pharmacy-list-pharmacy-container">
														<div className="ph-product-detail-quote-pharmacy-list-pharmacy-title-container">
															<Images
																classContent={'ph-product-detail-quote-pharmacy-list-image-container'}
																classImage={'ph-product-detail-quote-pharmacy-list-image'}
																default={item.pharmacy_image || 'https://dummyimage.com/300/09f/fff.png'}
																title={item.name_edited_pharmacy}
																alt={item.name_edited_pharmacy}
															/>
															<h3 className="ph-product-detail-quote-pharmacy-list-pharmacy-title">{item.name_edited_pharmacy}</h3>
														</div>

														<h4 className="ph-product-detail-quote-pharmacy-list-pharmacy-subtitle">
															{item.nearest_local.city}
															{item.nearest_local.commune && ', '} {item.nearest_local.commune}
														</h4>
													</div>
													<div
														className={`ph-product-detail-quote-pharmacy-list-pharmacy-stock-mobile-open ${
															item.stock > 3 ? (ENV_COUNTRY === 'AR' ? 'benvida-green' : 'green') : item.stock <= 3 && item.stock > 0 ? 'orange' : 'gray'
														}`}
													>
														{item.stock > 3 && <>Stock disponible</>}
														{item.stock <= 3 && item.stock > 0 && <>Pocas Unidades</>}
														{item.stock <= 0 && <>Sin stock</>}
													</div>
												</div>
												<div className="ph-product-detail-quote-pharmacy-list-pharmacy-inner-container second-row-margin">
													{item.on_demand ? (
														<div className="ph-product-detail-quote-pharmacy-list-image-ondemand-container">
															<h4 className="ph-product-detail-quote-pharmacy-list-image-ondemand-title">Contra Pedido</h4>
															<Images
																classContent={'ph-product-detail-quote-pharmacy-list-image-ondemand-inner-container'}
																classImage={'ph-product-detail-quote-pharmacy-list-image-ondemand'}
																default={`${ENV_IMG}/react-product/on-demand.png`}
																title={'Contra Pedido'}
																alt={'Contra Pedido'}
															/>
														</div>
													) : (
														<div className="ph-product-detail-quote-pharmacy-stock-distance-container">
															<Stock
																// dispatch={item?.nearest_local?.despatch_info[0]?.title === 'Despacho'}
																stock={item.stock}
																agreements={item.agreements}
																pharolPrice={true}
																promotions={item.interface.promotions}
																km={item.nearest_local?.distance_text || null}
																priceForm={formatPrice(item.price)}
															/>
															<div className="ph-product-detail-quote-pharmacy-list-pharmacy-distance-container">
																{item.nearest_local && (
																	<div className="ph-product-detail-quote-pharmacy-list-pharmacy-distance-text">{item.nearest_local.distance_text}</div>
																)}
															</div>
														</div>
													)}
												</div>
											</Col>

											<Col span={3} className="ph-product-detail-quote-pharmacy-list-info-desktop">
												<div>Retiro</div>
												<div className="second-row-margin">
													{checkPharmacy[Enviroment.COUNTRY].handle(item) === true && (
														<>
															<p className="ph-product-detail-quote-price-normal">{pricePharmacy[Enviroment.COUNTRY].handle()}</p>
														</>
													)}

													{checkPharmacy[Enviroment.COUNTRY].handle(item) === false && (
														<>
															<span className="ph-product-detail-detailpharmacy-dispatch-unavailable">No disponible</span>
														</>
													)}
												</div>
											</Col>

											<Col span={3} className="ph-product-detail-quote-pharmacy-list-info-desktop">
												<div>Envío</div>
												<div className="second-row-margin">
													{checkDelivery[Enviroment.COUNTRY].handle(item) === false && (
														<>
															<span className="ph-product-detail-detailpharmacy-dispatch-unavailable">No disponible</span>
														</>
													)}

													{checkDelivery[Enviroment.COUNTRY].handle(item) === true && (
														<>
															<span>Disponible</span>
														</>
													)}
												</div>
											</Col>

											<Col span={3} className="ph-product-detail-quote-pharmacy-list-info-desktop">
												<div>Precio regular</div>
												<div className="second-row-margin">
													<PriceProduct item={item} />
												</div>
											</Col>
											<Col span={4} className="ph-product-detail-quote-pharmacy-list-info-desktop">
												{item.agreements?.length > 0 ? (
													<>
														<div>Convenios</div>
														<div
															className="second-row-margin ph-product-detail-quote-dispatch-info-subtitle-dispatch ph-product-detail-quote-pharmacy-agreement-collapse"
															onLoad={(e) => handleCollapse(e, i)}
															onClick={(e) => handleCollapseClick(e, i)}
														>
															{item.agreements?.length > 1 ? (
																<span>Mostrar Beneficios</span>
															) : (
																<span>Mostrar Beneficio {item.agreements[0]?.editable_name}</span>
															)}
															<DownOutlined />
														</div>
													</>
												) : (
													<>
														<div>Beneficio</div>
														<div className="second-row-margin" style={{ paddingBottom: '3px' }}>
															{/* <ProductPromotion item={item} /> */}
															<Promotions item={item} />
														</div>
													</>
												)}
											</Col>
											<Col span={3} className="ph-product-detail-quote-pharmacy-list-info-desktop">
												<div>Precio final</div>
												<div className="second-row-margin">
													{item.promotions === null && (
														<>
															<PriceProduct item={item} />
														</>
													)}

													{item.promotions !== null && (
														<>
															<>
																<div className={'ph-product-detail-quote-price-main-container'}>
																	<div className={'ph-product-detail-quote-pharmacy-promotion-price'}>{formatPrice(item.promotion_price)}</div>
																</div>
															</>
														</>
													)}
												</div>
											</Col>

											<Col span={3} className="ph-product-detail-quote-pharmacy-list-info-desktop">
												<div>
													<div className="ph-product-detail-quote-pharmacy-show-more">
														<a href={`/products/${item.slug.trim()}-farmacias-${item.pharmacy}`}>
															<Button className="ph-product-detail-simple-alternative-button">Ver más</Button>
														</a>
													</div>
													<div className="ph-product-detail-quote-pharmacy-list-button-container">
														<ButtonAdd
															item={item}
															handleSubscriptionPharolPrice={props.handleSubscriptionPharolPrice}
															pharolPriceCheck={props.pharolPriceCheck}
														/>
													</div>
												</div>
											</Col>

											{/* MOBILE COMPONENT */}
											<div ref={pharmacyCollapse.current[i]} className="ph-product-detail-quote-pharmacy-list-collapsed-container">
												<div className="ph-product-detail-quote-pharmacy-list-collapsed-header">
													<div className="ph-product-detail-detailsimple-collapsed-header-row">
														<div className="ph-product-detail-detailsimple-header-first">Retiro</div>
														<div className="ph-product-detail-detailsimple-header-second">
															{checkPharmacy[Enviroment.COUNTRY].handle(item) === true && (
																<>
																	<p className="ph-product-detail-quote-price-normal">{pricePharmacy[Enviroment.COUNTRY].handle()}</p>
																</>
															)}
														</div>
													</div>
													<div className="ph-product-detail-detailsimple-collapsed-header-row">
														<div className="ph-product-detail-detailsimple-header-first">Envío</div>
														<div className="ph-product-detail-detailsimple-header-second">
															{checkDelivery[Enviroment.COUNTRY].handle(item) === false && (
																<>
																	<span className="ph-product-detail-detailpharmacy-dispatch-unavailable">No disponible</span>
																</>
															)}

															{checkDelivery[Enviroment.COUNTRY].handle(item) === true && (
																<>
																	<p className="ph-product-detail-quote-price-normal">Disponible</p>
																</>
															)}
														</div>
													</div>
													<div className="ph-product-detail-quote-pharmacy-list-pharmacy-distance-container">
														{item.nearest_local && (
															<div className="ph-product-detail-quote-pharmacy-list-pharmacy-distance-text">
																{item.nearest_local.distance_text} a tu domicilio
															</div>
														)}
													</div>
												</div>
												<div className="ph-product-detail-quote-pharmacy-list-collapsed-regular-price">
													<div className="ph-product-detail-detailsimple-header-first">Precio regular</div>
													<div className={item.interface?.promotions?.price_promotion && 'ph-product-detail-quote-price-before'}>
														<PriceProduct item={item} />
													</div>
												</div>

												<div>
													<div className="ph-product-detail-quote-pharmacy-list-collapsed-regular-price">
														<div>Beneficios</div>

														{item.promotions ? (
															<Promotions item={item} styleContainer={'ph-product-detail-quote-price-main-container-no-padding'} />
														) : item.agreements?.length > 0 ? (
															<p className="ph-product-detail-quote-agreements-title">Beneficio {item.agreements[0].editable_name}</p>
														) : (
															<div>-</div>
														)}
													</div>
													{/* <div className="ph-product-detail-quote-pharmacy-list-collapsed-agreements-list">
																{item.agreements?.map((agreement, i) => (
																	<ProductCovenant
																		key={i}
																		separatorStyle={true}
																		pharolPriceCheck={props.pharolPriceCheck}
																		setPharolPriceCheck={props.setPharolPriceCheck}
																		handleSubscriptionPharolPrice={props.handleSubscriptionPharolPrice}
																		product={item}
																		item={agreement}
																		isOneMarked={item.agreements.findIndex((thisAgreement) => thisAgreement.status === 'completed' || thisAgreement.status === 'requested')}
																		queryPharmacies={queryPharmacies}
																	/>
																))}
															</div> */}
												</div>

												<div className="ph-product-detail-quote-pharmacy-list-collapsed-price">
													<div>Precio final</div>
													<div className="ph-product-detail-quote-pharmacy-list-collapsed-promotion-list">
														{item.promotion_price ? (
															<>
																<div className={'ph-product-detail-quote-price-main-container'}>
																	<div className={'ph-product-detail-quote-pharmacy-promotion-price'}>{formatPrice(item.promotion_price)}</div>
																</div>
															</>
														) : (
															<div>{formatPrice(item.price)}</div>
														)}
													</div>
												</div>

												<div className="ph-product-detail-quote-pharmacy-list-collapsed-buttons">
													<a href={`/products/${item.slug.trim()}-farmacias-${item.pharmacy}`}>
														<Button className="ph-product-detail-alternative-button">Ver más</Button>
													</a>
													{item.agreements?.length === 0 && (
														<ButtonAdd
															item={item}
															pharolPriceCheck={props.pharolPriceCheck}
															handleSubscriptionPharolPrice={props.handleSubscriptionPharolPrice}
														/>
													)}
												</div>
											</div>
											{/* END MOBILE COMPONENT */}
										</Row>
									</React.Fragment>
								);
							})}
						</>
					) : (
						<div className="ph-product-detail-quote-pharmacy-no-results-container">
							<Images
								classImage={'ph-product-detail-quote-pharmacy-no-results-image-container'}
								default={'https://storage.googleapis.com/master-image-bucket/bag/bolsa-triste.png'}
								title={'bolsa triste'}
								alt={'bolsa triste'}
							/>
							<h4 className="ph-product-detail-quote-pharmacy-no-results-title">No encontramos farmacias que tengan este producto cerca de de tu dirección</h4>
							<h4 className="ph-product-detail-quote-pharmacy-no-results-subtitle">Revisa tu dirección o prueba buscando otro producto</h4>
						</div>
					)}
				</div>
			) : (
				<LoadingPharmacies />
			)}
		</>
	);
}
