import React from 'react';
import { Drawer } from 'antd';
import './style.scss';

export default function CustomDrawer(props) {
	return (
		<>
			<Drawer {...props} />
		</>
	);
}
