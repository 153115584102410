/** @format */

import React from 'react';

import { formatPrice } from '../../../../../../../../../../components/Commons/Hooks/Functions/FormatPrice';

import './style.scss';

export default function PriceProduct(props) {
	return (
		<>
			{props.item.interface && (
				<div className="ph-product-detail-quote-price-product-container">
					<p className={`${(props.item.interface?.promotions?.price_promotion || props.item.promotions) ? 'ph-product-detail-quote-price-before' : 'ph-product-detail-quote-price-normal'} ${!props.item.pharmacy ? 'ph-product-detail-quote-price-home' : ''}`}>{formatPrice(props.item.price)}</p>
				</div>
			)}
		</>
	);
}
