/** @format */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';

import { Row, Col, Button, Spin, Modal } from 'antd';
import { LoadingOutlined, EditFilled, DeleteFilled, FilePdfOutlined } from '@ant-design/icons';

import MetaDescription from '../../../../components/Commons/MetaDescription';
import MainTitlePageAlt from '../../../../components/Commons/MainTitlePageAlt';
import Spacer from '../../../../components/Commons/Spacer';
import Images from '../../../../components/Commons/Images';

import { GetRecipes } from '../../../../components/Commons/Hooks/Functions/GetRecipes';

import recipeIcon from '../../../../components/Commons/Assets/Icons/Filled/Enabled/recipe.svg';
import recipeIconGreen from '../../../../components/Commons/Assets/Icons/Filled/Enabled/Argentina/receta_green.png';
import recipeIconOpacity from '../../../../components/Commons/Assets/Icons/Filled/EnabledOpacity/recipe.svg';

import Pagination from './components/Pagination';
import ErrorService from './components/ErrorService';
import ModalRecipe from './components/ModalRecipe';

import { DeleteRecipes } from './services';

import './style.scss';
import { ENV_COUNTRY } from '../../../../components/Commons/Hooks/Variables/Enviroment';
import CustomButton from '../../../../v2-components/Antd/Button';

export default function Recipes() {
	const { t } = useTranslation('global');

	const page = useParams();
	const [isRecipeData, setRecipeData] = useState(null);
	const [isRecipeDataService, setRecipeDataService] = useState(true);
	const [isDisabled, setDisable] = useState(false);
	const [isVisible, setVisible] = useState({
		modal: false,
		type: null,
		data: null,
	});
	const [isPreviewModal, setPreviewModal] = useState({
		visible: false,
		image: null,
	});

	const handleRemoveRecipe = async (id) => {
		await DeleteRecipes(id).then(async (response) => {
			if (response) {
				await GetRecipes().then((response) => {
					if (response) {
						setRecipeData(response);
					}
				});
			}
		});
	};

	useEffect(() => {
		GetRecipes().then((response) => {
			if (response) {
				setRecipeData(response);
			} else {
				setDisable(true);
				setRecipeDataService(false);
			}
		});
	}, []);

	if (!isRecipeDataService) {
		return <ErrorService />;
	}

	return (
		<>
			<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			<div className="ph-profile-recipes-global-container">
				<div className="ph-profile-recipes-main-container">
					<div className="ph-profile-recipes-inner-container">
						<MainTitlePageAlt
							classDetailPageContainer={'ph-profile-recipe-main-box-title-detail-container'}
							classDetailPagetitle={'ph-profile-recipe-main-box-title-detail-title'}
							classDetailPageDescription={'ph-profile-recipe-main-box-title-detail-description'}
							title={'Mis Recetas Médicas'}
							descriptionProfile={t('profile.backToAcountLink')}
							image={ENV_COUNTRY === 'AR' ? recipeIconGreen : recipeIcon}
						/>
						<Spacer />
						<CustomButton disabled={isDisabled} onClick={() => setVisible({ modal: true, type: 'Crear', data: null })} className={'button-primary'}>
							Agregar
						</CustomButton>
					</div>
					<div className="ph-profile-recipes-list-main-container">
						{isRecipeData ? (
							<>
								{isRecipeData.data?.length > 0 ? (
									<>
										{isRecipeData.data.map((item) => (
											<div className="ph-profile-recipes-list-container" key={item.recipe_id}>
												<Row>
													<Col span={12}>
														<div>
															<h4 className="ph-profile-recipes-list-title">NOMBRE:</h4>
															<h3 className="ph-profile-recipes-list-subtitle">{item.name}</h3>
														</div>
													</Col>
													<Col span={6} className="ph-profile-recipes-list-button-container">
														<div>
															<Button
																disabled={isDisabled}
																onClick={() =>
																	setPreviewModal({
																		visible: true,
																		image: item.recipe,
																	})
																}
																className="ph-profile-recipes-list-button"
															>
																<h3 className="ph-profile-recipes-list-button-title">Ver Receta</h3>
															</Button>
														</div>
													</Col>
													<Col span={6} className="ph-profile-recipes-list-actions-container">
														<EditFilled
															className="ph-profile-recipes-list-actions-icon"
															onClick={() =>
																setVisible({
																	modal: true,
																	type: 'Editar',
																	data: item,
																})
															}
														/>
														<DeleteFilled className="ph-profile-recipes-list-actions-icon-2" onClick={() => handleRemoveRecipe(item.recipe_id)} />
													</Col>
												</Row>
											</div>
										))}
									</>
								) : (
									<div className="ph-profile-recipes-list-loading-container">
										<div className="ph-profile-recipes-list-empty-container">
											<Images classImage={'ph-profile-recipes-list-empty-image'} default={recipeIconOpacity} alt={'receta médica'} title={'receta médica'} />
											<h3 className="ph-profile-recipes-list-empty-title">No se encontraron Recetas Médicas</h3>
											<h3 className="ph-profile-recipes-list-empty-subtitle">Usa el boton Agregar para subirlas...</h3>
										</div>
									</div>
								)}
							</>
						) : (
							<div className="ph-profile-recipes-list-loading-container">
								<Spin indicator={<LoadingOutlined className="ph-profile-recipes-list-loading-image" />} />
							</div>
						)}
					</div>
					{isRecipeData?.totalItems > 10 && (
						<div className="ph-phofile-recipes-pagination-container">
							<Pagination item={isRecipeData} page={page.page} />
						</div>
					)}
				</div>
			</div>
			<ModalRecipe
				modal={isVisible}
				setVisible={(data) => setVisible(data)}
				previewModal={(data) => setPreviewModal(data)}
				setRecipeData={(data) => setRecipeData(data)}
			/>
			<Modal
				wrapClassName="ph-checkout-receipt-modal-container"
				visible={isPreviewModal.visible}
				title={'Vista Previa:'}
				footer={null}
				onCancel={() => setPreviewModal({ visible: false, image: null })}
			>
				{isPreviewModal?.image && (
					<>
						{isPreviewModal.image.endsWith('.pdf') ? (
							<>
								<div className="ph-checkout-receipt-modal-pdf-container">
									<FilePdfOutlined className="ph-checkout-receipt-modal-pdf-icon" />
								</div>
								<a href={isPreviewModal.image} target="_blank" rel="noreferrer">
									{isPreviewModal.image}
								</a>
							</>
						) : (
							<img alt="example" style={{ width: '100%' }} src={isPreviewModal.image} />
						)}
					</>
				)}
			</Modal>
		</>
	);
}
