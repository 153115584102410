/** @format */

import React, { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';

import { Link, useHistory } from 'react-router-dom';

import moment from 'moment';

import { Row, Col, Button, Spin } from 'antd';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';

import AddProductCart from '../../../../components/Commons/Hooks/Functions/AddProductCart';
import CountryValidator from '../../../../components/Commons/CountryValidator';
import Images from '../../../../components/Commons/Images';
import Notify from '../../../../components/Commons/Notify';
import Spacer from '../../../../components/Commons/Spacer';

import ButtonQuantity from '../../../../components/ButtonQuantity';

import Price from './components/Price';
import Promotions from './components/Promotions';
import PromotionsDescription from './components/PromotionsDescription';
import ModalCalculation from './components/ModalCalculation';

import { formatPrice } from '../../../../components/Commons/Hooks/Functions/FormatPrice';

import { NotificationAddressBuyNow } from '../Actions/components/NotificationAddressBuyNow';

import { handleStock } from './Hook/Stock';
import { handleDispatch } from './Hook/Dispatch';
import { handleLocals } from './Hook/Locals';
import Voucher from './Hook/Voucher';

import { BuyNow } from './Hook/BuyNow';
import { Calculation } from './services';

import './style.scss';

export default function ProductActions(props) {
	const history = useHistory();
	const [isTotalServices] = useGlobal('totalServices');
	const [isQuantity, setQuantity] = useGlobal('quantityProduct');
	const [isVisible, setVisible] = useState(false);
	const [isDisable, setDisable] = useState(false);

	const { handleAddProductCart } = AddProductCart();
	const { handleNotificationAddressBuyNow } = NotificationAddressBuyNow();
	const { handleBagVoucher } = Voucher();

	const hableAlternativeProduct = () => {
		let result = props.item.slug.split('-');
		window.location.href = `/results/${result[0]}`;
	};

	const handleServiceAdd = () => {
		props.serviceForm
			.validateFields()
			.then(async () => {
				setVisible(true);
				await Calculation(props.item, isTotalServices).then((response) => {
					setVisible(false);
					if (response) {
						localStorage.setItem('retirementData', JSON.stringify(response));
						localStorage.setItem('datePay', JSON.stringify(moment().add(5, 'minutes')));
						return history.push('/checkout/retirement-service');
					}
				});
			})
			.catch(() => {
				Notify({
					title: `Advertencia`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Debe rellenar los campos de la pestaña "Incluye a tu plan" para continuar'`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			});
	};

	const handleBuyNow = async (item, quantity) => {
		let location = JSON.parse(localStorage.getItem('locationStore'));

		if (location?.default) {
			handleNotificationAddressBuyNow();
			return;
		}

		if (quantity > item.limit_stock) {
			Notify({
				title: `Advertencia!`,
				colorTitle: 'ph-main-notification-warning-title',
				description: `¡Solo podemos dispensar ${item.limit_stock} unidades de este producto!`,
				time: 5,
				image: 'error',
				placement: 'topRight',
			});
			return;
		}

		setDisable(true);
		setVisible(true);

		const locals = await handleLocals([item]);
		const voucherPharmacies = await handleBagVoucher();

		await BuyNow(item, quantity, location, locals, voucherPharmacies, 'farmacia').then(async (response) => {
			setDisable(false);
			setVisible(false);
			if (response) {
				if (response.valid_code === false)
					return Notify({
						title: '¡Error!',
						colorTitle: 'ph-main-notification-error-title',
						description: '¡El cupón ingresado no es válido. Intenta nuevamente!',
						time: 5,
						image: 'error',
						placement: 'topRight',
					});

				const checkStock = handleStock(response.products);
				if (checkStock)
					return Notify({
						title: '¡Error!',
						colorTitle: 'ph-main-notification-error-title',
						description: `Algunos productos estan sin stock.`,
						time: 5,
						image: 'error',
						placement: 'topRight',
					});

				const checkDispatch = await handleDispatch(response.locals_group);

				if (!checkDispatch) return;

				if (response.voucher_code) localStorage.setItem('voucherCode', response.voucher_code);
				localStorage.setItem('retirementData', JSON.stringify(response));
				localStorage.setItem('datePay', JSON.stringify(moment().add(5, 'minutes')));
				return history.push('/checkout/retirement');
			}
		});
	};

	useEffect(() => {
		setQuantity(1);
	}, [setQuantity]);

	return (
		<>
			<div>
				{!props.item.on_demand && !props.service && (
					<>
						<Price item={props.item} />
						<Promotions item={props.item} />
					</>
				)}
				{props.service && (
					<>
						{isTotalServices ? (
							<>
								<div className="ph-product-detail-service-desktop-price-container">
									<h4 className="ph-product-detail-service-desktop-price-title">
										(<span className="ph-product-detail-service-desktop-price-title-asterisk">*</span>
										)Precio mensual:
									</h4>
									<h4 className="ph-product-detail-service-desktop-price-subtitle">
										<span className="ph-product-detail-service-desktop-price-symbol">$</span>
										{formatPrice(isTotalServices.price_unity)}
									</h4>
								</div>
								<h4 className="ph-product-detail-service-price-description">
									Precio total: $<span className="ph-product-detail-service-price-description-price">{formatPrice(isTotalServices.price_total)}</span>
								</h4>
							</>
						) : (
							<div className="ph-product-detail-service-desktop-price-loading-container">
								<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
							</div>
						)}
					</>
				)}
				{props.item.cenabast && <h3 className="ph-product-detail-cenabast-promotion-title">Producto Cenabast</h3>}
				<PromotionsDescription item={props.item} />
				<Row>
					<Col xs={24} sm={24} md={24} lg={24} xl={24} className="ph-product-detail-button-container">
						{props.service ? (
							<>
								{props.mobile && (
									<>
										<div>
											{isTotalServices ? (
												<>
													<h4 className="ph-product-detail-service-price-title">
														<span className="ph-product-detail-service-price-title-parent">
															(<span className="ph-product-detail-service-price-title-asterisk">*</span>)
														</span>{' '}
														$ {formatPrice(isTotalServices.price_unity)}
													</h4>
													<h4 className="ph-product-detail-service-price-subtitle">$ {formatPrice(isTotalServices.price_total)}</h4>
												</>
											) : (
												<Spin className="ph-product-detail-mobile-service-loading" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
											)}
										</div>
										<Spacer />
									</>
								)}
								<Button
									onClick={() => {
										handleServiceAdd();
									}}
									className="ph-product-detail-button"
									htmlType={'submit'}
								>
									Comprar
								</Button>
							</>
						) : (
							<>
								{!props.item.interface.on_demand ? (
									<>
										<div className="ph-product-detail-quantity-global-container">
											{props.item.stock > 0 && !props.service && (
												<>
													{(props.item.internet_sale !== '0' || props.item.internet_sale !== 0) && (
														<div className="ph-product-detail-quantity-main-container">
															<h3 className="ph-product-detail-button-quantity-main-title">Cantidad</h3>
															<ButtonQuantity item={props.item} />
														</div>
													)}
												</>
											)}
										</div>
										{props.item.stock > 0 && (
											<>
												<Button className="ph-product-detail-button" onClick={() => handleAddProductCart(props.item, isQuantity)}>
													Agregar
												</Button>

												{props.item.prescryption_type === 'NP' && (
													<CountryValidator
														countries={['CL']}
														component={
															<Button disabled={isDisable} className="ph-product-detail-alternative-button" onClick={() => handleBuyNow(props.item, isQuantity)}>
																{!isDisable ? 'Comprar ahora' : 'ESPERE...'}
															</Button>
														}
													/>
												)}
											</>
										)}
										{(props.item.stock <= 0 || (props.item.stock === undefined && !props.service)) && (
											<>
												{props.mobile && (
													<div className="ph-product-detail-img-bolsa-not-stock-content">
														<Row>
															<Col xs={5} sm={5} md={5} className="ph-product-detail-img-bolsa-not-stock-content-pt5">
																<Images
																	classContent={''}
																	classImage={'ph-product-detail-img-bolsa-not-stock'}
																	default={'https://storage.googleapis.com/master-image-bucket/bag/bolsa-triste.png'}
																	title={'bolsa vacia'}
																/>
															</Col>
															<Col xs={19} sm={19} md={19} className="ph-product-detail-img-bolsa-not-stock-content-pl5">
																{' '}
																<h4 className="ph-product-detail-img-bolsa-not-stock-h4">
																	¡Lo sentimos! <br></br> Se nos acabó...
																</h4>
															</Col>
														</Row>
													</div>
												)}
												<Button className="ph-product-detail-button-alternative" onClick={() => hableAlternativeProduct()}>
													<SearchOutlined /> Buscar Alternativas
												</Button>
											</>
										)}
									</>
								) : (
									<Button className="ph-product-detail-quotation-button">
										<Link to={`/request-preorder/on-demand/${props.item.slug}/${props.item.id_pharol}/${props.item.prescription_type}/${props.item.pharmacy}/unitpharmacy`}>Cotizar</Link>
									</Button>
								)}
							</>
						)}
					</Col>
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<div className="ph-product-detail-hashtag-container">
							{props.service ? (
								<div className="ph-product-detail-hashtag-inner-container">
									<h3 className="ph-product-detail-hashtag-title">#{props.item.provider}</h3>
								</div>
							) : (
								<>
									{props.item.meta_keywords && (
										<div className="ph-product-detail-hashtag-inner-container">
											{props.item.meta_keywords.split(',').map(function (item, i) {
												return (
													<h3 className="ph-product-detail-hashtag-title" key={i}>
														#{item.trim()}
													</h3>
												);
											})}
										</div>
									)}
								</>
							)}
						</div>
					</Col>
				</Row>
			</div>
			<ModalCalculation visible={isVisible} />
		</>
	);
}
