import { AxiosAdapter } from '../../v2-config/adapters/axios/axios.adapter';
import { Http } from '../../v2-config/adapters/axios/axios.protocols';
import { ENVIROMENT } from '../../v2-config/constants/enviroment.constant';
export class Services {
	async getTempplate({ template, location }) {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${ENVIROMENT.API_PHARMACY}/template/get-template/${template}/location/${location}/${ENVIROMENT.ROOT_PLATFORM}`,
			params: { active: true, pharmacy: ENVIROMENT.ROOT_PHARMACY, country: ENVIROMENT.COUNTRY },
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}
}
