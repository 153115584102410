import { IMAGES } from '../../../v2-config/constants/images.constant';

export const validateUrl = (url) => {
	if (!url) {
		return IMAGES.FILE_NOT_AVAILABLE;
	}
	const regex = /^(ftp|http|https):\/\/[^ "]+$/;
	const isValidUrl = regex.test(url);

	if (isValidUrl) {
		return url;
	}
	return IMAGES.FILE_NOT_AVAILABLE;
};
