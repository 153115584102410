/** @format */

import axios from 'axios';
import Notify from '../../../../components/Commons/Notify';
import { ENV_TRANSACTION } from '../../../../components/Commons/Hooks/Variables/Enviroment';

export const ReserveCheckSave = async (paymentName, mongoID, item) => {
	let returnResponse;
	await axios({
		method: 'POST',
		url: `${ENV_TRANSACTION}/reserve`,
		data: {
			// Es un campo que se envia pero por eso esta harcodeado por que es para que apunten al mismo servicio
			// mas que nada para no apuntar a otro servicio pero internamente el verifica que sea reserva y/o compra
			payment_method_name_id: 'mercagopago-ar',
			mongo_purchase_id: mongoID,
			amount: item,
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				Notify({
					title: `¡Felicidades!`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Se ha generado la reserva correctamente, revise su bandeja de entrada`,
					time: 3,
					image: 'success',
					placement: 'topRight',
				});
				returnResponse = true;
			} else {
				Notify({
					title: `Error => payment-create-operation!`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Ha ocurrido un error con este medio de pago: ${paymentName}, inténtalo de nuevo o utiliza otro método disponible. Si el problema persiste, comunícate con nuestro equipo de soporte`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
				returnResponse = false;
			}
		})
		.catch(() => {
			Notify({
				title: `Error!`,
				colorTitle: 'ph-main-notification-error-title',
				description: `Ocurrio un Error, por favor vuelva a intentarlo.`,
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
};
