/** @format */

import React, { Component } from 'react';

import Images from '../../../../../../../../components/Commons/Images';

import './style.scss';

export default class Promotions extends Component {
	render() {
		return (
			<>
				<div className="ph-order-detail-products-item-promotion-container">
					<Images
						classContent={'ph-order-detail-products-item-promotion-image-container'}
						classImage={'ph-order-detail-products-item-promotion-image'}
						webp={this.props.item.img_promotion.webp}
						jp2={this.props.item.img_promotion.jp2}
						jxr={this.props.item.img_promotion.jxr}
						default={this.props.item.img_promotion.png}
						title={'promotions'}
					/>
					<h3 className="ph-order-detail-products-item-promotion-title">{this.props.item.title}</h3>
				</div>
			</>
		);
	}
}
