/** @format */

import React from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';

import MetaDescription from '../../components/Commons/MetaDescription';
import Images from '../../components/Commons/Images';
import { ENV_COUNTRY, ENV_IMG_STATIC, ENV_IMG } from '../../components/Commons/Hooks/Variables/Enviroment';

import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';

import './style.scss';

import translates from '../../components/Commons/translates';
import data from './data.json';

export default function HowToBuy() {
	const { t } = useTranslation('global');

	return (
		<div className="ph-how-to-by-container">
			<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			<div className="ph-how-to-by-header-container">
				<div className="ph-how-to-by-header-content">
					<video
						className="ph-how-to-by-header-video-content"
						width="100%"
						height="auto"
						autoPlay
						loop
						muted
						src="https://storage.googleapis.com/master-image-bucket/video/Pharol%2C%20primer%20marketplace%20de%20farmacias.mp4"
					></video>
					<a className="ph-how-to-by-header-link" target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=cYKBEllzSoM&feature=emb_logo" title="Ver en Youtube">
						<Images
							classContent={'ph-how-to-by-header-link-image'}
							classImage={'ph-how-to-by-header-link-image'}
							default={`https://i.ibb.co/8r3XS6v/youtube-logo.webp`}
							title={'Youtube Logo'}
							alt={'Youtube Logo'}
						/>
					</a>
					<div className="ph-how-to-by-header-text-container">
						<div>
							<div className="ph-how-to-by-header-text-content">
								<ScrollAnimation animateIn="animate__zoomIn" animateOnce={true}>
									<Images
										classContent={'ph-how-to-by-header-image '}
										classImage={'ph-how-to-by-header-image '}
										default={`${ENV_IMG_STATIC}/logo-blanco-pharol.webp`}
										webp={`${ENV_IMG_STATIC}/logo-blanco-pharol.webp`}
										jp2={`${ENV_IMG_STATIC}/logo-blanco-pharol.jp2`}
										jxr={`${ENV_IMG_STATIC}/logo-blanco-pharol.jxr`}
										title={'Logo Pharol'}
										alt={'Logo Pharol'}
									/>
								</ScrollAnimation>
								<ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
									<h1 className="ph-how-to-by-header-title">¿Cómo comprar?</h1>
								</ScrollAnimation>
								<ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true}>
									<p className="ph-how-to-by-header-subtitle">Acceder a tus productos farmacéuticos nunca fue tan fácil</p>
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="ph-how-to-by-global-container">
				<div className="ph-how-to-by-step-one-contentainer">
					<div>
						<Row>
							<Col xs={24} sm={24} md={24} lg={8} className="ph-how-to-by-main-content-text-step-one">
								<ScrollAnimation animateIn="animate__bounceInLeft" animateOnce={true}>
									<p className="ph-how-to-by-main-step">Paso 1:</p>
								</ScrollAnimation>
								<ScrollAnimation animateIn="animate__bounceInLeft" animateOnce={true}>
									<h1 className="ph-how-to-by-main-title">Entra en la página y selecciona el producto</h1>
								</ScrollAnimation>
								<ScrollAnimation animateIn="animate__bounceInLeft" animateOnce={true}>
									<p className="ph-how-to-by-main-subtitle">
										Usa nuestro buscador general o ingresa desde el menú a las distintas categorías para encontrar lo que buscas. ¡Todos los días tenemos nuevos productos para ti!
									</p>
								</ScrollAnimation>
							</Col>
							<Col xs={24} sm={24} md={24} lg={16} className="ph-how-to-by-main-content-img-step-one">
								<ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
									<Images
										classContent={'ph-how-to-by-main-img-step-onxs'}
										classImage={'ph-how-to-by-main-img-step-ones'}
										default={`${ENV_IMG_STATIC}/how-to-buy/desktop-img-one.webp`}
										webp={`${ENV_IMG_STATIC}/how-to-buy/desktop-img-one.webp`}
										jp2={`${ENV_IMG_STATIC}/how-to-buy/desktop-img-one.jp2`}
										jxr={`${ENV_IMG_STATIC}/how-to-buy/desktop-img-one.jxr`}
										title={'Step One desktop'}
										alt={'Step One desktop'}
									/>
								</ScrollAnimation>
								<ScrollAnimation className="ph-how-to-by-animation-movilone" animateIn="animate__fadeInRight" animateOnce={true}>
									<Images
										classContent={'ph-how-to-by-main-img-step-one-mobile'}
										classImage={'ph-how-to-by-main-img-step-one-mobile'}
										default={`${ENV_IMG_STATIC}/how-to-buy/movil-img-one.webp`}
										webp={`${ENV_IMG_STATIC}/how-to-buy/movil-img-one.webp`}
										jp2={`${ENV_IMG_STATIC}/how-to-buy/movil-img-one.jp2`}
										jxr={`${ENV_IMG_STATIC}/how-to-buy/movil-img-one.jxr`}
										title={'Step One movil'}
										alt={'Step One movil'}
									/>
								</ScrollAnimation>
							</Col>
						</Row>
					</div>
				</div>
				<div className="ph-how-to-by-step-two-container">
					<div>
						<Row>
							<Col
								xs={{ span: 24, order: 2 }}
								sm={{ span: 24, order: 2 }}
								md={{ span: 24, order: 2 }}
								lg={{ span: 16, order: 1 }}
								xl={{ span: 16, order: 1 }}
								className="ph-how-to-by-main-content-img-step-two"
							>
								<ScrollAnimation className="ph-how-to-by-animation-gotas" animateIn="animate__fadeInBottomLeft" animateOnce={true}>
									<Images
										classContent={'ph-how-to-by-main-steptwo-img-one'}
										classImage={'ph-how-to-by-main-steptwo-img-one'}
										default={`${ENV_IMG_STATIC}/how-to-buy/movil-img-dos-gotas.webp`}
										webp={`${ENV_IMG_STATIC}/how-to-buy/movil-img-dos-gotas.webp`}
										jp2={`${ENV_IMG_STATIC}/how-to-buy/movil-img-dos-gotas.jp2`}
										jxr={`${ENV_IMG_STATIC}/how-to-buy/movil-img-dos-gotas.jxr`}
										title={'movil img two gotas'}
										alt={'movil img two gotas'}
									/>
								</ScrollAnimation>
								<ScrollAnimation className="ph-how-to-by-animation-direction" animateIn="animate__fadeInTopRight" animateOnce={true}>
									<Images
										classContent={'ph-how-to-by-main-steptwo-img-two'}
										classImage={'ph-how-to-by-main-steptwo-img-two'}
										default={`${ENV_IMG_STATIC}/how-to-buy/desktop_img_direction.webp`}
										webp={`${ENV_IMG_STATIC}/how-to-buy/desktop_img_direction.webp`}
										jp2={`${ENV_IMG_STATIC}/how-to-buy/desktop_img_direction.jp2`}
										jxr={`${ENV_IMG_STATIC}/how-to-buy/desktop_img_direction.jxr`}
										title={'desktop direction'}
										alt={'desktop direction'}
									/>
								</ScrollAnimation>
								<ScrollAnimation className="ph-how-to-by-animation-entrega" animateIn="animate__fadeInTopLeft" animateOnce={true}>
									<Images
										classContent={'ph-how-to-by-main-steptwo-img-three'}
										classImage={'ph-how-to-by-main-steptwo-img-three'}
										default={`${ENV_IMG_STATIC}/how-to-buy/desktop_entrega.webp`}
										webp={`${ENV_IMG_STATIC}/how-to-buy/desktop_entrega.webp`}
										jp2={`${ENV_IMG_STATIC}/how-to-buy/desktop_entrega.jp2`}
										jxr={`${ENV_IMG_STATIC}/how-to-buy/desktop_entrega.jxr`}
										title={'desktop entrega'}
										alt={'desktop entrega'}
									/>
								</ScrollAnimation>
								<ScrollAnimation className="ph-how-to-by-animation-biooil" animateIn="animate__fadeInBottomRight" animateOnce={true}>
									<Images
										classContent={'ph-how-to-by-main-steptwo-img-four'}
										classImage={'ph-how-to-by-main-steptwo-img-four'}
										default={`${ENV_IMG_STATIC}/how-to-buy/desktop_step_tow_product.webp`}
										webp={`${ENV_IMG_STATIC}/how-to-buy/desktop_step_tow_product.webp`}
										jp2={`${ENV_IMG_STATIC}/how-to-buy/desktop_step_tow_product.jp2`}
										jxr={`${ENV_IMG_STATIC}/how-to-buy/desktop_step_tow_product.jxr`}
										title={'desktop crema'}
										alt={'desktop crema'}
									/>
								</ScrollAnimation>
								<ScrollAnimation className="ph-how-to-by-animation-carttwo" animateIn="animate__fadeInRight" animateOnce={true}>
									<Images
										classContent={'ph-how-to-by-main-steptwo-img-five'}
										classImage={'ph-how-to-by-main-steptwo-img-five'}
										default={`${ENV_IMG_STATIC}/how-to-buy/desktop_step_two_cart.webp`}
										webp={`${ENV_IMG_STATIC}/how-to-buy/desktop_step_two_cart.webp`}
										jp2={`${ENV_IMG_STATIC}/how-to-buy/desktop_step_two_cart.jp2`}
										jxr={`${ENV_IMG_STATIC}/how-to-buy/desktop_step_two_cart.jxr`}
										title={'main bolsa'}
										alt={'main bolsa'}
									/>
								</ScrollAnimation>
							</Col>
							<Col
								xs={{ span: 24, order: 1 }}
								sm={{ span: 24, order: 1 }}
								md={{ span: 24, order: 1 }}
								lg={{ span: 8, order: 2 }}
								xl={{ span: 8, order: 2 }}
								className="ph-how-to-by-main-content-text-step-two"
							>
								<ScrollAnimation animateIn="animate__bounceInRight" delay={1000} animateOnce={true}>
									<p className="ph-how-to-by-main-step-two-title">Paso 2:</p>
								</ScrollAnimation>
								<ScrollAnimation animateIn="animate__bounceInRight" delay={1500} animateOnce={true}>
									<h1 className="ph-how-to-by-main-step-two-subtitle">Agrega tu producto a la bolsa de compras</h1>
								</ScrollAnimation>
								<ScrollAnimation animateIn="animate__bounceInRight" delay={2000} animateOnce={true}>
									<p className="ph-how-to-by-main-step-two-paraghraf">
										Además selecciona el tipo de entrega que más te acomode: Retiro en {translates.pharmacy[ENV_COUNTRY].charAt(0).toLowerCase() + translates.pharmacy[ENV_COUNTRY].slice(1)} o despacho
										a domicilio. ¡Atención! los descuentos serán aplicados en la siguiente pantalla.
									</p>
								</ScrollAnimation>
							</Col>
						</Row>
					</div>
				</div>
				<div className="ph-how-to-by-pay-container">
					<Row>
						<Col xs={24} className="ph-how-to-by-main-pay-text-content">
							<ScrollAnimation animateIn="animate__zoomIn" delay={1000} animateOnce={true}>
								<p className="ph-how-to-by-main-pay-title">Estás sólo a un paso:</p>
							</ScrollAnimation>
							<ScrollAnimation animateIn="animate__fadeInLeft" delay={1000} animateOnce={true}>
								<h1 className="ph-how-to-by-main-pay-subtitle">Realiza el proceso de pago</h1>
							</ScrollAnimation>
							<ScrollAnimation animateIn="animate__fadeInRight" delay={1500} animateOnce={true}>
								<p className="ph-how-to-by-main-pay-paraghraf ">
									Tendrás la oportunidad de elegir el día y la hora para recibir tu compra. Además, verás un resumen de tu pedido y podrás elegir entre los diferentes medios de pago adheridos a
									nuestra plataforma.
								</p>
							</ScrollAnimation>
						</Col>
						<Col xs={24} md={24} className="ph-how-to-by-main-pay-img-content">
							<div className="ph-how-to-by-animation-producto-movil">
								<Images
									classContent={'ph-how-to-by-animation-produc-movil'}
									classImage={'ph-how-to-by-animation-product-movil'}
									default={`https://i.ibb.co/p4JsB9K/check-7.jpg`}
									title={'desktop'}
									alt={'desktop'}
								/>
							</div>
							<ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
								<Images
									classContent={'ph-how-to-by-main-pay-img-screens'}
									classImage={'ph-how-to-by-main-pay-img-screen'}
									default={`${ENV_IMG_STATIC}/how-to-buy/desktop-img-one-step-three.webp`}
									webp={`${ENV_IMG_STATIC}/how-to-buy/desktop-img-one-step-three.webp`}
									jp2={`${ENV_IMG_STATIC}/how-to-buy/desktop-img-one-step-three.jp2`}
									jxr={`${ENV_IMG_STATIC}/how-to-buy/desktop-img-one-step-three.jxr`}
									title={'desktop step three'}
									alt={'desktop step three'}
								/>
							</ScrollAnimation>
							<ScrollAnimation className="ph-how-to-by-animation-map" animateIn="animate__bounceInLeft" animateOnce={true}>
								<Images
									classContent={'ph-how-to-by-main-pay-img-m'}
									classImage={'ph-how-to-by-main-pay-img-map'}
									default={`${ENV_IMG_STATIC}/how-to-buy/desktop_img-map.webp`}
									webp={`${ENV_IMG_STATIC}/how-to-buy/desktop_img-map.webp`}
									jp2={`${ENV_IMG_STATIC}/how-to-buy/desktop_img-map.jp2`}
									jxr={`${ENV_IMG_STATIC}/how-to-buy/desktop_img-map.jxr`}
									title={'desktop map'}
									alt={'desktop map'}
								/>
							</ScrollAnimation>
							<ScrollAnimation className="ph-how-to-by-animation-calendar" animateIn="animate__bounceInLeft" animateOnce={true}>
								<Images
									classContent={'ph-how-to-by-main-pay-img-calendare'}
									classImage={'ph-how-to-by-main-pay-img-calendar'}
									default={`${ENV_IMG_STATIC}/how-to-buy/desktop_calendar.webp`}
									webp={`${ENV_IMG_STATIC}/how-to-buy/desktop_calendar.webp`}
									jp2={`${ENV_IMG_STATIC}/how-to-buy/desktop_calendar.jp2`}
									jxr={`${ENV_IMG_STATIC}/how-to-buy/desktop_calendar.jxr`}
									title={'desktop calendar'}
									alt={'desktop calendar'}
								/>
							</ScrollAnimation>
							<ScrollAnimation className="ph-how-to-by-animation-farmacy" animateIn="animate__bounceInLeft" animateOnce={true}>
								<Images
									classContent={'ph-how-to-by-main-pay-img-farmaci'}
									classImage={'ph-how-to-by-main-pay-img-farmacy'}
									default={`${ENV_IMG_STATIC}/how-to-buy/desktop_farmacy.webp`}
									webp={`${ENV_IMG_STATIC}/how-to-buy/desktop_farmacy.webp`}
									jp2={`${ENV_IMG_STATIC}/how-to-buy/desktop_farmacy.jp2`}
									jxr={`${ENV_IMG_STATIC}/how-to-buy/desktop_farmacy.jxr`}
									title={'desktop farmacy'}
									alt={'desktop farmacy'}
								/>
							</ScrollAnimation>
							<ScrollAnimation className="ph-how-to-by-animation-terms" animateIn="animate__bounceInLeft" animateOnce={true}>
								<Images
									classContent={'ph-how-to-by-main-pay-img-termscondicions'}
									classImage={'ph-how-to-by-main-pay-img-terms'}
									default={`${ENV_IMG_STATIC}/how-to-buy/desktop_terms.webp`}
									webp={`${ENV_IMG_STATIC}/how-to-buy/desktop_terms.webp`}
									jp2={`${ENV_IMG_STATIC}/how-to-buy/desktop_terms.jp2`}
									jxr={`${ENV_IMG_STATIC}/how-to-buy/desktop_terms.jxr`}
									title={'desktop terms'}
									alt={'desktop terms'}
								/>
							</ScrollAnimation>
							{!!data[ENV_COUNTRY]?.payment_methods && (
								<>
									{data[ENV_COUNTRY].payment_methods.map((item, index) => (
										<ScrollAnimation className={item.class} animateIn="animate__bounceInRight" animateOnce={true} key={index}>
											<Images
												classContent={item.classImgContent}
												classImage={item.classImg}
												default={`${ENV_COUNTRY !== 'CL' ? ENV_IMG : ENV_IMG_STATIC}${item.img}.${item.img === "/how-to-buy/sweetpay" ? "jpg" : "webp"}`}
												webp={`${ENV_COUNTRY !== 'CL' ? ENV_IMG : ENV_IMG_STATIC}${item.img}.webp`}
												jp2={`${ENV_COUNTRY !== 'CL' ? ENV_IMG : ENV_IMG_STATIC}${item.img}.jp2`}
												jxr={`${ENV_COUNTRY !== 'CL' ? ENV_IMG : ENV_IMG_STATIC}${item.img}.jxr`}
												title={item.name}
												alt={item.name}
											/>
										</ScrollAnimation>
									))}
								</>
							)}
						</Col>
					</Row>
				</div>
				<div className="ph-how-to-by-important-contentainer">
					<div>
						<Row>
							<Col xs={24} sm={24} md={24} lg={8} className="ph-how-to-by-important-text-container">
								<ScrollAnimation animateIn="animate__bounceInLeft" animateOnce={true}>
									<p className="ph-how-to-by-important-title">¡Importante!</p>
								</ScrollAnimation>
								<ScrollAnimation animateIn="animate__bounceInLeft" animateOnce={true}>
									<h1 className="ph-how-to-by-important-subtitle">Si tu producto requiere Receta Médica</h1>
								</ScrollAnimation>
								<ScrollAnimation animateIn="animate__bounceInLeft" animateOnce={true}>
									<p className="ph-how-to-by-important-paraghraf">Adjunta la imágen de tus Recetas Médicas, en caso de que tus productos así lo exijan.</p>
								</ScrollAnimation>
								<ScrollAnimation animateIn="animate__bounceInLeft" animateOnce={true}>
									<a href="https://pharol.cl/type-recipes" target="_blank" rel="noopener noreferrer">
										<span className="ph-how-to-by-important-enlace">Más información</span>
									</a>
								</ScrollAnimation>
							</Col>
							<Col xs={24} sm={24} md={24} lg={16} className="ph-how-to-by-main-content-img-step-one">
								<ScrollAnimation className="ph-how-to-by-animation-desktop_recipe" animateIn="animate__fadeInRight" animateOnce={true}>
									<Images
										classContent={'ph-how-to-by-animation-desktop_recipe'}
										classImage={'ph-how-to-by-animation-desktop_recipe'}
										default={`${ENV_IMG_STATIC}/how-to-buy/desktop_recipe.webp`}
										webp={`${ENV_IMG_STATIC}/how-to-buy/desktop_recipe.webp`}
										jp2={`${ENV_IMG_STATIC}/how-to-buy/desktop_recipe.jp2`}
										jxr={`${ENV_IMG_STATIC}/how-to-buy/desktop_recipe.jxr`}
										title={'recipe'}
										alt={'recipe'}
									/>
								</ScrollAnimation>
								<ScrollAnimation className="ph-how-to-by-animation-wp" animateIn="animate__fadeInTopRight" animateOnce={true}>
									<Images
										classContent={'ph-how-to-by-important-recipewps'}
										classImage={'ph-how-to-by-important-recipewp'}
										default={`${ENV_IMG_STATIC}/how-to-buy/desktop_wp.webp`}
										webp={`${ENV_IMG_STATIC}/how-to-buy/desktop_wp.webp`}
										jp2={`${ENV_IMG_STATIC}/how-to-buy/desktop_wp.jp2`}
										jxr={`${ENV_IMG_STATIC}/how-to-buy/desktop_wp.jxr`}
										title={'recipes'}
										alt={'recipes'}
									/>
								</ScrollAnimation>
								<ScrollAnimation className="ph-how-to-by-animation-upload" animateIn="animate__fadeInLeft" animateOnce={true}>
									<Images
										classContent={'ph-how-to-by-important-uploadrecipe'}
										classImage={'ph-how-to-by-important-upload'}
										default={`${ENV_IMG_STATIC}/how-to-buy/desktop_upload.webp`}
										webp={`${ENV_IMG_STATIC}/how-to-buy/desktop_upload.webp`}
										jp2={`${ENV_IMG_STATIC}/how-to-buy/desktop_upload.jp2`}
										jxr={`${ENV_IMG_STATIC}/how-to-buy/desktop_upload.jxr`}
										title={'pharol recipes'}
										alt={'pharol recipes'}
									/>
								</ScrollAnimation>
								<ScrollAnimation className="ph-how-to-by-animation-movil_upload" animateIn="animate__fadeInLeft" animateOnce={true}>
									<Images
										classContent={'ph-how-to-by-animation-movil_upload'}
										classImage={'ph-how-to-by-animation-movil_upload'}
										default={`${ENV_IMG_STATIC}/how-to-buy/movil_upload.webp`}
										webp={`${ENV_IMG_STATIC}/how-to-buy/movil_upload.webp`}
										jp2={`${ENV_IMG_STATIC}/how-to-buy/movil_upload.jp2`}
										jxr={`${ENV_IMG_STATIC}/how-to-buy/movil_upload.jxr`}
										title={'pharol recipes'}
										alt={'pharol recipes'}
									/>
								</ScrollAnimation>
							</Col>
						</Row>
					</div>
				</div>
				<div className="ph-how-to-by-dowlond-contentainer">
					<div>
						<Row>
							<Col xs={24} sm={24} className="ph-how-to-by-dowlond-content">
								<ScrollAnimation animateIn="animate__zoomIn" animateOnce={true}>
									<p className="ph-how-to-by-dowlond-content-title">Descarga</p>
								</ScrollAnimation>
								<ScrollAnimation animateIn="animate__zoomIn" animateOnce={true}>
									<h1 className="ph-how-to-by-dowlond-content-subtitle">Nuestra App</h1>
								</ScrollAnimation>
								<ScrollAnimation animateIn="animate__zoomIn" animateOnce={true}>
									<p className="ph-how-to-by-dowlond-content-paraghraf">Para disfrutar de nuestros servicios y comprar lo que más necesites, donde más lo necesites.</p>
								</ScrollAnimation>
							</Col>
						</Row>
						<Row justify="center">
							<Col className="ph-how-to-by-dowlond-img">
								<ScrollAnimation animateIn="animate__zoomIn" animateOnce={true}>
									<a href="https://play.google.com/store/apps/details?id=com.mediclic.mediclic1&hl=es" target="_blank" rel="noopener noreferrer">
										<Images
											classContent={'ph-how-to-by-dowlond-content-playstore'}
											classImage={'ph-how-to-by-dowlond-playstore'}
											default={`${ENV_IMG_STATIC}/mediclic/playstore.webp`}
											webp={`${ENV_IMG_STATIC}/mediclic/playstore.webp`}
											jp2={`${ENV_IMG_STATIC}/mediclic/playstore.jp2`}
											jxr={`${ENV_IMG_STATIC}/mediclic/playstore.jxr`}
											title={'PlayStore'}
											alt={'PlayStore'}
										/>
									</a>
								</ScrollAnimation>
							</Col>
							<Col className="ph-how-to-by-dowlond-img">
								<ScrollAnimation animateIn="animate__zoomIn" animateOnce={true}>
									<a href="https://apps.apple.com/us/app/mediclic/id1143276587" target="_blank" rel="noopener noreferrer">
										<Images
											classContent={'ph-how-to-by-dowlond-content-appstore'}
											classImage={'ph-how-to-by-dowlond-appstore'}
											default={`${ENV_IMG_STATIC}/mediclic/appstore.webp`}
											webp={`${ENV_IMG_STATIC}/mediclic/appstore.webp`}
											jp2={`${ENV_IMG_STATIC}/mediclic/appstore.jp2`}
											jxr={`${ENV_IMG_STATIC}/mediclic/appstore.jxr`}
											title={'AppStore'}
											alt={'AppStore'}
										/>
									</a>
								</ScrollAnimation>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		</div>
	);
}
