/** @format */

import React, { Component } from 'react'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import { ClockCircleOutlined } from '@ant-design/icons'

import './style.scss'

export default class TimeLocal extends Component {
	handleDayWeekLocal = (item) => {
		if (item === 1) {
			return 'Lunes'
		} else if (item === 2) {
			return 'Martes'
		} else if (item === 3) {
			return 'Miercoles'
		} else if (item === 4) {
			return 'Jueves'
		} else if (item === 5) {
			return 'Viernes'
		} else if (item === 6) {
			return 'Sábado'
		} else if (item === 0) {
			return 'Domingo'
		}
	}

	render() {
		return (
			<Row className='ph-recipe-objeted-pharmacy-list-hours-selected-container'>
				<Col xs={24} sm={24} md={24} lg={12} xl={12}>
					<h3 className='ph-recipe-objeted-pharmacy-list-hour'>{this.props.element.open}</h3>
					<h3 className='ph-recipe-objeted-pharmacy-list-hour-separator'> - </h3>
					<h3 className='ph-recipe-objeted-pharmacy-list-hour'>{this.props.element.close}</h3>
				</Col>
				<Col xs={24} sm={24} md={24} lg={12} xl={12}>
					<h3 className='ph-recipe-objeted-pharmacy-list-hour-title'>
						{this.handleDayWeekLocal(this.props.element.day)}
					</h3>
					{this.props.element.status ? (
						<div className='ph-recipe-objeted-pharmacy-list-hour-subtitle-container'>
							<ClockCircleOutlined className='ph-recipe-objeted-pharmacy-list-hour-subtitle-icon-1' />
							<h3 className='ph-recipe-objeted-pharmacy-list-hour-subtitle-1'>Abierto</h3>
						</div>
					) : (
						<div className='ph-recipe-objeted-pharmacy-list-hour-subtitle-container'>
							<ClockCircleOutlined className='ph-recipe-objeted-pharmacy-list-hour-subtitle-icon-2' />
							<h3 className='ph-recipe-objeted-pharmacy-list-hour-subtitle-2'>Cerrado</h3>
						</div>
					)}
				</Col>
			</Row>
		)
	}
}
