/** @format */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';

import { Layout, Col, Row, DatePicker, Form, Select } from 'antd';

import MetaDescription from '../../../../components/Commons/MetaDescription';

import Images from '../../../../components/Commons/Images';
import Notify from '../../../../components/Commons/Notify';
import Loading from '../../../../components/Commons/Loading';
import MainTitlePageAlt from '../../../../components/Commons/MainTitlePageAlt';

import { DeleteFavorites, GetFavoritesList, FavoritesNotificationEmail } from '../../../../components/Commons/Favorites/Functions';

import favoriteIcon from '../../../../components/Commons/Assets/Icons/Filled/Enabled/favorite.svg';
import favoriteIconGreen from '../../../../components/Commons/Assets/Icons/Filled/Enabled/Argentina/favoritos1-1-verde.png';
import NoFavorites from './components/NoFavorites';
import Pagination from './components/Pagination';

import './style.scss';
import { ENV_COUNTRY } from '../../../../components/Commons/Hooks/Variables/Enviroment';
import CustomButton from '../../../../v2-components/Antd/Button';

const { Content } = Layout;

const Favorites = () => {
	const { t } = useTranslation('global');
	const history = useHistory();

	const [form] = Form.useForm();
	const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
	const page = useParams();
	const [isList, setlist] = useState(null);
	const [isUserToken] = useState(localStorage.getItem('userToken'));

	const handleRedirect = (slug) => {
		history.push(`/products/${slug}`);
	};

	const handleDeleteFavorites = async (item, data) => {
		DeleteFavorites(item, data).then((response) => {
			if (response) {
				localStorage.setItem('userFavorites', JSON.stringify(response));
				GetFavoritesList(isUserToken, page.page).then((responseList) => {
					if (responseList) {
						setlist(responseList);
					}
				});
			}
		});
	};

	const handleCreateFavoriteNotification = (item) => {
		form.validateFields().then(async () => {
			let fields = form.getFieldsValue();
			let date = fields[`date_${item}`];
			let day = fields[`day_${item}`];
			if (date === undefined || day === undefined) {
				Notify({
					title: `Campos vacios`,
					colorTitle: 'ph-main-notification-success-title',
					description: `Debes llenar todos los campos para activar la notificación del producto.`,
					time: 3,
					image: 'warning',
					placement: 'topRight',
				});
				return;
			}
			FavoritesNotificationEmail(isUserToken, item, true, moment(date._d).format('DD/MM/YYYY'), day).then((response) => {
				if (response) {
					GetFavoritesList(isUserToken, page.page).then((responseList) => {
						if (responseList) {
							setlist(responseList);
						}
					});
				}
			});
		});
	};

	const handleDeleteFavoritesNotification = (item) => {
		FavoritesNotificationEmail(isUserToken, item.id_pharol, false).then((response) => {
			if (response) {
				GetFavoritesList(isUserToken, page.page).then((responseList) => {
					if (responseList) {
						setlist(responseList);
					}
				});
			}
		});
	};

	const habledisabledDate = (current) => {
		return current && current < moment().endOf('day');
	};

	useEffect(() => {
		GetFavoritesList(isUserToken, page.page).then((response) => {
			if (response) {
				setlist(response);
			}
		});
	}, [isUserToken, page.page]);

	if (!isList) {
		return <Loading />;
	} else {
		return (
			<>
				<Content className="ph-favorites-global-container">
					<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
					<div className="ph-favorites-title-page-container">
						<MainTitlePageAlt
							title={'Favoritos'}
							descriptionProfile={t('profile.backToAcountLink')}
							image={ENV_COUNTRY === 'AR' ? favoriteIconGreen : favoriteIcon}
						/>
					</div>
					<div>
						{isList?.data?.length > 0 ? (
							<>
								<div className="ph-favorites-main-container">
									{isList.data.map((item, index) => (
										<div className="ph-favorites-inner-container" key={index}>
											<Row className="ph-favorites-quote-product-container">
												<Col xs={6} lg={4}>
													<Images
														classContent={'ph-favorites-product-image-container'}
														classImage={'ph-favorites-product-imgage'}
														default={item.image}
														alt={item.meta_title}
														title={item.meta_title}
													/>
												</Col>
												<Col xs={12} lg={16}>
													<br></br>
													<p className="ph-favorites-product-id">Código Pharol: {item.id_pharol}</p>
													<h1 className="ph-favorites-product-title">{item.editable_name}</h1>
													<p className="ph-favorites-product-laboratory">Laboratorio: {item.laboratory}</p>
													<div className="ph-favorites-icon-container" onClick={() => handleDeleteFavorites(isUserToken, item.id_pharol)}>
														<span>Eliminar</span>
													</div>
													<hr className="ph-favotire-content-hr" />
												</Col>
												<Col xs={6} lg={4}>
													<div className="ph-favorites-btn-container">
														<CustomButton className={'button-tertiary'} onClick={() => handleRedirect(item.slug)}>
															Detalle
														</CustomButton>
													</div>
												</Col>
											</Row>
											{item.notification?.status && (
												<Row>
													<Col xs={6} lg={4}></Col>
													<Col xs={16} lg={16}>
														<h3 className="ph-favotire-content-active-title-main">
															<Images
																classContent={'ph-favotire-content-logo-content'}
																classImage={`ph-favotire-content-logo-content-image`}
																default={favoriteIcon}
																title={'Notificacion activa'}
															/>
															Notificación activa
														</h3>
														<p className="ph-favotire-content-active-title-description">
															Recuerda que te estará llegando un correo electrónicos de este producto con las farmacias disponbiles.
														</p>
														<Row className="ph-favotire-content-active">
															<Col xs={24} lg={8}>
																Inicio: <span className="ph-favotire-content-active-title">{item.notification.initial_date}</span>
															</Col>
															<Col xs={24} lg={8}>
																Periocidad: <span className="ph-favotire-content-active-title">{item.notification.space_days} días</span>
															</Col>
															<Col xs={24} lg={8} className="ph-favotire-content-active-delete">
																<h4 className="ph-favotire-btn-delete-notification" onClick={() => handleDeleteFavoritesNotification(item)}>
																	Eliminar
																</h4>
															</Col>
														</Row>
													</Col>
												</Row>
											)}
											{!item.notification?.status && (
												<Row>
													<Col xs={6} lg={4}></Col>
													<Col xs={17} lg={17}>
														<h3 className="ph-favotire-content-active-title-main">¿Necesitas que te recordemos este Producto?</h3>
														<p className="ph-favotire-content-active-title-description">
															Elige cuando y te haremos llegar disponibilidad, precio y farmacia del producto.
														</p>
														<Form name="form_reminder" form={form}>
															<Row>
																<Col xs={24} lg={10} className="ph-favotire-content-form-item-reminder">
																	<Form.Item name={`date_${item.id_pharol}`}>
																		<DatePicker
																			placeholder="¿Desde cuándo te recordamos?"
																			className="ph-favotire-datepicker-reminder"
																			size="large"
																			style={{ width: '100%' }}
																			format={dateFormatList}
																			disabledDate={habledisabledDate}
																		/>
																	</Form.Item>
																</Col>
																<Col xs={24} lg={10} className="ph-favotire-content-form-item-reminder">
																	<Form.Item name={`day_${item.id_pharol}`}>
																		<Select
																			placeholder="¿Cada cuánto te recordamos?"
																			className="ph-favotire-selector-reminder"
																			size="large"
																			style={{ width: '100%' }}
																		>
																			<Select.Option value="10">Cada 10 días</Select.Option>
																			<Select.Option value="15">Cada 15 días</Select.Option>
																			<Select.Option value="20">Cada 20 días</Select.Option>
																		</Select>
																	</Form.Item>
																</Col>
																<Col xs={24} lg={4}>
																	<Form.Item>
																		<CustomButton
																			onClick={() => handleCreateFavoriteNotification(item.id_pharol)}
																			className={'button-secondary'}
																			htmlType="submit"
																		>
																			ACTIVAR
																		</CustomButton>
																	</Form.Item>
																</Col>
															</Row>
														</Form>
													</Col>
												</Row>
											)}
										</div>
									))}
								</div>
								{isList.totalItems > 10 && (
									<div className="ph-phofile-favorites-pagination-container">
										<Pagination item={isList} page={page.page} />
									</div>
								)}
								<div className="ph-phofile-favorites-pagination-separator-container"></div>
							</>
						) : (
							<NoFavorites />
						)}
					</div>
				</Content>
			</>
		);
	}
};

export default Favorites;
