/** @format */

import React, { Component } from 'react'

import './style.scss'

export default class DescriptionProduct extends Component {
	render() {
		return (
			<>
				<div className='ph-order-detail-products-item-container'>
					<h3 className={`ph-order-detail-products-item-title ${this.props.classTitle}`}>
						{this.props.title}
					</h3>
					<h3
						className={`ph-order-detail-products-item-description ${this.props.classDescription}`}>
						{this.props.description}
					</h3>
				</div>
			</>
		)
	}
}
