export const Events = {
	view_item: 'view_item',
	add_to_cart: 'add_to_cart',
	begin_checkout: 'begin_checkout',
	purchase: 'purchase',
	refound: 'refund',
	select_item: 'select_item',
	remove_from_cart: 'remove_from_cart',
	view_promotion: 'view_promotion',
};
