/** @format */

import React from 'react';
import { Link } from 'react-router-dom';

import { Row, Col } from 'antd';

import Images from '../../../../../../../../components/Commons/Images';

import { ENV_IMG, ENV_IMG_HOME } from '../../../../../../../../components/Commons/Hooks/Variables/Enviroment';

import LoadingPharmacies from './components/LoadingPharmacies';
import Stock from './components/Stock';
import Dispatch from './components/Dispatch';
import GeoLocation from './components/GeoLocation';
import PriceDetail from './components/PriceDetail';
import ButtonAdd from './components/ButtonAdd';

import './style.scss';

export default function QuotePharmacies(props) {
	return (
		<>
			{props.productSelected.pharmacies.length > 0 ? (
				<div className="ph-electronic-quote-pharmacy-is-list ph-electronic-quote-pharmacy-list-global-container">
					{props.productSelected.pharmacies.map((item, i) => {
						return (
							<Row className="ph-electronic-quote-pharmacy-list-main-container" key={i}>
								<Col xs={11} sm={10} md={11} lg={13} xl={13} className="ph-electronic-quote-pharmacy-list-pharmacy-main-container">
									<div className="ph-electronic-quote-pharmacy-list-pharmacy-inner-container">
										<a className="ph-electronic-quote-pharmacy-list-pharmacy-link-detail" href={`/products/${item.slug.trim()}-farmacias-${item.pharmacy}`}>
											<div className="ph-electronic-quote-pharmacy-list-pharmacy-container">
												<Images
													classContent={'ph-electronic-quote-pharmacy-list-image-container'}
													classImage={'ph-electronic-quote-pharmacy-list-image'}
													default={item.pharmacy_image}
													title={item.name_edited_pharmacy}
													alt={item.name_edited_pharmacy}
												/>
												<h3 className="ph-electronic-quote-pharmacy-list-pharmacy-title">{item.name_edited_pharmacy}</h3>
											</div>
										</a>
										{item.on_demand ? (
											<div className="ph-electronic-quote-pharmacy-list-image-ondemand-container">
												<h4 className="ph-electronic-quote-pharmacy-list-image-ondemand-title">Contra Pedido</h4>
												<Images
													classContent={'ph-electronic-quote-pharmacy-list-image-ondemand-inner-container'}
													classImage={'ph-electronic-quote-pharmacy-list-image-ondemand'}
													default={`${ENV_IMG}/react-product/on-demand.png`}
													title={'Contra Pedido'}
													alt={'Contra Pedido'}
												/>
											</div>
										) : (
											<Stock stock={item.stock} />
										)}
									</div>
									<div className="ph-electronic-quote-pharmacy-list-pharmacy-stock-dispatch-inner-container">
										<Dispatch item={item} />
									</div>
								</Col>
								<Col xs={2} sm={2} md={2} lg={2} xl={2}>
									<div className="ph-electronic-quote-pharmacy-list-pharmacy-geo-inner-container">{item.nearest_local && <GeoLocation item={item.nearest_local} />}</div>
								</Col>
								<Col xs={11} sm={12} md={11} lg={9} xl={9} className="ph-electronic-quote-pharmacy-list-pharmacy-title-stock-container">
									<div className="ph-electronic-quote-pharmacy-list-pharmacy-icons-container">
										{item.cenabast && <Images classImage={'ph-electronic-quote-pharmacy-list-image-cenabast'} default={`${ENV_IMG_HOME}/cenabast.jpg`} title={'Cenabast'} alt={'Cenabast'} />}
									</div>
									{item.on_demand ? (
										<Link
											className="ph-electronic-quote-pharmacy-on-demand-button"
											to={`/request-preorder/on-demand/${item.slug}/${item.id_pharol}/${item.prescription_type}/${item.pharmacy}/multipharmacy`}
										>
											Cotizar
										</Link>
									) : (
										<>
											<PriceDetail item={item} />
											<ButtonAdd
												item={item}
												theIndex={props.theContainerIndex}
												productSelected={props.productSelected}
												cartForm={props.cartForm}
												handleAddProductCartElectronic={props.handleAddProductCartElectronic}
												handleAlternativeProduct={props.handleAlternativeProduct}
											/>
										</>
									)}
								</Col>
							</Row>
						);
					})}
				</div>
			) : (
				<LoadingPharmacies />
			)}
		</>
	);
}
