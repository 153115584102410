import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { GetLocalStorageValue } from '../../../../../../_config/adapters/local-storage/get-local-storage-value.adapter';
import CustomButton from '../../../../../../v2-components/Antd/Button';
import CustomCard from '../../../../../../v2-components/Antd/Card';
import CustomLink from '../../../../../../v2-components/Antd/Link';
import { CustomNotification } from '../../../../../../v2-components/Antd/Notification';
import CustomSteps from '../../../../../../v2-components/Antd/Steps';
import LoadingModal from '../../../../../../v2-components/LoadingModal';
import LoadingPage from '../../../../../../v2-components/LoadingPage';
import { PRESCRYPTION_TYPE } from './constants/prescryption-type.constant';
import { homeDeliveryAddress } from './handle/home-delivery-address.handle';
import { pharmacyRetirement } from './handle/pharmacy-retirement.handle';
import { purchaseInfoAdapter } from './handle/purchase-info-adapter.handle';
import { stepsHandle } from './handle/steps.handle';
import { Service } from './service';
import './style.scss';

export default function ProfileShoppingDetailPage() {
	const { id, trx_id } = useParams();
	const [isLoading, setIsLoading] = useState(true);
	const [purchase, setPurchase] = useState();
	const [openLoadingModal, setOpenLoadingModal] = useState(false);
	const [isLoadingModal, setIsLoadingModal] = useState(false);
	const [process, setProcess] = useState();
	const [order, setOrder] = useState();
	const history = useHistory();
	const { token } = GetLocalStorageValue('userMainInfo');

	useEffect(() => {
		Promise.all([new Service().getPurchaseById(token, id), new Service().getOrderById(trx_id)])
			.then(([purchaseData, orderData]) => {
				if (purchaseData.statusCode === 500 || orderData.statusCode === 500) {
					setIsLoading(false);
					CustomNotification({ type: 'error', message: 'Error', description: orderData.message });
					return history.push('/profile-info/shopping-history');
				}
				setPurchase(purchaseData.data);
				setOrder(orderData.data.order[0]);
				setProcess(orderData.data?.tracking?.[0]?.process);
				setIsLoading(false);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [id, trx_id, history, token]);
	console.log('ORDER', order);

	const handleSendOrder = () => {
		setOpenLoadingModal(true);
		setIsLoadingModal(true);
		new Service()
			.resendEmail(purchase?.trx_id)
			.then((response) => {
				setIsLoadingModal(false);
				setOpenLoadingModal(false);
				CustomNotification({ type: 'success', message: 'Orden enviada', description: 'La orden fue reenviada a su dirección de correo' });
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const steps = stepsHandle(process);
	const current = steps.length - 1;
	const purchaseAdapter = purchaseInfoAdapter(purchase);

	if (isLoading) {
		return <LoadingPage />;
	}

	const totalProductsCount = (array) => {
		return array.reduce((total, item) => {
			return total + item?.quantity;
		}, 0);
	};
	console.log('order', order);
	return (
		<>
			<Row justify="center" className="shopping-detail-content">
				<Col md={18} xs={22}>
					<Row justify="left">
						<h3>Orden n° {purchase?.trx_id} </h3>
					</Row>
					<Row justify="left">
						<CustomLink className={'link-primary'} to="/profile-info/shopping-history">
							Volver
						</CustomLink>
					</Row>
					<br></br>

					<Row justify="left" gutter={[16, 16]}>
						<Col md={8} xs={24}>
							<h4> Estado de la compra</h4>
							<CustomCard onClick={() => {}} className={'card-primary'}>
								<CustomSteps direction="vertical" items={steps} current={current} />
							</CustomCard>
						</Col>
						<Col md={8} xs={24}>
							<h4> Detalle del pedido</h4>
							<CustomCard onClick={() => {}} className={'card-primary'}>
								<Row justify="left">
									<h3>Numero de orden: {purchaseAdapter.trx_id}</h3>
								</Row>
								<Row justify="left">
									<p>Productos: {totalProductsCount(purchase.products)}</p>
								</Row>
								<Row justify="left">
									<p>Sub Total Productos: ${purchaseAdapter.sub_total}</p>
								</Row>
								<Row justify="left">
									<p>Envío: ${purchaseAdapter.total_delivery}</p>
								</Row>
								<Row justify="left">
									<p>Total: ${purchaseAdapter.total}</p>
								</Row>
								<Row justify="left">
									<p>Fecha de compra: {purchaseAdapter.createdAt}</p>
								</Row>
								<Row justify="left">
									<h4>{purchaseAdapter.type_retirement}</h4>
								</Row>
								<Row justify="left">
									<div>
										<p>Fecha estimada de entrega:</p>
										<p>{purchaseAdapter.schedule_selected}</p>
									</div>
								</Row>
								{pharmacyRetirement(purchase?.info.type_retirement, purchase)}
								<Row justify="left">
									<div>
										<h4>
											{purchaseAdapter.first_name} {purchaseAdapter.last_name}
										</h4>
										<p>{purchaseAdapter.rut}</p>
										<p>{purchaseAdapter.email}</p>
										<p>{purchaseAdapter.phone}</p>
										{homeDeliveryAddress(purchase?.info.type_retirement, purchaseAdapter.address)}
									</div>
								</Row>
								<Row justify="left">
									<CustomButton onClick={() => handleSendOrder()} className={'button-tertiary'} block>
										Reenviar Correo
									</CustomButton>
								</Row>
							</CustomCard>
						</Col>
						<Col md={8} xs={24}>
							<h4> Productos</h4>
							{purchase?.products.map((item, index) => {
								return (
									<React.Fragment key={`card-${index}`}>
										<CustomCard onClick={() => {}} className={'card-primary'}>
											<Row justify="left">{item.laboratory}</Row>
											<Link to={`/products/${item.slug}-farmacias-${item.pharmacy}`}>
												<Row justify="left">{item.editable_name}</Row>
											</Link>
											<Row justify="left">Cantidad: {item.quantity}</Row>
											<Row justify="left">Precio por unidad: {item.unit_price}</Row>
											<Row justify="left">{PRESCRYPTION_TYPE[item.prescryption_type]}</Row>
										</CustomCard>
										<br></br>
									</React.Fragment>
								);
							})}
						</Col>
					</Row>
				</Col>
				<LoadingModal loading={isLoadingModal} visible={openLoadingModal} />
			</Row>
		</>
	);
}
