/** @format */

import axios from 'axios'

import Notify from '../../../Commons/Notify'

import {
	ENV_ELEC_PRESCRIPTION,
	ENV_COUNTRY,
	ENV_QUOTATION,
} from '../../../Commons/Hooks/Variables/Enviroment'

export const electronicQuoteServices = async (item) => {
	if (item.type === 'minsal' || item.type === 'mimed') {
		const userAddress = JSON.parse(localStorage.getItem('locationStore'))
		return await axios({
			method: 'POST',
			url: `${ENV_ELEC_PRESCRIPTION}/quote/products/${ENV_COUNTRY}`,
			data: {
				info: {
					location: userAddress.address,
					maps: {
						lat: userAddress.lat,
						lng: userAddress.lng,
					},
				},
				token: item.token,
				rut: item.rut ? item.rut : null,
				rme_type: item.type,
			},
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					return response.data
				} else {
					Notify({
						title: `Error`,
						colorTitle: 'ph-main-notification-error-title',
						description: `${response.data.message}...`,
						time: 5,
						image: 'error',
						placement: 'topRight',
					})
				}
			})
			.catch(() => {
				Notify({
					title: `Error Servicio`,
					colorTitle: 'ph-main-notification-error-title',
					description: 'Problemas con el servicio - RME',
					time: 5,
					image: 'error',
					placement: 'topRight',
				})
			})
	} else {
		return await axios({
			method: 'GET',
			url: `${ENV_QUOTATION}/${ENV_COUNTRY}/products/${item.token}`,
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					return response.data
				} else {
					Notify({
						title: `Error`,
						colorTitle: 'ph-main-notification-error-title',
						description: `${response.data.message}...`,
						time: 5,
						image: 'error',
						placement: 'topRight',
					})
				}
			})
			.catch(() => {
				Notify({
					title: `Error Servicio`,
					colorTitle: 'ph-main-notification-error-title',
					description: 'Problemas con el servicio - RME',
					time: 5,
					image: 'error',
					placement: 'topRight',
				})
			})
	}
}
