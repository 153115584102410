export const handleCountPharmacies = () => {
  const cart = JSON.parse(localStorage.getItem("cart"));
  let count = 0;
  let pharmacy = "";
  cart.map((item) => {
    if (pharmacy !== item.pharmacy) {
      count = count + 1;
    }
    pharmacy = item.pharmacy;
    return count;
  });
  return count;
};
