/** @format */
//import { validateRUT } from 'validar-rut'

import Rut from 'rutjs'

const handleValidateLegalAge = (item, date) => {
	let age = date.getFullYear() - parseInt(item[2])
	if (date.getMonth() + 1 < parseInt(item[1])) --age
	if (date.getMonth() + 1 === parseInt(item[1]) && date.getDate() < parseInt(item[0])) --age
	return age >= 18 ? true : false
}

const handleInputAge = (item, date) => {
	if (!!!item || item.length === 0) return { boolean: true, theLength: '' }
	let splited = item.split('/')
	if (splited[0] > 31 || splited[0] < 1) {
		return {
			boolean: false,
			message: 'Debe insertar un día correcto',
		}
	} else if (splited[1] > 12 || splited[1] < 1) {
		return {
			boolean: false,
			message: 'Debe insertar un mes correcto',
		}
	} else if (splited[2] < 1900 || !handleValidateLegalAge(splited, date)) {
		return {
			boolean: false,
			message: 'Debe ser mayor de edad',
		}
	} else {
		return { boolean: true, message: '', theLength: item.replace(/[/]/g, '') }
	}
}

export const rulesValidationMask = {
	rulesAge(date) {
		return [
			{
				required: true,
				message: 'Ingresa tu fecha de nacimiento',
			},
			() => ({
				validator(_, value) {
					if (!value?.includes('/') && value?.length === 8) {
						return Promise.resolve()
					}
					let validate = handleInputAge(value?.replace(/_/g, ''), date)
					if (!validate.boolean) {
						return Promise.reject(new Error(validate.message))
					} else if (validate.theLength.length === 8) {
						return Promise.resolve()
					} else {
						return Promise.reject()
					}
				},
			}),
		]
	},
	rulesPhone(item) {
		return [
			{
				required: true,
				message: 'Ingresa tu número!',
			},
			() => ({
				validator(_, value) {
					if (value?.replace(/[+()_/\s/]/g, '').length === item) {
						return Promise.resolve()
					} else if (
						value?.replace(/[+()_/\s/]/g, '').length > 0 &&
						value?.replace(/[+()_/\s/]/g, '').length < item
					) {
						return Promise.reject(new Error('Número incompleto'))
					} else return Promise.reject()
				},
			}),
		]
	},
	/* 	rulesRutCL: [
		{
			required: true,
			message: 'Ingrese su RUT!',
		},
		() => ({
			validator(_, value = '') {
				if (!value.includes('.') && value.length === 9) {
					return Promise.resolve()
				}
				let rutReplaced = value?.replace(/[_.-]/g, '')
				if (rutReplaced.length >= 0 && rutReplaced.length < 9) {
					return Promise.reject(
						new Error(
							'Si su RUT es menor a 10 millones, coloque un 0 al inicio'
						)
					)
				} else if (value) {
					let rut = validateRUT(value)
					if (!rut) {
						return Promise.reject(new Error('El RUT ingresado no es valido'))
					} else {
						return Promise.resolve()
					}
				} else return Promise.reject()
			},
		}),
	], */
	rulesRutCL: [
		{
			required: true,
			message: 'Ingrese su RUT!',
		},
		() => ({
			validator(_, value = '') {
				if (!value.includes('.') && value.length === 9) {
					return Promise.resolve()
				}
				let rutReplaced = value?.replace(/[_.-]/g, '')
				if (rutReplaced.length >= 0 && rutReplaced.length < 9) {
					return Promise.reject(new Error('Si su RUT es menor a 10 millones, coloque un 0 al inicio'))
				} else if (value) {
					let rut = new Rut(value)
					if (!rut.isValid) {
						return Promise.reject(new Error('El RUT ingresado no es valido'))
					} else {
						return Promise.resolve()
					}
					/* 	let rut = validateRUT(value)
					if (!rut) {
						return Promise.reject(new Error('El RUT ingresado no es valido'))
					} else {
						return Promise.resolve()
					} */
				} else return Promise.reject()
			},
		}),
	],
	rulesDniAR: [
		{
			required: true,
			message: 'Ingrese su DNI!',
		},
		() => ({
			validator(_, value = '') {
				if (!value.includes('.') && value.length <= 11) {
					return Promise.resolve()
				}
				let dniReplaced = value?.replace(/[_.-]/g, '')
				 if (dniReplaced.length >= 0 && dniReplaced.length <= 7) {
					return Promise.reject(
				 		new Error('Si su DNI es menor a 10 millones, coloque un 0 al inicio')
				 	)
				} else return Promise.resolve()
			},
		}),
	],
	rulesRutCO: [
		{
			required: true,
			message: 'Ingresa tu RUT!',
		},
		() => ({
			validator(_, value) {
				if (!value.includes('.') && value.length === 10) {
					return Promise.resolve()
				}
				if (value?.replace(/[_.-]/g, '').length === 10) {
					return Promise.resolve()
				} else if (value?.replace(/[_.-]/g, '').length > 0 && value?.replace(/[_.-]/g, '').length < 10) {
					return Promise.reject(new Error('Número incompleto'))
				} else return Promise.reject()
			},
		}),
	],
	rulesRFC: [
		{
			required: true,
			message: 'Ingrese su RFC',
		},
		() => ({
			validator(_, value) {
				if (value.length >= 12) {
					return Promise.resolve()
				}
				return Promise.reject(new Error('Debe completar este campo con mínimo 12 caracteres'))
			},
		}),
	],
	rulesCURP: [
		{
			required: true,
			message: '¡Por favor, ingrese su CURP!',
		},
		() => ({
			validator(_, value) {
				const validate =
					/^[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/gi.test(
						value
					)
				if (validate) {
					return Promise.resolve()
				} else if (!validate && value?.length < 18) {
					return Promise.reject()
				} else {
					return Promise.reject(new Error('Inserte un CURP válido'))
				}
			},
		}),
	],
}
