import { Row } from 'antd';
import { TYPE_RETIREMENT } from '../constants/type-retirement.constant';
import { purchaseInfoAdapter } from './purchase-info-adapter.handle';

export const pharmacyRetirement = (typeRetirement, order) => {
	const purchase = purchaseInfoAdapter(order);
	if (typeRetirement === TYPE_RETIREMENT.pharmacy) {
		return (
			<Row justify="left">
				<div>
					<h4>Farmacia {purchase.pharmacy}</h4>
					<p>
						{purchase.direction}, {purchase.commune}
					</p>
				</div>
			</Row>
		);
	}
	return null;
};
