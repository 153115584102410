/** @format */

import React from 'react'

import { EnvironmentFilled } from '@ant-design/icons'

import './style.scss'

export default function GeoLocation(props) {
	return (
		<div>
			<EnvironmentFilled
				className='ph-electronic-quote-pharmacy-list-pharmacy-geo-icon'
				style={{ color: props.item.color_distance }}
			/>
			<h4
				className='ph-electronic-quote-pharmacy-list-pharmacy-geo-title'
				style={{ color: props.item.color_distance }}>
				{props.item.distance_text}
			</h4>
		</div>
	)
}
