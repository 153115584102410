import { useState, useEffect } from 'react';

export default function useWindowScreen() {
	const [widthScreen, setWidthScreen] = useState(window.innerWidth);

	const updateWidthScreen = () => {
		setWidthScreen(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener('resize', updateWidthScreen);

		return () => {
			window.removeEventListener('resize', updateWidthScreen);
		};
	}, []);

	return widthScreen;
}
