/** @format */

import React from 'react';

import InputMask from 'react-input-mask';
import { Input } from 'antd';

import './style.scss';

const inputMask = (props) => {
	return (
		<InputMask {...props} className={props.className ? props.className : 'ph-edit-profile-field-input-mask'} onChange={props.onChange}>
			{(inputProps) => <Input {...inputProps} />}
		</InputMask>
	);
};

export default inputMask;
