import { AxiosAdapter } from '../../../../../../_config/v2/adapters/axios/axios.adapter';
import { Http } from '../../../../../../_config/v2/adapters/axios/axios.protocols';
import { Enviroment } from '../../../../../../_config/constants/enviroment-v2.constant';

export class Service {
	async getPurchaseById(token, id) {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${Enviroment.API_PHARMACY}/purchase/purchase-by-id/${id}`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}
	async resendEmail(trxId) {
		return new AxiosAdapter({
			method: Http.POST,
			url: `${Enviroment.FUNCTION_SUCCESS_TRANSACTION}/emails/resend`,
			params: { resend: true },
			data: { trx_id: trxId },
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				throw e;
			});
	}

	//TO DO SERVICIO MOMENTANEO
	async getOrderById(trx_id) {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${Enviroment.FUNCTION_PANEL_TRACKING}/pharmacy-standard/v2/country/${Enviroment.COUNTRY}/pharmacy/${Enviroment.ROOT_PHARMACY}/trx_id/${trx_id}/one-record`,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				throw e;
			});
	}
}
