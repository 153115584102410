/** @format */

import React, { Component } from 'react'
import Images from '../../../Images'
import './style.scss'
import { ENV_COUNTRY, ENV_IMG_HOME } from '../../../Hooks/Variables/Enviroment'

export default class ProductLogos extends Component {
	render() {
		return (
			<>
				{this.props.detail.pharmacy ? (
						<>
							{this.props.detail.interface.promotions && (
								<>
									{this.props.detail.interface.promotions.main_img_promotion && (
										<>
											{(this.props.detail.interface.promotions.main_img_promotion.webp ||
												this.props.detail.interface.promotions.main_img_promotion.jp2 ||
												this.props.detail.interface.promotions.main_img_promotion.jxr ||
												this.props.detail.interface.promotions.main_img_promotion
													.default) && (
													<>
														<Images
															classContent={`${this.props.promotionImgClassContent}`}
															classImage={`${this.props.promotionImgClass}`}
															webp={
																this.props.detail.interface.promotions
																	.main_img_promotion.webp
															}
															jp2={
																this.props.detail.interface.promotions
																	.main_img_promotion.jp2
															}
															jxr={
																this.props.detail.interface.promotions
																	.main_img_promotion.jxr
															}
															default={
															(ENV_COUNTRY === 'AR' && this.props.detail.promotions ) 
																? typeof this.props.detail.promotions === "string" ? JSON.parse(this.props.detail.promotions).rules.icon.image : this.props.detail.promotions.rules.icon.image
																: this.props.detail.interface.promotions.main_img_promotion.default
															}
															alt={'Sale'}
															title={'Sale'}
														/>
													</>
											)}
										</>
									)}
								</>
							)}
						</>
					)
					:(
						<>
							 {this.props.detail.promotions?.rules.icon.image && <>
								
								<Images
									classContent={`${this.props.promotionImgClassContent}`}
									classImage={`${this.props.promotionImgClass}`}
									default={
										this.props.detail.promotions.rules.icon.image
									}
									alt={'Sale'}
									title={this.props.detail.promotions.title}
								/>
							</>
							
							}	 
						</>
					)
				}
				<div className='ph-product-logos-main-container'>
					{this.props.detail.interface?.on_demand && (
							<Images
								classContent={'ph-product-promotion-cp-image-container'}
								classImage={`${this.props.cpImgClass}`}
								webp={this.props.detail.interface.on_demand.image.webp}
								jp2={this.props.detail.interface.on_demand.image.jp2}
								jxr={this.props.detail.interface.on_demand.image.jxr}
								default={this.props.detail.interface.on_demand.image.default}
								alt={this.props.detail.interface.on_demand.image.title}
								title={this.props.detail.interface.on_demand.image.title}
							/>
					)}
					{this.props.detail.cenabast && (
						<>
								<Images
									classContent={'ph-product-promotion-cenabast-image-container'}
									classImage={`${this.props.cenabastImgClass}`}
									default={`${ENV_IMG_HOME}/cenabast.jpg`}
									alt={'Cenabast'}
									title={'Cenabast'}
								/>
						</>
					)}
				</div>
			</>
		)
	}
}


