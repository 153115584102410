/** @format */

import { setGlobal } from "reactn";

import Notify from "../../../../../../components/Commons/Notify";

import { PixelFacebook } from "../../../../../../components/Commons/Hooks/Functions/PixelFacebook";
import { ENV_ISO_CURRENCY } from "../../../../../../components/Commons/Hooks/Variables/Enviroment";
import { amountProductsCart } from "../../../../../../components/Commons/Hooks/Functions/AmountProductsCart";

import RepurchaseNotication from "./components/RepurchaseNotification";

export const RepurchaseAddProductsCart = (item, quantity) => {
  const products = [...item];
  let limitStock = 0;
  products.forEach((element) => {
    delete element.recipe;
    delete element.valid_data_payment;
    delete element.validation_product;
    delete element.observation_reject_recipe;
    let cart = JSON.parse(localStorage.getItem("cart"));
    const index = cart.findIndex((search) => {
      return search.objectID === element.objectID;
    });
    if (index !== -1) {
      let auxQuantity = null;
      auxQuantity = cart[index].quantity + quantity;
      if (auxQuantity > element.limit_stock) {
        limitStock = limitStock + 1;
        element.to_delete = true;
      } else {
        cart[index].quantity = cart[index].quantity + quantity;
        cart[index].sub_total = cart[index].quantity * cart[index].unit_price;
        localStorage.setItem("cart", JSON.stringify(cart));
      }
    } else {
      element.quantity = quantity;
      element.unit_price = element.price;
      element.sub_total = element.price * quantity;
      cart.push(element);
      localStorage.setItem("cart", JSON.stringify(cart));
    }
    PixelFacebook("RepurcharseAddToCart", {
      content_ids: item.id_pharol,
      content_type: "product",
      content_name: item.name,
      currency: ENV_ISO_CURRENCY,
      value: parseInt(item.price).toFixed(2),
    });
    setGlobal(() => ({
      carQuantity: amountProductsCart(),
    }));
    setGlobal({ cart: JSON.parse(localStorage.getItem("cart")) });
  });
  let productsNotification = [];
  products.forEach((element) => {
    if (!element.to_delete) {
      productsNotification.push(element);
    }
  });
  if (productsNotification.length > 0)
    RepurchaseNotication(productsNotification, limitStock);
  else
    Notify({
      title: `Advertencia!`,
      colorTitle: "ph-main-notification-warning-title",
      description: `¡Ya tienes 10 unidades c/u de tus ${limitStock} productos que intentas agregar a la bolsa!`,
      time: 5,
      image: "error",
      placement: "topRight",
    });
};
