/** @format */

import React from 'react';

import { Row, Col } from 'antd';

//import AvailableStocks from './components/AvailableStocks'
import Quantity from './components/Quantity';
// import SubTotalPrice from './components/SubTotalPrice'
import ButtonsDetail from './components/ButtonsDetail';

import './style.scss';

export default function ProductSubTotal(props) {
	return (
		<>
			<Row align="middle" className="ph-detail-product-subtotal-row">
				<Col lg={3} xs={5}>
					<span className="ph-detail-product-quantity-text">Cantidad</span>
				</Col>
				{!props.service && (
					<Col lg={8} xs={19}>
						<Quantity item={props.item} />
					</Col>
				)}
				{/* <Col lg={3} className='ph-detail-productsubtotal-available-stocks-container'>
				<AvailableStocks item={props.item} />
			</Col> */}
				{/* <Col lg={12} className='ph-detail-productsubtotal-subtotal-price-container'>
				<span className='ph-detail-product-subtotal-text'>Subtotal</span>
				<SubTotalPrice item={props.item} />
			</Col> */}
			</Row>
			<Row>
				<Col className="ph-detail-productsubtotal-buttons-container">
					<ButtonsDetail item={props.item} pharolPriceCheck={props.pharolPriceCheck} handleSubscriptionPharolPrice={props.handleSubscriptionPharolPrice} />
				</Col>
			</Row>
		</>
	);
}
