/** @format */

import React from 'react';

import { useGlobal, setGlobal } from 'reactn';

import { Drawer } from 'antd';

import FacetsInfo from '../FacetsInfo';

import BannerImages from '../BannerImages';

import './style.scss';

export default function MobileDrawer(props) {
	const [isVisible] = useGlobal('algoliaSidebar');
	console.log('props',props)
	return (
		<Drawer
			className="ph-elactic-sidebar-drawer-main-container"
			width={'275'}
			title="Filtrar Productos"
			placement="right"
			closable={true}
			onClose={() =>
				setGlobal({
					algoliaSidebar: false,
				})
			}
			visible={isVisible}
		>
			<FacetsInfo params={props.params} subCategory={props.subCategory} category={props.category} />
			<BannerImages sidebarBanners={props.sidebarBanners} />
		</Drawer>
	);
}
