/** @format */
import React from 'react'
import { useGlobal } from 'reactn'

import Modal from 'antd/lib/modal'

import Images from '../../Images'

import './style.scss'

export default function RationalModal() {
	const [isModalRational, setModalRational] = useGlobal('rationalModal')
	return (
		<>
			<Modal
				wrapClassName='ph-rational-modal-container'
				maskClosable={true}
				width='900px'
				centered
				visible={isModalRational}
				onCancel={() => setModalRational(false)}
				okText='Confirmar'
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}>
				<Images
					classImage={'ph-rational-image'}
					webp={'https://storage.googleapis.com/tirx-resources-develop/b22b7b71-1c6f-4e99-ab3c-9ac328bf5458.webp'}
					jp2={'https://storage.googleapis.com/master-image-bucket/react-home/rational-banner.jp2'}
					jxr={'https://storage.googleapis.com/master-image-bucket/react-home/rational-banner.jxr'}
					default={'https://storage.googleapis.com/tirx-resources-develop/b22b7b71-1c6f-4e99-ab3c-9ac328bf5458.webp'}
					alt={'Uso racional'}
					title={'Uso racional'}
				/>
			</Modal>
		</>
	)
}
