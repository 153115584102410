/** @format */

import React from 'react'

import './style.scss'

export default function InfoAdditional(props) {
	return (
		<div className='ph-detail-simple-info-main-container'>
			<div
				className='ph-detail-simple-info-main-title'
				dangerouslySetInnerHTML={{
					__html: props.info.patient_brochure.information_html,
				}}></div>
		</div>
	)
}
