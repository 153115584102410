/** @format */

import React, { useState, useEffect } from 'react';

import { Row, Col, Collapse, Button } from 'antd';

import Images from '../../../../../../components/Commons/Images';

import { ENV_COUNTRY, ENV_IMG_ROUTE } from '../../../../../../components/Commons/Hooks/Variables/Enviroment';
import { formatPrice } from '../../../../../../components/Commons/Hooks/Functions/FormatPrice';
import translates from '../../../../../../components/Commons/translates';

import QuotePharmacies from './components/Pharmacies';

import HandleAddProductElectronic from '../../../../functions/BagAddProductElectronic';

import { serviceQuoteGetPharmacies } from './services';

import './style.scss';

export default function ElectroniProducts(props) {
	const { handleAddProductCartElectronic, open, setOpen } = HandleAddProductElectronic();
	const [seeMore, setSeeMore] = useState(
		props.products.map((item) => {
			return {
				theLength: item.products.length,
				limit: 3,
			};
		}),
	);

	const handleCollapse = (item) => {
		setOpen(item);
	};
	const handleAlternativeProduct = (item) => {
		let result = item.slug.split('-');
		window.location.href = `/results/${result[0]}`;
	};

	useEffect(() => {
		let propsCollapseProducts = props.products;
		let productCollapse = [];
		let n = 0;
		propsCollapseProducts.forEach(() => {
			productCollapse.push(n.toString());
			n = n + 1;
		});
		setOpen(productCollapse);
	}, [props.products, setOpen]);

	const handleSelectProduct = async (item) => {
		let productsSelectedCopy = [...props.productsSelected];
		let response = await serviceQuoteGetPharmacies(item.slug);
		productsSelectedCopy[item.indexSelected] = {
			...item,
			pharmacies: response,
		};
		props.setProductsSelected(productsSelectedCopy);
	};

	return (
		<>
			{props.products.map((containerProducts, index) => (
				<React.Fragment key={index}>
					{!containerProducts.selected && (
						<Collapse key={index} className="ph-quotation-cart-collapse-container" activeKey={open} onChange={handleCollapse} expandIconPosition={'right'}>
							<Collapse.Panel
								onChange={() => setOpen(() => [index])}
								header={
									<div className="ph-quotation-cart-producs-title-container">
										<h3 className="ph-quotation-cart-producs-title">{containerProducts.name}</h3>
									</div>
								}
								key={index}
							>
								<div className="ph-quotation-cart-producs-main-container">
									{index === props.productsSelected[index]?.indexSelected ? (
										<Row>
											<Col xs={24} sm={24} md={4} lg={4} xl={4}>
												<Images
													classContent={'ph-quotation-cart-producs-panel-collapse-image-container'}
													classImage={'ph-quotation-cart-producs-panel-collapse-image'}
													webp={`${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${props.productsSelected[index].id_pharol}.webp`}
													default={`${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${props.productsSelected[index].id_pharol}.png`}
													title={props.productsSelected[index].meta_title}
												/>
											</Col>
											<Col xs={24} sm={24} md={20} lg={20} xl={20}>
												<div className="ph-quotation-cart-panel-container">
													<div className="ph-quotation-cart-product-description-container">
														{props.productsSelected[index].laboratory && props.productsSelected[index].laboratory !== '-' && (
															<h4 className="ph-quotation-cart-product-description-laboratory">{props.productsSelected[index].laboratory}</h4>
														)}
														<h4 className="ph-quotation-cart-product-description-title">{props.productsSelected[index].meta_title}</h4>
														<h4 className="ph-quotation-cart-product-description-type">
															Code Internal: <span className="ph-quotation-cart-product-description-subtype">{props.productsSelected[index].id_pharol}</span>
														</h4>
														{props.productsSelected[index].interface && (
															<>
																{props.productsSelected[index].interface.on_demand && (
																	<div className="ph-quotation-cart-product-description-on-demand-container">
																		<h4 className="ph-quotation-cart-product-description-on-demand">{props.productsSelected[index].interface.on_demand.title}</h4>
																		<Images
																			classContent={'ph-quotation-cart-producs-title-image-on-demand-container'}
																			classImage={'ph-quotation-cart-producs-title-on-demand-image'}
																			webp={props.productsSelected[index].interface.on_demand.image.webp}
																			jp2={props.productsSelected[index].interface.on_demand.image.jp2}
																			jxr={props.productsSelected[index].interface.on_demand.image.jxr}
																			default={props.productsSelected[index].interface.on_demand.image.png}
																			title={'Contra pedido'}
																		/>
																	</div>
																)}
																{props.productsSelected[index].interface.magistral && (
																	<div className="ph-quotation-cart-product-description-on-demand-container">
																		<h4 className="ph-quotation-cart-product-description-on-demand">{props.productsSelected[index].interface.magistral.title}</h4>
																		<Images
																			classContent={'ph-quotation-cart-producs-title-image-on-demand-container'}
																			classImage={'ph-quotation-cart-producs-title-on-demand-image'}
																			webp={props.productsSelected[index].interface.magistral.image.webp}
																			jp2={props.productsSelected[index].interface.magistral.image.jp2}
																			jxr={props.productsSelected[index].interface.magistral.image.jxr}
																			default={props.productsSelected[index].interface.magistral.image.png}
																			title={'Contra pedido'}
																		/>
																	</div>
																)}
															</>
														)}
													</div>
												</div>
											</Col>
											<Col span={24}>
												<h4 className="ph-quotation-cart-pharmacies-title">{translates.pharmacies[ENV_COUNTRY]} disponibles:</h4>
											</Col>
											<Col span={24}>
												<div className="ph-quotation-cart-pharmacies-container">
													<QuotePharmacies
														theContainerIndex={index}
														cartForm={props.cartForm}
														productSelected={props.productsSelected[index]}
														handleAddProductCartElectronic={handleAddProductCartElectronic}
														handleAlternativeProduct={handleAlternativeProduct}
													/>
												</div>
											</Col>
										</Row>
									) : (
										<>
											{containerProducts.products.slice(0, seeMore[index].limit).map((item, containerIndex) => (
												<Row key={containerIndex}>
													<Col xs={24} sm={24} md={4} lg={4} xl={4}>
														<Images
															classContent={'ph-quotation-cart-producs-panel-collapse-image-container'}
															classImage={'ph-quotation-cart-producs-panel-collapse-image'}
															webp={`${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${item.id_pharol}.webp`}
															//jp2={`${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${props.item.id_pharol}.jp2`}
															//jxr={`${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${props.item.id_pharol}.jxr`}
															default={`${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${item.id_pharol}.png`}
															title={item.meta_title}
														/>
													</Col>
													<Col xs={14} sm={14} md={14} lg={14} xl={14}>
														<div className="ph-quotation-cart-panel-container">
															<div className="ph-quotation-cart-product-description-container">
																<h4 className="ph-quotation-cart-product-description-type">
																	Código: <span className="ph-quotation-cart-product-description-subtype">{item.id_pharol}</span>
																</h4>
																<h4 className="ph-quotation-cart-product-description-title">{item.meta_title}</h4>
																{item.interface && (
																	<>
																		{item.interface.on_demand && (
																			<div className="ph-quotation-cart-product-description-on-demand-container">
																				<h4 className="ph-quotation-cart-product-description-on-demand">{item.interface.on_demand.title}</h4>
																				<Images
																					classContent={'ph-quotation-cart-producs-title-image-on-demand-container'}
																					classImage={'ph-quotation-cart-producs-title-on-demand-image'}
																					webp={item.interface.on_demand.image.webp}
																					jp2={item.interface.on_demand.image.jp2}
																					jxr={item.interface.on_demand.image.jxr}
																					default={item.interface.on_demand.image.png}
																					title={'Contra pedido'}
																				/>
																			</div>
																		)}
																		{item.interface.magistral && (
																			<div className="ph-quotation-cart-product-description-on-demand-container">
																				<h4 className="ph-quotation-cart-product-description-on-demand">{item.interface.magistral.title}</h4>
																				<Images
																					classContent={'ph-quotation-cart-producs-title-image-on-demand-container'}
																					classImage={'ph-quotation-cart-producs-title-on-demand-image'}
																					webp={item.interface.magistral.image.webp}
																					jp2={item.interface.magistral.image.jp2}
																					jxr={item.interface.magistral.image.jxr}
																					default={item.interface.magistral.image.png}
																					title={'Contra pedido'}
																				/>
																			</div>
																		)}
																	</>
																)}
															</div>
														</div>
													</Col>
													<Col xs={6} sm={6} md={6} lg={6} xl={6} className="ph-quotation-cart-product-button-component-container">
														<p className="ph-quotation-cart-product-button-component-title">Desde</p>
														<p className="ph-quotation-cart-product-button-component-price">
															<span className="ph-quotation-cart-product-button-component-price-symbol">$</span>
															{formatPrice(item.prices_from)}
														</p>
														<Button
															className="ph-quotation-cart-product-button"
															onClick={() =>
																handleSelectProduct({
																	...item,
																	indexSelected: index,
																})
															}
														>
															SELECCIONAR
														</Button>
													</Col>
												</Row>
											))}
										</>
									)}
								</div>
								{containerProducts.products.length > 3 && (
									<p
										className="ph-quotation-cart-see-more"
										onClick={() => {
											setSeeMore((previousState) => ({
												...previousState,
												[index]: {
													...previousState[index],
													limit: previousState[index].limit + 3 >= previousState[index].theLength ? previousState[index].theLength : previousState[index].limit + 3,
												},
											}));
										}}
									>
										Ver más
									</p>
								)}
							</Collapse.Panel>
						</Collapse>
					)}
				</React.Fragment>
			))}
		</>
	);
}
