import { Col, Row } from 'antd';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { GetLocalStorageValue } from '../../../../_config/adapters/local-storage/get-local-storage-value.adapter';
import CustomCard from '../../../../v2-components/Antd/Card';
import CustomLink from '../../../../v2-components/Antd/Link';
import CustomPagination from '../../../../v2-components/Antd/Pagination';
import LoadingPage from '../../../../v2-components/LoadingPage';
import { DICTIONARY } from './constants/dictionary.constant';
import { formatedDate } from './handle/formated-date.handle';
import { Service } from './service';
import './style.scss';

export default function ProfileReserveHisgoryPage() {
	const history = useHistory();
	const location = useLocation();
	const [isLoading, setIsLoading] = useState(true);
	const [reserveHistory, setReserveHistory] = useState();
	const [pagination, setPagination] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const { token } = GetLocalStorageValue('userMainInfo');

	useEffect(() => {
		const params = queryString.parse(location.search);
		const page = params.page || 1;
		new Service()
			.getReserveHistory(token, page)
			.then((response) => {
				console.log('response', response);
				setReserveHistory(response.data.data);
				setPagination(response.data);
				setIsLoading(false);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [token, location.search]);

	const handlePageChange = (page) => {
		setCurrentPage(page);
		const queryParams = { page };
		history.push({ search: queryString.stringify(queryParams) });
	};

	const totalProductsCount = (array) => {
		return array.reduce((total, item) => {
			return total + item?.quantity;
		}, 0);
	};

	if (isLoading) {
		return <LoadingPage />;
	}
	return (
		<>
			<Row justify="center" className="reserve-history-content">
				<Col md={18} xs={22}>
					<Row justify="left">
						<h3>Historial de solicitudes</h3>
					</Row>
					<Row justify="left">
						<CustomLink className={'link-primary'} to="/profile-info">
							Volver
						</CustomLink>
					</Row>
					<br></br>

					{reserveHistory?.map((item, index) => {
						console.log('item', item);
						return (
							<React.Fragment key={`card-${index}`}>
								<CustomCard
									onClick={() => {
										history.push(`/profile-info/reserve-history/${item.purchase_id}/${item.operation_id}`);
									}}
									title={`Número de solicitud: ${item?.operation_id}`}
									className={'card-primary pointer'}
									extra={<p>{DICTIONARY[item?.status?.status]}</p>}
								>
									<Row justify="left">
										<Col md={8} xs={24}>
											Fecha: {formatedDate(item.created_at)}
										</Col>
										<Col md={8} xs={24}>
											Rut: {item.dni}
										</Col>
										<Col md={8} xs={24}>
											Cantidad de productos: {totalProductsCount(item.origin_products_pharmacy)}
										</Col>
									</Row>
								</CustomCard>
								<br></br>
							</React.Fragment>
						);
					})}
					<Row justify="end">
						<CustomPagination
							className={'pagination-default'}
							current={currentPage}
							total={pagination?.totalItems}
							pageSize={pagination?.itemsPerPage}
							onChange={handlePageChange}
						/>
					</Row>
				</Col>
			</Row>
		</>
	);
}
