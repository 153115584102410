/** @format */

import React, { useState } from 'react'

import { Form, Modal, Checkbox, Row, Col, Switch, Select, Button } from 'antd'

import InputPhone from '../../../../../../../components/Commons/InputCountry/Phone'
import InputField from '../../../../../../../components/Commons/Input'
import InputRut from '../../../../../../../components/Commons/InputCountry/Rut'
import InputMask from '../../../../../../../components/Commons/InputMask'
import { rulesValidationMask } from '../../../../../../../components/Commons/InputMask/rules'

import '../style.scss'

export default function BillModal(props) {
	const [form] = Form.useForm()
	const [department, setDepartment] = useState('')

	const handleFillInput = (item) => {
		item ? form.setFieldsValue(props.form.getFieldsValue()) : form.resetFields()
		setDepartment(props.form.getFieldsValue().type_house_mx)
	}

	const handleBillingForm = (item) => {
		props.setPersonalData((pvSt) => ({
			...pvSt,
			info_billing: {
				...item,
			},
		}))
		props.setBilling((previousState) => ({
			...previousState,
			modal: false,
		}))
	}

	return (
		<div>
			<Checkbox
				checked={props.billing.check}
				onChange={(checked) =>
					props.setBilling({
						modal: checked.target.checked,
						check: checked.target.checked,
					})
				}>
				<h4 className='ph-withdrawal-personal-data-facturation'> Facturación </h4>
			</Checkbox>
			<Modal
				title={
					<Row>
						<Col span={12}>
							{' '}
							<h1 className='ph-withdrawal-personal-data-facturation-title'>
								{' '}
								Datos de facturación{' '}
							</h1>
						</Col>
					</Row>
				}
				width={900}
				centered
				visible={props.billing.modal}
				wrapClassName='ph-withdrawal-personal-data-facturation-modal-container'
				onCancel={() =>
					props.setBilling({
						check: false,
						modal: false,
					})
				}
				footer={[
					<Button
						key='submit'
						className='ph-withdrawal-personal-data-facturation-btn'
						onClick={form.submit}>
						Aceptar
					</Button>,
				]}>
				<Row>
					<Col span={24} className='ph-withdrawal-personal-data-facturation-subtitle-container'>
						<span className='ph-withdrawal-personal-data-facturation-subtitle'>
							Usar los mismo datos rellenados
						</span>
						<Switch onChange={handleFillInput} />
					</Col>
				</Row>
				<Form form={form} name='mexican_billing' onFinish={handleBillingForm}>
					<Row>
						<Col
							xs={24}
							sm={24}
							md={12}
							lg={8}
							xl={8}
							className='ph-withdrawal-personal-data-form-container'>
							<InputField
								className={'ph-withdrawal-personal-data-field-input'}
								inputName={'first_name'}
								inputNameLabel={'Nombre'}
								inputNameRule={true}
								inputNameMessage={'Ingresa tu nombre'}
								inputNameType={'text'}
								inputNameRules={'rulesFirstName'}
							/>
						</Col>
						<Col
							xs={24}
							sm={24}
							md={12}
							lg={8}
							xl={8}
							className='ph-withdrawal-personal-data-form-container'>
							<InputField
								className={'ph-withdrawal-personal-data-field-input'}
								inputName={'last_name'}
								inputNameLabel={'Apellido'}
								inputNameRule={true}
								inputNameMessage={'Ingresa tu apellido'}
								inputNameType={'text'}
								inputNameRules={'rulesLastName'}
							/>
						</Col>
						<Col
							xs={24}
							sm={24}
							md={12}
							lg={8}
							xl={8}
							className='ph-withdrawal-personal-data-form-container'>
							<InputField
								className={'ph-withdrawal-personal-data-field-input'}
								inputName={'email'}
								inputNameLabel={'Correo electrónico'}
								inputNameRule={true}
								inputNameMessage={'E-mail es obligatorio'}
								inputNameType={'text'}
								inputNameRules={'rulesEmail'}
							/>
						</Col>
						<Col
							xs={24}
							sm={24}
							md={12}
							lg={8}
							xl={8}
							className='ph-withdrawal-personal-data-form-container'>
							<InputPhone
								inputPhone={'phone'}
								classInput={'ph-withdrawal-personal-data-field-input'}
							/>
						</Col>
						<Col
							xs={24}
							sm={24}
							md={12}
							lg={8}
							xl={8}
							className='ph-withdrawal-personal-data-form-container'>
							<Form.Item
								name='sex'
								rules={[
									{
										required: true,
										message: 'Introduzca su Género',
									},
								]}>
								<Select
									placeholder='Género'
									className='ph-withdrawal-personal-data-field-selector'
									size='large'
									style={{ width: '100%' }}>
									<Select.Option value='Masculino'>Masculino</Select.Option>
									<Select.Option value='Femenino'>Femenino</Select.Option>
								</Select>
							</Form.Item>
						</Col>
						<Col
							xs={24}
							sm={24}
							md={12}
							lg={8}
							xl={8}
							className='ph-withdrawal-personal-data-form-container'>
							<InputRut inputRUT='rut' classInput={'ph-withdrawal-personal-data-field-input'} />
						</Col>
						<Col
							xs={24}
							sm={24}
							md={12}
							lg={8}
							xl={8}
							className='ph-withdrawal-personal-data-form-container'>
							<Form.Item name='curp' rules={rulesValidationMask.rulesCURP}>
								<InputMask
									className={'ph-withdrawal-personal-data-field-input'}
									mask='aaaa999999aaaaaa*9'
									placeholder='CURP'
								/>
							</Form.Item>
						</Col>
						<Col
							xs={24}
							sm={24}
							md={12}
							lg={8}
							xl={8}
							className='ph-withdrawal-personal-delivery-form-container'>
							<InputField
								className={'ph-withdrawal-personal-delivery-field-input'}
								inputName={'street_mx'}
								inputNameLabel={'Calle'}
								inputNameRule={true}
								inputNameMessage={'Ingresa la calle'}
								inputNameType={'text'}
								inputNameRules={'rulesRequired'}
							/>
						</Col>
						<Col
							xs={24}
							sm={24}
							md={12}
							lg={8}
							xl={8}
							className='ph-withdrawal-personal-data-form-container'>
							<InputField
								className={'ph-withdrawal-personal-delivery-field-input'}
								inputName={'street_number_mx'}
								inputNameLabel={'No. de calle'}
								inputNameRule={true}
								inputNameMessage={'Ingrese su No. de calle'}
								inputNameType={'text'}
								inputNameRules={'rulesRequired'}
							/>
						</Col>
						<Col
							xs={24}
							sm={24}
							md={12}
							lg={8}
							xl={8}
							className='ph-withdrawal-personal-data-form-container'>
							<InputField
								className={'ph-withdrawal-personal-delivery-field-input'}
								inputName={'between_street'}
								inputNameLabel={'Calle referencial'}
								inputNameRule={true}
								inputNameMessage={'Ingrese la calle referencial'}
								inputNameType={'text'}
							/>
						</Col>
						<Col
							xs={24}
							sm={24}
							md={12}
							lg={8}
							xl={8}
							className='ph-withdrawal-personal-delivery-form-container'>
							<Form.Item
								name='type_house_mx'
								rules={[
									{
										required: true,
										message: 'Tipo de vivienda obligatorio',
									},
								]}>
								<Select
									placeholder='Tipo de vivienda'
									className='ph-withdrawal-personal-delivery-selector'
									size='large'
									style={{ width: '100%' }}
									onChange={(item) => setDepartment(item)}>
									<Select.Option value='casa'>Casa</Select.Option>
									<Select.Option value='departamento'>Departamento</Select.Option>
								</Select>
							</Form.Item>
						</Col>
						{department === 'departamento' && (
							<Col
								xs={24}
								sm={24}
								md={12}
								lg={8}
								xl={8}
								className='ph-withdrawal-personal-data-form-container'>
								<InputField
									className={'ph-withdrawal-personal-delivery-field-input'}
									inputName={'number_department_mx'}
									inputNameLabel={'No. Departamento'}
									inputNameRule={true}
									inputNameMessage={'Ingresa el No. de su departamento'}
									inputNameType={'text'}
									inputNameRules={'rulesRequired'}
								/>
							</Col>
						)}
						<Col
							xs={24}
							sm={24}
							md={12}
							lg={8}
							xl={8}
							className='ph-withdrawal-personal-data-form-container'>
							<InputField
								className={'ph-withdrawal-personal-data-field-input'}
								inputName={'colony'}
								inputNameLabel={'Colonia'}
								inputNameRule={true}
								inputNameMessage={'Colonia obligatorio'}
								inputNameType={'text'}
								inputNameRules={'rulesRequired'}
							/>
						</Col>
						<Col
							xs={24}
							sm={24}
							md={12}
							lg={8}
							xl={8}
							className='ph-withdrawal-personal-data-form-container'>
							<InputField
								className={'ph-withdrawal-personal-data-field-input'}
								inputName={'city'}
								inputNameLabel={'Ciudad'}
								inputNameRule={true}
								inputNameMessage={'Ciudad obligatorio'}
								inputNameType={'text'}
								inputNameRules={'rulesRequired'}
							/>
						</Col>
						<Col
							xs={24}
							sm={24}
							md={12}
							lg={8}
							xl={8}
							className='ph-withdrawal-personal-data-form-container'>
							<InputField
								className={'ph-withdrawal-personal-data-field-input'}
								inputName={'municipality'}
								inputNameLabel={'Municipio'}
								inputNameRule={true}
								inputNameMessage={'Municipio obligatorio'}
								inputNameType={'text'}
								inputNameRules={'rulesRequired'}
							/>
						</Col>
						<Col
							xs={24}
							sm={24}
							md={12}
							lg={8}
							xl={8}
							className='ph-withdrawal-personal-data-form-container'>
							<InputField
								className={'ph-withdrawal-personal-data-field-input'}
								inputName={'province'}
								inputNameLabel={'Provincia'}
								inputNameRule={true}
								inputNameMessage={'Provincia obligatoria'}
								inputNameType={'text'}
								inputNameRules={'rulesRequired'}
							/>
						</Col>
						<Col
							xs={24}
							sm={24}
							md={12}
							lg={8}
							xl={8}
							className='ph-withdrawal-personal-data-form-container'>
							<InputField
								className={'ph-withdrawal-personal-data-field-input'}
								inputName={'zip_code'}
								inputNameLabel={'Código Postal'}
								inputNameRule={true}
								inputNameMessage={'Código Postal obligatorio'}
								inputNameType={'text'}
								inputNameRules={'rulesRequired'}
							/>
						</Col>
						<Col
							xs={24}
							sm={24}
							md={12}
							lg={8}
							xl={8}
							className='ph-withdrawal-personal-data-form-container'>
							<InputField
								className={'ph-withdrawal-personal-data-field-input'}
								inputName={'country'}
								inputNameLabel={'País'}
								inputNameRule={true}
								inputNameMessage={'País obligatorio'}
								inputNameType={'text'}
								inputNameRules={'rulesRequired'}
							/>
						</Col>
						<Col
							xs={24}
							sm={24}
							md={12}
							lg={8}
							xl={8}
							className='ph-withdrawal-personal-data-form-container'>
							<InputField
								className={'ph-withdrawal-personal-delivery-field-input'}
								inputName={'reference_mx'}
								inputNameLabel={'Referencia'}
								inputNameRule={false}
								inputNameType={'text'}
							/>
						</Col>
						<Col
							xs={24}
							sm={24}
							md={12}
							lg={12}
							xl={12}
							className='ph-withdrawal-personal-data-form-container'>
							<Form.Item name='cfdi'>
								<Select
									placeholder='Seleccione CFDI'
									className='ph-withdrawal-personal-delivery-selector'
									size='large'
									style={{ width: '100%' }}>
									<Select.Option value='D01'>
										HONORARIOS MEDICOS, DENTALES Y GASTOS HOSPITALARIOS
									</Select.Option>
									<Select.Option value='D02'>
										GASTOS MEDICOS POR INCAPACIDAD O DISCAPACIDAD
									</Select.Option>
									<Select.Option value='D03'>GASTOS FUNERALES</Select.Option>
									<Select.Option value='D04'>DONATIVOS</Select.Option>
									<Select.Option value='D05'>
										INTERESES REALES EFECTIVAMENTE PAGADOS POR CREDITOS HIPOTECARIOS (CASA
										HABITACION)
									</Select.Option>
									<Select.Option value='D06'>APORTACIONES VOLUNTARIAS AL SAR</Select.Option>
									<Select.Option value='D07'>PRIMAS POR SEGUROS DE GASTOS MEDICOS</Select.Option>
									<Select.Option value='D08'>
										GASTOS DE TRANSPORTACION ESCOLAR OBLIGATORIA
									</Select.Option>
									<Select.Option value='D09'>
										DEPOSITOS EN CUENTAS PARA EL AHORRO, PRIMAS QUE TENGAN COMO BASE PLANES DE
										PENSIONES
									</Select.Option>
									<Select.Option value='D10'>
										PAGOS POR SERVICIOS EDUCATIVOS (COLEGIATURAS)
									</Select.Option>
									<Select.Option value='G01'>ADQUISICION DE MERCANCIAS</Select.Option>
									<Select.Option value='G02'>
										DEVOLUCIONES, DESCUENTOS O BONIFICACIONES
									</Select.Option>
									<Select.Option value='G03'>GASTOS EN GENERAL</Select.Option>
									<Select.Option value='I01'>CONSTRUCCIONES</Select.Option>
									<Select.Option value='I02'>
										MOBILARIO Y EQUIPO DE OFICINA POR INVERSIONES
									</Select.Option>
									<Select.Option value='I03'>EQUIPO DE TRANSPORTE</Select.Option>
									<Select.Option value='I04'>EQUIPO DE COMPUTO Y ACCESORIOS</Select.Option>
									<Select.Option value='I05'>
										DADOS, TROQUELES, MOLDES, MATRICES Y HERRAMENTAL
									</Select.Option>
									<Select.Option value='I06'>COMUNICACIONES TELEFONICAS</Select.Option>
									<Select.Option value='I07'>COMUNICACIONES SATELITALES</Select.Option>
									<Select.Option value='I08'>OTRA MAQUINARIA Y EQUIPO</Select.Option>
									<Select.Option value='P01'>POR DEFINIR</Select.Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		</div>
	)
}
