/** @format */

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import qs from 'qs';

import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';

import { useLocation } from 'react-router-dom';

import { Row, Col } from 'antd';

import MetaDescription from '../../components/Commons/MetaDescription';
import Images from '../../components/Commons/Images';
import Loading from '../../components/Commons/Loading';
import Elastic from '../../components/Elastic';
import TimelinePages from '../../components/TimelinePages';
import GetLaboratory from './services';
import { HandleFacets } from '../../components/Elastic/functions/HandleFacets';
import { GetPixelsManagement } from '../../components/Commons/Hooks/Functions/GetPixels';
import { PixelFacebook } from '../../components/Commons/Hooks/Functions/PixelFacebook';
import { ENV_COUNTRY } from '../../components/Commons//Hooks/Variables/Enviroment';

import { Country } from './country';
import './style.scss';

export default function Laboratory(props) {
	const { t } = useTranslation('global');
	const params = useLocation();
	const [isQuery] = useState(qs.parse(params.search.slice(1)));
	const [isList, setList] = useState(null);
	const [isLoading, setLoading] = useState(true);
	useEffect(() => {
		GetPixelsManagement('laboratory', props.match.params.laboratory).then((response) => {
			if (response) {
				if (response.facebook) {
					ReactPixel.init(response.facebook, null, { autoConfig: true, debug: false });
					setTimeout(() => {
						PixelFacebook();
					}, 500);
				}
				if (response['google-tag-manager']) {
					setTimeout(() => {
						TagManager.initialize({ gtmId: response['google-tag-manager'] });
					}, 500);
				}
			}
		});
	}, [props.match.params.laboratory]);

	useEffect(() => {
		window.scrollTo(0, 0);
		GetLaboratory(props.match.params.laboratory)
			.then((response) => {
				if (response) {
					setTimeout(() => {
						setList(response);
						setLoading(false);
					}, 600);
				}
			})
			.catch((e) => console.log(e));
		if (isQuery.voucher) {
			localStorage.setItem('validVoucher', isQuery.voucher);
		}
	}, [props.match.params.laboratory, isQuery.voucher]);

	const handleFilters = () => {
		const query = props.match.params.query ? props.match.params.query : '';
		const filters = Country[ENV_COUNTRY].handleFilters(props.match.params);
		const index = Country[ENV_COUNTRY].handleElasticPrecision(query);
		const sort = Country[ENV_COUNTRY].handleSort();
		const facets = HandleFacets('laboratory');
		const params = { filters, query, index, facets, sort };
		return params;
	};

	if (isLoading === true) {
		return <Loading />;
	}

	if (isLoading === false) {
		return (
			<>
				<MetaDescription
					title={`${isList.name || 'Laboratorio'} - ${t('main.metaTitle')}`}
					name={'description'}
					content={`${isList.description || ''}`}
					robots={true}
				/>
				{isList.name && (
					<>
						<div className="ph-laboratory-global-container">
							<TimelinePages item={props.match.params} />
							<div className="ph-laboratory-main-container">
								<Row>
									<Col xs={24} sm={24} md={6} lg={6} xl={6} className="ph-laboratory-main-image-main-container">
										<Images
											classContent={'ph-laboratory-main-image-container'}
											classImage={'ph-laboratory-main-image'}
											default={isList.image}
											title={isList.name}
											alt={isList.name}
										/>
									</Col>
									<Col xs={24} sm={24} md={18} lg={18} xl={18} className="ph-laboratory-description-main-container">
										<div>
											<p className="ph-laboratory-description-info">{isList.description}</p>
										</div>
									</Col>
								</Row>
							</div>
						</div>
					</>
				)}

				<Elastic
					laboratoryIdentifier={isList}
					shortageLetter={isList.shortage_letter}
					sidebarBanners={isList.side_banners}
					params={handleFilters()}
					showSearchBox={true}
					placeholcerSearchBox={'Buscar en este laboratorio'}
				/>
			</>
		);
	}
}
