/** @format */

import React from 'react';
import { useHistory } from 'react-router-dom';

import { Row, Col, notification } from 'antd';

import Images from '../../../../../../components/Commons/Images';

import { ENV_IMG } from '../../../../../../components/Commons/Hooks/Variables/Enviroment';

import './style.scss';

export function NewNotification() {
	const history = useHistory();

	const handleOpenModalAddress = () => {
		notification.close(3);
		history.push('/');
	};

	const handleShowNotification = (message) => {
		notification.open({
			duration: 0,
			className: 'ph-new-notification-main-container',
			description: (
				<>
					<div className="ph-new-notification-main-title-container">
						<h1 className="ph-new-notification-main-title">Información sobre su convenio:</h1>
					</div>
					<Row className="ph-new-notification-description-main-container">
						<Col span={6} className="ph-new-notification-image-main-container">
							<Images
								classContent={'ph-new-notification-image-container'}
								classImage={'ph-new-notification-image'}
								webp={`${ENV_IMG}/react-notification/warning.webp`}
								jp2={`${ENV_IMG}/react-notification/warning.jp2`}
								jxr={`${ENV_IMG}/react-notification/warning.jxr`}
								default={`${ENV_IMG}/react-notification/warning.png`}
								alt={'Notification'}
								title={'Notification'}
							/>
						</Col>
						<Col span={18}>
							<div className="ph-new-notification-pharmacy">
								<p className="ph-new-notification-pharmacy-title">¡Aviso!</p>
							</div>
							<div className="ph-new-notification-price-container">
								<p className="ph-new-notification-price-title">{message}</p>
							</div>
						</Col>
					</Row>
					<hr className="ph-new-notification-separator" />
					<Row>
						<Col span={24} className="ph-new-notification-total-container">
							<Images
								classContent={'ph-new-notification-total-image-container'}
								classImage={'ph-new-notification-total-image'}
								webp={`${ENV_IMG}/react-header/pharol-home.webp`}
								jp2={`${ENV_IMG}/react-header/pharol-home.jp2`}
								jxr={`${ENV_IMG}/react-header/pharol-home.jxr`}
								default={`${ENV_IMG}/react-header/pharol-home.png`}
								alt={'item.name'}
								title={'item.name'}
							/>
							<p className="ph-new-notification-total-title">¿Desea regresar al inicio?</p>
							<span className="ph-new-notification-total-subtitle" onClick={() => handleOpenModalAddress(true)}>
								Click Aquí
							</span>
						</Col>
					</Row>
				</>
			),
			key: 3,
			placement: 'topRight',
		});
	};

	return {
		handleShowNotification,
	};
}
