/** @format */

import React from 'react'

import Images from '../../../../../../../../../../../../components/Commons/Images'

import './style.scss'

export default function ProductPromotions(props) {
	return (
		<>
			{props.item.interface && (
				<>
					{props.item.interface.promotions && (
						<div className='ph-product-detail-quote-promotion-container'>
							{props.item.interface.promotions.img_promotion && (
								<>
									{(props.item.interface.promotions.img_promotion.webp ||
										props.item.interface.promotions.img_promotion.jp2 ||
										props.item.interface.promotions.img_promotion.jxr ||
										props.item.interface.promotions.img_promotion.default) && (
										<Images
											classContent={'ph-product-detail-quote-promotion-icon-container'}
											classImage={'ph-product-detail-quote-promotion-icon'}
											webp={props.item.interface.promotions.img_promotion.webp}
											jp2={props.item.interface.promotions.img_promotion.jp2}
											jxr={props.item.interface.promotions.img_promotion.jxr}
											default={props.item.interface.promotions.img_promotion.default}
											title={'Promotion icon'}
										/>
									)}
								</>
							)}
							<p className='ph-product-detail-quote-promotion-title'>
								{props.item.interface.promotions.title}
							</p>
						</div>
					)}
				</>
			)}
		</>
	)
}
