export const DATA_STEPS = [
	{
		status: 100,
		title: 'Por aprobar',
	},
	{
		status: 200,
		title: 'Aprobada',
	},
	{
		status: 300,
		title: 'Rechazada',
	},
	{
		status: 400,
		title: 'Completada',
	},
];
