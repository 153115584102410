/** @format */

export const HandleFormatResult = (item) => {
	const hanleFormatItems = (data, info) => {
		if (
			info === 'stock' ||
			info === 'price' ||
			info === 'bioequivalent' ||
			info === 'brand' ||
			info === 'id_pharol' ||
			info === 'limit_stock' ||
			info === 'price_per_quantity'
		)
			return parseFloat(data[info]?.raw)
		else if (info === 'on_demand' || info === 'sort_stock') {
			if (data[info]?.raw === 'true') return true
			else if (data[info]?.raw === 'false') return false
			if (data[info]?.raw === 'null') return null
		} else if (info === 'interface') return JSON.parse(data[info]?.raw)
		else if (info === 'service' || info === 'interface_pbm') {
			let array = []
			data[info]?.raw.forEach((element) => {
				array.push(JSON.parse(element))
			})
			return array
		} else return data[info]?.raw
	}
	let productResult = {}
	if (item) {
		Object.keys(item).map((element) => {
			productResult = {
				...productResult,
				[element]: hanleFormatItems(item, element),
			}
			return productResult
		})
	}
	return productResult
}
