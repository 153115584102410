import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
export default function CustomLink(props) {
	return (
		<>
			<Link {...props} />
		</>
	);
}
