/** @format */

import { Col, Row } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { setGlobal, useGlobal } from 'reactn';
import CustomButton from '../../../../v2-components/Antd/Button';
import CustomModal from '../../../../v2-components/Antd/Modal';
import { ENVIROMENT } from '../../../../v2-config/constants/enviroment.constant';
import { ICONS } from '../../../../v2-config/constants/icons.constant';
import './style.scss';

export default function ModalWelcome() {
	const history = useHistory();
	const [modalWelcome] = useGlobal('GlobalModalWelcome');

	const handleCloseModal = () => {
		const location = JSON.parse(localStorage.getItem('locationStore'));
		let userNotifications = JSON.parse(localStorage.getItem('userNotifications'));
		let date = new Date();
		let dateUTC = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toJSON();
		userNotifications = {
			...userNotifications,
			welcomeNotification: true,
			dateWelcome: dateUTC,
		};
		localStorage.setItem('userNotifications', JSON.stringify(userNotifications));
		setGlobal(() => ({ GlobalModalWelcome: false }));
		if (location.default) {
			setGlobal(() => ({
				GlobalModalSuggestion: true,
			}));
		}
	};

	const handleCloseModalToAddress = () => {
		let userNotifications = localStorage.getItem('userNotifications');
		userNotifications = {
			...userNotifications,
			welcomeNotification: true,
			dateWelcome: new Date(),
		};

		localStorage.setItem('userNotifications', JSON.stringify(userNotifications));
		setGlobal(() => ({ GlobalModalWelcome: false }));
		history.push('/auth/login');
	};

	return (
		<CustomModal
			className="modal-primary"
			maskClosable={false}
			title={'Te damos la bienvenida a ' + ENVIROMENT.ROOT_NAME}
			visible={modalWelcome}
			onCancel={() => handleCloseModal()}
			footer={false}
		>
			<Row justify={'center'}>
				<Col span={8}>
					<img src={ICONS.WELCOME} alt="Bienvenido" title="Bienvenido" style={{ width: '100%' }} />
				</Col>
				<h4>Cuando inicies sesión mejorará la experiencia de tu navegación</h4>
			</Row>
			<Row gutter={[16, 16]}>
				<Col md={12} xs={24}>
					<CustomButton className="button-secondary" block onClick={() => handleCloseModal()}>
						En Otro Momento
					</CustomButton>
				</Col>
				<Col md={12} xs={24}>
					<CustomButton className="button-primary" block onClick={() => handleCloseModalToAddress()}>
						Iniciar Sesión
					</CustomButton>
				</Col>
			</Row>
		</CustomModal>
	);
}
