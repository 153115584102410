/** @format */

import axios from 'axios';
import { ENV_COUNTRY, ENV_ELASTIC_KEY, ENV_ELASTIC_ENVIROMENT } from '../../../Hooks/Variables/Enviroment';
import { Country } from './country';

export const SearchProducts = async (query, pharmacy = null) => {
	console.log('SearchProducts', query, pharmacy);

	let filters = Country[ENV_COUNTRY].handleFilters(query);
	let elasticEngine = Country[ENV_COUNTRY].handleIndex();

	let returnResponse = null;
	await axios({
		method: 'POST',
		url: `${ENV_ELASTIC_ENVIROMENT}/api/as/v1/engines/${elasticEngine}/search`,
		headers: {
			Authorization: `Bearer ${ENV_ELASTIC_KEY}`,
		},
		data: {
			query: Country[ENV_COUNTRY].handleQuery(query),
			filters: filters,
			page: {
				size: 20,
				current: 1,
			},
		},
	}).then(async (response) => {
		console.log('SearchProducts response', response.data.results);
		const map = await response.data.results.map((iterator) => {
			return {
				_meta: iterator._meta.raw,
				bioequivalent: iterator.bioequivalent.raw,
				brand: iterator.brand.raw,
				car: iterator.car.raw,
				category: iterator.category.raw,
				cenabast: iterator.cenabast.raw,
				clear_concentration: iterator.clear_concentration.raw,
				code_isp: iterator.code_isp.raw,
				concentration: iterator.concentration.raw,
				cooled: iterator.cooled.raw,
				ean13: iterator.ean13.raw,
				editable_name: iterator.editable_name.raw,
				id: iterator.id.raw,
				id_pharol: iterator.id_pharol.raw,
				image: iterator.image.raw,
				image_120: iterator.image_120.raw,
				image_320: iterator.image_320.raw,
				indication: iterator.indication.raw,
				interface: iterator.interface.raw,
				interface_pbm: iterator.interface_pbm.raw,
				laboratory: iterator.laboratory.raw,
				limit_stock: iterator.limit_stock.raw,
				liquid: iterator.liquid.raw,
				master_category: iterator.master_category.raw,
				meta_descriptions: iterator.meta_descriptions.raw,
				meta_keywords: iterator.meta_keywords.raw,
				meta_title: iterator.meta_title.raw,
				name: iterator.name.raw,
				on_demand: iterator.on_demand.raw,
				original_image: iterator.original_image.raw,
				pharmacy: iterator.pharmacy.raw,
				pregnancy_and_lactation: iterator.pregnancy_and_lactation.raw,
				price_per_quantity: iterator.price_per_quantity.raw,
				principe_active: iterator.principe_active.raw,
				promotions: iterator.promotions.raw,
				quantity_per_container: iterator.quantity_per_container.raw,
				referrer: iterator.referrer.raw,
				shape_pharmacy: iterator.shape_pharmacy.raw,
				slug: iterator.slug.raw,
				sort_stock: iterator.sort_stock.raw,
				storage_conditions: iterator.storage_conditions.raw,
				sub_category: iterator.sub_category.raw,
				validate_recipe: iterator.validate_recipe.raw,
			};
		});

		returnResponse = map;
	});

	return returnResponse;
};
