/** @format */

import React, { useState } from 'react';

import { useHistory, Link } from 'react-router-dom';

import { Modal, Row, Col, Button, Spin } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

import Images from '../../../../../../../../components/Commons/Images';
import Notify from '../../../../../../../../components/Commons/Notify';
import Address from '../../../../../../../../components/Commons/Address';

import { formatPrice } from '../../../../../../../../components/Commons/Hooks/Functions/FormatPrice';

import { DispatchLoginNotify } from '../../../../../DispatchLoginNotify';

import GetDeliveryInfo from '../../../../../../../../components/Commons/Hooks/Functions/GetDeliveryInfo';

import './style.scss';

export default function ProductDispatchInfo(props) {
	const history = useHistory();
	const [isVisible, setVisible] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [isInfo, setInfo] = useState(null);

	const handleCloseModal = () => {
		setVisible(false);
	};

	const handleCheckAvailability = () => {
		if (localStorage.getItem('userEmail') && localStorage.getItem('userToken')) {
			setVisible(true);
		} else {
			DispatchLoginNotify(history);
		}
	};

	const handleCalculateDelivery = async (item) => {
		let address = JSON.parse(localStorage.getItem('locationStore'));
		if (address.default) {
			Notify({
				title: `Error`,
				colorTitle: 'ph-main-notification-error-title',
				description: '¡Debes ingresar una dirección para pocesar tu posible precio y fecha de entrega!',
				time: 4,
				image: 'error',
				placement: 'topRight',
			});
			return;
		}
		setLoading(true);
		await GetDeliveryInfo(item).then((response) => {
			if (response) {
				setInfo(response);
			}
		});
		setLoading(false);
	};

	return (
		<>
			<div className="ph-product-detail-dispatch-info-main-container">
				<Images
					classContent={'ph-product-detail-dispatch-info-image-container'}
					classImage={'ph-product-detail-dispatch-info-image'}
					webp={props.image.webp}
					jp2={props.image.jp2}
					jxr={props.image.jxr}
					default={props.image.default}
					title={props.title}
					alt={props.title}
				/>
				<div>
					{props.title === 'Despacho a domicilio' ? (
						<>
							{props.product.stock > 0 ? (
								<>
									{isInfo ? (
										<>
											<h3 className="ph-product-detail-dispatch-info-title">
												<span className="ph-product-detail-dispatch-info-link">{isInfo.message}</span>
											</h3>
											<h4 className="ph-product-detail-dispatch-info-subtitle-pharmacy">
												{isInfo.date} - {formatPrice(isInfo.price)}
											</h4>
										</>
									) : (
										<>
											<h3 className="ph-product-detail-dispatch-info-title">
												<span className="ph-product-detail-dispatch-info-link">{props.title}</span>
											</h3>
											<h4 className="ph-product-detail-dispatch-info-subtitle-dispatch" onClick={() => handleCheckAvailability()}>
												Calcular
											</h4>
										</>
									)}
								</>
							) : (
								<>
									<h3 className="ph-product-detail-dispatch-info-title">
										<span className="ph-product-detail-dispatch-info-link">{props.title}</span>
									</h3>
									<h4 className="ph-product-detail-dispatch-info-subtitle-dispatch-disabled">Calcular</h4>
								</>
							)}
						</>
					) : (
						<>
							<h3 className="ph-product-detail-dispatch-info-title">
								<span className="ph-product-detail-dispatch-info-link">{props.title}</span>
							</h3>
							<h4 className="ph-product-detail-dispatch-info-subtitle-pharmacy">Gratis</h4>
						</>
					)}
				</div>
			</div>
			<Modal
				wrapClassName="ph-calculate-delivery-pharmacy-modal-container"
				maskClosable={false}
				width="500px"
				title="Calculo de Despacho"
				centered
				visible={isVisible}
				onCancel={() => handleCloseModal()}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
			>
				<Row>
					<Col className="ph-calculate-delivery-pharmacy-modal-title-container" xs={24} sm={24} md={24} lg={24} xl={24}>
						<h3 className="ph-calculate-delivery-pharmacy-modal-title">¡Calcula tu costo y fecha posible de Despacho!</h3>
						<h5 className="ph-calculate-delivery-pharmacy-modal-description">Ingresa tu dirección exacta para una mejor experiencia:</h5>
					</Col>
					<div className="ph-repurchase-form-pharmacy-address-component-container">
						<Address classType={'ph-repurchase-form-address'} />
					</div>
					<div className="ph-repurchase-form-pharmacy-address-link-profile-address-container">
						<Link className="ph-repurchase-form-pharmacy-address-link-profile-address" to="/profile/address">
							Gestionar mis direcciones
						</Link>
					</div>

					{isInfo ? (
						<div className="ph-repurchase-form-pharmacy-address-result-delivery-container">
							<div className="ph-repurchase-form-pharmacy-address-result-delivery-info-container">
								<Images
									classContent={'ph-product-detail-quote-dispatch-info-image-container'}
									classImage={'ph-product-detail-quote-dispatch-info-image'}
									webp={props.image.webp}
									jp2={props.image.jp2}
									jxr={props.image.jxr}
									default={props.image.default}
									title={props.title}
									alt={props.title}
								/>
								<div>
									<h4 className="ph-repurchase-form-pharmacy-address-result-delivery-info-title">Recibir compra</h4>
									<h4 className="ph-repurchase-form-pharmacy-address-result-delivery-info-message">{isInfo.message}:</h4>
									{isInfo.day && isInfo.date && (
										<>
											<h4 className="ph-repurchase-form-pharmacy-address-result-delivery-info-day">
												{isInfo.day}, {isInfo.date}
											</h4>
											<h4 className="ph-repurchase-form-pharmacy-address-result-delivery-info-date">
												<span className="ph-repurchase-form-pharmacy-address-result-delivery-info-date-span">*</span>
												Lo seleccionas en el checkout
											</h4>
										</>
									)}
								</div>
							</div>
							<div className="ph-repurchase-form-pharmacy-address-result-delivery-price-container">
								{isInfo.price && (
									<>
										<h4 className="ph-repurchase-form-pharmacy-address-result-delivery-price-title">Valor:</h4>
										<h4 className="ph-repurchase-form-pharmacy-address-result-delivery-price-total">$ {formatPrice(isInfo.price)}</h4>
									</>
								)}
							</div>
						</div>
					) : (
						<div className="ph-repurchase-form-pharmacy-address-info-preloading-container">
							{isLoading && (
								<div className="ph-repurchase-form-pharmacy-address-info-loading-container">
									<Spin className="ph-repurchase-form-pharmacy-address-info-loading-icon" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
								</div>
							)}
							<h4 className="ph-repurchase-form-pharmacy-address-info-preloading-title">
								Usa el botón <span className="ph-repurchase-form-pharmacy-address-info-preloading-subtitle">Calcular</span> para procesar tu información
							</h4>
						</div>
					)}
				</Row>
				<div className="ph-repurchase-form-pharmacy-calculate-button-container">
					<Button className="ph-repurchase-form-pharmacy-calculate-button" onClick={() => handleCalculateDelivery(props.product)} loading={isLoading}>
						Calcular
					</Button>
				</div>
			</Modal>
		</>
	);
}
