/** @format */

import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Menu, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { setGlobal, useGlobal } from 'reactn';
import { FindIpAdapter } from '../../../../../_config/adapters/ip/ip-location.http';
import { ResponseIpAdapter } from '../../../../../_config/adapters/ip/ip-response.adapter';
import { LocalStorage } from '../../../../../_config/adapters/local-storage/local-storage.constant';
import { StorageSetItemAdapter } from '../../../../../_config/adapters/local-storage/storage-set-item.adapter';
import CustomButton from '../../../../../v2-components/Antd/Button';
import { ReactComponent as IconLocation } from '../../../../../v2-config/icons-svg/location.svg';
import { GlobalConsumer } from '../../../Context/Global';
import { AddressSearch } from '../../../Hooks/Functions/AddressSearch';
import { AddressSetLocation } from '../../../Hooks/Functions/AddressSetLocation';
import { PharmacySetLocation } from '../../../Hooks/Functions/PharmacySetLocation';
import Images from '../../../Images';
import InputField from '../../../Input';
import MapAddress from './components/MapAddress';
import { handleAddressContainer } from './handle/address-container.handle';
import { handleLocationImage } from './handle/location-image.handle';
import { handleShortName } from './handle/short-name.handle';

import './style.scss';

export default function AddressComponent() {
	const { t } = useTranslation('global');
	const { isModalAddress, setModalAddress, isLocation, setLocation } = GlobalConsumer();
	const history = useHistory();
	const location = useLocation();
	const [isLoadingButton, setLoadingButton] = useState(false);
	const [isAddressList, setAddressList] = useState([]);
	const [isAddressDisplay, setAddressDisplay] = useGlobal('address');
	const [, setNearPharmacy] = useGlobal('mapPharmacy');
	const [isOnDemand] = useGlobal('onDemandCart');
	const [attempts, setAttempts] = useState(true);
	const [form] = Form.useForm();

	const handleSearchAddress = async () => {
		const value = form.getFieldValue('address');

		setLoadingButton(true);
		await AddressSearch(value).then((response) => {
			setAddressList(response);
		});
		setLoadingButton(false);
	};

	const handleSetLocation = async (item) => {
		console.log('handleSetLocation', item);
		let address = null;
		setLoadingButton(true);
		setAddressList([]);
		await AddressSetLocation(item).then((response) => {
			address = response;
			PharmacySetLocation(address).then((response) => {
				setNearPharmacy(response);
			});
		});
		setLocation(address);
		form.resetFields();
		setLoadingButton(false);
		setGlobal({ collapseMapPharmacies: [] });
		setGlobal(() => ({ userAddressList: address }));
	};
	const handleCloseModal = () => {
		setModalAddress(false);
	};

	const handleOkModal = () => {
		setModalAddress(false);
		if (location.pathname.match(/cart/)) {
			history.go(0);
		}

		window.location.reload();
		window.scrollTo(0, 0);
	};

	const handleOpenModal = () => {
		setModalAddress(true);
	};

	useEffect(() => {
		if (
			location.pathname.match(/cart/) ||
			location.pathname.match(/product-check/) ||
			(location.pathname.match(/electronic-prescription/) && !location.pathname.match('/on_demand'))
		) {
			console.log('isLocation', isLocation);
			if (isLocation.default && attempts === true) {
				setAttempts(false);
			}
		}
	}, [isModalAddress, isLocation, setModalAddress, location.pathname, attempts]);

	useEffect(() => {
		PharmacySetLocation(isLocation).then((response) => {
			setNearPharmacy(response);
		});
		setAddressDisplay(isLocation);
		const onDemand = JSON.parse(localStorage.getItem('onDemandData')) ? true : false;
		setGlobal(() => ({ onDemandCart: onDemand }));
	}, [isLocation, setAddressDisplay, setNearPharmacy]);

	useEffect(() => {
		FindIpAdapter().then((response) => {
			StorageSetItemAdapter(LocalStorage.location, ResponseIpAdapter(response));
		});
	}, []);

	return (
		<>
			<div className={handleAddressContainer(location)} onClick={() => handleOpenModal()}>
				<IconLocation className={'icons-theme-address'} />
				<div className="ph-address-description-container">
					{isAddressDisplay && (
						<>
							<p className="ph-address-description-1">{isAddressDisplay.street}</p>
							<p className="ph-address-description-2">
								{isAddressDisplay.commune}, {isAddressDisplay.region}
							</p>
						</>
					)}
				</div>
			</div>
			<Modal
				wrapClassName="ph-address-modal-container"
				maskClosable={false}
				title="Gestión de Direcciones"
				centered
				visible={isModalAddress}
				onCancel={() => handleCloseModal()}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
			>
				<Row justify="center">
					<Col className="ph-address-modal-title-container" xs={24} sm={24} md={24} lg={24} xl={24}>
						<h5 className="ph-address-modal-description">{t('address.modal.subtitle')}</h5>
						<div className="ph-address-form-main-container">
							<Form className="ph-address-form-container" form={form}>
								<InputField
									disabled={isOnDemand}
									className={'ph-address-search-field'}
									inputName={'address'}
									inputNameLabel={t('address.modal.formInput')}
									inputNameType={'text'}
									inputNameRule={true}
									inputNameMessage={'Ingresa tu correo......'}
									onChange={() => setAddressList(null)}
									autocomplete="off"
								/>
								<Button
									disabled={isOnDemand}
									loading={isLoadingButton}
									className="ph-address-search-button"
									htmlType="submit"
									icon={<SearchOutlined />}
									onClick={handleSearchAddress}
								/>
							</Form>

							<div className="ph-address-search-list">
								{isAddressList && (
									<Menu>
										{isAddressList.map((address, index) => (
											<Menu.Item onClick={() => handleSetLocation(address.name)} className="ph-address-search-list-description" key={index}>
												<Images
													classContent={'ph-address-search-list-image-container'}
													classImage={'ph-address-search-list-image'}
													default={handleLocationImage()}
													title={'location'}
												/>
												{handleShortName(address.name)}
											</Menu.Item>
										))}
									</Menu>
								)}
							</div>
						</div>
					</Col>
					<Col span={24}>
						{isAddressDisplay && (
							<>
								<div className="ph-address-box-inner-main-container">
									<div className="ph-address-box-address-display-container">
										<h3 className="ph-address-box-title">Tu dirección actual es:</h3>
										<div className="ph-address-box-inner-container">
											<Images
												classContent={'ph-address-box-image-container'}
												classImage={'ph-address-image-logo'}
												default={handleLocationImage()}
												title={'location'}
											/>
											<div className="ph-address-description-container">
												<p className="ph-address-box-description-1">{isAddressDisplay.street}</p>
												<p className="ph-address-box-description-2">
													{isAddressDisplay.commune}, {isAddressDisplay.region}
												</p>
											</div>
										</div>
									</div>
								</div>
							</>
						)}
					</Col>
					<Col span={24}>{isAddressDisplay && <MapAddress address={isAddressDisplay} />}</Col>
					<Col span={24}>
						<br></br>
						<CustomButton className={'button-primary'} onClick={() => handleOkModal()}>
							Confirmar
						</CustomButton>
					</Col>
				</Row>
			</Modal>
		</>
	);
}
