/** @format */

export const handleCheckDelivery = (cart) => {
	let products = []
	let pharmacyIndex
	cart.forEach((item) => {
		if (pharmacyIndex !== item.pharmacy) {
			const filter = cart.filter((element) => {
				if (element.pharmacy === item.pharmacy) return element
				else return false
			})

			const delivery = filter.some((element) => !element.type_retirement)
			if (delivery && filter.length > 0) {
				filter.forEach((e) => {
					delete e.type_retirement
				})
			}
			products = [...products, ...filter]
		}
		pharmacyIndex = item.pharmacy
	})
	return products
}
