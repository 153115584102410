/** @format */

import React from 'react'

import { Link } from 'react-router-dom'
import CountryValidator from '../../../../../../../../components/Commons/CountryValidator/index'

import { ENV_COUNTRY } from '../../../../../../../../components/Commons/Hooks/Variables/Enviroment'

import data from '../../../../../../components/DispatchInfo/components/Contact/data.json'

import './style.scss'

export default function ProductContact() {
	return (
		<div span={24} className='ph-product-detail-quote-contact-container'>
			<CountryValidator 
				countries={['CL']}
				component={
					<h3 className='ph-product-detail-quote-contact-title'>
						Si tienes alguna duda{' '}
						<Link className='ph-product-detail-quote-contact-link' to='/contact'>
							contáctanos
						</Link>{' '}
						<span className='ph-product-detail-quote-contact-link-span'>
							o llámanos al {data[ENV_COUNTRY].phone}
						</span>
					</h3>
				}
			/>
		</div>
	)
}
