/** @format */

import React from 'react'

import { Spin } from 'antd'

import { LoadingOutlined } from '@ant-design/icons'

import './style.scss'

export default function BagElectronicLoading() {
	return (
		<div className='ph-bag-electronic-loading-container'>
			<Spin
				indicator={<LoadingOutlined className='ph-bag-electronic-loading-image' />}
				className='ph-bag-electronic-loading-icon'
			/>
		</div>
	)
}
