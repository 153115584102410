/** @format */

import React, { Component } from 'react';

import { Row, Col } from 'antd';

import Image from '../../components/Image';
import Info from '../../components/Info';
import Quote from '../../components/Quote';
import Promotions from '../../components/Promotions';
import Price from '../../components/Price';
import Button from '../../components/Button';
import ButtonQuantity from '../../components/ButtonQuantity';

import './style.scss';
import CountryValidator from '../../../CountryValidator';

export default class ProductList extends Component {
	state = {
		quantity: 1,
	};

	handleAddItem = (item) => {
		this.setState({
			quantity: this.state.quantity + item,
		});
	};

	handleRemoveItem = () => {
		this.setState({
			quantity: this.state.quantity - 1,
		});
	};

	render() {
		return (
			<>
				<div className={`${this.props.alternatives ? 'ph-product-list-container-alternatives' : ''} ph-product-list-container`}>
					<Row className="ph-product-list-inner-container">
						<Col
							xs={6}
							sm={4}
							md={4}
							lg={4}
							xl={4}
							className={`${this.props.alternatives ? 'ph-product-list-image-main-container-alternatives' : ''} ph-product-list-image-main-container`}
						>
							<Image detail={this.props.detail} listProductFavorite={true} alternatives={this.props.alternatives} />
						</Col>
						<Col xs={18} sm={20} md={20} lg={20} xl={20}>
							<Info classList={true} detail={this.props.detail} alternatives={this.props.alternatives} />
							{!this.props.detail.pharmacy && <Quote classList={true} available={this.props.detail.available} alternatives={this.props.alternatives} />}
							{this.props.detail.pharmacy && <Promotions classList={true} detail={this.props.detail} alternatives={this.props.alternatives} />}
							<div className={`${this.props.alternatives ? 'ph-product-list-button-main-container-alternatives' : ''} ph-product-list-button-main-container`}>
								<Row className="ph-product-list-button-inner-container">
									{this.props.detail.pharmacy && (
										<>
											{this.props.detail.stock > 0 && (
												<Col span={8} className="ph-product-list-button-add-container">
													<CountryValidator
														countries={['CL']}
														condition={this.props.alternatives}
														component={
															<ButtonQuantity
																item={this.props.detail}
																quantity={this.state.quantity}
																addItem={this.handleAddItem}
																removeItem={this.handleRemoveItem}
															/>
														}
													/>
												</Col>
											)}
										</>
									)}
									<Col span={16} className="ph-product-price-global-container">
										<div>
											<>
												{!this.props.detail.on_demand && (
													<>
														{/* <span className="ph-product-price-normal-from">Desde: </span> */}
														<Price detail={this.props.detail} alternatives={this.props.alternatives} />
													</>
												)}
											</>
											<Button
												classList={true}
												detail={this.props.detail}
												available={this.props.detail.available}
												quantity={this.state.quantity}
												alternatives={this.props.alternatives}
											/>
										</div>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}
