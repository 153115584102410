import { Config } from '../constant/config.constant';

export const handleName = (product, className, mobile = false) => {
	console.log('handleName', product.name, className, mobile);
	if (product.name.length > Config.lengthNameMobile && mobile === true) {
		return <h3 className={className}>{product.name.substring(0, Config.lengthNameMobile) + '...'}</h3>;
	}
	if (product.name.length > Config.lengthNameDesktop && mobile === false) {
		return <h3 className={className}>{product.name.substring(0, Config.lengthNameDesktop) + '...'}</h3>;
	}

	return <h3 className={className}>{product.name}</h3>;
};
