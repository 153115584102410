/** @format */

import axios from 'axios';

import Notify from '../../../../components/Commons/Notify';

import { ENV_COUNTRY, ENV_FUNCTION_CLAIM } from '../../../../components/Commons/Hooks/Variables/Enviroment';

export async function serviceGetAllClaims(item, page, limit) {
	let returnResponse;
	await axios({
		method: 'POST',
		url: `${ENV_FUNCTION_CLAIM}/search/list?page=${page}&limit=${limit}`,
		data: { ...item, country: ENV_COUNTRY },
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data;
			} else {
				Notify({
					title: `Error: ${response.data.error}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Problemas con el servicio:   ${response.data.message}`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			}
		})
		.catch(() => {
			Notify({
				title: `Problemas de Conexión`,
				colorTitle: 'ph-main-notification-error-title',
				description: `Revisa tu conexión o ponte en contacto con nosotros.`,
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
}
