/** @format */

import React, { Component } from 'react'

import Images from '../../../../../../../../components/Commons/Images'

import './style.scss'

export default class DescriptionImage extends Component {
	render() {
		return (
			<>
				<div className='ph-order-detail-products-item-image-container'>
					<h3 className={`ph-order-detail-products-item-image-title ${this.props.classTitle}`}>
						{this.props.title}
					</h3>
					<h3 className='ph-order-detail-products-item-image-subtitle'>
						{this.props.description}
					</h3>
					<Images
						classContent={'ph-order-detail-products-item-image-image-container'}
						classImage={'ph-order-detail-products-item-image-image'}
						default={this.props.image}
						alt={this.props.title}
						title={this.props.title}
					/>
				</div>
			</>
		)
	}
}
