/** @format */

import axios from 'axios'

import { setGlobal } from 'reactn'

import Notify from '../../../Notify'

import { ENV_COMMON, ENV_COUNTRY, ENV_ROOT_PHARMACY } from '../../Variables/Enviroment'

export const PharmacySetLocation = async (item) => {
	let returnResponse
	await axios({
		method: 'GET',
		url: `${ENV_COMMON}/location/pharmacy/${ENV_COUNTRY}`,
		params: {
			root_pharmacy: ENV_ROOT_PHARMACY,
			lat: item.lat,
			lng: item.lng,
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data
				localStorage.setItem('mapPharmacy', JSON.stringify(returnResponse))
				setGlobal(() => ({
					mapPharmacy: JSON.parse(localStorage.getItem('mapPharmacy')),
				}))
			} else {
				Notify({
					title: `Error ${response.data.statusCode}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `${response.data.message}`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				})
			}
		})
		.catch(() => {
			Notify({
				title: `Error`,
				colorTitle: 'ph-main-notification-error-title',
				description: 'Problemas con el servicio: Pharmacy Radius',
				time: 3,
				image: 'error',
				placement: 'topRight',
			})
		})
	return returnResponse
}
