import { DICTIONARY } from '../constants/dictionary.constant';

export const purchaseInfoAdapter = (purchase) => {
	return {
		email: purchase?.info?.email,
		first_name: purchase?.info.first_name,
		last_name: purchase?.info?.last_name,
		phone: purchase?.info?.phone,
		address: purchase?.info?.commune + ',' + purchase?.info?.street + ' ' + purchase?.info?.street_number + ',' + purchase?.info?.type_house,
		rut: purchase?.info?.rut,
		type_retirement: DICTIONARY[purchase?.info.type_retirement],
		pharmacy: purchase?.info?.pharmacy?.[0]?.pharmacy?.toUpperCase(),
		schedule_selected: purchase?.info?.pharmacy[0].schedule_selected,
		commune: purchase?.info?.pharmacy?.[0]?.commune,
		direction: purchase?.info?.pharmacy?.[0]?.direction,
	};
};
