import { Col, Row } from 'antd';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { GetLocalStorageValue } from '../../../../_config/adapters/local-storage/get-local-storage-value.adapter';
import CustomCard from '../../../../v2-components/Antd/Card';
import CustomLink from '../../../../v2-components/Antd/Link';
import CustomPagination from '../../../../v2-components/Antd/Pagination';
import LoadingPage from '../../../../v2-components/LoadingPage';
import { formatedDate } from './handle/formated-date.handle';
import { Service } from './service';
import { TYPE_RETIREMENT } from './constants/type-retirement.constant';
import './style.scss';

export default function ProfileShoppingHisgoryPage() {
	const history = useHistory();
	const location = useLocation();
	const [isLoading, setIsLoading] = useState(true);
	const [orderHistory, setOrderHistory] = useState();
	const [pagination, setPagination] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const { token } = GetLocalStorageValue('userMainInfo');

	useEffect(() => {
		const params = queryString.parse(location.search);
		const page = params.page || 1;
		new Service()
			.getPurchaseHistory(token, page)
			.then((response) => {
				setOrderHistory(response.data.data);
				setPagination(response.data);
				setIsLoading(false);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [token, location.search]);

	const handlePageChange = (page) => {
		setCurrentPage(page);
		const queryParams = { page };
		history.push({ search: queryString.stringify(queryParams) });
	};

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<>
			<Row justify="center" className="shopping-history-content">
				<Col md={18} xs={22}>
					<Row justify="left">
						<h3>Historial de compras</h3>
					</Row>
					<Row justify="left">
						<CustomLink className={'link-primary'} to="/profile-info">
							Volver
						</CustomLink>
					</Row>
					<br></br>

					{orderHistory.map((item, index) => {
						console.log('item', item);
						return (
							<React.Fragment key={`card-${index}`}>
								<CustomCard
									onClick={() => {
										history.push(`/profile-info/shopping-history/${item._id}/${item.trx_id}`);
									}}
									title={`Número de Orden: ${item.trx_id}`}
									className={'card-primary pointer'}
									extra={<p>{TYPE_RETIREMENT[item?.info?.type_retirement]}</p>}
								>
									<Row justify="left">
										<Col md={8} xs={24}>
											Fecha: {formatedDate(item.createdAt)}
										</Col>
										<Col md={8} xs={24}>
											Nombre: {item.info.first_name} {item.info.last_name}
										</Col>
										<Col md={8} xs={24}>
											Monto: ${item.total}
										</Col>
									</Row>
								</CustomCard>
								<br></br>
							</React.Fragment>
						);
					})}
					<Row justify="end">
						<CustomPagination current={currentPage} total={pagination?.totalItems} pageSize={pagination?.itemsPerPage} onChange={handlePageChange} />
					</Row>
				</Col>
			</Row>
		</>
	);
}
