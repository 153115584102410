/** @format */

import React, { useState } from 'react';
import { Modal, Button } from 'antd';

import { FilePdfOutlined } from '@ant-design/icons';

import Images from '../../../../../../../../components/Commons/Images';

import './style.scss';

export default function ReceiptModal(props) {
	const [isModal, setModal] = useState(false);

	return (
		<>
			<Button className="ph-order-detail-products-modal-receipt-link" onClick={() => setModal(true)}>
				Click para ver Receta Adjuntada
			</Button>
			<Modal
				width={600}
				wrapClassName="ph-order-detail-products-modal-container"
				centered
				title="Vista previa de Receta"
				visible={isModal}
				onCancel={() => setModal(false)}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
				okText=""
				cancelText=""
				footer={false}
			>
				{props.item.recipe && (
					<>
						{props.item.recipe.endsWith('.pdf') ? (
							<div>
								<div className="ph-tracking-detail-receipt-modal-pdf-container">
									<FilePdfOutlined className="ph-tracking-detail-receipt-modal-pdf-icon" />
								</div>
								<a className="ph-tracking-detail-receipt-modal-link" href={props.item.recipe} target="_blank" rel="noreferrer">
									Ver Receta
								</a>
							</div>
						) : (
							<Images classContent={''} classImage={'ph-order-detail-products-modal-receipt-image'} default={props.item.recipe} title={'Logo'} />
						)}
					</>
				)}

				<Button className="ph-order-detail-products-modal-receipt-button" onClick={() => setModal(false)}>
					Aceptar
				</Button>
			</Modal>
		</>
	);
}
