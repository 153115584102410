/** @format */

import axios from 'axios';

import Notify from '../../../../components/Commons/Notify';

import { ENV_USER } from '../../../../components/Commons/Hooks/Variables/Enviroment';

export const getUserDataService = async (item) => {
	let returnResponse;
	await axios({
		method: 'POST',
		url: `${ENV_USER}/user/find-user`,
		data: {
			email: item,
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				if (response.data.data.info) {
					returnResponse = response.data.data.info;
				} else {
					Notify({
						title: `Error:`,
						colorTitle: 'ph-main-notification-error-title',
						description: `Hubo un problema al cargar los datos de tu perfil, intenta llenarlos con el botón editar o comunícate con nuestro equipo de soporte.`,
						time: 7,
						image: 'error',
						placement: 'topRight',
					});
				}
			} else {
				Notify({
					title: `Error: ${response.data.error}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Problemas con el servicio:   ${response.data.message}`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			}
		})
		.catch(() => {
			Notify({
				title: `Problemas de Conexión`,
				colorTitle: 'ph-main-notification-error-title',
				description: `Revisa tu conexión o ponte en contacto con nosotros.`,
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
};
