/** @format */

import React, { useState } from 'react'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Upload from 'antd/lib/upload'
import Modal from 'antd/lib/modal'
import { CloudUploadOutlined } from '@ant-design/icons'

import Images from '../../../../components/Commons/Images'

import './style.scss'

export default function RequestRecipe(props) {
	const [fileList, setFileList] = useState([])
	const [modalPreview, setModalPreview] = useState({
		previewVisible: false,
		imagePreview: '',
		titlePreview: '',
	})

	const handleGetBase64 = (item) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(item)
			reader.onload = () => resolve(reader.result)
			reader.onerror = (error) => reject(error)
		})
	}
	const handleOnChangeImage = (item) => {
		props.setInfoSelected((pvSt) => ({
			...pvSt,
			recipe: item.fileList.length !== 0 ? item.file : {},
		}))
		setFileList(item.fileList)
	}
	const handlePreview = async (item) => {
		let previewImage = await handleGetBase64(item.originFileObj)
		setModalPreview({
			previewVisible: true,
			imagePreview: previewImage,
			titlePreview: item.name,
		})
	}

	return (
		<div className='ph-objected-recipe-upload-image-container'>
			<Upload
				accept='image/*, .pdf'
				beforeUpload={() => false}
				onChange={handleOnChangeImage}
				onPreview={handlePreview}
				listType='picture-card'
				className='image-upload-grid'>
				{fileList.length >= 1 ? null : (
					<div className='ph-objected-recipe-upload-component-main-container'>
						<div className='ph-objected-recipe-upload-component-title-container'>
							<CloudUploadOutlined className='ph-objected-recipe-upload-component-title-icon' />
							<h4 className='ph-objected-recipe-upload-component-title'>
								Haz click o arrastra tu{' '}
								<span className='ph-objected-recipe-upload-component-subtitle'>{props.title}</span>
							</h4>
						</div>
						<Row className='ph-objected-recipe-upload-component-main-container'>
							<Col
								xs={16}
								sm={16}
								md={16}
								lg={14}
								xl={14}
								className='ph-objected-recipe-upload-component-list-container'>
								<ul className='ph-objected-recipe-upload-component-list'>
									<li>Formatos Soportados: JPG,JPEG,PDF,PNG</li>
									<li>Tamaño máximo de archivo: 20MB</li>
									<li>Suba una imagen visible y de fácil lectura</li>
									<li>Use la imagen derecha como referencia:</li>
								</ul>
							</Col>
							<Col
								xs={8}
								sm={8}
								md={8}
								lg={10}
								xl={10}
								className='ph-objected-recipe-upload-component-img-container'>
								<Images
									classContent={'ph-recipe-upload-component-image-container'}
									classImage={'ph-recipe-upload-component-image'}
									default={'https://i.ibb.co/F4pXRPx/PRESCRIPTION-02-Convertido-1.png'}
									alt={'receta medica'}
									title={'receta medica'}
								/>
							</Col>
						</Row>
					</div>
				)}
			</Upload>
			<Modal
				visible={modalPreview.previewVisible}
				title={modalPreview.titlePreview}
				footer={null}
				onCancel={() => {
					setModalPreview((pvSt) => ({
						...pvSt,
						previewVisible: false,
					}))
				}}>
				<img alt='recipe' style={{ width: '100%' }} src={modalPreview.imagePreview} />
			</Modal>
		</div>
	)
}
