/** @format */

import { setGlobal } from 'reactn';
import { ProductAdapter } from '../../../../../_config/adapters/google-tag-manager/product.adapter';
import { Events } from '../../../../../_config/adapters/google-tag-manager/events.constant';

import { GlobalConsumer } from '../../../Context/Global';
import Notify from '../../../../../components/Commons/Notify';
import CheckLoginUser from '../../../../../components/Commons/Hooks/Functions/CheckLoginUser';
import CartNotification from './components/CartNotification';
import CartInnerNotification from './components/CartInnerNotification';
import { PixelFacebook } from '../PixelFacebook';
import { ENV_ISO_CURRENCY } from '../../Variables/Enviroment';
import { amountProductsCart } from '../AmountProductsCart';
import serviceCheckLocalProduct from './services';

export default function AddProductCart() {
	const { setBag } = GlobalConsumer();

	const handleAddProductCart = (item, quantity, isCart) => {
		if (!CheckLoginUser()) return;
		let cart = JSON.parse(localStorage.getItem('cart'));

		if (item.promotions && typeof item.promotions === 'string') {
			// Parsear 'promotions' a JSON ya que viene como string desde Elastic
			item.promotions = JSON.parse(item.promotions);
		}

		const index = cart.findIndex((search) => {
			if (search.objectID) return search.objectID === item.objectID;
			else return search.id === item.id;
		});
		if (index !== -1) {
			let auxQuantity = null;
			auxQuantity = cart[index].quantity + quantity;
			if (auxQuantity > item.limit_stock) {
				Notify({
					title: `Advertencia!`,
					colorTitle: 'ph-main-notification-warning-title',
					description: `¡Solo podemos dispensar ${item.limit_stock} unidades de este producto!`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			} else {
				cart[index].quantity = cart[index].quantity + quantity;
				cart[index].sub_total = cart[index].quantity * cart[index].unit_price;
				if (!isCart) {
					let checkProductNearestLocal;
					CartNotification(cart[index]);
					localStorage.setItem('cart', JSON.stringify(cart));
					setBag([...cart]);
					serviceCheckLocalProduct(item, cart).then((response) => {
						if (response) {
							checkProductNearestLocal = response;
							CartNotification(cart[index], checkProductNearestLocal);
						}
					});
				}

				if (isCart) {
					CartInnerNotification(cart[index]);
					setGlobal(() => ({
						cart: localStorage.setItem('cart', JSON.stringify(cart)),
					}));
					setBag([...cart]);
				}
			}
		} else {
			item.quantity = quantity;
			item.unit_price = item.price;
			item.sub_total = item.price * quantity;
			cart.push(item);
			localStorage.setItem('cart', JSON.stringify(cart));
			if (!isCart) {
				let checkProductNearestLocal;
				CartNotification(item);
				serviceCheckLocalProduct(item, cart).then((response) => {
					if (response) {
						checkProductNearestLocal = response;
						CartNotification(item, checkProductNearestLocal);
					}
				});
			}
			if (isCart) {
				CartInnerNotification(item);
				setGlobal(() => ({
					cart: localStorage.setItem('cart', JSON.stringify(cart)),
				}));
				setBag([...cart]);
			}
		}
		PixelFacebook('AddToCart', {
			content_ids: item.id_pharol,
			content_type: 'product',
			content_name: item.name,
			currency: ENV_ISO_CURRENCY,
			value: parseInt(item.price).toFixed(2),
		});
		ProductAdapter({ event: Events.add_to_cart, product: item, quantity: quantity });
		setGlobal(() => ({
			carQuantity: amountProductsCart(),
		}));
		setGlobal({ cart: JSON.parse(localStorage.getItem('cart')) });
		setBag([...cart]);
	};
	return {
		handleAddProductCart,
	};
}
