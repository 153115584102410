/** @format */

import { Card, Carousel, Col, Row, Typography } from 'antd';
import React from 'react';

const { Title, Paragraph } = Typography;

export default function TypeDelivery() {
	return (
		<div style={{ margin: '20px auto', width: '1200px' }}>
			<Title level={2}>Tipos de Entrega en JVF</Title>
			<Paragraph>
				Estos términos y condiciones ("Términos", "Acuerdo") son un acuerdo entre el proveedor de servicios ("Proveedor de servicios", "nosotros", "nos" o
				"nuestro") y usted ("Usuario", "usted" o "su"). Este Acuerdo establece los términos y condiciones generales de su uso del sitio web [Nombre del sitio
				web] y cualquiera de sus productos o servicios (colectivamente, "Sitio web" o "Servicios").
			</Paragraph>
			<br></br>
			<Carousel autoplay>
				<div>
					<img src="https://placehold.co/1200x300" alt="First slide" style={{ width: '100%', height: 'auto' }} />
				</div>
				<div>
					<img src="https://placehold.co/1200x300" alt="Second slide" style={{ width: '100%', height: 'auto' }} />
				</div>
				<div>
					<img src="https://placehold.co/1200x300" alt="Third slide" style={{ width: '100%', height: 'auto' }} />
				</div>
			</Carousel>

			<br></br>
			<Row gutter={16}>
				<Col key={'1'} span={8}>
					<Card hoverable style={{ width: '100%' }} cover={<img alt="example" src="https://placehold.co/400x300" />}>
						<Card.Meta
							title="Card title"
							description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
						/>
					</Card>
				</Col>
				<Col key={'2'} span={8}>
					<Card hoverable style={{ width: '100%' }} cover={<img alt="example" src="https://placehold.co/400x300" />}>
						<Card.Meta
							title="Card title"
							description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
						/>
					</Card>
				</Col>
				<Col key={'3'} span={8}>
					<Card hoverable style={{ width: '100%' }} cover={<img alt="example" src="https://placehold.co/400x300" />}>
						<Card.Meta
							title="Card title"
							description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
						/>
					</Card>
				</Col>
			</Row>
			<br></br>
			<Row gutter={16}>
				<Col key={'1'} span={12}>
					<Card hoverable style={{ width: '100%' }} cover={<img alt="example" src="https://via.placeholder.com/600x350" />}>
						<Card.Meta
							title="Card title"
							description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
						/>
					</Card>
				</Col>
				<Col key={'2'} span={12}>
					<Card hoverable style={{ width: '100%' }} cover={<img alt="example" src="https://via.placeholder.com/600x350" />}>
						<Card.Meta
							title="Card title"
							description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
						/>
					</Card>
				</Col>
			</Row>
			<br></br>
			<Row gutter={16}>
				<Col key={'1'} span={24}>
					<Card hoverable style={{ width: '100%' }} cover={<img alt="example" src="https://placehold.co/1200x300" />}>
						<Card.Meta
							title="Card title"
							description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
						/>
					</Card>
				</Col>
			</Row>
		</div>
	);
}
