/** @format */

import { Enviroment } from '../../_config/constants/enviroment-v2.constant';
import { AxiosAdapter } from '../../v2-config/adapters/axios/axios.adapter';
import { Http } from '../../v2-config/adapters/axios/axios.protocols';

export class Service {
	async checkOrder(item) {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${Enviroment.FUNCTION_CHECKOUT}/verify-recipe/${item}`,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}
	async paymentList() {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${Enviroment.FUNCTION_SUCCESS_TRANSACTION}/ecommerce/payment-option-list-by-pharmacy/${Enviroment.COUNTRY}/${Enviroment.ROOT_PHARMACY}`,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}
	async payment(amount, purchase_id, payment_option_id) {
		return new AxiosAdapter({
			method: Http.POST,
			url: `${Enviroment.FUNCTION_SUCCESS_TRANSACTION}/ecommerce/${Enviroment.COUNTRY}`,
			data: { amount, purchase_id, payment_option_id },
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}

	async checkPaymentDisscount(item, data) {
		return new AxiosAdapter({
			method: Http.POST,
			url: `${Enviroment.FUNCTION_CALCULATION}/payment-method-discount/${item}/${data}`,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}
}
