/** @format */

export function RutFormat(item) {
	let rutSplitted = item.split('-')
	rutSplitted[1] =
		rutSplitted[1] === 'k' || rutSplitted[1] === 'K'
			? rutSplitted[1].toUpperCase()
			: rutSplitted[1]
	rutSplitted = rutSplitted.join('').replace(/[.]/g, '')
	return rutSplitted
}
