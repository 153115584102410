/** @format */

import axios from 'axios';

import Notify from '../../../../components/Commons/Notify';

import { ENV_USER } from '../../../../components/Commons/Hooks/Variables/Enviroment';

export async function DeleteRecipes(id) {
	const token = localStorage.getItem('userToken');
	let returnResponse;
	console.log('id...', id);
	await axios({
		headers: {
			Authorization: 'Bearer ' + token,
		},
		method: 'POST',
		url: `${ENV_USER}/recipe/delete`,
		data: {
			id: id,
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data;
			} else {
				Notify({
					title: `Error: ${response.data.error}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Problemas con el servicio:   ${response.data.message}`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			}
		})
		.catch(() => {
			Notify({
				title: `Problemas de Conexión`,
				colorTitle: 'ph-main-notification-error-title',
				description: `Revisa tu conexión o ponte en contacto con nosotros.`,
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
}
