/** @format */

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { io } from 'socket.io-client';

import { Form, Input, Spin, Row, Col, Button } from 'antd';
import { SendOutlined } from '@ant-design/icons';

import MetaDescription from '../../../../components/Commons/MetaDescription';
import TitleComplaint from '../../components/TitleComplaint';

import { serviceGetMessages, serviceSendMessage } from './services';

import './style.scss';

export default function ComplaintBookChat(props) {
	const { t } = useTranslation('global');
	const { token } = useParams();
	const [form] = Form.useForm();
	const mounted = useRef(true);
	const inputChat = useRef(null);
	const boxMessages = useRef(null);
	const [loading, setLoading] = useState(true);
	const [messages, setMessages] = useState({ claim: { status: 'open' }, messages: [] });

	const handleFetchMessages = useCallback(async () => {
		return await serviceGetMessages(token).then((response) => {
			if (!mounted.current) return;
			setMessages(response);
			return;
		});
	}, [token]);

	useEffect(() => {
		handleFetchMessages().then(() => {
			setLoading(false);
			boxMessages.current.scroll({ top: boxMessages.current.scrollHeight, behavior: 'smooth' });
		});

		const socket = io(process.env.REACT_APP_BASE_BACKEND, {
			path: '/notification-push/socket.io',
			transports: ['websocket'],
		});

		socket.on(token, (payload) => {
			setMessages((pvSt) => ({ ...pvSt, messages: [...pvSt.messages, { ...payload }] }));
			boxMessages.current.scroll({ top: boxMessages.current.scrollHeight, behavior: 'smooth' });
		});

		return () => {
			mounted.current = false;
			socket.disconnect();
		};
	}, [handleFetchMessages, token]);

	const handleSendMessage = (item) => {
		if (item.message.trim() !== '') {
			form.resetFields();
			inputChat.current.focus();
			serviceSendMessage({
				...item,
				claim_token: token,
			});
		}
	};

	return (
		<>
			<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			<div className="ph-complaint-book-success-global-container">
				<TitleComplaint
					title={'Reclamos y sugerencias - Chat en vivo'}
					description={`Volver - ${props.match.params.pharmacy}`}
					image={`react-pharmacy/complaint-book/claim-icon`}
					link={`/complaint-book/menu/${props.match.params.pharmacy}`}
				/>
				<Spin spinning={loading}>
					<div ref={boxMessages} className="ph-complaint-book-chat-box-container">
						{messages.messages.map((item, i) => (
							<div key={i} className={`ph-complaint-book-message-box ${item.owner === 'client' && 'ph-complaint-book-message-box-right'}`}>
								<div className="ph-complaint-book-message-box-message">
									<p>{item.message}</p>
								</div>
							</div>
						))}
					</div>
					<Form form={form} onFinish={handleSendMessage}>
						<Row align="middle">
							<Col xs={21} sm={21} md={21} lg={23} xl={23}>
								<Form.Item name="message" initialValue="">
									<Input autoComplete="off" className="ph-complaint-book-chat-input" ref={inputChat} placeholder="Escribir mensaje..." disabled={messages.claim.status !== 'open' ? true : false} />
								</Form.Item>
							</Col>
							<Col className="ph-complaint-book-chat-button">
								<Form.Item>
									<Button type="primary" htmlType="submit" shape="circle" ghost>
										<SendOutlined />
									</Button>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Spin>
			</div>
		</>
	);
}
