/** @format */

import React, { useState } from 'react'

import { Row, Col, Upload, Modal } from 'antd'
import { CloudUploadOutlined } from '@ant-design/icons'

import Images from '../Images'

import './style.scss'

export default function UploadImage(props) {
	const [isPreviewModal, setPreviewModal] = useState(false)
	const [isFileList, setFileList] = useState([])
	const [isPreviewImg, setPreviewImg] = useState('')

	const handleOnChangeImage = (item) => {
		if (item.fileList.length > 0) props.addProductImage(item.file)
		setFileList(item.fileList)
	}

	const handleRemoveImage = () => {
		props.removeProductImage()
	}

	const getBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result)
			reader.onerror = (error) => reject(error)
		})
	}

	const handlePreview = async (item) => {
		if (!item.url && !item.preview) {
			item.preview = await getBase64(item.originFileObj)
		}
		setPreviewImg(item.preview)
		setPreviewModal(true)
	}

	return (
		<div className='ph-recipe-upload-image-container'>
			<Upload
				accept='image/*, .doc, .pdf'
				onChange={handleOnChangeImage}
				onRemove={handleRemoveImage}
				onPreview={handlePreview}
				beforeUpload={() => false}
				multiple={false}
				maxCount={1}
				listType='picture-card'
				className='image-upload-grid'>
				{isFileList?.length >= 1 ? null : (
					<div className='ph-recipe-upload-component-main-container'>
						<div className='ph-recipe-upload-component-title-container'>
							<CloudUploadOutlined className='ph-recipe-upload-component-title-icon' />
							<h4 className='ph-recipe-upload-component-title'>
								Haz click o arrastra tu <span className='ph-recipe-upload-component-subtitle'>{props.title}</span>
							</h4>
						</div>
						<Row className='ph-recipe-upload-component-main-container'>
							<Col xs={16} sm={16} md={16} lg={14} xl={14}>
								<ul className='ph-recipe-upload-component-list'>
									<li>Formatos: JPG,JPEG,PDF,PNG, DOC</li>
									<li>Tamaño máximo de archivo: 20MB</li>
									<li>Suba una imagen visible y de fácil lectura</li>
									<li>
										Solo una <strong>(1)</strong> imagen permitida
									</li>
									<li>Use la imagen derecha como referencia:</li>
								</ul>
							</Col>
							<Col xs={8} sm={8} md={8} lg={10} xl={10} className='ph-recipe-upload-component-list-container'>
								<Images
									classContent={'ph-recipe-upload-component-image-container'}
									classImage={'ph-recipe-upload-component-image'}
									default={'https://i.ibb.co/F4pXRPx/PRESCRIPTION-02-Convertido-1.png'}
									alt={'receta medica'}
									title={'receta medica'}
								/>
							</Col>
						</Row>
					</div>
				)}
			</Upload>
			<Modal wrapClassName='ph-checkout-receipt-modal-container' visible={isPreviewModal} title={'Vista Previa:'} footer={null} onCancel={() => setPreviewModal(false)}>
				{isPreviewImg && <img alt='example' style={{ width: '100%' }} src={isPreviewImg} />}
			</Modal>
		</div>
	)
}
