/** @format */

import React, { Component } from 'react'

import Images from '../../../../../../../../components/Commons/Images'

import './style.scss'

export default class Pharmacy extends Component {
	render() {
		return (
			<>
				<div className='ph-order-detail-products-pharmacy-container'>
					<Images
						classContent={'ph-order-detail-products-pharmacy-image-container'}
						classImage={'ph-order-detail-products-pharmacy-image'}
						default={this.props.item.pharmacy_image}
						title={this.props.titleImage}
					/>
					<h3 className='ph-order-detail-products-pharmacy-title'>
						{this.props.item.name_edited_pharmacy}
					</h3>
				</div>
			</>
		)
	}
}
