/** @format */

import React, { useEffect, useState } from 'react';
import { ENV_IMG, ENV_COUNTRY } from '../../Commons/Hooks/Variables/Enviroment';

export default function Images(props) {
	const [imgSrc, setImgSrc] = useState(props.default);
	const [imgError, setImgError] = useState(false);

	const handleImageError = () => {
		const country = {
			CL: {
				handleSetImage: () => {
					setImgSrc('https://storage.googleapis.com/tirx-resources-develop/817f55db-81a6-46a4-affd-ac46d05deaa0.jpg');
				},
			},
			AR: {
				handleSetImage: () => {
					setImgSrc(`${ENV_IMG}/react-product/placeholdermed.png`);
				},
			},
		};
		setImgError(true);
		return country[ENV_COUNTRY].handleSetImage();
	};

	useEffect(() => {
		setImgSrc(props.default);
	}, [props.default]);

	return (
		<>
			<picture className={props.classContent}>
				{!imgError && (
					<>
						{/* PARCHE
						<source className={props.classImage} srcSet={props.default} type="image/webp" onError={handleImageError} />
						<source className={props.classImage} srcSet={props.default} type="image/jp2" onError={handleImageError} /> 
						<source className={props.classImage} srcSet={props.default} type="image/vnd.ms-photo" onError={handleImageError} /> */}
						<source className={props.classImage} srcSet={props.default} type="image/png" onError={handleImageError} />
						<source className={props.classImage} srcSet={props.default} type="image/jpg" onError={handleImageError} />
					</>
				)}
				<img className={props.classImage} src={imgSrc} onError={handleImageError} alt={props.title} title={props.title} />
			</picture>
		</>
	);
}
