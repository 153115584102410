/** @format */

import React from 'react'

import { Button } from 'antd'

import './style.scss'

export default function QuoteAddButton(props) {
	return (
		<>
			{props.item.stock > 0 && (
				<Button
					className='ph-electronic-quote-button'
					onClick={() =>
						props.handleAddProductCartElectronic(
							props.theIndex,
							props.item,
							props.item.interface_quotation ? props.item.interface_quotation.quantity : 1,
							'quote',
							props.productSelected,
							props.cartForm
						)
					}>
					Agregar
				</Button>
			)}
			{(props.item.stock <= 0 || props.item.stock === undefined) && (
				<Button
					className='ph-electronic-alternative-button'
					onClick={() => props.handleAlternativeProduct(props.item)}>
					Alternativas
				</Button>
			)}
		</>
	)
}
