/** @format */

import React from 'react';

import { Row, Col } from 'antd';

import Images from '../../../../../../../../components/Commons/Images';

import { ENV_IMG } from '../../../../../../../../components/Commons/Hooks/Variables/Enviroment';

import './style.scss';

export default function RoadRoutes(props) {
	return (
		<div className="ph-withdrawal-pharmacy-routes-list-hours-selected-main-container">
			<Row>
				{!props.mobile && (
					<Col span={5} className="ph-withdrawal-pharmacy-routes-list-hours-selected-main-title-container">
						<h4 className="ph-withdrawal-pharmacy-routes-list-hours-selected-main-title">Recorrido:</h4>
					</Col>
				)}
				<Col xs={24} sm={19} md={19} lg={19} xl={19}>
					<Row>
						<Col xs={24} sm={16} md={16} lg={16} xl={16}>
							<Row>
								{props.mobile && (
									<Col xs={6} sm={24} md={24} lg={24} xl={24}>
										<h4 className="ph-withdrawal-pharmacy-routes-list-hours-selected-main-title">Recorrido:</h4>
									</Col>
								)}
								<Col xs={18} sm={24} md={24} lg={24} xl={24}>
									<div className="ph-withdrawal-pharmacy-routes-address-main-container">
										<div className="ph-withdrawal-pharmacy-routes-start-destiny-container">
											<span className="ph-withdrawal-pharmacy-routes-start-location-icon"></span>
											<span className="ph-withdrawal-pharmacy-routes-start-location-icon-title">{props.userLocation.address}</span>
										</div>
										<div className="ph-withdrawal-pharmacy-routes-road-location-icon" />
										<div className="ph-withdrawal-pharmacy-routes-road-location-icon" />
										<div className="ph-withdrawal-pharmacy-routes-road-location-icon" />
										<div className="ph-withdrawal-pharmacy-routes-destiny-container">
											<Images
												classContent={'ph-withdrawal-pharmacy-routes-destiny-image-container'}
												classImage={'ph-withdrawal-pharmacy-routes-destiny-image'}
												webp={`${ENV_IMG}/react-header/pharol-location.webp`}
												jp2={`${ENV_IMG}/react-header/pharol-location.jxr`}
												jxr={`${ENV_IMG}/react-header/pharol-location.jp2`}
												default={`${ENV_IMG}/react-header/pharol-location.png`}
											/>
											<h4 className="ph-withdrawal-pharmacy-routes-destiny-location-icon-title">
												{console.log('propsmodal', props)}
												{props?.item?.direction + ', ' + props.item.commune + ', ' + props.item.region}
												{/* {props.item.region} {props.item.direction} */}
											</h4>
										</div>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
}
