export const ENVIROMENT = {
	ROOT_PHARMACY: process.env.REACT_APP_ROOT_PHARMACY,
	ROOT_NAME: process.env.REACT_APP_ROOT_NAME,
	ROOT_RESERVE: process.env.REACT_APP_ROOT_RESERVE === 'true' ? true : false,
	ROOT_PLATFORM: process.env.REACT_APP_PLATFORM,
	COUNTRY: process.env.REACT_APP___COUNTRY,
	ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
	GOOGLE_ANALITYCS_4: process.env.REACT_APP_GOOGLE_ANALITYCS_4,
	GOOGLE_TAG: process.env.REACT_APP_GOOGLE_TAG_MANAGER,
	IMG_BUCKET: process.env.REACT_APP_GENERAL_STORAGE_IMAGES,
	FACEBOOK_PIXEL: process.env.REACT_APP_FACEBOOK_PIXEL,
	FUNCTION_FRONT_COMMON: process.env.REACT_APP_FUNCTION_FRONT_COMMON,
	FUNCTION_CUSTOM_STYLES: process.env.REACT_APP_FUNCTION_CUSTOM_STYLES,
	FUNCTION_SUCCESS_TRANSACTION: process.env.REACT_APP_FUNCTION_SUCCESS_TRANSACTION,
	FUNCTION_SITEMAP: process.env.REACT_APP_FUNCTION_SITEMAP,
	APP_ADDRESS: process.env.REACT_APP_ADDRESS,
	ENV_ELASTIC_INDEX: process.env.REACT_APP_ELASTIC_CL_APP_PRODUCTS,
	DOPPLER_ENVIRONMENT: process.env.DOPPLER_ENVIRONMENT,
	ELASTIC_CL_APP_PRODUCTS: process.env.REACT_APP_ELASTIC_CL_APP_PRODUCTS,
	ELASTIC_CL_APP_SEARCH_PRODUCTS: process.env.REACT_APP_ELASTIC_CL_APP_SEARCH_PRODUCTS,
	ELASTIC_CL_KEY: process.env.REACT_APP_ELASTIC_CL_KEY,
	ELASTIC_CL_PROJECT: process.env.REACT_APP_ELASTIC_CL_PROJECT,
	API_PHARMACY: process.env.REACT_APP_API_PHARMACY,
	TOKEN: process.env.REACT_APP_API_PHARMACY_TOKEN,
	FUNCTION_PANEL_TRACKING: process.env.REACT_APP_FUNCTION_PANEL_TRACKING,
	FUNCTION_EMAIL: process.env.REACT_APP_FUNCTION_EMAIL,
	FACEBOOK_DOMAIN_VERIFICATION: process.env.REACT_APP_FACEBOOK_DOMAIN_VERIFICATION,
};
