/** @format */

import React from 'react'

import './style.scss'

export default function ProductStock(props) {
	return (
		<>
			???????
			<div className='ph-product-detail-main-title-stock-container'>
				<h3 className='ph-product-detail-main-title-stock-title'>Stock:</h3>
				<h3 className='ph-product-detail-main-title-stock-description'>
					{!props.detail.on_demand && (
						<>
							{props.detail.stock > 3 && (
								<span className='ph-product-detail-main-title-stock-description-green'>
									Disponible
								</span>
							)}
							{props.detail.stock <= 3 && props.detail.stock > 0 && (
								<span className='ph-product-detail-main-title-stock-description-orange'>
									Pocas Unidades
								</span>
							)}
							{props.detail.stock === 0 && (
								<span className='ph-product-detail-main-title-stock-description-red'>
									No Disponible
								</span>
							)}
						</>
					)}
					{props.detail.on_demand && (
						<span className='ph-product-detail-main-title-stock-description-green'>
							Sola a pedido
						</span>
					)}
				</h3>
			</div>
		</>
	)
}
