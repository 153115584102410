/** @format */

import React from 'react'

import { Link } from 'react-router-dom'

import Layout from 'antd/lib/layout'

import Images from '../../../../components/Commons/Images'
import { ENV_IMG } from '../../../../components/Commons/Hooks/Variables/Enviroment'

import './style.scss'

const { Content } = Layout

export default function SuccesRecipeObjected(props) {
	return (
		<>
			<Content className='ph-objected-recipe-succes-global-container'>
				<div className='ph-objected-recipe-succes-main-container'>
					<section className='ph-objected-recipe-succes-inner-container'>
						<Images
							classContent={'ph-objected-recipe-succes-img-container'}
							classImage={'ph-objected-recipe-success-img'}
							webp={`${ENV_IMG}/react-checkout/master-recipe/succes.webp`}
							jp2={`${ENV_IMG}/react-checkout/master-recipe/succes.jxr`}
							jxr={`${ENV_IMG}/react-checkout/master-recipe/succes.jp2`}
							default={`${ENV_IMG}/react-checkout/master-recipe/succes.png`}
						/>
						<h1 className='ph-objected-recipe-success-title'>
							¡Has subido tu receta médica correctamente!
						</h1>
						<span className='ph-objected-recipe-success-subtitle'>
							Número de órden de compra:
							<strong className='ph-objected-recipe-success-subtitle-oc'>{props.trx_id}</strong>
						</span>
					</section>
					<div className='ph-objected-recipe-success-button-return-container'>
						<Link to='/' className='ph-objected-recipe-success-button-return'>
							<span className='ph-objected-recipe-success-button-return-span'>
								Volver al Inicio
							</span>
						</Link>
					</div>
				</div>
			</Content>
		</>
	)
}
