/** @format */

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Images from '../../components/Commons/Images';
import MetaDescription from '../../components/Commons/MetaDescription';
import { ENV_IMG } from '../../components//Commons/Hooks/Variables/Enviroment';
import CustomButton from '../../v2-components/Antd/Button';

import './style.scss';

export default function CovenantSuccess(props) {
	const { t } = useTranslation('global');
	const history = useHistory();

	const handleRedirect = () => {
		history.push('/');
	};

	return (
		<div className="ph-covenant-product-success-global-container">
			<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			<section className="ph-covenant-product-success-main-container">
				<div className="ph-covenant-product-success-inner-container">
					<Images
						classContent={'ph-covenant-product-succes-img-container'}
						classImage={'ph-covenant-product-succes-img'}
						webp={`${ENV_IMG}/react-notification/warning.webp`}
						jp2={`${ENV_IMG}/react-notification/warning.jp2`}
						jxr={`${ENV_IMG}/react-notification/warning.jxr`}
						default={`${ENV_IMG}/react-notification/warning.png`}
					/>
					<h1 className="ph-covenant-product-success-succes-title">¡Gracias por Preferirnos!</h1>
					<p className="ph-covenant-product-success-paraghaf">
						Solicitud: <strong className="ph-covenant-product-success-number">{props.match.params.id}</strong>
					</p>
					<h4 className="ph-covenant-product-success-description-span">
						<span className="ph-covenant-product-success-description-span-i">*</span>
						{t('checkout.transaction.error.description')}
						<a href={`mailto:${t('main.email')}`}>
							<span className="ph-covenant-product-success-description-contact">{t('main.email')}</span>
						</a>
					</h4>
					<div className="ph-covenant-product-success-payment-button-container">
						<CustomButton className={'button-primary'} onClick={() => handleRedirect}>
							Volver al Inicio
						</CustomButton>
					</div>
				</div>
			</section>
		</div>
	);
}
