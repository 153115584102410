/** @format */

import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn';

import { Row, Col, Checkbox } from 'antd';
import Images from '../../../../../../../../components/Commons/Images';
import { formatPrice } from '../../../../../../../../components/Commons/Hooks/Functions/FormatPrice';

import Dispatch from '../../../../components/DetailSimple/components/NewPharmacies/components/Dispatch';

import ProductSubTotal from './components/ProductSubTotal';

import ModalSubscribed from '../../../DetailSimple/components/ModalSubscribed';
import { ENVIROMENT } from '../../../../../../../../v2-config/constants/enviroment.constant';

import './style.scss';

import { serviceSendContract } from '../CovenantsWithoutRecipe/services';
import { ENV_COUNTRY } from '../../../../../../../../components/Commons/Hooks/Variables/Enviroment';

const PromotionComponent = (props) => {
	if (props.item.promotions?.rules.mecanic === 'N x N') {
		return (
			<div className="ph-product-detail-detailpharmacy-info-container">
				<div className="ph-product-detail-detailpharmacy-row">
					<div className="ph-product-detail-detailpharmacy-info-first">Beneficios</div>
					<div className="ph-product-detail-detailpharmacy-info-first promotion-mecanic">{props.item.interface.promotions.title}</div>
				</div>
			</div>
		);
	} else if (props.item.promotions?.rules.mecanic === 'X % Descuento') {
		return (
			<div className="ph-product-detail-detailpharmacy-info-container">
				<div className="ph-product-detail-detailpharmacy-row">
					<div className="ph-product-detail-detailpharmacy-info-first">Porcentaje de descuento</div>
					<div className="ph-product-detail-detailpharmacy-info-second promotion-percentaje">
						{props.item.interface.promotions.percentaje_promotion +
							`${ENV_COUNTRY === 'AR' && props.item.promotions.rules.mecanic === 'X % Descuento' ? ' OFF' : ''}`}
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className="ph-product-detail-detailpharmacy-info-container">
				<div className="ph-product-detail-detailpharmacy-row">
					<div className="ph-product-detail-detailpharmacy-info-first">Beneficios</div>
					{/* <div className="ph-product-detail-detailpharmacy-info-second">
						{formatPrice(+props.item.interface.promotions.price_promotion || +props.item.interface.promotions.price_promotion.amount)}
					</div> */}
					<div className="ph-product-detail-detailpharmacy-info-third">{props.item.promotions ? props.item.interface.promotions.title : '-'}</div>
				</div>
			</div>
		);
	}
};

export default function DeliveryAndPrice(props) {
	const [pharolPriceProduct] = useGlobal('pharolPriceProduct');
	const [isDataUser] = useGlobal('userDetailInfo');
	const [loading, setLoading] = useState(false);
	const [pharolPriceCheck, setPharolPriceCheck] = useState(false);
	const [modalPharolPrice, setModalPharolPrice] = useState({
		view: false,
		benefitToAdd: '',
		benefitToRemove: '',
		agreementsCompletedOrRequested: [],
	});

	useEffect(() => {
		if (pharolPriceProduct && pharolPriceProduct[0]?.status === 'completed') setPharolPriceCheck(true);
		return () => {};
	}, [pharolPriceProduct]);

	const handleSubscriptionPharolPrice = (pharmacy, nextStatus = pharolPriceCheck) => {
		serviceSendContract({
			agreement_campaign: pharolPriceProduct[0].name,
			nid: isDataUser.rut,
			product: {
				id_pharol: props.item.details[0].id_pharol,
				code_internal: props.item.details[0].code_internal,
				pharmacy,
			},
			status: nextStatus,
		});
	};

	const handleUnsuscribeAgreements = (item, agreementsList) => {
		setLoading(true);
		let i = 0;
		while (i < agreementsList.length) {
			if (!agreementsList[i].recipe_required) {
				serviceSendContract({
					agreement_campaign: agreementsList[i].name,
					nid: isDataUser.rut,
					product: {
						code_internal: props.item.details[0].code_internal,
						pharmacy: agreementsList[i].pharmacy,
					},
					status: false,
				});
			}
			i++;
		}
		setPharolPriceCheck(item);
		setModalPharolPrice({
			view: false,
			agreementsCompletedOrRequested: [],
		});
	};

	const handlePharolPriceCheck = ({ target: { checked } }) => {
		if (checked) {
			let n = 0;
			let allAgreementsCompletedOrRequested = [];
			while (n < props.isCovenant.length) {
				if (props.isCovenant[n].status === 'not-adjudicated' || props.isCovenant[n].status === 'requested') {
					allAgreementsCompletedOrRequested.push({
						...props.isCovenant[n],
						pharmacy: props.isCovenant[n].pharmacy,
					});
				}
				n++;
			}
			if (allAgreementsCompletedOrRequested.length > 0) {
				setModalPharolPrice({
					view: true,
					agreementsCompletedOrRequested: allAgreementsCompletedOrRequested,
				});
				return;
			}
		} else {
			handleSubscriptionPharolPrice(props.item.details[0].pharmacy, checked);
		}
		setPharolPriceCheck(checked);
		//handleSubscriptionPharolPrice(checked)
	};

	/*	const handleAgreementCollapse = (item) => {
		const content = agreementCollapse.current;
		if (content.style.maxHeight) {
			item.currentTarget.classList.remove('agreement-icon-active');
			content.style.maxHeight = null;
		} else {
			item.currentTarget.classList.add('agreement-icon-active');
			content.style.maxHeight = content.scrollHeight + 'px';
		}
	};
*/

	return (
		<>
			<div className="ph-product-detail-detailpharmacy-info-title">Entregado por</div>
			<div className="ph-product-detail-detailpharmacy-container">
				<div className="ph-product-detail-detailpharmacy-header">
					<div className="ph-product-detail-detailpharmacy-header-container">
						<div className="ph-product-detail-detailpharmacy-header-container-title-container">
							<Images
								classContent={'ph-product-detail-detailpharmacy-header-image-container'}
								classImage={'ph-product-detail-detailpharmacy-header-image'}
								default={props.item.details[0].pharmacy_image || 'https://dummyimage.com/300/09f/fff.png'}
								title={props.item.details[0].name_edited_pharmacy}
								alt={props.item.details[0].name_edited_pharmacy}
							/>
							<h3 className="ph-product-detail-detailpharmacy-header-pharmacy-title">{props?.item?.pharmacies[0]?.nearest_local?.name_local}</h3>
						</div>
					</div>
					<div
						className={`ph-product-detail-detailpharmacy-header-pharmacy-stock ${
							props.item.details[0].stock > 3
								? ENV_COUNTRY === 'AR'
									? 'benvida-green'
									: 'green'
								: props.item.details[0].stock <= 3 && props.item.details[0].stock > 0
								? 'orange'
								: 'gray'
						}`}
					>
						{props.item.details[0].stock > 3 && <>{`${ENVIROMENT.ROOT_RESERVE ? 'Por confirmar' : 'Stock disponible'}`}</>}
						{props.item.details[0].stock <= 3 && props.item.details[0].stock > 0 && <>{`${ENVIROMENT.ROOT_RESERVE ? 'Por confirmar' : 'Pocas Unidades'}`}</>}
						{props.item.details[0].stock <= 0 && <>{`${ENVIROMENT.ROOT_RESERVE ? 'Por confirmar' : 'Sin stock'}`}</>}
					</div>
				</div>

				<Row className="ph-product-detail-detailpharmacy-first-container" gutter={[30, 0]}>
					<Col lg={24} xs={24}>
						<div className="ph-product-detail-detailpharmacy-info-container">
							<div className="ph-product-detail-detailpharmacy-row">
								<div className="ph-product-detail-detailpharmacy-info-first">Retiro</div>
								<div className="ph-product-detail-detailpharmacy-info-second">
									{props.item.details[0].despatch_info?.some((i) => i.title === 'Retiro') ? (
										<p className="ph-product-detail-quote-price-normal">{ENV_COUNTRY === 'AR' ? 'Gratis' : formatPrice(0)}</p>
									) : (
										<span className="ph-product-detail-detailpharmacy-dispatch-unavailable">No disponible</span>
									)}
								</div>
							</div>
						</div>
					</Col>
					<Col lg={24} xs={24}>
						<div className="ph-product-detail-detailpharmacy-info-container">
							<div className="ph-product-detail-detailpharmacy-row">
								<div className="ph-product-detail-detailpharmacy-info-first">Envío</div>
								<div className="ph-product-detail-detailpharmacy-info-second">
									{(!!props.item.details[0].despatch_info && props.item.details[0].despatch_info[0].title !== 'Despacho') ||
									(ENV_COUNTRY === 'AR' && props.item.details[0].prescription_type !== 'NP') ? (
										<span className="ph-product-detail-detailpharmacy-dispatch-unavailable">No disponible</span>
									) : (
										<Dispatch pharmacies={props.item.pharmacies[0]} item={props.item.details[0]} />
									)}
								</div>
							</div>
						</div>
					</Col>
				</Row>
				{props.item.pharmacies.length && (
					<div className="ph-product-detail-detailpharmacy-info-delivery">
						{props.item.pharmacies[0].nearest_local?.distance_text} de distancia a tu domicilio
					</div>
				)}

				<Row className="ph-product-detail-detailpharmacy-second-container">
					<Col lg={24} xs={24}>
						<div className="ph-product-detail-detailpharmacy-info-container">
							<div className="ph-product-detail-detailpharmacy-row">
								<div className="ph-product-detail-detailpharmacy-info-first">Precio regular</div>
								<div
									className={`ph-product-detail-detailpharmacy-info-second ${
										props.item.details[0].interface?.promotions?.price_promotion || props.item.details[0].promotions
											? 'ph-product-detail-quote-price-before'
											: ''
									}`}
								>
									{formatPrice(props.item.details[0].price)}
								</div>
							</div>
						</div>

						<div className="ph-product-detail-detailpharmacy-promotion-container">
							<PromotionComponent item={props.item.details[0]} />
						</div>

						<div className="ph-product-detail-detailpharmacy-info-container">
							<div className="ph-product-detail-detailpharmacy-row">
								<div className="ph-product-detail-detailpharmacy-info-first-final-price">Precio final</div>
								<div className={`ph-product-detail-detailpharmacy-info-final-price`}>
									{formatPrice(props.item.details[0].promotions ? props.item.details[0].promotion_price : props.item.details[0].price)}
								</div>
							</div>
						</div>
					</Col>
					{!!pharolPriceProduct && pharolPriceProduct.length > 0 && (
						<>
							<Col lg={12} xs={24}>
								<div className="ph-product-detail-detailpharmacy-info-container pharol-price-extended">
									<div className="ph-product-detail-detailpharmacy-row pharol-price-first-row">
										<div className="ph-product-detail-detailpharmacy-info-first pharol-price-first">{pharolPriceProduct[0].editable_name}</div>
										<div className="ph-product-detail-detailpharmacy-info-second pharol-price-second">
											%{((pharolPriceProduct[0].discount_description.discount * 100) / props.item.details[0].price).toFixed(2)}
										</div>
										<div className="ph-product-detail-detailpharmacy-info-third">
											{formatPrice(props.item.details[0].price - pharolPriceProduct[0].discount_description.discount)}
										</div>
									</div>
									<div className="ph-product-detail-detailpharmacy-row">
										<div className="ph-product-detail-detailpharmacy-pharol-price-description">
											<div>*{pharolPriceProduct[0].discount_description.description.split('. ')[0]}.</div>
											<div>{pharolPriceProduct[0].discount_description.description.split('. ')[1]}</div>
										</div>
										<div className="ph-product-detail-detailpharmacy-request-checkbox-container">
											<div>
												<span className="ph-product-detail-detailpharmacy-request">Solicitar</span>
												<Checkbox checked={pharolPriceCheck} onChange={handlePharolPriceCheck} />
											</div>
										</div>
									</div>
								</div>
							</Col>
							<ModalSubscribed
								loading={loading}
								modalPharolPrice={modalPharolPrice}
								setModalPharolPrice={() => setModalPharolPrice((pvSt) => ({ ...pvSt, view: false }))}
								benefitToAdd={modalPharolPrice.benefitToAdd}
								benefitToRemove={modalPharolPrice.benefitToRemove}
								pharolPriceProduct={pharolPriceProduct}
								handleUnsuscribeAgreements={handleUnsuscribeAgreements}
							/>
						</>
					)}
				</Row>
				{/* {props.isCovenant && (
				<div className="ph-product-detail-detailpharmacy-info-container agreement-collapse" onClick={(e) => handleAgreementCollapse(e)}>
					<div className="ph-product-detail-detailpharmacy-info-first agreement-row">
						<div>{2 > 1 ? <span>Mostrar Beneficios</span> : <span>Mostrar Beneficio {props.isCovenant[0]?.editable_name}</span>}</div>
						<div className="ph-product-detail-detailpharmacy-agreement-icon">
							<DownOutlined style={{ fontSize: '12px' }} />
						</div>
					</div>
				</div>
			)} */}
				{/* <div className="ph-product-detail-detailpharmacy-agreement-container-collapse" ref={agreementCollapse}>
				{props.isCovenant?.map((agreement, i) => (
					<ProductCovenant
						key={i}
						product={props.item.details[0]}
						item={agreement}
						pharolPriceCheck={pharolPriceCheck}
						setPharolPriceCheck={setPharolPriceCheck}
						handleSubscriptionPharolPrice={handleSubscriptionPharolPrice}
						isOneMarked={props.isCovenant.findIndex((thisAgreement) => thisAgreement.status === 'completed' || thisAgreement.status === 'requested')}
					/>
				))}
			</div> */}
				<ProductSubTotal
					mobile={props.mobile}
					item={props.item.details[0]}
					pharolPriceCheck={pharolPriceCheck}
					handleSubscriptionPharolPrice={handleSubscriptionPharolPrice}
				/>
			</div>
		</>
	);
}
