/** @format */

import React from 'react'

import Card from '../../../search/components/Card'

import './style.scss'

export default function List(props) {
	return (
		<>
			{props.complaint.map((item, i) => (
				<Card key={i} {...item} />
			))}
		</>
	)
}
