/** @format */

import React, { Component } from 'react';
import { ENV_COUNTRY } from '../../../../components/Commons/Hooks/Variables/Enviroment';
import { Form, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

import MetaDescription from '../../../../components/Commons/MetaDescription';
import InputField from '../../../../components/Commons/Input';
import Images from '../../../../components/Commons/Images';
import MainTitlePageAlt from '../../../../components/Commons/MainTitlePageAlt';
import InputRut from '../../../../components/Commons/InputCountry/Rut';

import { default as trackingIcon } from '../../../../components/Commons/Assets/Icons/Filled/Enabled/tracking.svg';
import { default as trackingIconGreen } from '../../../../components/Commons/Assets/Icons/Filled/Enabled/Argentina/rastreo_6_2.png';

import SearchOrderService from '../../services';
import './style.scss';

class SearchOrder extends Component {
	state = { loading: false };

	handleSearchOrderInfo =  async  (item) => {
		 this.setState({ loading: true });
		await SearchOrderService(item).then((response) => {
			this.setState({ loading: false });
			if (response) {
				this.props.history.push(`/tracking/detail/${item.order}-${item.rut}`);
			}
		})
		.catch(err => {
			console.log("error handleSearchOrderInfo:",err)
		}); 
	};

	render() {
		const { t } = this.props;
		const isWindowDesktop = window.innerWidth >= 576

		return (
			<>
				<MetaDescription
					title={`Rastreo de Ordenes - ${t('main.metaTitle')}`}
					name={'description'}
					content={t('main.metaContent')}
				/>
				<div className="ph-search-tracking-global-container">
					<div className="ph-search-tracking-main-container">
						<MainTitlePageAlt title={'Rastreo y seguimiento'} image={ENV_COUNTRY === 'AR' ? trackingIconGreen : trackingIcon} descriptionPage={t('profile.backToAcountLink')} />
						<div className="ph-search-tracking-container">
							<Images classContent={'ph-search-tracking-image-container'} classImage={'ph-search-tracking-image'} default={trackingIcon} title={this.props.titleImage} />
							<h3 className="ph-search-tracking-title">{t('tracking.title')}</h3>
							<h4 className="ph-search-tracking-subtitle">{t('tracking.subTitle')}</h4>
							<Form name="search_tracking" onFinish={this.handleSearchOrderInfo}>
								<div className="ph-search-tracking-field-container">
									<InputRut 
									classInput={'ph-search-tracking-field-rut'} 

									/>
									<div className="ph-search-tracking-field-separator">
										<div className="ph-search-tracking-field-separator-inner"></div>
									</div>
									<InputField
										className={'ph-search-tracking-field-order'}
										inputName={'order'}
										inputNameLabel={'N° de orden'}
										inputNameRule={true}
										inputNameMessage={'Número de Orden Obligatorio'}
										inputNameType={'text'}
										inputNameIcon={''}
										inputNameRules={'rulesOrder'}
									/>
									<Button icon={isWindowDesktop && <SearchOutlined />} className={'ph-search-tracking-field-button'} htmlType='submit' loading={this.state.loading}>
										<h3 className="ph-search-tracking-field-button-title">BUSCAR</h3>
									</Button>
								</div>
							</Form>
							<p className="ph-search-tracking-description">
								<span className="ph-search-tracking-quote">*</span>
								Te permitirá ver el estatus actual y la localización de tu pedido para que te mantengas informado en todo momento.
							</p>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation('global')(SearchOrder);
