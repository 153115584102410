/** @format */

import React, { useEffect } from 'react';

import L from 'leaflet';
import markerIconUrl from 'leaflet/dist/images/marker-icon.png';
import markerShadowUrl from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import './style.scss';

export default function LeafletMap(props) {
  useEffect(() => {
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: markerIconUrl,
      iconUrl: markerIconUrl,
      shadowUrl: markerShadowUrl,
    });
  }, []);
	return (
		<>
			<MapContainer key={JSON.stringify(props.center)} center={props.center} zoom={props.zoom}>
				<TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
				<Marker position={props.center} >
					<Popup>
						{props.title} <br /> <h4 className='ph-map-marker-pharol'>{props.description}</h4>
					</Popup>
				</Marker>
			</MapContainer>
		</>
	)
}
