import { ENV_ELASTIC_INDEX } from '../../components/Commons//Hooks/Variables/Enviroment';
import { Enviroment } from '../../_config/constants/enviroment-v2.constant';
export const Country = {
	CL: {
		handleElasticPrecision: (item) => {
			console.log('handleElasticPrecision CL', ENV_ELASTIC_INDEX, item);
			return ENV_ELASTIC_INDEX;
		},
		handleFilters: (params) => {
			console.log('handleFilters CL', ENV_ELASTIC_INDEX, params);
			let filters = [];
			filters.push({
				field: 'pharmacy',
				values: [Enviroment.ROOT_PHARMACY],
			});
			filters.push({
				field: 'price',
				values: [0],
				type: 'none',
			});

			return filters;
		},
		handleSort: () => {
			let sort = [
				{
					validate_recipe: 'asc',
				},

				{
					stock: 'desc',
				},
			];
			return sort;
		},
	},
	AR: {
		handleElasticPrecision: (item) => {
			console.log('handleElasticPrecision AR', ENV_ELASTIC_INDEX, item);
			return ENV_ELASTIC_INDEX;
		},
		handleFilters: (params) => {
			console.log('handleFilters AR', ENV_ELASTIC_INDEX, params);
			let filters = [];
			filters.push({
				field: 'pharmacy',
				values: [params.pharmacy],
			});
			filters.push({
				field: 'stock',
				values: [0],
				type: 'none',
			});
			filters.push({
				field: 'price',
				values: [0],
				type: 'none',
			});
			return filters;
		},
		handleSort: () => {
			let sort = [
				{
					validate_recipe: 'asc',
				},
				{
					stock: 'desc',
				},
			];
			return sort;
		},
	},
};
