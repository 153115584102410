/** @format */

import { CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Layout, Menu, Spin, notification } from 'antd';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { default as LocationIcon } from '../../../../../../components/Commons/Assets/Icons/Filled/EnabledOpacity/location.svg';
import { GlobalConsumer } from '../../../../../../components/Commons/Context/Global';
import { AddressSetLocation } from '../../../../../../components/Commons/Hooks/Functions/AddressSetLocation';
import { PharmacySetLocation } from '../../../../../../components/Commons/Hooks/Functions/PharmacySetLocation';
import { ENV_ADDRESS, ENV_IMG_HEADER } from '../../../../../../components/Commons/Hooks/Variables/Enviroment';
import Images from '../../../../../../components/Commons/Images';
import InputField from '../../../../../../components/Commons/Input';
import Notify from '../../../../../../components/Commons/Notify';
import './style.scss';

export default function BagAddress(props) {
	const { isLocation, setModalAddress, setLocation } = GlobalConsumer();
	const location = useLocation();
	const history = useHistory();
	const [isLoadingButton, setLoadingButton] = useState(false);
	const [isAddressList, setAddressList] = useState([]);
	const [isUserAddressList] = useGlobal('userAddressListGlobal');
	const [form] = Form.useForm();

	const handleSetLocation = async (item) => {
		let address;
		setLoadingButton(true);
		setAddressList([]);
		await AddressSetLocation(item).then((response) => {
			address = response;
			PharmacySetLocation(address);
		});
		setLocation(address);
		if (location.pathname.match(/cart/)) {
			history.go(0);
		}
	};

	const handleDeleteLocation = () => {
		let key = 2;
		Notify({
			title: `¡Atención!`,
			colorTitle: 'ph-main-notification-error-title',
			description: `Si cambias esa dirección, puede que existan productos fuera de rango...
										Pulsa aceptar para confirmar:`,
			time: 0,
			image: 'error',
			placement: 'topRight',
			key,
			buttonFunction: () => {
				let address = ENV_ADDRESS.split(',');
				let lat = parseFloat(address[3]);
				let lng = parseFloat(address[4]);
				let location = {
					lat: lat,
					lng: lng,
					address: `${address[0]}, ${address[1]}, ${address[2]}`,
					street: address[0],
					commune: address[1],
					region: address[2],
					default: true,
				};
				localStorage.setItem('locationStore', JSON.stringify(location));
				setLocation(location);
				PharmacySetLocation(location);
				setModalAddress(true);
				notification.close(key);
			},
		});
	};

	return (
		<div className="ph-bag-address-main-container">
			<div className="ph-bag-address-description-container">
				{isLocation ? (
					<>
						{!isLocation.default ? (
							<div className="ph-bag-address-inner-container">
								<Images
									classContent="ph-bag-address-image-logo-container"
									classImage="ph-bag-address-image-logo"
									default={LocationIcon}
									title={'address logo'}
								/>
								<h3 className="ph-bag-address-step-title">
									{isLocation.street}, {isLocation.commune} - {isLocation.region}
								</h3>
								{props.isType !== 'on_demand' && (
									<Button className="ph-bag-address-step-button" onClick={() => handleDeleteLocation()}>
										<CloseCircleOutlined />
									</Button>
								)}
							</div>
						) : (
							<>
								<div className="ph-bag-address-form-main-container">
									<Form className="ph-bag-address-form-container" form={form}>
										<div className="ph-bag-address-form-inner-container" onClick={() => setModalAddress(true)}>
											<InputField
												className={`${
													isUserAddressList ? 'ph-bag-address-form-search-field-no-radius' : 'ph-bag-address-form-search-field-radius'
												} ph-bag-address-form-search-field`}
												inputName={'address'}
												inputNameLabel={'Ingresa calle y número...'}
												inputNameType={'text'}
												inputNameRule={true}
												inputNameMessage={'Ingresa tu correo......'}
												inputNameRules={'rulesAddress'}
											/>
										</div>
										<Button className="ph-bag-address-form-search-button" htmlType="submit" loading={isLoadingButton} onClick={() => setModalAddress(true)}>
											<h3 className="ph-bag-address-title-form-search-button">BUSCAR</h3>
										</Button>
									</Form>
								</div>
								<Layout className="ph-bag-address-form-search-list">
									{isAddressList && (
										<Menu>
											{isAddressList.map((address, index) => (
												<Menu.Item onClick={() => handleSetLocation(address.name)} className="ph-bag-address-form-search-list-description" key={index}>
													<Images
														classContent={`ph-bag-address-form-search-list-image-container`}
														classImage={`ph-bag-address-form-search-list-image`}
														webp={`${ENV_IMG_HEADER}/pharol-location-gris.webp`}
														jp2={`${ENV_IMG_HEADER}/pharol-location-gris.jp2`}
														jxr={`${ENV_IMG_HEADER}/pharol-location-gris.jxr`}
														default={`${ENV_IMG_HEADER}/pharol-location-gris.png`}
														title={'address logo gris'}
													/>
													{address.name}
												</Menu.Item>
											))}
										</Menu>
									)}
								</Layout>
							</>
						)}
						{props.isType === 'on_demand' && (
							<h3 className="ph-bag-address-on-demand-description">
								<span className="ph-bag-address-on-demand-asterisk">*</span>
								La dirección usada está vinculada a su cotización, no se puede cambiar.
							</h3>
						)}
					</>
				) : (
					<div className="ph-bag-address-loading-container">
						<Spin indicator={<LoadingOutlined className="ph-bag-address-image" />} className="ph-bag-address-icon" />
					</div>
				)}
			</div>
		</div>
	);
}
