/** @format */

import axios from 'axios';

import Notify from '../../../../components/Commons/Notify';

import { ENV_COUNTRY, ENV_CHECKOUT, ENV_ROOT_PHARMACY, ENV_CAMPAIGN } from '../../../../components/Commons/Hooks/Variables/Enviroment';

const servicesDetail = {
	async getOrder(item) {
		let returnResponse;
		var info = {
			age: item.user_info.age,
			email: item.user_info.email,
			first_name: item.user_info.first_name,
			last_name: item.user_info.last_name,
			location: item.user_info.location,
			pharmacy: item.pharmacies_selected,
			phone: item.user_info.phone,
			policies: item.user_info.policies,
			rut: item.user_info.rut,
			sex: item.user_info.sex,
			type_retirement: item.type_retirement,
			code_voucher: item.user_info.code_voucher,
		};
		if (ENV_COUNTRY === 'MX' && !!item.user_info.info_billing) {
			info['info_billing'] = item.user_info.info_billing;
		}
		if (ENV_COUNTRY === 'MX') {
			if (item.type_retirement === 'domicilio') info.info_delivery = { ...item.user_info.info_delivery };

			info.maps = item.user_info.maps;
			info.street = item.user_info.street;
			info.street_number = item.user_info.street_number;
			info.type_house = item.user_info.type_house;
			info.number_dpto_house = item.user_info.number_dpto_house;
			info.reference = item.user_info.reference;
			info.commune = item.user_info.comune;
			info.colony = item.user_info.colony;
			info.province = item.user_info.province;
			info.zip_code = item.user_info.zip_code;
			info.curp = item.user_info.curp;
			info.city = item.user_info.city;
			info.between_street = item.user_info.between_street !== '' ? item.user_info.between_street : undefined;
		}
		if ((item.type_retirement === 'domicilio' && ENV_COUNTRY === 'CL') || ENV_COUNTRY === 'CO') {
			info.maps = item.user_info.maps;
			info.street = item.user_info.street;
			info.street_number = item.user_info.street_number;
			info.type_house = item.user_info.type_house;
			info.number_dpto_house = item.user_info.number_dpto_house;
			info.reference = item.user_info.reference;
			info.commune = item.user_info.comune;
		}
		if (info.reference === '' || info.reference === null || info.reference === undefined) delete info.reference;
		localStorage.setItem('info_check', JSON.stringify(info));
		const data = {
			auth: localStorage.getItem('userEmail') ? true : false,
			info: info,
			products: item.products,
			total_services: item.total_services,
			total_delivery: item.total_delivery,
			total_discount_pbm: item.total_discount_pbm,
			total_discount: item.total_discount,
			total_voucher_discount: item.total_voucher_discount,
			total_voucher_despatch_discount: item.total_voucher_despatch_discount,
			total_voucher_products_discount: item.total_voucher_products_discount,
			sub_total: item.sub_total,
			total: item.total,
			payment_method_name_allowed: item.payment_method_name_allowed,
			payment: item.payment,
			referal: JSON.parse(localStorage.getItem('ePrescriptionToken')),
			country: ENV_COUNTRY,
			order_calculation: item.main_order_calculation,
			shipping_information: item.shipping_information,
			root_pharmacy: ENV_ROOT_PHARMACY,
		};
		await axios({
			method: 'POST',
			url: `${ENV_CHECKOUT}/checkout`,
			data: data,
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					if (response.data.data.valid_recipe) {
						returnResponse = response.data.data;
					} else {
						Notify({
							title: `Error en Receta!`,
							colorTitle: 'ph-main-notification-error-title',
							description: `¡Lo sentimos algo paso con su Receta, por favor vuelva a subirla!.`,
							time: 3,
							image: 'error',
							placement: 'topRight',
						});
						return (window.location.href = '/checkout/prescription');
					}
				} else if (response.data.statusCode === 400) {
					Notify({
						title: `${response.data.error} - ${response.data.statusCode}`,
						colorTitle: 'ph-main-notification-error-title',
						description: `${response.data.message}`,
						time: 3,
						image: 'error',
						placement: 'topRight',
					});
				} else {
					Notify({
						title: `Error!`,
						colorTitle: 'ph-main-notification-error-title',
						description: `${response.data.error}...`,
						time: 3,
						image: 'error',
						placement: 'topRight',
					});
				}
			})
			.catch(() => {
				Notify({
					title: `Problemas de Conexión`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Revisa tu conexión o ponte en contacto con nosotros.`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			});
		return returnResponse;
	},
	async checkOrder(item) {
		let returnResponse;
		await axios({
			method: 'GET',
			url: `${ENV_CHECKOUT}/checkout/${item}`,
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					returnResponse = response.data.data;
				} else {
					Notify({
						title: `Error!`,
						colorTitle: 'ph-main-notification-error-title',
						description: `${response.data.error}...`,
						time: 3,
						image: 'error',
						placement: 'topRight',
					});
				}
			})
			.catch(() => {
				Notify({
					title: `Problemas de Conexión`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Revisa tu conexión o ponte en contacto con nosotros.`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			});
		return returnResponse;
	},
	async userActiveCampaingService(item) {
		let returnResponse;
		await axios({
			method: 'POST',
			url: `${ENV_CAMPAIGN}/`,
			data: item,
		})
			.then((response) => {
				returnResponse = response;
			})
			.catch((error) => {
				console.log('error', error);
			});
		return returnResponse;
	},
};
export default servicesDetail;
