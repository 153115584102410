/** @format */

import React from 'react';

import { Row, Col, notification } from 'antd';

import { formatPrice } from '../../../FormatPrice';
import Images from '../../../../../Images';
import { ENV_IMG_ROUTE, ENV_COUNTRY } from '../../../../Variables/Enviroment';

import bagIcon from '../../../../../Assets/Icons/Filled/Enabled/bag.svg';

import './style.scss';

export default function CartInnerNotification(item) {
	const handleAmountItemsCart = () => {
		return JSON.parse(localStorage.getItem('cart')).reduce((sum, item) => sum + item.quantity, 0);
	};

	const handleTotalPriceCart = () => {
		return JSON.parse(localStorage.getItem('cart')).reduce((sum, item) => sum + item.price * item.quantity, 0);
	};

	notification.open({
		duration: 5,
		className: 'ph-cart-inner-notification-main-container',
		description: (
			<>
				<div className="ph-cart-inner-notification-main-title-container">
					<h1 className="ph-cart-inner-notification-main-title">¡Producto agregado a la Bolsa!</h1>
				</div>
				<Row className="ph-cart-inner-notification-description-main-container">
					<Col span={4} className="ph-cart-inner-notification-image-main-container">
						<Images
							classContent={'ph-cart-inner-notification-image-container'}
							classImage={'ph-cart-inner-notification-image'}
							webp={`${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${item.id_pharol}.webp`}
							default={`${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${item.id_pharol}.png`}
							alt={item.editable_name}
							title={item.editable_name}
						/>
					</Col>
					<Col span={20}>
						<div className="ph-cart-inner-notification-pharmacy">
							<Images
								classContent={'ph-cart-inner-notification-pharmacy-image-container'}
								classImage={'ph-cart-inner-notification-pharmacy-image'}
								default={item.pharmacy_image}
								alt={item.pharmacy}
								title={item.pharmacy}
							/>
							<p className="ph-cart-inner-notification-pharmacy-title">{item.name_edited_pharmacy}</p>
						</div>
						<h2 className="ph-cart-inner-notification-title">{item.name}</h2>
						<div className="ph-cart-inner-notification-quantity-container">
							<p className="ph-cart-inner-notification-quantity-title">Cantidad:</p>
							<p className="ph-cart-inner-notification-quantity-description">{item.quantity}</p>
						</div>
						<div className="ph-cart-inner-notification-price-container">
							<p className="ph-cart-inner-notification-price-title">Por unidad:</p>
							<p className="ph-cart-inner-notification-price-description">{formatPrice(item.unit_price)}</p>
						</div>
						{item.quantity > 1 && (
							<div className="ph-cart-inner-notification-price-container">
								<p className="ph-cart-inner-notification-price-title">Sub-total:</p>
								<p className="ph-cart-inner-notification-price-description">{formatPrice(item.sub_total)}</p>
							</div>
						)}
					</Col>
				</Row>
				<hr className="ph-cart-inner-notification-separator" />
				<Row>
					<Col span={12} className="ph-cart-inner-notification-total-container">
						<Images
							classContent={'ph-cart-inner-notification-total-image-container'}
							classImage={'ph-cart-inner-notification-total-image'}
							default={bagIcon}
							alt={'Bolsa de compras'}
							title={'Bolsa de compras'}
						/>
						<p className="ph-cart-inner-notification-total-title">Productos:</p>
						<p className="ph-cart-inner-notification-total-subtitle">({handleAmountItemsCart()})</p>
						<p className="ph-cart-inner-notification-total-description">{formatPrice(handleTotalPriceCart())}</p>
					</Col>
				</Row>
			</>
		),
		key: 1,
		placement: 'topRight',
	});
}
