import { Col, Form, Row } from 'antd';
import * as moment from 'moment';
import React, { useState } from 'react';
import CustomButton from '../../../../v2-components/Antd/Button';
import CustomCard from '../../../../v2-components/Antd/Card';
import CustomDescriptions from '../../../../v2-components/Antd/Descriptions';
import CustomFormItem from '../../../../v2-components/Antd/FormItem';
import CustomInput from '../../../../v2-components/Antd/Input';
import CustomLink from '../../../../v2-components/Antd/Link';
import { CustomNotification } from '../../../../v2-components/Antd/Notification';
import LoadingModal from '../../../../v2-components/LoadingModal';
import { VALIDATION } from '../../../../v2-config/constants/validations.constant';
import { CLAIMS_DICTIONARY } from './constants/claims.dictionary';
import { DICTIONARY } from './constants/dictionary.constant';
import { Service } from './service';
import './style.scss';

export default function ClaimsSearchPage() {
	const [form] = Form.useForm();
	const [claim, setClaim] = useState();
	const [openLoadingModal, setOpenLoadingModal] = useState(false);
	const [isLoadingModal, setIsLoadingModal] = useState(false);

	const handleFinish = (item) => {
		setOpenLoadingModal(true);
		setIsLoadingModal(true);
		new Service()
			.getClaimByClaimId(item.claim_id)
			.then((response) => {
				console.log('response', response);
				if (response.data.length === 0) {
					setIsLoadingModal(false);
					setOpenLoadingModal(false);
					form.resetFields();
					setClaim(null);
					return CustomNotification({ type: 'error', message: 'Sin resultados', description: 'No se encontraron resultados' });
				}
				setClaim(response.data[0]);
				setIsLoadingModal(false);
				setOpenLoadingModal(false);
				form.resetFields();
			})
			.catch((error) => {
				console.log(error);
			});
	};

	
	


	const items = [
		{
			key: 1,
			label: 'Fecha',
			children: moment.unix(claim?.created_at).format('DD/MM/YYYY hh:mm:ss A'),
		},
		{
			key: 2,
			label: 'Solicitud N°',
			children: claim?.claim_id,
		},

		{
			key: 3,
			label: 'Tipo de solicitud',
			children: CLAIMS_DICTIONARY[claim?.type],
		},

		{
			key: 4,
			label: 'Mensaje',
			children: claim?.message,
		},
		{
			key: 5,
			label: 'Respuesta',
			children: claim?.response || '--',
		},
		{
			key: 6,
			label: 'Estado',
			children: DICTIONARY[claim?.status],
		},
	];

	const searchResults = (claim) => {
		if (claim) {
			return (
				<CustomCard className={'card-primary'} title="Resultado de búsqueda">
					<CustomDescriptions title={'Información de la solicitud'} items={items} />
				</CustomCard>
			);
		}
		return null;
	};


	return (
		<>
			<Row justify="center" className="search-content">
				<Col md={18} xs={22}>
					<Row justify="left">
						<CustomLink className={'link-primary'} to="/claims">
							Volver
						</CustomLink>
					</Row>
					<br></br>
					<CustomCard className={'card-primary'} title="Buscar una solicitud">
						<Form form={form} layout="vertical" onFinish={handleFinish}>
							<CustomFormItem className={'form-primary'} label={'Número de reclamo'} name={'claim_id'} rules={VALIDATION.number}>
								<CustomInput className={'input-primary'} />
							</CustomFormItem>

							<CustomButton className={'button-primary'} htmlType="submit">
								Buscar
							</CustomButton>
						</Form>
					</CustomCard>
					<br></br>
					{searchResults(claim)}
					<LoadingModal loading={isLoadingModal} visible={openLoadingModal} />
				</Col>
			</Row>
		</>
	);
}
