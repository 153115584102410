/** @format */

import React, { Component } from 'react'

import './style.scss'

export default class CollapseTitle extends Component {
	render() {
		return (
			<>
				<div className='ph-order-detail-products-title-component-container'>
					<h3 className='ph-order-detail-products-component-title'>{this.props.title}</h3>
					<h3 className='ph-order-detail-products-component-description'>
						{this.props.description}
					</h3>
				</div>
			</>
		)
	}
}
