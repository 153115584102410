import axios from 'axios';
import { AxiosInterceptor } from './axios.interceptor';

export class AxiosAdapter {
	constructor(config) {
		this.config = config;
	}

	async request() {
		const response = await axios(this.config);
		const interceptor = await new AxiosInterceptor(response);
		return interceptor.pipe();
	}
}
