export const Country = {
	CL: {
		handleDisabledEmail: () => {
			const isLoged = localStorage.getItem('userEmail') !== ''
			return isLoged
		},
	},
	AR: {
		handleDisabledEmail: () => {
			return true
		},
	},
};
