/** @format */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row } from 'antd';

import Loading from '../../../../components/Commons/Loading';
import MainTitlePageAlt from '../../../../components/Commons/MainTitlePageAlt';
import Spacer from '../../../../components/Commons/Spacer';
import MetaDescription from '../../../../components/Commons/MetaDescription';

import MainTitleInfo from './components/MainTitleInfo';
import Profile from './components/Profile';
import Products from './components/Products';
import Pharmacies from './components/Pharmacies';
import Tracking from './components/Tracking';
import ModalResendEmail from './components/ModalResendEmail';
import { RepurchaseAddProductsCart } from './components/RepurchaseAddProductCart';
import CustomButton from '../../../../v2-components/Antd/Button';

import { default as trackingIcon } from '../../../../components/Commons/Assets/Icons/Filled/Enabled/tracking.svg';
import { default as userIcon } from '../../../../components/Commons/Assets/Icons/Filled/Enabled/user.svg';
import { default as homeIcon } from '../../../../components/Commons/Assets/Icons/Filled/Enabled/home.svg';
import { default as bagIcon } from '../../../../components/Commons/Assets/Icons/Filled/Enabled/bag.svg';
import { default as locationIcon } from '../../../../components/Commons/Assets/Icons/Filled/Enabled/location.svg';

import SearchOrderService from '../../services';

import './style.scss';

export default function SearchDetailInfo(props) {
	const { t } = useTranslation('global');

	const [isList, setList] = useState(null);
	const [isVisible, setVisible] = useState(false);
	const [isColumns, setColumns] = useState({
		profileMain: 6,
		profileInfo: 18,
		typeDelivery: 24,
	});

	const handleShowModalResendEmail = (item) => {
		setVisible(item);
	};

	const handleAddPurchaseProductsCart = (item) => {
		RepurchaseAddProductsCart(item, 1);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		let item = props.match.params.id_order.split('-');
		item = { order: item[0], rut: item[1] };
		SearchOrderService(item, props.history).then((response) => {
			if (response) {
				setList(response);
				if (response.type_delivery === 'domicilio') {
					setColumns({
						profileMain: 8,
						profileInfo: 16,
						typeDelivery: 16,
					});
				}
			} else {
				props.history.push(`/tracking`);
			}
		});
	}, [props.match.params.id_order, props.history]);

	if (isList) {
		return (
			<>
				<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />

				<div className="ph-order-detail-global-container">
					<div className="ph-order-detail-main-container">
						<div className="ph-order-detail-inner-container">
							<MainTitlePageAlt
								classDetailPageContainer={'ph-profile-main-box-title-detail-container'}
								classDetailPagetitle={'ph-profile-main-box-title-detail-title'}
								classDetailPageDescription={'ph-profile-main-box-title-detail-description'}
								title={'Información de la Orden'}
								descriptionOrderDetail={'Regresar'}
								image={trackingIcon}
							/>
							<Spacer />
							<CustomButton onClick={() => handleShowModalResendEmail(true)} className={'button-secondary'}>
								Reenviar Correo
							</CustomButton>
						</div>
						<Row>
							<Col xs={24} sm={24} md={24} lg={isColumns.profileInfo} xl={isColumns.profileInfo}>
								<MainTitleInfo title={'Datos Personales'} image={userIcon} />
							</Col>
							{isList.type_delivery === 'domicilio' && (
								<Col xs={24} sm={24} md={24} lg={isColumns.profileMain} xl={isColumns.profileMain} className="ph-order-detail-title-info-delivery">
									<MainTitleInfo title={'Información de Despacho'} image={homeIcon} />
								</Col>
							)}
						</Row>
						<Profile
							list={isList}
							columnsProfileMain={isColumns.profileMain}
							columnsProfileInfo={isColumns.profileInfo}
							columnsTypeDelivery={isColumns.typeDelivery}
						/>
						<div className="ph-order-detail-purchase-title-container">
							<MainTitleInfo title={'Detalle de mi compra'} image={bagIcon} />
							<CustomButton
								onClick={() => handleAddPurchaseProductsCart(isList.order[0].products)}
								className={'button-secondary ph-order-detail-repurchase-button'}
							>
								Volver a Comprar
							</CustomButton>
						</div>
						<Products list={isList.order[0]} />
						<MainTitleInfo title={'Dirección de Farmacias'} image={locationIcon} />
						<Pharmacies list={isList.order[0].info} />
						<MainTitleInfo title={'Rastreo de Paquetes'} image={trackingIcon} />
						<Tracking list={isList.tracking[0]} />
					</div>
				</div>
				<ModalResendEmail orderId={isList.order[0].trx_id} showModalResendEmail={handleShowModalResendEmail} visible={isVisible} />
			</>
		);
	} else {
		return <Loading />;
	}
}
