/** @format */

import React, { useState, useEffect } from 'react';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import MetaDescription from '../../components/Commons/MetaDescription';
import SliderBanner from '../../components/Commons/SliderBanner';
import Loading from '../../components/Commons/Loading';
import Elastic from '../../components/Elastic';
import TimelinePages from '../../components/TimelinePages';
import GetCategory from './services';
import CountryValidator from '../../components/Commons/CountryValidator';
import { HandleFacets } from '../../components/Elastic/functions/HandleFacets';
import { ENV_COUNTRY } from '../../components/Commons//Hooks/Variables/Enviroment';

import { Country } from './country';
import './style.scss';

export default function Category(props) {
	const { t } = useTranslation('global');
	const params = useLocation();
	const [isList, setList] = useState(null);
	const [isQuery] = useState(qs.parse(params.search.slice(1)));

	useEffect(() => {
		console.log('params', props.match.params);

		window.scrollTo(0, 0);
		GetCategory(props.match.params.category).then((response) => {
			if (response) {
				setTimeout(() => {
					setList(response);
				}, 600);
			}
		});
		if (isQuery.voucher) {
			localStorage.setItem('validVoucher', isQuery.voucher);
		}
	}, [props.match.params, isQuery.voucher]);

	const handleFilters = () => {
		const query = props.match.params.query ? props.match.params.query : '';
		const filters = Country[ENV_COUNTRY].handleFilters(props.match.params);
		const index = Country[ENV_COUNTRY].handleElasticPrecision(query);
		const sort = Country[ENV_COUNTRY].handleSort();
		const facets = HandleFacets('categories');
		const params = { filters, query, index, facets, sort };
		return params;
	};

	return (
		<>
			{isList ? (
				<>
					<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} robots={true} />
					<div className="ph-categories-global-container">
						<SliderBanner listSliders={isList.banners} />
						<CountryValidator
							countries={['CL']}
							component={
								<>
									<TimelinePages item={props.match.params} />
								</>
							}
						/>
					</div>
					<Elastic
						sidebarBanners={isList.side_banners}
						params={handleFilters()}
						category={props.match.params.category}
						subCategory={props.match.params.subCategory}
						showSearchBox={true}
						placeholcerSearchBox={'Buscar en esta categoria'}
					/>
				</>
			) : (
				<Loading />
			)}
		</>
	);
}
