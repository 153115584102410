/** @format */

import React from 'react';
import { ENV_COUNTRY } from '../../../../components/Commons/Hooks/Variables/Enviroment';
import { BagConsumer } from '../../../../components/Commons/Context/Bag';

import { Modal, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';

import Images from '../../../../components/Commons/Images';

import bagIcon from '../../../../components/Commons/Assets/Icons/Filled/Enabled/bag.svg';
import bagIconGreen from '../../../../components/Commons/Assets/Icons/Filled/Enabled/Argentina/bolsa1verde.svg';

import './style.scss';

export default function BagModalCalculate(props) {
	const { isBagCalculateModal } = BagConsumer();
	const { t } = useTranslation('global');

	return (
		<Modal
			wrapClassName="ph-bag-modal-calculate-modal"
			centered
			title="Bolsa"
			visible={!!props.loading ? props.loading : isBagCalculateModal}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			okText=""
			cancelText=""
			width={300}
			header={false}
			footer={false}
		>
			<Images classContent={'ph-bag-modal-calculate-image-container'} classImage={'ph-bag-modal-calculate-image'} default={ENV_COUNTRY === 'AR' ? bagIconGreen : bagIcon} />
			<h4 className="ph-bag-modal-calculate-title">{t('cart.modalTitle')}</h4>
			<h4 className="ph-bag-modal-calculate-subtitle">¡No cierres esta ventana!</h4>
			<Spin className="ph-bag-modal-calculate-spin" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
		</Modal>
	);
}
