/** @format */

export const FilterProductsRecipe = (item) => {
	const filterRecipe = (data) => {
		if (
			data.prescription_type === 'WP' ||
			data.prescription_type === 'RP' ||
			data.prescription_type === 'RCH' ||
			data.prescription_type === 'RRCS' ||
			data.prescription_type === 'APR' ||
			data.prescription_type === 'RMRSCS' ||
			data.prescription_type === 'RMRCCS' ||
			data.prescription_type === 'MRE'
		) {
			return true
		}
	}
	var filteredRecipe = item.filter(filterRecipe)
	return filteredRecipe
}
