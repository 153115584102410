import React from 'react';
import { Input } from 'antd';
import './style.scss';
export default function CustomInput(props) {
	return (
		<>
			<Input placeholder={props.placeholder || 'Escriba aquí'} {...props} />
		</>
	);
}
