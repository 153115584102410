/** @format */
import React from 'react'

import { Modal } from 'antd'

import Images from '../../../../../../components/Commons/Images'

import {
	ENV_IMG_ROUTE,
	ENV_COUNTRY,
} from '../../../../../../components/Commons/Hooks/Variables/Enviroment'

import './style.scss'

export default function ModalProductImage(props) {
	return (
		<>
			<Modal
				wrapClassName='ph-product-detail-image-modal-container'
				maskClosable={true}
				width='900px'
				centered
				visible={props.visible}
				onCancel={() => props.showImageModal(false)}
				okText='Confirmar'
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}>
				<Images
					classImage={'ph-product-detail-image-modal'}
					webp={`${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${props.item.id_pharol}.webp`}
					//jp2={`${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${props.item.id_pharol}.jp2`}
					//jxr={`${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${props.item.id_pharol}.jxr`}
					default={`${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${props.item.id_pharol}.png`}
					alt={props.title}
					title={props.title}
				/>
			</Modal>
		</>
	)
}
