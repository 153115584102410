import React from 'react';
import { Layout, Typography } from 'antd';
const { Content } = Layout;
const { Title, Paragraph } = Typography;

export default function TestPage() {
	return (
		<Layout>
			<Content style={{ padding: '0 50px', marginTop: '20px' }}>
				<Title level={2}>Términos de Uso </Title>
				<Paragraph>
					Estos términos y condiciones ("Términos", "Acuerdo") son un acuerdo entre el proveedor de servicios ("Proveedor de servicios", "nosotros", "nos" o
					"nuestro") y usted ("Usuario", "usted" o "su"). Este Acuerdo establece los términos y condiciones generales de su uso del sitio web [Nombre del sitio
					web] y cualquiera de sus productos o servicios (colectivamente, "Sitio web" o "Servicios").
				</Paragraph>
				<Title level={3}>Propiedad intelectual</Title>
				<Paragraph>
					El contenido del sitio web, que incluye, entre otros, textos, gráficos, logotipos, iconos, imágenes, clips de audio, descargas digitales,
					compilaciones de datos y software, es propiedad del Proveedor de servicios o de sus proveedores de contenido y está protegido por las leyes de
					derechos de autor y marcas comerciales.
				</Paragraph>
				<Title level={3}>Limitación de responsabilidad</Title>
				<Paragraph>
					Hasta el máximo grado permitido por la ley aplicable, en ningún caso el Proveedor de servicios, sus afiliados, directores, empleados o proveedores
					serán responsables por cualquier daño indirecto, punitivo, incidental, especial, consecuente o ejemplar, incluyendo sin limitación, daños por pérdida
					de beneficios, buena voluntad, uso, datos u otras pérdidas intangibles, que surjan de o estén relacionadas con el uso o la imposibilidad de usar el
					servicio.
				</Paragraph>
			</Content>
		</Layout>
	);
}
