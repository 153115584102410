/** @format */

import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalConsumer } from '../../../../../../components/Commons/Context/Global';
import { BagConsumer } from '../../../../../../components/Commons/Context/Bag';
import { ENV_COUNTRY } from '../../../../../../components/Commons/Hooks/Variables/Enviroment';
import { Row, Col, Modal, Button, Input, Select, Checkbox } from 'antd';
import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import Images from '../../../../../../components/Commons/Images';
import './style.scss';
import BagUploadRecipes from '../BagUploadRecipes';
import recipeIcon from '../../../../../../components/Commons/Assets/Icons/Filled/Enabled/recipe.svg';
import recipeIconAR from '../../../../../../components/Commons/Assets/Icons/Filled/EnabledOpacity/recipeAR.svg';
import { coverageList } from './coverageList';

export default function BagRecipesInfo() {
	const { t } = useTranslation('global');
	const { isBag, isMobile } = GlobalConsumer();
	const {
		isBagRecipe,
		setBagRecipe,
		isFirstStepError,
		setFirstStepError,
		setBagRecipeChecked,
		isBagRecipeModal,
		isBagRecipePreview,
		isBagRecipePreviewModal,
		isBagFilterProducts,
		setBagRecipeModal,
		setBagRecipePreview,
		setBagRecipePreviewModal,
		setBagFilterProducts,
	} = BagConsumer();

	const [isWarningModal, setWarningModal] = useState(false);

	const [customerCoverageType, setCustomerCoverageType] = useState('particular');

	const [customerCoverageName, setCustomerCoverageName] = useState('Osde');
	const [customerCoveragePlan, setCustomerCoveragePlan] = useState('');
	const [customerCoverageId, setCustomerCoverageId] = useState('');
	const [inputPlanError, setInputPlanError] = useState(false);
	const [inputIDeError, setInputIDError] = useState(false);
	const [isChecked, setIsChecked] = useState(false);

	const handleCheckboxChangeCL = (e) => {
		let customerCoverage = {};
		if (customerCoverageType === 'particular') {
			customerCoverage = {
				coverage_type: customerCoverageType,
			};
			// Guardamos el objeto en el almacenamiento local del navegador
			localStorage.setItem('customerCoverage', JSON.stringify(customerCoverage));
			setBagRecipeChecked(e.target.checked);
			setIsChecked(e.target.checked);
			setFirstStepError(false);
		} else {
			customerCoverage = {
				coverage_type: customerCoverageType,
			};
			// Guardamos el objeto en el almacenamiento local del navegador
			localStorage.setItem('customerCoverage', JSON.stringify(customerCoverage));
			setBagRecipeChecked(e.target.checked);
			setIsChecked(e.target.checked);
			setFirstStepError(false);
		}
	};

	const handleCheckboxChangeAR = (e) => {
		// Creamos el objeto con la info de la cobertura
		let customerCoverage = {};
		if (customerCoverageType === 'particular') {
			customerCoverage = {
				coverage_type: customerCoverageType,
			};
			// Guardamos el objeto en el almacenamiento local del navegador
			localStorage.setItem('customerCoverage', JSON.stringify(customerCoverage));
			setBagRecipeChecked(e.target.checked);
			setIsChecked(e.target.checked);
			setFirstStepError(false);
		} else {
			if (customerCoveragePlan !== '' && customerCoverageId !== '') {
				customerCoverage = {
					coverage_type: customerCoverageType,
					coverage_name: customerCoverageName,
					coverage_plan: customerCoveragePlan,
					coverage_code: customerCoverageId,
				};
				// Guardamos el objeto en el almacenamiento local del navegador
				localStorage.setItem('customerCoverage', JSON.stringify(customerCoverage));
				setBagRecipeChecked(e.target.checked);
				setIsChecked(e.target.checked);
				setFirstStepError(false);
			} else {
				setIsChecked(false);
				setInputPlanError(customerCoveragePlan === '');
				setInputIDError(customerCoverageId === '');
			}
		}
	};

	const cartRequireRecipe = useCallback(() => {
		let hasType = isBag.some(
			(product) =>
				product.prescryption_type === 'WP' ||
				product.prescription_type === 'RP' ||
				product.prescription_type === 'RCH' ||
				product.prescription_type === 'RRCS' ||
				product.prescription_type === 'APR' ||
				product.prescription_type === 'RMRSCS' ||
				product.prescription_type === 'RMRCCS' ||
				product.prescription_type === 'MRE',
		);
		return hasType;
	}, [isBag]);

	const handleBagRecipeModal = () => {
		if (!isBagRecipe) setWarningModal(true);
		else setBagRecipeModal(true);
	};

	const handleContinueRecipeModal = () => {
		setWarningModal(false);
		setBagRecipeModal(true);
	};

	const handleFilterProducts = (item) => {
		const filter = item.filter(
			(element) =>
				element.prescription_type === 'WP' ||
				element.prescription_type === 'RP' ||
				element.prescription_type === 'RCH' ||
				element.prescription_type === 'RRCS' ||
				element.prescription_type === 'APR' ||
				element.prescription_type === 'RMRSCS' ||
				element.prescription_type === 'RMRCCS' ||
				element.prescription_type === 'MRE',
		);
		return filter;
	};

	const cartHasType = (prescriptionType) => {
		let hasType = isBag.some((product) => product.prescryption_type === prescriptionType);
		return hasType;
	};

	const handlePreviewRecipe = (item) => {
		if (item.recipe) {
			setBagRecipePreviewModal(true);
			setBagRecipePreview(item);
		}
	};

	const handleImagePrescription = (item) => {
		if (item.interface?.prescryption_type) return item.interface.prescryption_type.image;
		else return item.pharmacy_image;
	};

	const handleTitlePrescription = (item) => {
		if (item.interface?.prescryption_type) return item.interface.prescryption_type.title;
		else return item.prescryption_type;
	};

	useEffect(() => {
		if (ENV_COUNTRY === 'CL') {
			handleCheckboxChangeCL({ target: { checked: true } });
		}
		if (ENV_COUNTRY === 'AR') {
			localStorage.setItem(
				'customerCoverage',
				JSON.stringify({
					coverage_type: customerCoverageType,
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let checkRecipe = cartRequireRecipe();
		console.log(checkRecipe);
		if (checkRecipe) {
			console.log('Requiere receta');
		} else {
			setBagRecipeChecked(true);
		}

		let cartVerifiedRequireRecipe = cartRequireRecipe();
		const filter = handleFilterProducts(isBag);
		if (filter.length > 0) setBagRecipe(filter.some((item) => !item.recipe));
		setBagFilterProducts(filter);

		if (ENV_COUNTRY === 'CL') {
			if (cartVerifiedRequireRecipe) {
				console.log('Verificación en CL receta');
			} else {
				setBagRecipe(false);
			}
		} else {
			console.log('Ejecutando recipe info');
			if (customerCoverageType === 'Obra Social') {
				console.log('coverage', customerCoverageType);
			} else {
				console.log('coverage', customerCoverageType);
				//setBagRecipe(false);
			}
		}
	}, [isBag, setBagRecipe, setBagFilterProducts, customerCoverageType, setBagRecipeChecked, cartRequireRecipe]);

	if (isBagFilterProducts?.length === 0) return <div className={`${isMobile ? 'ph-bag-recipes-empty-container' : ''}`}></div>;

	const handleChangeCoverageType = (coverageType) => {
		setCustomerCoverageType(coverageType);
	};

	const handleChangeCoverageName = (coverageName) => {
		console.log('coveragetype: ', coverageName);
		let customerCoverage = {};
		if (coverageName === 'particular') {
			handleChangeCoverageType('particular');
			localStorage.setItem(
				'customerCoverage',
				JSON.stringify({
					coverage_type: 'particular',
				}),
			);
			//	setBagRecipe(false);
		} else {
			handleChangeCoverageType('Obra Social');
			setBagRecipe(true);
			setCustomerCoverageName(coverageName);
			customerCoverage = {
				coverage_type: customerCoverageType,
				coverage_name: customerCoverageName,
				coverage_plan: customerCoveragePlan,
				coverage_code: customerCoverageId,
			};
			localStorage.setItem('customerCoverage', JSON.stringify(customerCoverage));
		}
	};

	const handleChangeCoveragePlan = (coveragePlan) => {
		setCustomerCoveragePlan(coveragePlan);
		setInputPlanError(false);
	};

	const handleChangeCoverageId = (coverageId) => {
		setCustomerCoverageId(coverageId);
		setInputIDError(false);
	};

	return (
		<>
			<div className="ph-bag-recipes-title-container">
				<div className="ph-bag-address-coverage-container-recipes-up">
					<span className="ph-bag-recipes-main-title-dot"></span>
					<span className="ph-bag-recipes-main-title">{t('cart.recipeMainTitle')}</span>
				</div>
				{ENV_COUNTRY === 'AR' && (
					<div className="ph-bag-address-coverage-container">
						<p className="ph-bag-recipes-coverage-title">Cobertura de salud</p>
					</div>
				)}
				{ENV_COUNTRY === 'AR' && customerCoverageType === 'particular' && (
					<div className="ph-bag-address-coverage-container">
						<Select
							key={'select-ar-1'}
							className="ph-bag-recipes-coverage-inputs"
							bordered={false}
							placeholder="Selecciona su Obra Social"
							defaultValue={customerCoverageType}
							style={{
								width: '100%',
							}}
							onChange={handleChangeCoverageName}
							showSearch
							filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
							options={coverageList}
						/>
					</div>
				)}

				{ENV_COUNTRY === 'AR' && customerCoverageType !== 'particular' && (
					<div className="ph-bag-address-coverage-container">
						<Select
							key={'select-ar-2'}
							className="ph-bag-recipes-coverage-inputs"
							bordered={false}
							placeholder="Selecciona su Obra Social"
							defaultValue={customerCoverageName}
							style={{
								width: '100%',
							}}
							onChange={handleChangeCoverageName}
							showSearch
							filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
							options={coverageList}
						/>
						<Input
							className={!inputPlanError ? 'ph-bag-recipes-coverage-inputs' : 'ph-bag-recipes-coverage-inputs-error'}
							placeholder="Ingrese su plan"
							value={customerCoveragePlan}
							onChange={(e) => handleChangeCoveragePlan(e.target.value)}
							onPressEnter={(e) => handleChangeCoveragePlan(e.target.value)}
						/>
						<Input
							className={!inputIDeError ? 'ph-bag-recipes-coverage-inputs' : 'ph-bag-recipes-coverage-inputs-error'}
							placeholder="N° de socio"
							value={customerCoverageId}
							onChange={(e) => handleChangeCoverageId(e.target.value)}
							onPressEnter={(e) => handleChangeCoverageId(e.target.value)}
						/>
					</div>
				)}
			</div>
			<div className="ph-bag-recipes-main-container">
				{/* Obra social debe cargar receta para poder avanzar y validar los checkbox */}
				{ENV_COUNTRY === 'AR' && customerCoverageType !== 'particular' && (
					<Row className='ph-bag-recipes-row-container'>
						<Col
							span={17}
							className={`${isMobile && isBagFilterProducts?.length > 3 ? 'ph-bag-recipes-more-inner-container' : ''} ph-bag-recipes-inner-container`}
						>
							<div>
								{isBagFilterProducts?.map((item, index) => (
									<div key={index}>
										<div
											className={`${
												isMobile && isBagFilterProducts?.length > 3 ? 'ph-bag-recipes-info-list-more-container' : ''
											} ph-bag-recipes-info-list-container`}
										>
											<Images
												classContent={'ph-bag-recipes-info-image-container'}
												classImage={'ph-bag-recipes-info-image'}
												default={handleImagePrescription(item)}
												title={handleTitlePrescription(item)}
											/>
											<h4
												onClick={() => handlePreviewRecipe(item)}
												className={`${item.recipe ? 'ph-bag-recipes-info-title-success' : ''} ph-bag-recipes-info-title`}
											>
												{handleTitlePrescription(item)}
											</h4>
										</div>
										{!isBagRecipe ? (
											<div className="ph-bag-address-coverage-container">
												<h3 className="ph-bag-recipes-info-upload-subtitle-success">Adjuntado correctamente</h3>
											</div>
										) : null}
									</div>
								))}
							</div>
						</Col>

						<Col span={7} className="ph-col-align-icons">
							<div className="ph-bag-recipes-info-upload-container">
								<div className="ph-bag-recipes-info-upload-inner-container" onClick={() => handleBagRecipeModal()}>
									<Images
										classContent={'ph-bag-recipes-info-upload-image-container'}
										classImage={'ph-bag-recipes-info-upload-image'}
										default={ENV_COUNTRY === 'CL' ? recipeIcon : recipeIconAR}
										title={'recipes'}
									/>
									{!isBagRecipe && <CheckOutlined className="ph-bag-recipes-info-upload-icon" />}
									{isBagRecipe ? <h3 className="ph-bag-recipes-info-upload-title">Adjuntar</h3> : null}
								</div>
								<EditOutlined onClick={() => handleContinueRecipeModal()} />
							</div>
						</Col>
					</Row>
				)}
				{/* Particular puede cargar la receta si lo desea, o marcar el checkbox y avanzar sin problema sin necesidad de cargar receta */}
				{ENV_COUNTRY === 'AR' && customerCoverageType === 'particular' && (
					<Row className='ph-bag-recipes-row-container'>
						<Col
							span={17}
							className={`${isMobile && isBagFilterProducts?.length > 3 ? 'ph-bag-recipes-more-inner-container' : ''} ph-bag-recipes-inner-container`}
						>
							<div>
								{isBagFilterProducts?.map((item, index) => (
									<div key={index}>
										<div
											className={`${
												isMobile && isBagFilterProducts?.length > 3 ? 'ph-bag-recipes-info-list-more-container' : ''
											} ph-bag-recipes-info-list-container`}
											key={index}
										>
											<Images
												classContent={'ph-bag-recipes-info-image-container'}
												classImage={'ph-bag-recipes-info-image'}
												default={handleImagePrescription(item)}
												title={handleTitlePrescription(item)}
											/>
											<h4
												onClick={() => handlePreviewRecipe(item)}
												className={`${item.recipe ? 'ph-bag-recipes-info-title-success' : ''} ph-bag-recipes-info-title`}
											>
												{handleTitlePrescription(item)}
											</h4>
										</div>
										{!isBagRecipe ? (
											<div className="ph-bag-address-coverage-container">
												<h3 className="ph-bag-recipes-info-upload-subtitle-success">Adjuntado correctamente</h3>
											</div>
										) : null}
									</div>
								))}
							</div>
						</Col>

						<Col span={7} className="ph-col-align-icons">
							<div className="ph-bag-recipes-info-upload-container">
								<div className="ph-bag-recipes-info-upload-inner-container" onClick={() => handleBagRecipeModal()}>
									<Images
										classContent={'ph-bag-recipes-info-upload-image-container'}
										classImage={'ph-bag-recipes-info-upload-image'}
										default={ENV_COUNTRY === 'CL' ? recipeIcon : recipeIconAR}
										title={'recipes'}
									/>
									{!isBagRecipe && <CheckOutlined className="ph-bag-recipes-info-upload-icon" />}
									{isBagRecipe ? <h3 className="ph-bag-recipes-info-upload-title">Adjuntar</h3> : null}
								</div>
								<EditOutlined onClick={() => handleContinueRecipeModal()} />
							</div>
						</Col>
					</Row>
				)}
				{ENV_COUNTRY === 'CL' && (
					<Row className='ph-bag-recipes-row-container'>
						<Col
							span={17}
							className={`${isMobile && isBagFilterProducts?.length > 3 ? 'ph-bag-recipes-more-inner-container' : ''} ph-bag-recipes-inner-container`}
						>
							<div>
								{isBagFilterProducts?.map((item, index) => (
									<div key={index}>
										<div
											className={`${
												isMobile && isBagFilterProducts?.length > 3 ? 'ph-bag-recipes-info-list-more-container' : ''
											} ph-bag-recipes-info-list-container`}
											key={index}
										>
											<Images
												classContent={'ph-bag-recipes-info-image-container'}
												classImage={'ph-bag-recipes-info-image'}
												default={handleImagePrescription(item)}
												title={handleTitlePrescription(item)}
											/>
											<h4
												onClick={() => handlePreviewRecipe(item)}
												className={`${item.recipe ? 'ph-bag-recipes-info-title-success' : ''} ph-bag-recipes-info-title`}
											>
												{handleTitlePrescription(item)}
											</h4>
										</div>
										{!isBagRecipe ? (
											<div className="ph-bag-address-coverage-container">
												<h3 className="ph-bag-recipes-info-upload-subtitle-success">Adjuntado correctamente</h3>
											</div>
										) : null}
									</div>
								))}
							</div>
						</Col>

						<Col span={2} className="ph-col-align-icons">
							<div className="ph-bag-recipes-info-upload-container">
								{console.log(!isBagRecipe)}
								{isBagRecipe ?
									<div className="ph-bag-recipes-info-upload-inner-container" onClick={() => handleBagRecipeModal()}>
										<Images
											classContent={'ph-bag-recipes-info-upload-image-container'}
											classImage={'ph-bag-recipes-info-upload-image'}
											default={ENV_COUNTRY === 'CL' ? recipeIcon : recipeIconAR}
											title={'recipes'}
										/>
										{/* {!isBagRecipe && <CheckOutlined className="ph-bag-recipes-info-upload-icon" />} */}
										<h3 className="ph-bag-recipes-info-upload-title">Adjuntar</h3>
									</div>
									:

									<EditOutlined onClick={() => handleContinueRecipeModal()} />
							}
							</div>
						</Col>
					</Row>
				)}
			</div>

			<Modal
				wrapClassName="ph-bag-recipes-modal-container"
				centered
				width={1000}
				maskClosable={true}
				visible={isBagRecipeModal}
				title="Sube tus Recetas Médicas"
				cancelText="Cancelar"
				destroyOnClose={true}
				onCancel={() => setBagRecipeModal(false)}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
			>
				<BagUploadRecipes filterproducts={isBagFilterProducts} />
			</Modal>
			<Modal
				key="modal-1"
				wrapClassName="ph-bag-recipes-modal-container"
				centered
				width={460}
				maskClosable={true}
				visible={isWarningModal}
				title="¡Advertencia!"
				cancelText="Cancelar"
				destroyOnClose={true}
				onCancel={() => setWarningModal(false)}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
			>
				<h3 className="ph-bag-recipes-modal-warning-title">
					Tus recetas fueron cargadas de forma exitosa. Si deseas volver a cargar alguna, selecciona <strong>"Ir a Recetas"</strong>. De lo contrario,
					selecciona <strong>"Continuar".</strong>
				</h3>
				<h4 className="ph-bag-recipes-modal-warning-subtitle">¿Desea continuar?</h4>
				<div className="ph-bag-recipes-modal-warning-button-container">
					<Button className="ph-bag-recipes-modal-warning-button-1" onClick={() => setWarningModal(false)}>
						Continuar
					</Button>
					<Button className="ph-bag-recipes-modal-warning-button-2" onClick={() => handleContinueRecipeModal()}>
						Ir a Recetas
					</Button>
				</div>
			</Modal>
			<Modal
				wrapClassName="ph-bag-recipes-modal-container"
				title="Vista Previa de la Receta"
				visible={isBagRecipePreviewModal}
				onOk={() => setBagRecipePreviewModal(false)}
				onCancel={() => setBagRecipePreviewModal(false)}
				okText="Confirmar"
				destroyOnClose={true}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
			>
				{isBagRecipePreview && (
					<>
						<Row className="ph-bag-recipes-modal-preview-product-title-container">
							<Col span={2}>
								<Images
									classContent={'ph-bag-recipes-modal-preview-product-image-container'}
									classImage={'ph-bag-recipes-modal-preview-product-image'}
									default={isBagRecipePreview.image}
									title={isBagRecipePreview.editable_name}
								/>
							</Col>
							<Col span={22}>
								<h3 className="ph-bag-recipes-modal-preview-product-title">{isBagRecipePreview.editable_name}</h3>
							</Col>
						</Row>

						<Images
							classContent={'ph-bag-recipes-modal-preview-image-container'}
							classImage={'ph-bag-recipes-modal-preview-image'}
							default={isBagRecipePreview.recipe}
							title={'recipe'}
						/>
					</>
				)}
			</Modal>
			{ENV_COUNTRY === 'AR' && cartRequireRecipe() && (
				<div className="ph-bag-address-coverage-container">
					<Checkbox onChange={handleCheckboxChangeAR} checked={isChecked} className="ph-check-recipe-cart">
						{cartHasType('RMRSCS') || cartHasType('RMRCCS')
							? 'Me comprometo a entregar la receta original y su duplicado al retirar el producto'
							: 'Me comprometo a entregar la(s) receta(s) al retirar los productos.'}
						{isFirstStepError && (
							<p style={{ color: 'red' }}>
								{customerCoverageType === 'particular'
									? 'Aceptar condiciones para continuar'
									: 'Aceptar condiciones para continuar ingresa tu plan y n° de socio'}
							</p>
						)}
					</Checkbox>
				</div>
			)}
			{ENV_COUNTRY === 'CL' && (
				<div className="ph-bag-address-coverage-container">
					<Checkbox onChange={handleCheckboxChangeCL} checked={true} className="ph-check-recipe-cart-cl"></Checkbox>
				</div>
			)}
			{ENV_COUNTRY === 'AR' && customerCoverageType === 'obraSocial' && cartRequireRecipe() ? (
				<div className="ph-bag-address-coverage-container">
					<p className="ph-check-recipe-cart">* La farmacia validara el descuento correspondiente a tu plan, cuando presentes tu prepaga/obra social.</p>
					<p className="ph-check-recipe-cart">* Leé atentamente el prospecto y ante la menor duda consultá a tu médico y/o farmacéutico.</p>
				</div>
			) : null}
		</>
	);
}
