/** @format */

import React, { Component } from 'react';

import Images from '../../../../../../../../components/Commons/Images';

import './style.scss';

export default class RetirementTracking extends Component {
	render() {
		return (
			<>
				<div className="ph-order-detail-tracking-retyrement-info-main-container">
					{this.props.item.delivery_type === 'domicilio' && (
						<>
							<Images
								classContent={'ph-order-detail-tracking-retyrement-info-image-container'}
								classImage={'ph-order-detail-tracking-retyrement-info-image'}
								default={`https://storage.googleapis.com/master-image-bucket/type-delivery/Icono%20Despacho.png`}
								title={'domicilio'}
							/>
							<h3 className="ph-order-detail-tracking-retyrement-info-title">Despachado a {this.props.item.delivery_type}</h3>
						</>
					)}
					{this.props.item.delivery_type === 'farmacia' && (
						<>
							<Images
								classContent={'ph-order-detail-tracking-retyrement-info-image-container'}
								classImage={'ph-order-detail-tracking-retyrement-info-image'}
								default={`https://storage.googleapis.com/master-image-bucket/type-delivery/Icono%20Retiro.png`}
								title={'farmacia'}
							/>
							<h3 className="ph-order-detail-tracking-retyrement-info-title">Retirado en {this.props.item.delivery_type}</h3>
						</>
					)}
				</div>
			</>
		);
	}
}
