export const CLAIMS_DICTIONARY = {
	'suggestion': 'Sugerencia',
	'congratulations': 'Felicitación',
	'claim-product not delivered': 'Reclamo - Producto no entregado',
	'claim-incorrect product': 'Reclamo - Producto erróneo',
	'claim-product about to expire or expired': 'Reclamo - Producto pronto a vencer o vencido',
	'claim-order not delivered': 'Reclamo - Pedido no entregado',
	'claim-order delivered late': 'Reclamo - Pedido entregado fuera de plazo',
	'claim-order withdrawal problems': 'Reclamo - Pedido con problemas de retiro',
	'claim-website issues': 'Reclamo - Problemas con la web',
	'claim-issues placing the order': 'Reclamo - Problemas para hacer el pedido',
	'claim-product out of stock': 'Reclamo - Producto sin stock',
	'claim-product without bioequivalent': 'Reclamo - Producto sin bioequivalente',
	'claim-shipping or product price': 'Reclamo - Precio de envio o producto',
	'claim-communication problems with the pharmacy': 'Reclamo - Problemas de comunicación con la farmacia',
	'claim-other': 'Reclamo - otro',
};
