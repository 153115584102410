/** @format */

import React, { useState, useEffect } from 'react'

import { Form, Select, Spin } from 'antd'

import { LoadingOutlined } from '@ant-design/icons'

import DeliveryScheduleFunction from '../../../../components/HooksCheckout/Functions/DeliverySchedule'

import './style.scss'
const { Option } = Select

const HomeDeliverySchedule = (props) => {
	const [form] = Form.useForm()
	//const [isUserEmail] = useState(localStorage.getItem('userEmail'))
	const [isUserToken] = useState(localStorage.getItem('userToken'))
	//const [isUserAddress] = useState(JSON.parse(localStorage.getItem('locationStore')))
	const [isDate, setDate] = useState(null)
	const [isTime, setTime] = useState(null)

	const [isDataDeliverySchedule] = useState({
		token_auth: !!isUserToken ? isUserToken : null,
		pharmacy:
			props.order.info.pharmacy.length > 1 ? 'pharol' : props.order.info.pharmacy[0].pharmacy,
		products: props.order.products,
		amount: props.order.shipping_information.price,
		local: props.order.locals ? props.order.locals[0].code_local : '0',
		points: props.order.shipping_information.points,
	})

	const handleDateSelected = (item) => {
		setTime(null)
		props.setInfoSelected((pvSt) => ({
			...pvSt,
			date: item,
		}))
		form.setFieldsValue({
			time_delivery: null,
		})
		isDate.time_availabilities.forEach((element) => {
			if (item === element.date) {
				setTime(element.timers)
			}
		})
	}

	useEffect(() => {
		async function DeliverySchedule() {
			await DeliveryScheduleFunction(isDataDeliverySchedule).then((response) => {
				setDate(response)
			})
		}
		DeliverySchedule()
	}, [isDataDeliverySchedule])

	if (!isDate) {
		return (
			<div className='ph-objected-recipe-loading-container'>
				<Spin
					indicator={<LoadingOutlined className='ph-objected-recipe-loading-image' />}
					className='ph-objected-recipe-loading-icon'
				/>
			</div>
		)
	} else {
		return (
			<Form form={form} className='ph-objected-recipe-input-global-container'>
				<Form.Item
					name='date_delivery'
					rules={[
						{
							required: true,
							message: 'Ingrese la fecha de entrega',
						},
					]}>
					<Select
						placeholder='Día y Fecha'
						className='ph-objected-recipe-delivery-selector'
						size='large'
						onChange={(value) => handleDateSelected(value)}>
						{isDate.time_availabilities.map((element, index) => (
							<Option value={element.date} key={index}>
								{element.date}
							</Option>
						))}
					</Select>
				</Form.Item>
				{isTime && (
					<Form.Item
						name='time_delivery'
						rules={[
							{
								required: true,
								message: 'Ingrese el horario de entrega',
							},
						]}>
						<Select
							placeholder='Horario'
							className='ph-objected-recipe-delivery-hour-selector'
							size='large'
							onChange={(item) =>
								props.setInfoSelected((pvSt) => ({
									...pvSt,
									time: item,
								}))
							}>
							{isTime.map((element, index) => (
								<Option value={`${element.start_time} - ${element.end_time}`} key={index}>
									{`${element.start_time} - ${element.end_time}`}
								</Option>
							))}
						</Select>
					</Form.Item>
				)}
			</Form>
		)
	}
}
export default HomeDeliverySchedule
