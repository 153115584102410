export const checkDelivery = {
	CL: {
		handle: (props) => {
			if (props.despatch_info.length <= 0) {
				return false;
			}
			if (props.despatch_info[0].title !== 'Despacho') {
				return false;
			}
			return true;
		},
	},
	AR: {
		handle: (props) => {
			if (props.despatch_info.length <= 0) {
				return false;
			}

			if (props.prescription_type !== 'NP') {
				return false;
			}

			if (props.despatch_info[0].title !== 'Despacho') {
				return false;
			}

			return true;
		},
	},
};
