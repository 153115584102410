import React, { useEffect, useState, Suspense, lazy } from 'react';
import { Row, Col } from 'antd';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { IsMobile } from '../../_config/utils/is-mobile.util';
import CustomMenu from '../../v2-components/Antd/Menu';
import CustomMenuItem from '../../v2-components/Antd/MenuItem';
import useWindowScreen from '../../v2-custom-hooks/useWindowsScreen.hook';
import CustomSelect from '../../v2-components/Antd/Select';
import CustomSkeleton from './components/Skeleton';
import { getItem } from './components/CardProduct/handle/get-item.handle';
import { getLocation } from './handle/get-location.handle';
import { Config } from './components/CardProduct/constant/config.constant';
import { paginationFilter } from './handle/pagination-filter.handle';
import { handleLengthMenu } from './handle/length-menu.handle';
import { handleActiveMenu } from './handle/active-menu.handle';
import { Service } from './service';
import { showPagination } from './handle/show-pagination.handle';
import './styles.scss';
const CardProductMobile = lazy(() => import('./components/CardProduct/CardProductMobile'));
const CardProductDesktop = lazy(() => import('./components/CardProduct/CardProductDesktop'));

export default function LandingPage() {
	const widthScreen = useWindowScreen();
	const history = useHistory();
	const { tag } = useParams();
	const location = useLocation();
	const [isLoading, setLoading] = useState(true);
	const [data, setData] = useState();
	const [types, setTypes] = useState();
	const [tagState, setTagState] = useState(tag);
	const [title, setTitle] = useState();
	const [paginationResponse, setPaginationResponse] = useState();
	const searchParams = new URLSearchParams(location?.search);
	const page = searchParams.get('page');
	const { lat, lng } = getLocation();

	useEffect(() => {
		new Service().getProducts(Config.type, lat, lng, tagState, page).then((response) => {
			setPaginationResponse(response[0]?.meta);
			setData(response[0]?.results);
			setTitle(response[0]?.editable_name);
			setLoading(false);
		});
		new Service().getTypes().then((response) => {
			setTypes(response);
		});
	}, [lat, lng, tagState, page]);

	const items = types?.map((iterator) => {
		return getItem(handleLengthMenu(iterator.editable_name), iterator.tag, iterator.tag);
	});
	const handleMenuClick = (e) => {
		if (e.key === tagState) return;
		setLoading(true);
		const findLabel = types?.find((tag) => tag.tag === e.key);
		setTitle(findLabel.editable_name);
		setTagState(e.key);
		history.push(`/offers/${e.key}`);
	};

	const handlePageChange = (page) => {
		searchParams.set('page', page);
		const newSearchParams = searchParams.toString();
		history.push({
			pathname: location?.pathname,
			search: newSearchParams,
		});
	};

	const handleOnClickProduct = (product) => {
		history.push(`/products/${product.slug}`);
	};
	const handleSelect = (e) => {
		setLoading(true);
		const findLabel = types?.find((tag) => tag.tag === e);
		setTitle(findLabel.editable_name);
		setTagState(e);
		history.push(`/offers/${e}`);
	};

	if (isLoading) {
		return <CustomSkeleton />;
	}

	if (data === undefined) {
		window.location.replace('/not-found');
	}

	return (
		<>
			{!IsMobile(widthScreen) && (
				<>
					<Row className="own-landing-web">
						<Row className="own-body">
							<Col span={24} className="own-title">
								<h2>{title}</h2>
							</Col>
							<Col span={24} className="own-filter">
								<Col span={5} className="own-see">
									<span>Ver:</span>
								</Col>
								<Col span={19}>{paginationFilter(paginationResponse?.current, paginationResponse?.items_page, paginationResponse?.total_items)}</Col>
							</Col>
							<Col span={24}>
								<Row>
									<Col span={5}>
										<CustomMenu className="own-landing-menu" key="menu" mode="vertical" defaultSelectedKeys={['1']} onClick={handleMenuClick}>
											{items?.map((item) => (
												<CustomMenuItem key={item.key} className={handleActiveMenu(item, tag)}>
													{handleLengthMenu(item.label)}
												</CustomMenuItem>
											))}
										</CustomMenu>
									</Col>
									<Col span={19}>
										<Row>
											{data?.map((product, key) => (
												<Col span={6} md={8} lg={6} xl={6}>
													<Suspense fallback={<CustomSkeleton />}>
														<CardProductDesktop key={`desktop-${key}`} product={product} onClickProduct={() => handleOnClickProduct(product)} />
													</Suspense>
												</Col>
											))}
										</Row>
										<Row className="own-pagination">{showPagination(CardProductDesktop, data, paginationResponse, handlePageChange)}</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</Row>
				</>
			)}

			{IsMobile(widthScreen) && (
				<>
					<Row className="own-landing-mobile">
						<Row className="own-title">
							<h2>{title}</h2>
						</Row>
						<Row className="own-filter">
							<CustomSelect className="own-select" bordered={false} options={items} onChange={handleSelect} placeholder={'Seleccionar oferta'} />
							<Col span={24}>{paginationFilter(paginationResponse?.current, paginationResponse?.items_page, paginationResponse?.total_items)}</Col>
						</Row>
						<Row className="own-card-product">
							<Col span={24}>
								{data?.map((product, key) => (
									<Suspense fallback={<CustomSkeleton />}>
										<CardProductMobile key={`mobile-${key}`} product={product} onClickProduct={() => handleOnClickProduct(product)} />
									</Suspense>
								))}
							</Col>
						</Row>
						<Row className="own-pagination">{showPagination(CardProductDesktop, data, paginationResponse, handlePageChange)}</Row>
					</Row>
				</>
			)}
		</>
	);
}
