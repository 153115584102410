/** @format */

import React from 'react'

import { Button, Form } from 'antd'

import './style.scss'

export default function BagRecipeButton(props) {
	return (
		<div className='ph-bag-recipe-button-main-container'>
			<Form.Item className='ph-bag-recipe-button-inner-container'>
				<div className='ph-bag-recipe-button-container'>
					<Button loading={props.loading} className='ph-bag-recipe-button-2' htmlType={'submit'}>
						CONFIRMAR
					</Button>
				</div>
			</Form.Item>
		</div>
	)
}
