/** @format */

import axios from 'axios'

import Notify from '../../../Notify'

import { ENV_COMMON, ENV_COUNTRY } from '../../Variables/Enviroment'

export const AddressSearch = async (item) => {
	console.log('AddressSearch', item)
	let returnResponse
	await axios({
		method: 'GET',
		url: `${ENV_COMMON}/location/place/${ENV_COUNTRY}`,
		params: {
			q: `${item}`,
		},
	})
		.then((response) => {
			console.log('response', response)
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data
			} else {
				Notify({
					title: `Error ${response.data.statusCode}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `${response.data.message}`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				})
			}
		})
		.catch((error) => {
			Notify({
				title: `Error`,
				colorTitle: 'ph-main-notification-error-title',
				description: 'Problemas con el servicio: Search Google places.',
				time: 3,
				image: 'error',
				placement: 'topRight',
			})
			returnResponse = error
		})
	return returnResponse
}
