/** @format */

import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';
import { useTranslation } from 'react-i18next';
import Loading from '../../../components/Commons/Loading';
import MetaDescription from '../../../components/Commons/MetaDescription';

import RenderMessaje from './components/RenderMessaje';

import confirmService from './services';

export default function Confirm(props) {
	const { t } = useTranslation('global');
	const [isDetail, setDetail] = useState(null);
	const [isToken, setToken] = useState(true);
	useEffect(() => {
		confirmService(props.match.params.token).then((response) => {
			if (response) {
				setDetail(response);
			} else {
				setToken(false);
			}
		});
	}, [props.match.params.token]);

	if (!isDetail) {
		if (isToken) {
			return <Loading />;
		} else {
			return (
				<>
					<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
					<RenderMessaje title={'¡Lo Sentimos!'} description={'Ha ocurrido un error, intenta volver a registrase o comunicate con nuestro equipo de soporte.'} button={'Volver al Inicio'} />
				</>
			);
		}
	} else {
		return (
			<>
				<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
				<RenderMessaje title={'¡Gracias por formar parte de nuestra familia!'} description={'Empieza a armar tu bolsa y disfruta la experiencia'} button={'Comienza tu Compra'} />
				<Redirect to='/auth/login' /> 
				{/* PARCHE FABO: REDIRECT */}
			</>
		);
	}
}
