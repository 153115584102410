/** @format */

import { CreditCardOutlined, LoadingOutlined } from '@ant-design/icons';
import { Col, Modal, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setGlobal, useGlobal } from 'reactn';
import Steper from '../../components/CheckoutTimeLine';
import { formatPrice } from '../../components/Commons/Hooks/Functions/FormatPrice';
import { PixelFacebook } from '../../components/Commons/Hooks/Functions/PixelFacebook';
import { ENV_ISO_CURRENCY } from '../../components/Commons/Hooks/Variables/Enviroment';
import Images from '../../components/Commons/Images';
import Loading from '../../components/Commons/Loading';
import MetaDescription from '../../components/Commons/MetaDescription';
import Spacer from '../../components/Commons/Spacer';
import Countdown from '../../components/Countdown';
import TitleBox from '../../components/TitleBox';
import CardOnDemand from '../../pages/checkout/components/CardOnDemand';
import PaymentDetail from '../../pages/checkout/components/PaymentDetail';
import PaymentDetailService from '../../pages/checkout/components/PaymentDetailService';
import StepperService from '../../pages/checkout/components/StepperService';
import CustomButton from '../../v2-components/Antd/Button';
import PaymentCard from './components/PaymentCard';
import { Service } from './services';
import { CustomNotification } from '../../v2-components/Antd/Notification';
import './style.scss';

export default function PaymentV2() {
	const { t } = useTranslation('global');
	const [isVoucherCode] = useState(localStorage.getItem('voucherCode'));
	const [isDiscountApplied, setDiscountApplied] = useGlobal('discountPaymentApplied');
	const [isDiscountName, setDiscountName] = useGlobal('discountPaymentName');
	const [isUserAddress] = useState(JSON.parse(localStorage.getItem('locationStore')));
	const [isDataPayment] = useState(JSON.parse(localStorage.getItem('paymentData')));
	const [isCurrentOrder, setCurrentOrder] = useGlobal('orderCheckoutPayment');
	const [isPaymentMethods, setPaymentMethods] = useState(null);
	const [isPaymentName, setPaymentName] = useState(null);
	const [isButtonPaymentStatus, setButtonPaymentStatus] = useState(true);
	const [isVisible, setVisible] = useState(false);
	const [isMobile, setMobile] = useState(false);
	const [isPaymentExcedent] = useGlobal('paymentExcedent');
	const [isService, setService] = useState(false);

	useEffect(() => {
		if (isDataPayment) {
			if (window.innerWidth < 992) {
				setMobile(true);
			}
			new Service()
				.checkOrder(isDataPayment)
				.then((response) => {
					if (response.valid_recipe === false) {
						CustomNotification({
							type: 'error',
							message: 'Error en Receta!',
							description: '¡Lo sentimos algo paso con su Receta, por favor vuelva a subirla!.',
							duration: 5,
						});
						return (window.location.href = '/checkout/prescription');
					}
					if (response) {
						if (response.order?.services?.length > 0) {
							setService(response.order?.services[0]);
						}
						setCurrentOrder(response.order);
						PixelFacebook('AddPaymentInfo', {
							currency: ENV_ISO_CURRENCY,
							value: parseInt(response.total).toFixed(2),
						});
					}
				})
				.catch((error) => {
					console.log('error', error);
					CustomNotification({ type: 'error', message: 'Error', description: 'Faltan datos para completar la transacción' });
					return (window.location.href = '/cart');
				});
		} else {
			window.location.href = '/';
		}
	}, [isDataPayment, isVoucherCode, setCurrentOrder]);

	useEffect(() => {
		new Service()
			.paymentList()
			.then((response) => {
				setPaymentMethods(response);
			})
			.catch((error) => {
				console.log('error', error);
				CustomNotification({ type: 'error', message: 'Error', description: 'Hubo un error obteniendo los medios de pago' });
			});
	}, []);

	const handleSelectPayment = (item) => {
		setButtonPaymentStatus(true);
		setPaymentName(item);
		if (!isPaymentExcedent) {
			new Service()
				.checkPaymentDisscount(isDataPayment, item)
				.then((response) => {
					if (response) {
						if (response.payment_method_rules && response.payment_method_rules.discount !== 0) {
							setDiscountApplied(true);
							setDiscountName(response.payment_method_name);
						} else {
							setDiscountApplied(false);
							setDiscountName(null);
						}
						setCurrentOrder(response);
					}
				})
				.catch((error) => {
					console.log('error', error);
					CustomNotification({ type: 'error', message: 'Error', description: 'Ocurrió un error al calcular los descuentos' });
				});
		}
		setButtonPaymentStatus(false);
	};

	const handlePayment = async (item, data) => {
		if (!isVisible) {
			setVisible(true);
		}
		if (!isButtonPaymentStatus) {
			setButtonPaymentStatus(true);
		}

		let paymentMethodName = isPaymentName;
		let orderTotal = isCurrentOrder.total;

		if (item) {
			if (item.cover_payment) {
				paymentMethodName = data;
			}
			orderTotal = parseFloat(orderTotal) - parseFloat(item.amountAuthorized);
		}

		new Service()
			.payment(parseFloat(orderTotal), isDataPayment, paymentMethodName)
			.then((response) => {
				setVisible(false);
				setPaymentName(null);
				setButtonPaymentStatus(true);
				setGlobal(() => ({
					paymentExcedent: null,
					paymentRequestExcedent: null,
				}));
				window.location.href = response.external_data.url;
			})
			.catch(() => {
				CustomNotification({ type: 'error', message: 'Error!', description: 'Ocurrió un Error, por favor vuelva a intentarlo', duration: 10 });
			});
	};

	const handlePaytmentExedent = () => {
		return formatPrice(isCurrentOrder.total);
	};

	if (!isCurrentOrder || !isPaymentMethods) {
		return <Loading />;
	} else {
		return (
			<>
				<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
				<div className="ph-payment-global-container">
					<div className="ph-payment-main-container">
						{isService ? <StepperService step={1} /> : <Steper step={2} />}

						<div className="ph-payment-titlebox-countdown-container">
							<TitleBox image={'pharol-lock'} title={'Pago'} checkout={true} />
							<Countdown service={isService} />
						</div>
						<Row>
							{isPaymentMethods && (
								<Col xs={24} sm={24} md={24} lg={17} xl={17}>
									<div className="ph-payment-gateways-container">
										<>
											{isPaymentMethods.length > 0 && (
												<>
													<div className="ph-payment-method-title-container">
														<CreditCardOutlined className="ph-payment-method-icon" />
														<h4 className="ph-payment-method-title">Pago con tarjeta de crédito/débito</h4>
													</div>
													<Row>
														{isPaymentMethods.map((item, index) => (
															<div key={index}>
																<PaymentCard
																	isPaymentExcedent={isPaymentExcedent}
																	isDiscountName={isDiscountName}
																	active={item.active}
																	available={item.active}
																	selectPayment={(item) => handleSelectPayment(item)}
																	paymentMethod={item.payment_option_id}
																	paymentSelected={isPaymentName}
																	name={item.display_name}
																	image={item.image}
																	type={item.type}
																/>
															</div>
														))}
													</Row>
												</>
											)}
										</>
									</div>
								</Col>
							)}
							<Col xs={24} sm={24} md={24} lg={7} xl={7} className="ph-payment-box-global-container">
								{isDiscountApplied && (
									<div className="ph-payment-box-discount-main-container">
										<Images
											classContent={'ph-payment-box-discount-image-container'}
											classImage={'ph-payment-box-discount-image'}
											default={`https://storage.googleapis.com/master-image-bucket/home/Tipos%20de%20entrega.png`}
											title={'Discount'}
										/>
										<div className="ph-payment-box-discount-container">
											<h4 className="ph-payment-box-discount-title">¡Descuento en Despacho!</h4>
											<h4 className="ph-payment-box-discount-description">
												<span className="ph-payment-box-discount-subtitle">Descuento Adicional</span> en método de pago seleccionado...
											</h4>
										</div>
									</div>
								)}
								{isCurrentOrder.type_calculation !== 'service' && <CardOnDemand />}
								<div className="ph-payment-box-main-container">
									{isService ? (
										<PaymentDetailService item={isCurrentOrder} />
									) : (
										<PaymentDetail
											isDiscountApplied={isDiscountApplied}
											address={isUserAddress}
											paymentRetirement={isCurrentOrder.info.type_retirement}
											item={isCurrentOrder}
										/>
									)}
									<div className="ph-checkout-payment-button-main-container">
										{isMobile && (
											<>
												<div>
													<h3 className="ph-checkout-detail-button-mobile-price-main-title">Subtotal:</h3>
													<h3 className="ph-checkout-detail-button-mobile-price-title">
														<span className="ph-checkout-detail-button-mobile-price-description">$</span>
														{handlePaytmentExedent()}
													</h3>
												</div>
												<Spacer />
											</>
										)}
										<CustomButton className={'button-primary'} onClick={() => handlePayment()} disabled={isButtonPaymentStatus}>
											PAGAR
										</CustomButton>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</div>
				<Modal
					wrapClassName="ph-checkout-pay-payment-modal"
					centered
					title="Proceso de pago"
					visible={isVisible}
					cancelButtonProps={{ style: { display: 'none' } }}
					okButtonProps={{ style: { display: 'none' } }}
					okText=""
					cancelText=""
					width={350}
					header={false}
					footer={false}
				>
					<h4 className="ph-checkout-pay-payment-modal-title">Estas a punto de finalizar tu compra...</h4>
					<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
				</Modal>
			</>
		);
	}
}
