/** @format */

import React from 'react';
import { Card } from 'antd';
import Spacer from '../../../Spacer';
import { PromotionProductAdapter } from '../../../../../_config/adapters/google-tag-manager/promotion-product.adapter';
import { Events } from '../../../../../_config/adapters/google-tag-manager/events.constant';

import Image from '../../components/Image';
import Info from '../../components/Info';
import Promotions from '../../components/Promotions';
import Quote from '../../components/Quote';
import Stock from '../../components/Stock';
import Price from '../../components/Price';
import Button from '../../components/Button';
import './style.scss';
import { useEffect } from 'react';

export default function ProductRectagle(props) {
	useEffect(() => {
		PromotionProductAdapter({ event: Events.view_promotion, product: props.detail });
	}, [props]);
	console.log('props rectangle',props)
	return (
		<Card className="ph-product-container">
			<Image detail={props.detail} />
			<Info detail={props.detail} />

			{props.detail.pharmacy && (
				<>
					<div className="ph-product-promotion-container">
						<Stock detail={props.detail} />
					</div>
					<Promotions detail={props.detail} />
				</>
			)}
			{!props.detail.pharmacy && (
				<>
					<Quote available={props.detail.available} />
				</>
			)}
			<Spacer />
			{!props.detail.on_demand && (
				<>
					<Price detail={props.detail} />
				</>
			)}
	{console.log('detail porduct',props.detail)} 
			<Button available={props.detail.available} detail={props.detail} />
		</Card>
	);
}
