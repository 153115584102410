/** @format */

import { Collapse, Drawer, Row } from 'antd';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { setGlobal } from 'reactn';
import { GlobalConsumer } from '../../../../../../Context/Global';
import { IMAGES } from '../../../../../../../../v2-config/constants/images.constant';
import locationEnabled from '../../../../../../Assets/Icons/Filled/Enabled/location.svg';
import menuDisabled from '../../../../../../Assets/Icons/Lineals/Disabled/menu.svg';
import { DownOutlined, HomeOutlined, PhoneOutlined, MenuOutlined, RightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { ReactComponent as UserOutlined } from '../../../../../../Assets/Icons/Lineals/user-outlined.svg';
import Images from '../../../../../../Images';
import CustomDrawer from '../../../../../../../../v2-components/Antd/Drawer';

import './style.scss';

const SideBar = ({ navBar, status }) => {
	const { setModalAddress } = GlobalConsumer()
	const [isSidebar, setSidebar] = useState(false);
	const [isEmail] = useState(localStorage.getItem('userEmail'));
	const [isUser] = useState(JSON.parse(localStorage.getItem('userMainInfo')));
	const [isUserAddress] = useState(JSON.parse(localStorage.getItem('locationStore')));
	const history = useHistory()
	const [open, setOpen] = useState(false);
	const [openSubDrawer, setOpenSubDrawer] = useState(false)
	const [category, setCategory] = useState([])
	const [subCategory, setSubCategory] = useState([])

	const handleModalAddress = () => {
		setSidebar(false);
		setGlobal({ modalAddress: true });
		setModalAddress(true)
	};

	const handleSetSidebar = (item) => {
		setSidebar(item);
	};

	const showDrawer = () => {
		setOpen(true);
	  };
	  const onClose = () => {
		setSidebar(false);
		setOpen(false);
	  };

	  const backToSidebar = () =>{
		onClose()
		setSidebar(true)
	  }

	  const onCloseSubDrawer = () => {
		setSidebar(false);
		setOpen(false);
		setOpenSubDrawer(false)
	  }

	  const backToCategoriesDrawer = () =>{
		onCloseSubDrawer()
		setOpen(true)
	  }

	  const navigateToCategory =  async (category, subCategory) => {
		await history.push('/');
		await history.push(`/categories/${category && category.slug}${subCategory ? `/${subCategory.slug}` : ''}`);
		onCloseSubDrawer()
		onClose()
		setSidebar(false);
	  };

	  const openSubCategories = (category) =>{
		setCategory(category)
		setSubCategory(category.children)
		setOpenSubDrawer(true)
	  }

	return (
		<>
			<div className="ph-sidebar-global-container">
				<div className="ph-sidebar-button" onClick={() => handleSetSidebar(true)}>
					<Images classContent={'ph-sidebar-actions-icon-container'} classImage={'ph-sidebar-actions-icon'} default={menuDisabled} title={'menu'} />
				</div>
				<Drawer
					width={300}
					zIndex={100}
					className="ph-sidebar-main-container"
					placement="left"
					closable={false}
					onClose={() => setSidebar(false)}
					visible={isSidebar}
					key="left"
				>
					<div>
						<div className="ph-sidebar-header-main-container">
							<div className="ph-sidebar-collapse-container">
								<div>
									<p className="ph-sidebar-collapse-title">{isEmail ? '¡Hola!' : '¡Te damos la bienvenida!'}</p>
									<p className="ph-sidebar-collapse-subtitle">{isEmail && `${isUser.first_name} ${isUser.last_name}`}</p>
									{!isEmail && (
										<>
											<Link to="/auth/login" className="ph-sidebar-collapse-item-title" onClick={() => setSidebar(false)}>
												Inicia Sesión
											</Link>
										</>
									)}
								</div>
							</div>
							<div className="ph-sidebar-collapse-image-logo-main-container">
								<img src={IMAGES.LOGO_DARK_BACKGROUND} alt="logo" title="logo" style={{ height: '30px' }} />
							</div>
						</div>
						<Collapse
							className="ph-sidebar-collapse-global-container"
							expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
							accordion="true"
							expandIconPosition="right"
							//defaultActiveKey={['5']}
						>
							<div onClick={() => setSidebar(false)}>
								<Link to="/">
									<div className="ph-sidebar-collapse-item-container-2">
										<HomeOutlined className="ph-sidebar-collapse-item-icon-2" />
										Home
									</div>
								</Link>
							</div>
							<div onClick={() => showDrawer()}>
									<div className="ph-sidebar-collapse-item-container-2">
									<MenuOutlined className="ph-sidebar-collapse-item-icon-2" />
										Categorías
									</div>
							</div>
							{isEmail && (
								<>
									<Link to="/profile-info" onClick={() => setSidebar(false)}>
										<div className="ph-sidebar-collapse-item-container-2">
											<UserOutlined className="ph-sidebar-collapse-item-icon-2" />
											Mi Perfil
										</div>
									</Link>
								</>
							)}

							<Link to="/contact">
								<div className="ph-sidebar-collapse-item-container-2" onClick={() => setSidebar(false)}>
									<PhoneOutlined className="ph-sidebar-collapse-item-icon-2" />
									Contacto
								</div>
							</Link>
						</Collapse>
					</div>
					<div className="ph-sidebar-address-container" onClick={() => handleModalAddress()}>
						<Images
							classContent={'ph-sidebar-address-image-logo-container'}
							classImage={'ph-sidebar-address-image-logo'}
							default={locationEnabled}
							title={'sidebar-address'}
						/>
						<div className="ph-address-description-container">
							{isUserAddress && (
								<>
									<p className="ph-sidebar-address-description-1">{isUserAddress.street}</p>
									<p className="ph-sidebar-address-description-2">
										{isUserAddress.commune}, {isUserAddress.region}
									</p>
								</>
							)}
						</div>
					</div>
				</Drawer>
				<CustomDrawer
				title={<div className='ph-sidebar-mobile-title-drawer-container'>
					<ArrowLeftOutlined onClick={backToSidebar}/>
					<h2 className='ph-sidebar-drawer-title'>Categorías</h2>
				</div>
			}
				placement="left"
				onClose={onClose}
        		visible={open}
				width={"100%"}
				bodyStyle={{
					padding:'8px'
				}}
				headerStyle={{
					display: 'flex',
					padding: '16px',
					alignItems: 'center',
					gap: '16px',
					alignSelf: 'stretch',
				}}
			>
			 	{navBar && navBar.menu_marketplace.find(obj => obj.id === 'categories').children.map(category => 
					(<Row 
						key={category.id}
						className='ph-mobile-drawer-container-items'
						onClick={() => openSubCategories(category)}
					>
						<p className='ph-mobile-drawer-text-items'
						>
							{category.name}</p>
						<RightOutlined />
					</Row>)
				)} 

			</CustomDrawer>
			<CustomDrawer
					title={
						<div className='ph-sidebar-mobile-title-drawer-container'>
							<ArrowLeftOutlined onClick={backToCategoriesDrawer}/>
							<h2 className='ph-sidebar-drawer-title'>{category.name}</h2>
						</div>
					}
        			visible={openSubDrawer}
					placement="left"
					onClose={onCloseSubDrawer}
					width={"100%"}
					bodyStyle={{
						padding:'8px'
					}}
					headerStyle={{
						display: 'flex',
						padding: '16px',
						alignItems: 'center',
						gap: '16px',
						alignSelf: 'stretch',
						
					}}
				>
					<Row
						className='ph-mobile-drawer-container-items'
						onClick={() => navigateToCategory(category)}
					>
						<p className='ph-mobile-drawer-text-items'>Ver todo {category.name}</p>
					</Row>
					{subCategory.map(subCategory => 
						(<Row 
							key={subCategory.id}
							className='ph-mobile-drawer-container-items'
							onClick={() => navigateToCategory(category, subCategory)}
						>
						<p className='ph-mobile-drawer-text-items'>{subCategory.name}</p>
						
					</Row>)
					)} 
			</CustomDrawer>
			</div>
		</>
	);
};
export default SideBar;
