/** @format */

import axios from 'axios';

import Notify from '../../../components/Commons/Notify';

import { ENV_USER, ENV_COUNTRY } from '../../../components/Commons/Hooks/Variables/Enviroment';

export async function LoginUserService(item) {
	let returnResponse;
	await axios({
		method: 'POST',
		url: `${ENV_USER}/user/login/${ENV_COUNTRY}`,
		data: {
			email: item.email.toLowerCase(),
			password: item.password,
			products: [],
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data;
				Notify({
					title: `¡Hola ${response.data.data.info.first_name} ${response.data.data.info.last_name}!`,
					colorTitle: 'ph-main-notification-success-title',
					description: 'Has iniciado sesión correctamente.',
					time: 5,
					image: 'warning',
					placement: 'topRight',
				});
			} else {
				Notify({
					title: 'Problemas al iniciar sesión', //`Error ${response.data.statusCode}`
					colorTitle: 'ph-main-notification-error-title',
					description: 'La contraseña ingresada es incorrecta, intenta nuevamente', // `Problemas al iniciar sesión: ${response.data.message}`
					time: 5,
					image: 'error',
					placement: 'topRight',
				});
			}
		})
		.catch(() => {
			Notify({
				title: `Error Servicio`,
				colorTitle: 'ph-main-notification-error-title',
				description: 'Revisa tu conexión a internet.',
				time: 5,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
}

export async function LoginUserSocialService(item) {
	let returnResponse;
	await axios({
		method: 'POST',
		url: `${ENV_USER}/user/login-by-social/${ENV_COUNTRY}`,
		data: {
			email: item.email.toLowerCase(),
			social_type: item.type,
			access_token: item.accesToken,
			user_id: item.userId,
			products: [],
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data;
				Notify({
					title: `¡Hola ${response.data.data.info.first_name} ${response.data.data.info.last_name}!`,
					colorTitle: 'ph-main-notification-success-title',
					description: 'Has iniciado sesión correctamente.',
					time: 5,
					image: 'warning',
					placement: 'topRight',
				});
			} else {
				Notify({
					title: 'Problemas al iniciar sesión', //`Error ${response.data.statusCode}`
					colorTitle: 'ph-main-notification-error-title',
					description: 'La contraseña ingresada es incorrecta, intenta nuevamente', // `Problemas al iniciar sesión: ${response.data.message}`
					time: 5,
					image: 'error',
					placement: 'topRight',
				});
			}
		})
		.catch(() => {
			Notify({
				title: `Error`,
				colorTitle: 'ph-main-notification-error-title',
				description: `Revisa tu conexión a internet.`,
				time: 5,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
}
