export const NOTIFICATION = {
	TYPE: {
		SUCCESS: 'success',
		ERROR: 'error',
		INFO: 'info',
		WARNING: 'warning',
		OPEN: 'open',
	},
	MESSAGE: {
		SUCCESS: '¡Bien!',
		ERROR: '¡Ups!',
		INFO: 'Información',
		WARNING: 'Advertencia',
		OPEN: 'Abrir',
	},
};
