/** @format */

import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { Enviroment } from '../../../../../../_config/constants/enviroment-v2.constant';
import Spacer from '../../../../../../components/Commons/Spacer';
import CustomButton from '../../../../../../v2-components/Antd/Button';
import './style.scss';
export default function CheckoutButtonRetirement(props) {
	const [isLoading] = useGlobal('retirementButtonLoading');
	const [isMobile, setMobile] = useState(false);

	useEffect(() => {
		if (window.innerWidth < 992) {
			setMobile(true);
		}
	}, []);

	return (
		<Form.Item className="ph-checkout-withdrawal-button-main-container">
			{isMobile && (
				<>
					<div>
						<h3 className="ph-checkout-withdrawal-button-mobile-price-main-title">Subtotal:</h3>
						<h3 className="ph-checkout-withdrawal-button-mobile-price-title">
							<span className="ph-checkout-withdrawal-button-mobile-price-description">$</span>
							{props.item.total}
						</h3>
					</div>
					<Spacer />
				</>
			)}
			<CustomButton className={'button-primary'} loading={isLoading} htmlType={'submit'}>
				{Enviroment.ROOT_RESERVE ? 'CONSULTAR STOCK' : 'SIGUIENTE'}
			</CustomButton>
		</Form.Item>
	);
}
