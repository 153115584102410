/** @format */

import { Col, Row } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setGlobal, useGlobal } from 'reactn';

import { Events } from '../../../../../../_config/adapters/google-tag-manager/events.constant';
import { PharmacyProductAdapter } from '../../../../../../_config/adapters/google-tag-manager/pharmacy-product.adapter';

import Carousel from '../../../../../../components/Commons/Carousel';
import { ENV_IMG, ENV_IMG_HOME } from '../../../../../../components/Commons/Hooks/Variables/Enviroment';
import { ModalTypeRecipes } from '../../../../../../components/Commons/Modals/ModalTypeRecipes';
import RationalBanner from '../../../../../../components/Commons/Modals/RationalBanner';
import Contact from '../../../../components/DispatchInfo/components/Contact';
import Image from '../../../../components/Image';
import InfoTabs from '../../../../components/InfoTabs';
import DescriptionTypeReceipt from '../../../../components/Title/components/DescriptionTypeReceipt';
import Title from '../DetailSimple/components/Title';
import DeliveryAndPrice from './components/DeliveryAndPrice';
import RectangleInfo from './components/RectangleInfo';
import Subscription from './components/Subscription';

import { useEffect } from 'react';
import CountryValidator from '../../../../../../components/Commons/CountryValidator';
import CustomImage from '../../../../../../v2-components/Antd/Image';
import './style.scss';

export default function ProductDetailPharmacy(props) {
	const { t } = useTranslation('global');
	const [isCovenant] = useGlobal('covenantProductList');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const prescriptionValidation =
		props.item.details[0].prescription_type === 'WP' ||
		props.item.details[0].prescription_type === 'RCH' ||
		props.item.details[0].prescription_type === 'RMRSCS';

	useEffect(() => {
		console.log('entrando a un producto de farmacia independiente');
		PharmacyProductAdapter({ event: Events.view_item, product: props.item.details[0] });
	}, [props.item]);
	const handleTypeRecipes = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};
	return (
		<>
			<Row gutter={[15, 0]}>
				<Col xs={24} sm={24} md={24} lg={8} xl={8} className="ph-product-detailpharmacy-title-container">
					<div>
						<Title item={props.item} detailPharmacy={true} />
					</div>
					<div className="ph-product-detail-detail-image-container">
						<Image classContent={'ph-product-detail-main-image-global-container'} item={props.item.details[0]} />
					</div>
					{props.item.details[0].cenabast && (
						<RectangleInfo
							title={'Producto Cenabast'}
							subtitle={'#REMEDIOSMÁSBARATOS'}
							description={'Infórmate aquí'}
							link={`https://www.cenabast.cl/`}
							image={`${ENV_IMG_HOME}/cenabast.jpg`}
						/>
					)}
					{/*
						// REVISAR
							{props.item.details[0].on_demand && (
							<RectangleInfo
								title={'Producto Contra Pedido'}
								subtitle={'Producto no disponible de forma inmediata en la farmacia.'}
								description={'Click aquí'}
								link={`${ENV_BASE}/against-orders`}
								image={`${ENV_IMG}/Iconos/Iconos%20Recetas%20Pharol_Mesa%20de%20trabajo%201%20copia%209.png`}
							/>
						)}
					*/}
					{props.item.details[0].prescription_type === 'NP' && props.item.details[0].master_category === 'Medicamentos' && (
						<CountryValidator
							countries={['CL']}
							component={
								<div
									className="ph-product-detail-rational-hover-container"
									onClick={() =>
										setGlobal({
											rationalModal: true,
										})
									}
								>
									<RectangleInfo
										title={'Uso racional de Medicamentos'}
										subtitle={'Llama a salud responde'}
										description={'600-360-7777'}
										image={`${ENV_IMG}/react-home/rational-icon.png`}
									/>
								</div>
							}
						/>
					)}
					{props.item.details[0].interface?.prescryption_type && props.item.details[0].master_category === 'Medicamentos' && !props.mobile && (
						<div
							onClick={handleTypeRecipes}
							style={{ cursor: 'pointer' }}
							className="ph-product-detail-type-recepit-main-container ph-product-detailpharmacy-type-recepit"
						>
							<DescriptionTypeReceipt
								title={t(`prescryptionType.${props.item.details[0].prescryption_type}`)}
								description={props.item.details[0].interface.prescryption_type.type}
								image={props.item.details[0].interface.prescryption_type.image}
							/>
							{props.item.details[0].bioequivalent === '1' && (
								<div style={{ display: 'flex', alignItems: 'center', marginTop: '13px' }}>
									<CustomImage width={30} src={props?.item?.details[0]?.interface?.bioequivalent?.image} />
									<div className="ph-product-detail-type-recepit-title" style={{ marginLeft: '15px' }}>
										Producto Bioequivalente
									</div>
								</div>
							)}
						</div>
					)}

					<CountryValidator
						countries={['AR']}
						condition={!props.item.details.some((i) => i.master_category === 'Medicamentos' && prescriptionValidation)}
						component={
							<p className="ph-product-detail-info-disclaimer">
								La farmacia validará el descuento correspondiente a tu plan cuando presentes tu prepaga/obra social.
							</p>
						}
					/>
					<CountryValidator
						countries={['AR']}
						condition={!props.item.details.some((i) => i.master_category === 'Medicamentos')}
						component={
							<p className="ph-product-detail-info-disclaimer">Leé atentamente el prospecto y ante la menor duda consultá a tu médico y/o farmacéutico.</p>
						}
					/>
					{/* PARCHE
              aca tiene que ir el boton de farmacias disponibles */}
					{/* {console.log(props)}
            <SidebarInfo
                pharmacyIdentifier={{}}
                params={{}}
              />
          */}
					{!props.mobile && (
						<>
							{props.item.bioequivalent.length > 0 && (
								<div className="ph-product-detail-mini-carousel-container">
									<h3 className="ph-product-detail-mini-carousel-title">Productos Intercambiables</h3>
									<Carousel carouselItems={[2, 2, 2, 2, 1]} type="product" list={props.item.bioequivalent} />
								</div>
							)}
							{props.item.details[0].master_category !== 'Medicamentos' && (
								<>
									{props.item.relations.length > 0 && props.item.bioequivalent.length === 0 && (
										<div className="ph-product-detail-mini-carousel-container">
											<h3 className="ph-product-detail-mini-carousel-title">Otras personas también vieron</h3>
											<Carousel carouselItems={[2, 2, 2, 2, 1]} type="product" list={props.item.relations} />
										</div>
									)}
								</>
							)}
						</>
					)}
					<div className="ph-product-detailpharmacy-contact-container">
						<Contact service={props.service} item={props.item} />
					</div>
				</Col>
				<Col xs={24} sm={24} md={24} lg={16} xl={16} className="ph-product-detailpharmacy-title-container">
					<>
						<div>
							<Title item={props.item} detailPharmacy={true} desktop={true} />
						</div>
						<div className="ph-product-detailpharmacy-subtitle-container">Entregado por:</div>
						<DeliveryAndPrice mobile={props.mobile} item={props.item} isCovenant={isCovenant} />
					</>
					{props.item.details[0].stock <= 0 && <Subscription />}
					<div className="ph-product-detail-simple-tabs-main-container">
						<InfoTabs item={props.item} />
					</div>
					{/* <DispatchInfo item={props.item} /> */}
				</Col>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					{props.item.details[0].master_category !== 'Medicamentos' && (
						<>
							{!props.mobile && (
								<>
									{props.item.relations.length > 0 && props.item.bioequivalent.length > 0 && (
										<div className="ph-product-detail-mini-carousel-container">
											<h3 className="ph-product-detail-mini-carousel-title">Otras personas también vieron</h3>
											<Carousel carouselItems={[5, 3, 2, 2, 1]} type="product" list={props.item.relations} />
										</div>
									)}
								</>
							)}
						</>
					)}
					{props.mobile && (
						<>
							{props.item.bioequivalent.length > 0 && (
								<div className="ph-product-detail-mini-carousel-container">
									<h3 className="ph-product-detail-mini-carousel-title">Productos Intercambiables</h3>
									<Carousel carouselItems={[2, 2, 2, 2, 1]} type="product" list={props.item.bioequivalent} />
								</div>
							)}
							{props.item.details[0].master_category !== 'Medicamentos' && (
								<>
									{props.item.relations.length > 0 && (
										<div className="ph-product-detail-mini-carousel-container">
											<h3 className="ph-product-detail-mini-carousel-title">Otras personas también vieron</h3>
											<Carousel carouselItems={[2, 2, 2, 2, 1]} type="product" list={props.item.relations} />
										</div>
									)}
								</>
							)}
						</>
					)}
				</Col>
			</Row>
			<RationalBanner />
			<ModalTypeRecipes isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} />
		</>
	);
}
