/** @format */

export const RemoveLocalStorage = () => {
	localStorage.removeItem('retirementData')
	localStorage.removeItem('recipeData')
	localStorage.removeItem('detailData')
	localStorage.removeItem('paymentData')
	localStorage.removeItem('fpayUrl')
	localStorage.removeItem('voucherCode')
	localStorage.removeItem('datePay')
	if (window.location.pathname !== '/bag' && window.location.pathname !== '/cart' && !window.location.pathname.match(/electronic-prescription/) && !window.location.pathname.match(/quote/)) {
		localStorage.setItem('cart', JSON.stringify([]))
		localStorage.removeItem('onDemandData')
		localStorage.removeItem('validVoucher')
	}
}
