/** @format */

import React from "react";
import { useTranslation } from "react-i18next";
import CountryValidator from "../../../../components/Commons/CountryValidator"
import { Button } from "antd";

import Images from "../../../../components/Commons/Images";
import { ENV_IMG_SOCIAL } from "../../../../components/Commons/Hooks/Variables/Enviroment";

import "./style.scss";

export default function ProductDetailSocialNetwork(props) {
  const { t } = useTranslation('global')
  const handleMetaItems = (item, pharmacy) => {
    let linkPharmacy = "";
    let metaTitle = `${item.meta_title} - ${t('main.metaTitle')}`;
    if (pharmacy) {
      linkPharmacy = `-farmacias-${item.pharmacy}`;
      metaTitle = `${item.meta_title} - Farmacias ${
        item.pharmacy.charAt(0).toUpperCase() + item.pharmacy.slice(1)
      } en ${t('main.company')}`;
    }
    const metaDescription = `${t('main.baseURL')}/products/${item.slug}${linkPharmacy}`;
    return {
      metaTitle: metaTitle,
      metaDescription: metaDescription,
    };
  };

  const handleShareFacebook = (item, pharmacy) => {
    const metaAtributes = handleMetaItems(item, pharmacy);
    console.log(item,metaAtributes)
    window.open(
      "http://www.facebook.com/sharer.php?u=" + metaAtributes.metaDescription,
      "facebook-popup",
      "height=450,width=600"
    );
  };

  const handleShareTwitter = (item, pharmacy) => {
    const metaAtributes = handleMetaItems(item, pharmacy);
    window.open(
      "http://twitter.com/share?url=" +
        metaAtributes.metaDescription +
        "&text=" +
        metaAtributes.metaTitle +
        " ",
      "twitter-popup",
      "height=450,width=600"
    );
  };

  const handleSharePinterest = (item, pharmacy) => {
    const metaAtributes = handleMetaItems(item, pharmacy);
    window.open(
      "http://www.pinterest.com/pin/create/button/?url=" +
        metaAtributes.metaDescription +
        "&media=" +
        item.image +
        "&description" +
        metaAtributes.metaTitle,
      "ventanacompartir",
      "height=350,width=600"
    );
  };

  const handleShareEmail = async (item, pharmacy) => {
    const metaAtributes = handleMetaItems(item, pharmacy);
    window.open(
      "mailto:?Subject=" +
        metaAtributes.metaTitle +
        "&body=" +
        metaAtributes.metaDescription
    );
  };

  return (
    <div
      className={`${
        props.detailSimple ? "ph-detail-simple-network-main-container" : ""
      } ph-product-detail-social-netwok-container`}
    >
      <Button
        className="ph-product-detail-social-button"
        onClick={() => handleShareFacebook(props.item, props.pharmacies)}
      >
        <Images
          classContent={"ph-product-detail-social-image-container"}
          classImage={"ph-product-detail-social-image"}
          default={`${ENV_IMG_SOCIAL}/facebook.png`}
          title={"Facebook"}
          alt={"Facebook"}
        />
      </Button>
      <CountryValidator 
        countries={['CL']}
        component={
          <>
            <Button
              className="ph-product-detail-social-button"
              onClick={() => handleShareTwitter(props.item, props.pharmacies)}
            >
              <Images
                classContent={"ph-product-detail-social-image-container"}
                classImage={"ph-product-detail-social-image"}
                default={`${ENV_IMG_SOCIAL}/twitter.png`}
                title={"Twitter"}
                alt={"Twitter"}
              />
            </Button>
            <Button
              className="ph-product-detail-social-button"
              onClick={() => handleSharePinterest(props.item, props.pharmacies)}
            >
              <Images
                classContent={"ph-product-detail-social-image-container"}
                classImage={"ph-product-detail-social-image"}
                default={`${ENV_IMG_SOCIAL}/pinteres.png`}
                title={"Twitter"}
                alt={"Twitter"}
              />
            </Button>
            <Button
              className="ph-product-detail-social-button"
              onClick={() => handleShareEmail(props.item, props.pharmacies)}
            >
              <Images
                classContent={"ph-product-detail-social-image-container"}
                classImage={"ph-product-detail-social-image"}
                default={`${ENV_IMG_SOCIAL}/send.png`}
                title={"Twitter"}
                alt={"Twitter"}
              />
            </Button>
          </>
        }
      />
    </div>
  );
}
