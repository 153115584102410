/** @format */

import axios from 'axios';

import { ENV_COMMON, ENV_COUNTRY } from '../../components/Commons/Hooks/Variables/Enviroment';
import { ScreenWidth } from '../../components/Commons/Hooks/Functions/ScreenWidth';

export default async function GetPharmacies(item) {
	console.log('GetPharmacies', item);
	let returnResponse;
	await axios({
		method: 'GET',
		url: `${ENV_COMMON}/pharmacy/${ENV_COUNTRY}/${item}?mobile=${ScreenWidth()}`,
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data;
			} else {
				throw new Error(response);
			}
		})
		.catch((e) => {
			console.log('error', e);
		});
	return returnResponse;
}
