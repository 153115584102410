/** @format */

import React, { useState } from 'react'

import Images from '../../../../components/Commons/Images'
import Logos from '../../../../components/Commons/Product/components/Logos'

import ModalImage from './components/ModalImage'

//import { FavoriteActions } from '../../../../components/Commons/Favorites/Components'

import {
	ENV_IMG_ROUTE,
	ENV_COUNTRY,
} from '../../../../components/Commons/Hooks/Variables/Enviroment'

import './style.scss'

export default function ProductImage(props) {
	const [isVisible, setVisible] = useState(false)

	const handleShowImageModal = (item) => {
		setVisible(item)
	}

	return (
		<>
			<div className={`${props.classContent}`}>
				<Logos
					promotionImgClassContent={'ph-product-detail-promotion-image-main-container'}
					promotionImgClass={'ph-product-detail-promotion-image'}
					cpImgClass={'ph-product-detail-promotion-cp-image'}
					cenabastImgClass={'ph-product-detail-promotion-cenabast-image'}
					detail={props.item}
				/>
				<div
					className='ph-product-detail-main-image-main-container'
					onClick={() => handleShowImageModal(true)}>
					<Images
						classContent={'ph-product-detail-main-image-container'}
						classImage={'ph-product-detail-main-image'}
						webp={`${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${props.item.id_pharol}.webp`
						} 
						default={`${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${props.item.id_pharol}.png`
						}
						title={props.item.editable_name}
						alt={props.item.editable_name}
					/>
				</div>
				<ModalImage
					title={props.item.name}
					item={props.item}
					showImageModal={handleShowImageModal}
					visible={isVisible}
				/>
			</div>
		</>
	)
}
