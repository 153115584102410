/** @format */

import React from 'react';

import './style.scss';

export default function TitleComplaint(props) {
	return (
		<>
			<div>
				<div className="ph-complaint-main-title-main-box-title-container">
					<h2 className="ph-complaint-main-title-main-box-main-title">{props.title}</h2>
				</div>
			</div>
		</>
	);
}
