/** @format */

import React, { useState } from 'react';
import { setGlobal, useGlobal } from 'reactn';

import { Form, Button, Layout, Menu, Spin } from 'antd';

import { CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';

import Images from '../Images';
import InputField from '../Input';

import { ENV_IMG_HEADER, ENV_ADDRESS, ENV_COUNTRY } from '../Hooks/Variables/Enviroment';

import { AddressSearch } from '../Hooks/Functions/AddressSearch';
import { AddressSetLocation } from '../Hooks/Functions/AddressSetLocation';
import { PharmacySetLocation } from '../Hooks/Functions/PharmacySetLocation';

import './style.scss';

export default function Address(props) {
	const [isLoadingButton, setLoadingButton] = useState(false);
	const [isAddress] = useGlobal('address');
	const [isAddressList, setAddressList] = useState([]);
	const [isUserAddressList] = useGlobal('userAddressListGlobal');
	const [form] = Form.useForm();

	const handleSearchAddress = async (item) => {
		setLoadingButton(true);
		await AddressSearch(item).then((response) => {
			setAddressList(response);
		});
		setLoadingButton(false);
	};

	const handleSetLocation = async (item) => {
		let address;
		setLoadingButton(true);
		setAddressList([]);
		await AddressSetLocation(item).then((response) => {
			address = response;
			PharmacySetLocation(address);
		});
		setGlobal(() => ({
			address: address,
		}));
		form.resetFields();
		setLoadingButton(false);
	};

	const handleDeleteLocation = () => {
		let address = ENV_ADDRESS.split(',');
		let lat = parseFloat(address[3]);
		let lng = parseFloat(address[4]);
		let location = {
			lat: lat,
			lng: lng,
			address: `${address[0]}, ${address[1]}, ${address[2]}`,
			street: address[0],
			commune: address[1],
			region: address[2],
			default: true,
		};
		localStorage.setItem('locationStore', JSON.stringify(location));
		setGlobal(() => ({ address: location }));
		PharmacySetLocation(location);
	};

	return (
		<div className={`${props.classType}-main-container`}>
			<div className={`${props.classType}-description-container`}>
				{isAddress ? (
					<>
						{!isAddress.default ? (
							<div className={`${props.classType}-inner-container`}>
								<Images
									classContent={`${props.classType}-image-logo-container`}
									classImage={`${props.classType}-image-logo`}
									webp={`${ENV_IMG_HEADER}/pharol-location.webp`}
									jp2={`${ENV_IMG_HEADER}/pharol-location.jp2`}
									jxr={`${ENV_IMG_HEADER}/pharol-location.jxr`}
									default={`${ENV_IMG_HEADER}/pharol-location.png`}
									title={'location'}
								/>
								<h3 className={`${props.classType}-step-title`}>
									{isAddress.street}, {isAddress.commune} - {isAddress.region}
								</h3>
								{props.isType !== 'on_demand' && (
									<Button className={`${props.classType}${ENV_COUNTRY === 'AR' ? '-step-button-arg' : '-step-button'}`} onClick={() => handleDeleteLocation()}>
										<CloseCircleOutlined />
									</Button>
								)}
							</div>
						) : (
							<>
								<div className={`${props.classType}-form-main-container`}>
									<Form className={`${props.classType}-form-container`} onFinish={handleSearchAddress} form={form}>
										<InputField
											className={`${isUserAddressList ? `${props.classType}-form-search-field-no-radius` : `${props.classType}-form-search-field-radius`} ${
												props.classType
											}-form-search-field`}
											inputName={'address'}
											inputNameLabel={'Ingresa calle y número...'}
											inputNameType={'text'}
											inputNameRule={true}
											inputNameMessage={'Ingresa tu correo......'}
											inputNameRules={'rulesAddress'}
										/>
										<Button className={`${props.classType}-form-search-button`} htmlType="submit" loading={isLoadingButton}>
											<h3 className={`${props.classType}-title-form-search-button`}>BUSCAR</h3>
										</Button>
									</Form>
								</div>
								<Layout className={`${props.classType}-form-search-list`}>
									{isAddressList && (
										<Menu>
											{isAddressList.map((address, index) => (
												<Menu.Item onClick={() => handleSetLocation(address.name)} className={`${props.classType}-form-search-list-description`} key={index}>
													<Images
														classContent={`${props.classType}-form-search-list-image-container`}
														classImage={`${props.classType}-form-search-list-image`}
														webp={`${ENV_IMG_HEADER}/pharol-location-gris.webp`}
														jp2={`${ENV_IMG_HEADER}/pharol-location-gris.jp2`}
														jxr={`${ENV_IMG_HEADER}/pharol-location-gris.jxr`}
														default={`${ENV_IMG_HEADER}/pharol-location-gris.png`}
														title={'location-gris'}
													/>
													{address.name}
												</Menu.Item>
											))}
										</Menu>
									)}
								</Layout>
							</>
						)}
						{props.isType === 'on_demand' && (
							<h3 className={`${props.classType}-on-demand-description`}>
								<span className={`${props.classType}-on-demand-asterisk`}>*</span>
								La dirección usada está vinculada a su cotización, no se puede cambiar.
							</h3>
						)}
					</>
				) : (
					<div className={`${props.classType}-loading-container`}>
						<Spin indicator={<LoadingOutlined className={`${props.classType}-image`} />} className={`${props.classType}-icon`} />
					</div>
				)}
			</div>
		</div>
	);
}
