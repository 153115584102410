/** @format */

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { ReactComponent as IconBag } from '../../../../../../../../v2-config/icons-svg/bag.svg';
import { amountProductsCart } from '../../../../../../Hooks/Functions/AmountProductsCart';

import './style.scss';

const CartIcon = () => {
	const history = useHistory();
	const [isCartQuantity, setCartQuantity] = useGlobal('carQuantity');

	const handleBagLink = () => {
		history.push('/cart');
	};

	useEffect(() => {
		setCartQuantity(amountProductsCart());
	}, [setCartQuantity]);

	return (
		<div className="ph-header-mobile-cart-link-container" onClick={() => handleBagLink()}>
			<IconBag className={window.location.pathname === '/cart' ? 'icons-theme active' : 'icons-theme'} />
			<div className="ph-header-mobile-cart-baloon-icon">
				<h5 className="ph-header-mobile-cart-baloon-title">{isCartQuantity && <>{isCartQuantity > 9 ? '+9' : isCartQuantity}</>}</h5>
			</div>
		</div>
	);
};
export default CartIcon;
