export const GetLocalStorageValue = (key) => {
	const value = localStorage.getItem(key);

	if (!value) {
		return null;
	}
	if (value === undefined) {
		return null;
	}

	try {
		const jsonParse = JSON.parse(value);
		return jsonParse;
	} catch (error) {
		return value;
	}
};
