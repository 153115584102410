/** @format */

import React, { useEffect } from 'react';

import { BagConsumer } from '../../../../../../components/Commons/Context/Bag';
import { GlobalConsumer } from '../../../../../../components/Commons/Context/Global';

import { formatPrice } from '../../../../../../components/Commons/Hooks/Functions/FormatPrice';

import './style.scss';

export default function BagTotalInfo(props) {
	const { isBagRetirement, isBagData } = BagConsumer();
	const { isMobile } = GlobalConsumer();

	useEffect(() => {}, [isBagData]);

	return (
		<div className="ph-bag-products-group-total-info-container">
			<div className="ph-bag-products-group-total-info-inner-container">
				{isBagRetirement && isBagRetirement[props.info.name] && props.info && (
					<>
						{isBagRetirement[props.info.name].type_retirement ? (
							<>
								<h4 className="ph-bag-products-group-total-info-price-title">
									Sub-total: <span className="ph-bag-products-group-total-info-price">{formatPrice(props.info.payment.sub_total)}</span>
								</h4>
								{props.info.payment.discount !== 0 && (
									<h4 className="ph-bag-products-group-total-info-price-title">
										Descuento:
										<span className="ph-bag-products-group-total-info-price">{formatPrice(props.info.payment.discount)}</span>
									</h4>
								)}
								{props.info.payment.discount_voucher !== 0 && (
									<h4 className="ph-bag-products-group-total-info-price-title">
										Desc. Cupón:
										<span className="ph-bag-products-group-total-info-price">-{formatPrice(props.info.payment.discount_voucher)}</span>
									</h4>
								)}
								<h4 className="ph-bag-products-group-total-info-price-title">
									Costo de Entrega:
									<span className="ph-bag-products-group-total-info-price">
										{isMobile && props.info.payment.delivery_subtotal > 0 && <span className="ph-bag-products-group-subtotal-since">desde </span>}
										{formatPrice(props.info.payment.delivery_subtotal)}
									</span>
								</h4>
								{props.info.payment.discount_pbm !== 0 && (
									<h4 className="ph-bag-products-group-total-info-price-title">
										PBM:
										<span className="ph-bag-products-group-total-info-price">{formatPrice(props.info.payment.discount_pbm)}</span>
									</h4>
								)}
								<h4 className="ph-bag-products-group-total-info-price-title-2">
									Total Farmacia:
									<span className="ph-bag-products-group-total-info-price-2">{formatPrice(props.info.payment.total)} </span>
								</h4>
							</>
						) : (
							<h4 className="ph-bag-products-group-no-total-title">Recuerde seleccionar un tipo de entrega</h4>
						)}
					</>
				)}
			</div>
		</div>
	);
}
