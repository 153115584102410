/** @format */

import React from 'react'

import { Modal } from 'antd'

import MapRoutes from './components/MapRoutes'
import TimeLocal from './components/TimeLocal'
import RoadRoutes from './components/RoadRoutes'

import './style.scss'

export default function ModalLocalSelected(props) {
	return (
		<Modal
			wrapClassName='ph-local-select-modal-container'
			centered
			width={700}
			maskClosable={true}
			title={props.isPreviewLocal ? props.isPreviewLocal.name_local : ''}
			visible={props.isVisible}
			cancelText='Cancelar'
			destroyOnClose={true}
			onCancel={() => props.handlePreviewLocal(false)}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}>
			{props.isPreviewLocal && (
				<>
					<MapRoutes
						pharmacyLocation={{
							lat: props.isPreviewLocal.location.lat,
							lng: props.isPreviewLocal.location.lng,
						}}
						userLocation={props.userAddress}
						center={[props.isPreviewLocal.location.lat, props.isPreviewLocal.location.lng]}
						zoom={11}
						title={'User waypoints'}
						description={'Route.'}
					/>
					<RoadRoutes
						mobile={props.isMobile}
						userLocation={props.userAddress}
						item={props.isPreviewLocal}
					/>
					<TimeLocal item={props.isPreviewLocal} />
				</>
			)}
		</Modal>
	)
}
