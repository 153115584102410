import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import CustomButton from '../../../../../../v2-components/Antd/Button';
import CustomCard from '../../../../../../v2-components/Antd/Card';
import CustomLink from '../../../../../../v2-components/Antd/Link';
import { CustomNotification } from '../../../../../../v2-components/Antd/Notification';
import CustomSteps from '../../../../../../v2-components/Antd/Steps';
import LoadingModal from '../../../../../../v2-components/LoadingModal';
import LoadingPage from '../../../../../../v2-components/LoadingPage';
import { homeDeliveryAddress } from './handle/home-delivery-address.handle';
import { pharmacyRetirement } from './handle/pharmacy-retirement.handle';
import { purchaseInfoAdapter } from './handle/purchase-info-adapter.handle';
import { stepsHandle } from './handle/steps.handle';
import { unixAdapter } from './handle/unix-adapter.handle';
import { Service } from './service';
import { PRESCRYPTION_TYPE } from './constants/prescryption-type.constant';

import './style.scss';
import { reasonRecipe } from './handle/reason-recipe.handle';
import { GetLocalStorageValue } from '../../../../../../_config/adapters/local-storage/get-local-storage-value.adapter';

export default function ProfileReserveDetailPage() {
	const { id, operation_id } = useParams();
	const [reserve, setReserve] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [openLoadingModal, setOpenLoadingModal] = useState(false);
	const [isLoadingModal, setIsLoadingModal] = useState(false);
	const [purchase, setPurchase] = useState();
	const { token } = GetLocalStorageValue('userMainInfo');

	useEffect(() => {
		Promise.all([new Service().getPurchaseById(id, token), new Service().getReserveByOperationId(operation_id, token)])
			.then(([purchaseData, reserveData]) => {
				setPurchase(purchaseData.data);
				setReserve(reserveData.data);
				setIsLoading(false);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [id, operation_id, token]);
	console.log('reserve', reserve);

	const steps = stepsHandle(reserve?.reserve[0]?.status_log);
	const current = steps.length - 1;
	const purchaseAdapter = purchaseInfoAdapter(purchase);

	const quantity = (code_internal) => {
		const product = reserve?.reserve[0]?.origin_products_pharmacy?.find((product) => product.code_internal === code_internal);
		return product?.quantity;
	};

	const totalProductsCount = (array) => {
		return array.reduce((total, item) => {
			return total + parseInt(quantity(item.code_internal));
		}, 0);
	};

	const handleSendOrder = () => {
		setOpenLoadingModal(true);
		setIsLoadingModal(true);
		new Service()
			.resendEmail(operation_id, purchase.country)
			.then((response) => {
				console.log('promesa resuelta');
				setOpenLoadingModal(false);
				setIsLoadingModal(false);
				CustomNotification({ type: 'success', message: 'Orden enviada', description: 'La orden fue reenviada a su dirección de correo' });
			})
			.catch((error) => {
				console.log(error);
			});
	};

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<>
			<Row justify="center" className="reserve-detail-content">
				<Col md={18} xs={22}>
					<Row justify="left">
						<h3>Solicitud N° {operation_id} </h3>
					</Row>
					<Row justify="left">
						<CustomLink className={'link-primary'} to="/profile-info/reserve-history">
							Volver
						</CustomLink>
					</Row>
					<br></br>

					<Row justify="left" gutter={[16, 16]}>
						<Col md={8} xs={24}>
							<h4> Estado de la solicitud</h4>
							<CustomCard onClick={() => {}} className={'card-primary'}>
								<CustomSteps current={current} direction="vertical" items={steps} />
							</CustomCard>
						</Col>
						<Col md={8} xs={24}>
							<h4> Detalle del pedido</h4>
							<CustomCard onClick={() => {}} className={'card-primary'}>
								<Row justify="left">
									<h4>N° de solicitud: {operation_id}</h4>
								</Row>
								<Row justify="left">
									<p>Productos: {totalProductsCount(reserve.products)}</p>
								</Row>
								<Row justify="left">
									<p>Sub Total Productos: ${purchase?.sub_total}</p>
								</Row>
								<Row justify="left">
									<p>Envío: ${purchase?.total_delivery}</p>
								</Row>
								<Row justify="left">
									<p>Total: ${purchase?.total}</p>
								</Row>
								<Row justify="left">
									<p>Fecha de reserva: {unixAdapter(purchase?.createdAt)}</p>
								</Row>
								<Row justify="left">
									<h4>{purchaseAdapter.type_retirement}</h4>
								</Row>
								<Row justify="left">
									<div>
										<p>Fecha estimada de entrega:</p>
										<p>{purchaseAdapter.schedule_selected}</p>
									</div>
								</Row>
								{pharmacyRetirement(purchase?.info.type_retirement, purchase)}
								<Row justify="left">
									<div>
										<h4>
											{purchaseAdapter.first_name} {purchaseAdapter.last_name}
										</h4>
										<p>{purchaseAdapter.rut}</p>
										<p>{purchaseAdapter.email}</p>
										<p>{purchaseAdapter.phone}</p>
										{homeDeliveryAddress(purchase?.info.type_retirement, purchaseAdapter.address)}
									</div>
								</Row>
								<Row justify="left">
									<CustomButton onClick={() => handleSendOrder()} className={'button-tertiary'} block>
										Reenviar Correo
									</CustomButton>
								</Row>
							</CustomCard>
						</Col>
						<Col md={8} xs={24}>
							<h4> Productos</h4>
							{reserve?.products?.map((item, index) => {
								return (
									<React.Fragment key={`card-${index}`}>
										<CustomCard onClick={() => {}} className={'card-primary'}>
											<Row justify="left">{item.laboratory}</Row>
											<Link to={`/products/${item.slug}-farmacias-${item.pharmacy}`}>
												<Row justify="left">{item.editable_name}</Row>
											</Link>
											<Row justify="left">Cantidad: {quantity(item.code_internal)}</Row>
											<Row justify="left">Precio por unidad: ${item.price}</Row>
											<Row justify="left">{PRESCRYPTION_TYPE[item.prescryption_type]}</Row>
											{reasonRecipe(reserve?.reserve[0]?.status)}
										</CustomCard>
										<br></br>
									</React.Fragment>
								);
							})}
							<LoadingModal loading={isLoadingModal} visible={openLoadingModal} />
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
}
