/** @format */

import React, { useState, useEffect } from 'react';
import { setGlobal } from 'reactn';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'antd';
import MetaDescription from '../../../../components/Commons/MetaDescription';
import Loading from '../../../../components/Commons/Loading';
import GetPharmacies from '../../../pharmacies/services';
import CardInfo from '../../components/CardInfo';
import TitleComplaint from '../../components/TitleComplaint';
import ModalCreate from '../../components/ModalCreate';
import { menuComplaint } from './data';

import './style.scss';

export default function ComplaintBbook(props) {
	const history = useHistory();
	const [isPharmacy, setPharmacy] = useState(null);

	useEffect(() => {
		window.scrollTo(0, 0);
		if (props.match.params.pharmacy === 'pharol') {
			setPharmacy({
				name_editable: 'Pharol',
				complaint_email: '',
			});
		} else {
			GetPharmacies(props.match.params.pharmacy).then((response) => {
				if (response) {
					setTimeout(() => {
						setPharmacy(response);
					}, 600);
				}
			});
		}
	}, [props.match.params.pharmacy]);

	const handleLink = (item) => {
		if (item.type === 'modal') return setGlobal({ complaintCreateModal: true });
		else return history.push(`${item.link}/${props.match.params.pharmacy}`);
	};

	if (!isPharmacy) return <Loading />;
	else
		return (
			<>
				<MetaDescription title={`${isPharmacy.name_editable} - Reclamos y Sugerencias`} name={'description'} content={`${isPharmacy.description}`} />
				<div className="ph-complaint-book-container">
					<TitleComplaint
						title={`Reclamos y sugerencias`}
						description={`Ingresa tu solicitud y serás atendido por ${isPharmacy.name_editable}`}
						image={`react-pharmacy/complaint-book/claim-icon`}
						link={`/pharmacies/${props.match.params.pharmacy}`}
					/>
					<Row>
						{menuComplaint.map((item, index) => (
							<Col xs={24} sm={12} md={12} lg={8} xl={8} key={index}>
								<div onClick={() => handleLink(item)}>
									<CardInfo rootImage={true} image={item.image} titleImage={item.title_image} title={item.title} description={item.description} />
								</div>
							</Col>
						))}
					</Row>
				</div>
				<ModalCreate isPharmacy={isPharmacy} />
			</>
		);
}
