/** @format */

import axios from 'axios'

import Notify from '../../../../../../components/Commons/Notify'

import { ENV_CALCULATION } from '../../../../../../components/Commons/Hooks/Variables/Enviroment'

export async function ServiceBagVoucher(item) {
	let returnResponse
	await axios({
		method: 'GET',
		url: `${ENV_CALCULATION}/voucher/${item}`,
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data
			}
		})
		.catch(() => {
			Notify({
				title: `Error de conexión`,
				colorTitle: 'ph-main-notification-error-title',
				description: 'Revisa tu conexión de internet..',
				time: 3,
				image: 'error',
				placement: 'topRight',
			})
		})
	return returnResponse
}
