/** @format */

import React from 'react';

import CountryValidator from '../../../../components/Commons/CountryValidator'
import Images from '../../../../components/Commons/Images';

import { formatStringToSlug } from '../../../../components/Commons/Hooks/Functions/FormatStringToSlug';

import './style.scss';

export default function Timeline(props) {
	return (
		<CountryValidator
			countries={['CL','AR']}
			condition={!props.item.master_category}
			component={
				<ul className="ph-product-timeline-main-container">
					<li className="ph-product-timeline-list-container">
						{props.service ? (
							<>
								{props.item.master_category_image && (
									<>
										{props.item.master_category_image.image && (
											<Images
												classContent={'ph-product-timeline-image-container'}
												classImage={'ph-product-timeline-image'}
												default={props.item.master_category_image.image}
												title={'solo logo white'}
											/>
										)}
									</>
								)}
							</>
						) : (
							<>
								{props.item.master_category_image > 0 && (
									<>
										{props.item.master_category_image[0].image && (
											<Images
												classContent={'ph-product-timeline-image-container'}
												classImage={'ph-product-timeline-image'}
												default={props.item.master_category_image[0].image}
												title={'solo logo white'}
											/>
										)}
									</>
								)}
							</>
						)}
					</li>
					<li className="ph-product-timeline-list-container">
						<a className="ph-product-timeline-master-category-title" href={`/categories/${formatStringToSlug(props.item.master_category)}`} rel="noopener noreferrer">
							{props.item.master_category}
						</a>
					</li>
					{props.item.category && (
						<li className="ph-product-timeline-list-container">
							<span className="ph-product-timeline-master-category-separator">{`>`}</span>
							<a
								className="ph-product-timeline-master-category-title"
								href={`/categories/${formatStringToSlug(props.item.master_category)}/${formatStringToSlug(props.item.category)}`}
								rel="noopener noreferrer"
							>
								{props.item.category}
							</a>
						</li>
					)}
					{props.item.subcategory && (
						<li className="ph-product-timeline-list-container">
							<span className="ph-product-timeline-master-category-separator">{`>`}</span>
							<a
								className="ph-product-timeline-master-category-title"
								href={`/categories/${formatStringToSlug(props.item.master_category)}/${formatStringToSlug(props.item.category)}/${formatStringToSlug(props.item.subcategory)}`}
								rel="noopener noreferrer"
							>
								{props.item.subcategory}
							</a>
						</li>
					)}
					<li className="ph-product-timeline-list-container">
						<span className="ph-product-timeline-master-category-separator">{`>`}</span>
						<span className="ph-product-timeline-master-category-subtitle">{props.item.name}</span>
					</li>
				</ul>

			}
		/>
	);
}
