/** @format */

import React, { useState } from 'react';

import moment from 'moment';

import { useGlobal, setGlobal } from 'reactn';
import CountryValidator from "../../../../../../components/Commons/CountryValidator"
import { Row, Col, Form, Select, Modal } from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';

import Images from '../../../../../../components/Commons/Images';
import Spacer from '../../../../../../components/Commons/Spacer';
import { rulesValidation } from '../../../../../../components/Commons/Input/rules';

import { formatPrice } from '../../../../../../components/Commons/Hooks/Functions/FormatPrice';

import ModalLocalSelected from '../ModalLocalSelected';

import { ENV_COUNTRY, ENV_IMG, ENV_IMG_ROUTE } from '../../../../../../components/Commons/Hooks/Variables/Enviroment';

import './style.scss';

export default function LocalByDateSelect(props) {
	const [isVisible, setVisible] = useState(null);
	const [isPreviewLocal, setPreviewLocal] = useState(null);
	const [dateSelected, setDateSelected] = useState({ day: '', timers: [] });
	const [deliveryDisplay, setDeliveryDisplay] = useState(null);
	const [modalHelp, setModalHelp] = useState(false);
	const [isDeliveryPrices] = useGlobal('deliveryPrices');
	const [isToday] = useState(moment().startOf('day'));

	const handleModalPharmacyInfo = (item) => {
		if (item) {
			setVisible(true);
			setPreviewLocal(item);
		} else {
			setVisible(false);
			setPreviewLocal(null);
		}
	};

	const handleDeliverySelect = (item, data) => {
		dateSelected.timers.forEach((element) => {
			if (item === `${element.start_time} - ${element.end_time}`) {
				setDeliveryDisplay(element);
				const deliveryPrices = { ...isDeliveryPrices, [data]: element.price };
				setGlobal({ deliveryPrices: deliveryPrices });
			}
		});
	};

	const handleSelectTitle = (item) => {
		var day = moment(item, 'DD/MM/YYYY');
		const result = moment.duration(day.diff(isToday)).asDays();
		if (result === 0) return 'Delivery para hoy';
		else return item;
	};

	return (
		<>
			<div className="ph-local-by-date-select-pharmacy-list-global-container">
				<Col span={24}>
					{props.group && (
						<div className="ph-local-by-date-select-pharmacy-list-main-container">
							<Row>
								<Col xs={24} sm={24} md={12} lg={12} xl={12}>
									<div className="ph-local-by-date-select-pharmacy-title-container">
										<Images
											classContent={'ph-local-by-date-select-pharmacy-image-container'}
											classImage={'ph-local-by-date-select-pharmacy-image'}
											default={props.group.image}
											title={props.group.name_editable}
										/>
										<h4 className="ph-local-by-date-select-pharmacy-main-title">{props.group.name_editable}</h4>
									</div>
									<div className="ph-local-by-date-select-pharmacy-local-selected-address-main-container">
										<Images
											classContent={'ph-local-by-date-select-pharmacy-list-button-info-destiny-image-container'}
											classImage={'ph-local-by-date-select-pharmacy-list-button-info-destiny-image'}
											webp={`${ENV_IMG}/react-header/pharol-location.webp`}
											jp2={`${ENV_IMG}/react-header/pharol-location.jxr`}
											jxr={`${ENV_IMG}/react-header/pharol-location.jp2`}
											default={`${ENV_IMG}/react-header/pharol-location.png`}
										/>
										<div>
											<div className="ph-local-by-date-select-pharmacy-list-button-info-start-destiny-container">
												<span className="ph-local-by-date-select-pharmacy-list-button-info-start-location-icon-title">
													{props.isUserAddress.commune} {props.isUserAddress.region} {props.isUserAddress.street}
												</span>
											</div>
											<div className="ph-local-by-date-select-pharmacy-list-button-info-road-location-icon" />
											<div className="ph-local-by-date-select-pharmacy-list-button-info-road-location-icon" />
											<div className="ph-local-by-date-select-pharmacy-list-button-info-road-location-icon" />
											<div className="ph-local-by-date-select-pharmacy-list-button-info-destiny-container">
												<span className="ph-local-by-date-select-pharmacy-list-button-info-start-location-icon"></span>
												<h4 className="ph-local-by-date-select-pharmacy-list-button-info-destiny-location-icon-title">
													{props.group.locals[0].region} {props.group.locals[0].direction}
												</h4>
											</div>

											<h4 className="ph-local-by-date-select-pharmacy-list-preview-map-title" onClick={() => handleModalPharmacyInfo(props.group.locals[0])}>
												Ver mapa
											</h4>

											<h4 className="ph-local-by-date-select-pharmacy-list-button-info-destiny-location-distance-title">
												Distancia:{' '}
												<span
													className="ph-local-by-date-select-pharmacy-list-button-info-destiny-location-distance-subtitle"
													style={{
														color: props.group.locals[0].color_distance,
													}}
												>
													{props.group.shipping_information.distance} km
												</span>
											</h4>
											<div>
												<h4 className="ph-local-by-date-select-pharmacy-list-delivery-info-title">Costos de entrega:</h4>
												<div className="ph-local-by-date-select-pharmacy-list-delivery-info-description-container">
													<span className="ph-local-by-date-select-pharmacy-list-delivery-info-2-description-title">Subtotal despacho:</span>
													<Spacer />
													<span className="ph-local-by-date-select-pharmacy-list-delivery-info-2-description-subtitle-2">
														{deliveryDisplay ? (
															`${formatPrice(deliveryDisplay.original_price)}`
														) : (
															<h4 className="ph-local-by-date-select-pharmacy-list-delivery-info-description-no-price-2">{formatPrice(props.group.payment.delivery_subtotal)}</h4>
														)}
													</span>
												</div>
												<div className="ph-local-by-date-select-pharmacy-list-delivery-info-description-container">
													<span className="ph-local-by-date-select-pharmacy-list-delivery-info-description-title">Descuento despacho:</span>
													<Spacer />
													<span className="ph-local-by-date-select-pharmacy-list-delivery-info-description-subtitle-2">{formatPrice(props.group.payment.discount_delivery)}</span>
												</div>
												<div className="ph-local-by-date-select-pharmacy-list-delivery-info-description-container">
													<span className="ph-local-by-date-select-pharmacy-list-delivery-info-description-title">Total despacho:</span>
													<Spacer />
													<span className="ph-local-by-date-select-pharmacy-list-delivery-info-description-subtitle">
														{deliveryDisplay ? (
															`${formatPrice(deliveryDisplay.price)}`
														) : (
															<h4 className="ph-local-by-date-select-pharmacy-list-delivery-info-description-no-price">{formatPrice(props.group.payment.delivery)}</h4>
														)}
													</span>
													<CountryValidator 
														countries={[]}
														component={
															<QuestionCircleOutlined className="ph-local-by-date-select-pharmacy-list-delivery-info-description-icon" onClick={() => setModalHelp(true)} />
														}
													/>
												</div>
											</div>
										</div>
									</div>
								</Col>
								<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-local-by-date-select-pharmacy-list-delivery-select-main-container">
									<div className="ph-local-by-date-select-pharmacy-list-delivery-select-container">
										{props.group.type_calculation !== 'quotation' ? (
											<div>
												<div>
													<h4 className="ph-withdrawal-pharmacy-local-list-item-main-title">Día de entrega:</h4>
													{props.group.schedule_delivery && (
														<Form.Item name={`date_schedule_${props.group.name}`} rules={rulesValidation.rulesDeliveryDate}>
															<Select
																className="ph-withdrawal-pharmacy-local-selector"
																style={{ width: '100%' }}
																size="large"
																allowClear
																placeholder="Selecciona una Fecha"
																onChange={(value) => {
																	let timers = props.group.schedule_delivery.find((schedule) => schedule.date === value);
																	props.form.setFieldsValue({
																		[`day_schedule_${props.group.name}`]: null,
																	});
																	setDateSelected({
																		day: value,
																		timers: timers.timers,
																	});
																}}
															>
																{props.group.schedule_delivery.map((element, index) => (
																	<Select.Option value={element.date} key={index}>
																		{handleSelectTitle(element.date)}
																	</Select.Option>
																))}
															</Select>
														</Form.Item>
													)}
												</div>
												{dateSelected.day && (
													<div>
														<h4 className="ph-withdrawal-pharmacy-local-list-item-main-title">Hora de entrega:</h4>
														<Form.Item name={`day_schedule_${props.group.name}`} rules={rulesValidation.rulesDeliveryDay}>
															<Select
																className="ph-withdrawal-pharmacy-local-selector"
																style={{ width: '100%' }}
																size="large"
																allowClear
																placeholder="Selecciona el Horario"
																onChange={(value) => handleDeliverySelect(value, props.group.name)}
															>
																{dateSelected.timers.map((element, index) => (
																	<Select.Option value={`${element.start_time} - ${element.end_time}`} key={index}>
																		{`Horario: ${element.start_time} - ${element.end_time} / Precio: $${element.price}`}
																	</Select.Option>
																))}
															</Select>
														</Form.Item>
													</div>
												)}
											</div>
										) : (
											<div className="ph-local-by-date-select-pharmacy-quote-message">
												* Este producto tiene condición de contrapedido, en el momento que dispongamos de disponibilidad de acuerdo a su fecha de llegada a la farmacia, podrás seleccionar fecha y hora
												de entrega.
											</div>
										)}
										{props.group.products && (
											<div className="ph-local-by-date-select-pharmacy-list-products-container">
												<h4 className="ph-withdrawal-pharmacy-local-list-item-main-title">Productos:</h4>
												<Spacer />
												<Row className="ph-local-by-date-select-pharmacy-list-products-row-container">
													{props.group.products.map((item, index) => (
														<Col span={6} key={index}>
															<Images
																classContent={'ph-local-by-date-select-pharmacy-list-products-image-container'}
																classImage={'ph-local-by-date-select-pharmacy-list-products-image'}
																webp={item.id_pharol === undefined ? item.image : `${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${item.id_pharol}.webp`}
																default={item.id_pharol === undefined ? item.image : `${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${item.id_pharol}.png`}
																alt={item.editable_name}
																title={item.editable_name}
															/>
														</Col>
													))}
												</Row>
											</div>
										)}
									</div>
								</Col>
							</Row>
						</div>
					)}
				</Col>
			</div>
			<ModalLocalSelected isVisible={isVisible} isPreviewLocal={isPreviewLocal} userAddress={props.isUserAddress} handlePreviewLocal={(data) => handleModalPharmacyInfo(data)} />
			<Modal
				wrapClassName="ph-checkout-local-by-date-info-modal-container"
				centered
				width={400}
				maskClosable={true}
				title={`Información sobre los Despachos a Domiclio:`}
				visible={modalHelp}
				cancelText="Cancelar"
				onCancel={() => setModalHelp(false)}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
			>
				<div className="ph-checkout-local-by-date-info-delivery-main-container">
					<Images
						classContent={'ph-checkout-local-by-date-info-delivery-image-container'}
						classImage={'ph-checkout-local-by-date-info-delivery-image'}
						webp={`${ENV_IMG}/pharol-go/pharol-go-bici.webp`}
						jp2={`${ENV_IMG}/pharol-go/pharol-go-bici.jp2`}
						jxr={`${ENV_IMG}/pharol-go/pharol-go-bici.jxr`}
						default={`${ENV_IMG}/pharol-go/pharol-go-bici.png`}
						title={'Delivery'}
					/>
				</div>
				<h4 className="ph-checkout-local-by-date-info-delivery-description">
					Con el fin de brindarte un mejor servicio, habilitamos la modalidad <strong>"Despacho Express"</strong>, la cual te permitirá recibir tus pedidos dentro del mismo <strong>día</strong> para
					compras realizadas <strong>antes de las 16:00 hrs</strong> con distancias máximas de <strong>15 Km’s</strong>. Sólo debes seleccionar en <strong>"Día de Entrega"</strong> la opción
					<strong>"Despacho para Hoy"</strong>
				</h4>
			</Modal>
		</>
	);
}
