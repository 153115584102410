import React from 'react';
import { Carousel } from 'antd';
import './style.scss';

export default function CustomCarousel(props) {
	return (
		<>
			<Carousel {...props} />
		</>
	);
}
