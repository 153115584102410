/** @format */

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import Images from '../../../Images';

import { linkToDetailProduct } from '../../../Hooks/Functions/LinkToDetailProduct';

import './style.scss';

export default class ProductInfo extends Component {
	render() {
		return (
			<>
				<div className="ph-product-info-container">
					<p
						className={`${this.props.classList ? 'ph-product-list-info-laboratory' : ''} ${
							this.props.alternatives ? 'ph-product-list-info-laboratory-alternatives' : ''
						} ph-product-info-laboratory`}
					>
						{this.props.detail.laboratory}
					</p>
					<NavLink to={linkToDetailProduct(this.props.detail)}>
						<p
							className={`${this.props.classList ? 'ph-product-list-info-title' : ''} ${
								this.props.alternatives ? 'ph-product-list-info-title-alternatives' : ''
							} ph-product-info-title`}
						>
							{this.props.detail.editable_name}
						</p>
					</NavLink>
					<div className="ph-product-info-pharmacy-container">
						{this.props.detail.pharmacy && (
							<>
								<Images
									classContent={`ph-product-info-pharmacy-image-container ${
										this.props.alternatives ? 'ph-product-info-pharmacy-image-container-alternatives' : ''
									}`}
									classImage={'ph-product-info-pharmacy-image'}
									default={this.props.detail.pharmacy_image}
									alt={this.props.detail.pharmacy}
									title={this.props.detail.pharmacy}
								/>
								<p
									className={`${this.props.classList ? 'ph-product-list-info-pharmacy-title' : ''} ${
										this.props.alternatives ? 'ph-product-list-info-pharmacy-title-alternatives' : ''
									} ph-product-info-pharmacy-title`}
								>
									{console.log('props cards',this.props)}
									{this.props.detail.name_edited_pharmacy}
								</p>
							</>
						)}
					</div>
				</div>
			</>
		);
	}
}
