/** @format */

import React from 'react';

import { Row, Col, Button, notification } from 'antd';

import { UnlockOutlined } from '@ant-design/icons';

import { formatPrice } from '../../../../../../../../components/Commons/Hooks/Functions/FormatPrice';
import Images from '../../../../../../../../components/Commons/Images';
import { ENV_IMG_HEADER } from '../../../../../../../../components/Commons/Hooks/Variables/Enviroment';

import './style.scss';

export default function RepurchaseNotication(item, data) {
	const handleAmountItemsCart = () => {
		return item.reduce((sum, item) => sum + item.quantity, 0);
	};

	const handleTotalPriceCart = () => {
		return item.reduce((sum, item) => sum + item.price * item.quantity, 0);
	};

	notification.open({
		duration: 15,
		className: 'ph-order-repurchase-main-container',
		description: (
			<>
				<div className="ph-order-repurchase-main-title-container">
					<h1 className="ph-order-repurchase-main-title">¡Productos de recompra agregados a la Bolsa!</h1>
				</div>
				<div className="ph-order-repurchase-products-main-container">
					{item.map((item, index) => (
						<Row className="ph-order-repurchase-description-main-container" key={index}>
							<Col span={4} className="ph-order-repurchase-image-main-container">
								<Images classContent={'ph-order-repurchase-image-container'} classImage={'ph-order-repurchase-image'} default={item.image} alt={item.editable_name} title={item.editable_name} />
							</Col>
							<Col span={20}>
								<div className="ph-order-repurchase-pharmacy">
									<Images
										classContent={'ph-order-repurchase-pharmacy-image-container'}
										classImage={'ph-order-repurchase-pharmacy-image'}
										default={item.pharmacy_image}
										alt={item.pharmacy}
										title={item.pharmacy}
									/>
									<p className="ph-order-repurchase-pharmacy-title">{item.name_edited_pharmacy}</p>
								</div>
								<h2 className="ph-order-repurchase-title">{item.name}</h2>
								<div className="ph-order-repurchase-quantity-container">
									<p className="ph-order-repurchase-quantity-title">Cantidad:</p>
									<p className="ph-order-repurchase-quantity-description">{item.quantity}</p>
								</div>
								<div className="ph-order-repurchase-price-container">
									<p className="ph-order-repurchase-price-title">Por unidad:</p>
									<p className="ph-order-repurchase-price-description">{formatPrice(item.unit_price)}</p>
								</div>
								{item.quantity > 1 && (
									<div className="ph-order-repurchase-price-container">
										<p className="ph-order-repurchase-price-title">Sub-total:</p>
										<p className="ph-order-repurchase-price-description">{formatPrice(item.sub_total)}</p>
									</div>
								)}
							</Col>
						</Row>
					))}
				</div>
				{data > 0 && <h4 className="ph-order-repurchase-stock-notification">* Tienes {data} productos no agregados por limite de stock en tu recompra</h4>}
				<hr className="ph-order-repurchase-separator" />
				<Row>
					<Col span={12} className="ph-order-repurchase-total-container">
						<Images
							classContent={'ph-order-repurchase-total-image-container'}
							classImage={'ph-order-repurchase-total-image'}
							webp={`${ENV_IMG_HEADER}/pharol-bolsa-compras.webp`}
							jp2={`${ENV_IMG_HEADER}/pharol-bolsa-compras.jp2`}
							jxr={`${ENV_IMG_HEADER}/pharol-bolsa-compras.jxr`}
							default={`${ENV_IMG_HEADER}/pharol-bolsa-compras.png`}
							alt={'Bolsa de compras'}
							title={'Bolsa de compras'}
						/>
						<p className="ph-order-repurchase-total-title">Productos:</p>
						<p className="ph-order-repurchase-total-subtitle">({handleAmountItemsCart()})</p>
						<p className="ph-order-repurchase-total-description">{formatPrice(handleTotalPriceCart())}</p>
					</Col>
					<Col span={12} className="ph-order-repurchase-total-button-main-container">
						<a href="/cart" rel="noopener noreferrer">
							<div className="ph-order-repurchase-total-button-container">
								<Button className="ph-order-repurchase-total-button-1">
									<UnlockOutlined />
								</Button>
								<Button className="ph-order-repurchase-total-button-2">BOLSA DE COMPRA</Button>
							</div>
						</a>
					</Col>
				</Row>
			</>
		),
		key: 1,
		placement: 'topRight',
	});
}
