/** @format */

import { CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Layout, Menu, Spin, notification } from 'antd';
import React, { useState } from 'react';
import { setGlobal, useGlobal } from 'reactn';
import { AddressSearch } from '../../../Commons/Hooks/Functions/AddressSearch';
import { AddressSetLocation } from '../../../Commons/Hooks/Functions/AddressSetLocation';
import { PharmacySetLocation } from '../../../Commons/Hooks/Functions/PharmacySetLocation';
import { ENV_ADDRESS, ENV_IMG_HEADER } from '../../../Commons/Hooks/Variables/Enviroment';
import Images from '../../../Commons/Images';
import InputField from '../../../Commons/Input';
import Notify from '../../../Commons/Notify';
import './style.scss';

export default function Address(props) {
	const [isAddress] = useGlobal('address');
	const [isAddressList, setAddressList] = useState([]);
	const [isUserAddressList] = useGlobal('userAddressListGlobal');
	const [form] = Form.useForm();

	const handleSearchAddress = async (item) => {
		console.log('handleSearchAddress', item);
		await AddressSearch(item).then((response) => {
			setAddressList(response);
		});
	};

	const handleSetLocation = async (item) => {
		let address;
		setAddressList([]);
		await AddressSetLocation(item).then((response) => {
			address = response;
			PharmacySetLocation(address);
		});
		setGlobal(() => ({
			address: address,
		}));
		form.resetFields();
	};

	const handleDeleteLocation = () => {
		let key = 2;
		Notify({
			title: `¡Atención!`,
			colorTitle: 'ph-main-notification-error-title',
			description: `Si cambias esa dirección, puede que existan productos fuera de rango...
										Pulsa aceptar para confirmar:`,
			time: 0,
			image: 'error',
			placement: 'topRight',
			key,
			buttonFunction: () => {
				let address = ENV_ADDRESS.split(',');
				let lat = parseFloat(address[3]);
				let lng = parseFloat(address[4]);
				let location = {
					lat: lat,
					lng: lng,
					address: `${address[0]}, ${address[1]}, ${address[2]}`,
					street: address[0],
					commune: address[1],
					region: address[2],
					default: true,
				};
				localStorage.setItem('locationStore', JSON.stringify(location));
				setGlobal(() => ({ address: location }));
				PharmacySetLocation(location);
				notification.close(key);
			},
		});
	};

	return (
		<div className={`${props.classType}-main-container`}>
			<div className={`${props.classType}-description-container`}>
				{isAddress ? (
					<>
						{!isAddress.default ? (
							<div className={`${props.classType}-inner-container`}>
								<Images
									classContent={`${props.classType}-image-logo-container`}
									classImage={`${props.classType}-image-logo`}
									webp={`${ENV_IMG_HEADER}/pharol-location.webp`}
									jp2={`${ENV_IMG_HEADER}/pharol-location.jp2`}
									jxr={`${ENV_IMG_HEADER}/pharol-location.jxr`}
									default={`${ENV_IMG_HEADER}/pharol-location.png`}
									title={'address'}
								/>
								<h3 className={`${props.classType}-step-title`}>
									{isAddress.street}, {isAddress.commune} - {isAddress.region}
								</h3>
								{props.isType !== 'on_demand' && (
									<Button className={`${props.classType}-step-button`} onClick={() => handleDeleteLocation()}>
										<CloseCircleOutlined />
									</Button>
								)}
							</div>
						) : (
							<>
								<div className={`${props.classType}-form-main-container`}>
									<Form className={`${props.classType}-form-container`} form={form}>
										<InputField
											className={`${isUserAddressList ? `${props.classType}-form-search-field-no-radius` : `${props.classType}-form-search-field-radius`} ${
												props.classType
											}-form-search-field`}
											inputName={'address'}
											inputNameLabel={'Ingresa calle y número...'}
											inputNameType={'text'}
											inputNameRule={true}
											inputNameMessage={'Ingresa tu correo......'}
											inputNameRules={'rulesAddress'}
											onChange={(e) => handleSearchAddress(e.target.value)}
										/>
									</Form>
								</div>
								<Layout className={`${props.classType}-form-search-list`}>
									{isAddressList && (
										<Menu>
											{isAddressList.map((address, index) => (
												<Menu.Item onClick={() => handleSetLocation(address.name)} className={`${props.classType}-form-search-list-description`} key={index}>
													<Images
														classContent={`${props.classType}-form-search-list-image-container`}
														classImage={`${props.classType}-form-search-list-image`}
														webp={`${ENV_IMG_HEADER}/pharol-location-gris.webp`}
														jp2={`${ENV_IMG_HEADER}/pharol-location-gris.jp2`}
														jxr={`${ENV_IMG_HEADER}/pharol-location-gris.jxr`}
														default={`${ENV_IMG_HEADER}/pharol-location-gris.png`}
														title={'address'}
													/>
													{address.name}
												</Menu.Item>
											))}
										</Menu>
									)}
								</Layout>
							</>
						)}
						{props.isType === 'on_demand' && (
							<h3 className={`${props.classType}-on-demand-description`}>
								<span className={`${props.classType}-on-demand-asterisk`}>*</span>
								La dirección usada está vinculada a su cotización, no se puede cambiar.
							</h3>
						)}
					</>
				) : (
					<div className={`${props.classType}-loading-container`}>
						<Spin indicator={<LoadingOutlined className={`${props.classType}-image`} />} className={`${props.classType}-icon`} />
					</div>
				)}
			</div>
		</div>
	);
}
