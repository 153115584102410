import { Row, Col } from 'antd';

export default function Politics() {
	return (
		<div className="ph-politics-contains">
			<div className="ph-politics-header">
				<Row justify="center">
					<Col>
						<h1 className="ph-politics-tittle">POLÍTICAS DE PRIVACIDAD</h1>
					</Col>
				</Row>
			</div>
			<div className="ph-politics-main">
				<Row justify="center">
					<Col>
						<p className="ph-politics-paragraph">
							Farmacias JVF SPA (en adelante la “Empresa”), propietaria de Farmacia JVF, ha puesto a disposición de usted (en adelante también el “Usuario” o
							los “Usuarios”) el sitio web www.farmaciajvf.com (en adelante el Canal Digital de Farmacia JVF), que consiste en una plataforma tecnológica para
							la compraventa o reserva de productos farmacéuticos.
						</p>
						<p className="ph-politics-paragraph">
							La presente Política de Privacidad (en adelante la Política), tiene por finalidad describir el tratamiento de sus datos como resultado de la
							visita a nuestro sitio web y en la utilización de nuestros servicios como resultado de la compraventa o reserva de productos que desde ella se
							comercialicen.
						</p>
						<p className="ph-politics-paragraph">
							La Empresa, domiciliada en Chile, reconoce como legislación aplicable la Ley N°19.628 sobre protección de la vida privada (Ley de datos
							personales) y sus posteriores modificaciones. Se entenderá por datos de carácter personal, o datos personales, los relativos a cualquier
							información concerniente a personas naturales, identificadas o identificables. Estos datos serán recopilados por el sólo hecho que usted ha
							entregado dicha información, como consecuencia del uso, visita y/o suscripción en cualquiera de las plataformas de la Empresa.
						</p>
						<p className="ph-politics-paragraph">
							De los datos recolectados por estas vías, la Empresa otorga protección de sus datos, evitando la divulgación de estos. Para ello, adoptará todos
							los medios técnicos que estén dentro de su alcance para evitar la pérdida, robo, hurto, alteración, acceso no autorizado y/o una apropiación
							indebida de los mismos. Si bien la Empresa realizará sus mejores esfuerzos para que dicha pérdida no ocurra, las medidas técnicas de seguridad que
							se puedan tomar para que esto no ocurra no son infalibles y la Empresa no puede hacerse cargo en caso de que estas medidas de seguridad llegasen a
							fallar.
						</p>
						<p className="ph-politics-paragraph">
							La Empresa puede, de acuerdo con nuestra legislación vigente, efectuar un tratamiento de datos personales, siempre que lo haga de manera
							razonable. Lo anterior, puede considerar la recopilación interna y el análisis de datos para prestar un mejor servicio al cliente, buscar
							programas de beneficios para los Usuarios o simplemente para fines estadísticos. En todo caso, el tratamiento que se le dará a los datos, impide
							que éstos puedan ser asociados a una persona determinada.
						</p>
						<p className="ph-politics-paragraph">
							La Empresa reconoce que, dentro de los datos entregados por los Usuarios, existen algunos de ellos más sensibles, como por ejemplo aquellos que se
							refieren a las características físicas o morales de las personas o a hechos o circunstancias de su vida privada o intimidad, los estados de salud
							físicos o psíquicos y la vida sexual. Sobre ellos, la Empresa no realizará tratamiento alguno, a menos que el propio Usuario lo autorice de manera
							expresa o nuestra legislación así lo permita.
						</p>
						<p className="ph-politics-paragraph">
							Se reconoce como una práctica frecuente, la utilización de cookies y/o herramientas para analíticas de información. Las cookies y/o herramientas
							para analíticas son archivos que se transfieren desde un sitio web al disco duro del computador del Usuario, con el fin de que el Usuario, al
							ingresar nuevamente al sitio Web, éste lo reconozca.
						</p>
						<p className="ph-politics-paragraph">
							La Empresa, de acuerdo con sus Términos y Condiciones, establece que el servicio de Canal Digital de Farmacia JVF, solamente puede ser utilizado
							por mayores de 18 años de edad que hayan aceptado íntegramente los Términos y Condiciones. El Usuario que sea menor de edad, pero que cuente con
							la autorización de sus padres o de su representante legal, podrá utilizar los servicios, siempre y cuando sus padres o representante legal, hayan
							aceptado íntegramente los presentes Términos y Condiciones. En consecuencia, la Empresa no tiene por finalidad recopilar o recolectar información
							relacionada con menores de edad y, en caso de que la información proporcionada por el Usuario a la Empresa no sea correcta, puede solicitar su
							eliminación al siguiente correo electrónico contactofarmaciajvf@gmail.com, eximiendo a la Empresa de cualquier responsabilidad por su uso. Será
							responsabilidad de los padres, tutores o representantes, la autorización de la información que los menores de edad, que se encuentren bajo su
							responsabilidad, proporcionen a la Empresa.
						</p>
						<p className="ph-politics-paragraph">
							Al momento de ingresar al Canal Digital de la Empresa, usted acepta que ésta no se hará responsable de la información entregada por el Usuario a
							terceros por medio de links alojados en nuestro Sitio Web.
						</p>
					</Col>
				</Row>
			</div>
		</div>
	);
}
