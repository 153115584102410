/** @format */

import axios from 'axios'

import Notify from '../../../../components/Commons/Notify'

import {
	ENV_CALCULATION,
	ENV_COUNTRY,
	ENV_ROOT_PHARMACY,
} from '../../../../components/Commons/Hooks/Variables/Enviroment'

export async function Calculation(item, data) {
	item = {
		...item,
		...data,
	}

	let returnResponse
	let location = JSON.parse(localStorage.getItem('locationStore'))

	const info = {
		products: [],
		lat: location.lat,
		lng: location.lng,
		type_retirement: item.despatch,
		rut: '',
		email: localStorage.getItem('email'),
		token_auth: localStorage.getItem('userToken'),
		code_voucher: null,
		country: ENV_COUNTRY,
		locals: [],
		root_pharmacy: ENV_ROOT_PHARMACY,
		services: [item],
		address: location.address,
	}

	await axios({
		method: 'POST',
		url: `${ENV_CALCULATION}/`,
		data: info,
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data
			} else if (response.data.statusCode === 409) {
				Notify({
					title: `Error Servicio ${response.data.statusCode}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Problemas con el servicio`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				})
			} else if (response.data.statusCode === 400) {
				Notify({
					title: `${response.data.error} - ${response.data.statusCode}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `${response.data.message}`,
					time: 6,
					image: 'error',
					placement: 'topRight',
				})
			} else {
				Notify({
					title: `¡Error al Calcular tus productos! - (${response.data.error})`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Algo pasó, te pedimos intentar nuevamente. Si el problema persiste, escríbenos al chat. - (${response.data.message})`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				})
			}
		})
		.catch(() => {
			Notify({
				title: `Error de conexión`,
				colorTitle: 'ph-main-notification-error-title',
				description: 'Revisa tu conexión de internet..',
				time: 3,
				image: 'error',
				placement: 'topRight',
			})
		})
	return returnResponse
}
