/** @format */

import axios from 'axios';

import Notify from '../../../../components/Commons/Notify';

import { ENV_CALCULATION, ENV_COUNTRY, ENV_ROOT_PHARMACY } from '../../../../components/Commons/Hooks/Variables/Enviroment';

export async function Calculation(cart, location, email, userToken, voucherPharmacies, locals, typeRetirement, userData, saveCart, countPharmacies) {
	let data = {
		products: cart,
		lat: location.lat,
		lng: location.lng,
		type_retirement: typeRetirement,
		rut: userData?.rut,
		email: email,
		token_auth: userToken,
		code_voucher: null,
		voucher_pharmacy: voucherPharmacies,
		locals: locals,
		country: ENV_COUNTRY,
		root_pharmacy: ENV_ROOT_PHARMACY,
		address: location.address,
		count_locals: countPharmacies,
	};
	let returnResponse;
	await axios({
		method: 'POST',
		url: `${ENV_CALCULATION}/`,
		data: data,
		params: {
			save_car: saveCart,
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data;
			} else if (response.data.statusCode === 409) {
				Notify({
					title: `Error Servicio ${response.data.statusCode}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Problemas con el servicio`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			}
		})
		.catch(() => {
			Notify({
				title: `Error de conexión`,
				colorTitle: 'ph-main-notification-error-title',
				description: 'Revisa tu conexión de internet..',
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
}
