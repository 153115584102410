/** @format */

import React, { Fragment, useState, useEffect } from 'react';
import { useGlobal } from 'reactn';

import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';

import Images from '../../../../components/Commons/Images';
import Spacer from '../../../../components/Commons/Spacer';
import { formatPrice } from '../../../../components/Commons/Hooks/Functions/FormatPrice';
import { ENV_IMG_HEADER } from '../../../../components/Commons/Hooks/Variables/Enviroment';
import DescriptionPrices from './components/DescriptionPrices';

import './style.scss';

export default function PaymentDetail(props) {
	const { Panel } = Collapse;
	const [isPaymentExcedent] = useGlobal('paymentExcedent');
	const [isVoucher] = useState(localStorage.getItem('voucherCode'));
	const [isDeliveryPharmacies, setDeliveryPharmacies] = useState(JSON.parse(localStorage.getItem('retirementData')));
	const [isDeliveryPrices] = useGlobal('deliveryPrices');

	const handleTotal = (item) => {
		if (item) {
			const delivery = item?.locals_group.map((element) => element.payment.delivery).reduce((prev, curr) => prev + curr, 0);
			const voucher = item?.locals_group.map((element) => element.payment.discount_voucher).reduce((prev, curr) => prev + curr, 0);
			const discount = item?.locals_group.map((element) => element.payment.discount).reduce((prev, curr) => prev + curr, 0);
			const pbm = item?.locals_group.map((element) => element.payment.discount_pbm).reduce((prev, curr) => prev + curr, 0);
			const locals_group = item?.locals_group.map((element) => {
				return element.products.map((e) => e).reduce((sum, item) => sum + item.price * item.quantity, 0);
			});
			const arrayTotal = locals_group.map((element) => element).reduce((prev, curr) => prev + curr, 0);
			const total = arrayTotal + delivery + pbm - voucher + discount;
			return total;
		}
	};

	const handlePaytmentExedent = () => {
		if (isPaymentExcedent) {
			return formatPrice(parseFloat(props.item.total) - isPaymentExcedent.amountAuthorized);
		} else {
			return formatPrice(props.item.total);
		}
	};

	const handleDeliveryPharmacy = (item, data) => {
		return data;
	};

	const handleTotalDelivery = (item) => {
		const delivery = item?.locals_group.map((element) => element.payment.delivery).reduce((prev, curr) => prev + curr, 0);
		return delivery;
	};

	useEffect(() => {
		if (isDeliveryPrices) {
			let deliveryInfo = { ...isDeliveryPharmacies };
			deliveryInfo.locals_group.forEach((element) => {
				if (isDeliveryPrices && isDeliveryPrices.hasOwnProperty(element.name)) {
					element.payment.delivery = isDeliveryPrices[element.name];
				}
			});
			setDeliveryPharmacies({ ...deliveryInfo });
			localStorage.setItem('retirementData', JSON.stringify(deliveryInfo));
		}
	}, [props.item, isDeliveryPrices]); // eslint-disable-line

	return (
		<>
			{props.address && (
				<>
					<li className="ph-checkout-main-box-main-title-container">
						{props.item.type_retirement === 'farmacia' ? (
							<span className="ph-checkout-main-box-main-title">Mi Dirección:</span>
						) : (
							<span className="ph-checkout-main-box-main-title">Dirección de entrega Seleccionada:</span>
						)}
					</li>
					<div className="ph-checkout-main-box-inner-container">
						<Images
							classContent={'ph-checkout-main-box-image-logo-container'}
							classImage={'ph-checkout-main-box-image-logo'}
							webp={`${ENV_IMG_HEADER}/pharol-location.webp`}
							jp2={`${ENV_IMG_HEADER}/pharol-location.jp2`}
							jxr={`${ENV_IMG_HEADER}/pharol-location.jxr`}
							default={`${ENV_IMG_HEADER}/pharol-location.png`}
							title={'address logo'}
						/>
						<h3 className="ph-checkout-main-box-step-title">
							{props.address.street}, {props.address.commune} - {props.address.region}
						</h3>
					</div>
				</>
			)}
			<li className="ph-checkout-main-box-main-title-container">
				<span className="ph-checkout-main-box-main-title">Métodos de entrega Seleccionados:</span>
			</li>
			{isDeliveryPharmacies && isDeliveryPharmacies.locals_group && (
				<div className="ph-checkout-main-box-delivery-pharmacy-main-container">
					{isDeliveryPharmacies.locals_group.map((element, index) => (
						<React.Fragment key={index}>
							<div className="ph-checkout-main-box-delivery-pharmacy-retirement-main-container">
								<h3 className="ph-checkout-main-box-delivery-pharmacy-retirement-title">
									<>{element.type_retirement === 'farmacia' ? 'Retiro en farmacia' : 'Despacho a Domicilio'}</>
								</h3>
							</div>
						</React.Fragment>
					))}
				</div>
			)}
			{props.item && (
				<>
					<div className="ph-checkout-main-box-delivery-container"></div>
					<h1 className="ph-checkout-main-box-price-main-title">Resumen del Pedido</h1>
					<DescriptionPrices title={'Subtotal:'} price={props.item.sub_total} />
					<DescriptionPrices colorDiscount={true} title={'Descuentos:'} price={props.item.total_discount} />
					{props.item.total_services !== 0 && <DescriptionPrices title={'Servicios:'} price={props.item.total_services} />}
					{props.item.total_discount_pbm !== 0 && <DescriptionPrices title={'Descuento PBM:'} price={props.item.total_discount_pbm} />}
					{props.item.total_delivery_original !== 0 && (
						<>
							{isDeliveryPharmacies && (
								<>
									<Collapse
										defaultActiveKey={['1']}
										className="ph-checkout-main-box-price-detail-delivery"
										expandIconPosition={'right'}
										expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
									>
										<Panel header="Costos de entrega (Detalles):" key="1">
											{isDeliveryPharmacies.locals_group.map((item, index) => (
												<Fragment key={index}>
													<div className="ph-checkout-main-box-price-detail-delivery-pharmacy-container">
														<div className="ph-checkout-main-box-price-detail-delivery-pharmacy-inner-container">
															<Images
																classContent={'ph-checkout-main-box-price-detail-delivery-pharmacy-image-container'}
																classImage={'ph-checkout-main-box-price-detail-delivery-pharmacy-image'}
																default={item.image}
																title={item.name_editable}
															/>
															<h4 className="ph-checkout-main-box-price-detail-delivery-pharmacy-title">{item.name_editable}</h4>
														</div>
														<Spacer />
														<h4 className="ph-checkout-main-box-price-detail-delivery-pharmacy-price">
															{formatPrice(handleDeliveryPharmacy(item.name, item.payment.delivery))}
														</h4>
													</div>
												</Fragment>
											))}
										</Panel>
									</Collapse>
								</>
							)}
							<div className="ph-checkout-main-box-price-detail-delivery-pharmacy-total-main-container">
								<hr className="ph-checkout-main-box-price-detail-delivery-pharmacy-separator" />
								<DescriptionPrices
									colorDiscount={true}
									deliveryTotal={true}
									title={'Costos de Entrega (Total):'}
									price={handleTotalDelivery(isDeliveryPharmacies)}
								/>
							</div>
						</>
					)}

					{props.isDiscountApplied && (
						<>
							{props.item.total_delivery_discount_payment_method !== 0 && (
								<DescriptionPrices colorVoucher={true} title={'Descuento (Medio de Pago):'} price={props.item.total_delivery_discount_payment_method} />
							)}
						</>
					)}
					{props.item.total_voucher_discount !== 0 && (
						<DescriptionPrices colorVoucher={true} title={'Total Descuento Cupón:'} price={props.item.total_voucher_discount} />
					)}
					{isPaymentExcedent && <DescriptionPrices colorVoucher={true} title={'Excedentes:'} price={`-${isPaymentExcedent.amountAuthorized}`} />}
					{props.fromWithdrawal ? (
						<div className="ph-checkout-main-box-delivery-price-container">
							<h3 className="ph-cart-checkout-box-total-price">
								Total:
								<Spacer />
								<span className="ph-cart-checkout-box-total-price-info">{formatPrice(handleTotal(isDeliveryPharmacies))}</span>
							</h3>
						</div>
					) : (
						<div className="ph-checkout-main-box-delivery-price-container">
							<h3 className="ph-cart-checkout-box-total-price">
								Total:
								<Spacer />
								<span className="ph-cart-checkout-box-total-price-info">{handlePaytmentExedent()}</span>
							</h3>
						</div>
					)}
					{isVoucher && props.item.type_calculation !== 'service' && (
						<>
							<h4 className="ph-cart-checkout-box-voucher-main-title">Cupón activo:</h4>
							<div className="ph-cart-checkout-box-voucher-container">
								<Images
									classContent={'ph-cart-checkout-box-voucher-image-container'}
									classImage={'ph-cart-checkout-box-voucher-image'}
									default={`https://storage.googleapis.com/master-image-bucket/giftcard/Cup%C3%B3n%20Pharol_Mesa%20de%20trabajo%201%20copia%202.png`}
									title={'Cupon'}
								/>
								<span className="ph-cart-checkout-box-voucher-title">{isVoucher}</span>
							</div>
						</>
					)}
				</>
			)}
		</>
	);
}
