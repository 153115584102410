/** @format */

import React from 'react'

import { ENV_COUNTRY } from '../../../../../../../../../../components/Commons/Hooks/Variables/Enviroment'

import './style.scss'

const AgreementPromotionOrPharolPrice = (props) => {
	if (props.pharolPrice) return <>{`Precio ${ENV_COUNTRY === 'AR' ? "Benvida" : "Pharol"}`}</>
	else if (props.agreements?.length > 0)
		return <>{props.agreements.length} Beneficio(s) por convenio</>
	else
		return <>{props.promotions?.percentaje_promotion || props.promotions?.description_promotion}</>
}

export default function QuotePharmaciesStock(props) {
	return (
		<>
			<div className='ph-product-detail-quote-main-km-agreement-mobile-container'>
				<div className='ph-product-detail-quote-main-km-mobile'>{props.km}</div>
				<div className='ph-product-detail-quote-main-agreement-mobile'>
					<AgreementPromotionOrPharolPrice {...props} />
				</div>
			</div>
			<div className='ph-product-detail-quote-main-title-stock-container'>
				<h3
					className={`ph-product-detail-quote-main-title-stock-description ph-product-detail-quote-main-title-stock-${
						props.stock > 3 ? (ENV_COUNTRY === 'AR' ? 'benvida-green' :'green') : props.stock <= 3 && props.stock > 0 ? (ENV_COUNTRY === 'AR' ? 'pink' : 'orange') : 'gray'
					}`}>
					{props.stock > 3 && <>Stock disponible</>}
					{props.stock <= 3 && props.stock > 0 && <>Pocas Unidades</>}
					{props.stock <= 0 && <>Sin stock</>}
				</h3>
				{/* <div className='ph-product-detail-quote-main-desc'>
					{(props.dispatch && ENV_COUNTRY !== 'AR')&&<>Envío a domicilio /</>} Retiro en farmacia
				</div> */}
				<span className='ph-product-detail-quote-main-price-title-mobile'>Desde</span>{' '}
				<span className='ph-product-detail-quote-main-price-mobile'>${props.priceForm}</span>
			</div>
		</>
	)
}
