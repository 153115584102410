/** @format */

import React from 'react'

import { formatPrice } from '../../../../../../../../components/Commons/Hooks/Functions/FormatPrice'

import './style.scss'

export default function PriceProduct(props) {
	return (
		<>
			{props.item.interface.promotions ? (
				<>
					<p
						className={
							props.item.interface.promotions.price_promotion
								? 'ph-product-detail-price-before-symbol'
								: 'ph-product-detail-price-normal-symbol'
						}>
						$
					</p>
					<p
						className={
							props.item.interface.promotions.price_promotion
								? 'ph-product-detail-price-before'
								: 'ph-product-detail-price-normal'
						}>
						{formatPrice(props.item.price)}
					</p>
				</>
			) : (
				<>
					<p className='ph-product-detail-price-normal-symbol'>$</p>
					<p className='ph-product-detail-price-normal'>{formatPrice(props.item.price)}</p>
				</>
			)}
		</>
	)
}
