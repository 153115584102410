import { AxiosAdapter } from '../../../../_config/v2/adapters/axios/axios.adapter';
import { Http } from '../../../../_config/v2/adapters/axios/axios.protocols';
import { Enviroment } from '../../../../_config/constants/enviroment-v2.constant';

export class Service {
	async getUser(token) {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${Enviroment.API_PHARMACY}/user/get-by-email`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}
	async updateUser(body, token) {
		return new AxiosAdapter({
			method: Http.PUT,
			url: `${Enviroment.API_PHARMACY}/user/update-me`,
			data: body,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}
}
