/** @format */

import L from 'leaflet'
import { createControlComponent } from '@react-leaflet/core'
import 'leaflet-routing-machine'

import './style.scss'

const createRoutineMachineLayer = (props) => {
	const instance = L.Routing.control({
		//serviceUrl: 'http://my-osrm/route/v1',
		router: L.Routing.mapbox(
			'pk.eyJ1IjoiYXJraGFsZW0iLCJhIjoiY2t2bDVheGtuMGhkYzJ1cW42OTl5Nm1vbyJ9.mL490f6gLtICsQSl2mY3Gw'
		),
		waypoints: [
			L.latLng(props.pharmacyLocation.lat, props.pharmacyLocation.lng),
			L.latLng(props.userLocation.lat, props.userLocation.lng),
		],
		/* 		lineOptions: {
			styles: [{ color: '#d7141f', weight: 3 }],
		}, */
		show: true,
		addWaypoints: true,
		routeWhileDragging: true,
		draggableWaypoints: true,
		fitSelectedRoutes: true,
		showAlternatives: false,
	})

	return instance
}

const RoutingMachine = createControlComponent(createRoutineMachineLayer)

export default RoutingMachine
