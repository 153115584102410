/** @format */

import React from 'react'

import './style.scss'

export default function ErrorService() {
	return (
		<div className='ph-profile-recipes-error-main-container'>
			<div className='ph-profile-recipes-error-container'>
				<div>
					<h3 className='ph-profile-recipes-error-title'>¡Ha ocurrido un error!</h3>
					<h3 className='ph-profile-recipes-error-subtitle'>Comuníquese con nuestro personal....</h3>
				</div>
			</div>
		</div>
	)
}
