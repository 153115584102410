/** @format */

import React from 'react';

import { formatPrice } from '../../../../../../components/Commons/Hooks/Functions/FormatPrice';

import { ENV_COUNTRY } from '../../../../../../components/Commons/Hooks/Variables/Enviroment';
import Info from '../components/Info';
import { checkUnit } from './handle/check-unit.handle';

export default function ProductDescription(props) {
	console.log('[PROPS DESCRIPTION', props);
	return (
		<div>
			<Info title={'Categoría'} description={props.item.master_category} />
			<Info title={'Laboratorio'} description={props.item.laboratory} />
			<Info title={'Código'} description={props.item.code_internal} />
			{props.item.price_per_quantity && (
				<Info
					title={`Fraccionado por ${props.item.unit_of_measurement}`}
					description={`${formatPrice(checkUnit(props?.item))}`}
				/>
			)}
			{props.item.interface && (
				<>
					{props.item.interface.promotions && (
						<>
							<Info title={'Promoción'} description={props.item.interface.promotions.title} />
							<Info
								title={'Promoción Válida'}
								description={`Desde el ${props.item.interface.promotions.date_start} al ${props.item.interface.promotions.date_end}`}
							/>
						</>
					)}
				</>
			)}
			{ENV_COUNTRY !== 'AR' && props.item.code_isp !== ' ' && <Info title={'Registro ISP'} description={props.item.code_isp} />}
			{props.item.principe_active && <Info title={'Principio activo'} description={props.item.principe_active} />}
			{props.item.shape_pharmacy && <Info title={'Forma Farmacéutica'} description={props.item.shape_pharmacy} />}
			{props.item.concentration !== ' ' && <Info title={'Concentración'} description={props.item.concentration} />}
			{props.item.unit_of_measurement !== ' ' && <Info title={'Unidad de medida'} description={props.item.unit_of_measurement} />}
			{props.item.indication !== ' ' && <Info title={'Indicaciones'} description={props.item.indication} />}
			{props.item.side_effects !== ' ' && <Info title={'Efectos Secundarios'} description={props.item.side_effects} />}
			{props.item.storage_conditions !== ' ' && <Info title={'Condiciones de almacenaje'} description={props.item.storage_conditions} />}
		</div>
	);
}
