/** @format */

import React, { useState } from 'react'

import { Row, Col, Button, Modal } from 'antd'

import { FilePdfOutlined, CloudUploadOutlined } from '@ant-design/icons'

import { GetRecipes } from '../../../../../../../../components/Commons/Hooks/Functions/GetRecipes'

import './style.scss'

export default function BagRecipeList(props) {
	const [isRecipeList, setRecipeList] = useState(null)
	const [isVisible, setVisible] = useState(false)
	const [isLoading, setLoading] = useState(false)
	const [isPreviewModal, setPreviewModal] = useState({ visible: false, image: null })

	const handleCloseModal = () => {
		setVisible(false)
	}

	const handleRecipeList = async () => {
		if (!isRecipeList) {
			setLoading(true)
			await GetRecipes().then((response) => {
				if (response) {
					setVisible(true)
					setRecipeList(response)
					setLoading(false)
				}
			})
		} else {
			setVisible(true)
		}
	}

	const handleAddListRecipes = (item) => {
		let products = [...props.filteredProductsRecipe]
		products.forEach((element) => {
			if (element.objectID) {
				if (element.objectID === props.item.objectID) {
					element.recipe = item.recipe
					element.recipe_save = true
					delete element.recipe_will_save
				}
			} else {
				if (element.id === props.item.id) {
					element.recipe = item.recipe
					element.recipe_save = true
					delete element.recipe_will_save
				}
			}
		})
		props.handlefilteredProducts(products)
		setVisible(false)
	}

	if (
		(!localStorage.getItem('userEmail') && !localStorage.getItem('userToken')) ||
		props.item.physical_presentation
	)
		return ''

	return (
		<>
			<div className='ph-bag-modal-recipe-list-recipes-modal-button-container'>
				<Button
					loading={isLoading}
					icon={
						<CloudUploadOutlined className='ph-bag-modal-recipe-list-recipes-modal-button-icon' />
					}
					className='ph-bag-modal-recipe-list-recipes-modal-button'
					onClick={() => handleRecipeList()}>
					<span
						className={`${
							isLoading ? 'ph-bag-modal-recipe-list-recipes-modal-button-title-load' : ''
						} ph-bag-modal-recipe-list-recipes-modal-button-title`}>
						Recetas Guardadas
					</span>
				</Button>
			</div>
			<Modal
				wrapClassName='ph-bag-modal-recipe-list-recipes-modal-container'
				title='Gestión de Recetas'
				visible={isVisible}
				onCancel={() => handleCloseModal()}
				onOk={''}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
				width='500px'>
				<h3 className='ph-bag-modal-recipe-list-recipes-modal-title'>
					Selecciona una receta de la lista:
				</h3>
				{isRecipeList?.data?.length > 0 ? (
					<>
						<div className='ph-bag-modal-recipe-list-recipes-list-main-container'>
							{isRecipeList?.data?.map((item) => (
								<div
									className='ph-bag-modal-recipe-list-recipes-list-container'
									key={item.recipe_id}>
									<Row>
										<Col
											span={14}
											className='ph-bag-modal-recipe-list-recipes-list-title-container'>
											<h4 className='ph-bag-modal-recipe-list-recipes-list-title'>
												NOMBRE:
											</h4>
											<h3 className='ph-bag-modal-recipe-list-recipes-list-subtitle'>
												{item.name}
											</h3>
										</Col>
										<Col
											span={5}
											className='ph-bag-modal-recipe-list-recipes-list-button-container'>
											<Button
												className='ph-bag-modal-recipe-list-recipes-list-second-button'
												onClick={() =>
													setPreviewModal({ visible: true, image: item.recipe })
												}>
												<h3 className='ph-bag-modal-recipe-list-recipes-list-button-second-title'>
													Ver Receta
												</h3>
											</Button>
										</Col>
										<Col
											span={5}
											className='ph-bag-modal-recipe-list-recipes-list-button-container'>
											<Button
												className='ph-bag-modal-recipe-list-recipes-list-button'
												onClick={() => handleAddListRecipes(item)}>
												<h3 className='ph-bag-modal-recipe-list-recipes-list-button-title'>
													Agregar
												</h3>
											</Button>
										</Col>
									</Row>
								</div>
							))}
						</div>
					</>
				) : (
					<div className='ph-bag-modal-recipe-list-recipes-list-no-data-container'>
						<h3 className='ph-bag-modal-recipe-list-recipes-list-no-data-title'>
							no se encontraron recetas
						</h3>
					</div>
				)}
			</Modal>
			<Modal
				wrapClassName='ph-checkout-receipt-modal-container'
				visible={isPreviewModal.visible}
				title={'Vista Previa:'}
				footer={null}
				onCancel={() => setPreviewModal({ visible: false, image: null })}>
				{isPreviewModal?.image && (
					<>
						{isPreviewModal.image.endsWith('.pdf') ? (
							<>
								<div className='ph-bag-modal-recipe-list-modal-pdf-container'>
									<FilePdfOutlined className='ph-bag-modal-recipe-list-modal-pdf-icon' />
								</div>
								<a href={isPreviewModal.image} target='_blank' rel='noreferrer'>
									{isPreviewModal.image}
								</a>
							</>
						) : (
							<img alt='example' style={{ width: '100%' }} src={isPreviewModal.image} />
						)}
					</>
				)}
			</Modal>
		</>
	)
}
