/** @format */

import React from 'react'

import { Spin } from 'antd'

import { LoadingOutlined } from '@ant-design/icons'

import './style.scss'

export default function LoadingPharmacies() {
	return (
		<div className='ph-loginpharmacies-loading-container'>
			<Spin
				indicator={
					<LoadingOutlined className='ph-deatail-pharmacy-product-list-loader-container-image' />
				}
				className='ph-deatail-pharmacy-product-list-loader-container'
			/>
		</div>
	)
}
