/** @format */

import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { Row, Col, Form, Checkbox, Button } from 'antd';

import { FilePdfOutlined } from '@ant-design/icons';

import Notify from '../../../../components/Commons/Notify';
import Images from '../../../../components/Commons/Images';
import Loading from '../../../../components/Commons/Loading';

import { ENV_IMG } from '../../../../components/Commons/Hooks/Variables/Enviroment';

import { FilterProductsRecipe } from '../../Hooks/Functions/FilterProductsRecipe';

import TitleBox from '../../../../components/TitleBox';
import Stepper from '../../../../components/Steper';
import Countdown from '../../../../components/Countdown';

import PaymentDetail from '../../components/PaymentDetail';
import CardOnDemand from '../../components/CardOnDemand';
import LoadingService from '../../components/LoadingService';

import UploadImage from '../../../../components/HooksCheckout/components/UploadImage';
import { GetOrder } from '../../../../components/Commons/Hooks/Functions/GetOrder';
import { SaveMultipleRecipes } from '../../../../components/Commons/Hooks/Functions/SaveRecipes';

import ButtonRecipe from './components/ButtonRecipe';
import RecipeImage from './components/RecipeImage';
import TypeReceipt from './components/TypeReceipt';
import RecipeList from './components/RecipeList';

import { serviceUploadImage } from './services';

import './style.scss';

export default function Prescription() {
	const history = useHistory();
	const [form] = Form.useForm();
	const [isUserAddress] = useState(JSON.parse(localStorage.getItem('locationStore')));
	const [isRecipeData] = useState(JSON.parse(localStorage.getItem('recipeData')));
	const [isFilteredProducsRecipe, setFilteredProducsRecipe] = useState([]);
	const [isRecipeAgreement, setRecipeAgreement] = useState(false);
	const [isMobile, setMobile] = useState(false);
	const [isSeeMore, setSeeMore] = useState([]);
	const [isByPassProducts, setSByPassProducts] = useState([]);
	const [isVisible, setVisible] = useState(false);
	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		if (isRecipeData) {
			let filterProducts = FilterProductsRecipe(isRecipeData.products);
			setFilteredProducsRecipe(filterProducts);
			const recipeAgreement = filterProducts.some((item) => {
				return item.prescription_type !== 'WP';
			});
			form.setFieldsValue({ recipe_agreement: !recipeAgreement });
			setRecipeAgreement(recipeAgreement);
		} else {
			window.location.href = '/';
		}
		if (window.innerWidth < 992) {
			setMobile(true);
		}
	}, [isRecipeData, form]);

	const handlePhysicalPresentation = (checkbox, item, index) => {
		isRecipeData.products.forEach((element) => {
			if (element.objectID === item.objectID) {
				element.physical_presentation = checkbox.target.checked;
				element.recipe = 'https://storage.googleapis.com/master-image-bucket/react-checkout/physical_presentation.jpg';
				if (!checkbox.target.checked) {
					delete element.recipe;
				}
			}
		});
		let collapse = [...isByPassProducts];
		const even = (data) => data === index;
		if (!collapse.some(even)) {
			collapse.push(index);
		} else {
			if (collapse.length <= 1) {
				collapse = [];
			} else {
				const i = collapse.findIndex((search) => {
					return search === index;
				});
				collapse.splice(i, 1);
			}
		}
		setSByPassProducts(collapse);
	};

	const handleSubmitRecipe = async () => {
		let recipeDataCopy = JSON.parse(JSON.stringify(isRecipeData));
		var successRecipe = true;
		var successRut = true;
		isFilteredProducsRecipe.map((element) => {
			if (!element.recipe) {
				successRecipe = false;
			}
			if (element.prescription_type === 'RCH' || element.prescription_type === 'RMRCCS') {
				if (!element.rut) {
					successRecipe = false;
				}
			}
			return element;
		});
		if (!successRecipe) {
			Notify({
				title: `Advertencia!`,
				colorTitle: 'ph-main-notification-warning-title',
				description: `Debe subir la receta obligatoriamente.`,
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
			return;
		}
		if (!successRut) {
			Notify({
				title: `Advertencia!`,
				colorTitle: 'ph-main-notification-warning-title',
				description: `Debe subir el RUT obligatoriamente.`,
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
			return;
		}

		const handleFilterSaveRecipe = (item) => {
			const filtered = (data) => {
				if (data.recipe_will_save && !data.physical_presentation) {
					return true;
				}
			};
			return item.filter(filtered);
		};

		setVisible(true);
		setLoading(true);

		await serviceUploadImage(isFilteredProducsRecipe, 'recipe').then(async (response) => {
			if (response) {
				await serviceUploadImage(response, 'rut').then(async (response) => {
					if (response) {
						let recipesWillSave = handleFilterSaveRecipe(response);
						if (recipesWillSave.length > 0) {
							await SaveMultipleRecipes(recipesWillSave);
						}
						response.forEach((item) => {
							delete item.recipe_save;
							delete item.recipe_will_save;
						});
						recipeDataCopy.products = recipeDataCopy.products.map((product) => {
							const item = response.find((final) => final.objectID === product.objectID);
							return item === undefined ? product : item;
						});
						return handleSubmitRetirementService(recipeDataCopy);
					} else {
						setVisible(false);
						setLoading(false);
					}
				});
			} else {
				setVisible(false);
				setLoading(false);
			}
		});
	};

	const handleSaveReceipt = (checkbox, item, index) => {
		isFilteredProducsRecipe.forEach((element) => {
			if (element.objectID === item.objectID && checkbox.target.checked && checkbox.target.name === index) {
				element.recipe_will_save = true;
			} else if (element.objectID === item.objectID && !checkbox.target.checked && checkbox.target.name === index) {
				delete element.recipe_will_save;
			}
		});
	};

	const handleSubmitRetirementService = async (item) => {
		await GetOrder(item).then((response) => {
			setVisible(false);
			setLoading(false);
			if (response) {
				localStorage.setItem('paymentData', JSON.stringify(response));
				return history.push('/checkout/payment');
			}
		});
	};

	const handleAddProductImage = (item, data, imgType) => {
		isRecipeData.products.forEach((element) => {
			if (element.objectID === data.objectID) {
				if (imgType === 'recipe') {
					element.recipe = item;
				}
				if (imgType === 'rut') {
					element.rut = item;
				}
			}
		});
	};

	const handleRemoveProductImage = (item, data) => {
		isRecipeData.products.forEach((element) => {
			if (element.objectID === item.objectID) {
				if (data === 'recipe') {
					delete element.recipe;
				}
				if (data === 'rut') {
					delete element.rut;
				}
			}
		});
	};

	const handleRemoveRecipeSave = (item) => {
		let products = [...isFilteredProducsRecipe];
		isFilteredProducsRecipe.forEach((element) => {
			if (element.objectID === item.objectID) {
				delete element.recipe;
				delete element.recipe_save;
			}
		});
		setFilteredProducsRecipe(products);
	};

	const handleExplainText = (item) => {
		let collapse = [...isSeeMore];
		const even = (element) => element === item;
		if (!collapse.some(even)) {
			collapse.push(item);
		} else {
			if (collapse.length <= 1) {
				collapse = [];
			} else {
				const index = collapse.findIndex((search) => {
					return search === item;
				});
				collapse.splice(index, 1);
			}
		}
		setSeeMore(collapse);
	};

	if (!isRecipeData) {
		return <Loading />;
	} else {
		return (
			<>
				<div className="ph-recipe-global-container">
					<div className="ph-recipe-main-container">
						<Stepper step={2} />
						<div className="ph-prescription-titlebox-countdown-container">
							<TitleBox image={'pharol-search-lupa'} title={'Receta'} checkout={true} />
							<Countdown />
						</div>
						{isFilteredProducsRecipe && (
							<>
								<Form onFinish={handleSubmitRecipe} name="recipe_form" form={form}>
									<Row className="ph-recipe-inner-container">
										<Col xs={24} sm={24} md={24} lg={17} xl={17}>
											{!isMobile && (
												<div className={`${isRecipeAgreement ? '' : 'ph-checkout-recipe-agree-hidden-container'} ph-checkout-recipe-agree-main-container`}>
													<div className="ph-checkout-recipe-agree-container">
														<Form.Item
															name="recipe_agreement"
															valuePropName="checked"
															rules={[
																{
																	validator: (_, value) => (value ? Promise.resolve() : Promise.reject('Acepta las condiciones para continuar.')),
																},
															]}
														>
															<Checkbox>
																<h5 className="ph-checkout-recipe-agree-title">
																	La cantidad de unidades quedará sujeta a lo indicado en la receta médica. La receta será revisada y validada por un químico farmacéutico una vez realizada la compra.
																</h5>
															</Checkbox>
														</Form.Item>
													</div>
												</div>
											)}
											<div className={`${isRecipeAgreement ? '' : 'ph-checkout-recipe-list-hidden-main-container'} ph-checkout-recipe-list-main-container`}>
												{isFilteredProducsRecipe.map((item, index) => (
													<Row className="ph-recipe-list-main-container" key={index}>
														<Col xs={4} sm={4} md={5} lg={5} xl={5}>
															<Images
																classContent={'ph-recipe-product-image-container'}
																classImage={'ph-recipe-product-image'}
																default={item.image}
																alt={item.name_editable}
																title={item.name_editable}
															/>
														</Col>
														<Col xs={20} sm={20} md={19} lg={19} xl={19}>
															<h3 className="ph-checkout-recipe-list-title">{item.editable_name}</h3>
															<h4 className="ph-checkout-recipe-list-laboraroty-title">
																Laboratorio: <span className="ph-checkout-recipe-list-laboraroty-subtitle">{item.laboratory}</span>
															</h4>
															<TypeReceipt item={item} />
														</Col>
														<Col xs={0} sm={0} md={5} lg={5} xl={5}></Col>
														<Col xs={24} sm={24} md={19} lg={19} xl={19}>
															<div>
																{!item.rem_product && !item.interface.on_demand && !item.interface.magistral && !item.interface.minsal && !item.interface.digital && (
																	<>
																		<div className="ph-checkout-recipe-list-upload-image-main-container">
																			<div
																				className={`${
																					isByPassProducts.some((element) => element === index) ? 'ph-checkout-recipe-list-upload-bypass-recipe-image-container-enabled' : ''
																				} ph-checkout-recipe-list-upload-bypass-recipe-image-container`}
																			>
																				<Images
																					classContent={'ph-checkout-recipe-list-upload-bypass-recipe-inner-image-container'}
																					classImage={'ph-checkout-recipe-list-upload-bypass-recipe-inner-image'}
																					webp={`${ENV_IMG}/react-checkout/electronic-prescription/receta-medica-icon.webp`}
																					jp2={`${ENV_IMG}/react-checkout/electronic-prescription/receta-medica-icon.jp2`}
																					jxr={`${ENV_IMG}/react-checkout/electronic-prescription/receta-medica-icon.jxr`}
																					default={`${ENV_IMG}/react-checkout/electronic-prescription/receta-medica-icon.png`}
																					title={'Prescription Image'}
																				/>
																				<h4 className="ph-checkout-recipe-list-upload-bypass-recipe-subtitle">¡Podrás continuar con tu pago sin problemas!</h4>
																				<h4 className="ph-checkout-recipe-list-upload-bypass-recipe-description">
																					Un químico farmacéutico se pondrá en contacto contigo para una dispensación segura.
																				</h4>
																			</div>
																			<div>
																				{item.recipe_save ? (
																					<Row className="ph-checkout-recipe-will-save-title-container">
																						<Col xs={24} sm={12} md={12} lg={12} xl={12} className="ph-checkout-recipe-will-save-title-inner-container">
																							{item.recipe.endsWith('.pdf') ? (
																								<div className="ph-checkout-receipt-modal-added-pdf-main-container">
																									<div className="ph-checkout-receipt-modal-added-pdf-container">
																										<FilePdfOutlined className="ph-checkout-receipt-modal-added-pdf-icon" />
																									</div>
																									<a className="ph-checkout-receipt-modal-added-pdf-link" href={item.recipe} target="_blank" rel="noreferrer">
																										Ver Receta
																									</a>
																								</div>
																							) : (
																								<Images
																									classContent={'ph-checkout-recipe-will-save-title-image-container'}
																									classImage={'ph-checkout-recipe-will-save-title-image'}
																									default={item.recipe}
																									alt={'save recipe'}
																									title={'recipe save'}
																								/>
																							)}
																						</Col>
																						<Col xs={24} sm={12} md={12} lg={12} xl={12} className="ph-checkout-recipe-will-save-title-inner-container">
																							<Button className="ph-checkout-recipe-will-save-title-button" onClick={() => handleRemoveRecipeSave(item)}>
																								Eliminar
																							</Button>
																						</Col>
																					</Row>
																				) : (
																					<div>
																						<RecipeList filteredProductsRecipe={isFilteredProducsRecipe} item={item} handlefilteredProducts={(data) => setFilteredProducsRecipe(data)} />
																						<UploadImage
																							type={'recipe'}
																							title={'Receta'}
																							addProductImage={(data) => handleAddProductImage(data, item, 'recipe')}
																							removeProductImage={() => handleRemoveProductImage(item, 'recipe')}
																						/>
																						{localStorage.getItem('userEmail') && localStorage.getItem('userToken') && (
																							<div className="ph-checkout-recipe-agree-title-wp-main-container">
																								<Checkbox
																									name={index}
																									className="ph-checkout-recipe-agree-title-wp-container"
																									onChange={(checkbox) => {
																										handleSaveReceipt(checkbox, item, index);
																									}}
																								></Checkbox>
																								<div>
																									<h5 className="ph-checkout-recipe-will-save-title">
																										Guardar <span className="ph-checkout-recipe-will-save-subtitle">Receta Médica</span> para una próxima compra
																									</h5>
																								</div>
																							</div>
																						)}
																					</div>
																				)}
																			</div>
																		</div>
																		{(item.prescription_type === 'RCH' || item.prescription_type === 'RMRCCS') && (
																			<UploadImage
																				type={'rut'}
																				title={'RUT'}
																				addProductImage={(data) => handleAddProductImage(data, item, 'rut')}
																				removeProductImage={() => handleRemoveProductImage(item, 'rut')}
																			/>
																		)}
																	</>
																)}
																{(item.rem_product || item.interface.on_demand || item.interface.magistral || item.interface.minsal || item.interface.digital) && <RecipeImage item={item} />}
																{item.prescription_type === 'WP' && (
																	<>
																		{!item.rem_product && !item.interface.on_demand && !item.interface.magistral && !item.interface.minsal && !item.interface.digital && (
																			<>
																				{!item.recipe_save && (
																					<div className="ph-checkout-recipe-agree-title-wp-main-container">
																						<Checkbox
																							className="ph-checkout-recipe-agree-title-wp-container"
																							onChange={(checkbox) => {
																								handlePhysicalPresentation(checkbox, item, index);
																							}}
																						></Checkbox>
																						<div>
																							<h5 className="ph-checkout-recipe-agree-title-wp">
																								¿No tienes una receta? has click y ¡Te ayudamos!{' '}
																								<span onClick={() => handleExplainText(index)} className="ph-checkout-recipe-agree-title-wp-show-more">
																									{isSeeMore.some((element) => element === index) ? 'ver menos...' : 'ver más...'}
																								</span>
																							</h5>
																							<h5
																								className={`${isSeeMore.some((element) => element === index) ? '' : 'ph-checkout-recipe-agree-see-more'} ph-checkout-recipe-agree-title-wp-description`}
																							>
																								<span className="ph-checkout-recipe-agree-title-wp-asterick">*</span>
																								No cuento con una prescripción médica vigente, pero acepto que un Químico Farmacéutico me contacte para realizar la dispensación segura del medicamento
																								de acuerdo las recomendaciones del Instituto de Salud Pública.
																							</h5>
																						</div>
																					</div>
																				)}
																			</>
																		)}
																	</>
																)}
															</div>
														</Col>
													</Row>
												))}
											</div>
										</Col>
										<Col xs={24} sm={24} md={24} lg={7} xl={7} className="ph-prescription-checkout-box-global-container">
											<CardOnDemand />
											<div className="ph-retirement-checkout-box-main-container">
												<PaymentDetail address={isUserAddress} item={isRecipeData} />
												<ButtonRecipe isMobile={isMobile} loading={isLoading} item={isRecipeData} recipeAgreement={isRecipeAgreement} />
											</div>
										</Col>
									</Row>
								</Form>
							</>
						)}
					</div>
				</div>
				<LoadingService visible={isVisible} />
			</>
		);
	}
}
