import { Enviroment } from '../../constants/enviroment-v2.constant';
export const ResponseIpAdapter = (response) => {
	const splitAddress = Enviroment.APP_ADDRESS.split(',');
	console.log('split = ', splitAddress[0], splitAddress[1], splitAddress[2], splitAddress[3]);
	return {
		address: `${splitAddress[0]}, ${splitAddress[1]}, ${splitAddress[2]}`,
		commune: splitAddress[1],
		region: splitAddress[2],
		street: splitAddress[0],
		lat: parseFloat(splitAddress[3]),
		lng: parseFloat(splitAddress[4]),
		defatul: true,
	};

	// return {
	// 	address: `${response.regionName}, ${response.city}, ${response.country}`,
	// 	region: response.regionName,
	// 	commune: response.city,
	// 	lat: response.lat,
	// 	lng: response.lon,
	// };
};
