export const imagePrescription = {
	AR: {
		NP: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Venta%20directa.png',
		WP: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Receta%20me%CC%81dica.png',
		RMRSCS: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Receta%20retenido%20sin%20control%20de%20stock.png',
	},
	CL: {
		NP: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Venta%20directa.png',
		MRE: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Receta%20me%CC%81dica.png',
		APR: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Receta%20me%CC%81dica.png',
		WP: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Receta%20me%CC%81dica.png',
		RMRSCS: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Receta%20retenido%20sin%20control%20de%20stock.png',
		RMRCCS: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Receta%20me%CC%81dica%20retenida.png',
	},
};
