/** @format */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import { Modal, Radio } from 'antd';
import moment from 'moment';
import CustomButton from '../../../v2-components/Antd/Button';

export default function ExtendStopwatch(props) {
	let history = useHistory();
	const [newTime, setNewTime] = useState(2);
	const [modalStopwatch, setModalStopwatch] = useState(30);

	const handleCancelTime = () => {
		props.setModal(false);
		if (props.service) window.location.href = `/${props.service.provider}/${props.service.slug}`;
		else window.location.href = '/cart';
	};

	useEffect(() => {
		let modalInterval = null;
		let modalTime = 30;
		if (props.modal === true) {
			modalInterval = setInterval(() => {
				setModalStopwatch((previous) => previous - 1);
				modalTime--;
				if (modalTime <= 0) {
					if (props.service) {
						history.push(`/${props.service.provider}/${props.service.slug}`);
					} else {
						history.push('/cart');
					}
				}
			}, 1000);
		}
		return () => {
			clearInterval(modalInterval);
		};
	}, [props.modal, history, props.service]);

	return (
		<Modal
			visible={props.modal}
			wrapClassName="ph-retirement-personal-data-facturation-modal-container"
			title={<h1 className="ph-retirement-personal-data-facturation-title">¿Desea extender el tiempo?</h1>}
			footer={[
				<CustomButton
					key="submit"
					className={'button-primary'}
					onClick={() => {
						setModalStopwatch(30);
						props.setStorageTime(moment().add(newTime, 'minutes'));
						localStorage.setItem('datePay', JSON.stringify(moment().add(newTime, 'minutes')));
						props.setModal(false);
					}}
				>
					Aceptar
				</CustomButton>,
			]}
			onCancel={() => handleCancelTime()}
		>
			<p>
				Los cinco minutos de compra se han agotado. Por favor selecciona los minutos a extender dentro de{' '}
				{modalStopwatch.toString().length === 2 ? modalStopwatch : <span>0{modalStopwatch}</span>} segundos:
			</p>
			<div style={{ textAlign: 'center' }}>
				<Radio.Group
					onChange={(i) => {
						setNewTime(i.target.value);
					}}
					value={newTime}
				>
					<Radio value={2}>2:00</Radio>
					<Radio value={3}>3:00</Radio>
					<Radio value={5}>5:00</Radio>
				</Radio.Group>
			</div>
		</Modal>
	);
}
