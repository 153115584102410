/** @format */

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useParams, Link } from 'react-router-dom';

import { Form, Input, Button, Spin } from 'antd';

import { StarFilled, LoadingOutlined } from '@ant-design/icons';

import { ENV_IMG } from '../../components/Commons/Hooks/Variables/Enviroment';

import Notify from '../../components/Commons/Notify';
import Images from '../../components/Commons/Images';
import MetaDescription from '../../components/Commons/MetaDescription';

import { SendReview, CheckReview } from './services';

import './style.scss';

export default function ReviewRequest() {
	const { t } = useTranslation('global');

	const params = useParams();
	const [formReview] = Form.useForm();
	const { TextArea } = Input;

	const [isStars, setStars] = useState([false, false, false, false, false]);
	const [isKey, setKey] = useState(0);
	const [isLoading, setLoading] = useState(false);
	const [isSuccess, setSuccess] = useState(false);

	const [isData, setData] = useState(null);
	const [isService, setService] = useState(true);

	const handleClickStars = (item) => {
		if (!isSuccess) {
			item = item + 1;
			setKey(item);
			if (item === 1) setStars([true, false, false, false, false]);
			else if (item === 2) setStars([true, true, false, false, false]);
			else if (item === 3) setStars([true, true, true, false, false]);
			else if (item === 4) setStars([true, true, true, true, false]);
			else if (item === 5) setStars([true, true, true, true, true]);
		}
	};

	const handleSendReview = async (item) => {
		if (isKey === 0) {
			Notify({
				title: `Advertencia`,
				colorTitle: 'ph-main-notification-error-title',
				description: `Selecciona la cantidad de estrellas a calificar...`,
				time: 5,
				image: 'error',
				placement: 'topRight',
			});
			return;
		}
		setLoading(true);
		const data = {
			key: params.key,
			stars: isKey,
			comment: item.comment ? item.comment : '',
		};
		await SendReview(data).then((response) => {
			if (response) {
				setSuccess(true);
			}
		});
		setLoading(false);
	};

	useEffect(() => {
		CheckReview(params.key).then((response) => {
			if (response) {
				setData(response);
			} else {
				setService(false);
			}
		});
	}, [params.key]);

	if (!isService) {
		return (
			<>
				<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
				<div className="ph-review-request-global-container">
					<div className="ph-review-request-main-container">
						<div>
							<div className="ph-review-request-success-thanks-container">
								<h3 className="ph-review-request-success-thanks-title">Error en servicio</h3>
							</div>
							<div className="ph-review-request-success-thanks-link-container">
								<Link className="ph-review-request-success-thanks-link" to="/">
									Volver al inicio
								</Link>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			<div className="ph-review-request-global-container">
				<div className="ph-review-request-main-container">
					<div>
						<div className="ph-review-request-container">
							<Images
								classContent={'ph-review-request-image-container'}
								classImage={'ph-review-request-image'}
								default={`${ENV_IMG}/pharol-go/pharol-go-motorcycle.png`}
								title={'go review'}
								alt={'go review'}
							/>
						</div>
						<h2 className="ph-review-request-main-title">¡Gracias por preferirnos!</h2>
						{isData ? (
							<>
								{isData.is_calificated ? (
									<>
										<div className="ph-review-request-success-thanks-container">
											<h3 className="ph-review-request-success-thanks-title">Este servicio ya fue calificado</h3>
										</div>
										<div className="ph-review-request-success-thanks-link-container">
											<Link className="ph-review-request-success-thanks-link" to="/">
												Volver al inicio
											</Link>
										</div>
									</>
								) : (
									<>
										<h3 className="ph-review-request-main-subtitle">¿Cómo ha sido tu experiencia del servicio de delivery?</h3>
										<div className="ph-review-request-stars-container">
											{isStars.map((item, index) => (
												<StarFilled className={`${item ? 'ph-review-request-stars-filled' : 'ph-review-request-stars-no-filled'}`} onClick={() => handleClickStars(index)} key={index} />
											))}
										</div>
										{!isSuccess ? (
											<>
												<h3 className="ph-review-request-main-description">(da click a la cantidad de estrellas)</h3>
												<Form onFinish={handleSendReview} form={formReview}>
													<Form.Item className="ph-review-request-text-input-container" name="comment">
														<TextArea className="ph-review-request-text-input" placeholder="Escribe tu opinión (opcional)" rows={4} />
													</Form.Item>
													<div className="ph-review-request-success-button-container">
														<Button className="ph-review-request-success-button" htmlType="submit" loading={isLoading}>
															FINALIZAR
														</Button>
													</div>
												</Form>
											</>
										) : (
											<>
												<div className="ph-review-request-success-thanks-container">
													<h3 className="ph-review-request-success-thanks-title">¡Muchas gracias por calificarnos!</h3>
												</div>
												<div className="ph-review-request-success-thanks-link-container">
													<Link className="ph-review-request-success-thanks-link" to="/">
														Volver al inicio
													</Link>
												</div>
											</>
										)}
									</>
								)}
							</>
						) : (
							<div className="ph-review-request-loading-container">
								<Spin indicator={<LoadingOutlined className="ph-review-request-loading-image" />} className="ph-review-request-loading-icon" />
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
