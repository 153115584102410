/** @format */

import axios from 'axios';

import Notify from '../../../Notify';

import { ENV_COUNTRY, ENV_CHECKOUT, ENV_ROOT_PHARMACY } from '../../Variables/Enviroment';
import { Enviroment } from '../../../../../_config/constants/enviroment-v2.constant';

export async function GetOrder(item) {
	let infoMX = {};
	let returnResponse;
	let coverage = localStorage.getItem('customerCoverage');
	var info = {
		age: item.age.replace(/[/]/g, ''),
		email: item.email,
		first_name: item.first_name,
		last_name: item.last_name,
		location: `${item.isUserAddress.region}, ${item.isUserAddress.street}, ${item.isUserAddress.commune}`,
		pharmacy: [...item.pharmacy],
		phone: item.phone.replace(/[+()/\s/]/g, ''),
		policies: item.agreement,
		rut: item.rut.replace(/[+()/\s/]/g, ''),
		sex: item.sex,
		type_retirement: item.type_retirement,
		code_voucher: localStorage.getItem('voucherCode') || null,
		platform: Enviroment.ROOT_SITE,
		coverage: coverage,
	};
	if (ENV_COUNTRY === 'MX') {
		if (!!item.info_billing) {
			info['info_billing'] = {
				...item.info_billing,
				street: item.info_billing.street_mx,
				street_number: item.info_billing.street_number_mx,
				type_house: item.info_billing.type_house_mx,
				number_dpto_house: item.info_billing.number_department_mx,
				commune: item.info_billing.municipality,
				reference: item.info_billing.reference_mx,
			};
			delete info.info_billing.street_mx;
			delete info.info_billing.street_number_mx;
			delete info.info_billing.type_house_mx;
			delete info.info_billing.number_department_mx;
			delete info.info_billing.municipality;
			delete info.info_billing.country;
			delete info.info_billing.reference_mx;
		}
		info.maps = {
			lat: item.isUserAddress.lat,
			lng: item.isUserAddress.lng,
		};
		info.street = item.street_mx;
		info.street_number = item.street_number_mx;
		info.type_house = item.type_house_mx;
		info.number_dpto_house = item.number_department_mx;
		info.reference = item.reference_mx;
		info.commune = item.municipality;
		info.colony = item.colony;
		info.province = item.province;
		info.zip_code = item.zip_code;
		info.curp = item.curp;
		info.city = item.city;
		info.between_street = item.between_street !== '' ? item.between_street : undefined;
		if (item.type_retirement === 'domicilio')
			info.info_delivery = {
				street: item.street,
				street_number: item.street_number,
				type_house: item.type_house,
				commune: item.commune,
				colony: item.colony_del,
				city: item.city_del,
				province: item.province_del,
				zip_code: item.zip_code_del,
				between_street: item.between_street_del,
				reference: item.reference_mx,
				number_dpto_house: item.number_department,
			};
	}
	if ((item.type_retirement === 'domicilio' && ENV_COUNTRY === 'CL') || ENV_COUNTRY === 'CO' || ENV_COUNTRY === 'AR') {
		info.maps = {
			lat: item.isUserAddress.lat,
			lng: item.isUserAddress.lng,
		};
		info.street = item.street;
		info.street_number = item.street_number;
		info.type_house = item.type_house;
		info.number_dpto_house = item.number_department;
		info.reference = item.reference;
		info.commune = item.commune;
		info.location += ` ${item.street}, ${item.street_number}, ${item.type_house}${item.type_house === 'departamento' ? ' - ' + item.number_department : '. '}`;
	}
	if (info.reference === '' || info.reference === null || info.reference === undefined) delete info.reference;
	if (info.info_billing?.reference === '' || info.info_billing?.reference === null || info.info_billing?.reference === undefined) {
		delete info.info_billing?.reference;
	}
	if (ENV_COUNTRY === 'MX') {
		infoMX = {
			street_mx: item.street_mx,
			street_number_mx: item.street_number_mx,
			type_house_mx: item.type_house_mx,
			number_department_mx: item.number_department_mx,
			municipality: item.municipality,
		};
	}
	localStorage.removeItem('info_check');
	localStorage.setItem('form_retirement', JSON.stringify({ ...info, ...infoMX }));

	const data = {
		auth: localStorage.getItem('userEmail') ? true : false,
		info: info,
		products: item.products,
		total_services: item.total_services,
		total_delivery: item.total_delivery,
		total_discount_pbm: item.total_discount_pbm,
		total_discount: item.total_discount,
		total_voucher_discount: item.total_voucher_discount,
		total_voucher_despatch_discount: item.total_voucher_despatch_discount,
		total_voucher_products_discount: item.total_voucher_products_discount,
		sub_total: item.sub_total,
		total: item.total,
		payment_method_name_allowed: item.payment_method_name_allowed,
		payment: item.payment,
		referal: JSON.parse(localStorage.getItem('ePrescriptionToken')),
		country: ENV_COUNTRY,
		order_calculation: item.main_order_calculation,
		shipping_information: item.shipping_information,
		root_pharmacy: ENV_ROOT_PHARMACY,
	};
	await axios({
		method: 'POST',
		url: `${ENV_CHECKOUT}/checkout`,
		data: data,
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				if (response.data.data.valid_recipe) {
					returnResponse = response.data.data.order._id;
				} else {
					Notify({
						title: `Error en Receta!`,
						colorTitle: 'ph-main-notification-error-title',
						description: `¡Lo sentimos algo paso con su Receta, por favor vuelva a subirla!.`,
						time: 3,
						image: 'error',
						placement: 'topRight',
					});
				}
			} else if (response.data.statusCode === 400) {
				Notify({
					title: `${response.data.error} - ${response.data.statusCode}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `${response.data.message}`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			} else {
				Notify({
					title: `Error!`,
					colorTitle: 'ph-main-notification-error-title',
					description: `${response.data.error}...`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			}
		})
		.catch(() => {
			Notify({
				title: `Problemas de Conexión`,
				colorTitle: 'ph-main-notification-error-title',
				description: `Revisa tu conexión o ponte en contacto con nosotros.`,
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
}
