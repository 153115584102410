/** @format */

import React from 'react'

import moment from 'moment'

import './style.scss'

export default function BoxInfox(props) {
	return (
		<>
			<div className={`ph-order-history-purchase-list-title-container ${props.classContent}`}>
				<h3 className={`ph-order-history-purchase-list-title ${props.classTitle}`}>
					{props.title}
				</h3>
				{props.titleResponsive && (
					<h3
						className={`ph-order-history-purchase-list-title-responsive ${props.classTitleResponsive}`}>
						{props.titleResponsive}
					</h3>
				)}
				<p className={`ph-order-history-purchase-list-description ${props.classDescription}`}>
					{props.description.toString().includes('-')
						? moment(props.description).format('YYYY/MM/DD hh:mm:ss A')
						: props.description}
				</p>
			</div>
		</>
	)
}
