/** @format */

import axios from "axios";

import Notify from "../../components/Commons/Notify";

import {
  ENV_EMAIL,
  ENV_COUNTRY,
} from "../../components/Commons/Hooks/Variables/Enviroment";

export default async function pharolGoService(data) {
  data.rut_number = data.rut_number.replace(/[.-]/g, "");
  data.phone = data.phone.replace(/[+()/\s/]/g, "");
  let returnResponse;
  await axios({
    method: "POST",
    url: `${ENV_EMAIL}/${ENV_COUNTRY}/contactPharolGo`,
    data,
  })
    .then((response) => {
      if (response.data.status) {
        returnResponse = response.status;
        Notify({
          title: "¡Felicidades!",
          colorTitle: "ph-main-notification-success-title",
          description: `Hola ${data.first_name} ${data.last_name}, hemos recibido tu mensaje exitosamente, a la brevedad te daremos respuesta.`,
          time: 3,
          image: "warning",
          placement: "topRight",
        });
      } else {
        Notify({
          title: "¡Lo sentimos!",
          colorTitle: "ph-main-notification-error-title",
          description: "Ha ocurrido un error, por favor intente mas tarde",
          time: 3,
          image: "error",
          placement: "topRight",
        });
      }
    })
    .catch(() => {
      Notify({
        title: "¡Lo sentimos!",
        colorTitle: "ph-main-notification-error-title",
        description: "Ha ocurrido un error, revise su conexion de internet.",
        time: 3,
        image: "error",
        placement: "topRight",
      });
    });
  return returnResponse;
}
