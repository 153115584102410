/** @format */

import React from 'react'

import { formatPrice } from '../../../../../../../../../../../../../../components/Commons/Hooks/Functions/FormatPrice'

import './style.scss'

export default function PromotionProductDiscount(props) {
	return (
		<>
			<div className='ph-electronic-quote-price-promotion'>
				{props.promotion.description_promotion && <>{props.promotion.description_promotion}</>}
				{props.promotion.percentaje_promotion && <>{props.promotion.percentaje_promotion}</>}
				{props.promotion.price_promotion && (
					<>
						<div className='ph-electronic-quote-price-promotion-symbol'>
							{props.promotion.price_promotion.symbol}
						</div>
						{formatPrice(props.promotion.price_promotion.amount)}
					</>
				)}
			</div>
		</>
	)
}
