/** @format */

import React, { useState, useRef, useEffect } from 'react';
import { useGlobal } from 'reactn';
import { Row, Col } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';

import { GeneralProductAdapter } from '../../../../../../_config/adapters/google-tag-manager/general-product.adapter';
import { Events } from '../../../../../../_config/adapters/google-tag-manager/events.constant';

import CountryValidator from '../../../../../../components/Commons/CountryValidator';
import Image from '../../../../components/Image';
import Carousel from '../../../../../../components/Commons/Carousel';
import InfoTabs from '../../../../components/InfoTabs';
import Title from './components/Title';
import Pharmacies from './components/NewPharmacies';
import Contact from './components/Contact';
import ContactRational from './components/ContactRational';
import PharolPriceContainer from './components/PharolPriceContainer';
import ModalSubscribed from './components/ModalSubscribed';

import { serviceSendContract } from '../DetailPharmacy/components/CovenantsWithoutRecipe/services';

import './style.scss';

export default function ProductDetailSimple(props) {
	const [showMoreTab, setShowMoreTab] = useState(false);
	const [pharolPriceCheck, setPharolPriceCheck] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isPharmacies] = useGlobal('pharmaciesList');
	const [pharolPriceProduct] = useGlobal('pharolPriceProduct');
	const [isDataUser] = useGlobal('userDetailInfo');
	const showMoreRef = useRef(null);
	const [modalPharolPrice, setModalPharolPrice] = useState({
		view: false,
		agreementsCompletedOrRequested: [],
	});
	const prescriptionValidation =
		props.item.details[0].prescription_type === 'WP' ||
		props.item.details[0].prescription_type === 'RCH' ||
		props.item.details[0].prescription_type === 'RMRSCS';

	useEffect(() => {
		GeneralProductAdapter({ event: Events.view_item, product: props.item.details[0] });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (pharolPriceProduct && pharolPriceProduct[0]?.status === 'completed') setPharolPriceCheck(true);
	}, [pharolPriceProduct]);

	const handleInfoTabs = () => {
		let content = showMoreRef.current;
		setShowMoreTab((pvSt) => !pvSt);
		if (content.style.maxHeight) {
			content.style.maxHeight = null;
		} else {
			content.style.maxHeight = content.scrollHeight + 'px';
		}
	};

	const handleSubscriptionPharolPrice = (pharmacy) => {
		serviceSendContract({
			agreement_campaign: pharolPriceProduct[0].name,
			nid: isDataUser.rut,
			product: {
				id_pharol: props.item.details[0].id_pharol,
				code_internal: props.item.details[0].code_internal,
				pharmacy,
			},
			status: pharolPriceCheck,
		});
	};

	const handleUnsuscribeAgreements = (item, agreementsList) => {
		setLoading(true);
		let i = 0;
		while (i < agreementsList.length) {
			if (!agreementsList[i].recipe_required) {
				serviceSendContract({
					agreement_campaign: agreementsList[i].name,
					nid: isDataUser.rut,
					product: {
						code_internal: props.item.details[0].code_internal,
						pharmacy: agreementsList[i].pharmacy,
					},
					status: false,
				});
			}
			i++;
		}
		setPharolPriceCheck(item);
		setModalPharolPrice({
			view: false,
			agreementsCompletedOrRequested: [],
		});
	};

	const handlePharolPrice = ({ target: { checked } }) => {
		let isPharmaciesCopy = JSON.parse(JSON.stringify(isPharmacies));
		if (checked) {
			let n = 0;
			let allAgreementsCompletedOrRequested = [];
			while (n < isPharmaciesCopy.length) {
				for (const agreement of isPharmaciesCopy[n].agreements) {
					if (agreement.status === 'not-adjudicated' || agreement.status === 'requested') {
						allAgreementsCompletedOrRequested.push({
							...agreement,
							pharmacy: isPharmacies[n].pharmacy,
						});
					}
				}
				n++;
			}
			if (allAgreementsCompletedOrRequested.length > 0) {
				setModalPharolPrice({
					view: true,
					agreementsCompletedOrRequested: allAgreementsCompletedOrRequested,
				});
				return;
			}
		} else {
			handleSubscriptionPharolPrice();
		}
		setPharolPriceCheck(checked);
		//handleSubscriptionPharolPrice(checked)
	};

	return (
		<>
			<Row gutter={[15, 0]} className="ph-product-detail-quote-pharmacy-global-container">
				<Col xs={24} sm={24} md={24} lg={8} xl={8} className="ph-product-detail-simple-global-container">
					<Row>
						<div className="ph-product-detail-simple-title-container">
							<Title item={props.item} />
						</div>
						<div className="ph-product-detail-simple-image-container">
							<Image classContent={'ph-product-detail-quote-main-image-global-container'} item={props.item.details[0]} />
						</div>
					</Row>
					<Row>
						<CountryValidator
							countries={['AR']}
							condition={!props.item.details.some((i) => i.master_category === 'Medicamentos' && prescriptionValidation)}
							component={
								<p className="ph-product-detail-info-disclaimer">
									La farmacia validará el descuento correspondiente a tu plan cuando presentes tu prepaga/obra social.
								</p>
							}
						/>
						<CountryValidator
							countries={['AR']}
							condition={!props.item.details.some((i) => i.master_category === 'Medicamentos')}
							component={
								<p className="ph-product-detail-info-disclaimer">Leé atentamente el prospecto y ante la menor duda consultá a tu médico y/o farmacéutico.</p>
							}
						/>
					</Row>
				</Col>
				<Col xs={24} sm={24} md={24} lg={16} xl={16} className="ph-product-detail-simple-global-container">
					<div className="ph-product-detail-simple-title-container">
						<Title item={props.item} />
					</div>
					<div className="ph-product-detail-quote-tabs-main-container">
						<InfoTabs item={props.item} showMoreRef={showMoreRef} showMoreTab={showMoreTab} />
						<div className="ph-product-detail-quote-tabs-show-more" onClick={handleInfoTabs}>
							<div>Ver más</div>
							{!showMoreTab ? (
								<DownOutlined className="ph-product-detail-quote-tabs-show-more-icon" />
							) : (
								<UpOutlined className="ph-product-detail-quote-tabs-show-more-icon" />
							)}
						</div>
					</div>
					<Contact />
				</Col>
			</Row>
			{!!pharolPriceProduct && pharolPriceProduct.length > 0 && (
				<PharolPriceContainer
					isPharmacies={isPharmacies}
					pharolPriceProduct={pharolPriceProduct[0]}
					pharolPriceCheck={pharolPriceCheck}
					handlePharolPrice={handlePharolPrice}
				/>
			)}
			<ModalSubscribed
				loading={loading}
				pharolPriceProduct={pharolPriceProduct}
				modalPharolPrice={modalPharolPrice}
				setModalPharolPrice={(item) => setModalPharolPrice((pvSt) => ({ ...pvSt, view: false }))}
				handleUnsuscribeAgreements={handleUnsuscribeAgreements}
			/>
			<Pharmacies
				mobile={props.mobile}
				item={props.item}
				handleSubscriptionPharolPrice={handleSubscriptionPharolPrice}
				pharolPriceCheck={pharolPriceCheck}
				setPharolPriceCheck={setPharolPriceCheck}
			/>
			<h2 className="ph-product-detail-quote-tabs-mobile-title">Información</h2>
			<div className="ph-product-detail-quote-tabs-main-container">
				<InfoTabs item={props.item} showMoreTab={showMoreTab} />
			</div>
			<ContactRational mobile={props.mobile} item={props.item} />
			{props.item.bioequivalent.length > 0 && (
				<>
					<h3 className="ph-product-detail-quote-carousel-pharmacy-title">Productos Intercambiables</h3>
					<Carousel carouselItems={[5, 3, 2, 2, 1]} type="product" list={props.item.bioequivalent} />
				</>
			)}
			{props.item.details[0].master_category !== 'Medicamentos' && (
				<>
					{props.item.relations.length > 0 && (
						<>
							<h3 className="ph-product-detail-quote-carousel-pharmacy-title">Otras personas también vieron</h3>
							<Carousel carouselItems={[5, 3, 2, 2, 1]} type="product" list={props.item.relations} />
						</>
					)}
				</>
			)}
		</>
	);
}
