/** @format */

import React from 'react'

import { Button, Form } from 'antd'
import { NotificationOutlined } from '@ant-design/icons'

import InputField from '../../../../../../../../components/Commons/Input'

import { userSusbcribeService } from '../../../../../../../../components/Commons/Hooks/Services/UserSubscribe'

import './style.scss'

export default function ProductSubscription() {
	const [form] = Form.useForm()
	const handleSubmitSubscribe = async (item) => {
		await userSusbcribeService(item, true).then((response) => {
			if (response.data.contact) {
				form.resetFields()
			}
		})
	}

	return (
		<div className='ph-product-detail-subscription-main-container'>
			<h3 className='ph-product-detail-subscription-title'>
				¡Déjanos tu correo y te avisaremos cuando este producto tenga stock!
			</h3>
			<div>
				<Form
					className='ph-product-detail-subscription-container'
					onFinish={handleSubmitSubscribe}
					form={form}>
					<InputField
						className={'ph-product-detail-subscription-field'}
						inputName={'emailProduct'}
						inputNameLabel={'Ingresa tu Correo Electrónico'}
						inputNameRule={true}
						inputNameMessage={'Ingresa tu Correo Electrónico'}
						inputNameType={'text'}
						inputNameRules={'rulesEmail'}
					/>
					<Button className='ph-product-detail-subscription-button' htmlType='submit'>
						SUSCRÍBIRSE <NotificationOutlined />
					</Button>
				</Form>
			</div>
		</div>
	)
}
