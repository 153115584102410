import {dataSteps} from '../constants/data-steps.constant'
import { handleDescription } from './description.handle';

export const stepsHandle = (process) => {
	console.log('process',process)
	const filteredStatus = dataSteps.filter((s) => process?.some((p) => p.status === s.status));
	const steps = filteredStatus.map((item, index) => {
		const description = process?.filter((item) => item.status === filteredStatus[index].status);
		console.log('description', description);
		return {
			title: item.description,
			description: handleDescription(description),
		};
	});
	return steps;
};
