/** @format */

import { ENV_COUNTRY } from '../../../Commons/Hooks/Variables/Enviroment'

import translates from '../QuoteTranslate'

export const onDemand = {
	image: 'react-product/on-demand',
	title: 'Contra Pedido',
	subtitle: `¡Ingresa tus datos, sube tu ${translates.recipe[ENV_COUNTRY]} médica (si te lo indica) y te enviaremos tu cotización!`,
	meta: `Sube tu ${translates.recipe[ENV_COUNTRY]} médica y te enviaremos la cotización`,
}

export const magistral = {
	image: 'react-checkout/magistral-recipe/magistral',
	title: `${translates.magistral[ENV_COUNTRY]}`,
	subtitle: `¡Ingresa tus datos, sube tu ${translates.recipe[ENV_COUNTRY]} médica y te enviaremos tu cotización!`,
	meta: `Sube tu ${translates.recipe[ENV_COUNTRY]} médica y te enviaremos la cotización`,
}

export const minsalRecipe = {
	image: 'react-checkout/magistral-recipe/magistral',
	title: 'Receta Médica',
	subtitle: `¡Ingresa tus datos, sube tu ${translates.recipe[ENV_COUNTRY]} médica del Minsal y te enviaremos tu cotización!`,
	meta: `Sube tu ${translates.recipe[ENV_COUNTRY]} médica y te enviaremos la cotización`,
}

export const requestRecipe = {
	image: 'react-checkout/magistral-recipe/magistral',
	title: 'Receta Médica',
	subtitle: `¡Ingresa tus datos, sube tu ${translates.recipe[ENV_COUNTRY]} médica y te enviaremos tu cotización!`,
	meta: `Sube tu ${translates.recipe[ENV_COUNTRY]} médica y te enviaremos la cotización`,
}
