/** @format */

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, Spin } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

import { GlobalConsumer } from '../../../../components/Commons/Context/Global';

import { useHistory } from 'react-router-dom';

import Images from '../../../../components/Commons/Images';
import Notify from '../../../../components/Commons/Notify';
import MetaDescription from '../../../../components/Commons/MetaDescription';
import { ENV_IMG } from '../../../../components//Commons/Hooks/Variables/Enviroment';

import { GetProduct } from '../../../../components/Commons/Hooks/Functions/GetProduct';
import { GetUserData } from '../../../../components/Commons/Hooks/Functions/GetUserData';

import AddProductCart from '../../../../components/Commons/Hooks/Functions/AddProductCart';

import Address from '../../../../components/Commons/Header/components/Address';

import { serviceGetAgreementCustomer } from './services';

import trackingIcon from '../../../../components/Commons/Assets/Icons/Filled/Enabled/tracking.svg';
import CustomButton from '../../../../v2-components/Antd/Button';

import './style.scss';

export default function CheckProduct(props) {
	const { t } = useTranslation('global');

	const history = useHistory();
	const [isLocation] = useState(JSON.parse(localStorage.getItem('locationStore')));
	const [isCart] = useState(JSON.parse(localStorage.getItem('cart')));
	const [isCheckProducteModal, setCheckProducteModal] = useState(false);
	const [isCheckValidate, setCheckValidate] = useState(true);
	const [isCheckStock, setCheckStock] = useState(false);

	const { setModalAddress } = GlobalConsumer();

	const { handleAddProductCart } = AddProductCart();

	const handleRedirect = () => {
		history.push('/');
	};

	useEffect(() => {
		(async () => {
			if (isLocation.hasOwnProperty('default')) {
				setModalAddress(true);
				return;
			}
			let CheckStock = false;

			if (isCart.length > 0) {
				isCart.forEach((item) => {
					if (item.quantity > 9) {
						CheckStock = true;
					}
				});
			}
			if (CheckStock) {
				Notify({
					title: `Error al agregar producto`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Has superado la cantidad máxima en la compra de uno de tus productos, seras dirigido a la bolsa.`,
					time: 5,
					image: 'error',
					placement: 'topRight',
				});
				setCheckStock(CheckStock);
				setTimeout(() => {
					setCheckProducteModal(false);
					history.push('/cart');
				}, 4000);
				return;
			} else {
				setCheckProducteModal(true);
				let products = await GetProduct(props.match.params.id, isLocation);
				if (!products) {
					setCheckValidate(false);
					setCheckProducteModal(false);
					return;
				}
				const userEmail = localStorage.getItem('userEmail');
				const userToken = localStorage.getItem('userToken');

				if (userEmail || userToken) {
					let userInfo = await GetUserData(userEmail);
					if (!!userInfo?.rut) {
						let responseAgreement = await serviceGetAgreementCustomer({
							rut: userInfo.rut,
							pharmacy: products.details[0].pharmacy,
							code_internal: products.details[0].code_internal,
						});
						if (!responseAgreement.hasOwnProperty('statusCode') && responseAgreement.recipe !== null) {
							products.details[0] = {
								...products.details[0],
								recipe: responseAgreement.recipe,
							};
						}
					}
				}
				handleAddProductCart(products.details[0], 1, true);
				setTimeout(() => {
					setCheckProducteModal(false);
					history.push('/cart');
				}, 4000);
			}
		})();
	}, []); // eslint-disable-line

	return (
		<>
			<div className="ph-check-detail-product-success-global-container">
				<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
				<section className="ph-check-detail-product-success-main-container">
					<div className="ph-check-detail-product-success-inner-container">
						{isCheckStock && (
							<>
								<Images
									classContent={'ph-check-detail-product-succes-img-container'}
									classImage={'ph-check-detail-product-succes-img'}
									webp={`${ENV_IMG}/react-notification/error.webp`}
									jp2={`${ENV_IMG}/react-notification/error.jp2`}
									jxr={`${ENV_IMG}/react-notification/error.jxr`}
									default={`${ENV_IMG}/react-notification/error.png`}
								/>
								<h1 className="ph-check-detail-product-success-succes-title">¡Tu producto no pudo ser agregado!</h1>
							</>
						)}
						{!isCheckValidate && (
							<>
								<Images
									classContent={'ph-check-detail-product-succes-img-container'}
									classImage={'ph-check-detail-product-succes-img'}
									webp={`${ENV_IMG}/react-notification/error.webp`}
									jp2={`${ENV_IMG}/react-notification/error.jp2`}
									jxr={`${ENV_IMG}/react-notification/error.jxr`}
									default={`${ENV_IMG}/react-notification/error.png`}
								/>
								<h1 className="ph-check-detail-product-success-succes-title">¡Ha ocurrido un error!</h1>
								<p className="ph-check-detail-product-success-paraghaf">
									Producto: <strong className="ph-check-detail-product-success-number">{props.match.params.id}</strong>
								</p>
								<h4 className="ph-check-detail-product-success-description-span">
									<span className="ph-check-detail-product-success-description-span-i">*</span>
									{t('checkout.transaction.error.description')}
									<a href={`mailto:${t('main.email')}`}>
										<span className="ph-check-detail-product-success-description-contact">{t('main.email')}</span>
									</a>
								</h4>
								<div className="ph-check-detail-product-success-payment-button-container">
									<CustomButton className={'button-primary'} onClick={() => handleRedirect()}>
										Volver al Inicio
									</CustomButton>
								</div>
							</>
						)}
					</div>
				</section>
			</div>
			<Modal
				wrapClassName="ph-check-detail-product-modal"
				centered
				title="Bolsa"
				visible={isCheckProducteModal}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
				okText=""
				cancelText=""
				width={300}
				header={false}
				footer={false}
			>
				<Images classContent={'ph-check-detail-product-image-container'} classImage={'ph-check-detail-product-image'} default={trackingIcon} />
				<h4 className="ph-check-detail-product-title">Verificando tu Producto...</h4>
				<h4 className="ph-check-detail-product-subtitle">¡No cierres esta ventana!</h4>
				<Spin className="ph-check-detail-product-spin" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
			</Modal>
			<Address />
		</>
	);
}
