/** @format */

import React from 'react'

import { Modal, Button } from 'antd'

import './style.scss'

export default function ModalSubscribed(props) {
	return (
		<Modal
			wrapClassName='ph-product-pharol-price-modal-container'
			visible={props.modalPharolPrice.view}
			title='Beneficios no acumulables'
			width={396}
			footer={[]}>
			<div>
				<div>
					Al solicitar el benficio{' '}
					<span className='ph-pharol-product-pharol-price-modal-benefit-to-cancel-approve'>
						{props.benefitToAdd}
					</span>
				</div>
				<div>
					se cancelará el beneficio de{' '}
					<span className='ph-pharol-product-pharol-price-modal-benefit-to-cancel-approve'>
						{props.benefitToRemove}
					</span>
				</div>
			</div>
			<div className='ph-product-pharol-price-modal-are-you-agree-container'>
				<span>¿Estás de acuerdo?</span>
			</div>
			<div>
				<div className='ph-product-pharol-price-modal-buttons-container'>
					<Button
						className='ph-product-detail-alternative-new-button'
						onClick={props.setModalPharolPrice}>
						Cancelar
					</Button>
					<Button
						loading={props.loading}
						className='ph-product-detail-quote-new-button'
						onClick={() => {
							if (!props.pharolPriceCheck)
								props.handleUnsuscribeAgreements(
									true,
									props.modalPharolPrice.agreementsCompletedOrRequested
								)
							}
						}>
						De acuerdo
					</Button>
				</div>
			</div>
		</Modal>
	)
}
