/** @format */

import ReactPixel from 'react-facebook-pixel'

export const PixelFacebook = (item, data) => {
	if (item) {
		ReactPixel.track(item, data)
	} else {
		ReactPixel.pageView()
	}
}
