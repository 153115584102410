/** @format */

import React, { useEffect } from 'react'

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

import RoutineMachine from './components/Route'

import 'leaflet/dist/leaflet.css'

import './style.scss'

export default function LeafletMap(props) {
	useEffect(() => {
		const markerIcon = require('leaflet')
		delete markerIcon.Icon.Default.prototype._getIconUrl
		markerIcon.Icon.Default.mergeOptions({
			iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
			iconUrl: require('leaflet/dist/images/marker-icon.png').default,
			shadowUrl: require('leaflet/dist/images/marker-shadow.png').default,
		})
	}, [])
	return (
		<div className='ph-map-retirement-routes-container'>
			<MapContainer key={JSON.stringify(props.center)} center={props.center} zoom={props.zoom}>
				<TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
				<Marker position={props.center}>
					<Popup>
						{props.title} <br />{' '}
						<h4 className='ph-map-retirement-routes-marker-pharol'>{props.description}</h4>
					</Popup>
				</Marker>
				{props.multiple ? (
					<>
						{props.pharmacyLocation.map((item, index) => (
							<RoutineMachine
								key={index}
								pharmacyLocation={{ lat: item.location.lat, lng: item.location.lng }}
								userLocation={props.userLocation}
							/>
						))}
					</>
				) : (
					<RoutineMachine
						pharmacyLocation={props.pharmacyLocation}
						userLocation={props.userLocation}
					/>
				)}
			</MapContainer>
		</div>
	)
}
