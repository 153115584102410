/** @format */

import 'antd/dist/antd.css';
import i18next from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { I18nextProvider } from 'react-i18next';
import '../src/components/Commons/Sass/style.scss';
import { Enviroment } from './_config/constants/enviroment-v2.constant';
import { Translation } from './_config/global/tanslation';
import { IsProduction } from './_config/utils/is-production.util';
import Router from './router';
import * as serviceWorker from './serviceWorker';
import './v2-config/main.scss';

IsProduction();
if (Enviroment.GOOGLE_ANALITYCS_4) {
	ReactGA.initialize(`${Enviroment.GOOGLE_ANALITYCS_4}`);
	ReactGA.send({
		hitType: 'pageview',
		page: `${window.location.pathname + window.location.search}`,
	});
}

ReactPixel.init(Enviroment.FACEBOOK_PIXEL, null, { autoConfig: true, debug: false });

i18next.init({
	lng: Enviroment.COUNTRY,
	debug: true,
	resources: {
		CL: {
			global: Translation,
		},
	},
});

ReactDOM.render(
	<I18nextProvider i18n={i18next}>
		<Router />
	</I18nextProvider>,
	document.getElementById('root'),
);

serviceWorker.unregister();
