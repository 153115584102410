/** @format */
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Layout from '../components/Layout/';
import TestPage from '../pages/test';
// import Home from '../pages/home/';

import Contact from '../pages/contact/';

import Login from '../pages/auth/login/';
import Register from '../pages/auth/register/';
import RecoverPassword from '../pages/auth/recover-password/';
import Confirm from '../pages/auth/confirm';
import TokenEmail from '../pages/auth/token';
import Profile from '../pages/profile/';
import AccountData from '../pages/profile/pages/account-data/';
import Tracking from '../pages/tracking/pages/search';
import TrackingDetail from '../pages/tracking/pages/detail';
import Address from '../pages/profile/pages/address/';
import PurchaseHistoryList from '../pages/profile/pages/purchase-history/';
import UpdatePassword from '../pages/profile/pages/update-password/';

//import Cart from '../pages/carts/pages/cart'
import Bag from '../pages/carts/pages/bag';
//import ElectronicCart from '../pages/carts/pages/electronic-prescription'
import BagElectronicCart from '../pages/carts/pages/bag-electronic-prescription';
//import Retirement from '../pages/checkout/pages/retirement/'
import Withdrawal from '../pages/checkout/pages/withdrawal';
import RetirementService from '../pages/checkout/pages/retirement-service/';
import Prescription from '../pages/checkout/pages/prescription/';
import Detail from '../pages/checkout/pages/detail/';
// import Payment from '../pages/checkout/pages/payment/';
import Success from '../pages/checkout/pages/transaction/success/';
import Error from '../pages/checkout/pages/transaction/error';
import RequestPreorder from '../pages/preorder/pages/request-preorder';
import SuccessRequest from '../pages/preorder/pages/success';
import ErrorRequest from '../pages/preorder/pages/error';

import Category from '../pages/categories/';
import Laboratory from '../pages/laboratories/';
import Pharmacy from '../pages/pharmacies/';
import Result from '../pages/results/';
import ProductDetail from '../pages/products/pages/product';
import ServiceDetail from '../pages/products/pages/service';
import ComplaintBookMenu from '../pages/complaint-book/pages/menu';
import ComplaintBookSearch from '../pages/complaint-book/pages/search';
import ComplaintBookHistory from '../pages/complaint-book/pages/history';
import ComplaintBookChat from '../pages/complaint-book/pages/chat';

import RecipesDigitalMinsal from '../pages/preorder/pages/request-quotation';
//import ElectronicQuote from '../pages/carts/pages/electronic-quote'
import BagElectronicQuote from '../pages/carts/pages/bag-electronic-quote';

import TermsConditions from '../pages/terms-conditions';
import Politics from '../pages/politics/';
import TypeRecipes from '../pages/type-recipes';
import UploadPharmacy from '../pages/upload-pharmacy';
import Mediclic from '../pages/mediclic';
import HowToBuy from '../pages/how-to-buy';
import About from '../pages/about';
import PharolGo from '../pages/pharol-go';
import PharolGoTerms from '../pages/politics-pharol-go';
import TypeDelivery from '../pages/type-delivery';
import patientExecutives from '../pages/patient-executives';
import ObjectedRecipe from '../pages/objected-recipe';
import ScheduleSuccess from '../pages/preorder/pages/schedule-success';
import Schedule from '../pages/preorder/pages/schedule';
import Favorites from '../pages/profile/pages/favorites/';
import Recipes from '../pages/profile/pages/recipes';
import FrequentQuestions from '../pages/frequent-questions';
import ReviewSend from '../pages/review-request';
import SuccessCovenant from '../pages/covenant-success';
import ProductCheck from '../pages/products/pages/product-check';
import SuccessReserve from '../pages/checkout/pages/transaction/success-reserve';
import ErrorReserve from '../pages/checkout/pages/transaction/error-reserve';

import Building from '../pages/building';

import NotFound from '../pages/not-found/';

import PrivateRoute from './components/PrivateRoute/';
import PrivateCheckout from './components/PrivateCheckout/';
import ContextBag from './components/Context/Bag';

import '../components/Commons/Hooks/Variables/Storage';

import LandingPage from '../v2-pages/landing';
import LandingExamplePage from '../v2-pages/landing-examples';
import ClaimsPage from '../v2-pages/claims';
import ClaimsCreatePage from '../v2-pages/claims/pages/create';
import ClaimsSearchPage from '../v2-pages/claims/pages/seacrh';
import ClaimsBookPage from '../v2-pages/claims/pages/book';
import ProfilePage from '../v2-pages/profile';
import ProfilePersonalPage from '../v2-pages/profile/pages/personal';
import ProfileShoppingHisgoryPage from '../v2-pages/profile/pages/shopping-history';
import ProfileShoppingDetailPage from '../v2-pages/profile/pages/shopping-history/pages/detail';
import ProfileReserveHisgoryPage from '../v2-pages/profile/pages/reserve-history';
import ProfileReserveDetailPage from '../v2-pages/profile/pages/reserve-history/pages/detail';
import ProfileChangePasswordPage from '../v2-pages/profile/pages/change-password';
import LoginUser from '../v2-pages/auth/login';
import RegisterUser from '../v2-pages/auth/register';
import RecoverPasswordUser from '../v2-pages/auth/recover-password';
import PaymentV2 from '../v2-pages/payment';

const Routers = () => {
	return (
		<BrowserRouter>
			<Layout>
				<Switch>
					<Route exact path="/" component={Pharmacy} />
					<Route exact path="/test/:id?" component={TestPage} />
					<Route exact path="/contact" component={Contact} />
					<Route exact path="/auth/login" component={LoginUser} />
					<Route exact path="/auth/register" component={RegisterUser} />
					<Route exact path="/auth/recover-password" component={RecoverPasswordUser} />
					<Route exact path="/auth/token" component={TokenEmail} />
					<Route exact path="/auth/confirm/:token" component={Confirm} />
					<PrivateRoute exact path="/profile" component={Profile} />
					<PrivateRoute exact path="/profile/account-data" component={AccountData} />
					<PrivateRoute exact path="/profile/address" component={Address} />
					<PrivateRoute exact path="/profile/purchase-history" component={PurchaseHistoryList} />
					<PrivateRoute exact path="/profile/update-password" component={UpdatePassword} />
					<PrivateRoute exact path="/profile/favorites/:page" component={Favorites} />
					<PrivateRoute exact path="/profile/recipes/:page" component={Recipes} />
					<Route exact path="/tracking" component={Tracking} />
					<Route exact path="/tracking/detail/:id_order" component={TrackingDetail} />
					<ContextBag exact path="/cart" component={Bag} />
					<ContextBag exact path="/electronic-prescription/:id/:type?" component={BagElectronicCart} />
					<Route exact path="/preorder/schedule/:token" component={Schedule} />
					<Route exact path="/preorder/schedule-success" component={ScheduleSuccess} />
					<Route exact path="/request-preorder/success/:id" component={SuccessRequest} />
					<Route exact path="/request-preorder/error" component={ErrorRequest} />
					<Route exact path="/request-preorder/:type/:slug?/:id?/:prescription?/:pharmacy?/:request?" component={RequestPreorder} />
					<Route exact path="/quote/:type_rme" component={RecipesDigitalMinsal} />
					<ContextBag exact path="/electronic-quote/:token/:rut?" component={BagElectronicQuote} />
					<PrivateCheckout exact path="/checkout/retirement-service" component={RetirementService} />
					<PrivateCheckout exact path="/checkout/retirement" component={Withdrawal} />
					<PrivateCheckout exact path="/checkout/prescription" component={Prescription} />
					<PrivateCheckout exact path="/checkout/detail" component={Detail} />
					<PrivateCheckout exact path="/checkout/payment" component={PaymentV2} />
					<PrivateCheckout exact path="/checkout/success/:id" component={Success} />
					<PrivateCheckout path="/transactions/success" component={Success} />
					<PrivateCheckout path="/transactions/error-reserve" component={ErrorReserve} />
					<PrivateCheckout path="/transactions/error" component={Error} />
					<Route path="/transactions/success-reserve" component={SuccessReserve} />
					<Route exact path="/categories/:category/:subCategory?" component={Category} />
					<Route exact path="/laboratories/:laboratory/:query?" component={Laboratory} />
					<Route exact path="/pharmacies/:pharmacy/:query?" component={Pharmacy} />
					<Route exact path="/results/:query" component={Result} />
					<Route exact path="/products/:id" component={ProductDetail} />
					<Route exact path="/product-check/:id" component={ProductCheck} />
					<Route exact path="/covenant/success/:id" component={SuccessCovenant} />
					<Route exact path="/services/:provider/:slug" component={ServiceDetail} />
					<Route exact path="/complaint-book/menu/:pharmacy" component={ComplaintBookMenu} />
					<Route exact path="/complaint-book/search/:pharmacy" component={ComplaintBookSearch} />
					<Route exact path="/complaint-book/history/:page/:pharmacy" component={ComplaintBookHistory} />
					<Route exact path="/complaint-book/chat/:pharmacy/:token" component={ComplaintBookChat} />
					<Route exact path="/terms-conditions" component={TermsConditions} />
					<Route exact path="/politics" component={Politics} />
					<Route exact path="/type-recipes" component={TypeRecipes} />
					<Route exact path="/upload-pharmacy" component={UploadPharmacy} />
					<Route exact path="/mediclic" component={Mediclic} />
					<Route exact path="/how-to-buy" component={HowToBuy} />
					<Route exact path="/about" component={About} />
					<Route exact path="/pharol-go" component={PharolGo} />
					<Route exact path="/pharol-go-terms" component={PharolGoTerms} />
					<Route exact path="/type-delivery" component={TypeDelivery} />
					<Route exact path="/patient-executives" component={patientExecutives} />
					<Route exact path="/building/:pharmacy" component={Building} />
					<Route exact path="/frequent-questions/:slug" component={FrequentQuestions} />
					<Route exact path="/objected-recipe/:trx_id/:code_internal" component={ObjectedRecipe} />
					<Route exact path="/review-request/:key" component={ReviewSend} />

					<Route exact path="/offers/:tag" component={LandingPage} />
					<Route exact path="/landing/examples" component={LandingExamplePage} />

					<Route exact path="/claims" component={ClaimsPage} />
					<Route exact path="/claims/create" component={ClaimsCreatePage} />
					<Route exact path="/claims/search" component={ClaimsSearchPage} />
					<Route exact path="/claims/book" component={ClaimsBookPage} />

					<PrivateRoute exact path="/profile-info" component={ProfilePage} />
					<PrivateRoute exact path="/profile-info/personal" component={ProfilePersonalPage} />
					<PrivateRoute exact path="/profile-info/shopping-history" component={ProfileShoppingHisgoryPage} />
					<PrivateRoute exact path="/profile-info/shopping-history/:id/:trx_id" component={ProfileShoppingDetailPage} />
					<PrivateRoute exact path="/profile-info/reserve-history" component={ProfileReserveHisgoryPage} />
					<PrivateRoute exact path="/profile-info/reserve-history/:id/:operation_id" component={ProfileReserveDetailPage} />
					<PrivateRoute exact path="/profile-info/change-password" component={ProfileChangePasswordPage} />

					<Route exact path="/v2/auth/login" component={Login} />
					<Route exact path="/v2/auth/register" component={Register} />
					<Route exact path="/v2/auth/recover-password" component={RecoverPassword} />

					<Route path="*" component={NotFound} />
				</Switch>
			</Layout>
		</BrowserRouter>
	);
};

export default Routers;
