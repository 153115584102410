/** @format */

import axios from 'axios'

import Notify from '../../../../components/Commons/Notify'

import { ENV_USER } from '../../../../components/Commons/Hooks/Variables/Enviroment'

export default async function updatePasswordService(email, item) {
	let returnResponse
	await axios({
		method: 'POST',
		url: `${ENV_USER}/user/change-password`,
		data: {
			email: email,
			old_password: item.old_password,
			new_password: item.new_password,
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				Notify({
					title: 'Cambio de Clave',
					colorTitle: 'ph-main-notification-success-title',
					description: 'Su cambio de clave fue realizado con exito.',
					time: 3,
					image: 'warning',
					placement: 'topRight',
				})
			} else {
				Notify({
					title: 'Error de Clave',
					colorTitle: 'ph-main-notification-error-title',
					description: 'Error al cambiar su clave, porfavor vuelva a intentarlo.',
					time: 3,
					image: 'error',
					placement: 'topRight',
				})
			}
			returnResponse = response.data
		})
		.catch((error) => {
			Notify({
				title: 'Error',
				colorTitle: 'ph-main-notification-error-title',
				description: 'Error al conectar con el servicio, ENV_USER/change-password',
				time: 3,
				image: 'error',
				placement: 'topRight',
			})
			returnResponse = error.response.data
		})
	return returnResponse
}
