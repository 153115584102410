/** @format */

import axios from 'axios';

import Notify from '../../../../components/Commons/Notify';

import { ENV_COUNTRY, ENV_CHECKOUT, ENV_ROOT_PHARMACY } from '../../../../components/Commons/Hooks/Variables/Enviroment';

import { RutFormat } from '../../../../components/Commons/Hooks/Functions/RutFormat';

export async function GetOrder(retirementData, userAddress, form, check, mexForm) {
	let returnResponse;

	let info = {
		age: form.age.replace(/[/]/g, ''),
		email: form.email,
		first_name: form.first_name,
		last_name: form.last_name,
		location: `${userAddress.region}, ${userAddress.street}, ${userAddress.commune}`,
		phone: form.phone.replace(/[+()/\s/]/g, ''),
		policies: form.agreement,
		rut: RutFormat(form.rut),
		sex: form.sex,
		type_retirement: retirementData.type_retirement,
		code_voucher: null,
		maps: {
			lat: userAddress.lat,
			lng: userAddress.lng,
		},
	};

	if (ENV_COUNTRY === 'MX' && check && !!mexForm) {
		const info_billing = {
			first_name: mexForm.first_name_bill,
			last_name: mexForm.last_name_bill,
			email: mexForm.email_bill,
			phone: mexForm.phone_bill.replace(/[+()/\s/]/g, ''),
			rut: mexForm.rut_bill.replace(/[_]/g, ''),
			curp: mexForm.curp_bill,
			street: mexForm.street_name_bill,
			street_number: mexForm.street_number_bill,
			type_house: mexForm.type_house_bill,
			commune: mexForm.municipality_bill,
			colony: mexForm.cologne_bill,
			city: mexForm.city_bill,
			province: mexForm.province_bill,
			zip_code: mexForm.postal_code_bill,
			between_street: mexForm.between_street_bill !== '' ? mexForm.between_street_bill : undefined,
			cfdi: mexForm.cfdi_bill,
			reference: mexForm.reference_bill,
			number_dpto_house: mexForm.number_department_bill,
		};
		info = {
			...info,
			...info_billing,
		};
	}

	localStorage.setItem('info_check', JSON.stringify(info));

	const data = {
		auth: localStorage.getItem('userEmail') ? true : false,
		info: info,
		products: [],
		total_services: retirementData.total_services,
		total_delivery: retirementData.total_delivery,
		total_discount_pbm: retirementData.total_discount_pbm,
		total_discount: retirementData.total_discount,
		sub_total: retirementData.sub_total,
		total: retirementData.total,
		payment_method_name_allowed: retirementData.payment_method_name_allowed,
		payment: retirementData.payment,
		referal: JSON.parse(localStorage.getItem('ePrescriptionToken')),
		country: ENV_COUNTRY,
		order_calculation: retirementData.main_order_calculation,
		shipping_information: retirementData.shipping_information,
		root_pharmacy: ENV_ROOT_PHARMACY,
		services: retirementData.services,
	};

	await axios({
		method: 'POST',
		url: `${ENV_CHECKOUT}/checkout`,
		data: data,
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				if (response.data.data.valid_recipe) {
					returnResponse = response.data.data.order._id;
				} else {
					Notify({
						title: `Error en Receta!`,
						colorTitle: 'ph-main-notification-error-title',
						description: `¡Ha ocurrido un error, vuelva a intentarlo!.`,
						time: 3,
						image: 'error',
						placement: 'topRight',
					});
				}
			} else if (response.data.statusCode === 400) {
				Notify({
					title: `${response.data.error} - ${response.data.statusCode}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `${response.data.message}`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			} else {
				Notify({
					title: `Error!`,
					colorTitle: 'ph-main-notification-error-title',
					description: `${response.data.error}...`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			}
		})
		.catch(() => {
			Notify({
				title: `Problemas de Conexión`,
				colorTitle: 'ph-main-notification-error-title',
				description: `Revisa tu conexión o ponte en contacto con nosotros.`,
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
}
