/** @format */

import React from 'react';
import './style.scss';

export default function CardProfileInfo(props) {
	return (
		<div className="ph-complaint-info-card">
			<div className="ph-complaint-info-description-container">
				<h3 className="ph-complaint-info-card-title">{props.title}</h3>
				<p className="ph-complaint-info-card-description">{props.description}</p>
			</div>
		</div>
	);
}
