/** @format */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { setGlobal, useGlobal } from 'reactn';

import { Link } from 'react-router-dom';

import { Checkbox, Col, Form, Row } from 'antd';

import AddressCheckout from '../../../../components/HooksCheckout/components/AddressCheckout';

import InputField from '../../../../components/Commons/Input';
import Notify from '../../../../components/Commons/Notify';

import InputPhone from '../../../../components/Commons/InputCountry/Phone';
import InputRut from '../../../../components/Commons/InputCountry/Rut';
import Loading from '../../../../components/Commons/Loading';
import MainTitlePage from '../../../../components/Commons/MainTitlePage';
import MetaDescription from '../../../../components/Commons/MetaDescription';

import KnowLoginUser from '../../../../components/Commons/Hooks/Functions/KnowLoginUser';

import { magistral, minsalRecipe, onDemand } from '../../../../components/HooksCheckout/QuoteInfo/QuoteTitle';
import UploadImage from '../../../../components/HooksCheckout/components/UploadImage';
import { serviceUploadImage } from '../../components/ServiceUploadImage';
import { serviceRequestPreorder } from './services';

import CustomButton from '../../../../v2-components/Antd/Button';
import './style.scss';

const RequestPreorder = (props) => {
	const { t } = useTranslation('global');

	const [isuserAddress] = useState(JSON.parse(localStorage.getItem('locationStore')));
	const [preorder_form] = Form.useForm();
	const [isLoading, setLoading] = useState(false);
	const [isData, setData] = useState(null);
	const [isRecipe, setRecipe] = useState(null);
	const [isRequestInfo, setRequestInfo] = useState(null);
	const [isUpload] = useGlobal('uploadImage');

	const handleAddPreorderImage = async (item) => {
		setRecipe(item);
	};

	const handleRemovePreorderImage = () => {
		setRecipe(null);
	};

	const handlePreorderRequest = async () => {
		const userAddress = JSON.parse(localStorage.getItem('locationStore'));
		if (userAddress.default) {
			Notify({
				title: '¡Aviso!',
				colorTitle: 'ph-main-notification-warning-title',
				description: '¡Debe ingresar una dirección para proceder!',
				time: 4,
				image: 'warning',
				placement: 'topRight',
			});
			return;
		}
		if (isRequestInfo.prescription_type !== 'NP') {
			if (!isRecipe) {
				Notify({
					title: '¡Aviso!',
					colorTitle: 'ph-main-notification-warning-title',
					description: '¡Debe subir la receta para poder procesar su pedido!',
					time: 4,
					image: 'warning',
					placement: 'topRight',
				});
				return;
			}
		}
		setLoading(true);
		let responseImage;
		if (isRequestInfo.prescription_type !== 'NP') {
			responseImage = await serviceUploadImage(isRecipe);
		}
		await preorder_form
			.validateFields()
			.then((values) => {
				setLoading(false);
				let rutSplitted = values.rut.split('-');
				let quantity = isRequestInfo.request_type === 'magistral' ? 1 : values.quantity_product;
				let comment = values.comment?.length > 0 ? values.comment : '';
				rutSplitted[1] = rutSplitted[1] === 'k' || rutSplitted[1] === 'K' ? rutSplitted[1].toUpperCase() : rutSplitted[1];
				const pharmacy = values.pharmacy || isRequestInfo.request_type === 'magistral' ? 'pharol' : isRequestInfo.pharmacy;
				delete values.pharmacy;
				delete values.quantity_product;
				delete values.comment;
				const data = {
					user_info: {
						...values,
						phone: values.phone.replace(/[+()/\s/]/g, ''),
						rut: rutSplitted.join('').replace(/[.]/g, ''),
						location: userAddress,
					},
					quotation_type: isRequestInfo.request_type,
					pharmacy: pharmacy,
					recipe: isRecipe ? responseImage : null,
					item_products: [
						{
							id_pharol: isRequestInfo.id_pharol ? isRequestInfo.id_pharol : '999999999',
							quantity: parseInt(quantity),
						},
					],
					comment,
				};
				if (!isRecipe) {
					delete data.recipe;
				}

				serviceRequestPreorder(data).then((response) => {
					setLoading(false);
					if (response) {
						window.location.href = `/request-preorder/success/${response}`;
					}
				});
			})
			.catch((e) => {
				console.error('¡Faltan campos por llenar!', e);
				setLoading(false);
			});
	};

	useEffect(() => {
		KnowLoginUser();
		const data = {
			request_type: props.match.params.type,
			slug: props.match.params.slug,
			id_pharol: props.match.params.id,
			prescription_type: props.match.params.prescription,
			pharmacy: props.match.params.pharmacy,
			preorder_type: props.match.params.request,
		};
		if (data.request_type === 'on-demand') {
			setData(onDemand);
		} else if (data.request_type === 'magistral') {
			setData(magistral);
		} else if (data.request_type === 'minsal-recipe') {
			data.request_type = 'magistral';
			setData(minsalRecipe);
		}
		setRequestInfo(data);
		setGlobal({ address: isuserAddress });
	}, [props.match.params, isuserAddress]);

	if ((!isData && !isRequestInfo) || !isData) {
		return <Loading />;
	} else {
		return (
			<>
				<div className="ph-request-preorder-global-container">
					<div className="ph-request-preorder-global-inner-container">
						<div>
							<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
							<MainTitlePage rootImage={true} title={isData?.title} descriptionPage={isData?.subtitle} image={`${isData?.image}`} />
							<div className="ph-request-preorder-main-container">
								<Form name="request_preorder" form={preorder_form}>
									<Row>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-request-preorder-form-container">
											<InputField
												className={'ph-request-preorder-field-input'}
												inputName={'first_name'}
												inputNameLabel={'Nombre'}
												inputNameRule={true}
												inputNameMessage={'Ingresa tu nombre'}
												inputNameType={'text'}
												inputNameRules={'rulesFirstName'}
											/>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-request-preorder-form-container">
											<InputField
												className={'ph-request-preorder-field-input'}
												inputName={'last_name'}
												inputNameLabel={'Apellido'}
												inputNameRule={true}
												inputNameMessage={'Ingresa tu apellido'}
												inputNameType={'text'}
												inputNameRules={'rulesLastName'}
											/>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-request-preorder-form-container">
											<InputField
												className={'ph-request-preorder-field-input'}
												inputName={'email'}
												inputNameLabel={'Correo electrónico'}
												inputNameRule={true}
												inputNameMessage={'E-mail es obligatorio'}
												inputNameType={'text'}
												inputNameRules={'rulesEmail'}
											/>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-request-preorder-form-container">
											<InputRut classInput={'ph-request-preorder-field-input'} />
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-request-preorder-form-container">
											<InputPhone classInput={'ph-request-preorder-field-input'} />
										</Col>
										{isRequestInfo?.request_type !== 'magistral' ? (
											<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-request-preorder-form-container">
												<InputField
													className={'ph-request-preorder-field-input'}
													inputName={'quantity_product'}
													inputNameLabel={'Cantidad'}
													inputNameRule={true}
													inputNameMessage={'Cantidad obligatoria'}
													inputNameType={'number'}
													inputNameRules={'rulesNumberMagistral'}
													min={1}
													max={10}
												/>
											</Col>
										) : (
											<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-request-preorder-form-container">
												<InputField
													className={'ph-request-preorder-field-input'}
													inputName={'comment'}
													inputNameLabel={'Comentario (Opcional)'}
													inputNameType={'text'}
												/>
											</Col>
										)}
									</Row>
								</Form>
								<Row>
									<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-request-preorder-form-container">
										<AddressCheckout isPreorder={true} classType={'ph-request-form-address'} />
									</Col>
								</Row>
								{isRequestInfo?.preorder_type === 'multipharmacy' && (
									<Form className="ph-request-preorder-form-checkbox-container" form={preorder_form} initialValues={{ pharmacy: false }}>
										<Form.Item name="pharmacy" valuePropName="checked">
											<Checkbox>
												<span className="ph-request-preorder-form-checkbox-description">Quiero recibir todas las cotizaciones sobre este producto</span>
											</Checkbox>
										</Form.Item>
									</Form>
								)}
								{isRequestInfo?.preorder_type === 'unitpharmacy' && (
									<Link className="ph-request-preorder-link-unitpharmacy" to={`/products/${isRequestInfo.slug.trim()}`}>
										Ver disponibilidad en otras farmacias
									</Link>
								)}
								{isRequestInfo?.prescription_type !== 'NP' && (
									<div className="ph-request-preorder-recipe-container">
										<UploadImage
											type={'recipe'}
											title={'Receta'}
											addProductImage={(item) => handleAddPreorderImage(item)}
											removeProductImage={() => handleRemovePreorderImage()}
										/>
									</div>
								)}
								<div className="ph-request-preorder-button-container">
									<CustomButton
										disabled={isUpload}
										form="preorder_form"
										key="submit"
										htmlType="submit"
										loading={isLoading}
										className={'button-primary'}
										onClick={() => handlePreorderRequest()}
									>
										ENVIAR
									</CustomButton>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
};
export default RequestPreorder;
