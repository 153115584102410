/** @format */

import React from 'react'
import { Link } from 'react-router-dom'

import Images from '../Images'

import './style.scss'

export default function MainTitleInfoAlt(props) {
	return (
		<div>
			<div className={`ph-profile-main-box-title-container ${props.classDetailPageContainer}`}>
				<Images
					classContent={'ph-profile-main-box-title-image-container'}
					classImage={'ph-profile-main-box-title-image'}
					default={props.image}
					title={props.title}
				/>
				<h2 className={`ph-profile-main-box-main-title ${props.classDetailPagetitle}`}>{props.title}</h2>
			</div>
			{localStorage.getItem('userEmail') && props.descriptionProfile ? (
				<h3 className={`ph-profile-main-box-main-subtitle ${props.classDetailPageDescription}`}>
					<Link to='/profile' style={{ textDecoration: "none", color: "inherit" }}>
						{props.descriptionProfile}
					</Link>
				</h3>
			) : props.descriptionOrderDetail ? (
				<h3 className={`ph-profile-main-box-main-subtitle ${props.classDetailPageDescription}`}>
					<Link to='/tracking' style={{ textDecoration: "none", color: "inherit" }}>
						{props.descriptionOrderDetail}
					</Link>
				</h3>
			) : (
				<h3 className={`ph-profile-main-box-main-subtitle ${props.classDetailPageDescription}`}>
					<Link to='/' style={{ textDecoration: "none", color: "inherit" }}>
						{props.descriptionPage}
					</Link>
				</h3>
			)}
		</div>
	)
}
