/** @format */

import axios from 'axios';

import Notify from '../../../Notify';

import { ENV_CHECKOUT, ENV_COUNTRY } from '../../Variables/Enviroment';

export const uploadSingleImageService = async (item, type) => {
	let returnResponse;

	let data = new FormData();
	data.append('file', item);

	await axios({
		method: 'POST',
		url: `${ENV_CHECKOUT}/upload/${ENV_COUNTRY}/${type}`,
		data: data,
	})
		.then((response) => {
			if (response) {
				returnResponse = response;
			} else {
				Notify({
					title: 'Problemas de Conexión',
					colorTitle: 'ph-main-notification-error-title',
					description: 'Ha ocurrido un error al subir la imagen, intenta de nuevo',
					time: 4,
					image: 'error',
					placement: 'topRight',
				});
			}
		})
		.catch(() => {
			Notify({
				title: 'Problemas de Conexión',
				colorTitle: 'ph-main-notification-error-title',
				description: 'Revisa tu conexión o ponte en contacto con nosotros',
				time: 4,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
};
