/** @format */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form, Checkbox, Button } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';

import { GlobalConsumer } from '../../../../../../components/Commons/Context/Global';
import { BagConsumer } from '../../../../../../components/Commons/Context/Bag';

import Notify from '../../../../../../components/Commons/Notify';
import Images from '../../../../../../components/Commons/Images';
import { rulesValidation } from '../../../../../../components/Commons/Input/rules';

import { ENV_IMG } from '../../../../../../components/Commons/Hooks/Variables/Enviroment';

import { SaveMultipleRecipes } from '../../../../../../components/Commons/Hooks/Functions/SaveRecipes';

import CheckoutLoading from '../../../../../../components/CheckoutLoading';

import UploadImage from '../../../../../../components/HooksCheckout/components/UploadImage';

import BagRecipeButton from './components/BagRecipeButton';
import BagRecipeImage from './components/BagRecipeImage';
import BagRecipeList from './components/BagRecipeList';
import BagRecipeProduct from './components/BagRecipeProduct';

import { serviceUploadImage } from './services';

import './style.scss';
import { CustomNotification } from '../../../../../../v2-components/Antd/Notification';

export default function BagUploadRecipes(props) {
	const { t } = useTranslation('global');
	const { isMobile, setBag } = GlobalConsumer();
	const { setBagRecipe, setBagRecipeModal } = BagConsumer();
	const [form] = Form.useForm();
	const [isRecipeData, setRecipeData] = useState(props.filterproducts);
	const [isRecipeAgreement, setRecipeAgreement] = useState(false);
	const [isSeeMore, setSeeMore] = useState([]);
	const [isByPassProducts, setSByPassProducts] = useState([]);
	const [isVisible, setVisible] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [invalidRecipe, setInvalidRecipe] = useState();

	useEffect(() => {
		console.log('aaaa...', isRecipeData);
		setRecipeAgreement(isRecipeData.some((item) => item.prescription_type !== 'WP'));
	}, [isRecipeData]);

	const handlePhysicalPresentation = (checkbox, item, index) => {
		isRecipeData.forEach((element) => {
			if (element.objectID) {
				if (element.objectID === item.objectID) {
					element.physical_presentation = checkbox.target.checked;
					element.recipe = `${ENV_IMG}/react-checkout/physical_presentation.jpg`;
					if (!checkbox.target.checked) {
						delete element.recipe;
					}
				}
			} else {
				if (element.id === item.id) {
					element.physical_presentation = checkbox.target.checked;
					element.recipe = `${ENV_IMG}/react-checkout/physical_presentation.jpg`;
					if (!checkbox.target.checked) {
						delete element.recipe;
					}
				}
			}
		});
		let collapse = [...isByPassProducts];
		const even = (data) => data === index;
		if (!collapse.some(even)) collapse.push(index);
		else {
			if (collapse.length <= 1) {
				collapse = [];
			} else {
				const i = collapse.findIndex((search) => {
					return search === index;
				});
				collapse.splice(i, 1);
			}
		}
		setSByPassProducts(collapse);
	};
	console.log('invalid recipe',invalidRecipe)
	const handleSubmitRecipe = async () => {
		console.log('invalid recipe',invalidRecipe)
		if (invalidRecipe) {
			return CustomNotification({
				type: 'error',
				message: 'Tipo de archivo incorrecto',
				description: 'Solo se permiten archivos de imagen (JPG, JPEG, PNG) o documentos (DOC, PDF). Vuelva a cargarlo teniendo en cuenta las especificaciones.',
			});
		}

		var successRecipe = true;
		var successRut = true;

		isRecipeData.map((element) => {
			if (!element.recipe) {
				successRecipe = false;
			}
			if (element.prescription_type === 'RCH' || element.prescription_type === 'RMRCCS') {
				if (!element.rut) {
					successRecipe = false;
				}
			}
			return element;
		});
		if (!successRecipe) {
			Notify({
				title: `Advertencia!`,
				colorTitle: 'ph-main-notification-warning-title',
				description: `Debe subir la receta obligatoriamente.`,
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
			return;
		}
		if (!successRut) {
			Notify({
				title: `Advertencia!`,
				colorTitle: 'ph-main-notification-warning-title',
				description: `Debe subir el RUT obligatoriamente.`,
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
			return;
		}
		const recipeInfo = isRecipeData;
		const handleFilterSaveRecipe = (item) => {
			const filtered = (data) => {
				if (data.recipe_will_save && !data.physical_presentation) {
					return true;
				}
			};
			return item.filter(filtered);
		};

		setVisible(true);
		setLoading(true);

		await serviceUploadImage(isRecipeData, 'recipe').then(async (responseRecipe) => {
			if (responseRecipe) {
				await serviceUploadImage(responseRecipe, 'rut').then(async (responseRut) => {
					if (responseRut) {
						let recipesWillSave = handleFilterSaveRecipe(responseRut);
						if (recipesWillSave.length > 0) {
							await SaveMultipleRecipes(recipesWillSave);
						}
						responseRut.forEach((item) => {
							delete item.recipe_save;
							delete item.recipe_will_save;
						});
						const recipeSavedInfo = recipeInfo.map((product) => {
							let item;
							if (product.objectID) item = responseRut.find((final) => final.objectID === product.objectID);
							else item = responseRut.find((final) => final.id === product.id);
							return item === undefined ? product : item;
						});

						let bag = JSON.parse(localStorage.getItem('cart'));
						const bagFinal = bag.map((product) => {
							let item;
							if (product.objectID) item = responseRut.find((final) => final.objectID === product.objectID);
							else item = responseRut.find((final) => final.id === product.id);
							return item === undefined ? product : item;
						});
						localStorage.setItem('cart', JSON.stringify(bagFinal));
						setBag(bagFinal);
						setRecipeData(recipeSavedInfo);
						setVisible(false);
						setLoading(false);
						setBagRecipe(false);
						setBagRecipeModal(false);
					} else {
						setVisible(false);
						setLoading(false);
					}
				});
			} else {
				setVisible(false);
				setLoading(false);
			}
		});
	};

	const handleSaveReceipt = (checkbox, item, index) => {
		isRecipeData.forEach((element) => {
			if (element.objectID) {
				if (element.objectID === item.objectID && checkbox.target.checked && checkbox.target.name === index) {
					element.recipe_will_save = true;
				} else if (element.objectID === item.objectID && !checkbox.target.checked && checkbox.target.name === index) {
					delete element.recipe_will_save;
				}
			} else {
				if (element.id === item.id && checkbox.target.checked && checkbox.target.name === index) {
					element.recipe_will_save = true;
				} else if (element.id === item.id && !checkbox.target.checked && checkbox.target.name === index) {
					delete element.recipe_will_save;
				}
			}
		});
	};

	const handleAddProductImage = (item, data, imgType) => {
		let products = [...isRecipeData];
		products.forEach((element) => {
			if (element.objectID) {
				if (element.objectID === data.objectID) {
					if (imgType === 'recipe') {
						element.recipe = item;
					}
					if (imgType === 'rut') {
						element.rut = item;
					}
				}
			} else {
				if (element.id === data.id) {
					if (imgType === 'recipe') {
						element.recipe = item;
					}
					if (imgType === 'rut') {
						element.rut = item;
					}
				}
			}
		});
		setRecipeData(products);
	};

	const handleRemoveProductImage = (item, data) => {
		let products = [...isRecipeData];
		products.forEach((element) => {
			if (element.objectID) {
				if (element.objectID === item.objectID) {
					if (data === 'recipe') {
						delete element.recipe;
					}
					if (data === 'rut') {
						delete element.rut;
					}
				}
			} else {
				if (element.id === item.id) {
					if (data === 'recipe') {
						delete element.recipe;
					}
					if (data === 'rut') {
						delete element.rut;
					}
				}
			}
		});
		setRecipeData(products);
	};

	const handleRemoveRecipeSave = (item) => {
		let products = [...isRecipeData];
		products.forEach((element) => {
			if (element.objectID) {
				if (element.objectID === item.objectID) {
					delete element.recipe;
					delete element.recipe_save;
				}
			} else {
				if (element.id === item.id) {
					delete element.recipe;
					delete element.recipe_save;
				}
			}
		});
		setRecipeData(products);
	};

	const handleExplainText = (item) => {
		let collapse = [...isSeeMore];
		const even = (element) => element === item;
		if (!collapse.some(even)) {
			collapse.push(item);
		} else {
			if (collapse.length <= 1) {
				collapse = [];
			} else {
				const index = collapse.findIndex((search) => {
					return search === item;
				});
				collapse.splice(index, 1);
			}
		}
		setSeeMore(collapse);
	};

	return (
		<>
			<Form onFinish={handleSubmitRecipe} name="recipe_form" form={form}>
				{isRecipeData?.map((item, index) => (
					<div className="ph-bag-recipe-list-main-container" key={index}>
						<Row>
							<Col xs={0} sm={0} md={13} lg={13} xl={13} className="ph-bag-recipe-list-inner-container">
								<BagRecipeProduct
									item={item}
									index={index}
									handlePhysicalPresentation={(check, product, i) => handlePhysicalPresentation(check, product, i)}
									handleExplainText={(i) => handleExplainText(i)}
									isSeeMore={isSeeMore}
								/>
								<BagRecipeList filteredProductsRecipe={isRecipeData} item={item} handlefilteredProducts={(data) => setRecipeData(data)} />
							</Col>
							<Col xs={24} sm={24} md={11} lg={11} xl={11}>
								<div>
									{!item.rem_product && !item.interface.on_demand && !item.interface.magistral && !item.interface.minsal && !item.interface.digital && (
										<>
											<div className="ph-bag-recipe-list-upload-image-main-container">
												<div
													className={`${
														isByPassProducts.some((element) => element === index) ? 'ph-bag-recipe-list-upload-bypass-recipe-image-container-enabled' : ''
													} ph-bag-recipe-list-upload-bypass-recipe-image-container`}
												>
													<Images
														classContent={'ph-bag-recipe-list-upload-bypass-recipe-inner-image-container'}
														classImage={'ph-bag-recipe-list-upload-bypass-recipe-inner-image'}
														webp={`${ENV_IMG}/react-checkout/electronic-prescription/receta-medica-icon.webp`}
														jp2={`${ENV_IMG}/react-checkout/electronic-prescription/receta-medica-icon.jp2`}
														jxr={`${ENV_IMG}/react-checkout/electronic-prescription/receta-medica-icon.jxr`}
														default={`${ENV_IMG}/react-checkout/electronic-prescription/receta-medica-icon.png`}
														title={'prescription'}
													/>
													<h4 className="ph-bag-recipe-list-upload-bypass-recipe-subtitle">¡Podrás continuar con tu pago sin problemas!</h4>
													<h4 className="ph-bag-recipe-list-upload-bypass-recipe-description">
														Un químico farmacéutico se pondrá en contacto contigo para una dispensación segura.
													</h4>
												</div>
												<div>
													{item.recipe_save ? (
														<Row className="ph-bag-recipe-will-save-title-container">
															<Col xs={24} sm={12} md={12} lg={12} xl={12} className="ph-bag-recipe-will-save-title-inner-container">
																{item.recipe.endsWith('.pdf') ? (
																	<div className="ph-checkout-receipt-modal-added-pdf-main-container">
																		<div className="ph-checkout-receipt-modal-added-pdf-container">
																			<FilePdfOutlined className="ph-checkout-receipt-modal-added-pdf-icon" />
																		</div>
																		<a className="ph-checkout-receipt-modal-added-pdf-link" href={item.recipe} target="_blank" rel="noreferrer">
																			Ver Receta
																		</a>
																	</div>
																) : (
																	<Images
																		classContent={'ph-bag-recipe-will-save-title-image-container'}
																		classImage={'ph-bag-recipe-will-save-title-image'}
																		default={item.recipe}
																		alt={'save-recipe'}
																		title={'save-recipe'}
																	/>
																)}
															</Col>
															<Col xs={24} sm={12} md={12} lg={12} xl={12} className="ph-bag-recipe-will-save-title-inner-container">
																<Button className="ph-bag-recipe-will-save-title-button" onClick={() => handleRemoveRecipeSave(item)}>
																	Eliminar
																</Button>
															</Col>
														</Row>
													) : (
														<div>
															<UploadImage
																type={'recipe'}
																title={'Receta'}
																setInvalidRecipe={setInvalidRecipe}
																addProductImage={(data) => handleAddProductImage(data, item, 'recipe')}
																removeProductImage={() => handleRemoveProductImage(item, 'recipe')}
															/>
															{localStorage.getItem('userEmail') && localStorage.getItem('userToken') && (
																<div className="ph-bag-recipe-agree-title-wp-main-container">
																	<Checkbox
																		name={index}
																		className="ph-bag-recipe-agree-title-wp-container"
																		onChange={(checkbox) => {
																			handleSaveReceipt(checkbox, item, index);
																		}}
																	></Checkbox>
																	<div>
																		<h5 className="ph-bag-recipe-will-save-title">
																			Guardar <span className="ph-bag-recipe-will-save-subtitle">Receta Médica</span> para una próxima compra
																		</h5>
																	</div>
																</div>
															)}
														</div>
													)}
												</div>
											</div>
											{(item.prescription_type === 'RCH' || item.prescription_type === 'RMRCCS') && (
												<UploadImage
													type={'rut'}
													title={'RUT'}
													addProductImage={(data) => handleAddProductImage(data, item, 'rut')}
													removeProductImage={() => handleRemoveProductImage(item, 'rut')}
												/>
											)}
										</>
									)}
									{(item.rem_product || item.interface.on_demand || item.interface.magistral || item.interface.minsal || item.interface.digital) && (
										<BagRecipeImage item={item} />
									)}
								</div>
							</Col>
						</Row>
					</div>
				))}
				<div className="ph-bag-recipe-agree-main-container">
					<div className="ph-bag-recipe-agree-container">
						<Form.Item defaultValue={false} name="recipe_agreement" valuePropName="checked" rules={rulesValidation.rulesCheckAgree}>
							<Checkbox>
								<h5 className="ph-bag-recipe-agree-title">{t('cart.recipeAgreeTitle')}</h5>
							</Checkbox>
						</Form.Item>
					</div>
				</div>
				<BagRecipeButton isMobile={isMobile} loading={isLoading} item={isRecipeData} recipeAgreement={isRecipeAgreement} />
			</Form>
			<CheckoutLoading visible={isVisible} />
		</>
	);
}
