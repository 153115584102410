/** @format */

export const facetsInfo = {
	master_category: { title: 'Categorias', sub_title: 'Categoria:' },
	category: { title: 'Sub Categorias', sub_title: 'Sub Categorías:' },
	// sub_category: { title: 'Sub-Categoría', sub_title: 'Sub-Categorías:' },
	shape_pharmacy: {
		title: 'Forma Farmacéutica',
		sub_title: 'Forma Farmacéutica:',
	},
	laboratory: { title: 'Laboratorio', sub_title: 'Laboratorios:' },
	// pharmacy: { title: "Farmacia", sub_title: "Farmacias:" },s
	quantity_per_container: { title: 'Cantidad', sub_title: 'Cantidad:' },
	cenabast: { title: 'Cenabast', sub_title: 'Cenabast:' },
	concentration: { title: 'Concentración', sub_title: 'Concentración:' },
	promotion_tag: { title: 'Promociones', sub_title: 'Promociones:' },
};

export const sortInfo = {
	price: 'Precio',
};
