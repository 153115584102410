import React from 'react';
import { Menu } from 'antd';
import './styles.scss';

export default function CustomMenuItem(props) {
	return (
		<>
			<Menu.Item {...props} />
		</>
	);
}
