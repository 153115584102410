/** @format */

import React, { useEffect, useState } from 'react';

import { useGlobal } from 'reactn';

import { Form, Button } from 'antd';

import InputField from '../../../../../../components/Commons/Input';
import Images from '../../../../../../components/Commons/Images';
import Notify from '../../../../../../components/Commons/Notify';

import BagVoucherCalculation from '../../../../functions/BagCalculation/components/BagVoucher';
import BagTotal from '../../../../functions/BagTotal';

import { GlobalConsumer } from '../../../../../../components/Commons/Context/Global';
import { BagConsumer } from '../../../../../../components/Commons/Context/Bag';
import CountryValidator from '../../../../../../components/Commons/CountryValidator';

import { handleLocals } from '../../../../functions/BagCalculation/components/BagLocals';
import { Calculation } from '../../../../functions/BagCalculation/services';
import { BagMergeLocals } from '../../../../functions/BagMergeLocals';
import { handleCountPharmacies } from '../../../../functions/BagCountPharmacies';

import { GetUserData } from '../../../../../../components/Commons/Hooks/Functions/GetUserData';

import './style.scss';
import { ENV_COUNTRY } from '../../../../../../components/Commons/Hooks/Variables/Enviroment';

export default function BagVoucher(props) {
	console.log('BagVoucher: ', props);
	const [isBagVoucherPharmacies] = useGlobal('bagVoucherPharmacies');

	const [isLoadingVoucher, setLoadingVoucher] = useState(false);

	const { isLocation, isLoginUser, setBag, isMobile } = GlobalConsumer();

	const prescriptionValidation = (item) => {
		return item === 'WP' || item === 'RCH' || item === 'RMRSCS';
	};

	const {
		isBagData,
		setBagData,
		setBagTotalCart,
		isBagRetirementPharmacies,
		isBagDeliveryPharmacies,
		setBagRetirementPharmacies,
		setBagDeliveryPharmacies,
		isBagRetirement,
		setBagRetirement,
		setLoadingGroup,
		setBagRequest,
		isBagRequest,
	} = BagConsumer();

	const { handleBagVoucher } = BagVoucherCalculation();

	const { handleBagTotal } = BagTotal();

	const handleAplyVoucher = async (field, group) => {
		const pharmacyTitle = group.name.toUpperCase();
		if (!field[group.name]) {
			return Notify({
				title: `¡Aviso!`,
				colorTitle: 'ph-main-notification-warning-title',
				description: `Ingresa un cupón válido para la farmacia ${pharmacyTitle}`,
				time: 8,
				image: 'warning',
				placement: 'topRight',
			});
		}
		setLoadingVoucher(true);
		const voucherPharmacies = await handleBagVoucher(field[group.name], group.name);
		setLoadingVoucher(false);

		if (Object.keys(voucherPharmacies).length > 0) {
			const products = JSON.parse(localStorage.getItem('cart'));
			setBagRequest(true);
			setLoadingGroup((prevState) => ({
				...prevState,
				[group.name]: true,
			}));
			let localGroupPharmacyRetirement;
			let localGroupPharmacyDelivery;

			let userData;
			if (isLoginUser?.email) userData = await GetUserData(isLoginUser.email);

			const productsFilter = group.products.map((item) => {
				delete item.type_retirement;
				return item;
			});

			await Calculation(
				productsFilter,
				isLocation,
				isLoginUser.email,
				isLoginUser.token,
				voucherPharmacies,
				handleLocals(productsFilter),
				'domicilio',
				userData,
				false,
				handleCountPharmacies(),
			).then(async (response) => {
				let responseService;
				if (response?.locals_group?.length > 1) {
					const localsGroup = await BagMergeLocals(response.locals_group);
					responseService = { ...response, locals_group: [localsGroup] };
				} else responseService = response;

				localGroupPharmacyDelivery = response.locals_group[0];

				let deliveryPharmacies = isBagDeliveryPharmacies;
				deliveryPharmacies[productsFilter[0].pharmacy] =
					responseService && responseService.locals_group[0].locals.length > 0 ? responseService.locals_group[0] : false;
				setBagDeliveryPharmacies({ ...deliveryPharmacies });
			});

			await Calculation(
				productsFilter,
				isLocation,
				isLoginUser.email,
				isLoginUser.token,
				voucherPharmacies,
				handleLocals(productsFilter),
				'farmacia',
				userData,
				false,
				handleCountPharmacies(),
			).then(async (response) => {
				let responseService;
				if (response?.locals_group?.length > 1) {
					const localsGroup = await BagMergeLocals(response.locals_group);
					responseService = { ...response, locals_group: [localsGroup] };
				} else responseService = response;

				localGroupPharmacyRetirement = response.locals_group[0];

				let retirementPharmacies = isBagRetirementPharmacies;
				retirementPharmacies[productsFilter[0].pharmacy] =
					responseService && responseService.locals_group[0].locals.length > 0 ? responseService.locals_group[0] : false;
				setBagRetirementPharmacies({ ...retirementPharmacies });
			});

			const validRetirement = isBagRetirement;
			let locals_group = isBagData.locals_group.map((item) => {
				if (item.name === productsFilter[0].pharmacy) {
					return group.type_retirement === 'farmacia' ? localGroupPharmacyRetirement : localGroupPharmacyDelivery;
				}
				return item;
			});

			if (localGroupPharmacyRetirement?.payment.discount_voucher === 0) {
				Notify({
					title: `¡Aviso!`,
					colorTitle: 'ph-main-notification-warning-title',
					description: `El cupón no aplica ningún descuento a tus productos de la farmacia ${pharmacyTitle}`,
					time: 8,
					image: 'warning',
					placement: 'topRight',
				});
			} else {
				Notify({
					title: `¡Aviso!`,
					colorTitle: 'ph-main-notification-warning-title',
					description: `Tienes un descuento en productos de la farmacia ${pharmacyTitle}`,
					time: 8,
					image: 'warning',
					placement: 'topRight',
				});
			}

			setBagRetirement({ ...validRetirement });
			const bagData = { ...isBagData, locals_group: [...locals_group] };

			setBagData(bagData);
			setBagTotalCart(handleBagTotal(bagData));
			setBag(products);
			setLoadingGroup((prevState) => ({
				...prevState,
				[group.name]: false,
			}));
			setBagRequest(false);
		} else {
			Notify({
				title: `¡Aviso!`,
				colorTitle: 'ph-main-notification-warning-title',
				description: `Cupón no válido para la farmacia ${pharmacyTitle}`,
				time: 8,
				image: 'warning',
				placement: 'topRight',
			});
		}
		props.cartForm.setFieldsValue(field);
	};

	useEffect(() => {
		props.cartForm.setFieldsValue({
			[`${props.item.name}`]: isBagVoucherPharmacies && isBagVoucherPharmacies[props.item.name],
		});
	}, [isBagVoucherPharmacies, props.cartForm, props.item.name]);

	return (
		<Form form={props.cartForm}>
			<div className="ph-bag-voucher-main-title-container">
				<div className="ph-bag-main-info-main-title-container">
					<div className="ph-bag-main-info-diclaimers-container">
						<CountryValidator
							countries={['AR']}
							condition={!props.item.products.some((i, index) => i.master_category === 'Medicamentos' && prescriptionValidation(i.prescription_type))}
							component={
								<p className="ph-bag-main-info-disclaimer">
									La farmacia validará el descuento correspondiente a tu plan cuando presentes tu prepaga/obra social.
								</p>
							}
						/>
						<CountryValidator
							countries={['AR']}
							condition={!props.item.products.some((i) => i.master_category === 'Medicamentos')}
							component={
								<p className="ph-bag-main-info-disclaimer">Leé atentamente el prospecto y ante la menor duda consultá a tu médico y/o farmacéutico.</p>
							}
						/>
					</div>
					<CountryValidator
						countries={[]}
						component={
							<>
								<span className="ph-bag-main-info-main-title-dot"></span>
								<span className="ph-bag-main-info-main-title">¿Tienes un cupón de descuento?</span>
							</>
						}
					/>
				</div>
			</div>
			<CountryValidator
				countries={[]}
				component={
					<div className="ph-bag-main-info-cupon-input-container">
						<Images
							classContent={'ph-bag-main-info-image-container'}
							classImage={'ph-bag-main-info-image'}
							default={`https://storage.googleapis.com/master-image-bucket/giftcard/Cup%C3%B3n%20Pharol_Mesa%20de%20trabajo%201%20copia%202.png`}
							title={'Voucher'}
						/>
						<InputField
							className={'ph-address-cart-form-cupon-field'}
							inputName={`${props.item.name}`}
							inputNameLabel={` Cupón ${ENV_COUNTRY === 'CL' ? `${props.item.name} aquí...` : ''} `}
							inputNameType={'text'}
						/>
						<Button
							disabled={isBagRequest}
							className="ph-bag-main-info-voucher-button-action"
							loading={isLoadingVoucher}
							onClick={() => handleAplyVoucher(props.cartForm.getFieldValue(), props.item)}
						>
							Aplicar
						</Button>
						{!isMobile && (
							<div className="ph-bag-main-info-option-title-container">
								<span className="ph-bag-main-info-main-asterisk">*</span>
								<span className="ph-bag-main-info-main-subtitle">¡Ingresa tu cupón!..(Opcional)</span>
							</div>
						)}
					</div>
				}
			/>
		</Form>
	);
}
