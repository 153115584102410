/** @format */

import React from 'react'

import { Tabs } from 'antd'

import Description from './components/Description'
import InfoAdditional from './components/InfoAdditional'
import InfoDetail from './components/InfoDetail'
import ServicePlans from './components/ServicePlans'

import './style.scss'

export default function InfoTabs(props) {
	
	const { TabPane } = Tabs
	return (
		<div ref={props.showMoreRef} className='ph-detail-simple-infotabs-container'>
			<Tabs defaultActiveKey='1'>
				{props.service ? (
					<>
						<TabPane tab='Incluye a tu plan' key='0'>
							<ServicePlans serviceForm={props.serviceForm} item={props.item.details[0]} />
						</TabPane>
					</>
				) : (
					<TabPane tab='Descripción' key='1'>
						<Description item={props.item.details[0]} />
					</TabPane>
				)}
				{props.item.details[0].patient_brochure && (
					<>
						{props.item.details[0].patient_brochure.adverse_reactions !== ' ' &&
							props.item.details[0].patient_brochure.precautions !== ' ' &&
							!props.service && (
								<TabPane tab='Prospectos' key='2'>
									<InfoDetail item={props.item.details[0]} />
								</TabPane>
							)}
						{props.item.details[0].patient_brochure.information_html && (
							<TabPane tab='Prospectos Adicional' key='3'>
								<InfoAdditional info={props.item.details[0]} />
							</TabPane>
						)}
					</>
				)}
			</Tabs>
		</div>
	)
}
