import { Layout, Typography } from 'antd';
import React from 'react';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

export default function TermsConditions() {
	return (
		<Layout>
			<Content style={{ padding: '0 50px', marginTop: '20px' }}>
				<Title level={2} style={{ textAlign: 'center' }}>
					Términos y condiciones
				</Title>
				<Title level={3}>1. Generalidades</Title>
				<Paragraph>
					Este documento (los “Términos y Condiciones”) regula los términos y condiciones bajo los cuales usted (en adelante también el “Usuario”) tiene derecho
					a acceder y usar los servicios del sitio web www.farmaciajvf.com, y de cualquier información, texto, video u otro material comunicado en el mismo. El
					sitio web le permite visitar, navegar, buscar, cotizar y, si así lo desea, adquirir los bienes o servicios que se exhiben en él. <br />
					<br />
					El sitio web ofrece al Usuario alternativas para pagar los productos o servicios adquiridos a través éste, mediante la integración de plataformas de
					pago de terceros, y podría o no, ofrecer el despacho de los productos adquiridos.
					<br />
					<br /> Es deber del Usuario leer detenidamente estos Términos y Condiciones e imprimir o guardar una copia de éstos para su información, ya que el
					sólo uso del sitio web atribuye la condición de Usuario e implica la aceptación plena y sin reservas de todas y cada una de las disposiciones acá
					contenidas.
					<br />
					<br />
					<Title level={5}>Requisitos para el uso del sitio web</Title>
					El sitio web solamente puede ser utilizado por mayores de 18 años que hayan aceptado íntegramente los presentes Términos y Condiciones. El Usuario que
					sea menor de edad, pero que cuente con la autorización de su representante legal, podrá utilizar los Servicios, siempre y cuando su representante
					legal haya aceptado íntegramente los presentes Términos y Condiciones. El uso real y efectivo del sitio web por parte del Usuario, hará presumir a
					Farmacia JVF que éstos han sido aceptados íntegramente. <br />
					<br />
					El Servicio sólo estará disponible en Chile y el Usuario acepta no utilizar o intentar utilizar el sitio web desde fuera de esta ubicación.
					<br />
					<br /> La información que proporcione para su registro en el sitio web debe ser auténtica, correcta y correspondiente a la realidad, debiendo
					comunicar mediante actualización de su registro, cualquier modificación de la misma, tan pronto como ésta se produjere.
					<br />
					<br /> Usted acepta y es de su responsabilidad cumplir con todos estos requisitos, los cuales pueden ser cambiados de forma periódica por parte de
					Farmacia JVF.
				</Paragraph>
				<Title level={3}>2. Comunicaciones</Title>
				<Paragraph>
					Para la comunicación de cualquier presentación, consulta o reclamo, a propósito del uso del sitio web, Farmacia JVF designa como representante
					especial a Felipe Andrés Fleiderman Valenzuela, Representante Legal de ésta, disponible en el correo felipe@farmaciajvf.com; y en el teléfono 56 9
					6476 3510, con domicilio para estos efectos en calle San Pablo 3136, comuna de Santiago, Región Metropolitana.
					<br />
					<br /> Toda notificación de Farmacia JVF a sus Usuarios, respecto de los presentes Términos y Condiciones, será mediante correo electrónico a la
					dirección de correo electrónico registrada en la cuenta del Usuario, la dirección proporcionada al hacer compras a través del sitio web, o por medio
					de la publicación de un mensaje en éste.
				</Paragraph>
				<Title level={3}>3. Registro</Title>
				<Paragraph>
					El registro en el sitio web y el uso de contraseña no es requisito obligatorio para contratar, sin embargo, permiten agilizar los pedidos sin tener
					que volver a introducir datos como las direcciones de entrega, acceder al historial de compra con la posibilidad de recomprar, guardar productos en
					“Mis Favoritos”, guardar Recetas Médicas utilizadas anteriormente, entre otros.
					<br />
					<br /> En caso de registrarse en el sitio web, el Usuario contará con una contraseña secreta de la cual podrá disponer e incluso modificar si así lo
					requiriera. Para activar la cuenta de Usuario y la contraseña, el Usuario deberá completar el formulario de registro disponible en el sitio web y
					enviarlo a Farmacia JVF haciendo click en el campo respectivo.
					<br />
					<br /> Respecto de la confidencialidad de la contraseña, serán responsables el Usuario y el administrador, dentro del ámbito que a cada uno
					corresponda.
				</Paragraph>
				<Title level={3}>4. Realizar compras a través del sitio web sin receta médica de productos "en stock"</Title>
				<Paragraph>
					Para hacer compras o contratar otros servicios a través del sitio web que no requieran recetas médicas y que se encuentren en stock, el Usuario deberá
					seguir los siguientes pasos, haciendo click en el campo correspondiente (el procedimiento de compra o reservas para productos que requieren receta
					médica o que no están en stock, se explican en la siguiente sección 5):
					<br />
					<br /> 1. Ingresar a www.farmaciajvf.com.
					<br /> 2. Buscar los productos a través de la barra de búsqueda ubicada en la parte superior o navegando dentro del sitio web, a través de las
					Categorías y/o Secciones que contengan productos.
					<br /> 3. Seleccionar el botón Agregar del producto y/o servicio que desee comprar.
					<br /> 4. Ingresar la dirección de entrega o una dirección de referencia para efectos de calcular la distancia con su respectivo costo/tiempo estimado
					de entrega.
					<br /> 5. Elegir entre Despacho a Domicilio o Retiro en Farmacia. <br />
					6. Completar los datos personales de entrega, aceptar los T&C y PP y seleccionar el día y hora de entrega que mejor se adapten a su necesidad. <br />
					7. Seleccionar el medio de pago con el que efectuará la transacción.
					<br /> 8. En caso de no estar registrado y desear hacerlo, pinchar sobre el botón “Haz click aquí”, al lado de ¿Aún no estás registrado? <br />
					9. La orden luego pasará automáticamente a un proceso de confirmación de identidad, resguardando siempre la seguridad y privacidad del Usuario y del
					proceso de contratación, disponibilidad, vigencia y cupo del medio de pago que se haya seleccionado.
					<br /> 10. Habiéndose cumplido lo anterior, se perfecciona el pedido y se realiza el cargo en el medio de pago utilizado, enviándole al usuario el
					comprobante de compra con la boleta o factura que corresponda en formato electrónico. La entrega del producto se realizará de acuerdo al tipo de
					entrega seleccionado por el usuario.
				</Paragraph>
				<Title level={3}>5. Venta o reserva de productos bajo receta médica</Title>
				<Paragraph>
					Farmacia JVF solo venderá al público aquellos productos farmacéuticos que requieran Receta Médica, previa exhibición y validación de la misma por
					parte de un profesional habilitado, salvo aquellos que se autoricen para su venta directa en el respectivo Registro Sanitario otorgado por Instituto
					de Salud Pública.
					<br />
					<br /> De conformidad con las disposiciones legales, se entenderá por:
					<br /> a) “Receta Médica”: el instrumento privado, gráfico o electrónico, mediante el cual el profesional habilitado para prescribir, indica a una
					persona identificada y previamente evaluada, como parte integrante del acto médico y, por consiguiente, de la relación clínica, el uso y las
					condiciones de empleo de un producto farmacéutico. <br />
					b) “Receta Magistral”: aquella receta en que un profesional legalmente habilitado para ello prescribe una fórmula especial para un paciente
					determinado, la que debe elaborarse en el momento de su presentación.
					<br /> c) “Receta Médica Retenida”: aquella receta en la que se prescriban productos sujetos a esta condición de venta, y ella deberá archivarse en el
					establecimiento.
					<br /> d) “Receta Cheque”: aquella por medio de la cual se prescriben productos estupefacientes o psicotrópicos y que se extiende empleando los
					formularios oficiales, gráficos o electrónicos.
					<br /> e) “Producto Farmacéutico” o “Medicamento”: cualquier sustancia natural, biológica, sintética o las mezclas de ellas, originada mediante
					síntesis o procesos químicos, biológicos o biotecnológicos, que se destine a las personas con fines de prevención, diagnóstico, atenuación,
					tratamiento o curación de las enfermedades o sus síntomas o de regulación de sus sistemas o estados fisiológicos particulares, incluyéndose en este
					concepto los elementos que acompañan su presentación y que se destinan a su administración. <br />
					<br />
					En el caso de presentación de Receta Médica, el proceso de pedido será similar al indicado en la sección 4 de estos Términos y Condiciones, con la
					diferencia de que, una vez seleccionado el tipo de entrega, el Usuario deberá adjuntar la imagen de la Receta Médica antes de pasar al paso 6. En este
					caso, si se comprueba por parte del farmacéutico de Farmacia JVF que: (i) la Receta Médica no cumple con la disposición legal, (ii) la Receta Médica
					no es legible y/o (iii) el Medicamento y la Receta Médica no coinciden, la respectiva farmacia podrá rechazar el pedido correspondiente, dándole al
					Usuario hasta 3 intentos para regularizar el motivo del rechazo. En caso de que el Usuario no regularice el motivo del rechazo o solicite el
					reembolso, Farmacia JVF se encargará de llevar a cabo dicho reembolso.
					<br /> En el caso de Receta Médica Retenida, el proceso de pedido será similar al del párrafo anterior, con la diferencia de que, además de adjuntar
					la imagen de la Receta Médica al momento de efectuar la compra o reserva, el Usuario deberá hacer entrega de la misma al personal autorizado para el
					despacho y entrega del pedido, en caso de que el tipo de entrega seleccionado sea el de despacho a domicilio, o al personal autorizado para dispensar
					en la farmacia, en el caso de que el tipo de entrega seleccionado sea el de retiro en farmacia.
					<br />
					<br /> Para el caso de la Reserva de productos, esto es, pedidos de productos que no necesariamente están en stock de la farmacia, en vez del botón de
					Agregar descrito en el paso 3 de la sección 4 de estos Términos y Condiciones, al Usuario se le disponibilizará el botón de Reservar. En este caso, el
					Usuario deberá seguir los pasos 1-6 descritos en la sección 4 de estos Términos y Condiciones. Una vez solicitada la Reserva, la Receta Médica será
					revisada y validada por profesionales farmacéuticos de la farmacia y ésta podrá enviar la confirmación de la orden de compra al Usuario en un plazo
					máximo de 48 horas al correo electrónico indicado por el Usuario. El Usuario puede aceptar o rechazar la orden de compra según desee. Sólo después de
					que el Usuario haya aceptado la orden de compra y se haya realizado el resto del proceso de pedido descrito en la sección 4 de estos Términos y
					Condiciones, se establece un contrato vinculante entre el Usuario y la farmacia correspondiente al precio indicado en la orden de compra.
					<br />
					<br /> En el caso de una Receta Magistral, el proceso de pedido será distinto al indicado en la sección 4 de estos Términos y Condiciones, ya que el
					Usuario deberá subir la imagen de su Receta Médica Magistral a través de la sección de “Recetario Magistral” dentro del Menú “Servicios” ubicado en el
					Header del Home de Farmacia JVF, junto con los datos que se solicitan para efectos de que la farmacia que preste este servicio, pueda realizar sus
					cotización, en base a (i) Precio y (ii) Tiempo estimado de Preparación. La farmacia podrá enviar su cotización al Usuario en un plazo máximo de 48
					horas al correo electrónico indicado por el Usuario. El Usuario puede aceptar o rechazar esta cotización según desee. Sólo después de que el Usuario
					haya aceptado la cotización y se haya realizado el resto del proceso de pedido descrito en la sección 4 de estos Términos y Condiciones, se establece
					un contrato vinculante entre el Usuario y la farmacia correspondiente al precio indicado en la cotización.
				</Paragraph>
				<Title level={3}>6. Precios</Title>
				<Paragraph>
					Farmacia JVF, pondrá a disposición del Usuario, en el sitio web www.farmaciajvf.com, un catálogo que contendrá el listado con los precios de los
					productos ofrecidos. Estos pueden cambiar en cualquier momento y no necesariamente serán iguales a los de venta en físico, pudiendo la Farmacia JVF
					disponibilizar productos y precios exclusivos para su canal online. Sin embargo, no se modificarán las condiciones bajo las cuales los Usuarios hayan
					contratado en el sitio web. Los precios contenidos en el catálogo siempre incluirán el Impuesto al Valor Agregado (I.V.A), el que deberá ser pagado
					por el Usuario. <br />
					<br />
					En el caso de productos solicitados por Receta Magistral, la farmacia le enviará al Usuario cotizaciones indicando el precio, tal como se describe en
					la sección 5. Cualquier modificación en la información publicada en el sitio web, incluyendo las referidas a productos, precios, existencias,
					condiciones, promociones y ofertas, nunca afectará a una orden de compra ya efectuada por el Usuario y siempre se referirá a operaciones futuras, sin
					afectar, en caso alguno, los derechos adquiridos por el Usuario. <br />
					<br />
					El uso del sitio web es totalmente gratuito para el Usuario.
				</Paragraph>
				<Title level={3}>7. Medios de pago</Title>
				<Paragraph>
					Salvo que se señale un medio diferente para casos u ofertas específicos, los productos y servicios ofrecidos en el sitio web sólo podrán ser pagados
					por medio de:
					<br />
					<br /> 1. Tarjetas de crédito bancarias Visa, Mastercard, Dinners Club International o American Express, emitidas en Chile o en el extranjero;
					<br />
					2. Tarjetas de débito bancarias acogidas al sistema Redcompra o del sistema que se encuentre vigente al momento del pago, emitidas en Chile por bancos
					nacionales; <br />
					3. Otros medios de pago electrónicos que pueda tener contratado la farmacia para su sitio web como Khipu, Servipag, Paypal, entre otros. <br />
					<br />
					El pago con tarjetas de débito se realizará a través de WebPay pago o del sistema que se encuentre vigente al momento del pago electrónico, que es un
					sistema encargado de hacer el cargo automático a la cuenta bancaria del Usuario.
					<br />
					<br /> Los Usuarios declaran que entienden que estos medios de pago o portales de pago pertenecen a terceras empresas proveedoras de estos servicios,
					independientes a la farmacia, por lo que la continuidad de su prestación de servicios en el tiempo, así como el correcto funcionamiento de sus
					herramientas y botones de pago en línea, será de exclusiva responsabilidad de las empresas proveedoras de estos servicios y en ningún caso de la
					farmacia. Los proveedores de los sistemas de pago son los únicos responsables de la indisponibilidad de los sistemas de pago o de los retrasos en los
					pagos o reembolsos. <br />
					<br />
					El pago a través del sitio web es gratuito; el Usuario puede estar sujeto a comisiones u otros cobros que le cobre su respectivo banco por el uso de
					tarjetas de crédito u otros medios de pago, sobre los que la farmacia no tiene control.
				</Paragraph>
				<Title level={3}>8. Derechos, garantías y devoluciones</Title>
				<Paragraph>
					Si un producto comprado llegase a presentar defectos, todos los derechos existentes en virtud de las leyes aplicables, en particular la Ley sobre
					Protección de los derechos de los Consumidores, deben ser ejercidos por el Usuario directamente contra la farmacia que ha celebrado el respectivo
					contrato con el Usuario.
					<br />
					<br /> El Usuario podrá poner término al contrato celebrado con la farmacia de forma unilateral y sin expresión de causas en un plazo de 10 días desde
					la recepción del producto o la puesta en marcha del servicio y antes de la prestación del mismo. Respecto a las Reservas, productos prescritos por
					Receta Magistral o, de otra forma, confeccionados a la medida, productos que requieren cuidados específicos, como por ejemplo, productos que requieren
					cadena de frío, productos claramente alterados en sus sellos y/o empaques, el derecho a retracto está expresamente excluido. Posterior a la aprobación
					y/o confirmación de un pedido de tales productos, no es posible, bajo ningún concepto, solicitar anulación del mismo.
					<br /> En caso de proceder con la devolución de dinero, la farmacia realizará el abono, mediante transferencia bancaria, en un período no superior a 7
					días hábiles de haberse aceptado, por la farmacia respectiva, la devolución, cuestión que será informada a través del correo electrónico que se
					hubiere registrado o informado.
				</Paragraph>
				<Title level={3}>9. Entrega de productos</Title>
				<Paragraph>
					El Usuario podrá elegir si desea retirar el producto adquirido en la farmacia con la que ha celebrado un contrato, o seleccionar la opción de despacho
					a domicilio del producto, según las condiciones indicadas en el sitio web.
					<br />
					<br />
					<Title level={5}>a. Despacho de productos</Title>
					<br />
					Las condiciones de despacho y entrega de los productos adquiridos podrán ser escogidas de entre las opciones ofrecidas en el sitio web en caso de que
					Farmacia JVF disponibilice este servicio. En tal caso, los tiempos de despacho serán informados a través de elementos gráficos o textos en el sitio
					web y/o en la descripción del producto correspondiente. <br />
					<br />
					La información del lugar de envío es de exclusiva responsabilidad del Usuario. <br />
					<br />
					1. Datos de despacho
					<br /> Será responsabilidad del Usuario la exactitud de los datos entregados en el sitio web para una correcta y oportuna entrega de los productos en
					el domicilio o lugar de despacho.
					<br />
					<br /> Los cambios de lugar de despacho serán revisados caso a caso desde la farmacia y deberán ser solicitados por el Usuario, a través de un correo
					electrónico a contactofarmaciajvf@gmail.com o llamando al teléfono +56964763510, con una anticipación de al menos 24 horas antes de la fecha de
					entrega.
					<br />
					<br /> 2. Entrega de producto.
					<br /> a) Los despachos a domicilio se realizan de: lunes a viernes, en los tramos de tiempo indicados como disponibles para tal efecto en el sitio
					web. Los días festivos no se consideran para el plazo de entrega, salvo que la farmacia informe lo contrario. <br />
					b) El Usuario debe revisar si el producto entregado corresponde a su compra y que se encuentre en buen estado. <br />
					c) Si el producto es un Medicamento bajo venta de Receta Médica, previo a la entrega, se procederá a la validación de la receta, de acuerdo al proceso
					descrito en la sección 5 de los presentes Términos y Condiciones. <br />
					d) La recepción debe realizarse por un mayor de edad, quien deberá firmar y escribir su nombre y Rut en la Orden de Compra (ya sea física o virtual),
					para acreditar la recepción conforme. <br />
					e) En la eventualidad de que el producto sea recibido por un tercero (familiares, asesores del hogar, conserjes, etc.) el Usuario acepta que el
					producto se entenderá recibido conforme. Si el producto es un Medicamento bajo venta de Receta Médica, se debe cumplir con la validación descrita en
					la sección 5 de los presentes Términos y Condiciones.
					<br />
					<br /> 3.Garantía de Despacho El compromiso de la farmacia es despachar en forma oportuna los productos, de acuerdo con los plazos de despachos
					informados en el sitio web. Si esto no llegara a suceder, se programará un próximo envío o se gestionará la devolución de la compra, a elección del
					Usuario. <br />
					<br />
					En el caso anterior, el Usuario deberá enviar un correo electrónico a contactofarmaciajvf@gmail.com o llamar al teléfono +56964763510 o enviar un
					mensaje de Whatsapp al +56964763510 y la farmacia gestionará el próximo envío o la devolución de la compra, según lo que el Usuario haya elegido.
					<br />
					<br /> La garantía quedará sin efecto en caso de:
					<br />
					<br /> a) Que los datos de la dirección de despacho no sean correctos.
					<br /> b) Cualquier otra situación atribuible a la culpa del Usuario. <br />
					c) En caso fortuito o fuerza mayor, por el cual se hace imposible la entrega.
					<br />
					<br />
					<Title level={5}>b. Retiro en farmacia</Title>
					<br />
					Si el Usuario elige retirar el producto en la farmacia, podrá hacerlo en el horario en que ésta se encuentre abierta al público. El horario se
					establece de lunes a viernes, dentro de los tramos de tiempo indicados para tal efecto. Los fines de semana o días festivos podrán o no, ser
					habilitados para el retiro en la farmacia. <br />
					<br />
					Los retiros deberán ser realizados dentro de un plazo determinado, el cual se indicará en la confirmación de la compra. Los comprobantes de esta
					gestión quedarán disponibles durante 30 días. Si el producto no fuera retirado dentro del plazo especificado, la transacción quedará sin efecto,
					haciéndose la devolución de la cantidad pagada al Usuario, a menos que se trate de productos reservados exclusivamente por el Usuario, productos
					prescritos por Receta Magistral o, de otra forma, confeccionados a la medida del Usuario. Si, en los últimos dos casos no es razonablemente posible
					hacer otro uso del producto, éste será destruido sin que el Usuario tenga derecho a la devolución de dinero.
				</Paragraph>
				<Title level={3}>10. Limitación de responsabilidad</Title>
				<Paragraph>
					En ningún caso, la farmacia responderá por:
					<br />
					<br /> 1. La utilización indebida que Usuarios del sitio web puedan hacer de los materiales exhibidos, de los derechos de propiedad industrial y de
					los derechos de propiedad intelectual.
					<br /> 2. Daños o eventuales daños y perjuicios que se le puedan causar a los Usuarios por el funcionamiento de las herramientas de búsqueda y de los
					errores que se generen por los elementos técnicos del sitio web o motores de búsqueda.
					<br /> 3. Contenidos de las páginas a las que los Usuarios puedan acceder con o sin autorización de la farmacia.
					<br /> 4. El acceso de menores de edad o personas sin capacidad, bajo los términos de la legislación correspondiente, a los contenidos adherentes a la
					relación contractual que surja del sitio web.
					<br /> 5. Pérdida, mal uso o uso no autorizado de su código de validación, ya sea por parte del Usuario o de terceros, luego de realizada la compra en
					la forma expresada en los Términos y Condiciones. Asimismo, la farmacia y el Usuario reconocen y dejan constancia que las plataformas computacionales
					proporcionadas por la farmacia no son infalibles, y por tanto, durante la vigencia de los presentes Términos y Condiciones, pueden verificarse
					circunstancias ajenas a la voluntad de la farmacia, que impliquen que el Servicio o las plataformas computacionales no se encuentren operativas
					durante un determinado periodo de tiempo. En tales casos, la farmacia procurará restablecer la operación del servicio con la mayor celeridad posible,
					sin que por ello pueda imputársele algún tipo de responsabilidad. <br />
					6. Información de la farmacia o sus servicios que se encuentre en sitios distintos a www.farmaciajvf.com.
					<br />
					<br /> Farmacia JVF no se hace responsable por los virus ni otros elementos en los documentos electrónicos almacenados en los sistemas informáticos de
					los Usuarios, ni responderá de los perjuicios ocasionados al Usuario, provenientes del uso inadecuado de las tecnologías puestas a disposición de
					éste, cualquiera sea la forma en la cual se utilicen, de forma inadecuada, estas tecnologías, ni tampoco responderá de los daños producidos al sitio
					por el uso indebido y de mala fe de los Usuarios y/o compradores. No obstante, en el evento de realizarse un doble pago por un Usuario en el sitio
					web, la farmacia devolverá la suma del sobrepago, dentro de los 7 días hábiles siguientes a la recepción del respectivo reclamo escrito del Usuario,
					en el que se anexen los originales de los comprobantes del pago adicional a lo adquirido.
					<br />
					<br /> Farmacia JVF tiene el derecho, pero no la obligación, de examinar el contenido creado y subido por los Usuarios al sitio web; sin que exista
					garantía alguna por parte de la farmacia, más allá de sus mejores esfuerzos de velar por la excelencia o calidad de dicho contenido, de que éste se
					ajuste a los requerimientos o expectativas de los Usuarios y de que sean veraces o de fuente confiable. Por lo tanto, la farmacia no será responsable
					del contenido creado y subido por sus Usuarios, y por el uso indebido del sitio web por parte de los Usuarios, y de los daños y perjuicios que dicho
					contenido o uso indebido pueda causarles. Cada Usuario es responsable del uso que haga del Servicio y el sitio web puesto a disposición por la
					farmacia. <br />
					<br />
					Farmacia JVF no será responsable por interrupciones, errores, falta de disponibilidad y pérdidas de información en su Servicio y del sitio web. Tales
					Servicios se entregan “tal como están” y según disponibilidad para su uso, la cual puede estar sujeta a cambios o variaciones. La farmacia no
					garantiza que el Servicio y el sitio web permanecerán libres de virus informáticos, interferencias de información o ataques de hackers, por lo que no
					será responsable de los daños que tales circunstancias puedan provocar al Usuario, aun cuando la farmacia hubiera sido avisada de su posible
					existencia. Tampoco garantiza que cualquier error en el Servicio será corregido. <br />
					<br />
					Farmacia JVF realizará esfuerzos razonables para resguardar la información proporcionada por sus Usuarios. Sin embargo, el envío de dicha información,
					su veracidad y manipulación por parte del Usuario será de su exclusiva responsabilidad. <br />
					<br />
					El Usuario deberá mantener indemne a la farmacia frente a toda acción, demanda, procedimiento, pérdida y daño de cualquier tipo, gastos (incluyendo
					costas y gastos legales), sufridos o incurridos por la farmacia por parte de otros usuarios o terceras personas, como consecuencia de la falta de
					cumplimiento de los presentes Términos y Condiciones.
					<br />
					<br /> Todo lo publicado en el sitio web de la farmacia no tiene por finalidad la automedicación o autodiagnóstico, por consiguiente, no reemplaza la
					consulta a un médico especialista. Por lo tanto, la compra realizada por el Usuario es por su propia cuenta y riesgo, eximiendo de responsabilidad a
					la farmacia de mal uso o almacenamiento de los productos.
				</Paragraph>
				<Title level={3}>11. Seguridad de datos y datos personales</Title>
				<Paragraph>
					<Title level={5}>a. Responsabilidad de los Usuarios respecto de la información registrada en las Plataformas</Title>
					<br />
					Farmacia JVF adoptará las medidas necesarias y prudentes para resguardar la seguridad de los datos y clave secreta, como sistemas de encriptación de
					información, certificados de seguridad u otros que estime pertinente. En caso de detectarse cambios en la información que el Usuario haya registrado
					en el sitio web, o bien, ante cualquier irregularidad en las transacciones relacionadas con su identificación o la del medio de pago, o simplemente
					como medida de protección a su identidad, desde la farmacia podrán contactarle por vía telefónica o correo electrónico, a fin de corroborar sus datos
					e intentar evitar posibles fraudes.
					<br />
					<br /> En caso de no poder establecer el contacto en un plazo de 72 hrs, por su propia seguridad, su orden de compra efectuada en el sitio web no
					podrá ser confirmada. Se le informará vía telefónica o por correo electrónico al Usuario que su orden ha quedado sin efecto por no poder confirmar su
					identidad o el medio de pago ofrecido. Además, los comprobantes de las gestiones realizadas para el contacto y confirmación de la operación, estarán
					disponibles en las oficinas de la farmacia durante 30 días, para que pueda confirmar la orden de compra. Cualquier consulta puede ser efectuada a
					contacto@farmaciajvf.com
					<Title level={5}>
						<br />
						b. Datos personales
					</Title>
					<br />
					Los datos personales que los Usuarios suministren en el sitio web pasarán a formar parte de una base de datos de la farmacia y serán destinados para
					gestionar los pedidos, comunicarse con los Usuarios, validar los datos de la compra, realizar el despacho, responder consultas y para fines
					promocionales. Los datos posiblemente serán comunicados a proveedores de servicios que apoyen a la farmacia en brindar los servicios y cumplir con sus
					obligaciones. Las farmacias son responsables del tratamiento de los datos personales del Usuario, y si el Usuario tiene alguna duda sobre el
					tratamiento de sus datos por parte de la farmacia, deberá ponerse en contacto con ella directamente o revisar sus políticas de privacidad.
					<br />
					<br /> Farmacia JVF solo solicitará datos financieros a través de correo electrónico para realizar las debidas reversas.
					<br />
					<br /> Los Usuarios garantizan que sólo proporcionarán los datos personales que les pertenezcan o para cuyo tratamiento los respectivos titulares
					hayan dado su consentimiento. Los Usuarios, con la aceptación de los presentes Términos y Condiciones, manifiestan su consentimiento para el
					tratamiento de sus datos personales en la forma aquí descrita.
					<br />
					<br /> La farmacia garantiza a los usuarios el libre ejercicio de sus derechos de información, modificación, oposición, cancelación y bloqueo de sus
					datos personales establecidos en la Ley 19.628.
				</Paragraph>
				<Title level={3}>12. Alcance de las condiciones informadas en el sitio web</Title>
				<Paragraph>
					Farmacia JVF no modificará las condiciones bajo las cuales haya contratado con los Usuarios en el sitio web. Mientras aparezcan en el sitio web, los
					precios informados estarán a disposición del Usuario, aunque no sean los mismos que se ofrezcan en otros canales de venta de la farmacia, como tiendas
					físicas, catálogos, televisión, radio, u otros.
					<br />
					<br /> Con todo, los costos de entrega son aplicables para la cobertura geográfica de entrega o despacho. Si, una vez ingresado un producto al carro
					de compras, es cambiada la dirección de entrega o de referencia a una diferente, podría cambiar el precio total del pedido, de acuerdo a los costos de
					entrega asociados a la nueva dirección registrada. <br />
					<br />
					Las promociones ofrecidas en el sitio web no necesariamente serán las mismas que la farmacia ofrezca por otros canales de venta. En las promociones
					que consistan en el envío gratuito o rebajado de un producto por la compra de otro, el despacho del bien que se entregue gratuitamente o a precio
					rebajado, se hará en el mismo lugar al cual se despacha el producto comprado, salvo que el Usuario solicite, al aceptar la oferta, que los productos
					se remitan a direcciones distintas, en cuyo caso deberá pagar el valor del despacho de ambos productos. No se podrá participar en estas promociones
					sin adquirir conjuntamente todos los productos comprendidos en ellas.
				</Paragraph>
				<Title level={3}>13. Uso permitido del sitio web</Title>
				<Paragraph>
					El Usuario no podrá: <br />
					<br />
					a) Realizar o facilitar actos de ingeniería reversa, decodificar o modificar de cualquier manera el sitio web, así como también, adulterar sus medidas
					de protección efectivas.
					<br /> b) Interceptar, examinar u observar cualquier tipo de comunicación utilizada por clientes, el servidor, el sitio web o el Servicio de la
					farmacia, ya sea mediante alguna técnica o uso de algún tipo de herramienta o software. <br />
					c) Ingresar al sitio web y utilizar el Servicio sin tener autorización para hacerlo o mediante cuentas que no le pertenezcan.
					<br /> d) Proporcionar antecedentes falsos o no ajustados a la realidad, para el registro y utilización del sitio web, como también actos de
					suplantación de terceras personas o Usuarios. <br />
					e) Utilizar el Servicio para fines distintos de los contemplados en los presentes Términos y Condiciones, o la realización de actos contrarios a la
					moral y a las buenas costumbres, tales como acoso y abuso a los demás Usuarios.
					<br /> f) Todo acto que, mediante la utilización del Servicio, pueda suponer una infracción a los derechos de propiedad intelectual de otros Usuarios
					o terceras personas.
				</Paragraph>
				<Title level={3}>14. Propiedad intelectual</Title>
				<Paragraph>
					Todos los contenidos incluidos en el sitio web, como textos, material gráfico, logotipos, íconos de botones, imágenes, código fuente, audio clips,
					descargas digitales y compilaciones de datos, son propiedad de la farmacia o de terceros que hayan concedido a la farmacia el derecho a utilizar estos
					contenidos, y están protegidos por las leyes chilenas e internacionales sobre propiedad intelectual.
					<br />
					<br /> Todos los derechos no expresamente otorgados en estos Términos y Condiciones son reservados por la farmacia o sus cesionarios, proveedores,
					editores, titulares de derechos u otros proveedores de contenidos. Ningún producto, imagen o sonido pueden ser reproducidos, duplicados, copiados,
					vendidos, revendidos, visitados o explotados para ningún fin, en todo o en parte, sin el consentimiento escrito previo de la farmacia. No se puede
					enmarcar o utilizar técnicas de enmarcación para encerrar alguna marca comercial, logotipo u otra información registrada o patentada (incluyendo
					imágenes, texto, disposición de páginas, o formulario) de la farmacia, sin su consentimiento previo y por escrito. Tampoco se puede usar meta
					etiquetas ni ningún otro “texto oculto” que use el nombre o marcas comerciales de la farmacia, sin autorización escrita previa. Se prohíbe hacer uso
					indebido del sitio web o de estas marcas, licencias o patentes. Lo anterior, sin perjuicio de las excepciones expresamente señaladas en la ley.
					<br />
					<br /> La utilización que el Usuario haga de la propiedad intelectual de la farmacia será exclusivamente para el uso del Servicio. Por consiguiente,
					queda estrictamente prohibido cualquier acto de reproducción, adaptación, distribución y explotación no autorizada, de los objetos protegidos por la
					propiedad intelectual de la farmacia.
				</Paragraph>
				<Title level={3}>15. Legislación aplicable y competencia</Title>
				<Paragraph>
					Los presentes Términos y Condiciones se rigen por las leyes de la República de Chile. Cualquier controversia o conflicto derivado de la utilización
					del sitio web de la farmacia, sus Términos y Condiciones y la Política de Privacidad, su validez, interpretación, alcance o cumplimiento, será
					sometida a las leyes aplicables de la República de Chile, fijándose como domicilio la ciudad y comuna de Santiago de Chile y sometiéndose Farmacia JVF
					y los Usuarios, a la jurisdicción de sus tribunales ordinarios de justicia.
				</Paragraph>
				<Title level={3}>16. Término y vigencia del servicio</Title>
				<Paragraph>
					El no cumplimiento de los presentes Términos y Condiciones facultará a Farmacia JVF para cerrar la cuenta del Usuario en cuestión, quedando el Usuario
					obligado a pagar todos los Servicios y productos prestados hasta la fecha.
					<br /> La farmacia se reserva el derecho a modificar, suspender o descontinuar el Servicio (o cualquier parte o contenido) en caso de incumplimiento
					de los presentes Términos y Condiciones, en cualquier momento con o sin mediar notificación.
				</Paragraph>
			</Content>
		</Layout>
	);
}
