/** @format */

import React, { Component } from 'react';

import { formatPrice } from '../../../../../../../../components/Commons/Hooks/Functions/FormatPrice';
import Images from '../../../../../../../../components/Commons/Images';
import Spacer from '../../../../../../../../components/Commons/Spacer';

import './style.scss';

export default class Services extends Component {
	render() {
		return (
			<>
				<div className="ph-order-detail-products-item-service-main-container">
					<hr className="ph-order-detail-products-item-service-separator" />
					<h3 className="ph-order-detail-products-item-service-main-title">Servicios Adquiridos: ({this.props.services.length})</h3>
					{this.props.services.map(function (item, i) {
						return (
							<div className="ph-order-detail-products-item-service-container" key={i}>
								<div className="ph-order-detail-products-item-service-inner-container">
									<Images classContent={'ph-order-detail-products-item-service-image-container'} classImage={'ph-order-detail-products-item-service-image'} default={item.image} title={item.title} />
									<div className="ph-order-detail-products-item-service-title-container">
										<h3 className="ph-order-detail-products-item-service-title">
											{item.title} ({item.quantity})
										</h3>
										<h3 className="ph-order-detail-products-item-service-description">{item.description}</h3>
									</div>
								</div>
								<Spacer />
								<div>
									<div className="ph-order-detail-products-item-service-price-container">
										<h3 className="ph-order-detail-products-item-service-price-title">Precio x unidad:</h3>
										<h3 className="ph-order-detail-products-item-service-price-description">{item.price && <>{formatPrice(item.price)}</>}</h3>
									</div>
									<div className="ph-order-detail-products-item-service-price-container">
										<h3 className="ph-order-detail-products-item-service-price-title">Total:</h3>
										<h3 className="ph-order-detail-products-item-service-price-description">{item.subtotal_services && <> {formatPrice(item.subtotal_services)} </>}</h3>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</>
		);
	}
}
