import { Events } from './events.constant';
export const PromotionProductAdapter = ({ event = Events.view_item, product, position = 1 }) => {
	let idName = `general-${product.id_pharol}`;
	if (product.promotions === null) return;

	if (product.pharmacy !== undefined) {
		idName = `${product.pharmacy}-${product.code_internal}`;
	}
	console.log('PromotionProductAdapter', { event, product, position, idName });
	window.dataLayer.push({ ecommerce: null });
	window.dataLayer.push({
		event: event,
		ecommerce: {
			items: [
				{
					item_name: product.editable_name,
					item_id: idName,
					item_brand: product.laboratory,
					item_category: product.master_category,
					item_category2: product.category,
					item_category3: product.sub_category,
					quantity: product.stock,
					price: product.price,
					index: position,
				},
			],
		},
	});
};
