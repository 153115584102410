/** @format */

import React from 'react';
import { useTranslation } from 'react-i18next';

import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Button from 'antd/lib/button';

import MetaDescription from '../../components/Commons/MetaDescription';
import Images from '../../components/Commons/Images';
import { ENV_IMG_STATIC } from '../../components/Commons/Hooks/Variables/Enviroment';

import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';

import './style.scss';

export default function Mediclic() {
	const { t } = useTranslation('global');

	return (
		<div>
			<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			<div className="ph-mediclic-global-container">
				<div className="ph-mediclic-header-container">
					<Row justify="center">
						<Col>
							<ScrollAnimation animateIn="animate__fadeInUp" delay={1000} animateOnce={true}>
								<h1 className="ph-mediclic-title">
									¡CONSULTA AL <span className="ph-mediclic-title-detail">DOCTOR</span> CUANDO QUIERAS!
								</h1>
							</ScrollAnimation>
							<ScrollAnimation animateIn="animate__fadeInUp" delay={1500} animateOnce={true}>
								<p className="ph-mediclic-subtitle">
									Le damos la bienvenida en Pharol a Mediclic, un servicio de videollamadas para consultas médicas totalmente online. Ahorra tu tiempo y dinero, recibe atención profesional al instante
									desde donde estés.
								</p>
							</ScrollAnimation>
							<ScrollAnimation animateIn="animate__fadeInUp" delay={2000} animateOnce={true}>
								<div>
									<a href="https://www.mediclic.cl/" target="_blank" rel="noopener noreferrer">
										<Images
											classContent={'ph-mediclic-logo-content'}
											classImage={'ph-mediclic-logo-img'}
											default={`${ENV_IMG_STATIC}/mediclic/logo.png`}
											webp={`${ENV_IMG_STATIC}/mediclic/logo.webp`}
											jp2={`${ENV_IMG_STATIC}/mediclic/logo.jp2`}
											jxr={`${ENV_IMG_STATIC}/mediclic/logo.jxr`}
											title={'Mediclic logo'}
											alt={'Mediclic logo'}
										/>
									</a>
								</div>
							</ScrollAnimation>
							<ScrollAnimation animateIn="animate__fadeInUp" delay={2500} animateOnce={true}>
								<div>
									<Images
										classContent={'ph-mediclic-banner-content'}
										classImage={'ph-mediclic-banner-img'}
										default={`${ENV_IMG_STATIC}/mediclic/alianza.webp`}
										webp={`${ENV_IMG_STATIC}/mediclic/alianza.webp`}
										jp2={`${ENV_IMG_STATIC}/mediclic/alianza.jp2`}
										jxr={`${ENV_IMG_STATIC}/mediclic/alianza.jxr`}
										title={'Mediclic banner'}
										alt={'Mediclic banner'}
									/>
								</div>
							</ScrollAnimation>
							<ScrollAnimation animateIn="animate__fadeInUp" delay={3000} animateOnce={true}>
								<Button className="ph-mediclic-btn-header" href="https://www.youtube.com/watch?v=ztJkq3UyliA&feature=emb_logo" target="_blank" rel="noopener noreferrer">
									¿QUÉ ES MEDICLIC?
								</Button>
							</ScrollAnimation>
						</Col>
					</Row>
				</div>
			</div>
			<div className="ph-mediclic-main-container">
				<div className="ph-mediclic-images-overlay">
					<ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true}>
						<div className="ph-mediclic-image ph-mediclic-image-sample-1"></div>
					</ScrollAnimation>
					<ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true}>
						<div className="ph-mediclic-image ph-mediclic-image-sample-2"></div>
					</ScrollAnimation>
					<ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true}>
						<div className="ph-mediclic-image ph-mediclic-image-sample-3"></div>
					</ScrollAnimation>
					<ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true}>
						<div className="ph-mediclic-image ph-mediclic-image-sample-4"></div>
					</ScrollAnimation>
					<ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
						<div className="ph-mediclic-image-b ph-mediclic-image-sample-5"></div>
					</ScrollAnimation>
				</div>
				<Row justify="center">
					<Col>
						<ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
							<h3 className="ph-mediclic-main-title">¿Cómo funciona Mediclic?</h3>
						</ScrollAnimation>
					</Col>
				</Row>
				<Row className="ph-mediclic-main-content">
					<Col>
						<ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
							{' '}
							<Images
								classContent={'ph-mediclic-icons-service-content'}
								classImage={'ph-mediclic-icons-service-img'}
								default={`${ENV_IMG_STATIC}/mediclic/medicliccl.png`}
								webp={`${ENV_IMG_STATIC}/mediclic/medicliccl.webp`}
								jp2={`${ENV_IMG_STATIC}/mediclic/medicliccl.jp2`}
								jxr={`${ENV_IMG_STATIC}/mediclic/medicliccl.jxr`}
								title={'Mediclic icons'}
								alt={'Mediclic icons'}
							/>
						</ScrollAnimation>
					</Col>
					<Col>
						<ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
							<h3 className="ph-mediclic-services-titles">Ingresa a Mediclic.cl</h3>
						</ScrollAnimation>
					</Col>
				</Row>
				<div>
					<ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
						<p className="ph-mediclic-services-main-subtitles"> Regístrate e ingresa desde tu computador o dispositivo móvil</p>
					</ScrollAnimation>
				</div>
				<Row className="ph-mediclic-main-content">
					<Col>
						<ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
							{' '}
							<Images
								classContent={'ph-mediclic-icons-service-content'}
								classImage={'ph-mediclic-icons-service-img'}
								default={`${ENV_IMG_STATIC}/mediclic/consulta.png`}
								webp={`${ENV_IMG_STATIC}/mediclic/consulta.webp`}
								jp2={`${ENV_IMG_STATIC}/mediclic/consulta.jp2`}
								jxr={`${ENV_IMG_STATIC}/mediclic/consulta.jxr`}
								title={'Mediclic icons'}
								alt={'Mediclic icons'}
							/>
						</ScrollAnimation>
					</Col>
					<Col>
						<ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
							<h3 className="ph-mediclic-services-titles">Solicita tu consulta</h3>
						</ScrollAnimation>
					</Col>
				</Row>
				<div>
					<ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
						<p className="ph-mediclic-services-main-subtitles"> En pocos minutos estarás hablando con el profesional por video consulta o chat</p>
					</ScrollAnimation>
				</div>
				<Row className="ph-mediclic-main-content">
					<Col>
						<ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
							{' '}
							<Images
								classContent={'ph-mediclic-icons-service-content'}
								classImage={'ph-mediclic-icons-service-img'}
								default={`${ENV_IMG_STATIC}/mediclic/resumen.png`}
								webp={`${ENV_IMG_STATIC}/mediclic/resumen.webp`}
								jp2={`${ENV_IMG_STATIC}/mediclic/resumen.jp2`}
								jxr={`${ENV_IMG_STATIC}/mediclic/resumen.jxr`}
								title={'Mediclic icons'}
								alt={'Mediclic icons'}
							/>
						</ScrollAnimation>
					</Col>
					<Col>
						<ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
							<h3 className="ph-mediclic-services-titles">Recibe tu resumen</h3>
						</ScrollAnimation>
					</Col>
				</Row>
				<div>
					<ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
						<p className="ph-mediclic-services-main-subtitles">Recibirás tu resumen en el correo electrónico y prescripción médica en caso de ser necesario</p>
					</ScrollAnimation>
				</div>
			</div>
			<div className="ph-mediclic-global-container">
				<div className="ph-mediclic-inner-container">
					<div>
						<ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
							<h1 className="ph-mediclic-inner-title">Servicio Mediclic</h1>
						</ScrollAnimation>
					</div>
					<Row justify="center">
						<Col>
							<div>
								<ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
									<Images
										classContent={'ph-mediclic-doctor-content'}
										classImage={'ph-mediclic-doctor-img'}
										default={`${ENV_IMG_STATIC}/mediclic/movildoctor.webp`}
										webp={`${ENV_IMG_STATIC}/mediclic/movildoctor.webp`}
										jp2={`${ENV_IMG_STATIC}/mediclic/movildoctor.jp2`}
										jxr={`${ENV_IMG_STATIC}/mediclic/movildoctor.jxr`}
										title={'Mediclic App Doctor'}
										alt={'Mediclic App Doctor'}
									/>
								</ScrollAnimation>
							</div>
						</Col>
						<Col>
							<div className="ph-mediclic-doctor-icons-container">
								<ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true}>
									{/* 				aqui la clase se llama container? o conteiner?, esta asi en varios lugares */}
									<Row className="ph-mediclic-icon-title-conteiner">
										<Col>
											<Images
												classContent={'ph-mediclic-icons-content'}
												classImage={'ph-mediclic-icons-img'}
												default={`${ENV_IMG_STATIC}/mediclic/atencion.webp`}
												webp={`${ENV_IMG_STATIC}/mediclic/atencion.webp`}
												jp2={`${ENV_IMG_STATIC}/mediclic/atencion.jp2`}
												jxr={`${ENV_IMG_STATIC}/mediclic/atencion.jxr`}
												title={'Mediclic icons'}
												alt={'Mediclic icons'}
											/>
										</Col>
										<Col>
											<h3 className="ph-mediclic-doctor-services-titles">
												Atención médica
												<span className="ph-mediclic-services-subtitles">on demand, sin lista de espera.</span>
											</h3>
										</Col>
									</Row>
								</ScrollAnimation>
								<ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true}>
									<Row className="ph-mediclic-icon-title-conteiner">
										<Col>
											<Images
												classContent={'ph-mediclic-icons-content'}
												classImage={'ph-mediclic-icons-img'}
												default={`${ENV_IMG_STATIC}/mediclic/horarios.webp`}
												webp={`${ENV_IMG_STATIC}/mediclic/horarios.webp`}
												jp2={`${ENV_IMG_STATIC}/mediclic/horarios.jp2`}
												jxr={`${ENV_IMG_STATIC}/mediclic/horarios.jxr`}
												title={'Mediclic icons'}
												alt={'Mediclic icons'}
											/>
										</Col>
										<Col>
											<h3 className="ph-mediclic-doctor-services-titles">
												Horarios
												<span className="ph-mediclic-services-subtitles">de 9:00 a 24:00 Hrs de lunes a lunes.</span>
											</h3>
										</Col>
									</Row>
								</ScrollAnimation>
								<ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true}>
									<Row className="ph-mediclic-icon-title-conteiner">
										<Col>
											{' '}
											<Images
												classContent={'ph-mediclic-icons-content'}
												classImage={'ph-mediclic-icons-img'}
												default={`${ENV_IMG_STATIC}/mediclic/entrega.webp`}
												webp={`${ENV_IMG_STATIC}/mediclic/entrega.webp`}
												jp2={`${ENV_IMG_STATIC}/mediclic/entrega.jp2`}
												jxr={`${ENV_IMG_STATIC}/mediclic/entrega.jxr`}
												title={'Mediclic icons'}
												alt={'Mediclic icons'}
											/>
										</Col>
										<Col>
											<h3 className="ph-mediclic-doctor-services-titles">
												Entrega
												<span className="ph-mediclic-services-subtitles">de receta médica digital.</span>
											</h3>
										</Col>
									</Row>
								</ScrollAnimation>
								<ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true}>
									<Row className="ph-mediclic-icon-title-conteiner">
										<Col>
											{' '}
											<Images
												classContent={'ph-mediclic-icons-content'}
												classImage={'ph-mediclic-icons-img'}
												default={`${ENV_IMG_STATIC}/mediclic/revision.webp`}
												webp={`${ENV_IMG_STATIC}/mediclic/revision.webp`}
												jp2={`${ENV_IMG_STATIC}/mediclic/revision.jp2`}
												jxr={`${ENV_IMG_STATIC}/mediclic/revision.jxr`}
												title={'Mediclic icons'}
												alt={'Mediclic icons'}
											/>
										</Col>
										<Col>
											<h3 className="ph-mediclic-doctor-services-titles">
												Revisión
												<span className="ph-mediclic-services-subtitles">y lectura de exámenes.</span>
											</h3>
										</Col>
									</Row>
								</ScrollAnimation>
								<ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true}>
									<Row className="ph-mediclic-icon-title-conteiner">
										<Col>
											{' '}
											<Images
												classContent={'ph-mediclic-icons-content'}
												classImage={'ph-mediclic-icons-img'}
												default={`${ENV_IMG_STATIC}/mediclic/acceder.webp`}
												webp={`${ENV_IMG_STATIC}/mediclic/acceder.webp`}
												jp2={`${ENV_IMG_STATIC}/mediclic/acceder.jp2`}
												jxr={`${ENV_IMG_STATIC}/mediclic/acceder.jxr`}
												title={'Mediclic icons'}
												alt={'Mediclic icons'}
											/>
										</Col>
										<Col>
											<h3 className="ph-mediclic-doctor-services-titles">
												Acceder cómodamente
												<span className="ph-mediclic-services-subtitles">desde la App en tu casa.</span>
											</h3>
										</Col>
									</Row>
								</ScrollAnimation>
								<ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true}>
									<Row className="ph-mediclic-icon-title-conteiner">
										<Col>
											<Images
												classContent={'ph-mediclic-icons-content'}
												classImage={'ph-mediclic-icons-img'}
												default={`${ENV_IMG_STATIC}/mediclic/losdoctores.webp`}
												webp={`${ENV_IMG_STATIC}/mediclic/losdoctores.webp`}
												jp2={`${ENV_IMG_STATIC}/mediclic/losdoctores.jp2`}
												jxr={`${ENV_IMG_STATIC}/mediclic/losdoctores.jxr`}
												title={'Mediclic icons'}
												alt={'Mediclic icons'}
											/>
										</Col>
										<Col>
											<h3 className="ph-mediclic-doctor-services-titles">
												Los doctores <span className="ph-mediclic-services-subtitles">no recetan psicotrópicos.</span>
											</h3>
										</Col>
									</Row>
								</ScrollAnimation>
								<ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true}>
									<Row className="ph-mediclic-icon-title-conteiner">
										<Col>
											<Images
												classContent={'ph-mediclic-icons-content'}
												classImage={'ph-mediclic-icons-img'}
												default={`${ENV_IMG_STATIC}/mediclic/doctores.webp`}
												webp={`${ENV_IMG_STATIC}/mediclic/doctores.webp`}
												jp2={`${ENV_IMG_STATIC}/mediclic/doctores.jp2`}
												jxr={`${ENV_IMG_STATIC}/mediclic/doctores.jxr`}
												title={'Mediclic icons'}
												alt={'Mediclic icons'}
											/>
										</Col>
										<Col>
											<h3 className="ph-mediclic-doctor-services-titles">
												Doctores registrados <span className="ph-mediclic-services-subtitles">en Superintendencia de Salud.</span>
											</h3>
										</Col>
									</Row>
								</ScrollAnimation>
								<ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true}>
									<Row className="ph-mediclic-icon-title-conteiner">
										<Col justify="start">
											<Button
												className="ph-mediclic-btn-header ph-mediclic-inner-btn"
												href="https://consultas.mediclic.cl/Registro?RPromo=PHAROLCL&RPais=Chile"
												target="_blank"
												rel="noopener noreferrer"
											>
												REGISTRATE AQUÍ
											</Button>
										</Col>
									</Row>
								</ScrollAnimation>
							</div>
						</Col>
					</Row>
				</div>
			</div>
			<div className="ph-mediclic-download-app-container">
				<div className="ph-mediclic-download-app-content">
					<Row justify="center">
						<ScrollAnimation animateIn="animate__zoomIn" animateOnce={true}>
							<h3 className="ph-mediclic-download-app-title">
								No más filas,
								<br />
								no más tiempo perdido
							</h3>
						</ScrollAnimation>
					</Row>
					<Row justify="center">
						<Col>
							<ScrollAnimation animateIn="animate__zoomIn" animateOnce={true}>
								<p className="ph-mediclic-download-app-subtitle">Con la app podrás consultar al doctor desde el lugar que estés, tu salud estará protegida a un clic de distancia.</p>
							</ScrollAnimation>
						</Col>
					</Row>
					<Row justify="center">
						<Col className="ph-mediclic-download-img">
							<ScrollAnimation animateIn="animate__zoomIn" animateOnce={true}>
								<a href="https://play.google.com/store/apps/details?id=com.mediclic.mediclic1&hl=es" target="_blank" rel="noopener noreferrer">
									<Images
										classContent={'ph-mediclic-download-app-content-img'}
										classImage={'ph-mediclic-download-app-img'} 
										default={`${ENV_IMG_STATIC}/mediclic/playstore.png`}
										webp={`${ENV_IMG_STATIC}/mediclic/playstore.webp`}
										jp2={`${ENV_IMG_STATIC}/mediclic/playstore.jp2`}
										jxr={`${ENV_IMG_STATIC}/mediclic/playstore.jxr`}
										title={'Mediclic download-app'}
										alt={'Mediclic download-app'}
									/>
								</a>
							</ScrollAnimation>
						</Col>
						<Col className="ph-mediclic-download-img">
							<ScrollAnimation animateIn="animate__zoomIn" animateOnce={true}>
								<a href="https://apps.apple.com/us/app/mediclic/id1143276587" target="_blank" rel="noopener noreferrer">
									<Images
										classContent={'ph-mediclic-download-app-content-img'}
										classImage={'ph-mediclic-download-app-img'}
										default={`${ENV_IMG_STATIC}/mediclic/appstore.png`}
										webp={`${ENV_IMG_STATIC}/mediclic/appstore.webp`}
										jp2={`${ENV_IMG_STATIC}/mediclic/appstore.jp2`}
										jxr={`${ENV_IMG_STATIC}/mediclic/appstore.jxr`}
										title={'Mediclic download-app'}
										alt={'Mediclic download-app'}
									/>
								</a>
							</ScrollAnimation>
						</Col>
					</Row>
					<Row justify="center">
						<Col>
							<ScrollAnimation animateIn="animate__zoomIn" animateOnce={true}>
								<Images
									classContent={'ph-mediclic-logo-content'}
									classImage={'ph-mediclic-family-img'}
									default={`${ENV_IMG_STATIC}/mediclic/people.png`}
									webp={`${ENV_IMG_STATIC}/mediclic/people.webp`}
									jp2={`${ENV_IMG_STATIC}/mediclic/people.jp2`}
									jxr={`${ENV_IMG_STATIC}/mediclic/people.jxr`}
									title={'Mediclic family'}
									alt={'Mediclic family'}
								/>
							</ScrollAnimation>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	);
}
