/** @format */

import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

import MapChange from '../MapChange';

import 'leaflet/dist/leaflet.css';
import './style.scss';

export default function MapAddress(props) {
	useEffect(() => {
		const markerIcon = require('leaflet');
		delete markerIcon.Icon.Default.prototype._getIconUrl;
		markerIcon.Icon.Default.mergeOptions({
			iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
			iconUrl: require('leaflet/dist/images/marker-icon.png').default,
			shadowUrl: require('leaflet/dist/images/marker-shadow.png').default,
		});
	}, []);

	return (
		<div className="ph-address-modal-map-box-container">
			<MapContainer center={{ lat: props.address.lat, lng: props.address.lng }} zoom={13}>
				<MapChange center={{ lat: props.address.lat, lng: props.address.lng }} zoom={13} />
				<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
				<Marker position={{ lat: props.address.lat, lng: props.address.lng }}>
					<Popup>
						<span>{props.address.address}</span>
					</Popup>
				</Marker>
			</MapContainer>
		</div>
	);
}
