/** @format */

import React from 'react'

import './style.scss'

export default function QuotePharmaciesStock(props) {
	return (
		<>
			<div className='ph-electronic-quote-main-title-stock-container'>
				<h3 className='ph-electronic-quote-main-title-stock-title'>Stock:</h3>
				<h3 className='ph-electronic-quote-main-title-stock-description'>
					{props.stock > 3 && (
						<span className='ph-electronic-quote-main-title-stock-description-green'>
							Disponible
						</span>
					)}
					{props.stock <= 3 && props.stock > 0 && (
						<span className='ph-electronic-quote-main-title-stock-description-orange'>
							Pocas Unidades
						</span>
					)}
					{props.stock <= 0 && (
						<span className='ph-electronic-quote-main-title-stock-description-red'>
							No Disponible
						</span>
					)}
				</h3>
			</div>
		</>
	)
}
