/** @format */

import React from 'react'

import { Pagination } from 'antd'

import { useHistory } from 'react-router-dom'

import './style.scss'

export default function PaginationFavorites(props) {
	let history = useHistory()

	const handlePageChange = (item) => {
		history.push(`/profile/favorites/${item}`)
	}

	return (
		<Pagination
			className='ph-profile-favorites-pagination-container'
			current={props.item.currentPage}
			onChange={handlePageChange}
			total={props.item.totalItems}
			pageSize={props.item.itemsPerPage}
			showTotal={() => `Total ${props.item.totalItems} items`}
		/>
	)
}
