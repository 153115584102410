/** @format */

import axios from 'axios';

import Notify from '../../components/Commons/Notify';

import { ENV_PHAROLGO } from '../../components/Commons/Hooks/Variables/Enviroment';

export async function SendReview(item) {
	let returnResponse;
	await axios({
		method: 'PUT',
		url: `${ENV_PHAROLGO}/calification/${item.key}/${item.stars}`,
		data: {
			comment: item.comment,
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = true;
			} else {
				Notify({
					title: `Error: ${response.data.statusCode}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `${response.data.message}`,
					time: 8,
					image: 'error',
					placement: 'topRight',
				});
			}
		})
		.catch(() => {
			Notify({
				title: `Problemas de Conexión`,
				colorTitle: 'ph-main-notification-error-title',
				description: `Revisa tu conexión o ponte en contacto con nosotros.`,
				time: 5,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
}

export async function CheckReview(key) {
	let returnResponse;
	await axios({
		method: 'GET',
		url: `${ENV_PHAROLGO}/calification/${key}`,
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data;
			} else {
				Notify({
					title: `Error: ${response.data.statusCode}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `${response.data.message}`,
					time: 8,
					image: 'error',
					placement: 'topRight',
				});
			}
		})
		.catch(() => {
			Notify({
				title: `Problemas de Conexión`,
				colorTitle: 'ph-main-notification-error-title',
				description: `Revisa tu conexión o ponte en contacto con nosotros.`,
				time: 5,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
}
