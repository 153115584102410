import { Product } from '../constant/product.constant';

export const handleMecanic = (product, className) => {
	if (product.promotions === null) {
		return <h5 className="mecanic-none"> </h5>;
	}

	if (product.prescryption_type === Product.RMRSCS) {
		return <h5 className={className}> </h5>;
	}

	if (product.prescryption_type === Product.NP) {
		return <h5 className={className}> </h5>;
	}

	if (product.promotions?.rules?.mecanic === Product.mecanicDiscountOff) {
		return <h5 className={className}> - {parseInt(product.promotions?.rules?.benefit)}% OFF</h5>;
	}

	if (product.promotions?.rules?.mecanic === Product.mecanicNxM) {
		return (
			<h5 className={className}>
				{product.promotions?.rules?.quantity_per_requeriment} x {product.promotions?.rules?.quantity_benefic}
			</h5>
		);
	}

	return <h5 className={className}>{product.promotions?.rules?.tag}</h5>;
};
