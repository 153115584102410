/** @format */

import React, { useState } from 'react'
import { Modal, Button } from 'antd'

import resendEmailOrderService from './services'

import './style.scss'

export default function ModalResendEmail(props) {
	const [isLoading, setLoading] = useState(false)

	const handleResendEmailOrder = async (item) => {
		setLoading(true)
		await resendEmailOrderService(item).then(() => {
			setLoading(false)
			props.showModalResendEmail(false)
		})
	}

	return (
		<>
			<Modal
				width={350}
				wrapClassName='ph-detail-resend-email-container'
				centered
				title='Reenviar Correo de la Orden'
				visible={props.visible}
				onCancel={() => props.showModalResendEmail(false)}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
				okText=''
				cancelText=''
				footer={false}>
				<p className='ph-detail-resend-email-description'>
					¿Desea reenviar el correo de confirmación de su compra?
				</p>
				<Button
					className='ph-detail-resend-email-button'
					onClick={() => handleResendEmailOrder(props.orderId)}
					loading={isLoading}>
					Aceptar
				</Button>
			</Modal>
		</>
	)
}
