/** @format */

import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Service } from './services';
import { IMAGES } from '../../v2-config/constants/images.constant';
import { validateUrl } from './handle/validate-url.handle';
import './style.scss';
import { GetLocalStorageValue } from '../../_config/adapters/local-storage/get-local-storage-value.adapter';

export default function Footer(props) {
	const [date] = useState(new Date().getFullYear());
	const [data, setData] = useState(null);
	const [isLoading, setLoading] = useState(true);
	const locals = GetLocalStorageValue('mapPharmacy');
	let mainLocal 
	if (locals) {
		 mainLocal = locals[0]?.locals?.find((local) => local.code_local === '1');

	}

	useEffect(() => {
		new Service()
			.getPharmacies()
			.then((response) => {
				setData(response.data[0]);
				const emailPharmacy = response.data[0].email
				localStorage.setItem('emailPharmacy', JSON.stringify(emailPharmacy))
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	if (isLoading) return null;
	return (
		<>
			<Row className="footer-content" justify="center">
				<Col span={22} className="container">
					<Link to={'/'} className={'text-link logo-link'}>
						<img src={IMAGES.LOGO_DARK_BACKGROUND} alt="logo" className="logo" height={42} />
					</Link>
					<Row justify="center">
						<Col md={6} xs={24} className="footer-module">
							<h3 className="own-medium h3.md">Nosotros</h3>
							<Link to={'/'} className="no-hover">
								{data?.name_pharmacy}
							</Link>
							<br></br>
							<Link to={'/'} className="no-hover">
								{data?.rut}
							</Link>
							<br></br>
							<a href={`tel:${data?.phone}`} className="no-hover">
								{mainLocal?.phone}
							</a>
							<br></br>
							<a href={`mailto::${data?.email}`} className={'text-link'}>
								{data?.email}
							</a>
						</Col>
						<Col md={6} xs={24} className="footer-module">
							<h3 className="own-medium h3.md">Legales</h3>
							<Link to={'/claims'} className={'text-link'}>
								Reclamos y Sugerencias
							</Link>
							<br></br>
							<Link to="/" onClick={() => window.open(validateUrl(data?.faq))} className={'text-link'}>
								Políticas y Devoluciones
							</Link>
							<br></br>
							<Link to="/" onClick={() => window.open(validateUrl(data?.shortage_letter))} className={'text-link'}>
								Carta de desabastecimiento
							</Link>
							<br></br>

							<Link to="/" onClick={() => window.open(validateUrl(data?.electronic_sales_resolution))} className={'text-link'}>
								Resolución Venta Electrónica
							</Link>
							<br></br>
							<Link
								to="/"
								onClick={() => window.open('https://storage.googleapis.com/tirx-resources-develop/b22b7b71-1c6f-4e99-ab3c-9ac328bf5458.webp')}
								className={'text-link'}
							>
								Uso Racional de Medicamentos
							</Link>
						</Col>
						<Col md={6} xs={24} className="footer-module">
							<h3 className="own-medium h3.md">Reglamentación</h3>
							<Link to="/" onClick={() => window.open('https://www.bcn.cl/leychile/navegar?idNorma=13613')} className={'text-link'}>
								Reglamento de Farmacia
							</Link>
							<br></br>
							<Link to="/" onClick={() => window.open('https://www.bcn.cl/leychile/navegar?idNorma=1026879')} className={'text-link'}>
								Reglamento de Productos Farmaceúticos
							</Link>
							<br></br>
							<Link to="/" onClick={() => window.open('https://www.ispch.cl/anamed/farmacovigilancia/nram/')} className={'text-link'}>
								Formulario RAM
							</Link>
						</Col>
						<Col md={6} xs={24} className="footer-module">
							<h3 className="own-medium h3.md">Emergencias toxicológicas</h3>
							<Link to={'/'} className="no-hover">
								Emergencias Toxicológicas
							</Link>
							<br></br>
							<a href={`tel:+56226353800`} className="no-hover">
								Toxicológicas +562-2635-3800
							</a>
							<br></br>
							<a href={`tel:+56222473600`} className="no-hover">
								Químicas +562-2247-3600
							</a>
							<br></br>
							<a href={`tel:+6003607777`} className="no-hover">
								Salud Responde: 600 360 7777
							</a>
						</Col>
					</Row>
				</Col>
				<Col span={24}>
					<Row justify="center" className="copyright">
						<p>Copyright TIRX 2023 - {date}</p>
					</Row>
				</Col>
			</Row>
		</>
	);
}
