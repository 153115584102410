/** @format */

/** @format */

import React from 'react'
import { ENV_COUNTRY } from '../../components/Commons/Hooks/Variables/Enviroment'
import FrequentQuestionsAR from './components/AR'
import FrequentQuestionsCL from './components/CL'

import './style.scss'

export default function TermsConditions(props) {
	console.log(props)
	const handleControllerCountry = (country) => {
		console.log(country)
		switch (country) {
			case 'CL':
				return <FrequentQuestionsCL slug={props.match.params.slug} />
			case 'AR':
				return <FrequentQuestionsAR slug={props.match.params.slug} />
			default:
				return <FrequentQuestionsCL slug={props.match.params.slug} />
		}
	}
	return <>{handleControllerCountry(ENV_COUNTRY)}</>
}
