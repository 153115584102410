/** @format */

import React, { Component } from 'react';

import Images from '../../../../../../../../../../components/Commons/Images';

import './style.scss';

export default class TypeRetirement extends Component {
	render() {
		return (
			<>
				<div className="ph-order-detail-products-retyrement-info-main-container">
					{this.props.item.info.type_retirement === 'domicilio' && (
						<>
							<Images
								classContent={'ph-order-detail-products-retyrement-info-image-container'}
								classImage={'ph-order-detail-products-retyrement-info-image'}
								default={`https://storage.googleapis.com/master-image-bucket/type-delivery/Icono%20Despacho.png`}
								title={'domicilio'}
							/>
							<h3 className="ph-order-detail-products-retyrement-info-title">Despacho a {this.props.item.info.type_retirement}</h3>
						</>
					)}
					{this.props.item.info.type_retirement === 'farmacia' && (
						<>
							<Images
								classContent={'ph-order-detail-products-retyrement-info-image-container'}
								classImage={'ph-order-detail-products-retyrement-info-image'}
								default={`https://storage.googleapis.com/master-image-bucket/type-delivery/Icono%20Retiro.png`}
								title={'farmacia'}
							/>
							<h3 className="ph-order-detail-products-retyrement-info-title">Retiro en {this.props.item.info.type_retirement}</h3>
						</>
					)}
				</div>
			</>
		);
	}
}
