/** @format */

import React from 'react'

import Spacer from '../../../../../../../../../../../../components/Commons/Spacer'

import PromotionDiscount from './components/PromotionDiscount'
import PriceProduct from './components/PriceProduct'

import './style.scss'

export default function ProductPrice(props) {
	return (
		<>
			<div className='ph-electronic-quote-price-main-container'>
				<div className='ph-electronic-quote-price-container'>
					<PriceProduct item={props.item} />
					{props.item.interface && (
						<>
							{props.item.interface.promotions && (
								<>
									{(props.item.interface.promotions.description_promotion ||
										props.item.interface.promotions.percentaje_promotion ||
										props.item.interface.promotions.price_promotion) && <Spacer />}
									<PromotionDiscount promotion={props.item.interface.promotions} />
								</>
							)}
						</>
					)}
				</div>
			</div>
		</>
	)
}
