/** @format */

import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { setGlobal } from 'reactn';
import qs from 'qs';
import { useLocation, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { formatPrice } from '../../../../components/Commons/Hooks/Functions/FormatPrice';
import MetaDescription from '../../../../components/Commons/MetaDescription';
import Carousel from '../../../../components/Commons/Carousel';
import Loading from '../../../../components/Commons/Loading';

import DetailSimple from './components/DetailSimple';
import DetailPharmacy from './components/DetailPharmacy';
import ProductNotFound from './components/ProductNotFound';

import { PixelFacebook } from '../../../../components/Commons/Hooks/Functions/PixelFacebook';
import { ENV_ISO_CURRENCY, ENV_BASE, ENV_COUNTRY } from '../../../../components/Commons/Hooks/Variables/Enviroment';
import { GetUserData } from '../../../../components/Commons/Hooks/Functions/GetUserData';
import { GetProduct, GetCovenants } from './services';
import { ENVIROMENT } from '../../../../v2-config/constants/enviroment.constant';
import './style.scss';

export default function Product(props) {
	const { t } = useTranslation('global');
	const history = useHistory();
	const params = useLocation();
	const [isQuery] = useState(qs.parse(params.search.slice(1)));
	const [isUserLocation] = useState(JSON.parse(localStorage.getItem('locationStore')));
	const [isUser] = useState(localStorage.getItem('userEmail'));
	const [isDetail, setDetail] = useState(null);
	const [isMetaTitle, setMetaTitle] = useState('');
	const [isMetaDescriptions, setMetaDescriptions] = useState('');
	const [isMobile, setMobile] = useState(false);
	const [isCanonical, setCanonical] = useState(false);
	const [isNotFound, setNotFound] = useState(false);

	const handleProductCovenant = useCallback(
		async (item) => {
			let userData;
			if (isUser) {
				userData = await GetUserData(isUser);
				setGlobal({ userDetailInfo: userData });
			}
			const productPharolPrice = await GetCovenants({ id_pharol: item.detail.id_pharol }, userData);
			if (productPharolPrice) setGlobal({ pharolPriceProduct: productPharolPrice });
			if (item.pharmacies.length > 0) {
				const productCovenants = await GetCovenants(
					{
						code_internal: item.detail.code_internal,
						pharmacy: item.detail.pharmacy,
					},
					userData,
				);
				if (productCovenants) setGlobal({ covenantProductList: productCovenants });
			}
		},
		[isUser],
	);

	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	useEffect(() => {
		if (ENVIROMENT.ROOT_PHARMACY !== props?.match?.params?.id?.match(/[^-]+$/)[0]) {
			history.push('/not-found')
		}
		setDetail(null);
		const url = props.match.params.id.split('-').reverse();
		if (url[0] === 'ahumada') {
			url.splice(0, 2);
			const slugRedirect = url.reverse().join('-');
			return history.push(slugRedirect);
		} else {
			GetProduct(props.match.params.id, isUserLocation).then((response) => {
				if (response) {
					if (window.innerWidth < 992) {
						setMobile(true);
					}
					console.log('detalle del producto', response.description);
					setDetail(response.description);

					setMetaTitle(response.metaTitle);
					setMetaDescriptions(response.description.details[0].meta_descriptions);
					setCanonical(ENV_BASE + '/products/' + response.description.details[0].slug);
					let product = response.description.details[0];
					PixelFacebook('ViewContent', {
						content_ids: product.id_pharol,
						content_type: 'product',
						content_name: product.name,
						currency: ENV_ISO_CURRENCY,
						value: parseInt(product.price).toFixed(2),
					});
					if (isQuery.voucher) {
						localStorage.setItem('validVoucher', isQuery.voucher);
					}
					handleProductCovenant({
						detail: response.description?.details[0],
						pharmacies: response.description?.pharmacies,
					});
				} else {
					const route = props.match.params.id.split('-farmacia');
					setCanonical(ENV_BASE + '/products/' + route);
					setNotFound(true);
				}
			});
		}
	}, [props.match.params.id, isUserLocation, isQuery.voucher, handleProductCovenant, history]);

	console.log('url', props.match.params.id);
	console.log('farmacia', props.match.params.id.match(/[^-]+$/)[0]);
	if (isNotFound)
		return (
			<>
				<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} canonical={isCanonical} />
				<ProductNotFound slug={props.match.params.id} />
			</>
		);
	console.log('isDetail', isDetail);
	if (isDetail) {
		return (
			<>
				<div className="ph-product-detail-background-container">
					<Helmet>
						<script type="application/ld+json">
							{JSON.stringify({
								'@context': 'https://schema.org/',
								'@type': 'Product',
								name: isDetail.details[0].editable_name,
								image: [isDetail.details[0].image],
								description: isDetail.details[0].meta_descriptions,
								sku: isDetail.details[0].code_internal,
								offers: {
									'@type': 'Offer',
									url: window.location.href,
									priceCurrency: ENV_COUNTRY === 'CL' ? 'CLP' : ENV_COUNTRY === 'CO' ? 'COP' : 'MXN',
									price: formatPrice(isDetail.details[0].price),
									itemCondition: 'https://schema.org/UsedCondition',
									availability: 'https://schema.org/InStock',
								},
							})}
						</script>
					</Helmet>
					<MetaDescription title={isMetaTitle} name={'description'} content={isMetaDescriptions} canonical={isCanonical} />
					<div className="ph-product-detail-global-container" style={{ marginTop: '35px' }}>
						<div className="ph-product-detail-main-container">
							{isDetail.details[0].pharmacy && (
								<>
									<DetailPharmacy mobile={isMobile} item={isDetail} /> 
								</>
							)}

							{!isDetail.details[0].pharmacy && (
								<>
									<DetailSimple mobile={isMobile} item={isDetail} />
								</>
							)}

							{isDetail.banners && (
								<>
									{isDetail.banners.length > 0 && (
										<>
											<h3 className="ph-product-detail-featured-carousel-title">Productos Destacados</h3>
											<Carousel carouselItems={[4, 2, 2, 2, 1]} list={isDetail.banners} />
										</>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</>
		);
	} else {
		return <Loading />;
	}
}
