/** @format */

import axios from 'axios';

import Notify from '../../components/Commons/Notify';
import { ENV_CHECKOUT, ENV_COUNTRY } from '../../components/Commons/Hooks/Variables/Enviroment';

function serviceUploadFile(item) {
	const form = new FormData();
	form.append('file', item);
	return axios({
		method: 'POST',
		url: `${process.env.REACT_APP_FUNCTION_CHECKOUT}/upload/${ENV_COUNTRY}/recipe`,
		headers: { 'Content-Type': 'multipart/form-data' },
		data: form,
	}).then((response) => {
		if (response.data.statusCode === 200) {
			return response.data.data[0].url;
		} else {
			Notify({
				title: `Problema al subir el archivo`,
				colorTitle: 'ph-main-notification-error-title',
				description: `Revisa tu conexión o ponte en contacto con nosotros.`,
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
			return '';
		}
	});
}

export async function serviceGetProduct(item) {
	let returnResponse;
	await axios({
		method: 'GET',
		url: `${ENV_CHECKOUT}/get-product-order/${item.trx_id}/${item.code_internal}`,
		data: {
			email: item,
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data;
			} else {
				Notify({
					title: `Problemas de conexión`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Revisa tu conexión o ponte en contacto con nosotros.`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
				returnResponse = response.data;
			}
		})
		.catch(() => {
			Notify({
				title: `Problemas de Conexión`,
				colorTitle: 'ph-main-notification-error-title',
				description: `Revisa tu conexión o ponte en contacto con nosotros.`,
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
}

export async function serviceSendRecipe(item) {
	if (Object.keys(item.recipe).length === 0 || !item.date.length || !item.time.length) {
		Notify({
			title: `Campos vacíos`,
			colorTitle: 'ph-main-notification-error-title',
			description: `Debe completar el formulario para poder enviar el nuevo recipe.`,
			time: 3,
			image: 'error',
			placement: 'topRight',
		});
		return;
	}
	item.recipe = await serviceUploadFile(item.recipe);
	if (!item.recipe.length) return;
	return await axios({
		method: 'POST',
		url: `${ENV_CHECKOUT}/update-products-recipe`,
		data: {
			_id: item._id,
			trx_id: item.trx_id.toString(),
			code_internal: item.code_internal,
			new_recipe: item.recipe,
			new_schedule_selected: `${item.date} ${item.time}`,
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				return true;
			} else {
				Notify({
					title: `Problema al actualizar el recipe`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Revisa tu conexión o ponte en contacto con nosotros.`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
				return false;
			}
		})
		.catch((error) => {
			Notify({
				title: `Problema al intentar actualizar el recipe`,
				colorTitle: 'ph-main-notification-error-title',
				description: `Debe completar el formulario para poder enviar el nuevo recipe.`,
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
		});
}
