/** @format */

import React from 'react'

import { Row, Col, Popover } from 'antd'

import './style.scss'

const statusList = {
	open: 'Abierto',
	close: 'Cerrado',
	banned: 'Baneado',
}

export default function Card(props) {
	return (
		<Row align='middle' className='ph-complaint-book-list-content'>
			<Col xs={24} sm={24} md={14} lg={14} xl={14}>
				<Row>
					<Col span={12}>
						OC: <span className='ph-complaint-book-oc'>{props.trx_id}</span>
					</Col>
					<Col span={12}>
						Estado: <span className='ph-complaint-book-oc'>{statusList[props.status]}</span>
					</Col>
				</Row>
				<div className='ph-complaint-book-date'>{props.time}</div>
			</Col>
			<Col xs={24} sm={24} md={6} lg={6} xl={6}>
				<div className='ph-complaint-book-subject'>{props.subject}</div>
				<div className='ph-complaint-book-message'>
					<Popover content={props.message}>{props.message}</Popover>
				</div>
			</Col>
			<Col xs={24} sm={24} md={4} lg={4} xl={4} className='ph-complaint-book-id-container'>
				<div className='ph-complaint-book-id-title'>ID: {props.claim_id}</div>
				<div className={`ph-complaint-book-type ph-complaint-book-type-${props.type}`}>
					{props.type === 'suggestion' ? 'Sugerencia' : 'Reclamo'}
				</div>
			</Col>
		</Row>
	)
}
