/** @format */

import React, { useEffect, useState } from 'react';

import { Row, Col, Form } from 'antd';

import MetaDescription from '../../../../components/Commons/MetaDescription';
import Carousel from '../../../../components/Commons/Carousel';
import Loading from '../../../../components/Commons/Loading';
import { ENV_BASE } from '../../../../components/Commons/Hooks/Variables/Enviroment';

import { RemoveLocalStorage } from '../../../../components/Commons/Hooks/Functions/RemoveLocalStorage';

import InfoTabs from '../../components/InfoTabs';
import Timeline from '../../components/Timeline';
import Actions from '../../components/Actions';
import Image from '../../components/Image';
import Title from '../../components/Title';
import DispatchInfo from '../../components/DispatchInfo';
import SocialNetwork from '../../components/SocialNetwork';

import { ServiceDetail } from './services';

import './style.scss';

export default function Service(props) {
	const [serviceForm] = Form.useForm();
	const [isDetail, setDetail] = useState(null);
	const [isMobile, setMobile] = useState(false);

	useEffect(() => {
		RemoveLocalStorage();
		ServiceDetail(props.match.params.provider, props.match.params.slug).then((response) => {
			if (response) {
				if (window.innerWidth < 992) {
					setMobile(true);
				}
				setDetail(response);
			}
		});
	}, [props.match.params.provider, props.match.params.slug]);

	if (isDetail) {
		return (
			<>
				<MetaDescription title={`${isDetail.details[0].meta_title}`} name={'description'} content={isDetail.details[0].meta_descriptions} canonical={`${ENV_BASE} ${isDetail.details[0].slug}`} />
				<div className="ph-service-detail-global-container">
					<Timeline service={true} item={isDetail.details[0]} />
					<div className="ph-service-detail-main-container">
						<Row>
							<Col xs={24} sm={24} md={24} lg={10} xl={10}>
								{isMobile && <Title service={true} item={isDetail} />}
								<Image classContent={'ph-service-main-image-global-container'} item={isDetail.details[0]} />
								{!isMobile && <SocialNetwork item={isDetail.details[0]} />}
							</Col>
							<Col xs={24} sm={24} md={24} lg={14} xl={14}>
								{!isMobile && <Title service={true} item={isDetail} />}
								<Actions serviceForm={serviceForm} service={true} mobile={isMobile} item={isDetail.details[0]} />
								{isMobile && <SocialNetwork item={isDetail.details[0]} />}
								<div className="ph-service-detail-tabs-main-container">
									<InfoTabs serviceForm={serviceForm} service={true} item={isDetail} />
								</div>
								<DispatchInfo service={true} item={isDetail} />
							</Col>
						</Row>
					</div>
					{isDetail.banners && (
						<>
							{isDetail.banners.length > 0 && (
								<>
									<h3 className="ph-product-detail-featured-carousel-title">Productos Destacados</h3>
									<Carousel carouselItems={[4, 2, 2, 2, 1]} list={isDetail.banners} />
								</>
							)}
						</>
					)}
				</div>
			</>
		);
	} else {
		return <Loading />;
	}
}
