export const getItem = (label, value, key, icon, children, type) => {
	return {
		value,
		icon,
		children,
		label,
		type,
		key,
	};
};
