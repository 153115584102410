/** @format */

import React, { Component } from 'react'

import moment from 'moment'

import { Collapse, Row, Col } from 'antd'

import Images from '../../../../../../components/Commons/Images'
import Spacer from '../../../../../../components/Commons/Spacer'

import {
	ENV_BASE,
	ENV_IMG_ROUTE,
	ENV_COUNTRY,
} from '../../../../../../components/Commons/Hooks/Variables/Enviroment'

import CollapseTitle from './components/CollapseTitle'
import Description from './components/Description'
import Pharmacy from './components/Pharmacy'
import ReceiptModal from './components/ReceiptModal'
import Observations from './components/Observations'
import DescriptionImage from './components/DescriptionImage'
import Promotion from './components/Promotion'
import PriceUnit from './components/PriceUnit'
import PriceTotal from './components/PriceTotal'
import Services from './components/Services'
import ProductPaymentInfo from './components/ProductPaymentInfo'
import './style.scss'

const { Panel } = Collapse

export default class OrderDetailProducts extends Component {
	render() {
		return (
			<>
				<div className='ph-order-detail-products-main-container'>
					<Collapse
						className='ph-order-detail-products-inner-container'
						defaultActiveKey={['1']}
						expandIconPosition={'right'}>
						<div className='ph-order-detail-products-title-container'>
							<CollapseTitle
								title={'Fecha de Orden:'}
								description={moment(this.props.list.payment_info.fecha_aprobacion).format(
									'YYYY/MM/DD hh:mm:ss A'
								)}
							/>
							<Spacer />
							<CollapseTitle
								title={'Numero de productos:'}
								description={this.props.list.products.length}
							/>
							<Spacer />
							<CollapseTitle
								title={'Numero de Orden:'}
								description={this.props.list.id_punto_pago}
							/>
						</div>
						<Panel key='1'>
							{this.props.list.products.map(function (item, i) {
								return (
									<Row className='ph-order-detail-products-list-main-container' key={i}>
										<Col
											xs={24}
											sm={24}
											md={4}
											lg={4}
											xl={4}
											className='ph-order-detail-products-image-container'>
											<a
												href={`${ENV_BASE}/products/${item.slug}`}
												rel='noopener noreferrer'>
												<Images
													classContent={'ph-order-detail-products-image-container'}
													classImage={'ph-order-detail-products-image'}
													webp={`${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${item.id_pharol}.webp`}
													default={`${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${item.id_pharol}.png`}
													alt={item.meta_title}
													title={item.meta_title}
												/>
											</a>
											<div className='ph-order-detail-products-quantity'>
												{item.quantity}
											</div>
										</Col>
										<Col xs={24} sm={24} md={20} lg={20} xl={20}>
											<a
												href={`${ENV_BASE}/products/${item.slug}`}
												rel='noopener noreferrer'>
												<h3 className='ph-order-detail-products-title'>
													{item.meta_title}
												</h3>
											</a>
											<Description
												classTitle={'ph-order-detail-products-item-font'}
												classDescription={'ph-order-detail-products-item-color-blue'}
												title={'Laboratorio:'}
												description={item.laboratory}
											/>
											<Pharmacy item={item} />
											<Description
												classDescription={'ph-order-detail-products-item-font-weight'}
												title={'Principio Activo:'}
												description={item.principe_active}
											/>
											<Description
												classTitle={'ph-order-detail-products-item-font'}
												title={'Categoría:'}
												description={item.category}
											/>
											<Description
												classTitle={'ph-order-detail-products-item-font'}
												title={'Promoción:'}
												description={item.filter_promotion}
											/>
											<Description
												classTitle={'ph-order-detail-products-item-font'}
												title={'Tipo:'}
												description={item.shape_pharmacy}
											/>
											<Description
												classTitle={'ph-order-detail-products-item-font'}
												title={'Almacenamiento:'}
												description={item.storage_conditions}
											/>
											{item.interface?.prescryption_type && (
												<DescriptionImage
													title={item.interface.prescryption_type.title}
													description={item.interface.prescryption_type.type}
													image={item.interface.prescryption_type.image}
												/>
											)}
											{item.recipe && <ReceiptModal item={item} />}
											{item.validation_product && <Observations item={item} />}
											{item.pbm && (
												<Description
													classTitle={'ph-order-detail-products-item-font-weight'}
													classDescription={
														'ph-order-detail-products-item-color-blue-weight'
													}
													title={`PBM (${item.pbm.agreement_name}):`}
													description={`${item.pbm.discount} (${item.pbm.id_agreement})`}
												/>
											)}
											{item.interface?.on_demand && (
												<DescriptionImage
													classTitle={
														'ph-order-detail-products-item-image-title-alt-cp'
													}
													title={item.interface.on_demand.title}
													image={item.interface.on_demand.image.default}
												/>
											)}
											{item.interface && (
												<>
													{item.interface.promotions && (
														<Promotion item={item.interface.promotions} />
													)}
												</>
											)}
											<PriceUnit item={item} />
											{item.quantity > 1 && <PriceTotal item={item} />}
											{item.service && <Services services={item.service} />}
										</Col>
									</Row>
								)
							})}
							<ProductPaymentInfo item={this.props.list} />
						</Panel>
					</Collapse>
				</div>
			</>
		)
	}
}
