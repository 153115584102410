/** @format */

import axios from 'axios';

import Notify from '../../components/Commons/Notify';

import { ENV_COMMON, ENV_COUNTRY, ENV_ROOT_PHARMACY } from '../../components/Commons/Hooks/Variables/Enviroment';

export default async function GetSidebarBanner() {
	let returnResponse;
	await axios({
		method: 'GET',
		url: `${ENV_COMMON}/all-category/${ENV_COUNTRY}`,
		params: {
			root_pharmacy: ENV_ROOT_PHARMACY,
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data;
			} else {
				Notify({
					title: `Error: ${response.data.error}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `${response.data.messaje}`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			}
		})
		.catch(() => {
			Notify({
				title: `Problemas de Conexión`,
				colorTitle: 'ph-main-notification-error-title',
				description: `Revisa tu conexión o ponte en contacto con nosotros.`,
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
}
