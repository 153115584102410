import { Row } from 'antd';
import { ICONS } from '../../v2-config/constants/icons.constant';
import CustomModal from '../Antd/Modal';
import './style.scss';
export default function LoadingModal(props) {
	return (
		<CustomModal className={'modal-primary'} visible={props?.visible} closable={false} footer={null} centered maskClosable={false} destroyOnClose>
			<Row className={'loading-modal'} justify="center">
				<img src={ICONS.LOADING} alt={'cargando'} title={'cargando'} />
			</Row>
		</CustomModal>
	);
}
