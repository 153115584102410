/** @format */
import React from 'react';

import { Checkbox } from 'antd';

import Images from '../../../../../../../../components/Commons/Images';

import './style.scss';
import { ENV_COUNTRY } from '../../../../../../../../components/Commons/Hooks/Variables/Enviroment';

export default function BagRecipeProduct(props) {
	const handleImageError = (e) => {
		e.target.onerror = '';
		e.target.src = 'https://dummyimage.com/600x400/fbfbfb/ddd&text=Pharol+Image';
		return true;
	};

	const handleImagePrescription = (item) => {
		if (item.interface?.prescryption_type) return item.interface.prescryption_type.image;
		else return item.pharmacy_image;
	};

	const handleTitlePrescription = (item) => {
		if (item.interface?.prescryption_type) return item.interface.prescryption_type.title;
		else return item.prescryption_type;
	};

	return (
		<div className="ph-bag-recipe-product-container">
			<Images
				classContent={'ph-bag-recipe-product-image-container'}
				classImage={'ph-bag-recipe-product-image'}
				default={props.item.image}
				alt={props.item.name_editable}
				title={props.item.name_editable}
			/>

			<div className="ph-bag-recipe-product-prescription-main-container">
				<div className="ph-bag-recipe-product-prescription-container">
					<Images
						classContent={'ph-bag-recipe-product-image-prescription-container'}
						classImage={'ph-bag-recipe-product-image-prescription'}
						default={handleImagePrescription(props.item)}
						alt={handleTitlePrescription(props.item)}
						title={handleTitlePrescription(props.item)}
					/>
					<h3 className="ph-bag-recipe-product-prescription-title">{handleTitlePrescription(props.item)}</h3>
				</div>
				<h3 className="ph-bag-recipe-product-title">{props.item.editable_name}</h3>
				{props.item.recipe && (
					<div className="ph-bag-recipe-product-uploaded-image-main-container">
						<div className="ph-bag-recipe-product-uploaded-image-container">
							<img onError={(e) => handleImageError(e)} className="ph-bag-recipe-product-uploaded-image" src={props.item.recipe} alt={'recipe'} title={'recipe'} />
						</div>
						<h4 className="ph-bag-recipe-product-uploaded-image-main-title">Receta actual</h4>
					</div>
				)}
				{props.item.prescription_type === 'WP' && (
					<>
						{!props.item.rem_product && !props.item.interface.on_demand && !props.item.interface.magistral && !props.item.interface.minsal && !props.item.interface.digital && (
							<>
								{ENV_COUNTRY !== 'AR' && !props.item.recipe_save && (
									<div className="ph-bag-recipe-agree-title-wp-main-container">
										<Checkbox
											className="ph-bag-recipe-agree-title-wp-container"
											onChange={(checkbox) => {
												props.handlePhysicalPresentation(checkbox, props.item, props.index);
											}}
										></Checkbox>
										<div>
											<h5 className="ph-bag-recipe-agree-title-wp">
												¿No tienes una receta? has click y ¡Te ayudamos!{' '}
												<span onClick={() => props.handleExplainText(props.index)} className="ph-bag-recipe-agree-title-wp-show-more">
													{props.isSeeMore.some((element) => element === props.index) ? 'ver menos...' : 'ver más...'}
												</span>
											</h5>
											<h5 className={`${props.isSeeMore.some((element) => element === props.index) ? '' : 'ph-bag-recipe-agree-see-more'} ph-bag-recipe-agree-title-wp-description`}>
												<span className="ph-bag-recipe-agree-title-wp-asterick">*</span>
												No cuento con una prescripción médica vigente, pero acepto que un Químico Farmacéutico me contacte para realizar la dispensación segura del medicamento de acuerdo las
												recomendaciones del Instituto de Salud Pública.
											</h5>
										</div>
									</div>
								)}
							</>
						)}
					</>
				)}
			</div>
		</div>
	);
}
