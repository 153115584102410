/** @format */
import React from 'react'
import { useHistory } from 'react-router-dom'

import { Pagination } from 'antd'

import './style.scss'

export default function Paginator(props) {
	const history = useHistory()

	return (
		<>
			<div className='ph-complaint-book-pagination-main-container'>
				<Pagination
					className='ph-complaint-book-filter-pagination-container'
					defaultCurrent={1}
					current={parseInt(props.page)}
					onChange={(page) => history.push(`${props.route}/${page}/${props.pharmacy}`)}
					total={props.totalItems}
					defaultPageSize={20}
					pageSize={props.itemsPerPage}
					showTotal={(total) => `Total ${total} items`}
				/>
			</div>
		</>
	)
}
