/** @format */

import React from 'react';

import { useGlobal } from 'reactn';

import Spacer from '../../../../components/Commons/Spacer';

import { formatPrice } from '../../../../components/Commons/Hooks/Functions/FormatPrice';

import DescriptionPrices from './components/DescriptionPrices';

import './style.scss';

export default function PaymentDetail(props) {
	const [isPaymentExcedent] = useGlobal('paymentExcedent');
	const handlePaytmentExedent = () => {
		if (isPaymentExcedent) {
			return formatPrice(parseFloat(props.item.total) - isPaymentExcedent.amountAuthorized);
		} else {
			return formatPrice(props.item.total);
		}
	};

	return (
		<div>
			<h3 className="ph-checkout-service-main-box-delivery-main-title">Resumen</h3>
			<DescriptionPrices title={'Costo mensual:'} price={props.item.services[0].price_unity} />
			<DescriptionPrices title={`Costo x ${props.item.services[0].units} meses:`} price={props.item.sub_total} />
			<DescriptionPrices colorDiscount={true} title={`Costo de entrega:`} price={props.item.total_delivery} />
			{isPaymentExcedent && <DescriptionPrices colorVoucher={true} title={'Excedentes:'} price={`-${isPaymentExcedent.amountAuthorized}`} />}
			<div className="ph-checkout-service-main-box-delivery-price-container">
				<h3 className="ph-cart-checkout-box-total-price">
					Total:
					<Spacer />
					<span className="ph-cart-checkout-box-total-price-info">{handlePaytmentExedent()}</span>
				</h3>
			</div>
		</div>
	);
}
