import React from 'react';
import { Menu } from 'antd';
import './styles.scss';

export default function CustomMenu(props) {
	return (
		<>
			<Menu {...props} />
		</>
	);
}
