import { Col, Form, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { setGlobal } from 'reactn';
import { GlobalConsumer } from '../../../components/Commons/Context/Global';
import CustomButton from '../../../v2-components/Antd/Button';
import CustomCard from '../../../v2-components/Antd/Card';
import CustomFormItem from '../../../v2-components/Antd/FormItem';
import CustomImage from '../../../v2-components/Antd/Image';
import CustomInput from '../../../v2-components/Antd/Input';
import CustomLink from '../../../v2-components/Antd/Link';
import { CustomNotification } from '../../../v2-components/Antd/Notification';
import LoadingModal from '../../../v2-components/LoadingModal';
import { IMAGES } from '../../../v2-config/constants/images.constant';
import { VALIDATION } from '../../../v2-config/constants/validations.constant';
import { Enviroment } from '../../../_config/constants/enviroment-v2.constant';
import { Service } from './service';
import './style.scss';
export default function LoginUser() {
	const [form] = Form.useForm();
	const [openLoadingModal, setOpenLoadingModal] = useState(false);
	const { setLocation, setLoginUser } = GlobalConsumer();

	const history = useHistory();

	const handleFinish = (item) => {
		item.products = [];
		setOpenLoadingModal(true);
		new Service()
			.login({ ...item, platform: Enviroment.ROOT_SITE, country: Enviroment.COUNTRY, pharmacy: Enviroment.ROOT_PHARMACY })
			.then((response) => {
				if (response.statusCode !== 201) {
					setOpenLoadingModal(false);
					return CustomNotification({ type: 'error', message: null, description: response.message });
				}
				const userMainInfo = { first_name: response.data.user.first_name, last_name: response.data.user.last_name, token: response.data.user.token };
				localStorage.setItem('userEmail', response.data.user.email);
				localStorage.setItem('userMainInfo', JSON.stringify(userMainInfo));
				localStorage.setItem('cart', JSON.stringify([]));
				setGlobal(() => userMainInfo);
				setLoginUser({ email: response.data.user.email });
				if (item?.address?.length > 0) {
					let recentAddress = item?.address[item.address.length - 1];
					let addressSplit = recentAddress.location.formatted_address.split(',');
					let address = {
						lat: recentAddress.location.geometry.location.lat,
						lng: recentAddress.location.geometry.location.lng,
						address: recentAddress.location.formatted_address,
						street: addressSplit[0],
						commune: addressSplit[1],
						region: addressSplit[2],
					};
					localStorage.setItem('locationStore', JSON.stringify(address));
					setGlobal(() => ({ address: address }));
					setLocation(address);
				}
				setOpenLoadingModal(false);
				form.resetFields();
				history.push('/profile-info');
			})
			.catch((error) => {
				console.log(error);
			});
	};
	useEffect(() => {
		if (localStorage.getItem('userEmail')) history.push('/');
	}, [history]);

	return (
		<Row justify="center" align="middle" className="login-container">
			<Col md={13} xs={22}>
				<br />
				<Form form={form} layout="vertical" onFinish={handleFinish}>
					<CustomCard
						className={'card-primary'}
						actions={[
							<CustomLink className={'link-primary'} to="/">
								Volver
							</CustomLink>,
							<CustomButton className={'button-primary'} htmlType="submit">
								INICIAR SESIÓN
							</CustomButton>,
						]}
					>
						<Row justify="center">
							<CustomImage src={IMAGES.LOGO_LIGHT_BACKGROUND} alt="logo" height={42} />
						</Row>
						<Row justify="center">
							<h2>Iniciar Sesión</h2>
						</Row>
						<Row justify="center">
							<p>Accede a tu cuenta y a tus beneficios</p>
						</Row>
						<Row justify="center" align="middle">
							<CustomFormItem className={'form-primary'} name={'email'} rules={VALIDATION.email}>
								<CustomInput className={'input-primary'} placeholder={'Correo electrónico'} />
							</CustomFormItem>
						</Row>
						<Row justify="center" align="middle">
							<CustomFormItem className={'form-primary'} name={'password'} rules={VALIDATION.required}>
								<CustomInput className={'input-primary'} type={'password'} placeholder={'Contraseña'} />
							</CustomFormItem>
						</Row>
						<Row justify="center">
							<Link to={'/auth/recover-password'}>Olvidé mi contraseña</Link>
						</Row>
						<Row justify="center">
							<p>
								¿Aún no estas registrado? <Link to={'/auth/register'}>Haz click aquí.</Link>
							</p>
						</Row>
					</CustomCard>
				</Form>
				<LoadingModal visible={openLoadingModal} />
			</Col>
		</Row>
	);
}
