/** @format */

import React from 'react';

import { Row, Col, Button, Form } from 'antd';

import { AppleOutlined } from '@ant-design/icons';
import { GooglePlusOutlined } from '@ant-design/icons';

import { ENV_COUNTRY, ENV_IMG_STATIC } from '../../components/Commons/Hooks/Variables/Enviroment';

import Images from '../../components/Commons/Images';
import Spacer from '../../components/Commons/Spacer';
import InputField from '../../components/Commons/Input';
import MetaDescription from '../../components/Commons/MetaDescription';
import translates from '../../components/Commons/translates';

import './style.scss';

export default function patientExecutives() {
	return (
		<>
			<div>
				<MetaDescription title={'Ejecutivos de Pacientes - Red de Farmacias Online | Pharol'} name={'description'} content={'Red de Farmacias Online | Pharol - Servicios a Domicilio'} />
				<div className="ph-executives-header-global-container">
					<div className="ph-executives-header-main-container">
						<Row className="ph-executives-header-content">
							<Col span={12}>
								<Images
									classContent={'ph-executives-header-logo-container'}
									classImage={'ph-executives-header-logo-img'}
									default={`https://storage.googleapis.com/master-image-bucket/pharol-go/pharol-go-white.png`}
									title={'PharolGo logo'}
									alt={'PharolGo logo'}
								/>
								<p className="ph-executives-header-description">El primer servicio logístico farmacéutico que integra servicios de profesionales de salud</p>
								<div className="ph-executives-header-input-container">
									<Form className="ph-executives-header-input-inner-container">
										<InputField className="ph-executives-header-input" inputName="email" inputNameLabel="¿Te gustaría recibir mayor información?" inputNameType="email" inputNameRules="rulesEmail" />
										<Button className="ph-executives-header-button" href="https://pharol.cl/pharol-go" target="_blank" rel="noopener noreferrer">
											Suscribirme
										</Button>
									</Form>
								</div>
							</Col>
							<Spacer />
							<Col span={6}>
								<div className="ph-executives-header-man-global-container">
									<span className="ph-executives-header-widet-span">"Descargue la App y ahora asisto a mis pacientes en sus domicilios"</span>
									<div className="ph-executives-header-widget-global-container">
										<Images
											classContent={'ph-executives-header-img-man-container'}
											classImage={'ph-executives-header-img-man'}
											default={`${ENV_IMG_STATIC}/patient-executive/man.png`}
											webp={`${ENV_IMG_STATIC}/patient-executive/man.webp`}
											jp2={`${ENV_IMG_STATIC}/patient-executive/man.jp2`}
											jxr={`${ENV_IMG_STATIC}/patient-executive/man.jxr`}
											title={'PharolGo'}
											alt={'PharolGo'}
										/>
										<div>
											<h6 className="ph-executives-header-widet-name">Juan Cáceres</h6>
											<p className="ph-executives-header-widet-profesion">Enfermero - Ejecutivo de Pacientes</p>
										</div>
									</div>
								</div>
							</Col>
							<Col span={24}>
								<div className="ph-executives-header-repet-global-container">
									<div className="ph-executives-header-repet-main-container">
										<Images
											classContent={'ph-executives-header-img-repet-container'}
											classImage={'ph-executives-header-img-repet'}
											default={`${ENV_IMG_STATIC}/patient-executive/repet.png`}
											webp={`${ENV_IMG_STATIC}/patient-executive/repet.webp`}
											jp2={`${ENV_IMG_STATIC}/patient-executive/repet.jp2`}
											jxr={`${ENV_IMG_STATIC}/patient-executive/repet.jxr`}
											title={'PharolGo'}
											alt={'PharolGo'}
										/>
										<p className="ph-executives-header-repet-subtitle">Volver a encargar</p>
									</div>
									<div className="ph-executives-header-repet-medicine-container">
										<div>
											<span className="ph-executives-header-repet-span">Mi medicina</span>
											<p className="ph-executives-header-repet-paragrahf">
												Puedes solicitar el servicio de aplicación a <br /> domicilio
											</p>
										</div>
										<div>
											<Images
												classContent={'ph-executives-header-img-repet-icon-one-container'}
												classImage={'ph-executives-header-img-repet-icon-one'}
												default={`${ENV_IMG_STATIC}/patient-executive/cardmedicaltwo.png`}
												webp={`${ENV_IMG_STATIC}/patient-executive/cardmedicaltwo.webp`}
												jp2={`${ENV_IMG_STATIC}/patient-executive/cardmedicaltwo.jp2`}
												jxr={`${ENV_IMG_STATIC}/patient-executive/cardmedicaltwo.jxr`}
												title={'PharolGo'}
												alt={'PharolGo'}
											/>
										</div>
									</div>
									<div className="ph-executives-header-repet-products-container">
										<div>
											<span className="ph-executives-header-repet-span">Servicio de inyección</span>
											<p className="ph-executives-header-repet-paragrahf">
												Recibe este servicio en la comodidad de tu <br /> casa y por profesionales de salud
											</p>
										</div>
										<div>
											<Images
												classContent={'ph-executives-header-img-repet-icon-one-container'}
												classImage={'ph-executives-header-img-repet-icon-one'}
												default={`${ENV_IMG_STATIC}/patient-executive/cardmedical.png`}
												webp={`${ENV_IMG_STATIC}/patient-executive/cardmedical.webp`}
												jp2={`${ENV_IMG_STATIC}/patient-executive/cardmedical.jp2`}
												jxr={`${ENV_IMG_STATIC}/patient-executive/cardmedical.jxr`}
												title={'PharolGo'}
												alt={'PharolGo'}
											/>
										</div>
									</div>
								</div>
							</Col>
						</Row>
						<Images
							classContent={'ph-executives-header-img-route-container'}
							classImage={'ph-executives-header-img-route'}
							default={`${ENV_IMG_STATIC}/patient-executive/route.png`}
							webp={`${ENV_IMG_STATIC}/patient-executive/route.webp`}
							jp2={`${ENV_IMG_STATIC}/patient-executive/route.jp2`}
							jxr={`${ENV_IMG_STATIC}/patient-executive/route.jxr`}
							title={'PharolGo'}
							alt={'PharolGo'}
						/>
						<Images
							classContent={'ph-executives-header-img-moto-container'}
							classImage={'ph-executives-header-img-moto'}
							default={`${ENV_IMG_STATIC}/patient-executive/moto.png`}
							webp={`${ENV_IMG_STATIC}/patient-executive/moto.webp`}
							jp2={`${ENV_IMG_STATIC}/patient-executive/moto.jp2`}
							jxr={`${ENV_IMG_STATIC}/patient-executive/moto.jxr`}
							title={'PharolGo'}
							alt={'PharolGo'}
						/>
						<Images
							classContent={'ph-executives-header-img-delivery-container'}
							classImage={'ph-executives-header-img-delivery'}
							default={`${ENV_IMG_STATIC}/patient-executive/delivery.png`}
							webp={`${ENV_IMG_STATIC}/patient-executive/delivery.webp`}
							jp2={`${ENV_IMG_STATIC}/patient-executive/delivery.jp2`}
							jxr={`${ENV_IMG_STATIC}/patient-executive/delivery.jxr`}
							title={'PharolGo'}
							alt={'PharolGo'}
						/>
					</div>
				</div>
				<div className="ph-executives-widget-movil-container">
					<div className="ph-executives-widget-movil-content">
						<Images
							classContent={'ph-executives-widget-movil-img-man-container'}
							classImage={'ph-executives-widget-movil-img-man'}
							default={`${ENV_IMG_STATIC}/patient-executive/man.png`}
							webp={`${ENV_IMG_STATIC}/patient-executive/man.webp`}
							jp2={`${ENV_IMG_STATIC}/patient-executive/man.jp2`}
							jxr={`${ENV_IMG_STATIC}/patient-executive/man.jxr`}
							title={'PharolGo logo'}
							alt={'PharolGo logo'}
						/>
						<div>
							<span className="ph-executives-widget-movil-man-name">Juan Cáceres</span>
							<p className="ph-executives-widget-movil-man-profesion">Ejecutivo de Pacientes</p>
						</div>
					</div>
				</div>
				<Row className="ph-executives-withyou-global-container">
					<Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 1 }} className="ph-executives-withyou-main-container">
						<h2 className="ph-executives-withyou-title">¡Estamos Contigo!</h2>
						<p className="ph-executives-withyou-subtitle">
							Con Pharol Go podrás encontrar profesionales capacitados que te brinden la administración de medicamentos que necesitas. Llevándote siempre una mejor experiencia sin salir de tu casa.
						</p>
					</Col>
					<Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 2 }} className="ph-executives-withyou-img-container">
						<Images
							classContent={'ph-executives-withyou-image-container'}
							classImage={'ph-executives-withyou-image'}
							default={`${ENV_IMG_STATIC}/patient-executive/team.png`}
							webp={`${ENV_IMG_STATIC}/patient-executive/team.webp`}
							jp2={`${ENV_IMG_STATIC}/patient-executive/team.jp2`}
							jxr={`${ENV_IMG_STATIC}/patient-executive/team.jxr`}
							title={'PharolGo'}
							alt={'PharolGo'}
						/>
					</Col>
				</Row>
				<Row className="ph-executives-allies-global-container">
					<Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }} className="ph-executives-allies-main-container">
						<h2 className="ph-executives-allies-title">¡Tus aliados en salud!</h2>
						<p className="ph-executives-allies-subtitle">Encuentra a nuestros ejecutivos de pacientes disponibles para ayudarte en la administración de tus medicamentos y/o inyectables.</p>
					</Col>
					<Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }} className="ph-executives-allies-img-container">
						<Images
							classContent={'ph-executives-ranking-image-container'}
							classImage={'ph-executives-ranking-image'}
							default={`${ENV_IMG_STATIC}/patient-executive/ranking.png`}
							webp={`${ENV_IMG_STATIC}/patient-executive/ranking.webp`}
							jp2={`${ENV_IMG_STATIC}/patient-executive/ranking.jp2`}
							jxr={`${ENV_IMG_STATIC}/patient-executive/ranking.jxr`}
							title={'PharolGo'}
							alt={'PharolGo'}
						/>
					</Col>
				</Row>
				<Row className="ph-executives-info-global-container">
					<Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 1 }} className="ph-executives-info-main-container">
						<Images
							classContent={'ph-executives-info-click-img-container'}
							classImage={'ph-executives-info-click-img'}
							default={`${ENV_IMG_STATIC}/patient-executive/enunclic.png`}
							webp={`${ENV_IMG_STATIC}/patient-executive/enunclic.webp`}
							jp2={`${ENV_IMG_STATIC}/patient-executive/enunclic.jp2`}
							jxr={`${ENV_IMG_STATIC}/patient-executive/enunclic.jxr`}
							title={'PharolGo'}
							alt={'PharolGo'}
						/>
						<h2 className="ph-executives-info-click-title">Todo en un clic</h2>
						<p className="ph-executives-info-click-subtitle">
							¿Necesitas vacurnarte y no sabes a donde ir? Con ejecutivos de pacientes encuentra a profesionales de la salud disponibles para brindarte atención personalizada, servicios de salud y
							administración de productos farmacológicos a domicilio
						</p>
					</Col>
					<Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 2 }} className="ph-executives-info-main-container">
						<Images
							classContent={'ph-executives-info-access-img-container'}
							classImage={'ph-executives-info-access-img'}
							default={`${ENV_IMG_STATIC}/patient-executive/acceso.png`}
							webp={`${ENV_IMG_STATIC}/patient-executive/acceso.webp`}
							jp2={`${ENV_IMG_STATIC}/patient-executive/acceso.jp2`}
							jxr={`${ENV_IMG_STATIC}/patient-executive/acceso.jxr`}
							title={'PharolGo'}
							alt={'PharolGo'}
						/>
						<h2 className="ph-executives-info-access-title">¿Cómo accedo?</h2>
						<p className="ph-executives-info-access-subtitle">
							Al momento de seleccionar algún medicamento que requiera administración por parte de un profesional de salud, podrás añadir a tu bolsa de compra el servicio y coordinar la aplicación del
							medicamento en tu domicilio.
						</p>
					</Col>
				</Row>
				<Row className="ph-executives-metrics-global-container">
					<Col xs={12} md={6}>
						<h4 className="ph-executives-metrics-title">+ 500</h4>
						<div className="ph-executives-metrics-titles-container">
							<span className="ph-executives-metrics-subtitle">Veces</span>
							<span className="ph-executives-metrics-subtitle">Descargada</span>
						</div>
					</Col>
					<Col xs={12} md={6}>
						<h4 className="ph-executives-metrics-title">+ 5 k</h4>
						<div className="ph-executives-metrics-titles-container">
							<span className="ph-executives-metrics-subtitle">Clientes</span>
							<span className="ph-executives-metrics-subtitle">Registrados</span>
						</div>
					</Col>
					<Col xs={12} md={6}>
						{' '}
						<h4 className="ph-executives-metrics-title">+ 50 k</h4>
						<div className="ph-executives-metrics-titles-container">
							<span className="ph-executives-metrics-subtitle">Compras</span>
							<span className="ph-executives-metrics-subtitle">Procesadas</span>
						</div>
					</Col>
					<Col xs={12} md={6}>
						<h4 className="ph-executives-metrics-title">4</h4>
						<div className="ph-executives-metrics-titles-container">
							<span className="ph-executives-metrics-subtitle">Locales</span>
							<span className="ph-executives-metrics-subtitle">Operativos</span>
						</div>
					</Col>
				</Row>
				<div className="ph-executives-benefits-global-container">
					<Row className="ph-executives-benefits-main-container">
						<Col xs={24} md={12} className="ph-executives-benefits-description-container">
							<h2 className="ph-executives-benefits-title">¡Descarga nuestra App móvil!</h2>
							<p className="ph-executives-benefits-subtitle">Y disfruta de estos beneficios que te ofrece Pharol</p>
							<div className="ph-executives-benefits-innerbox-container">
								<Images
									classContent={'ph-executives-icon-number-container'}
									classImage={'ph-executives-icon-number'}
									default={`${ENV_IMG_STATIC}/patient-executive/iconone.png`}
									webp={`${ENV_IMG_STATIC}/patient-executive/iconone.webp`}
									jp2={`${ENV_IMG_STATIC}/patient-executive/iconone.jp2`}
									jxr={`${ENV_IMG_STATIC}/patient-executive/iconone.jxr`}
									title={'PharolGo'}
									alt={'PharolGo'}
								/>
								<span className="ph-executives-benefits-subtitle-span">Busca tus productos farmacéuticos.</span>
							</div>
							<div className="ph-executives-benefits-innerbox-container">
								<Images
									classContent={'ph-executives-icon-number-container'}
									classImage={'ph-executives-icon-number'}
									default={`${ENV_IMG_STATIC}/patient-executive/icontwo.png`}
									webp={`${ENV_IMG_STATIC}/patient-executive/icontwo.webp`}
									jp2={`${ENV_IMG_STATIC}/patient-executive/icontwo.jp2`}
									jxr={`${ENV_IMG_STATIC}/patient-executive/icontwo.jxr`}
									title={'PharolGo'}
									alt={'PharolGo'}
								/>
								<span className="ph-executives-benefits-subtitle-span">Cotiza en más de una {translates.pharmacy[ENV_COUNTRY]} con un solo clic.</span>
							</div>
							<div className="ph-executives-benefits-innerbox-container">
								<Images
									classContent={'ph-executives-icon-number-container'}
									classImage={'ph-executives-icon-number'}
									default={`${ENV_IMG_STATIC}/patient-executive/iconthree.png`}
									webp={`${ENV_IMG_STATIC}/patient-executive/iconthree.webp`}
									jp2={`${ENV_IMG_STATIC}/patient-executive/iconthree.jp2`}
									jxr={`${ENV_IMG_STATIC}/patient-executive/iconthree.jxr`}
									title={'PharolGo'}
									alt={'PharolGo'}
								/>
								<span className="ph-executives-benefits-subtitle-span">Recibe tus compras en tu domicilio.</span>
							</div>
							<div className="ph-executives-benefits-buttons-container">
								<Button
									className="ph-executives-benefits-button-app-store"
									href="https://www.apple.com/cl/app-store/"
									target="_blank"
									rel="noopener noreferrer"
									icon={<AppleOutlined className="ph-executives-benefits-app-store-icon" />}
								>
									App Store
								</Button>
								<Button
									className="ph-executives-benefits-button-google-play"
									href="https://play.google.com/store"
									target="_blank"
									rel="noopener noreferrer"
									icon={<GooglePlusOutlined className="ph-executives-benefits-google-play-icon" />}
								>
									Google Play
								</Button>
							</div>
						</Col>
						<Col xs={24} md={12} className="ph-executives-benefits-moviles-container">
							<Images
								classContent={'ph-executives-images-movilOne-container'}
								classImage={'ph-executives-image-movilOne'}
								default={`${ENV_IMG_STATIC}/patient-executive/movilOne.png`}
								webp={`${ENV_IMG_STATIC}/patient-executive/movilOne.webp`}
								jp2={`${ENV_IMG_STATIC}/patient-executive/movilOne.jp2`}
								jxr={`${ENV_IMG_STATIC}/patient-executive/movilOne.jxr`}
								title={'PharolGo'}
								alt={'PharolGo'}
							/>
							<Images
								classContent={'ph-executives-images-movilTwo-container'}
								classImage={'ph-executives-image-movilTwo'}
								default={`${ENV_IMG_STATIC}/patient-executive/movilTwo.png`}
								webp={`${ENV_IMG_STATIC}/patient-executive/movilTwo.webp`}
								jp2={`${ENV_IMG_STATIC}/patient-executive/movilTwo.jp2`}
								jxr={`${ENV_IMG_STATIC}/patient-executive/movilTwo.jxr`}
								title={'PharolGo'}
								alt={'PharolGo'}
							/>
						</Col>
					</Row>
				</div>
				<Row className="ph-executives-singUp-global-container">
					<Col xs={24} lg={12} className="ph-executives-singUp-text-container">
						<h3 className="ph-executives-singUp-title">¿Quieres saber más? </h3>
						<span className="ph-executives-singUp-span">Déjanos tu correo y te enviaremos mayor información.</span>
					</Col>
					<Col xs={24} lg={12} className="ph-executives-singUp-input-global-container">
						<div className="ph-executives-singUp-input-container">
							<Form className="ph-executives-singUp-input-container">
								<InputField className="ph-executives-singUp-input" inputName="email" inputNameLabel="Correo electrónico" inputNameType="email" inputNameRules="rulesEmail" />
								<Button className="ph-executives-singUp-button" href="https://pharol.cl/pharol-go" target="_blank" rel="noopener noreferrer">
									Suscribirme
								</Button>
							</Form>
						</div>
					</Col>
				</Row>
			</div>
		</>
	);
}
