/** @format */

import axios from 'axios'

import { ENV_CALCULATION, ENV_COUNTRY, ENV_ROOT_PHARMACY } from '../../Variables/Enviroment'

export default async function GetNotificationProduct(item, data) {
	const userLocation = JSON.parse(localStorage.getItem('locationStore'))
	let returnResponse
	await axios({
		method: 'POST',
		url: `${ENV_CALCULATION}/nearest_local`,
		data: {
			product: item,
			country: ENV_COUNTRY,
			root_pharmacy: ENV_ROOT_PHARMACY,
			lat: userLocation.lat,
			lng: userLocation.lng,
			address: userLocation.address,
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = {
					response: true,
					message: response.data.data.message,
					color_distance: response.data.data.color_distance,
					delivery: response.data.data.delivery,
					despatch_info: response.data.data.despatch_info,
					distance: response.data.data.distance,
					distance_text: response.data.data.distance_text,
					pharmacy_withdrawal: response.data.data.pharmacy_withdrawal,
				}
				const index = data.findIndex((search) => {
					if (search.objectID) return search.objectID === item.objectID
					else return search.id === item.id
				})
				data[index].despath_details = {
					delivery: response.data.data.delivery,
					pharmacy_withdrawal: response.data.data.pharmacy_withdrawal,
				}
				localStorage.setItem('cart', JSON.stringify(data))
			} else {
				returnResponse = {
					response: false,
					message: null,
					color_distance: '',
					delivery: '',
					distance: '',
					distance_text: '',
					pharmacy_withdrawal: '',
				}
			}
		})
		.catch(() => {})
	return returnResponse
}
