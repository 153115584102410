/** @format */

export const rulesValidation = {
	rulesRequired: [
		{
			required: true,
			message: 'Por favor ingresa un valor',
		},
	],
	rulesEmail: [
		{
			type: 'email',
			message: 'Correo no es válido',
		},
		{
			required: true,
			message: 'Por favor ingresa tu Correo',
		},
	],
	rulesPassword: [
		{
			required: true,
			message: 'Ingresa tu contraseña!',
		},
		{
			min: 4,
			message: 'Mínimo 4 caracteres!',
		},
	],
	rulesText: [
		{
			required: true,
			message: 'Ingresa el nombre de tu cuenta',
		},
		{
			min: 4,
			message: 'Mínimo 4 caracteres!',
		},
	],
	rulesAddress: [
		{
			required: true,
			message: 'Ingresa calle ó Comuna',
		},
	],
	rulesAlias: [
		{
			required: true,
			min: 3,
			message: 'Ingresa tu Alias...',
		},
	],
	rulesRecipe: [
		{
			required: true,
			message: 'Ingresa tuna imagen de tu receta...',
		},
	],
	rulesFirstName: [
		{
			required: true,
			message: 'Ingresa tu nombre',
		},
		{
			min: 3,
			message: 'Mínimo 3 caracteres!',
		},
	],
	rulesLastName: [
		{
			required: true,
			message: 'Ingresa tu apellido',
		},
		{
			min: 3,
			message: 'Mínimo 3 caracteres!',
		},
	],
	rulesDate: [
		{
			required: true,
			message: 'Ingresa una fecha!',
		},
		{
			min: 8,
			message: 'Formato incorrecto!',
		},
	],
	rulesPhone: [
		{
			required: true,
			message: 'Campo obligatorio!',
		},
		{
			min: 11,
			message: 'Número incompleto!',
		},
		{
			max: 11,
			message: 'Número incorrecto!',
		},
	],
	rulesRut: [
		{
			required: true,
			message: 'Ingresa tu RUT!',
		},
		{
			min: 9,
			message: 'RUT incompleto!',
		},
		{
			max: 9,
			message: 'RUT incorrecto!',
		},
	],
	rulesOrder: [
		{
			required: true,
			message: 'Ingresa tu num. de Orden',
		},
	],
	rulesDeliveryDate: [
		{
			required: true,
			message: 'Seleccione una fecha de despacho...',
		},
	],
	rulesDeliveryDay: [
		{
			required: true,
			message: 'Seleccione un día de despacho...',
		},
	],
	rulesCupon: [
		() => ({
			validator(_, value) {
				const validate = /^[A-Za-z0-9]+$/.test(value)
				if (value) {
					if (validate) {
						return Promise.resolve()
					} else {
						return Promise.reject(new Error('Formato no válido.'))
					}
				} else {
					return Promise.reject(new Error('Ingrese el No. de cupón.'))
				}
			},
		}),
	],
	confirmPassword: [
		{
			required: true,
			message: '¡Por favor, confirme su contraseña!',
		},
		({ getFieldValue }) => ({
			validator(rule, value) {
				if (!value || getFieldValue('password') === value) {
					return Promise.resolve()
				}
				return Promise.reject('¡Las dos contraseñas que ingresó no coinciden!')
			},
		}),
	],
	confirmEmail: [
		{
			required: true,
			message: '¡Por favor, confirme su correo!',
		},
		({ getFieldValue }) => ({
			validator(rule, value) {
				if (!value || getFieldValue('email') === value) {
					return Promise.resolve()
				}
				return Promise.reject('¡Los dos correos no coinciden!')
			},
		}),
	],
	rulesNumberMagistral: [
		{
			required: true,
			message: 'Por favor ingresa un valor',
		},
		() => ({
			validator(_, value) {
				const validate = value >= 1 && value <= 10 ? true : false
				if (validate) {
					return Promise.resolve()
				} else {
					return Promise.reject(new Error('Debe introducir una cantidad entre 1 y 10.'))
				}
			},
		}),
	],
	rulesCheckAgree: [
		{
			validator: (_, value) => (value ? Promise.resolve() : Promise.reject('Acepta las condiciones para continuar.')),
		},
	],
}
