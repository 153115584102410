import React from 'react';
import { Steps } from 'antd';
import { useHistory } from 'react-router-dom';
import { Enviroment } from '../../_config/constants/enviroment-v2.constant';
import './style.scss';

export default function Steper(props) {
	const history = useHistory()

	const validateLink = (step,  route) => {
		if(props.step && props.step > step) return () => history.push(route)
	}

	return (
		<div className="ph-stepper-global-container">
			<Steps responsive={false} direction="horizontal" size="small" current={props.step}>
				<Steps.Step 
					title="Tu Bolsa" 
					onClick={validateLink(0, "/cart")}
					className={`${props.step && props.step > 0 ? "cursor-pointer" : ""}`}
				/>
				<Steps.Step 
					title="Datos de Entrega" 
					className={`${props.step && props.step > 1 ? "cursor-pointer" : ""} step-two`}
					onClick={validateLink(1, "/checkout/retirement")} 
				/>
				{
					Enviroment.ROOT_RESERVE 
						? 
							<Steps.Step title="Solicitud" className="step-three" /> 
						: 
							<Steps.Step title="Pago" className="step-three" />
				}
			</Steps>
		</div>
	);
}
