/** @format */

import React, { useEffect, useState } from 'react';

import { Form, Row, Col, Select } from 'antd';

import Images from '../../../../../../components/Commons/Images';
import { rulesValidation } from '../../../../../../components/Commons/Input/rules';

import LocalButton from './components/LocalButton';
import ModalLocalSelected from '../ModalLocalSelected';

import { ENV_IMG_HEADER, ENV_IMG } from '../../../../../../components/Commons/Hooks/Variables/Enviroment';
import { PharmacyScheduleFunction } from './service';
import { ENVIROMENT } from '../../../../../../v2-config/constants/enviroment.constant';

import './style.scss';

export default function PharmacySelect(props) {
	const [isVisible, setVisible] = useState(false);
	const [isPreviewLocal, setPreviewLocal] = useState(null);
	const [isMobile, setMobile] = useState(false);

	const handleSelectLocal = async (item) => {
		await PharmacyScheduleFunction(item.pharmacy, item.local, props.group).then((response) => {
			if (response) {
				if (props.group.type_calculation === 'normal' || props.group.type_calculation === 'minsal') {
					props.group.local_selected = item;
					props.group.local_selected.date_selected = response;
				}
				props.isRetirementData.locals_group[props.index] = props.group;
				props.setRetirementData(props.isRetirementData);
				//setGlobal({ retirementData: props.isRetirementData })
			}
		});
	};

	const handleDeleteLocal = () => {
		delete props.group.local_selected;
		props.isRetirementData.locals_group[props.index] = props.group;
		props.setRetirementData(props.isRetirementData);
	};

	const handlePreviewLocal = (item) => {
		if (item) {
			setVisible(item);
			setPreviewLocal(item);
		} else {
			setVisible(false);
			setPreviewLocal(null);
		}
	};

	const handleDateSelected = (value, item) => {
		item.local_selected.date_selected.forEach((element) => {
			if (value === element.date) {
				item.local_selected.times_selected = element.timers;
			}
		});
		item.local_selected.day_selected = value;
		props.isRetirementData.locals_group[props.index] = props.group;
		props.setRetirementData(props.isRetirementData);
	};

	useEffect(() => {
		if (window.innerWidth < 768) {
			setMobile(true);
		}
	}, []);

	return (
		<>
			<div className="ph-withdrawal-pharmacy-list-global-container">
				{props.group.type_calculation !== 'quotation' ? (
					<>
						{props.group.local_selected ? (
							<div className="ph-withdrawal-pharmacy-local-list-main-container">
								<Row className="ph-withdrawal-pharmacy-local-list-inner-container">
									<Col span={24}>
										<div className="ph-withdrawal-pharmacy-local-selected-main-container">
											<Images
												classContent={'ph-withdrawal-pharmacy-local-selected-image-container'}
												classImage={'ph-withdrawal-pharmacy-local-selected-image'}
												default={props.group.image}
												title={props.group.name_editable}
											/>
											<h3 className="ph-withdrawal-pharmacy-local-selected-main-title">{props?.group?.local_selected?.name_local}</h3>
										</div>
										<Row>
											<Col xs={15} sm={13} md={13} lg={13} xl={13}>
												<div className="ph-withdrawal-pharmacy-local-selected-address-main-container">
													<Images
														classContent={'ph-withdrawal-pharmacy-list-button-info-destiny-image-container'}
														classImage={'ph-withdrawal-pharmacy-list-button-info-destiny-image'}
														webp={`${ENV_IMG}/react-header/pharol-location.webp`}
														jp2={`${ENV_IMG}/react-header/pharol-location.jxr`}
														jxr={`${ENV_IMG}/react-header/pharol-location.jp2`}
														default={`${ENV_IMG}/react-header/pharol-location.png`}
													/>
													<div>
														<div className="ph-withdrawal-pharmacy-list-button-info-start-destiny-container">
															<span className="ph-withdrawal-pharmacy-list-button-info-start-location-icon-title">
																{props.userAddress.commune} {props.userAddress.region} {props.userAddress.street}
															</span>
														</div>
														<div className="ph-withdrawal-pharmacy-list-button-info-road-location-icon" />
														<div className="ph-withdrawal-pharmacy-list-button-info-road-location-icon" />
														<div className="ph-withdrawal-pharmacy-list-button-info-road-location-icon" />
														<div className="ph-withdrawal-pharmacy-list-button-info-destiny-container">
															<span className="ph-withdrawal-pharmacy-list-button-info-start-location-icon"></span>
															<h4 className="ph-withdrawal-pharmacy-list-button-info-destiny-location-icon-title">
															
																{/* {props.group.local_selected.region} {props.group.local_selected.direction} */}
																{props?.group?.local_selected?.direction + ',' + props.group.local_selected.commune + ',' + props.group.local_selected.region}
															</h4>
														</div>
													</div>
												</div>
											</Col>
											{!isMobile && (
												<Col span={5}>
													<div className="ph-withdrawal-pharmacy-local-selected-destiny-location-local-container">
														<div>
															<h4 className="ph-withdrawal-pharmacy-local-selected-destiny-location-local-title">Local:</h4>
															<h4 className="ph-withdrawal-pharmacy-local-selected-destiny-location-local-subtitle">
																{props.group.local_selected.name_local}({props.group.local_selected.local})
															</h4>
														</div>
													</div>
												</Col>
											)}
											<Col span={1}>
												<div className="ph-withdrawal-pharmacy-local-selected-location-separator" />
											</Col>
											<Col xs={8} sm={5} md={5} lg={5} xl={5}>
												{props.group.locals.length > 1 && (
													<>
														<div className="ph-withdrawal-pharmacy-local-selected-deselect-button-container" onClick={() => handleDeleteLocal()}>
															<h4 className="ph-withdrawal-pharmacy-local-selected-deselect-button-title">
																Seleccionado
																<span className="ph-withdrawal-pharmacy-local-selected-deselect-button-icon"></span>
															</h4>
														</div>
													</>
												)}

												<div onClick={() => handlePreviewLocal(props.group.local_selected)}>
													<h4 className="ph-withdrawal-pharmacy-local-selected-preview-button">ver mapa</h4>
												</div>
											</Col>
										</Row>
									</Col>
								</Row>
								<Row>
									<Col xs={24} sm={12} md={12} lg={12} xl={12} className="ph-withdrawal-pharmacy-local-list-item-container">
										<h4 className="ph-withdrawal-pharmacy-local-list-item-main-title">Día de entrega:</h4>
										{props.group.local_selected.date_selected && (
											<Form.Item name={`date_schedule_${props.group.name}`} rules={rulesValidation.rulesDeliveryDate}>
												<Select
													className="ph-withdrawal-pharmacy-local-selector"
													style={{ width: '100%' }}
													size="large"
													allowClear
													placeholder="Selecciona una Fecha"
													onChange={(value) => handleDateSelected(value, props.group)}
												>
													{(ENVIROMENT.ROOT_RESERVE ? props.group.local_selected.date_selected.slice(1) : props.group.local_selected.date_selected).map(
														(element, i) => (
															<Select.Option value={element.date} key={i}>
																{element.date}
															</Select.Option>
														),
													)}
												</Select>
											</Form.Item>
										)}
									</Col>
									{props.group.local_selected.day_selected && (
										<Col xs={24} sm={12} md={12} lg={12} xl={12} className="ph-withdrawal-pharmacy-local-list-item-container">
											<h4 className="ph-withdrawal-pharmacy-local-list-item-main-title">Hora de entrega:</h4>
											<Form.Item name={`day_schedule_${props.group.name}`} rules={rulesValidation.rulesDeliveryDate}>
												<Select
													className="ph-withdrawal-pharmacy-local-selector"
													style={{ width: '100%' }}
													size="large"
													allowClear
													placeholder="Selecciona el Horario"
												>
													{props.group.local_selected.times_selected.map((element, i) => (
														<Select.Option value={`${element.start} - ${element.finish}`} key={i}>
															{`${element.start} - ${element.finish}`}
														</Select.Option>
													))}
												</Select>
											</Form.Item>
										</Col>
									)}
								</Row>
							</div>
						) : (
							<LocalButton mobile={isMobile} pharmacy={props.group} selectLocal={handleSelectLocal} />
						)}
					</>
				) : (
					<div className="ph-withdrawal-pharmacy-local-list-main-container">
						<div>
							<h4 className="ph-withdrawal-pharmacy-local-list-main-on-demand-title">Dirección de entrega para producto Contra Pedido</h4>
							<Images
								classContent={'ph-withdrawal-pharmacy-local-list-address-image-container'}
								classImage={'ph-withdrawal-pharmacy-local-list-address-image'}
								webp={`${ENV_IMG_HEADER}/pharol-location.webp`}
								jp2={`${ENV_IMG_HEADER}/pharol-location.jp2`}
								jxr={`${ENV_IMG_HEADER}/pharol-location.jxr`}
								default={`${ENV_IMG_HEADER}/pharol-location.png`}
								title={props.title}
							/>
							<h3 className="ph-withdrawal-pharmacy-local-list-address-title">
								La dirección de esta farmacia es: {props.group.local_selected.direction}, {props.group.local_selected.distance},{' '}
								{props.group.local_selected.duration}.
							</h3>
						</div>
					</div>
				)}
			</div>
			<ModalLocalSelected
				isVisible={isVisible}
				isPreviewLocal={isPreviewLocal}
				userAddress={props.userAddress}
				handlePreviewLocal={(data) => handlePreviewLocal(data)}
			/>
		</>
	);
}
