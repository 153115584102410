/** @format */

import React, { Component } from "react";

import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import Images from "../../../../../../components/Commons/Images";

import locationIcon from "../../../../../../components/Commons/Assets/Icons/Filled/EnabledOpacity/location.svg";

import servicesAddress from "../../services";
import "./style.scss";

export default class AddressList extends Component {
  handleDeleteAddress = (item) => {
    this.props.list.splice(item, 1);
    servicesAddress
      .manageAddress(this.props.email, this.props.list, "delete")
      .then((response) => {
        this.setState({ list: response });
      });
  };

  render() {
    return (
      <>
        {this.props?.list?.length > 0 ? (
          <>
            {this.props.list.map((item, i) => (
              <div className="ph-profile-address-list-container" key={i}>
                <h3 className="ph-profile-address-list-title">
                  {i + 1}. {item.alias}
                </h3>
                <h4 className="ph-profile-address-list-description">
                  {item.location.formatted_address}
                </h4>
                <Button
                  className="ph-profile-address-list-button"
                  onClick={() => this.handleDeleteAddress(i, item.alias)}
                >
                  <DeleteOutlined />
                </Button>
              </div>
            ))}
          </>
        ) : (
          <>
            <div className="ph-profile-address-none-list-content">
              <Images
                classContent={"ph-profile-address-image-container-none"}
                classImage={"ph-profile-address-image-none"}
                default={locationIcon}
                title={this.props.title}
              />
              <h3 className="ph-profile-address-title-none">
                Ingrese una Dirección
              </h3>
              <p className="ph-profile-address-description-none">
                Al tener varias direcciones te sera más fácil agregarlas al
                momento de comprar.
              </p>
            </div>
          </>
        )}
      </>
    );
  }
}
