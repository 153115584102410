/** @format */

import axios from 'axios'

import Notify from '../../../../../../components/Commons/Notify'

import {
	ENV_CALCULATION,
	ENV_ROOT_PHARMACY,
	ENV_COUNTRY,
} from '../../../../../../components/Commons/Hooks/Variables/Enviroment'

import { GetUserData } from '../../../../../../components/Commons/Hooks/Functions/GetUserData'

export async function BuyNow(item, quantity, location, locals, voucherPharmacies, typeRetirement) {
	let returnResponse

	const email = localStorage.getItem('userEmail')
	const userToken = localStorage.getItem('userToken')

	let userData

	if (email) userData = await GetUserData(email)

	item.quantity = quantity
	item.unit_price = item.price
	item.sub_total = item.price * quantity

	const data = {
		products: [item],
		lat: location.lat,
		lng: location.lng,
		type_retirement: typeRetirement,
		rut: userData?.rut,
		email: email,
		token_auth: userToken,
		code_voucher: null,
		voucher_pharmacy: voucherPharmacies,
		locals: locals,
		country: ENV_COUNTRY,
		root_pharmacy: ENV_ROOT_PHARMACY,
		address: location.address,
	}

	await axios({
		method: 'POST',
		url: `${ENV_CALCULATION}/`,
		data: data,
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data
			} else if (response.data.statusCode === 409) {
				Notify({
					title: `Error Servicio ${response.data.statusCode}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Problemas con el servicio`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				})
			} else if (response.data.statusCode === 400) {
				Notify({
					title: `${response.data.error} - ${response.data.statusCode}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `${response.data.message}`,
					time: 6,
					image: 'error',
					placement: 'topRight',
				})
			} else {
				Notify({
					title: `¡Error al Calcular tus productos! - (${response.data.error})`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Algo pasó, te pedimos intentar nuevamente. Si el problema persiste, escríbenos al chat. - (${response.data.message})`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				})
			}
		})
		.catch(() => {
			Notify({
				title: `Error de conexión`,
				colorTitle: 'ph-main-notification-error-title',
				description: 'Revisa tu conexión de internet..',
				time: 3,
				image: 'error',
				placement: 'topRight',
			})
		})
	return returnResponse
}
