/** @format */

import axios from 'axios'

import Notify from '../../../../components/Commons/Notify'

import { ENV_USER } from '../../../../components/Commons/Hooks/Variables/Enviroment'

export default async function PurchaseHistoryServices() {
	let returnResponse
	await axios({
		method: 'GET',
		url: `${ENV_USER}/account/purchases`,
		headers: {
			Authorization: `bearer ${localStorage.getItem('userToken')}`,
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data
			} else {
				Notify({
					title: `Error de Consulta`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Problemas con el servicio: ...account/purchases`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				})
			}
		})
		.catch((error) => {
			Notify({
				title: `Error Servicio ${error}`,
				colorTitle: 'ph-main-notification-error-title',
				description: 'Revise su conexión a Internet.',
				time: 3,
				image: 'error',
				placement: 'topRight',
			})
		})
	return returnResponse
}
