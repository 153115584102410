/** @format */

import axios from 'axios';
import Notify from '../../../components/Commons/Notify';

import { ENV_USER, ENV_CAMPAIGN, ENV_COUNTRY, ENV_ROOT_PHARMACY } from '../../../components/Commons/Hooks/Variables/Enviroment';

export const userRegisterService = async (item) => {
	let returnResponse;
	await axios({
		method: 'POST',
		url: `${ENV_USER}/user/create/${ENV_COUNTRY}`,
		data: {
			email: item.email,
			first_name: item.first_name,
			last_name: item.last_name,
			password: item.password,
		},
		params: {
			root_pharmacy: ENV_ROOT_PHARMACY,
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				Notify({
					title: `Hola`,
					colorTitle: 'ph-main-notification-success-title',
					description: `${
						ENV_COUNTRY === 'AR'
							? 'Estás a un solo paso de formar parte de Benvida. Por favor revisá tu correo electrónico para activar la cuenta.'
							: '¡Estás a tan solo un paso de disfrutar de todos nuestros beneficios! Revisa tu correo electrónico, para activar tu cuenta.'
					}`, // PARCHE FABO - BUSCAR LA FORMA QUE FUUNCIONE DICCIONARIO
					time: 10,
					image: 'warning',
					placement: 'topRight',
				});
				returnResponse = response.data;
			} else {
				Notify({
					title: `Error`,
					colorTitle: 'ph-main-notification-error-title',
					description: `${response.data.message}...`,
					time: 5,
					image: 'error',
					placement: 'topRight',
				});
				returnResponse = response.data;
			}
		})
		.catch(() => {
			Notify({
				title: `Error Servicio`,
				colorTitle: 'ph-main-notification-error-title',
				description: 'Problemas con el servicio - ENV_USER',
				time: 5,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
};

export const userActiveCampaingService = async (item) => {
	let returnResponse;
	await axios({
		method: 'POST',
		url: `${ENV_CAMPAIGN}/`,
		data: item,
	})
		.then((response) => {
			returnResponse = response;
		})
		.catch((error) => {
			console.log('error', error);
		});
	return returnResponse;
};
