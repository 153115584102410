import locationDisabled from '../../../../Assets/Icons/Filled/Enabled/location.svg';
import locationArgDisabled from '../../../../Assets/Icons/Filled/Disabled/Argentina/localizacion1-2.png';
import { Enviroment } from '../../../../../../_config/constants/enviroment-v2.constant';

export const handleLocationImage = () => {
	const Icon = {
		AR: locationArgDisabled,
		CL: locationDisabled,
	};

	return Icon[Enviroment.COUNTRY];
};
