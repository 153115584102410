/** @format */

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Form, Layout, Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { setGlobal } from 'reactn';
import { GlobalConsumer } from '../../../components/Commons/Context/Global';
import { GetFavorites } from '../../../components/Commons/Favorites/Functions';
import InputField from '../../../components/Commons/Input';
import MetaDescription from '../../../components/Commons/MetaDescription';
import Spacer from '../../../components/Commons/Spacer';
import CustomButton from '../../../v2-components/Antd/Button';
import { LoginUserService } from './services';
import { IMAGES } from '../../../v2-config/constants/images.constant';
import './style.scss';

export default function Login(props) {
	const { t } = useTranslation('global');
	const { setLocation, setLoginUser } = GlobalConsumer();
	const [isLoading, setLoading] = useState(false);

	const handleLoginUser = async (item) => {
		setLoading(true);
		await LoginUserService(item).then((response) => {
			setLoading(false);
			if (response) handleFindInfoUser(response);
		});
	};

	const handleFindInfoUser = async (item) => {
		localStorage.setItem('userToken', item.token);
		localStorage.setItem('userEmail', item.email);
		localStorage.setItem('userMainInfo', JSON.stringify(item?.info));
		localStorage.setItem('cart', JSON.stringify([]));
		setGlobal(() => ({ userMainInfo: item?.info }));
		setLoginUser({ email: item.email, token: item.token });
		GetFavorites(item.token);
		if (item?.address?.length > 0) {
			let recentAddress = item.address[item.address.length - 1];
			let addressSplit = recentAddress.location.formatted_address.split(',');
			let address = {
				lat: recentAddress.location.geometry.location.lat,
				lng: recentAddress.location.geometry.location.lng,
				address: recentAddress.location.formatted_address,
				street: addressSplit[0],
				commune: addressSplit[1],
				region: addressSplit[2],
			};
			localStorage.setItem('locationStore', JSON.stringify(address));
			setGlobal(() => ({ address: address }));
			setLocation(address);
		}
		props.history.push('/profile-info');
	};

	useEffect(() => {
		if (localStorage.getItem('userEmail') && localStorage.getItem('userToken')) props.history.push('/');
	}, [props.history]);

	return (
		<>
			<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			<Layout className="ph-auth-login-main-container">
				<Row justify="center" className="ph-auth-background">
					<Col md={12} xs={22}>
						<Form name="normal_login" initialValues={{ remember: true }} onFinish={handleLoginUser} className="ph-auth-login-form-main-container">
							<div className="ph-auth-login-body-container">
								<Row justify="center">
									<img src={IMAGES.LOGO_LIGHT_BACKGROUND} alt="logo" className="logo" height={42} />
								</Row>
								<div className="ph-auth-login-title-container">
									<div>
										<h2 className="ph-auth-login-title">Iniciar Sesión</h2>
										<h3 className="ph-auth-login-subtitle">{t('login.subtitle')}</h3>
									</div>
								</div>
								<div className="ph-auth-login-form-container">
									<InputField
										className={'ph-auth-login-field-input'}
										inputName={'email'}
										inputNameLabel={'Correo electrónico'}
										inputNameRule={true}
										inputNameMessage={'E-mail es obligatorio'}
										inputNameType={'text'}
										inputNameIcon={''}
										inputNameRules={'rulesEmail'}
									/>
									<InputField
										className={'ph-auth-login-field-input'}
										inputName={'password'}
										inputNameLabel={'Contraseña'}
										inputNameRule={true}
										inputNameMessage={'La contraseña es obligatoria'}
										inputNameType={'password'}
										inputNameIcon={''}
										inputNameRules={'rulesPassword'}
									/>
								</div>

								<div className="ph-auth-login-recover-link-container">
									<Link to="/auth/recover-password">
										<h3 className="ph-auth-login-recover-link-description">Olvidé mi contraseña.</h3>
									</Link>
								</div>

								<div className="ph-auth-login-register-container">
									<h3 className="ph-auth-login-register-description">¿Aún no estas registrado?</h3>
									<Link to="/auth/register">
										<h5 className="ph-auth-login-register-link-description">{t('login.registerLink')}</h5>
									</Link>
								</div>
							</div>
							<Spacer />
							<div className="ph-auth-login-footer-container">
								<div className="ph-auth-login-footer-button-home-container">
									<Link className="ph-auth-login-footer-button-home-inner-container" to="/">
										<ArrowLeftOutlined className="ph-auth-login-footer-button-home-icon" />
										<h3 className="ph-auth-login-footer-button-home-title">Volver</h3>
									</Link>
								</div>
								<Spacer />
								<div className="ph-auth-login-main-button-container">
									<Form.Item>
										<CustomButton className={'button-primary'} htmlType={'submit'} loadingSubmit={isLoading}>
											Iniciar Sesión
										</CustomButton>
									</Form.Item>
								</div>
							</div>
						</Form>
					</Col>
				</Row>
			</Layout>
		</>
	);
}
