/** @format */

import React from 'react';

import { Checkbox } from 'antd';

import { formatPrice } from '../../../../../../../../components/Commons/Hooks/Functions/FormatPrice';

import './styles.scss';

export default function PharolPriceContainer(props) {
	return (
		<div className="ph-pharol-price-container">
			<div className="ph-pharol-price-title-check-container">
				<div className="ph-pharol-price-title-container">
					<span className="ph-pharol-price-title">{props.pharolPriceProduct.editable_name}:</span>
					<span className="ph-pharol-price-title-desc-one"> {props.pharolPriceProduct.discount_description.title.slice(0, 10)} </span>
					<span className="ph-pharol-price-title-desc-second">
						{props.pharolPriceProduct.discount_description.title.slice(10, 24)}
						{formatPrice(props.pharolPriceProduct.discount_description.discount)}
						{props.pharolPriceProduct.discount_description.title.slice(28)}
					</span>
				</div>
				<div className="ph-pharol-price-checkbox">
					<span className="ph-pharol-price-checkbox-title">Solicitar</span>
					<Checkbox checked={props.pharolPriceCheck} onChange={props.handlePharolPrice} disabled={!props.isPharmacies} />
				</div>
			</div>
			<div className="ph-pharol-price-description">{props.pharolPriceProduct.discount_description.description}</div>
		</div>
	);
}
