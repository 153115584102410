/** @format */

import axios from 'axios';

import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import { ENV_FAVORITES, ENV_COUNTRY, ENV_ROOT_PHARMACY } from '../../Hooks/Variables/Enviroment';

import favoriteIcon from '../../Assets/Icons/Filled/Enabled/favorite.svg';

import Notify from '../../Notify';
import Images from '../../Images';

import './style.scss';

export const GetFavorites = async (item) => {
	let returnResponse;

	await axios({
		method: 'GET',
		headers: {
			Authorization: `Bearer ${item}`,
		},
		url: `${ENV_FAVORITES}/favorite/${ENV_COUNTRY}/list`,
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data.favoriteIds;
				localStorage.setItem('userFavorites', JSON.stringify(returnResponse));
			} else {
				Notify({
					title: `Error: ${response.data.statusCode}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `${response.data.message}`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			}
		})
		.catch(() => {
			Notify({
				title: 'Problemas de Conexión',
				colorTitle: 'ph-main-notification-error-title',
				description: 'Revisa tu conexión o ponte en contacto con nosotros',
				time: 4,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
};

export const GetFavoritesList = async (item, data) => {
	let returnResponse;

	await axios({
		method: 'GET',
		headers: {
			Authorization: `Bearer ${item}`,
		},
		url: `${ENV_FAVORITES}/favorite/${ENV_COUNTRY}`,
		params: {
			page: data,
			limit: 10,
			root_pharmacy: ENV_ROOT_PHARMACY,
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data;
			} else {
				Notify({
					title: `Error: ${response.data.statusCode}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `${response.data.message}`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			}
		})
		.catch(() => {
			Notify({
				title: 'Problemas de Conexión',
				colorTitle: 'ph-main-notification-error-title',
				description: 'Revisa tu conexión o ponte en contacto con nosotros',
				time: 4,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
};

export const AddFavorites = async (item, data) => {
	let returnResponse;
	await axios({
		method: 'POST',
		headers: {
			Authorization: `Bearer ${item}`,
		},
		url: `${ENV_FAVORITES}/favorite/${ENV_COUNTRY}/${data.id_pharol}`,
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data.favoriteIds;
			} else {
				Notify({
					title: `Aviso:`,
					colorTitle: 'ph-main-notification-success-title',
					description: `Su producto se encuentra actualmente en su lista de Favoritos.`,
					time: 3,
					image: 'warning',
					placement: 'topRight',
				});
			}
		})
		.catch(() => {
			Notify({
				title: 'Problemas de Conexión',
				colorTitle: 'ph-main-notification-error-title',
				description: 'Revisa tu conexión o ponte en contacto con nosotros',
				time: 4,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
};

export const DeleteFavorites = async (item, data) => {
	let returnResponse;
	await axios({
		method: 'DELETE',
		headers: {
			Authorization: `Bearer ${item}`,
		},
		url: `${ENV_FAVORITES}/favorite/${ENV_COUNTRY}/${data}`,
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data.favoriteIds;
			} else {
				Notify({
					title: `Error: ${response.data.statusCode}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Error al Borrar su producto de favoritos.`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			}
		})
		.catch(() => {
			Notify({
				title: 'Problemas de Conexión',
				colorTitle: 'ph-main-notification-error-title',
				description: 'Revisa tu conexión o ponte en contacto con nosotros',
				time: 4,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
};

export const FavoritesNotification = (item) => {
	const HandleLoginLink = () => {
		notification.close(1);
		item.push('/auth/login');
	};

	const HandleRegisterLink = () => {
		notification.close(1);
		item.push('/auth/register');
	};

	notification.open({
		duration: 5,
		className: 'ph-notification-favorites-access-global-container',
		description: (
			<>
				<div className="ph-notification-favorites-access-title-container">
					<Images classContent={'ph-favorites-notify-image-container'} classImage={'ph-favorites-notify-image'} default={favoriteIcon} title={'favoritos'} />
					<h3 className="ph-notification-favorites-access-title">¿Deseas agregar a favoritos?</h3>
				</div>
				<div className="ph-notification-favorites-access-description-container">
					<h3 className="ph-notification-favorites-access-description">
						Para poder agregar un producto a favoritos es necesario que te registres o inicies sesión.
					</h3>
				</div>
				<Row className="ph-notification-favorites-access-actions-container">
					<Col span={12} className="ph-notification-favorites-access-actions-col">
						<Button onClick={() => HandleLoginLink()} className="ph-notification-favorites-access-button-login">
							Iniciar Sesión
						</Button>
					</Col>
					<Col span={12} className="ph-notification-favorites-access-actions-col">
						<Button onClick={() => HandleRegisterLink()} className="ph-notification-favorites-access-button-register">
							Regístrate
						</Button>
					</Col>
				</Row>
			</>
		),
		key: 1,
		placement: 'topRight',
	});
};

export const FavoritesNotificationEmail = async (item, id_product, status, date, day) => {
	let returnResponse;
	await axios({
		method: 'POST',
		headers: {
			Authorization: `Bearer ${item}`,
		},
		url: `${ENV_FAVORITES}/favorite/notification/${ENV_COUNTRY}/${id_product}`,
		data: {
			status: status,
			initial_date: date,
			space_days: day,
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data;
			} else {
				Notify({
					title: `Error: ${response.data.statusCode}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Error al Borrar su producto de favoritos.`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			}
		})
		.catch(() => {
			Notify({
				title: 'Problemas de Conexión',
				colorTitle: 'ph-main-notification-error-title',
				description: 'Revisa tu conexión o ponte en contacto con nosotros',
				time: 4,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
};
