/** @format */

import React from 'react'

import { formatPrice } from '../../../../../../../../../../../../../../components/Commons/Hooks/Functions/FormatPrice'

import './style.scss'

export default function PriceProduct(props) {
	return (
		<>
			{props.item.interface && (
				<>
					{props.item.interface.promotions ? (
						<>
							<p
								className={
									props.item.interface.promotions.price_promotion
										? 'ph-electronic-quote-price-before-symbol'
										: 'ph-electronic-quote-price-normal-symbol'
								}>
								$
							</p>
							<p
								className={
									props.item.interface.promotions.price_promotion
										? 'ph-electronic-quote-price-before'
										: 'ph-electronic-quote-price-normal'
								}>
								{formatPrice(props.item.price)}
							</p>
						</>
					) : (
						<>
							<p className='ph-electronic-quote-price-normal-symbol'>$</p>
							<p className='ph-electronic-quote-price-normal'>{formatPrice(props.item.price)}</p>
						</>
					)}
				</>
			)}
		</>
	)
}
