import { Enviroment } from '../../../../../_config/constants/enviroment-v2.constant';
import CustomImage from '../../../../../v2-components/Antd/Image';
import { Icons } from '../constant/icons.constant';

export const handleTagIcon = (product, width) => {
	if (product.validate_recipe === 'TRUE') {
		return null;
	}
	if (product.promotions === null) {
		return null;
	}
	if (product.promotions === null) {
		return null;
	}

	if (width) {
		return <CustomImage width={width} src={Icons[Enviroment.COUNTRY].promotion_tag} />;
	}
	return <CustomImage src={Icons[Enviroment.COUNTRY].promotion_tag} />;
};
