/** @format */

import { setGlobal } from 'reactn';
import Notify from '../../../../components/Commons/Notify';
import { amountProductsCart } from '../../../../components/Commons/Hooks/Functions/AmountProductsCart';

export default function BagDeleteQuantityProduct() {
	const handleDeleteQuantityProduct = (cart, product) => {
		if (product.pbm_data) {
			Notify({
				title: `¡Advertencia!`,
				colorTitle: 'ph-main-notification-error-title',
				description: 'Tienes un convenio PBM asociado a este producto, si deseas modificar la cantidad, debes eliminar primero la adjudicacion.',
				time: 3,
				image: 'warning',
				placement: 'topRight',
			});
			return;
		}
		const index = cart.findIndex((search) => {
			if (search.objectID) return search.objectID === product.objectID;
			else return search.id === product.id;
		});
		if (cart[index].quantity > 1) {
			cart[index].quantity = cart[index].quantity - 1;
			cart[index].sub_total = cart[index].quantity * cart[index].price;
			cart.forEach((item) => {
				if (item.pharmacy === cart[index].pharmacy) delete item.type_retirement;
			});
			localStorage.setItem('cart', JSON.stringify(cart));
			setGlobal(() => ({ carQuantity: amountProductsCart() }));
		} else return;

		return cart;
	};

	return {
		handleDeleteQuantityProduct,
	};
}
