/** @format */

import React from 'react'

import { setGlobal } from 'reactn'

import Images from '../../../../../../../../components/Commons/Images'
import RationalBanner from '../../../../../../../../components/Commons/Modals/RationalBanner'

import {
	ENV_IMG_HOME,
	ENV_COUNTRY,
} from '../../../../../../../../components/Commons/Hooks/Variables/Enviroment'

import Mediclic from './components/Mediclic'

import './style.scss'
import CountryValidator from '../../../../../../../../components/Commons/CountryValidator'

export default function ProductQuoteContactRational(props) {
	return (
		<div>
			{props.item.details[0].prescription_type === 'NP' &&
				props.item.details[0].master_category === 'Medicamentos' && (
					<CountryValidator
						countries={['CL']}
						component={
							<div
								className='ph-product-detail-quote-rectangle-info-main-container'
								onClick={() =>
									setGlobal({
										rationalModal: true,
									})
								}>
								<Images
									classContent={'ph-product-detail-quote-rectangle-main-image-container'}
									classImage={'ph-product-detail-quote-rectangle-main-image'}
									default={`${ENV_IMG_HOME}/rational-icon.png`}
									title={'Uso racional de Medicamentos'}
									alt={'Uso racional de Medicamentos'}
								/>
								<div>
									<h3 className='ph-product-detail-quote-rectangle-info-title'>
										Uso racional de Medicamentos
									</h3>
									<h4 className='ph-product-detail-quote-rectangle-info-subtitle'>
										Llama a salud responde
									</h4>
									<h4 className='ph-product-detail-quote-rectangle-info-description'>600-360-7777</h4>
								</div>
							</div>
						}
					/>
				)}
			{props.mobile && (
				<>
					{props.item.details[0].prescription_type !== 'NP' &&
						props.item.details[0].master_category === 'Medicamentos' &&
						ENV_COUNTRY === 'CL' && <div className='ph-product-detail-quote-mobile-spacer'></div>}
				</>
			)}
			<div className='ph-product-detail-quote-contact-mediclic-description'>
				{props.item.prescription_type !== 'NP' &&
					props.item.master_category === 'Medicamentos' &&
					ENV_COUNTRY === 'CL' && <Mediclic />}
			</div>
			<RationalBanner />
		</div>
	)
}
