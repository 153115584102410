/** @format */

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Radio, Col, Row, Form } from 'antd';

import MetaDescription from '../../components/Commons/MetaDescription';
import InputField from '../../components/Commons/Input';
import Images from '../../components/Commons/Images';
import ButtonCustom from '../../components/Commons/ButtonCustom';
import Notify from '../../components/Commons/Notify';
import pharolGoService from './services';

import InputRut from '../../components/Commons/InputCountry/Rut';
import InputPhone from '../../components/Commons/InputCountry/Phone';

import { ENV_COUNTRY } from '../../components/Commons/Hooks/Variables/Enviroment';

import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';

import data from './data.json';

import './style.scss';

export default function PharolGo() {
	const { t } = useTranslation('global');

	const [form] = Form.useForm();
	const [isCar, setCar] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [isBike, setBike] = useState(false);
	const [isOtherVehicle, setOtherVehicle] = useState(false);
	const [show, setShow] = useState({
		afirmativeProfession: false,
		negativeProfession: false,
	});
	const { afirmativeProfession, negativeProfession } = show;
	const handleChangeProfesion = (item) => {
		if (item.target.value === 'SI') {
			setShow({
				...show,
				afirmativeProfession: true,
				negativeProfession: false,
			});
		} else if (item.target.value === 'NO') {
			setShow({
				...show,
				afirmativeProfession: false,
				negativeProfession: true,
			});
		}
	};
	const handleSetCar = (item) => {
		if (item.target.checked) {
			setCar(true);
		} else {
			setCar(false);
		}
	};
	const handleSetBike = (item) => {
		if (item.target.checked) {
			setBike(true);
		} else {
			setBike(false);
		}
	};
	const handleSetOtherVehicle = (item) => {
		if (item.target.checked) {
			setOtherVehicle(true);
		} else {
			setOtherVehicle(false);
		}
	};
	const handleVehicleStatus = (vehicle, bike, bici, another) => {
		if (!vehicle && !bike && !bici && !another) {
			return false;
		} else {
			return true;
		}
	};
	const handleSubmitPharolGo = async (item) => {
		setLoading(true);
		if (!handleVehicleStatus(item.vehicle, item.motorcycle, item.bici, item.another_vehicle)) {
			Notify({
				title: '¡Importante!',
				colorTitle: 'ph-main-notification-warning-title',
				description: 'Debe Seleccionar un vehículo.',
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
			setLoading(false);
			return;
		}
		await pharolGoService(item).then((response) => {
			if (response) {
				setCar(false);
				setBike(false);
				setOtherVehicle(false);
				setShow({
					...show,
					afirmativeProfession: false,
					negativeProfession: false,
				});
				form.resetFields();
			}
		});
		setLoading(false);
	};

	return (
		<div className="ph-rapigo-contains">
			<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			<div className="ph-rapigo-header-container">
				<div className="ph-rapigo-header-content">
					<div className="ph-rapigo-header">
						<Row className="ph-rapigo-header-inner-content">
							<Col xs={24} className="ph-rapigo-header-main-content">
								<ScrollAnimation animateIn="animate__zoomIn" animateOnce={true}>
									<Images
										classContent={'ph-rapigo-header-image-container'}
										classImage={'ph-rapigo-header-image-container'}
										default={`https://storage.googleapis.com/master-image-bucket/pharol-go/pharol-go-white.png`}
										title={'PharolGo logo'}
										alt={'PharolGo logo'}
									/>
								</ScrollAnimation>
							</Col>
						</Row>
					</div>
				</div>
			</div>
			<div className="ph-rapigo-global-container">
				<Row justify="center">
					<Col xs={24} sm={24} lg={24} xl={24} className="ph-rapigo-global-global-title">
						<div>
							<div className="ph-rapigo-global-title-container">
								<h1 className="ph-rapigo-global-title-text">¡Únete a la primera Red de Prestadores de Salud On Demand!</h1>
							</div>
						</div>
						<h1 className="ph-rapigo-global-subtitle-text">Con Pharol GO, llevémosle bienestar a nuestros pacientes.</h1>
					</Col>
				</Row>
				<div>
					<div xs={24} sm={24} lg={24} xl={24} className="ph-rapigo-form-main-container">
						<Form onFinish={handleSubmitPharolGo} name="pharolgo_form" form={form}>
							<div className="ph-rapigo-personal-date-container">
								<h3 className="ph-rapigo-form-title">Datos personales:</h3>
								<div>
									<Row className="ph-rapigo-personal-date-content">
										<Col xs={24} sm={24} md={8} lg={8}>
											<h3 className="ph-rapigo-form-name">Nombre</h3>
											<InputField
												className="ph-rapigo-personal-date-input"
												inputName="first_name"
												inputNameLabel="Nombre"
												inputNameRule={true}
												inputNameMessage="El nombre es obligatorio"
												inputNameType="text"
												inputNameRules="rulesFirstName"
											/>
										</Col>
										<Col xs={24} sm={24} md={8} lg={8}>
											<h3 className="ph-rapigo-form-name">Apellido</h3>
											<InputField
												className={'ph-rapigo-personal-date-input'}
												inputName={'last_name'}
												inputNameLabel={'Apellido'}
												inputNameRule={true}
												inputNameMessage={'El apellido es obligatorio'}
												inputNameType={'text'}
												inputNameRules={'rulesLastName'}
											/>
										</Col>
										<Col xs={24} sm={24} md={8} lg={8}>
											<h3 className="ph-rapigo-form-name">{data[ENV_COUNTRY].title_document}</h3>
											<InputRut inputRUT={'rut_number'} classInput={'ph-rapigo-personal-date-input'} />
										</Col>
									</Row>
								</div>
								<div className="ph-rapigo-date-profesion-container">
									<h3 className="ph-rapigo-date-profesion-text">¿Estudias o tienes una profesión?</h3>
									<Row className="ph-rapigo-form-prevision-container">
										<Col xs={12} sm={12} lg={12}>
											<div className="ph-rapigo-profession-dates">
												<Form.Item
													rules={[
														{
															required: true,
															message: 'introduzca su profesión.',
														},
													]}
													name="profession_summary"
												>
													<Radio.Group onChange={(value) => handleChangeProfesion(value)}>
														<Radio value="SI">Si</Radio>
														<Radio value="NO">No</Radio>
													</Radio.Group>
												</Form.Item>
											</div>
										</Col>
										{negativeProfession && (
											<Col xs={12} sm={12} lg={12}>
												<div className="ph-rapigo-profession-date">
													<p className="ph-rapigo-profession-p">¿Cuál es tu ocupación u oficio?</p>
													<InputField
														className={'ph-rapigo-ofice-input'}
														inputName="work_people"
														inputNameLabel={'Oficio'}
														inputNameRule={true}
														inputNameMessage={'Agregue el oficio'}
														inputNameType={'text'}
														inputNameRules={'rulesText'}
													/>
												</div>
											</Col>
										)}
									</Row>
								</div>
								{afirmativeProfession && (
									<div className="ph-rapigo-date-profesion-container">
										<h3 className="ph-rapigo-date-profesion-text">¿Cuál es tu nivel de estudios?</h3>
										<Row className="ph-rapigo-form-prevision-container">
											<Col xs={24} sm={24} lg={12}>
												<div className="ph-rapigo-profession-dates">
													<Form.Item
														name="experience_studies"
														rules={[
															{
																required: true,
																message: 'introduzca su profesión.',
															},
														]}
													>
														<Radio.Group>
															<Radio value="Profesional" className="ph-rapigo-text-radio">
																Profesión
															</Radio>
															<Radio value="Tecnico" className="ph-rapigo-text-radio">
																Técnico
															</Radio>
															<Radio value="Estudiante" className="ph-rapigo-text-radio">
																Estudiante
															</Radio>
														</Radio.Group>
													</Form.Item>
												</div>
											</Col>
											<Col xs={24} sm={24} lg={12}>
												<div className="ph-rapigo-profession-date">
													<p className="ph-rapigo-profession-date-p">¿Cuál es tu carrera?</p>
													<InputField
														className="ph-rapigo-type-profesion-input"
														inputName="profession_people"
														inputNameLabel="Tu profesión"
														inputNameRule={true}
														inputNameMessage="Agregue su profesión"
														inputNameType="text"
														inputNameRules="rulesText"
													/>
												</div>
											</Col>
										</Row>
									</div>
								)}
								<Row>
									<Col xs={24} sm={24} lg={24}>
										<div className="ph-rapigo-date-direction-container">
											<h3 className="ph-rapigo-form-name">Dirección</h3>
											<InputField
												className={'ph-rapigo-direction-input'}
												inputName={'address'}
												inputNameLabel={'Dirección'}
												inputNameRule={true}
												inputNameMessage={'La dirección es obligatorio'}
												inputNameType={'num'}
												inputNameRules={'rulesAddress'}
											/>
										</div>
									</Col>
								</Row>
								<Row className="ph-rapigo-email-direction-container">
									<Col xs={24} sm={24} lg={12}>
										<div className="ph-rapigo-email-phone-container">
											<h3 className="ph-rapigo-form-name">Correo</h3>
											<InputField
												className={'ph-rapigo-email-input'}
												inputName={'email'}
												inputNameLabel={'Email'}
												inputNameRule={true}
												inputNameMessage={'El email es obligatorio'}
												inputNameType={'num'}
												inputNameRules={'rulesEmail'}
											/>
										</div>
									</Col>
									<Col xs={24} sm={24} lg={12}>
										<div className="ph-rapigo-email-phone-container-2">
											<h3 className="ph-rapigo-form-name">Teléfono</h3>
											<InputPhone classInput={'ph-rapigo-personal-date-input-phone'} />
										</div>
									</Col>
								</Row>
							</div>
							<br />
							<div className="ph-rapigo-personal-date-container">
								<h3 className="ph-rapigo-form-title">Datos del vehículo:</h3>
								<div style={{ width: '100%' }}>
									<Row className="ph-rapigo-trasnport-container">
										<Col xs={12} sm={12} md={6} lg={6} className="ph-rapigo-transport-global-container">
											<div className="ph-rapigo-form-container-trasnport">
												<Form.Item name="vehicle" valuePropName="checked">
													<Checkbox onChange={(value) => handleSetCar(value)}>Vehículo</Checkbox>
												</Form.Item>
												<img className="ph-rapigo-form-img-trasnport" src="https://storage.googleapis.com/master-image-bucket/pharol-go/pharol-go-car.png" alt="pharol vehicle" />
											</div>
											{isCar && (
												<>
													<p className="ph-rapigo-trasnport-dates-container-p">Pantente:</p>
													<InputField
														className={'ph-rapigo-type-input'}
														inputName={'vehicle_number'}
														inputNameLabel={'Pantente'}
														inputNameRule={true}
														inputNameMessage={'El numero de patente es obligatorio'}
														inputNameType={'num'}
														inputNameRules={'rulesText'}
													/>
												</>
											)}
										</Col>
										<Col xs={12} sm={12} md={6} lg={6} className="ph-rapigo-transport-global-container">
											<div className="ph-rapigo-form-container-trasnport">
												<Form.Item name="motorcycle" valuePropName="checked">
													<Checkbox onChange={(value) => handleSetBike(value)}>Moto</Checkbox>
												</Form.Item>
												<img className="ph-rapigo-form-img-trasnports" src="https://storage.googleapis.com/master-image-bucket/pharol-go/pharol-go-motorcycle.png" alt="pharol vehicle" />
											</div>
											{isBike && (
												<>
													<p className="ph-rapigo-trasnport-dates-container-p">Pantente:</p>
													<InputField
														className={'ph-rapigo-type-input'}
														inputName={'motorcycle_number'}
														inputNameLabel={'Pantente'}
														inputNameRule={true}
														inputNameMessage={'El numero de patente es obligatorio'}
														inputNameType={'num'}
														inputNameRules={'rulesText'}
													/>
												</>
											)}
										</Col>
										<Col xs={12} sm={12} md={6} lg={6} className="ph-rapigo-transport-global-container">
											<div className="ph-rapigo-form-container-trasnport">
												<Form.Item name="bici" valuePropName="checked">
													<Checkbox>Bicicleta</Checkbox>
												</Form.Item>
												<img className="ph-rapigo-img-trasnports" src="https://storage.googleapis.com/master-image-bucket/pharol-go/pharol-go-bici.png" alt="pharol bici" />
											</div>
										</Col>
										<Col xs={12} sm={12} md={6} lg={6} className="ph-rapigo-transport-global-container">
											<div className="ph-rapigo-form-container-trasnport">
												<Form.Item name="another_vehicle" valuePropName="checked">
													<Checkbox onChange={(value) => handleSetOtherVehicle(value)}>Otro</Checkbox>
												</Form.Item>
												<img className="ph-rapigo-form-img-trasnport" src="https://storage.googleapis.com/master-image-bucket/pharol-go/another-vehicle.jpg" alt="pharol bici" />
											</div>
											{isOtherVehicle && (
												<>
													<p className="ph-rapigo-trasnport-dates-container-p">Tipo:</p>
													<InputField
														className={'ph-rapigo-type-input'}
														inputName={'another_vehicle_description'}
														inputNameLabel={'Tipo de vehículo'}
														inputNameRule={true}
														inputNameMessage={'El numero de patente es obligatorio'}
														inputNameType={'num'}
														inputNameRules={'rulesText'}
													/>
												</>
											)}
										</Col>
									</Row>
								</div>
							</div>
							<br />
							<div className="ph-rapigo-personal-date-container">
								<h3 className="ph-rapigo-form-title">Datos de dispositivo:</h3>
								<h3 className="ph-rapigo-form-subtitle">Tipo de teléfono móvil</h3>
								<Row className="ph-rapigo-telefhone-container">
									<Col xs={24} sm={24} lg={24} className="ph-rapigo-telefhone-content">
										<div>
											<Form.Item
												name="phone_model"
												rules={[
													{
														required: true,
														message: 'Especifique su modelo de movil.',
													},
												]}
											>
												<Radio.Group>
													<Radio className="ph-rapigo-form-movil-container" name="Android" value={'Android'}>
														Android <img className="ph-rapigo-form-img-telefone" src="https://storage.googleapis.com/master-image-bucket/pharol-go/android.png" alt="pharol vehicle" />
													</Radio>
													<Radio className="ph-rapigo-form-movil-container" name="Iphone" value={'Iphone'}>
														Iphone <img className="ph-rapigo-form-img-telefone" src="https://storage.googleapis.com/master-image-bucket/pharol-go/apple.png" alt="pharol vehicle" />
													</Radio>
													<Radio className="ph-rapigo-form-movil-container" name="Windows Phone" value={'Windows'}>
														Windows <img className="ph-rapigo-form-img-telefone" src="https://storage.googleapis.com/master-image-bucket/pharol-go/windows.png" alt="pharol vehicle" />
													</Radio>
													<Radio className="ph-rapigo-form-movil-container" name="Otro" value={'Otro'}>
														Otro <img className="ph-rapigo-form-img-telefone" src="https://storage.googleapis.com/master-image-bucket/pharol-go/other.png" alt="pharol vehicle" />
													</Radio>
												</Radio.Group>
											</Form.Item>
										</div>
									</Col>
								</Row>
							</div>
							<br />
							{!!data[ENV_COUNTRY]?.aditional_info && (
								<div className="ph-rapigo-personal-date-container">
									<h3 className="ph-rapigo-form-title">Información adicional:</h3>
									<h3 className="ph-rapigo-form-subtitle">¿Qué previsión de salud tienes?</h3>
									<div>
										<Row className="ph-rapigo-form-prevision-container">
											<Col xs={24} sm={24} lg={24} className="ph-rapigo-telefhone-content">
												<div>
													<Form.Item
														name="risk_channels"
														rules={[
															{
																required: true,
																message: 'Especifique su seguro.',
															},
														]}
													>
														<Radio.Group>
															{data[ENV_COUNTRY]?.aditional_info.map((item, index) => (
																<Radio value={item.name} name={item.name} className="ph-rapigo-span-texts" key={index}>
																	{item.name}
																</Radio>
															))}
														</Radio.Group>
													</Form.Item>
												</div>
											</Col>
										</Row>
									</div>
								</div>
							)}
							<div className="ph-rapigo-terms-container">
								<Form.Item
									className="ph-retirement-personal-data-agree-container"
									name="conditions_terms"
									valuePropName="checked"
									rules={[
										{
											validator: (_, value) => (value ? Promise.resolve() : Promise.reject('Acepta las condiciones para continuar.')),
										},
									]}
								>
									<Checkbox>
										{' '}
										<h5 className="ph-rapigo-terms-text">
											Al llenar este formulario el usuario acepta los
											<a className="ph-rapigo-link" target="_blank" rel="noreferrer noopener" href="https://storage.googleapis.com/fasa-pharol/Reporte%20Comentarios%20y%20Reclamos/PharolGo%20TyC.pdf">
												Términos y Condiciones
											</a>
											de Pharol Go.
										</h5>
									</Checkbox>
								</Form.Item>
							</div>
							<div className="ph-rapigo-btn-container">
								<ButtonCustom loadingSubmit={isLoading} classButton="ph-rapigo-btn" labelName="Registrarme" htmlType="submit" />
							</div>
						</Form>
					</div>
				</div>
				<div className="ph-rapigo-dowlon-container">
					<img className="ph-rapigo-dowlon-img" src="https://storage.googleapis.com/master-image-bucket/pharol-go/download-app (1).png" alt="download-app-1" />
					<img className="ph-rapigo-dowlon-img-two" src="https://storage.googleapis.com/master-image-bucket/pharol-go/download-app-inversed (1).png" alt="download-app-2" />
					<Row>
						<Col xs={24} sm={24} md={24} className="ph-rapigo-dowlon-content">
							<p className="ph-rapigo-dowlon-span">Descarga</p>
							<h1 className="ph-rapigo-dowlon-title">Nuestra App</h1>
							<p className="ph-rapigo-dowlon-paraghraf">Para disfrutar del mejor servicio "On Demand" del mercado.</p>
						</Col>
						<Col xs={24} sm={24} md={24} className="ph-rapigo-dowlon-content-two">
							<div className="ph-buy-section-6-buttons-content">
								<a href="https://play.google.com/store/apps/details?id=app.pharol.mensajero&hl=es_CL" target="_blank" rel="noreferrer noopener">
									<img width="100%" className="ph-rapigo-dowlon-googleplay" src="https://storage.googleapis.com/master-image-bucket/mediclic/botao-baixar-android.png" alt="imagen" />
								</a>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	);
}
