/** @format */

import React from 'react'

import { BagConsumer } from '../../../../components/Commons/Context/Bag'

import Spacer from '../../../../components/Commons/Spacer'

import BagPriceinfo from '../BagMainInfo/components/BagPriceInfo'
import BagButtonCalculate from '../BagMainInfo/components/BagButtonCalculate'

import './style.scss'

export default function BagMainInfoMobile(props) {
	const { isBagData } = BagConsumer()

	return (
		<div className='ph-bag-main-info-mobile-main-container'>
			<div>
				<BagPriceinfo amount={isBagData?.total} type={'primary'} title={'Total a Pagar:'} />
			</div>
			<Spacer />
			<BagButtonCalculate cartForm={props.cartForm} />
		</div>
	)
}
