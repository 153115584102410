import React from 'react';
import { ICONS } from '../../v2-config/constants/icons.constant';
import './style.scss';

export default function LoadingPage() {
	return (
		<div className="loading-page">
			<img src={ICONS.LOADING} alt={'Cargando...'} title={'Cargando...'} />
		</div>
	);
}
