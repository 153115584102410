/** @format */

import React from 'react';

import { Row, Col } from 'antd';

import { ENV_COUNTRY } from '../../components/Commons/Hooks/Variables/Enviroment';
import MetaDescription from '../../components/Commons/MetaDescription';
import translates from '../../components/Commons/translates';

import './style.scss';

export default function termsPharolGo() {
	return (
		<div className="ph-pharolGo-politics-container">
			<MetaDescription
				title={'PharolGo Políticas de privacidad - Red de Farmacias Online | Pharol'}
				name={'description'}
				content={'Red de Farmacias Online | Pharol - Para nosotros es importante tu privacidad, puedes revisar a continuación nuestras cláusulas al respecto.'}
			/>
			<div className="ph-pharolGo-politics-header">
				<Row justify="center">
					<Col>
						<h1 className="ph-pharolGo-politics-tittle">POLÍTICAS DE PRIVACIDAD</h1>
					</Col>
				</Row>
			</div>
			<div className="ph-pharolGo-politics-main">
				<Row justify="center">
					<Col>
						<p className="ph-pharolGo-politics-paragraph">
							<strong> PHAROL SpA </strong>(en adelante la <strong>“Empresa”</strong>) ha puesto a disposición de usted (en adelante también el <strong>“Usuario” </strong> los{' '}
							<strong>“Usuarios”</strong>) el servicio <strong>{translates.pharmacy[ENV_COUNTRY].toUpperCase()} ONLINE DE PHAROL</strong> (en adelante el Servicio{' '}
							<strong>“{translates.pharmacy[ENV_COUNTRY].toUpperCase()} ONLINE”</strong>), que consiste en un Sistema automatizado para la compraventa de productos farmacéuticos a través de
							Plataformas móvil (App), web móvil y web (<strong>www.pharol.cl</strong> y/u otros sitios a elección de <strong>PHAROL</strong>), todos de propiedad de <strong>PHAROL</strong>.
						</p>
						<p className="ph-pharolGo-politics-paragraph">
							La presente <strong>Política de Privacidad</strong> (en adelante la <strong>Política</strong>) tiene por finalidad describir el tratamiento de sus datos como resultado de la visita a
							nuestras Plataformas y en la utilización de nuestros servicios como resultado de la compraventa de productos que en ella se comercialicen.
						</p>
						<p className="ph-pharolGo-politics-paragraph">
							<strong>La Empresa</strong>, domiciliada en Chile, reconoce como legislación aplicable la Ley N°19.628 sobre protección de la vida privada (Ley de datos personales) y sus posteriores
							modificaciones. Se entenderá por datos de carácter personal o datos personales, los relativos a cualquier información concerniente a personas naturales, identificadas o identificables.
							Estos datos serán recopilados por el sólo hecho que usted ha entregado dicha información, como consecuencia de la suscripción en cualquiera de las Plataformas del Sistema de la Empresa o
							por el solo hecho de visitar las mismas.
						</p>
						<p className="ph-pharolGo-politics-paragraph">
							De los datos recolectados por estas vías, la <strong>Empresa</strong> otorga protección de sus datos,evitando la divulgación de los mismos. Para ello adoptará todos los medios técnicos
							que estén dentro de su alcance para evitar la pérdida, robo, hurto, alteración, acceso no autorizado y/o una apropiación indebida de los mismos. La Empresa realizará sus mejores
							esfuerzos para que dicha pérdida no ocurra, debido a que las medidas técnicas de seguridad que se puedan tomar para que esto no ocurra no son infalibles y la <strong>Empresa</strong> no
							puede hacerse cargo en caso que estas medidas de seguridad llegasen a fallar.
						</p>
						<p className="ph-pharolGo-politics-paragraph">
							La <strong>Empresa</strong> puede, de acuerdo a nuestra legislación vigente, efectuar un tratamiento de datos personales, siempre que lo haga de una manera razonable. Lo anterior puede
							implicar la recopilación interna y el análisis de datos para prestar un mejor servicio al cliente, buscar programas de beneficios para los <strong>Usuarios</strong> o simplemente para
							fines estadísticos. En todo caso, el tratamiento que se realizará al dato, impide que éste pueda ser asociado a una persona determinada.
						</p>
						<p className="ph-pharolGo-politics-paragraph">
							La Empresa reconoce que dentro de los datos entregados por los {''}
							<strong>Usuarios</strong> existen algunos de ellos más sensibles, como por ejemplo aquellos que se refieren a las características físicas o morales de las personas o a hechos o
							circunstancias de su vida privada o intimidad, los estados de salud físicos o psíquicos y la vida sexual. Sobre ellos, la <strong>Empresa</strong> no realizará tratamiento alguno, a
							menos que el propio <strong>Usuario</strong> lo autorice al efecto de manera expresa o nuestra legislación así lo permita.
						</p>
						<p className="ph-pharolGo-politics-paragraph">
							Se reconoce como una práctica frecuente, la utilización de cookies y/o herramientas para analíticas para la recolección de información. Las cookies y/o herramientas para analíticas son
							archivos que se transfieren desde un Sitio Web al disco duro del computador del <strong>Usuario</strong>, con el fin de que el <strong>Usuario</strong>
							,al ingresar nuevamente al sitio Web, éste lo reconozca.
						</p>
						<p className="ph-pharolGo-politics-paragraph">
							La <strong>Empresa</strong>,de acuerdo a sus términos y condiciones, establece que el servicio de <strong>{translates.pharmacy[ENV_COUNTRY].toUpperCase()} ONLINE </strong>
							solamente puede ser utilizado por mayores de 18 años de edad que hayan aceptado íntegramente los términos y condiciones. El <strong>Usuario</strong> que sea menor de edad, pero que
							cuente con la autorización de sus padres o de su representante legal, podrá utilizar los servicios, siempre y cuando sus padres o representante legal, hayan aceptado íntegramente los
							presentes términos y condiciones. En consecuencia, la <strong>Empresa</strong> no tiene por finalidad recopilar o recolectar información relacionada con menores de edad y, en caso que la
							información proporcionada por el Usuario a la <strong>Empresa</strong> no sea correcta, puede solicitar su eliminación al siguiente correo electrónico{' '}
							<strong> contacto@pharol.cl</strong>, eximiendo a la <strong>Empresa</strong> de cualquier responsabilidad por su uso. Será responsabilidad de los padres, tutores o representantes, la
							autorización de la información que los menores de edad que se encuentren bajo su cuidado, proporcionen a la <strong>Empresa</strong>.
						</p>
						<p className="ph-pharolGo-politics-paragraph">
							Al momento de ingresar a cualquiera de las Plataformas de la <strong>Empresa</strong>, usted acepta que ésta no se hará responsable de la información entregada por el usuario a terceros
							por medio de links alojados en nuestro Sitio Web.
						</p>
						<p className="ph-pharolGo-politics-paragraph">
							<strong>PHAROLGO</strong> comprende y respeta la necesidad de privacidad de nuestros usuarios. Este Aviso de privacidad (“Aviso”) describe los tipos de información que recopilamos, los
							fines para los que se utiliza y las opciones que tiene con respecto a su uso.
						</p>
						<p className="ph-pharolGo-politics-paragraph">
							Este Aviso se aplica a los usuarios del Servicio <strong>PHAROLGO</strong> (por ejemplo, conductores de reparto o <strong> “Conductores”</strong>, le recomendamos que lea este Aviso en
							su totalidad para comprender nuestras prácticas de privacidad antes de utilizar nuestros Servicios.
						</p>
						<p className="ph-pharolGo-politics-paragraph">
							Recopilamos la siguiente información a través de su uso de nuestros Servicios y, de lo contrario, con su consentimiento. En algunos casos, recibimos información directamente de usted,
							como cuando se registra para obtener una cuenta de despachador de <strong>PHAROLGO</strong> , recopilamos su nombre, dirección de correo electrónico y número de teléfono. Los tipos de
							información que recopilamos incluyen:
						</p>
						<p className="ph-pharolGo-politics-paragraph">
							<strong>1.</strong> Información sobre usted y su cuenta de <strong>PHAROLGO</strong> : nombre, dirección de correo electrónico, dirección postal, número de teléfono, información del
							cliente y de entrega (como información de programación y destino de entrega) y, en algunos casos, fotografías. Usted nos proporciona esta información directamente durante el proceso de
							registro de la cuenta.
						</p>
						<p className="ph-pharolGo-politics-paragraph">
							<strong>2.</strong> Información del vehículo de los conductores: método de transporte, año del vehículo, marca, modelo, color y número de placa. Usted nos proporciona esta información
							directamente cuando utiliza nuestros Servicios.
						</p>
						<p className="ph-pharolGo-politics-paragraph">
							<strong>3.</strong> Información sobre el comportamiento del conductor: recopilamos información sobre cómo conduce su vehículo. Esto incluye tiempo, ubicación, rumbo, velocidad y
							precisión. Recopilamos esta información automáticamente cuando utiliza nuestros Servicios.
						</p>
						<p className="ph-pharolGo-politics-paragraph">
							<strong>4.</strong> Información de geolocalización: la ubicación del conductor se recopila del servicio de ubicación nativo de su dispositivo, que combina GPS, torres de telefonía móvil,
							Wi-Fi y otros datos de sensores para identificar con precisión la latitud y longitud de un conductor mientras el conductor está “de servicio”. No accedemos, recopilamos ni almacenamos
							información de ubicación desde el dispositivo móvil de un conductor cuando está fuera de servicio o fuera de línea. Podemos recopilar otra información, como informes de fallas de
							aplicaciones o análisis de uso de aplicaciones para conductores que no están en línea, con el propósito expreso de proporcionar y mejorar nuestros Servicios. Podemos iniciar sesión de
							forma remota en la aplicación, con su permiso, para resolver un problema si nuestra aplicación falla.
						</p>
						<p className="ph-pharolGo-politics-paragraph">
							<strong>5.</strong> Información de contacto comercial: recopilamos información personal de individuos cuando asistimos o organizamos eventos, conferencias y otras reuniones de negocios.
							Esta información incluye información de contacto comercial como nombre, dirección de correo electrónico y número de teléfono.
						</p>
						<p className="ph-pharolGo-politics-paragraph">
							<strong>6.</strong> Información del dispositivo: dirección IP, tipo de dispositivo, sistema operativo, fabricante, modelo y número de versión, e identificadores únicos del dispositivo,
							como el ID de su dispositivo, pero no el IMEI. Recopilamos esta información automáticamente cuando utiliza nuestros Servicios.
						</p>
						<p className="ph-pharolGo-politics-paragraph">
							<strong>7.</strong> Información de uso y del navegador: sistema operativo (SO) que se ejecuta en su dispositivo, dirección de Protocolo de Internet (IP), tiempos de acceso, tipo de
							navegador e idioma, y el sitio web que visitó antes de nuestros Sitios. También recopilamos detalles de uso, como tiempo, frecuencia y patrón de uso. En algunos casos, analizamos
							información sobre usuarios finales específicos, como la cantidad de tiempo que pasan en la plataforma. En otros casos, miramos los datos en conjunto. Usamos esta información para
							optimizar nuestros Servicios. Recopilamos esta información automáticamente cuando utiliza nuestros Servicios.
						</p>
					</Col>
				</Row>
			</div>
		</div>
	);
}
