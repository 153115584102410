/** @format */

import React, { useState } from 'react';

import { Form, Modal, Checkbox, Row, Col, Switch, Select, Button } from 'antd';

import { setGlobal } from 'reactn';

import InputPhone from '../../../../../../../components/Commons/InputCountry/Phone';
import InputField from '../../../../../../../components/Commons/Input';
import InputRut from '../../../../../../../components/Commons/InputCountry/Rut';
import InputMask from '../../../../../../../components/Commons/InputMask';
import { rulesValidationMask } from '../../../../../../../components/Commons/InputMask/rules';

import '../style.scss';

const { Option } = Select;

export default function BillModal(props) {
	const [form] = Form.useForm();
	const [deparment, setDeparment] = useState('');

	const handleFillInput = (item) => {
		form.setFieldsValue({
			first_name_bill: item ? props.personalData.firstName : '',
			last_name_bill: item ? props.personalData.lastName : '',
			email_bill: item ? props.personalData.email : '',
			phone_bill: item ? props.personalData.phone : '',
			sex_bill: item ? props.personalData.sex : '',
			rut_bill: item ? props.personalData.rut : '',
			curp_bill: item ? props.personalData.curp : '',
			type_house_bill: item ? props.personalData.type_house : '',
			number_department_bill: item ? props.personalData.number_department : '',
			cologne_bill: item ? props.personalData.cologne : '',
			city_bill: item ? props.personalData.city : '',
			province_bill: item ? props.personalData.province : '',
			postal_code_bill: item ? props.personalData.postal_code : '',
			street_name_bill: item ? props.personalData.street : '',
			street_number_bill: item ? props.personalData.street_number : '',
			municipality_bill: item ? props.personalData.comune : '',
			between_street_bill: item ? props.personalData.between_street : '',
		});
		setDeparment(props.personalData.type_house);
	};
	const handleBillingForm = (item) => {
		setGlobal({ mexForm: item });
		props.setBilling((previousState) => ({
			...previousState,
			modal: false,
		}));
	};

	return (
		<div>
			<Checkbox
				checked={props.billing.check}
				onChange={(checked) =>
					props.setBilling({
						modal: checked.target.checked,
						check: checked.target.checked,
					})
				}
			>
				<h4 className="ph-retirement-personal-data-facturation"> Facturación </h4>
			</Checkbox>
			<Modal
				title={
					<Row>
						<Col span={12}>
							{' '}
							<h1 className="ph-retirement-personal-data-facturation-title"> Datos de facturación </h1>
						</Col>
					</Row>
				}
				width={900}
				centered
				visible={props.billing.modal}
				wrapClassName="ph-retirement-personal-data-facturation-modal-container"
				onCancel={() =>
					props.setBilling({
						check: false,
						modal: false,
					})
				}
				footer={[
					<Button key="submit" className="ph-retirement-personal-data-facturation-btn" onClick={form.submit}>
						Aceptar
					</Button>,
				]}
			>
				<Row>
					<Col span={24} className="ph-retirement-personal-data-facturation-subtitle-container">
						<span className="ph-retirement-personal-data-facturation-subtitle">Usar los mismo datos rellenados</span>
						<Switch onChange={handleFillInput} />
					</Col>
				</Row>
				<Form form={form} name="mexican_billing" onFinish={handleBillingForm}>
					<Row>
						<Col xs={24} sm={24} md={12} lg={8} xl={8} className="ph-retirement-personal-data-form-container">
							<InputField
								className={'ph-retirement-personal-data-field-input'}
								inputName={'first_name_bill'}
								inputNameLabel={'Nombre'}
								inputNameRule={true}
								inputNameMessage={'Ingresa tu nombre'}
								inputNameType={'text'}
								inputNameRules={'rulesFirstName'}
							/>
						</Col>
						<Col xs={24} sm={24} md={12} lg={8} xl={8} className="ph-retirement-personal-data-form-container">
							<InputField
								className={'ph-retirement-personal-data-field-input'}
								inputName={'last_name_bill'}
								inputNameLabel={'Apellido'}
								inputNameRule={true}
								inputNameMessage={'Ingresa tu apellido'}
								inputNameType={'text'}
								inputNameRules={'rulesLastName'}
							/>
						</Col>
						<Col xs={24} sm={24} md={12} lg={8} xl={8} className="ph-retirement-personal-data-form-container">
							<InputField
								className={'ph-retirement-personal-data-field-input'}
								inputName={'email_bill'}
								inputNameLabel={'Correo electrónico'}
								inputNameRule={true}
								inputNameMessage={'E-mail es obligatorio'}
								inputNameType={'text'}
								inputNameRules={'rulesEmail'}
							/>
						</Col>
						<Col xs={24} sm={24} md={12} lg={8} xl={8} className="ph-retirement-personal-data-form-container">
							<InputPhone inputPhone={'phone_bill'} classInput={'ph-retirement-personal-data-field-input'} />
						</Col>
						<Col xs={24} sm={24} md={12} lg={8} xl={8} className="ph-retirement-personal-data-form-container">
							<Form.Item
								name="sex_bill"
								rules={[
									{
										required: true,
										message: 'Introduzca su Género',
									},
								]}
							>
								<Select placeholder="Género" className="ph-retirement-personal-data-field-selector" size="large" style={{ width: '100%' }}>
									<Option value="Masculino">Masculino</Option>
									<Option value="Femenino">Femenino</Option>
								</Select>
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={12} lg={8} xl={8} className="ph-retirement-personal-data-form-container">
							<InputRut inputRUT="rut_bill" classInput={'ph-retirement-personal-data-field-input'} />
						</Col>
						<Col xs={24} sm={24} md={12} lg={8} xl={8} className="ph-retirement-personal-data-form-container">
							<Form.Item name="curp_bill" rules={rulesValidationMask.rulesCURP}>
								<InputMask className={'ph-retirement-personal-data-field-input'} mask="aaaa999999aaaaaa*9" placeholder="CURP" />
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={12} lg={8} xl={8} className="ph-retirement-personal-delivery-form-container">
							<InputField
								className={'ph-retirement-personal-delivery-field-input'}
								inputName={'street_name_bill'}
								inputNameLabel={'Calle'}
								inputNameRule={true}
								inputNameMessage={'Ingresa la calle'}
								inputNameType={'text'}
								inputNameRules={'rulesRequired'}
							/>
						</Col>
						<Col xs={24} sm={24} md={12} lg={8} xl={8} className="ph-retirement-personal-data-form-container">
							<InputField
								className={'ph-retirement-personal-delivery-field-input'}
								inputName={'street_number_bill'}
								inputNameLabel={'No. de calle'}
								inputNameRule={true}
								inputNameMessage={'Ingrese su No. de calle'}
								inputNameType={'text'}
								inputNameRules={'rulesRequired'}
							/>
						</Col>
						<Col xs={24} sm={24} md={12} lg={8} xl={8} className="ph-retirement-personal-data-form-container">
							<InputField
								className={'ph-retirement-personal-delivery-field-input'}
								inputName={'between_street_bill'}
								inputNameLabel={'Calle referencial'}
								inputNameRule={true}
								inputNameMessage={'Ingrese la calle referencial'}
								inputNameType={'text'}
							/>
						</Col>
						<Col xs={24} sm={24} md={12} lg={8} xl={8} className="ph-retirement-personal-delivery-form-container">
							<Form.Item
								name="type_house_bill"
								rules={[
									{
										required: true,
										message: 'Tipo de vivienda obligatorio',
									},
								]}
							>
								<Select placeholder="Tipo de vivienda" className="ph-retirement-personal-delivery-selector" size="large" style={{ width: '100%' }} onChange={(item) => setDeparment(item)}>
									<Option value="casa">Casa</Option>
									<Option value="departamento">Departamento</Option>
								</Select>
							</Form.Item>
						</Col>
						{deparment === 'departamento' && (
							<Col xs={24} sm={24} md={12} lg={8} xl={8} className="ph-retirement-personal-data-form-container">
								<InputField
									className={'ph-retirement-personal-delivery-field-input'}
									inputName={'number_department_bill'}
									inputNameLabel={'No. Departamento'}
									inputNameRule={true}
									inputNameMessage={'Ingresa el No. de su departamento'}
									inputNameType={'text'}
									inputNameRules={'rulesRequired'}
								/>
							</Col>
						)}
						<Col xs={24} sm={24} md={12} lg={8} xl={8} className="ph-retirement-personal-data-form-container">
							<InputField
								className={'ph-retirement-personal-data-field-input'}
								inputName={'cologne_bill'}
								inputNameLabel={'Colonia'}
								inputNameRule={true}
								inputNameMessage={'Colonia obligatorio'}
								inputNameType={'text'}
								inputNameRules={'rulesRequired'}
							/>
						</Col>
						<Col xs={24} sm={24} md={12} lg={8} xl={8} className="ph-retirement-personal-data-form-container">
							<InputField
								className={'ph-retirement-personal-data-field-input'}
								inputName={'city_bill'}
								inputNameLabel={'Ciudad'}
								inputNameRule={true}
								inputNameMessage={'Ciudad obligatorio'}
								inputNameType={'text'}
								inputNameRules={'rulesRequired'}
							/>
						</Col>
						<Col xs={24} sm={24} md={12} lg={8} xl={8} className="ph-retirement-personal-data-form-container">
							<InputField
								className={'ph-retirement-personal-data-field-input'}
								inputName={'municipality_bill'}
								inputNameLabel={'Municipio'}
								inputNameRule={true}
								inputNameMessage={'Municipio obligatorio'}
								inputNameType={'text'}
								inputNameRules={'rulesRequired'}
							/>
						</Col>
						<Col xs={24} sm={24} md={12} lg={8} xl={8} className="ph-retirement-personal-data-form-container">
							<InputField
								className={'ph-retirement-personal-data-field-input'}
								inputName={'province_bill'}
								inputNameLabel={'Provincia'}
								inputNameRule={true}
								inputNameMessage={'Provincia obligatoria'}
								inputNameType={'text'}
								inputNameRules={'rulesRequired'}
							/>
						</Col>
						<Col xs={24} sm={24} md={12} lg={8} xl={8} className="ph-retirement-personal-data-form-container">
							<InputField
								className={'ph-retirement-personal-data-field-input'}
								inputName={'postal_code_bill'}
								inputNameLabel={'Código Postal'}
								inputNameRule={true}
								inputNameMessage={'Código Postal obligatorio'}
								inputNameType={'text'}
								inputNameRules={'rulesRequired'}
							/>
						</Col>
						<Col xs={24} sm={24} md={12} lg={8} xl={8} className="ph-retirement-personal-data-form-container">
							<InputField
								className={'ph-retirement-personal-data-field-input'}
								inputName={'country_bill'}
								inputNameLabel={'País'}
								inputNameRule={true}
								inputNameMessage={'País obligatorio'}
								inputNameType={'text'}
								inputNameRules={'rulesRequired'}
							/>
						</Col>
						<Col xs={24} sm={24} md={12} lg={8} xl={8} className="ph-retirement-personal-data-form-container">
							<InputField className={'ph-retirement-personal-delivery-field-input'} inputName={'reference_bill'} inputNameLabel={'Referencia'} inputNameRule={false} inputNameType={'text'} />
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-retirement-personal-data-form-container">
							<Form.Item name="cfdi_bill">
								<Select placeholder="Seleccione CFDI" className="ph-retirement-personal-delivery-selector" size="large" style={{ width: '100%' }}>
									<Option value="D01">HONORARIOS MEDICOS, DENTALES Y GASTOS HOSPITALARIOS</Option>
									<Option value="D02">GASTOS MEDICOS POR INCAPACIDAD O DISCAPACIDAD</Option>
									<Option value="D03">GASTOS FUNERALES</Option>
									<Option value="D04">DONATIVOS</Option>
									<Option value="D05">INTERESES REALES EFECTIVAMENTE PAGADOS POR CREDITOS HIPOTECARIOS (CASA HABITACION)</Option>
									<Option value="D06">APORTACIONES VOLUNTARIAS AL SAR</Option>
									<Option value="D07">PRIMAS POR SEGUROS DE GASTOS MEDICOS</Option>
									<Option value="D08">GASTOS DE TRANSPORTACION ESCOLAR OBLIGATORIA</Option>
									<Option value="D09">DEPOSITOS EN CUENTAS PARA EL AHORRO, PRIMAS QUE TENGAN COMO BASE PLANES DE PENSIONES</Option>
									<Option value="D10">PAGOS POR SERVICIOS EDUCATIVOS (COLEGIATURAS)</Option>
									<Option value="G01">ADQUISICION DE MERCANCIAS</Option>
									<Option value="G02">DEVOLUCIONES, DESCUENTOS O BONIFICACIONES</Option>
									<Option value="G03">GASTOS EN GENERAL</Option>
									<Option value="I01">CONSTRUCCIONES</Option>
									<Option value="I02">MOBILARIO Y EQUIPO DE OFICINA POR INVERSIONES</Option>
									<Option value="I03">EQUIPO DE TRANSPORTE</Option>
									<Option value="I04">EQUIPO DE COMPUTO Y ACCESORIOS</Option>
									<Option value="I05">DADOS, TROQUELES, MOLDES, MATRICES Y HERRAMENTAL</Option>
									<Option value="I06">COMUNICACIONES TELEFONICAS</Option>
									<Option value="I07">COMUNICACIONES SATELITALES</Option>
									<Option value="I08">OTRA MAQUINARIA Y EQUIPO</Option>
									<Option value="P01">POR DEFINIR</Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		</div>
	);
}
