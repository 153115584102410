import { AxiosAdapter } from '../../_config/v2/adapters/axios/axios.adapter';
import { Http } from '../../_config/v2/adapters/axios/axios.protocols';
import { Enviroment } from '../../_config/constants/enviroment-v2.constant';

export class Service {
	async getPharmacies() {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${Enviroment.API_PHARMACY}/config-footer/${Enviroment.COUNTRY}/${Enviroment.ROOT_PHARMACY}`,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}
}
