/** @format */

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import MainTitlePageAlt from '../../../../components/Commons/MainTitlePageAlt';
import Spacer from '../../../../components/Commons/Spacer';
import MetaDescription from '../../../../components/Commons/MetaDescription';

import { default as locationIcon } from '../../../../components/Commons/Assets/Icons/Filled/Enabled/location.svg';
import { default as locationIconGreen } from '../../../../components/Commons/Assets/Icons/Filled/Enabled/Argentina/localizacion-green.png';
import { default as locationOpacityIcon } from '../../../../components/Commons/Assets/Icons/Filled/EnabledOpacity/location.svg';

import AddressList from './components/AddressList';
import ModalAddAddress from './components/ModalAddAddress';

import servicesAddress from './services';
import './style.scss';
import { ENV_COUNTRY } from '../../../../components/Commons/Hooks/Variables/Enviroment';
import CustomButton from '../../../../v2-components/Antd/Button';

class Address extends Component {
	state = {
		email: localStorage.getItem('userEmail'),
		list: null,
		listGoogleMaps: [],
		addressGoogle: null,
		addModals: false,
		alias: null,
		newAddress: true,
	};

	componentDidMount() {
		servicesAddress.getUserAddress(this.state.email).then((response) => {
			this.setState({ list: response });
		});
	}

	handleAddAddressModal = (item) => {
		this.setState({ addModals: item });
	};

	render() {
		const { t } = this.props;
		return (
			<>
				<MetaDescription
					title={'Red de Farmacias Online | Direcciones - Usuario'}
					name={'description'}
					content={'Red de Farmacias Online | Busca y compra tus remedios o medicamentos a precios bajos con despacho a domicilio o retiro express...'}
				/>
				<div className="ph-profile-address-global-container">
					<div className="ph-profile-address-main-container">
						<div className="ph-profile-address-inner-container">
							<MainTitlePageAlt
								title={'Direcciones'}
								descriptionProfile={t('profile.backToAcountLink')}
								image={ENV_COUNTRY === 'AR' ? locationIconGreen : locationIcon}
							/>
							<Spacer />
							<CustomButton onClick={() => this.handleAddAddressModal(true)} className={'button-primary'}>
								<h3 className="ph-profile-address-resend-email-button-title">Agregar</h3>
							</CustomButton>
						</div>
						<div className="ph-profile-address-container">
							{this.state.list ? (
								<AddressList list={this.state.list} email={this.state.email} locationOpacityIcon={locationOpacityIcon} />
							) : (
								<div className="ph-profile-address-spin-container">
									<Spin indicator={<LoadingOutlined />} />
								</div>
							)}
							<ModalAddAddress
								locationIcon={locationIcon}
								list={this.state.list}
								email={this.state.email}
								visible={this.state.addModals}
								addAddressModal={this.handleAddAddressModal}
							/>
						</div>
					</div>
				</div>
			</>
		);
	}
}
export default withTranslation('global')(Address);
