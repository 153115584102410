/** @format */

import axios from 'axios';

import Notify from '../../../../../components/Commons/Notify';

import { ENV_PUNTO_PAGO, ENV_CHECKOUT, ENV_ROOT_PHARMACY } from '../../../../../components/Commons/Hooks/Variables/Enviroment';

const servicesSuccess = {
	async Inject(item) {
		let returnResponse;
		await axios({
			method: 'GET',
			url: `${ENV_PUNTO_PAGO}/inject-token/${item}/${ENV_ROOT_PHARMACY}`,
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					returnResponse = response.data;
				} else {
					Notify({
						title: `Error`,
						colorTitle: 'ph-main-notification-error-title',
						description: `Problemas con la reinjección de la orden: Success Transaction - INJECT`,
						time: 15,
						image: 'error',
						placement: 'topRight',
					});
				}
			})
			.catch(() => {
				Notify({
					title: `Error`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Ocurrio un Error, por favor vuelva a intentarlo.`,
					time: 15,
					image: 'error',
					placement: 'topRight',
				});
			});
		return returnResponse;
	},
	async getPurchase(item) {
		let returnResponse;
		await axios({
			method: 'GET',
			url: `${ENV_CHECKOUT}/get-purchase/${item}`,
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					returnResponse = response.data.data;
				} else {
					Notify({
						title: `Error`,
						colorTitle: 'ph-main-notification-error-title',
						description: `Problemas con la consulta: getOrder`,
						time: 10,
						image: 'error',
						placement: 'topRight',
					});
				}
			})
			.catch(() => {
				Notify({
					title: `Problemas de Conexión`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Revisa tu conexión o ponte en contacto con nosotros.`,
					time: 10,
					image: 'error',
					placement: 'topRight',
				});
			});
		return returnResponse;
	},
	async getFpayPayment() {
		let returnResponse;
		await axios({
			method: 'GET',
			url: localStorage.getItem('fpayUrl'),
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
				} else {
					Notify({
						title: `Error`,
						colorTitle: 'ph-main-notification-error-title',
						description: `Problemas con la consulta: getFpayPayment`,
						time: 10,
						image: 'error',
						placement: 'topRight',
					});
				}
			})
			.catch(() => {
				Notify({
					title: `Problemas de Conexión`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Revisa tu conexión o ponte en contacto con nosotros.`,
					time: 10,
					image: 'error',
					placement: 'topRight',
				});
			});
		return returnResponse;
	},
};
export default servicesSuccess;
