import { AxiosAdapter } from '../../../_config/v2/adapters/axios/axios.adapter';
import { Http } from '../../../_config/v2/adapters/axios/axios.protocols';
import { Enviroment } from '../../../_config/constants/enviroment-v2.constant';

export class Service {
	async getProducts(type, lat, lng, tag, page) {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${Enviroment.FUNCTION_FRONT_COMMON}/landing-products/${Enviroment.COUNTRY}`,
			params: { lat, lng, type, tag, page },
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}
	async getTypes() {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${Enviroment.FUNCTION_FRONT_COMMON}/landing-products/get-types/${Enviroment.COUNTRY}`,
			params: { type: 'offers' },
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}

	getMix() {
		return [
			{
				interface_pbm: [],
				id_pharol: 102901,
				prescryption_type: 'NP',
				available: 523,
				country: 'AR',
				despatch: 'mix',
				original_image: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/102901.jpg',
				image: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/102901.jpg',
				image_120: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/102901.jpg',
				image_320: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/102901.jpg',
				prescription_type: 'NP',
				on_demand: false,
				objectID: 'AR-102901',
				brand: 0,
				bioequivalent: '0',
				category: '3',
				master_category: 'Ofertas',
				sub_category: 'Facial',
				slug: 'agua-micelar-nivea-rose-care-400-ml',
				limit_stock: 10,
				price_per_quantity: 2927,
				min_price: 2927,
				stock_category: 'c3',
				sort_stock: true,
				interface: {
					promotions: {
						id_promotion: '215',
						title: 'Descuento',
						date_start: '01/10/2023',
						date_end: '31/10/2023',
						price_promotion: null,
						description_promotion: null,
						percentaje_promotion: '-20%',
						main_img_promotion: {
							webp: 'https://storage.googleapis.com/master-image-bucket/react-promotion/sale-icon-orange.webp',
							jp2: 'https://storage.googleapis.com/master-image-bucket/react-promotion/sale-icon-orange.jp2',
							jxr: 'https://storage.googleapis.com/master-image-bucket/react-promotion/sale-icon-orange.jxr',
							default: 'https://storage.googleapis.com/master-image-bucket/react-promotion/sale-icon-orange.png',
						},
						img_promotion: {
							webp: 'https://storage.googleapis.com/master-image-bucket/react-promotion/promotion-icon.webp',
							jp2: 'https://storage.googleapis.com/master-image-bucket/react-promotion/promotion-icon.jp2',
							jxr: 'https://storage.googleapis.com/master-image-bucket/react-promotion/promotion-icon.jxr',
							default: 'https://storage.googleapis.com/master-image-bucket/react-promotion/promotion-icon.png',
						},
					},
					on_demand: null,
					cooled: null,
					bioequivalent: null,
				},
				filter_promotion: 'Con Promoción',
				promotions: {
					id_promotion: '215',
					pharmacy: 'botanico',
					local: '1',
					rules: {
						mecanic: 'X % Descuento',
						description: 'Porcentaje de Descuento',
						quantity_benefic: 1,
						quantity_per_requeriment: 1,
						benefit: '20.00',
						tag: '20% descuento',
						icon: {
							type: '% descuento',
							inside_icon: '-20%',
							outside_icon: '',
							image: 'https://storage.googleapis.com/master-image-bucket/react-promotion/sale-icon-orange.png',
							text_promotion_apply: 'El descuento se verá reflejado antes de pagar',
							color_promotion: '#be7ab2',
							gp: false,
							title: 'Descuento',
							percentaje_promotion: '-20%',
							main_img_promotion: {
								webp: 'https://storage.googleapis.com/master-image-bucket/react-promotion/sale-icon-orange.webp',
								jp2: 'https://storage.googleapis.com/master-image-bucket/react-promotion/sale-icon-orange.jp2',
								jxr: 'https://storage.googleapis.com/master-image-bucket/react-promotion/sale-icon-orange.jxr',
								default: 'https://storage.googleapis.com/master-image-bucket/react-promotion/sale-icon-orange.png',
							},
						},
					},
					image: 'https://storage.googleapis.com/master-image-bucket/Iconos/Iconos%20Banners%20Compra-42%20(1).png',
					date_start: '01/10/2023',
					date_end: '31/10/2023',
					status: true,
					day_benefit: false,
					day_description: '',
				},
				car: '0',
				clear_concentration: null,
				code_isp: null,
				concentration: '',
				indication: null,
				liquid: '0',
				validate_recipe: 'FALSE',
				ean13: '4005900773821',
				laboratory: 'Nivea',
				name: 'Agua Micelar Nivea Rose Care 400 ml',
				meta_descriptions: 'Agua Micelar Nivea Rose Care 400 ml',
				meta_keywords: 'Benvida',
				meta_title: 'Agua Micelar Nivea Rose Care 400 ml',
				unit_of_measurement: null,
				storage_conditions: '0',
				cenabast: null,
				cenabast_image: null,
				motorcycle: '1',
				pregnancy_and_lactation: null,
				cooled: '0',
				principe_active: null,
				editable_name: 'Agua Micelar Nivea Rose Care 400 ml',
				side_effects: null,
				referrer: '0',
				quantity_per_container: '',
				shape_pharmacy: '',
				promotion_rate: 0.8,
				promotion_price: 2599.08,
				price: 3248.85,
				stock: 6,
			},
			{
				commune: [],
				region: [],
				city: [],
				interface_pbm: [],
				id_pharol: 503777,
				prescryption_type: 'NP',
				available: 18626,
				country: 'AR',
				despatch: 'mix',
				original_image: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/503777.jpg',
				image: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/503777.jpg',
				image_120: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/503777.jpg',
				image_320: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/503777.jpg',
				prescription_type: 'NP',
				on_demand: false,
				objectID: 'AR-503777',
				brand: 0,
				bioequivalent: '0',
				category: 'Venta Libre',
				master_category: 'Medicamentos',
				sub_category: 'Antiinflamatorios Antifebriles y Analgesicos',
				slug: 'paracetamol-bayer-500-mg-30-comp',
				limit_stock: 10,
				price_per_quantity: 1436.02,
				min_price: 1436.02,
				stock_category: 'c3',
				sort_stock: true,
				interface: {
					promotions: null,
					on_demand: null,
					prescryption_type: {
						type: 'NP',
						title: 'Venta Directa',
						image: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Venta%20directa.png',
					},
					cooled: null,
					bioequivalent: null,
				},
				filter_promotion: 'Sin Promoción',
				promotions: null,
				car: '0',
				clear_concentration: null,
				code_isp: null,
				concentration: '500mg',
				indication: null,
				liquid: '0',
				validate_recipe: 'FALSE',
				ean13: '7793640992455',
				laboratory: 'BAYER CONSUMER',
				name: 'Paracetamol Bayer 500 mg 30 comp',
				meta_descriptions: 'Paracetamol Bayer 500 mg 30 comp',
				meta_keywords: 'Benvida',
				meta_title: 'Paracetamol Bayer 500 mg 30 comp',
				unit_of_measurement: 'mg',
				storage_conditions: '0',
				cenabast: null,
				cenabast_image: null,
				motorcycle: '1',
				pregnancy_and_lactation: null,
				cooled: '0',
				principe_active: 'paracetamol',
				editable_name: 'Paracetamol Bayer 500 mg 30 comp',
				side_effects: null,
				referrer: '0',
				quantity_per_container: '',
				shape_pharmacy: 'Comprimidos/Pastillas/Tabletas/Grageas',
				__v: 0,
				patient_brochure: {
					country: 'AR',
					product_code: 503777,
					adverse_reactions: null,
					contraindication: null,
					interaction: null,
					precautions: null,
				},
				master_category_image: [
					{
						_id: '652fd6fdaf98870cca97320e',
						name: 'Medicamentos',
						slug: 'medicamentos',
						image: 'https://storage.cloud.google.com/master-image-bucket/mainMenu/img_generica.png',
						ranking: 20,
						meta_title: 'Reservá Medicamentos',
						meta_keywords: '',
						status: true,
						country: 'AR',
						__v: 0,
					},
				],
			},
			{
				interface_pbm: [],
				commune: [],
				region: [],
				city: [],
				id_pharol: 503718,
				prescryption_type: 'NP',
				country: 'AR',
				despatch: 'mix',
				original_image: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/503718.jpg',
				image: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/503718.jpg',
				image_120: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/503718.jpg',
				image_320: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/503718.jpg',
				prescription_type: 'NP',
				on_demand: false,
				objectID: 'mitre_503718',
				brand: 0,
				bioequivalent: '0',
				category: 'Venta Libre',
				master_category: 'Medicamentos',
				sub_category: 'Ver Mas Medicamentos',
				slug: 'redoxitos-pastilllas-masticables-sabor-naranja-25-u',
				limit_stock: 10,
				price_per_quantity: 3302.19,
				stock_category: 'c3',
				sort_stock: true,
				interface: {
					promotions: {
						id_promotion: '109',
						title: 'Descuento',
						date_start: '18/10/2023',
						date_end: '31/10/2023',
						price_promotion: null,
						description_promotion: null,
						percentaje_promotion: '-18%',
						main_img_promotion: {
							webp: 'https://storage.googleapis.com/master-image-bucket/react-promotion/sale-icon-orange.webp',
							jp2: 'https://storage.googleapis.com/master-image-bucket/react-promotion/sale-icon-orange.jp2',
							jxr: 'https://storage.googleapis.com/master-image-bucket/react-promotion/sale-icon-orange.jxr',
							default: 'https://storage.googleapis.com/master-image-bucket/react-promotion/sale-icon-orange.png',
						},
						img_promotion: {
							webp: 'https://storage.googleapis.com/master-image-bucket/react-promotion/promotion-icon.webp',
							jp2: 'https://storage.googleapis.com/master-image-bucket/react-promotion/promotion-icon.jp2',
							jxr: 'https://storage.googleapis.com/master-image-bucket/react-promotion/promotion-icon.jxr',
							default: 'https://storage.googleapis.com/master-image-bucket/react-promotion/promotion-icon.png',
						},
					},
					on_demand: null,
					cooled: null,
					bioequivalent: null,
				},
				filter_promotion: 'Con Promoción',
				promotions: {
					id_promotion: '109',
					pharmacy: 'mitre',
					local: '1',
					rules: {
						mecanic: 'X % Descuento',
						description: 'Porcentaje de Descuento',
						quantity_benefic: 1,
						quantity_per_requeriment: 1,
						benefit: '18.00',
						tag: '18% descuento',
						icon: {
							type: '% descuento',
							inside_icon: '-18%',
							outside_icon: '',
							image: 'https://storage.googleapis.com/master-image-bucket/react-promotion/sale-icon-orange.png',
							text_promotion_apply: 'El descuento se verá reflejado antes de pagar',
							color_promotion: '#be7ab2',
							gp: false,
							title: 'Descuento',
							percentaje_promotion: '-18%',
							main_img_promotion: {
								webp: 'https://storage.googleapis.com/master-image-bucket/react-promotion/sale-icon-orange.webp',
								jp2: 'https://storage.googleapis.com/master-image-bucket/react-promotion/sale-icon-orange.jp2',
								jxr: 'https://storage.googleapis.com/master-image-bucket/react-promotion/sale-icon-orange.jxr',
								default: 'https://storage.googleapis.com/master-image-bucket/react-promotion/sale-icon-orange.png',
							},
						},
					},
					image: 'https://storage.googleapis.com/master-image-bucket/Iconos/Iconos%20Banners%20Compra-42%20(1).png',
					date_start: '18/10/2023',
					date_end: '31/10/2023',
					status: true,
					day_benefit: false,
					day_description: '',
				},
				car: '0',
				clear_concentration: null,
				code_isp: null,
				concentration: 'No Clasificada',
				indication: null,
				liquid: '0',
				validate_recipe: 'FALSE',
				ean13: '7793640000303',
				laboratory: 'BAYER CONSUMER',
				name: 'redoxitos pastilllas masticables sabor naranja 25 u',
				meta_descriptions: 'Redoxitos Pastilllas Masticables Sabor Naranja 25 u',
				meta_keywords: 'Benvida',
				meta_title: 'Redoxitos Pastilllas Masticables Sabor Naranja 25 u',
				unit_of_measurement: 'No Clasificada',
				storage_conditions: '0',
				cenabast: null,
				cenabast_image: null,
				motorcycle: '1',
				pregnancy_and_lactation: null,
				cooled: '0',
				principe_active: 'vit.c',
				editable_name: 'Redoxitos Pastilllas Masticables Sabor Naranja 25 u',
				side_effects: null,
				referrer: '0',
				quantity_per_container: '',
				shape_pharmacy: 'Caramelos',
				marketplace_fee_percentage: 10.89,
				pharmacy: 'mitre',
				pharmacy_image: 'https://storage.googleapis.com/master-image-bucket/Resolucion/Documentos_Benvida_Omax/Logo_omax.jpg',
				name_edited_pharmacy: 'Uomax Mitre',
				stock: 71,
				code_internal: '100100000000049362',
				price: 3302.19,
				promotion_rate: 0.82,
				promotion_price: 2707.8,
				__v: 0,
				patient_brochure: {
					country: 'AR',
					product_code: 503718,
					adverse_reactions: null,
					contraindication: null,
					interaction: null,
					precautions: null,
				},
				master_category_image: [
					{
						_id: '652fd6fdaf98870cca97320e',
						name: 'Medicamentos',
						slug: 'medicamentos',
						image: 'https://storage.cloud.google.com/master-image-bucket/mainMenu/img_generica.png',
						ranking: 20,
						meta_title: 'Reservá Medicamentos',
						meta_keywords: '',
						status: true,
						country: 'AR',
						__v: 0,
					},
				],
				despatch_info: [
					{
						title: 'Retiro',
						description: 'en farmacia',
						image: {
							webp: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/retiro-farmacia.webp',
							default: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/retiro-farmacia.png',
							jp2: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/retiro-farmacia.jp2',
							jxr: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/retiro-farmacia.jxr',
						},
					},
				],
			},
			{
				interface_pbm: [],
				commune: ['Parque Patricios'],
				region: ['Buenos Aires'],
				city: ['CABA'],
				id_pharol: 503748,
				prescryption_type: 'NP',
				country: 'AR',
				despatch: 'mix',
				original_image: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/503748.jpg',
				image: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/503748.jpg',
				image_120: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/503748.jpg',
				image_320: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/503748.jpg',
				prescription_type: 'NP',
				on_demand: false,
				objectID: 'pedriel_503748',
				brand: 0,
				bioequivalent: '0',
				category: 'Venta Libre',
				master_category: 'Medicamentos',
				sub_category: 'Antiinflamatorios Antifebriles y Analgesicos',
				slug: 'actron-400-rapida-accion-20-capsulas-blandas',
				limit_stock: 10,
				price_per_quantity: 1993.17,
				stock_category: 'c3',
				sort_stock: true,
				interface: {
					promotions: null,
					on_demand: null,
					prescryption_type: {
						type: 'NP',
						title: 'Venta Directa',
						image: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Venta%20directa.png',
					},
					cooled: null,
					bioequivalent: null,
				},
				filter_promotion: 'Sin Promoción',
				promotions: null,
				car: '0',
				clear_concentration: null,
				code_isp: null,
				concentration: '400mg',
				indication: null,
				liquid: '0',
				validate_recipe: 'FALSE',
				ean13: '7793640215486',
				laboratory: 'BAYER CONSUMER',
				name: 'actron 400 rápida acción 20 cápsulas blandas',
				meta_descriptions: 'Actron 400 Rápida Acción 20 Cápsulas Blandas',
				meta_keywords: 'Benvida',
				meta_title: 'Actron 400 Rápida Acción 20 Cápsulas Blandas',
				unit_of_measurement: 'mg',
				storage_conditions: '0',
				cenabast: null,
				cenabast_image: null,
				motorcycle: '1',
				pregnancy_and_lactation: null,
				cooled: '0',
				principe_active: 'ibuprofeno',
				editable_name: 'Actron 400 Rápida Acción 20 Cápsulas Blandas',
				side_effects: null,
				referrer: '0',
				quantity_per_container: '',
				shape_pharmacy: 'Cápsulas/Softgels',
				marketplace_fee_percentage: 10.89,
				pharmacy: 'pedriel',
				pharmacy_image: 'https://storage.googleapis.com/master-image-bucket/Resolucion/Documentos_Benvida_prueba/1579013380212.jpg',
				name_edited_pharmacy: 'La Sante - Pedriel',
				stock: 160,
				code_internal: '9930128',
				price: 1993.17,
				promotion_rate: 100,
				promotion_price: 1993.17,
				__v: 0,
				patient_brochure: {
					country: 'AR',
					product_code: 503748,
					adverse_reactions: null,
					contraindication: null,
					interaction: null,
					precautions: null,
				},
				master_category_image: [
					{
						_id: '652fd6fdaf98870cca97320e',
						name: 'Medicamentos',
						slug: 'medicamentos',
						image: 'https://storage.cloud.google.com/master-image-bucket/mainMenu/img_generica.png',
						ranking: 20,
						meta_title: 'Reservá Medicamentos',
						meta_keywords: '',
						status: true,
						country: 'AR',
						__v: 0,
					},
				],
				despatch_info: [
					{
						title: 'Despacho',
						description: 'a domicilio',
						image: {
							webp: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/despacho-domicilio.webp',
							default: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/despacho-domicilio.png',
							jp2: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/despacho-domicilio.jp2',
							jxr: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/despacho-domicilio.jxr',
						},
					},
					{
						title: 'Retiro',
						description: 'en farmacia',
						image: {
							webp: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/retiro-farmacia.webp',
							default: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/retiro-farmacia.png',
							jp2: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/retiro-farmacia.jp2',
							jxr: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/retiro-farmacia.jxr',
						},
					},
				],
			},
			{
				commune: [],
				region: [],
				city: [],
				interface_pbm: [],
				id_pharol: 505275,
				prescryption_type: 'RMRSCS',
				available: 16,
				country: 'AR',
				despatch: 'farmacia',
				original_image: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/505275.jpg',
				image: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/505275.jpg',
				image_120: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/505275.jpg',
				image_320: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/505275.jpg',
				prescription_type: 'RMRSCS',
				on_demand: false,
				objectID: 'AR-505275',
				brand: 0,
				bioequivalent: '0',
				category: 'Venta Bajo Receta Archivada',
				master_category: 'Medicamentos',
				sub_category: 'Ver Mas Medicamentos',
				slug: 'clonax-2-mg-comp-x-50',
				limit_stock: 10,
				price_per_quantity: 3555.72,
				min_price: 3555.72,
				stock_category: 'c3',
				sort_stock: true,
				interface: {
					promotions: null,
					on_demand: null,
					prescryption_type: {
						type: 'RMRSCS',
						title: 'Receta Médica Retenida',
						image: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Receta%20retenido%20sin%20control%20de%20stock.png',
					},
					cooled: null,
					bioequivalent: null,
				},
				filter_promotion: 'Sin Promoción',
				promotions: null,
				car: '0',
				clear_concentration: null,
				code_isp: null,
				concentration: '2mg',
				indication: null,
				liquid: '0',
				validate_recipe: 'TRUE',
				ean13: '7795328054998',
				laboratory: 'BETA',
				name: 'CLONAX 2 mg comp.x 50',
				meta_descriptions: 'CLONAX 2 mg comp.x 50',
				meta_keywords: 'Benvida',
				meta_title: 'CLONAX 2 mg comp.x 50',
				unit_of_measurement: 'mg',
				storage_conditions: '0',
				cenabast: null,
				cenabast_image: null,
				motorcycle: '1',
				pregnancy_and_lactation: null,
				cooled: '0',
				principe_active: 'clonazepam',
				editable_name: 'CLONAX 2 mg comp.x 50',
				side_effects: null,
				referrer: '0',
				quantity_per_container: '',
				shape_pharmacy: 'Comprimidos/Pastillas/Tabletas/Grageas',
				__v: 0,
				patient_brochure: {
					country: 'AR',
					product_code: 505275,
					adverse_reactions: null,
					contraindication: null,
					interaction: null,
					precautions: null,
				},
				master_category_image: [
					{
						_id: '652fd6fdaf98870cca97320e',
						name: 'Medicamentos',
						slug: 'medicamentos',
						image: 'https://storage.cloud.google.com/master-image-bucket/mainMenu/img_generica.png',
						ranking: 20,
						meta_title: 'Reservá Medicamentos',
						meta_keywords: '',
						status: true,
						country: 'AR',
						__v: 0,
					},
				],
			},
			{
				interface_pbm: [],
				commune: ['Parque Patricios'],
				region: ['Buenos Aires'],
				city: ['CABA'],
				id_pharol: 511532,
				prescryption_type: 'RMRSCS',
				country: 'AR',
				despatch: 'farmacia',
				original_image: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/511532.jpg',
				image: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/511532.jpg',
				image_120: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/511532.jpg',
				image_320: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/511532.jpg',
				prescription_type: 'RMRSCS',
				on_demand: false,
				objectID: 'pedriel_511532',
				brand: 0,
				bioequivalent: '0',
				category: 'Venta Bajo Receta Archivada',
				master_category: 'Medicamentos',
				sub_category: 'Ver Mas Medicamentos',
				slug: 'fabamox-duo-1-gr-875-125-mg-comp-rec-x-14',
				limit_stock: 10,
				price_per_quantity: 6289.5,
				stock_category: 'c3',
				sort_stock: true,
				interface: {
					promotions: null,
					on_demand: null,
					prescryption_type: {
						type: 'RMRSCS',
						title: 'Receta Médica Retenida',
						image: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Receta%20retenido%20sin%20control%20de%20stock.png',
					},
					cooled: null,
					bioequivalent: null,
				},
				filter_promotion: 'Sin Promoción',
				promotions: null,
				car: '0',
				clear_concentration: null,
				code_isp: null,
				concentration: '875/125mg',
				indication: null,
				liquid: '0',
				validate_recipe: 'TRUE',
				ean13: '7798032933580',
				laboratory: 'Savant Generic',
				name: 'fabamox duo 1 gr 875/125 mg comp.rec.x 14',
				meta_descriptions: 'FABAMOX DUO 1 GR 875/125 mg comp.rec.x 14',
				meta_keywords: 'Benvida',
				meta_title: 'FABAMOX DUO 1 GR 875/125 mg comp.rec.x 14',
				unit_of_measurement: 'mg',
				storage_conditions: '0',
				cenabast: null,
				cenabast_image: null,
				motorcycle: '1',
				pregnancy_and_lactation: null,
				cooled: '0',
				principe_active: 'amoxicilina+clavulánico.ác.',
				editable_name: 'FABAMOX DUO 1 GR 875/125 mg comp.rec.x 14',
				side_effects: null,
				referrer: '0',
				quantity_per_container: '',
				shape_pharmacy: 'Comprimidos/Pastillas/Tabletas/Grageas',
				marketplace_fee_percentage: 10.89,
				pharmacy: 'pedriel',
				pharmacy_image: 'https://storage.googleapis.com/master-image-bucket/Resolucion/Documentos_Benvida_prueba/1579013380212.jpg',
				name_edited_pharmacy: 'La Sante - Pedriel',
				stock: 40,
				code_internal: '5562135',
				price: 6289.5,
				promotion_rate: 100,
				promotion_price: 6289.5,
				__v: 0,
				patient_brochure: {
					country: 'AR',
					product_code: 511532,
					adverse_reactions: null,
					contraindication: null,
					interaction: null,
					precautions: null,
				},
				master_category_image: [
					{
						_id: '652fd6fdaf98870cca97320e',
						name: 'Medicamentos',
						slug: 'medicamentos',
						image: 'https://storage.cloud.google.com/master-image-bucket/mainMenu/img_generica.png',
						ranking: 20,
						meta_title: 'Reservá Medicamentos',
						meta_keywords: '',
						status: true,
						country: 'AR',
						__v: 0,
					},
				],
				despatch_info: [
					{
						title: 'Retiro',
						description: 'en farmacia',
						image: {
							webp: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/retiro-farmacia.webp',
							default: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/retiro-farmacia.png',
							jp2: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/retiro-farmacia.jp2',
							jxr: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/retiro-farmacia.jxr',
						},
					},
				],
			},
			{
				commune: [],
				region: [],
				city: [],
				interface_pbm: [],
				id_pharol: 511565,
				prescryption_type: 'WP',
				available: 6240,
				country: 'AR',
				despatch: 'mix',
				original_image: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/511565.jpg',
				image: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/511565.jpg',
				image_120: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/511565.jpg',
				image_320: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/511565.jpg',
				prescription_type: 'WP',
				on_demand: false,
				objectID: 'AR-511565',
				brand: 0,
				bioequivalent: '0',
				category: 'Venta Bajo Receta',
				master_category: 'Medicamentos',
				sub_category: 'Antiinflamatorios Antifebriles y Analgesicos',
				slug: 'fabogesic-600-rapida-accion-caps-gelat-blanda-x-10',
				limit_stock: 10,
				price_per_quantity: 2083.94,
				min_price: 2083.94,
				stock_category: 'c3',
				sort_stock: true,
				interface: {
					promotions: null,
					on_demand: null,
					prescryption_type: {
						type: 'WP',
						title: 'Presentación Receta Médica',
						image: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Receta%20me%CC%81dica.png',
					},
					cooled: null,
					bioequivalent: null,
				},
				filter_promotion: 'Sin Promoción',
				promotions: null,
				car: '0',
				clear_concentration: null,
				code_isp: null,
				concentration: '600mg',
				indication: null,
				liquid: '0',
				validate_recipe: 'TRUE',
				ean13: '7798032935126',
				laboratory: 'Savant Consumer',
				name: 'FABOGESIC 600 RAPIDA ACCION caps.gelat.blanda x 10',
				meta_descriptions: 'FABOGESIC 600 RAPIDA ACCION caps.gelat.blanda x 10',
				meta_keywords: 'Benvida',
				meta_title: 'FABOGESIC 600 RAPIDA ACCION caps.gelat.blanda x 10',
				unit_of_measurement: 'mg',
				storage_conditions: '0',
				cenabast: null,
				cenabast_image: null,
				motorcycle: '1',
				pregnancy_and_lactation: null,
				cooled: '0',
				principe_active: 'ibuprofeno',
				editable_name: 'FABOGESIC 600 RAPIDA ACCION caps.gelat.blanda x 10',
				side_effects: null,
				referrer: '0',
				quantity_per_container: '',
				shape_pharmacy: 'Cápsulas/Softgels',
				__v: 0,
				patient_brochure: {
					country: 'AR',
					product_code: 511565,
					adverse_reactions: null,
					contraindication: null,
					interaction: null,
					precautions: null,
				},
				master_category_image: [
					{
						_id: '652fd6fdaf98870cca97320e',
						name: 'Medicamentos',
						slug: 'medicamentos',
						image: 'https://storage.cloud.google.com/master-image-bucket/mainMenu/img_generica.png',
						ranking: 20,
						meta_title: 'Reservá Medicamentos',
						meta_keywords: '',
						status: true,
						country: 'AR',
						__v: 0,
					},
				],
			},
			{
				interface_pbm: [],
				commune: [],
				region: [],
				city: [],
				id_pharol: 511565,
				prescryption_type: 'WP',
				country: 'AR',
				despatch: 'mix',
				original_image: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/511565.jpg',
				image: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/511565.jpg',
				image_120: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/511565.jpg',
				image_320: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/511565.jpg',
				prescription_type: 'WP',
				on_demand: false,
				objectID: 'bikaner_511565',
				brand: 0,
				bioequivalent: '0',
				category: 'Venta Bajo Receta',
				master_category: 'Medicamentos',
				sub_category: 'Antiinflamatorios Antifebriles y Analgesicos',
				slug: 'fabogesic-600-rapida-accion-caps-gelat-blanda-x-10',
				limit_stock: 10,
				price_per_quantity: 2083.94,
				stock_category: 'c3',
				sort_stock: true,
				interface: {
					promotions: null,
					on_demand: null,
					prescryption_type: {
						type: 'WP',
						title: 'Presentación Receta Médica',
						image: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Receta%20me%CC%81dica.png',
					},
					cooled: null,
					bioequivalent: null,
				},
				filter_promotion: 'Sin Promoción',
				promotions: null,
				car: '0',
				clear_concentration: null,
				code_isp: null,
				concentration: '600mg',
				indication: null,
				liquid: '0',
				validate_recipe: 'TRUE',
				ean13: '7798032935126',
				laboratory: 'Savant Consumer',
				name: 'fabogesic 600 rapida accion caps.gelat.blanda x 10',
				meta_descriptions: 'FABOGESIC 600 RAPIDA ACCION caps.gelat.blanda x 10',
				meta_keywords: 'Benvida',
				meta_title: 'FABOGESIC 600 RAPIDA ACCION caps.gelat.blanda x 10',
				unit_of_measurement: 'mg',
				storage_conditions: '0',
				cenabast: null,
				cenabast_image: null,
				motorcycle: '1',
				pregnancy_and_lactation: null,
				cooled: '0',
				principe_active: 'ibuprofeno',
				editable_name: 'FABOGESIC 600 RAPIDA ACCION caps.gelat.blanda x 10',
				side_effects: null,
				referrer: '0',
				quantity_per_container: '',
				shape_pharmacy: 'Cápsulas/Softgels',
				marketplace_fee_percentage: 10.89,
				pharmacy: 'bikaner',
				pharmacy_image: 'https://storage.googleapis.com/master-image-bucket/Resolucion/Documentos_Openfarma/openfarma.jpg',
				name_edited_pharmacy: 'Openfarma Bikaner',
				stock: 113,
				code_internal: '6165422',
				price: 2083.94,
				promotion_rate: 100,
				promotion_price: 2083.94,
				__v: 0,
				patient_brochure: {
					country: 'AR',
					product_code: 511565,
					adverse_reactions: null,
					contraindication: null,
					interaction: null,
					precautions: null,
				},
				master_category_image: [
					{
						_id: '652fd6fdaf98870cca97320e',
						name: 'Medicamentos',
						slug: 'medicamentos',
						image: 'https://storage.cloud.google.com/master-image-bucket/mainMenu/img_generica.png',
						ranking: 20,
						meta_title: 'Reservá Medicamentos',
						meta_keywords: '',
						status: true,
						country: 'AR',
						__v: 0,
					},
				],
				despatch_info: [
					{
						title: 'Retiro',
						description: 'en farmacia',
						image: {
							webp: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/retiro-farmacia.webp',
							default: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/retiro-farmacia.png',
							jp2: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/retiro-farmacia.jp2',
							jxr: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/retiro-farmacia.jxr',
						},
					},
				],
			},
			{
				commune: [],
				region: [],
				city: [],
				interface_pbm: [],
				id_pharol: 127545,
				prescryption_type: 'NP',
				available: 451,
				country: 'AR',
				despatch: 'mix',
				original_image: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/127545.jpg',
				image: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/127545.jpg',
				image_120: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/127545.jpg',
				image_320: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/127545.jpg',
				prescription_type: 'NP',
				on_demand: false,
				objectID: 'AR-127545',
				brand: 0,
				bioequivalent: '0',
				category: 'Fragancias de Mujeres',
				master_category: 'Fragancias',
				sub_category: 'Ver Todo Fragancias de Mujeres',
				slug: 'eau-de-parfum-carolina-herrera-very-good-girl-glam-80-ml',
				limit_stock: 10,
				price_per_quantity: 88500,
				min_price: 88500,
				stock_category: 'c3',
				sort_stock: true,
				interface: {
					promotions: null,
					on_demand: null,
					prescryption_type: {
						type: 'NP',
						title: 'Venta Directa',
						image: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Venta%20directa.png',
					},
					cooled: null,
					bioequivalent: null,
				},
				filter_promotion: 'Sin Promoción',
				promotions: null,
				car: '0',
				clear_concentration: null,
				code_isp: null,
				concentration: '',
				indication: null,
				liquid: '0',
				validate_recipe: 'FALSE',
				ean13: '8411061058459',
				laboratory: 'Carolina Herrera',
				name: 'Eau De Parfum Carolina Herrera Very Good Girl Glam 80 ml',
				meta_descriptions: 'Eau De Parfum Carolina Herrera Very Good Girl Glam 80 ml',
				meta_keywords: 'Benvida',
				meta_title: 'Eau De Parfum Carolina Herrera Very Good Girl Glam 80 ml',
				unit_of_measurement: null,
				storage_conditions: '0',
				cenabast: null,
				cenabast_image: null,
				motorcycle: '1',
				pregnancy_and_lactation: null,
				cooled: '0',
				principe_active: null,
				editable_name: 'Eau De Parfum Carolina Herrera Very Good Girl Glam 80 ml',
				side_effects: null,
				referrer: '0',
				quantity_per_container: '',
				shape_pharmacy: '',
				__v: 0,
				patient_brochure: {
					country: 'AR',
					product_code: 127545,
					adverse_reactions: null,
					contraindication: null,
					interaction: null,
					precautions: null,
				},
				master_category_image: [
					{
						_id: '652fd6fdaf988759b69731ff',
						name: 'Fragancias',
						slug: 'fragancias',
						image: 'https://storage.cloud.google.com/master-image-bucket/mainMenu/img_generica.png',
						ranking: 24,
						meta_title: 'Fragancias',
						meta_keywords: '',
						status: true,
						country: 'AR',
						__v: 0,
					},
				],
			},
			{
				interface_pbm: [],
				commune: [],
				region: [],
				city: [],
				id_pharol: 127545,
				prescryption_type: 'NP',
				country: 'AR',
				despatch: 'mix',
				original_image: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/127545.jpg',
				image: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/127545.jpg',
				image_120: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/127545.jpg',
				image_320: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/127545.jpg',
				prescription_type: 'NP',
				on_demand: false,
				objectID: 'boticajulian_127545',
				brand: 0,
				bioequivalent: '0',
				category: 'Fragancias de Mujeres',
				master_category: 'Fragancias',
				sub_category: 'Ver Todo Fragancias de Mujeres',
				slug: 'eau-de-parfum-carolina-herrera-very-good-girl-glam-80-ml',
				limit_stock: 10,
				price_per_quantity: 88500,
				stock_category: 'c3',
				sort_stock: true,
				interface: {
					promotions: null,
					on_demand: null,
					prescryption_type: {
						type: 'NP',
						title: 'Venta Directa',
						image: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Venta%20directa.png',
					},
					cooled: null,
					bioequivalent: null,
				},
				filter_promotion: 'Sin Promoción',
				promotions: null,
				car: '0',
				clear_concentration: null,
				code_isp: null,
				concentration: '',
				indication: null,
				liquid: '0',
				validate_recipe: 'FALSE',
				ean13: '8411061058459',
				laboratory: 'Carolina Herrera',
				name: 'eau de parfum carolina herrera very good girl glam 80 ml',
				meta_descriptions: 'Eau De Parfum Carolina Herrera Very Good Girl Glam 80 ml',
				meta_keywords: 'Benvida',
				meta_title: 'Eau De Parfum Carolina Herrera Very Good Girl Glam 80 ml',
				unit_of_measurement: null,
				storage_conditions: '0',
				cenabast: null,
				cenabast_image: null,
				motorcycle: '1',
				pregnancy_and_lactation: null,
				cooled: '0',
				principe_active: null,
				editable_name: 'Eau De Parfum Carolina Herrera Very Good Girl Glam 80 ml',
				side_effects: null,
				referrer: '0',
				quantity_per_container: '',
				shape_pharmacy: '',
				marketplace_fee_percentage: 0,
				pharmacy: 'boticajulian',
				pharmacy_image: 'https://storage.googleapis.com/master-image-bucket/Resolucion/Documentos_Benvida_RP/RP_Logo.jpg',
				name_edited_pharmacy: 'RP La Botica de Julián',
				stock: 439,
				code_internal: 'J1008314',
				price: 88500,
				promotion_rate: 100,
				promotion_price: 88500,
				__v: 0,
				patient_brochure: {
					country: 'AR',
					product_code: 127545,
					adverse_reactions: null,
					contraindication: null,
					interaction: null,
					precautions: null,
				},
				master_category_image: [
					{
						_id: '652fd6fdaf988759b69731ff',
						name: 'Fragancias',
						slug: 'fragancias',
						image: 'https://storage.cloud.google.com/master-image-bucket/mainMenu/img_generica.png',
						ranking: 24,
						meta_title: 'Fragancias',
						meta_keywords: '',
						status: true,
						country: 'AR',
						__v: 0,
					},
				],
				despatch_info: [
					{
						title: 'Despacho',
						description: 'a domicilio',
						image: {
							webp: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/despacho-domicilio.webp',
							default: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/despacho-domicilio.png',
							jp2: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/despacho-domicilio.jp2',
							jxr: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/despacho-domicilio.jxr',
						},
					},
					{
						title: 'Retiro',
						description: 'en farmacia',
						image: {
							webp: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/retiro-farmacia.webp',
							default: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/retiro-farmacia.png',
							jp2: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/retiro-farmacia.jp2',
							jxr: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/retiro-farmacia.jxr',
						},
					},
				],
			},
			{
				commune: [],
				region: [],
				city: [],
				interface_pbm: [],
				id_pharol: 113376,
				prescryption_type: 'NP',
				available: 466,
				country: 'AR',
				despatch: 'mix',
				original_image: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/113376.jpg',
				image: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/113376.jpg',
				image_120: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/113376.jpg',
				image_320: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/113376.jpg',
				prescription_type: 'NP',
				on_demand: false,
				objectID: 'AR-113376',
				brand: 0,
				bioequivalent: '0',
				category: 'Capilar',
				master_category: 'Dermocosmetica',
				sub_category: 'Shampoo',
				slug: 'shampoo-klorane-quinina-400-ml',
				limit_stock: 10,
				price_per_quantity: 13390,
				min_price: 13390,
				stock_category: 'c3',
				sort_stock: true,
				interface: {
					promotions: null,
					on_demand: null,
					prescryption_type: {
						type: 'NP',
						title: 'Venta Directa',
						image: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Venta%20directa.png',
					},
					cooled: null,
					bioequivalent: null,
				},
				filter_promotion: 'Sin Promoción',
				promotions: null,
				car: '0',
				clear_concentration: null,
				code_isp: null,
				concentration: '',
				indication: null,
				liquid: '0',
				validate_recipe: 'FALSE',
				ean13: '7799075001533',
				laboratory: 'Klorane',
				name: 'Shampoo Klorane Quinina 400 ml',
				meta_descriptions: 'Shampoo Klorane Quinina 400 ml',
				meta_keywords: 'Benvida',
				meta_title: 'Shampoo Klorane Quinina 400 ml',
				unit_of_measurement: null,
				storage_conditions: '0',
				cenabast: null,
				cenabast_image: null,
				motorcycle: '1',
				pregnancy_and_lactation: null,
				cooled: '0',
				principe_active: null,
				editable_name: 'Shampoo Klorane Quinina 400 ml',
				side_effects: null,
				referrer: '0',
				quantity_per_container: '',
				shape_pharmacy: '',
				__v: 0,
				patient_brochure: {
					country: 'AR',
					product_code: 113376,
					adverse_reactions: null,
					contraindication: null,
					interaction: null,
					precautions: null,
				},
				master_category_image: [
					{
						_id: '652fd6fdaf988740e197321f',
						name: 'Dermocosmetica',
						slug: 'dermocosmetica',
						image: 'https://storage.cloud.google.com/master-image-bucket/mainMenu/img_generica.png',
						ranking: 21,
						meta_title: 'Dermocosmética',
						meta_keywords: '',
						status: true,
						country: 'AR',
						__v: 0,
					},
				],
			},
			{
				interface_pbm: [],
				commune: [],
				region: [],
				city: [],
				id_pharol: 113376,
				prescryption_type: 'NP',
				country: 'AR',
				despatch: 'mix',
				original_image: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/113376.jpg',
				image: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/113376.jpg',
				image_120: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/113376.jpg',
				image_320: 'https://storage.googleapis.com/pharol-images-prod/products/AR/original/113376.jpg',
				prescription_type: 'NP',
				on_demand: false,
				objectID: 'portena_113376',
				brand: 0,
				bioequivalent: '0',
				category: 'Capilar',
				master_category: 'Dermocosmetica',
				sub_category: 'Shampoo',
				slug: 'shampoo-klorane-quinina-400-ml',
				limit_stock: 10,
				price_per_quantity: 16068,
				stock_category: 'c3',
				sort_stock: true,
				interface: {
					promotions: {
						id_promotion: '50',
						title: 'Descuento',
						date_start: '01/10/2023',
						date_end: '31/10/2023',
						price_promotion: null,
						description_promotion: null,
						percentaje_promotion: '-20%',
						main_img_promotion: {
							webp: 'https://storage.googleapis.com/master-image-bucket/react-promotion/sale-icon-orange.webp',
							jp2: 'https://storage.googleapis.com/master-image-bucket/react-promotion/sale-icon-orange.jp2',
							jxr: 'https://storage.googleapis.com/master-image-bucket/react-promotion/sale-icon-orange.jxr',
							default: 'https://storage.googleapis.com/master-image-bucket/react-promotion/sale-icon-orange.png',
						},
						img_promotion: {
							webp: 'https://storage.googleapis.com/master-image-bucket/react-promotion/promotion-icon.webp',
							jp2: 'https://storage.googleapis.com/master-image-bucket/react-promotion/promotion-icon.jp2',
							jxr: 'https://storage.googleapis.com/master-image-bucket/react-promotion/promotion-icon.jxr',
							default: 'https://storage.googleapis.com/master-image-bucket/react-promotion/promotion-icon.png',
						},
					},
					on_demand: null,
					cooled: null,
					bioequivalent: null,
				},
				filter_promotion: 'Con Promoción',
				promotions: {
					id_promotion: '50',
					pharmacy: 'portena',
					local: '1',
					rules: {
						mecanic: 'X % Descuento',
						description: 'Porcentaje de Descuento',
						quantity_benefic: 1,
						quantity_per_requeriment: 1,
						benefit: '20.00',
						tag: '20% descuento',
						icon: {
							type: '% descuento',
							inside_icon: '-20%',
							outside_icon: '',
							image: 'https://storage.googleapis.com/master-image-bucket/react-promotion/sale-icon-orange.png',
							text_promotion_apply: 'El descuento se verá reflejado antes de pagar',
							color_promotion: '#be7ab2',
							gp: false,
							title: 'Descuento',
							percentaje_promotion: '-20%',
							main_img_promotion: {
								webp: 'https://storage.googleapis.com/master-image-bucket/react-promotion/sale-icon-orange.webp',
								jp2: 'https://storage.googleapis.com/master-image-bucket/react-promotion/sale-icon-orange.jp2',
								jxr: 'https://storage.googleapis.com/master-image-bucket/react-promotion/sale-icon-orange.jxr',
								default: 'https://storage.googleapis.com/master-image-bucket/react-promotion/sale-icon-orange.png',
							},
						},
					},
					image: 'https://storage.googleapis.com/master-image-bucket/Iconos/Iconos%20Banners%20Compra-42%20(1).png',
					date_start: '01/10/2023',
					date_end: '31/10/2023',
					status: true,
					day_benefit: false,
					day_description: '',
				},
				car: '0',
				clear_concentration: null,
				code_isp: null,
				concentration: '',
				indication: null,
				liquid: '0',
				validate_recipe: 'FALSE',
				ean13: '7799075001533',
				laboratory: 'Klorane',
				name: 'shampoo klorane quinina 400 ml',
				meta_descriptions: 'Shampoo Klorane Quinina 400 ml',
				meta_keywords: 'Benvida',
				meta_title: 'Shampoo Klorane Quinina 400 ml',
				unit_of_measurement: null,
				storage_conditions: '0',
				cenabast: null,
				cenabast_image: null,
				motorcycle: '1',
				pregnancy_and_lactation: null,
				cooled: '0',
				principe_active: null,
				editable_name: 'Shampoo Klorane Quinina 400 ml',
				side_effects: null,
				referrer: '0',
				quantity_per_container: '',
				shape_pharmacy: '',
				marketplace_fee_percentage: 0,
				pharmacy: 'portena',
				pharmacy_image: 'https://storage.googleapis.com/master-image-bucket/Resolucion/Documentos_Benvida_RP/RP_Logo.jpg',
				name_edited_pharmacy: 'RP Porteña',
				stock: 21,
				code_internal: '-316875',
				price: 16068,
				promotion_rate: 0.8,
				promotion_price: 12854.4,
				__v: 0,
				patient_brochure: {
					country: 'AR',
					product_code: 113376,
					adverse_reactions: null,
					contraindication: null,
					interaction: null,
					precautions: null,
				},
				master_category_image: [
					{
						_id: '652fd6fdaf988740e197321f',
						name: 'Dermocosmetica',
						slug: 'dermocosmetica',
						image: 'https://storage.cloud.google.com/master-image-bucket/mainMenu/img_generica.png',
						ranking: 21,
						meta_title: 'Dermocosmética',
						meta_keywords: '',
						status: true,
						country: 'AR',
						__v: 0,
					},
				],
				despatch_info: [
					{
						title: 'Despacho',
						description: 'a domicilio',
						image: {
							webp: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/despacho-domicilio.webp',
							default: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/despacho-domicilio.png',
							jp2: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/despacho-domicilio.jp2',
							jxr: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/despacho-domicilio.jxr',
						},
					},
					{
						title: 'Retiro',
						description: 'en farmacia',
						image: {
							webp: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/retiro-farmacia.webp',
							default: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/retiro-farmacia.png',
							jp2: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/retiro-farmacia.jp2',
							jxr: 'https://storage.googleapis.com/master-image-bucket/react-type-delibery/retiro-farmacia.jxr',
						},
					},
				],
			},
		];
	}
}
