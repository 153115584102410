import { Col, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { setGlobal } from 'reactn';
import { GlobalConsumer } from '../../../components/Commons/Context/Global';
import CustomButton from '../../../v2-components/Antd/Button';
import CustomCard from '../../../v2-components/Antd/Card';
import CustomCheckbox from '../../../v2-components/Antd/Checkbox';
import CustomFormItem from '../../../v2-components/Antd/FormItem';
import CustomImage from '../../../v2-components/Antd/Image';
import CustomInput from '../../../v2-components/Antd/Input';
import CustomLink from '../../../v2-components/Antd/Link';
import { CustomNotification } from '../../../v2-components/Antd/Notification';
import LoadingModal from '../../../v2-components/LoadingModal';
import { IMAGES } from '../../../v2-config/constants/images.constant';
import { VALIDATION } from '../../../v2-config/constants/validations.constant';
import { Service } from './service';
import { Enviroment } from '../../../_config/constants/enviroment-v2.constant';
import './style.scss';

export default function RegisterUser() {
	const [form] = Form.useForm();
	const [openLoadingModal, setOpenLoadingModal] = useState(false);
	const { setLoginUser } = GlobalConsumer();
	const history = useHistory();

	const handleFinish = (item) => {
		const user = {
			first_name: item.first_name,
			last_name: item.last_name,
			phone: item.phone,
			email: item.email,
			rut: item.rut,
			password: item.password,
			platform: Enviroment.ROOT_SITE,
			pharmacy: Enviroment.ROOT_PHARMACY,
			country: Enviroment.COUNTRY,
		};
		setOpenLoadingModal(true);
		new Service()
			.register(user)
			.then((response) => {
				console.log('response', response);
				if (response.statusCode !== 201) {
					setOpenLoadingModal(false);
					return CustomNotification({ type: 'error', message: null, description: response.message });
				}
				const userMainInfo = { first_name: response.data.user.first_name, last_name: response.data.user.last_name, token: response.data.user.token };
				localStorage.setItem('userEmail', response.data.user.email);
				localStorage.setItem('userMainInfo', JSON.stringify(userMainInfo));
				setGlobal(() => userMainInfo);
				setLoginUser({ email: response.data.user.email });
				setOpenLoadingModal(false);
				form.resetFields();
				history.push('/profile-info');
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		if (localStorage.getItem('userEmail')) history.push('/');
	}, [history]);

	return (
		<Row justify="center" align="middle" className="own-register-container">
			<Col md={10} xs={22}>
				<br />
				<Form form={form} layout="vertical" onFinish={handleFinish}>
					<CustomCard
						className={'card-primary'}
						actions={[
							<CustomLink className={'link-primary'} to="/">
								Volver
							</CustomLink>,
							<CustomButton className={'button-primary'} htmlType="submit">
								REGISTRARSE
							</CustomButton>,
						]}
					>
						<Row justify="center">
							<CustomImage src={IMAGES.LOGO_LIGHT_BACKGROUND} alt="logo" height={42} />
						</Row>
						<Row justify="center">
							<h2>¡Regístrate!</h2>
						</Row>
						<Row justify="center">
							<p>Accede a tu cuenta y a tus beneficios</p>
						</Row>
						<Row justify="center" align="middle">
							<CustomFormItem className={'form-primary'} name={'first_name'} rules={VALIDATION.first_name}>
								<CustomInput className={'input-primary'} placeholder={'Nombre'} />
							</CustomFormItem>
						</Row>
						<Row justify="center" align="middle">
							<CustomFormItem className={'form-primary'} name={'last_name'} rules={VALIDATION.last_name}>
								<CustomInput className={'input-primary'} placeholder={'Apellido'} />
							</CustomFormItem>
						</Row>
						<Row justify="center" align="middle">
							<CustomFormItem className={'form-primary'} name={'rut'} rules={VALIDATION.rut}>
								<CustomInput className={'input-primary'} placeholder={'Rut'} />
							</CustomFormItem>
						</Row>
						<Row justify="center" align="middle">
							<CustomFormItem className={'form-primary'} name={'phone'} rules={VALIDATION.phone_number}>
								<CustomInput className={'input-primary'} placeholder={'Teléfono'} />
							</CustomFormItem>
						</Row>
						<Row justify="center" align="middle">
							<CustomFormItem className={'form-primary'} name={'email'} rules={VALIDATION.email}>
								<CustomInput className={'input-primary'} placeholder={'Correo electrónico'} />
							</CustomFormItem>
						</Row>
						<Row justify="center" align="middle">
							<CustomFormItem className={'form-primary'} name={'repeat_email'} rules={VALIDATION.repeat_email}>
								<CustomInput className={'input-primary'} placeholder={'Repetir correo electrónico'} />
							</CustomFormItem>
						</Row>
						<Row justify="center" align="middle">
							<CustomFormItem className={'form-primary'} name={'password'} rules={VALIDATION.password}>
								<CustomInput className={'input-primary'} type={'password'} placeholder={'Contraseña'} />
							</CustomFormItem>
						</Row>
						<Row justify="center" align="middle">
							<CustomFormItem className={'form-primary'} name={'termsAndConditions'} valuePropName="checked" rules={VALIDATION.termsAndConditions}>
								<CustomCheckbox>
									<Link to="/policies">Acepto los Términos y Condiciones</Link>
								</CustomCheckbox>
							</CustomFormItem>
						</Row>

						<Row justify="center">
							<p>
								¿Ya tienes cuenta? <Link to={'/auth/login'}>Haz click aquí.</Link>
							</p>
						</Row>
					</CustomCard>
				</Form>
				<LoadingModal visible={openLoadingModal} />
			</Col>
		</Row>
	);
}
