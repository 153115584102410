/** @format */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Images from '../../../../../components/Commons/Images';
import CustomButton from '../../../../../v2-components/Antd/Button';
import './style.scss';

export default function Messaje(props) {
	const { t } = useTranslation('global');
	const history = useHistory();

	const handleRedirect = () => {
		history.push('/');
	};
	return (
		<>
			<div className="ph-confirm-global-container">
				<section className="ph-confirm-main-container">
					<div className="ph-confirm-inner-container">
						<Images
							classContent={'ph-confirm-img-container'}
							classImage={'ph-confirm-img'}
							default={'https://storage.googleapis.com/master-image-bucket/404/Iconos%20Banners%20Compra_Mesa%20de%20trabajo%201%20copia%2034%202.jpg'}
						/>
						<h1 className="ph-confirm-succes-title">{props.title}</h1>
						<h4 className="ph-confirm-description">
							{props.description}
							<span className="ph-confirm-description-contact">{t('main.company')}</span>
						</h4>
						<div className="ph-confirm-payment-button-container">
							<CustomButton className={'button-primary'} onClick={handleRedirect}>
								{props.button}
							</CustomButton>
						</div>
					</div>
				</section>
			</div>
		</>
	);
}
