/** @format */

import axios from 'axios';

import Notify from '../../../../components/Commons/Notify';

import { ENV_USER } from '../../../../components/Commons/Hooks/Variables/Enviroment';

var listAdress;

const servicesAddress = {
	async getUserAddress(item) {
		let returnResponse;
		await axios({
			method: 'POST',
			url: `${ENV_USER}/user/find-user`,
			data: {
				email: item,
			},
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					returnResponse = response.data.data.address;
					listAdress = response.data.data.address;
				} else {
					Notify({
						title: `Error: ${response.data.statusCode}`,
						colorTitle: 'ph-main-notification-error-title',
						description: `Problemas con el servicio:   ${response.data.message}`,
						time: 3,
						image: 'error',
						placement: 'topRight',
					});
				}
			})
			.catch((error) => {
				returnResponse = error.response.data;
				Notify({
					title: `Problemas de Conexión`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Revisa tu conexión o ponte en contacto con nosotros.`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			});
		return returnResponse;
	},
	async manageAddress(email, item, type) {
		let returnResponse;
		if (type === 'add') {
			listAdress.push(item);
		}
		await axios({
			method: 'POST',
			url: `${ENV_USER}/user/add-address`,
			data: {
				email: email,
				address: type === 'add' ? listAdress : item,
			},
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					returnResponse = response.data;
					Notify({
						title: `Direcciones`,
						colorTitle: 'ph-main-notification-success-title',
						description: type === 'add' ? 'Su dirección fue creada correctamente.' : 'Su dirección fue borrada correctamente.',
						time: 3,
						image: 'warning',
						placement: 'topRight',
					});
				} else {
					Notify({
						title: `Error: ${response.data.error}`,
						colorTitle: 'ph-main-notification-error-title',
						description: `Problemas con el servicio:   ${response.data.message}`,
						time: 3,
						image: 'error',
						placement: 'topRight',
					});
				}
			})
			.catch((error) => {
				returnResponse = error.response.data;
				Notify({
					title: `Problemas de Conexión`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Revisa tu conexión o ponte en contacto con nosotros.`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			});
		return returnResponse;
	},
};
export default servicesAddress;
