/** @format */

import React from 'react';

import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Images from '../../../Commons/Images';

import './style.scss';
import CountryValidator from '../../../Commons/CountryValidator';
import { Country } from '../../../../_config/constants/image-country.constant';
import CustomButton from '../../../../v2-components/Antd/Button';

export default function Error(props) {
	const { t } = useTranslation('global');
	const history = useHistory();

	const handleRedirect = () => {
		history.push('/');
	};

	return (
		<div className="ph-elastic-result-not-found-main-container">
			<CountryValidator
				countries={['CL']}
				component={
					<Images
						classContent={'ph-elastic-result-not-found-image-container'}
						classImage={'ph-elastic-result-not-found-image'}
						default={`https://storage.googleapis.com/master-image-bucket/404/ICONO-35.png`}
						title={'not found'}
						alt={'not found'}
					/>
				}
			/>

			<CountryValidator
				countries={['AR']}
				component={
					<Images
						classContent={'ph-elastic-result-not-found-image-container'}
						classImage={'ph-elastic-result-not-found-image'}
						default={Country.AR.sinStock}
						title={'not found'}
						alt={'not found'}
					/>
				}
			/>

			<h3 className="ph-elastic-result-not-found-title">
				{t('error.title')}:&nbsp;
				<span className="ph-elastic-result-not-found-title-bold">{props.params.query}</span>
			</h3>
			<CountryValidator
				countries={['CL']}
				component={
					<h4 className="ph-elastic-result-not-found-subtitle">
						Si necesitas ayuda visita nuestra sección de{' '}
						<Link className="ph-elastic-result-not-found-subtitle-bold" to="/contact">
							Contacto
						</Link>
					</h4>
				}
			/>
			<CustomButton onClick={() => handleRedirect} className={'button-primary'}>
				Volver al inicio
			</CustomButton>
		</div>
	);
}
