import React from 'react';
import { Button } from 'antd';
import './style.scss';
export default function CustomButton(props) {
	return (
		<>
			<Button {...props} />
		</>
	);
}
