import { ENV_COUNTRY } from '../../Variables/Enviroment';

export const CheckUserInfo = (userInfo) => {
	const user = JSON.parse(userInfo);
	if (ENV_COUNTRY === 'CL') return true;
	if (ENV_COUNTRY === 'AR') {
		console.log('userInfo', ENV_COUNTRY, user);
		if (user.rut === undefined || user.age === undefined || user.phone === undefined) {
			return false;
		} else {
			return true;
		}
	}
};
