/** @format */

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link, useHistory } from 'react-router-dom';

import { ENV_IMG } from '../../../../components//Commons/Hooks/Variables/Enviroment';
import Images from '../../../../components/Commons/Images';
import MetaDescription from '../../../../components/Commons/MetaDescription';
import CustomButton from '../../../../v2-components/Antd/Button';

import './style.scss';

const RequestPreorderError = () => {
	const { t } = useTranslation('global');
	const history = useHistory();

	const handleRedirect = () => {
		history.push('/');
	};

	return (
		<div className="ph-request-preorder-error-global-container">
			<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			<section className="ph-request-preorder-error-main-container">
				<div className="ph-request-preorder-error-inner-container">
					<Images
						classContent={'ph-request-preorder-error-img-container'}
						classImage={'ph-request-preorder-error-img'}
						webp={`${ENV_IMG}/react-checkout/master-recipe/error.webp`}
						jp2={`${ENV_IMG}/react-checkout/master-recipe/error.jxr`}
						jxr={`${ENV_IMG}/react-checkout/master-recipe/error.jp2`}
						default={`${ENV_IMG}/react-checkout/master-recipe/error.png`}
					/>
					<h1 className="ph-request-preorder-error-succes-title">¡Ha ocurrido un error!</h1>
					<h4 className="ph-request-preorder-error-description-main">Intente nuevamente la operación</h4>
					<h4 className="ph-request-preorder-error-description-span">
						<span className="ph-request-preorder-error-description-span-i">*</span>
						Si el problema persiste, contáctenos por el chat o por
						<Link to="/contact">
							<span className="ph-request-preorder-error-description-contact">contacto@pharol.cl</span>
						</Link>
					</h4>
					<div className="ph-request-preorder-error-payment-button-container">
						<CustomButton className={'button-primary'} onClick={() => handleRedirect}>
							Volver al Inicio
						</CustomButton>
					</div>
				</div>
			</section>
		</div>
	);
};

export default RequestPreorderError;
