/** @format */

import { ServiceBagVoucher } from './services'

import { setGlobal } from 'reactn'

export default function BagVoucher() {
	const handleBagVoucher = async () => {
		let returnResponse
		const urlSearchParams = new URLSearchParams(window.location.search)
		const query = Object.fromEntries(urlSearchParams.entries())

		if (query.voucher) {
			localStorage.setItem('validVoucher', query.voucher)
		}
		let validVoucher = query.voucher ? query.voucher : localStorage.getItem('validVoucher')

		if (validVoucher) {
			const response = await ServiceBagVoucher(validVoucher)
			if (response) {
				let pharmacies = response.pharmacy.split(',')
				let vouchers = {}
				pharmacies.forEach((item) => {
					vouchers = { ...vouchers, [item]: response.code_voucher }
				})
				returnResponse = vouchers
			} else returnResponse = {}
		} else {
			returnResponse = {}
		}
		setGlobal(() => ({ bagVoucherPharmacies: returnResponse }))
		return returnResponse
	}
	return {
		handleBagVoucher,
	}
}
