/** @format */

import React, { Component } from 'react'

import { formatPrice } from '../../../../../../../../components/Commons/Hooks/Functions/FormatPrice'

import './style.scss'

export default class PriceTotal extends Component {
	render() {
		return (
			<>
				<div className='ph-order-detail-products-item-price-total-container'>
					<h3 className='ph-order-detail-products-item-price-total-title'>Precio por </h3>
					<h3 className='ph-order-detail-products-item-price-total-subtitle'>
						{this.props.item.quantity}
					</h3>
					<h3 className='ph-order-detail-products-item-price-total-description'>
						Unidades Compradas:
					</h3>
					<h3 className='ph-order-detail-products-item-price-total-symbol'>$</h3>
					<h3 className='ph-order-detail-products-item-price-total'>
						{this.props.item.sub_total && <> {formatPrice(this.props.item.sub_total)} </>}
					</h3>
				</div>
			</>
		)
	}
}
