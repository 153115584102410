/** @format */

//import { handleNotification } from '../BagNotification'

export const handleDispatch = (item) => {
  /*   const handleTitleRetirement = (item) => {
    if (item === "farmacia")
      return { title: "Retiro en Farmacia", type: "retirement" };
    else if (item === "domicilio")
      return { title: "Despacho a Domiclio", type: "delivery" };
    else return { title: "Ningún tipo", type: "available" };
  }; */
  let despatch = true;
  item.forEach((item) => {
    if (item.locals && item.locals.length === 0) {
      /* 			handleNotification(
				'¡Error',
				'error',
				`Actualmente ${item.name_editable} no cuenta con el servicio de ${handleTitleRetirement(item.type_retirement).title} para la dirección de entrega ingresada.`
			) */

      despatch = false;
    }
  });
  return despatch;
};
