/** @format */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Col from 'antd/lib/col';
import Form from 'antd/lib/form';
import Row from 'antd/lib/row';
import Select from 'antd/lib/select';

import Loading from '../../../../components/Commons/Loading';
import MainTitlePage from '../../../../components/Commons/MainTitlePage';
import MetaDescription from '../../../../components/Commons/MetaDescription';

import ErrorRequest from '../../../../components/HooksCheckout/components/ErrorRequest';
import servicesSchedule from './services';

import CustomButton from '../../../../v2-components/Antd/Button';
import './style.scss';

const ScheduleonDemand = (props) => {
	const { t } = useTranslation('global');

	const [form] = Form.useForm();
	const [isData, setData] = useState(null);
	const [isServiceResponse, setServiceResponse] = useState(true);
	const [isDisableDay, setDisableDay] = useState(true);
	const [isDaySelected, setDaySelected] = useState(false);
	const [isSuccessSchedule, setSuccessSchedule] = useState(false);
	const { Option } = Select;

	const handleDateSelected = (item) => {
		setDisableDay(false);
		const filteredHours = isData.filter((data) => item === data.date);
		setDaySelected(filteredHours[0].timers);
		form.setFieldsValue({
			time_delivery: null,
		});
	};

	const handleScheduleDelivery = (item) => {
		setSuccessSchedule(true);
		let newStructure = {
			schedule_date: `${item.date_delivery} ${item.time_delivery}`,
		};
		isData.forEach((group) => {
			if (group.date === item.date_delivery) {
				let theIndex = group.timers.findIndex((timer) => `${timer.start_time} - ${timer.end_time}` === item.time_delivery);
				newStructure = {
					...newStructure,
					schedule_object: { ...group.timers[theIndex] },
				};
			}
		});
		servicesSchedule.onDemandScheduleSuccess(newStructure, props.match.params.token).then((response) => {
			setSuccessSchedule(false);
			if (response) {
				window.location.href = '/preorder/schedule-success';
			}
		});
	};

	useEffect(() => {
		servicesSchedule.onDemandSchedule(props.match.params.token).then((response) => {
			if (response) {
				setData(response);
			} else {
				setServiceResponse(false);
			}
		});
	}, [props.match.params.token]);

	if (isServiceResponse) {
		if (isData) {
			return (
				<>
					<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
					<div className="ph-preorder-schedules-global-container">
						<div className="ph-preorder-schedules-global-inner-container">
							<MainTitlePage
								rootImage={true}
								title={'Cotización'}
								descriptionPage={'Seleccionar fecha y horario de entrega'}
								image={'react-header/pharol-clock'}
							/>
							<div className="ph-preorder-schedules-main-container">
								<div className="ph-preorder-schedules-inner-container">
									<div className="ph-preorder-schedules-text-container">
										<h3 className="ph-preorder-schedules-text-title">Por favor selecciona una fecha y horario de entrega de tus productos farmacéuticos.</h3>
									</div>
									<Form onFinish={handleScheduleDelivery} form={form}>
										<Form.Item>
											<Row>
												<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-preorder-schedules-form-container">
													<Form.Item
														name="date_delivery"
														rules={[
															{
																required: true,
																message: 'Ingrese la fecha de entrega',
															},
														]}
													>
														<Select
															placeholder="Día y Fecha"
															className="ph-retirement-personal-delivery-selector"
															size="large"
															style={{ width: '100%' }}
															onChange={(value) => handleDateSelected(value)}
														>
															{isData?.map((element, index) => (
																<Option value={element.date} key={index}>
																	{element.date}
																</Option>
															))}
														</Select>
													</Form.Item>
												</Col>
												<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-preorder-schedules-form-container">
													<Form.Item
														name="time_delivery"
														rules={[
															{
																required: true,
																message: 'Ingrese el horario de entrega',
															},
														]}
													>
														<Select
															placeholder="Horario"
															className="ph-preorder-schedules-delivery-selector"
															size="large"
															disabled={isDisableDay}
															style={{ width: '100%' }}
														>
															{isDaySelected && (
																<>
																	{isDaySelected?.map((element, index) => (
																		<Option value={`${element.start_time} - ${element.end_time}`} key={index}>
																			{`${element.start_time} - ${element.end_time}`}
																		</Option>
																	))}
																</>
															)}
														</Select>
													</Form.Item>
												</Col>
											</Row>
										</Form.Item>
										<div className="ph-preorder-button-schedule-container ">
											<CustomButton loading={isSuccessSchedule} className={'button-primary'} htmlType={'submit'}>
												ENVIAR
											</CustomButton>
										</div>
									</Form>
								</div>
							</div>
						</div>
					</div>
				</>
			);
		} else {
			return <Loading />;
		}
	} else {
		return <ErrorRequest />;
	}
};
export default ScheduleonDemand;
