import Notify from '../../../Notify';
import { ENV_COUNTRY } from '../../Variables/Enviroment';

export default function CheckLoginUser(props) {
	const email = localStorage.getItem('userEmail');
	if (ENV_COUNTRY === 'CL') return true;

	if (ENV_COUNTRY === 'AR') {
		console.log('localStorage', ENV_COUNTRY, email);
		if (email === undefined || email === '') {
			Notify({
				title: `¡Aviso!`,
				colorTitle: 'ph-main-notification-success-title',
				description: `No puede agregar productos sin haber iniciado sesión`,
				time: 8,
				image: 'warning',
				placement: 'topRight',
			});
			return false;
		} else {
			return true;
		}
	}
}
