/** @format */
import { Row, Col, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import './style.scss';

export default function KnowLoginUser() {
	if (!localStorage.getItem('userToken') && !localStorage.getItem('userEmail')) {
		if (
			window.location.pathname.match('/request-preorder/magistral') ||
			window.location.pathname.match('/request-preorder/on-demand') ||
			window.location.pathname.match('/quote/minsal') ||
			window.location.pathname.match('/quote/digital')
		) {
			Modal.info({
				title: '¿Deseas disfrutar de este servicio?',
				className: 'ph-knowloginuser-msn-content',
				content: (
					<>
						<p>
							Para disfrutar de este servicio debes <b>Iniciar sesión</b> o Registrarte:{' '}
						</p>
						<br />
						<Row className="">
							<Col span={12} className="">
								<a href="/auth/register" className="ph-knowloginuser-access-button-register">
									Registrarte
								</a>
							</Col>
							<Col span={12} className="">
								<a href="/auth/login" className="ph-knowloginuser-access-button-login">
									Iniciar Sesión
								</a>
							</Col>
						</Row>
						<a className="ph-knowloginuser-close-btn" href="/">
							<CloseOutlined />
						</a>
					</>
				),
			});
		}
	}
}
