export class AxiosInterceptor {
	constructor(response) {
		this.response = response || [];
	}

	async pipe() {
		try {
			console.log('try al pipe');
			if (this.response.data.length > 0) {
				return this.response.data;
			}

			if (this.response.data.statusCode === 200 || this.response.data.statusCode === 201) {
				console.log('pipe data ->', this.response.data.data);
				return this.response.data.data;
			}

			throw this.response.data;
		} catch (e) {
			console.log('catch pipe', e);
			throw e;
		}
	}
}
