/** @format */

export const menuComplaint = [
	{
		type: 'modal',
		image: 'react-header/pharol-usuario-login',
		title_image: 'lol',
		title: 'Crear Solicitud',
		description:
			'Aqui podrás crear un nuevo reporte con el requerimiento que necesites que sea atendido.',
	},
	{
		type: 'link',
		link: '/complaint-book/search',
		image: 'react-pharmacy/complaint-book/claim-icon',
		title_image: 'lol',
		title: 'Buscar Solicitud',
		description:
			'Busca una solicitud realizada por número de RUT o su código correspondiente asignado.',
	},
	{
		type: 'link',
		link: '/complaint-book/history/1',
		image: 'react-header/pharol-tracking-search',
		title_image: 'lol',
		title: 'Libro de Reclamos',
		description:
			'Consulta un rango de fechas de solicitudes o reclamos realizados por los clientes.',
	},
]
