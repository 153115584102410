/** @format */

import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn';
import { useTranslation } from 'react-i18next';

import { Layout, Col, Row } from 'antd';

import { ENV_COUNTRY } from '../../../../components/Commons/Hooks/Variables/Enviroment';

import CountryValidator from '../../../../components/Commons/CountryValidator';

import Images from '../../../../components/Commons/Images';
import Loading from '../../../../components/Commons/Loading';
import MainTitlePageAlt from '../../../../components/Commons/MainTitlePageAlt';
import MetaDescription from '../../../../components/Commons/MetaDescription';

import { default as userIcon } from '../../../../components/Commons/Assets/Icons/Filled/Enabled/user.svg';
import { default as userIconOpacity } from '../../../../components/Commons/Assets/Icons/Filled/EnabledOpacity/user.svg';
import { default as userIconGreen } from '../../../../components/Commons/Assets/Icons/Filled/Enabled/Argentina/user1-verde.png';

import { getUserDataService } from './services';
import CardInfo from './components/CardInfo';
import ModalEditProfile from './components/ModalEditProfile';
import { CheckUserInfo } from '../../../../components/Commons/Hooks/Functions/CheckUserInfo';
import CustomButton from '../../../../v2-components/Antd/Button';

import data from './data.json';

import './style.scss';

const { Content } = Layout;

export default function ProfileDetail(props) {
	const { t } = useTranslation('global');

	const [isList, setList] = useGlobal('userProfile');
	const [isEmail] = useState(localStorage.getItem('userEmail'));
	const [isModalEditProfile, setModalEditProfile] = useState(null);
	const [isLoading, setLoading] = useState(true);
	const [message, setMessage] = useState(null);

	const init = async () => {
		const login = await CheckUserInfo(localStorage.getItem('userMainInfo'));
		if (login === false) {
			setMessage('Aún debe completar sus datos personales para avanzar.');
		}
	};
	useEffect(() => {
		getUserDataService(isEmail).then((response) => {
			setList(response);
			setLoading(false);
		});
		init();
	}, [isEmail, setList, props.history]);

	const handleEditProfileModal = (item) => {
		setModalEditProfile(item);
	};

	if (isLoading) {
		return <Loading />;
	} else {
		return (
			<>
				<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
				<Content className="ph-userinfo-global-container">
					<div className="ph-userinfo-main-container">
						<MainTitlePageAlt
							title={'Datos Personales'}
							descriptionProfile={t('profile.backToAcountLink')}
							image={ENV_COUNTRY === 'AR' ? userIconGreen : userIcon}
						/>
						<p>{message}</p>
						<Row className="ph-userinfo-container">
							<Col xs={24} sm={24} md={8} lg={8} xl={8} className="ph-userinfo-inner-container">
								<div className="ph-userinfo-perfil-image-main-container">
									<Images
										classContent={'ph-userinfo-perfil-image-container'}
										classImage={'ph-userinfo-perfil-image'}
										default={userIconOpacity}
										title={'User Pharol'}
									/>
									<div className="ph-userinfo-perfil-title-container">
										<h3 className="ph-userinfo-perfil-title">Hola,</h3>
										<h3 className="ph-userinfo-perfil-subtitle">
											{isList && (
												<>{isList.first_name && isList.last_name ? <>{isList.first_name + ' ' + isList.last_name}</> : '(Debes llenar tus datos Pesonales)'}</>
											)}
										</h3>
									</div>
									<h3 className="ph-userinfo-perfil-description">¡Te damos la bienvenida!</h3>
								</div>
							</Col>
							<Col xs={24} sm={24} md={16} lg={16} xl={16} className={`${!isList ? 'ph-no-userdata-inner-container' : ''} ph-userdata-inner-container`}>
								{isList ? (
									<>
										<CardInfo title={'Nombre:'} description={isList.first_name} />
										<CardInfo title={'Apellido:'} description={isList.last_name} />
										<CardInfo title={'Correo Electrónico:'} description={isEmail} />
										<CardInfo title={'Teléfono:'} description={isList.phone} />
										<CardInfo title={data[ENV_COUNTRY].title_document} description={isList.rut} />
										<CardInfo title={'Fecha de nacimiento:'} description={isList.age} />
										<CountryValidator countries={['CL']} component={<CardInfo title={'Género:'} description={isList.sex} />} />
									</>
								) : (
									<h3 className="ph-no-userdata-inner-title">Usa el Boton Editar Para Llenar tus Datos</h3>
								)}
							</Col>
							<CustomButton className={'button-primary ph-userinfo-edit-button'} onClick={() => handleEditProfileModal(true)}>
								Editar
							</CustomButton>
						</Row>
						<ModalEditProfile list={isList} email={isEmail} editProfileModal={handleEditProfileModal} visible={isModalEditProfile} />
					</div>
				</Content>
			</>
		);
	}
}
