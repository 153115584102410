/** @format */

import React, { Component } from 'react'

import Spacer from '../../../../../../../../components/Commons/Spacer'

import TypeRetirement from './components/TypeRetirement'
import AmountInfo from './components/AmountInfo'

import './style.scss'

export default class ProductPaymentInfo extends Component {
	render() {
		return (
			<>
				<div className='ph-order-detail-products-payment-info-main-container'>
					<TypeRetirement item={this.props.item} />
					<Spacer />
					<div className='ph-order-detail-products-payment-info-inner-container'>
						<AmountInfo
							classTitle={'ph-order-detail-products-total-price-description-blue'}
							title={'Subtotal:'}
							description={this.props.item.sub_total}
						/>
						<Spacer />
						{this.props.item.total_services !== 0 && (
							<AmountInfo
								title={'Servicios:'}
								description={this.props.item.total_services}
							/>
						)}
						{this.props.item.total_delivery !== 0 && (
							<AmountInfo title={'Despacho:'} description={this.props.item.total_delivery} />
						)}
						{this.props.item.total_discount !== 0 && (
							<AmountInfo
								title={'Descuento:'}
								description={this.props.item.total_discount}
							/>
						)}
						{this.props.item.total_discount_pbm !== 0 && (
							<AmountInfo title={'PBM:'} description={this.props.item.total_discount_pbm} />
						)}
						{this.props.item.total_voucher_discount !== 0 && (
							<AmountInfo
								title={'Cupón:'}
								description={this.props.item.total_voucher_discount}
							/>
						)}
						<AmountInfo
							classTitle={'ph-order-detail-products-total-price-description-blue-weight'}
							classDescription={'ph-order-detail-products-total-price-description-black'}
							title={'Total:'}
							description={this.props.item.total}
						/>
					</div>
				</div>
			</>
		)
	}
}
