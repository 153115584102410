/** @format */

import React from 'react';

import Images from '../../../Commons/Images';

import './style.css';

export default function ErrorPrescription() {
	return (
		<div className="ph-checkout-request-error-service-global-container">
			<div className="ph-checkout-request-error-service-main-container">
				<Images
					classContent={'ph-checkout-request-error-service-image-container'}
					classImage={'ph-checkout-request-error-service-image'}
					default={'https://storage.googleapis.com/master-image-bucket/bag/bolsa-triste.png'}
					title={'bolsa'}
				/>
				<h2 className="ph-checkout-request-error-service-title">Lo sentimos!</h2>
				<h3 className="ph-checkout-request-error-service-description">Ha ocurrido un error, comuniquese con nuestro equipo de soporte...</h3>
			</div>
		</div>
	);
}
