/** @format */

import React from 'react'

import { Steps } from 'antd'

import './style.scss'

export default function Steper(props) {
	return (
		<Steps className='ph-stepper-old-main-container ant-steps-horizontal' size='small' current={props.step} direction='horizontal'>
			<Steps.Step title='Bolsa de Compras' />
			<Steps.Step title='Entrega' />
			<Steps.Step title='Receta' />
			<Steps.Step title='Pago' />
		</Steps>
	)
}
