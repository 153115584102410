/** @format */

import React from 'react'

import { GlobalConsumer } from '../../../../components/Commons/Context/Global'
import { BagConsumer } from '../../../../components/Commons/Context/Bag'

import BagAddress from './components/BagAddress'
import BagPriceinfo from './components/BagPriceInfo'
import BagRecipesInfo from './components/BagRecipesInfo'
import BagButtonCalculate from './components/BagButtonCalculate'

import './style.scss'

export default function BagMainInfo(props) {
	const { isMobile } = GlobalConsumer()
	const { isBagData } = BagConsumer()

	return (
		<div className='ph-bag-main-info-global-container'>
			<div className='ph-bag-main-info-main-container'>
				<div>
					{!isMobile && (
						<>
							<div className='ph-bag-main-info-main-title-container'>
								<span className='ph-bag-main-info-main-title-dot'></span>
								<span className='ph-bag-main-info-main-title'>¿Cuál es tu dirección de entrega?</span>
							</div>
							<div>
								<BagAddress isType={props.isType} classType={'ph-cart-form-address'} />
							</div>
						</>
					)}
					<BagRecipesInfo />
					<div className='ph-bag-main-info-delivery-container'>
						<BagPriceinfo amount={isBagData?.total} type={'primary'} title={'Total a Pagar:'} />
					</div>
				</div>
				<div className='ph-bag-main-info-button-calculate-container'>
					<BagButtonCalculate cartForm={props.cartForm} />
				</div>
			</div>
		</div>
	)
}
