/** @format */

import React from 'react';
import { NavLink } from 'react-router-dom';

import Spacer from '../../Spacer';

import { ENV_COUNTRY } from '../../Hooks/Variables/Enviroment';
import { IMAGES } from '../../../../v2-config/constants/images.constant';

import './style.scss';

const SecondaryHeader = () => {
	return (
		<>
			<div className="ph-header-secondary-space-vertical-container"></div>
			<div className={'ph-header-secondary-global-container'}>
				<div className="ph-header-secondary-main-container">
					<NavLink to="/">
						<img src={IMAGES.LOGO_LIGHT_BACKGROUND} alt="logo" className='ph-header-secondary-logo-image'/>
					</NavLink>
					<Spacer />
					<NavLink className={ENV_COUNTRY === 'AR' ? 'ph-header-secondary-button-arg' : 'ph-header-secondary-button'} to="/">
						Seguir Comprando
					</NavLink>
				</div>
			</div>
		</>
	);
};
export default SecondaryHeader;
