import { Col, Form, Row } from 'antd';
import React, { useState } from 'react';
import CustomButton from '../../../../v2-components/Antd/Button';
import CustomCard from '../../../../v2-components/Antd/Card';
import CustomFormItem from '../../../../v2-components/Antd/FormItem';
import CustomInput from '../../../../v2-components/Antd/Input';
import CustomLink from '../../../../v2-components/Antd/Link';
import { CustomNotification } from '../../../../v2-components/Antd/Notification';
import CustomSelect from '../../../../v2-components/Antd/Select';
import CustomTextArea from '../../../../v2-components/Antd/TextArea';
import LoadingModal from '../../../../v2-components/LoadingModal';
import { VALIDATION } from '../../../../v2-config/constants/validations.constant';
import { CLAIMS_DICTIONARY } from './constants/dictionary.constant';
import claimsOptions from './constants/options.constant';
import { Service } from './service';
import './style.scss';
import { Enviroment } from '../../../../_config/constants/enviroment-v2.constant';

export default function ClaimsCreatePage() {
	const [form] = Form.useForm();
	const [openLoadingModal, setOpenLoadingModal] = useState(false);
	const [isLoadingModal, setIsLoadingModal] = useState(false);

	const handleFinish = (item) => {
		if (item.trx_id) {
			parseInt(item.trx_id);
		}
		item.phone = `(+56)${item.phone}`;
		item.platform = Enviroment.ROOT_PLATFORM;
		item.pharmacy = Enviroment.ROOT_PHARMACY;
		item.country = Enviroment.COUNTRY;
		setOpenLoadingModal(true);
		setIsLoadingModal(true);
		new Service()
			.createClaim({ ...item })
			.then((response) => {
				setIsLoadingModal(false);
				setOpenLoadingModal(false);
				CustomNotification({
					type: 'success',
					message: `Solicitud cargada correctamente`,
					description: `Su número de ${CLAIMS_DICTIONARY[response?.data?.type]} es ${response?.data?.claim_id}`,
					duration: null,
				});
				form.resetFields();
			})
			.catch((error) => {
				console.log(error);
			});
	};
	return (
		<>
			<Row justify="center" className="create-content">
				<Col md={18} xs={22}>
					<Row justify="left">
						<CustomLink className={'link-primary'} to="/claims">
							Volver
						</CustomLink>
					</Row>
					<br></br>
					<Form form={form} layout="vertical" onFinish={handleFinish}>
						<CustomCard
							className={'card-primary'}
							title="Crear Solicitud"
							actions={[
								<CustomButton className={'button-primary'} htmlType="submit">
									Enviar
								</CustomButton>,
							]}
						>
							<CustomFormItem className={'form-primary'} label={'Nombre'} name={'first_name'} rules={VALIDATION.alphabetic_name}>
								<CustomInput className={'input-primary'} placeholder={'María'} />
							</CustomFormItem>
							<CustomFormItem className={'form-primary'} label={'Apellido'} name={'last_name'} rules={VALIDATION.alphabetic_last_name}>
								<CustomInput className={'input-primary'} placeholder={'Sanchez'} />
							</CustomFormItem>
							<CustomFormItem className={'form-primary'} label={'Teléfono'} name={'phone'} rules={VALIDATION.phone_number}>
								<CustomInput className={'input-primary'} placeholder={'1 1111 1111'} />
							</CustomFormItem>
							<CustomFormItem className={'form-primary'} label={'Rut'} name={'rut'} rules={VALIDATION.rut}>
								<CustomInput className={'input-primary'} placeholder={'11.111.111-1'} />
							</CustomFormItem>
							<CustomFormItem className={'form-primary'} label={'Correo electrónico'} name={'email'} rules={VALIDATION.email_format}>
								<CustomInput className={'input-primary'} placeholder={'ejemplo@mail.com'} />
							</CustomFormItem>
							<CustomFormItem className={'form-primary'} label={'Tipo de solicitud'} rules={VALIDATION.required} name={'type'}>
								<CustomSelect placeholder={'Seleccione una opción'} className={'select-primary'} options={claimsOptions} />
							</CustomFormItem>
							<CustomFormItem className={'form-primary'} label={'Número de pedido'} name={'trx_id'} rules={VALIDATION.trx_id}>
								<CustomInput className={'input-primary'} placeholder={'123456'} />
							</CustomFormItem>
							<CustomFormItem className={'form-primary'} label={'Mensaje'} name={'message'} rules={VALIDATION.message}>
								<CustomTextArea className={'text-area-primary'} rows={5} placeholder={'Escribe tu mensaje'} />
							</CustomFormItem>
						</CustomCard>
					</Form>
					<LoadingModal loading={isLoadingModal} visible={openLoadingModal} />
				</Col>
			</Row>
		</>
	);
}
