/** @format */

import React, { useEffect, useState } from 'react'

import { Col, Form, Select } from 'antd'

import InputField from '../../../../../../../components/Commons/Input'

import './style.scss'

export default function DateTimeSelect(props) {
	const [isNumber, setNumber] = useState(null)

	const handleTypeHOuseSelected = (item) => {
		if (item === 'departamento') {
			setNumber(true)
		} else {
			setNumber(false)
		}
	}

	useEffect(() => {
		if (
			!!props.personalData?.number_dpto_house ||
			!!JSON.parse(localStorage.getItem('form_retirement'))?.number_dpto_house
		)
			setNumber(true)
	}, [props.personalData?.number_dpto_house])

	return (
		<>
			<Col
				xs={24}
				sm={24}
				md={12}
				lg={12}
				xl={12}
				className='ph-withdrawal-type-house-select-form-container'>
				<Form.Item
					name={`type_house${props.mx ? '_mx' : ''}`}
					rules={[
						{
							required: true,
							message: 'Tipo de vivienda obligatorio',
						},
					]}>
					<Select
						placeholder='Tipo de vivienda'
						className='ph-withdrawal-type-house-select-selector'
						size='large'
						style={{ width: '100%' }}
						onChange={(value) => handleTypeHOuseSelected(value)}>
						<Select.Option value='casa'>Casa</Select.Option>
						<Select.Option value='departamento'>Departamento</Select.Option>
					</Select>
				</Form.Item>
			</Col>
			{isNumber && (
				<Col
					xs={24}
					sm={24}
					md={12}
					lg={12}
					xl={12}
					className='ph-withdrawal-type-house-select-form-container'>
					<InputField
						className={'ph-withdrawal-type-house-select-field-input'}
						inputName={`number_department${props.mx ? '_mx' : ''}`}
						inputNameLabel={'No. Departamento'}
						inputNameRule={true}
						inputNameMessage={'Ingresa el No. de su departamento'}
						inputNameType={'text'}
						inputNameRules={'rulesRequired'}
					/>
				</Col>
			)}
		</>
	)
}
