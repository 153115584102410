/** @format */

import React, { Component } from "react";
import { Link } from "react-router-dom";

import Images from "../Images";
import { ENV_IMG_HEADER, ENV_IMG } from "../Hooks/Variables/Enviroment";

import "./style.scss";

export default class MainTitleInfo extends Component {
  state = {
    image: this.props.rootImage ? ENV_IMG : ENV_IMG_HEADER,
  };

  render() {
    return (
      <>
        <div>
          <div
            className={`ph-profile-main-box-title-container ${this.props.classDetailPageContainer}`}
          >
            <Images
              classContent={"ph-profile-main-box-title-image-container"}
              classImage={"ph-profile-main-box-title-image"}
              default={`${this.state.image}/${this.props.image}.png`}
              title={this.props.title}
            />
            <h2
              className={`ph-profile-main-box-main-title ${this.props.classDetailPagetitle}`}
            >
              {this.props.title}
            </h2>
          </div>
          {localStorage.getItem("userEmail") &&
          this.props.descriptionProfile ? (
            <Link to="/profile">
              <h3
                className={`ph-profile-main-box-main-subtitle ${this.props.classDetailPageDescription}`}
              >
                {this.props.descriptionProfile}
              </h3>
            </Link>
          ) : this.props.descriptionOrderDetail ? (
            <Link to="/tracking">
              <h3
                className={`ph-profile-main-box-main-subtitle ${this.props.classDetailPageDescription}`}
              >
                {this.props.descriptionOrderDetail}
              </h3>
            </Link>
          ) : (
              <h3
                className={`ph-profile-main-box-main-subtitle-fq ${this.props.classDetailPageDescription}`}
              >
                {this.props.descriptionPage}
              </h3>
          )}
        </div>
      </>
    );
  }
}
