/** @format */

import axios from 'axios';

import Notify from '../../../../components/Commons/Notify';

import { ENV_SCHEDULE, ENV_TRACKING } from '../../../../components/Commons/Hooks/Variables/Enviroment';

const servicesSchedule = {
	async onDemandSchedule(item) {
		let returnResponse;
		await axios({
			method: 'GET',
			url: `${ENV_SCHEDULE}/delivery-ondemand/${item}`,
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					returnResponse = response.data.data;
				} else {
					Notify({
						title: `Error!`,
						colorTitle: 'ph-main-notification-error-title',
						description: `${response.data.error}...`,
						time: 3,
						image: 'error',
						placement: 'topRight',
					});
				}
			})
			.catch(() => {
				Notify({
					title: `Problemas de Conexión`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Revisa tu conexión o ponte en contacto con nosotros.`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			});
		return returnResponse;
	},
	async onDemandScheduleSuccess(item, data) {
		let returnResponse;
		await axios({
			method: 'PUT',
			url: `${ENV_TRACKING}/on-demand/schedule-update/${data}`,
			data: item,
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					returnResponse = response;
				} else {
					Notify({
						title: `Error!`,
						colorTitle: 'ph-main-notification-error-title',
						description: `${response.data.error}...`,
						time: 3,
						image: 'error',
						placement: 'topRight',
					});
				}
			})
			.catch(() => {
				Notify({
					title: `Problemas de Conexión`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Revisa tu conexión o ponte en contacto con nosotros.`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			});
		return returnResponse;
	},
};

export default servicesSchedule;
