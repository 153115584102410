/** @format */

import React, { useState } from 'react';

import Images from '../../../../components/Commons/Images';

import { ENV_IMG } from '../../../../components/Commons/Hooks/Variables/Enviroment';

import './style.scss';

export default function CardOnDemand() {
	const [isOnDemand] = useState(JSON.parse(localStorage.getItem('onDemandData')));
	if (isOnDemand) {
		return (
			<div className="ph-checkout-on-demand-card-container">
				{isOnDemand.quotation_type === 'magistral' && (
					<Images
						classContent={'ph-checkout-on-demand-card-image-container'}
						classImage={'ph-checkout-on-demand-card-image'}
						webp={`${ENV_IMG}/react-checkout/magistral-recipe/magistral.webp`}
						jxr={`${ENV_IMG}/react-checkout/magistral-recipe/magistral.jxr`}
						jp2={`${ENV_IMG}/react-checkout/magistral-recipe/magistral.jp2`}
						default={`${ENV_IMG}/react-checkout/magistral-recipe/magistral.png`}
						title={'Receta Magistral'}
					/>
				)}
				{isOnDemand.quotation_type === 'on-demand' && (
					<Images
						classContent={'ph-checkout-on-demand-card-image-container'}
						classImage={'ph-checkout-on-demand-card-image'}
						webp={`${ENV_IMG}/react-product/on-demand.webp`}
						jxr={`${ENV_IMG}/react-product/on-demand.jxr`}
						jp2={`${ENV_IMG}/react-product/on-demand.jp2`}
						default={`${ENV_IMG}/react-product/on-demand.png`}
						title={'Contra Pedido'}
					/>
				)}
				<div>
					<h4 className="ph-checkout-on-demand-title">¡Recuerda!</h4>
					<h4 className="ph-checkout-on-demand-subtitle">
						Posees productos de{' '}
						<span className="ph-checkout-on-demand-subtitle-bold">
							{isOnDemand.quotation_type === 'magistral' && 'Receta Magistral'} {isOnDemand.quotation_type === 'on-demand' && 'Contra Pedido'}
						</span>{' '}
						en tu compra.
					</h4>
				</div>
			</div>
		);
	} else {
		return '';
	}
}
