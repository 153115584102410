import { Col, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Enviroment } from '../../../_config/constants/enviroment-v2.constant';
import CustomButton from '../../../v2-components/Antd/Button';
import CustomCard from '../../../v2-components/Antd/Card';
import CustomFormItem from '../../../v2-components/Antd/FormItem';
import CustomImage from '../../../v2-components/Antd/Image';
import CustomInput from '../../../v2-components/Antd/Input';
import CustomLink from '../../../v2-components/Antd/Link';
import { CustomNotification } from '../../../v2-components/Antd/Notification';
import LoadingModal from '../../../v2-components/LoadingModal';
import { IMAGES } from '../../../v2-config/constants/images.constant';
import { VALIDATION } from '../../../v2-config/constants/validations.constant';
import { Service } from './service';
import './style.scss';

export default function RecoverPasswordUser() {
	const [form] = Form.useForm();
	const [openLoadingModal, setOpenLoadingModal] = useState(false);
	const history = useHistory();

	const handleFinish = (item) => {
		setOpenLoadingModal(true);
		new Service()
			.recoverPassword({ ...item, platform: Enviroment.ROOT_SITE, country: Enviroment.COUNTRY, pharmacy: Enviroment.ROOT_PHARMACY })
			.then((response) => {
				if (response.statusCode !== 201) {
					setOpenLoadingModal(false);
					return CustomNotification({ type: 'error', message: null, description: response.message });
				}
				form.resetFields();
				setOpenLoadingModal(false);
				CustomNotification({ type: 'success', message: 'Exito', description: 'Verifique su casilla de correo' });
				history.push('/auth/login');
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		if (localStorage.getItem('userEmail')) history.push('/');
	}, [history]);

	return (
		<Row justify="center" align="middle" className="own-register-container">
			<Col md={13} xs={22}>
				<br />
				<Form form={form} layout="vertical" onFinish={handleFinish}>
					<CustomCard
						className={'card-primary'}
						actions={[
							<CustomLink className={'link-primary'} to="/">
								Volver
							</CustomLink>,
							<CustomButton className={'button-primary'} htmlType="submit">
								RECUPERAR
							</CustomButton>,
						]}
					>
						<Row justify="center">
							<CustomImage src={IMAGES.LOGO_LIGHT_BACKGROUND} alt="logo" height={42} />
						</Row>
						<Row justify="center">
							<h2>Recuperar Contraseña</h2>
						</Row>
						<Row justify="center">
							<p>Fácil y rápido cuando lo necesitas.</p>
						</Row>

						<Row justify="center" align="middle">
							<CustomFormItem className={'form-primary'} name={'email'} rules={VALIDATION.email}>
								<CustomInput className={'input-primary'} placeholder={'Correo electrónico'} />
							</CustomFormItem>
						</Row>
						<Row justify="center">
							<p>
								¿Ya tienes cuenta? <Link to={'/auth/login'}>Haz click aquí.</Link>
							</p>
						</Row>
					</CustomCard>
				</Form>
				<LoadingModal visible={openLoadingModal} />
			</Col>
		</Row>
	);
}
