/** @format */

import moment from "moment";

import { ENV_ADDRESS } from "../Enviroment";

import { setGlobal } from "reactn";

import { GetFavorites } from "../../../Favorites/Functions";

const userToken = localStorage.getItem("userToken");
const userEmail = localStorage.getItem("userEmail");
const userNotifications = JSON.parse(localStorage.getItem("userNotifications"));

const cart = localStorage.getItem("cart");
const ePrescriptionToken = localStorage.getItem("ePrescriptionToken");
const locationStore = JSON.parse(localStorage.getItem("locationStore"));

const date = new Date();
const formatUTC = Date.UTC(
  date.getUTCFullYear(),
  date.getUTCMonth(),
  date.getUTCDate(),
  date.getUTCHours(),
  date.getUTCMinutes(),
  date.getUTCSeconds()
);
const formatDate = moment(formatUTC).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
const dateNow = moment(formatDate, "YYYY-MM-DD HH:mm:ss");

let timeDistanceWelcome;
let timeDistanceSuggestion;

if (userNotifications?.dateWelcome) {
  const dateWelcomeStored = moment(
    userNotifications?.dateWelcome,
    "YYYY-MM-DD HH:mm:ss"
  );
  timeDistanceWelcome = dateNow.diff(dateWelcomeStored, "m");
}

if (userNotifications?.dateSuggestion) {
  const dateSuggestionStored = moment(
    userNotifications?.dateSuggestion,
    "YYYY-MM-DD HH:mm:ss"
  );
  timeDistanceSuggestion = dateNow.diff(dateSuggestionStored, "m");
}

if (
  !userNotifications?.welcomeNotification &&
  (!!window.location.pathname.match(/categories/) ||
    !!window.location.pathname.match(/laboratories/) ||
    !!window.location.pathname.match(/pharmacies/) ||
    !!window.location.pathname.match(/results/) ||
    !!window.location.pathname.match(/products/) ||
    !!window.location.pathname.match(/services/) ||
    !!window.location.pathname.match(/complaint-book/) ||
    window.location.pathname === "/contact" ||
    window.location.pathname === "/") &&
  (!userNotifications?.dateWelcome || timeDistanceWelcome >= 10)
) {
  setTimeout(() => {
    setGlobal(() => ({ GlobalModalWelcome: true }));
  }, 300);
}

if (
  userNotifications?.welcomeNotification &&
  (locationStore === null || locationStore.default) &&
  (!!window.location.pathname.match(/categories/) ||
    !!window.location.pathname.match(/laboratories/) ||
    !!window.location.pathname.match(/pharmacies/) ||
    !!window.location.pathname.match(/results/) ||
    !!window.location.pathname.match(/products/) ||
    !!window.location.pathname.match(/services/) ||
    !!window.location.pathname.match(/complaint-book/) ||
    window.location.pathname === "/contact" ||
    window.location.pathname === "/") &&
  (!userNotifications?.dateSuggestion || timeDistanceSuggestion >= 10)
) {
  setTimeout(() => {
    setGlobal(() => ({ GlobalModalSuggestion: true }));
  }, 300);
}

if (userEmail === null) localStorage.setItem("userEmail", "");

if (cart === null) localStorage.setItem("cart", JSON.stringify([]));

if (ePrescriptionToken === null)
  localStorage.setItem("ePrescriptionToken", JSON.stringify({}));

if (userToken) {
  let favorites = localStorage.getItem("userFavorites");
  const verifyFavorite = Array.isArray(JSON.parse(favorites));
  if (verifyFavorite === false) {
    favorites = null;
    localStorage.removeItem("userFavorites");
  }
  if (!favorites) GetFavorites(userToken);
}

if (locationStore === null) {
  let address = ENV_ADDRESS.split(",");
  let lat = parseFloat(address[3]);
  let lng = parseFloat(address[4]);
  localStorage.setItem(
    "locationStore",
    JSON.stringify({
      lat: lat,
      lng: lng,
      address: `${address[0]}, ${address[1]}, ${address[2]}`,
      street: address[0],
      commune: address[1],
      region: address[2],
      default: true,
    })
  );
}
