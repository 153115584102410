/** @format */

import React from 'react'

import './style.scss'

export default function ProductPromotionsDescription(props) {
	return (
		<>
			{props.item.promotions && (
				<>
					{props.item.promotions.rules.icon.type !== 'x' && (
						<>
							{props.item.promotions.rules.tag !== 'Uso Crónico' &&
								props.item.promotions.rules.icon.gp === false &&
								props.item.promotions.day_description !== 'X' && (
									<h3 className='ph-product-detail-promotion-description-main-title'>
										<span className='ph-product-detail-promotion-description-main-asterisk'>
											*
										</span>
										El descuento se verá reflejado antes de pagar
									</h3>
								)}
							{props.item.promotions.rules.tag === 'Uso Crónico' && (
								<h3 className='ph-product-detail-promotion-description-main-title'>
									Beneficio exclusivo para inscritos en
									<a
										className='ph-product-detail-promotion-description-title-gp'
										target='_blank'
										href='https://www.familiaahumada.cl/ws/registration.do'
										rel='noopener noreferrer'>
										Familia Ahumada
									</a>
									.
								</h3>
							)}
							{props.item.promotions.rules.icon.gp && (
								<h3 className='ph-product-detail-promotion-description-main-title'>
									<span className='ph-product-detail-promotion-description-main-asterisk'>
										*
									</span>
									Promoción exclusiva solo con
									<a
										className='ph-product-detail-promotion-description-title'
										target='_blank'
										href='https://www.familiaahumada.cl/ws/registration.do'
										rel='noopener noreferrer'>
										Familia Ahumada
									</a>
									.
								</h3>
							)}
						</>
					)}
				</>
			)}
		</>
	)
}
