/** @format */

import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { setGlobal, useGlobal } from 'reactn';
import { useTranslation } from 'react-i18next';

import qs from 'qs';

import { GlobalConsumer } from '../../../../components/Commons/Context/Global';
import { BagConsumer } from '../../../../components/Commons/Context/Bag';

import { Row, Col, Form } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';

import MetaDescription from '../../../../components/Commons/MetaDescription';
import Notify from '../../../../components/Commons/Notify';

import CheckoutTimeLine from '../../../../components/CheckoutTimeLine';
import CheckoutGlobalTitle from '../../../../components/CheckoutGlobalTitle';

import { RemoveLocalStorage } from '../../../../components/HooksCheckout/Functions/RemoveLocalStorage';

import BagMainInfo from '../../components/BagMainInfo';
import BagMainInfoMobile from '../../components/BagMainInfoMobile';
import BagAlliesInfo from '../../components/BagAlliesInfo';
import BagProductsGroup from '../../components/BagProductsGroup';
import BagError from '../../components/BagError';
import BagBanners from '../../components/BagBanners';
import BagModalCalculate from '../../components/BagModalCalculate';

import ElectronicProductcs from './components/ElectronicProductcs';
import Loading from './components/Loading';
import NoProducts from './components/NoProducts';
import { NewNotification } from './components/NotificationAddressBuyNow';

import { electronicQuoteServices } from '../../../../components/HooksCheckout/Functions/MinsalRequest';

import { BagValidateDelivery } from '../../functions/BagValidateDelivery';
import { BagCalculation } from '../../functions/BagCalculation';
import { BagMerge } from '../../functions/BagMergeLocals';

import BagTotal from '../../functions/BagTotal';

import './style.scss';

export default function Bag(props) {
	const { t } = useTranslation('global');
	const [isAddedCart] = useGlobal('onDemandCart');
	const { handleShowNotification } = NewNotification();
	const { isBag, isMobile, setBag } = GlobalConsumer();
	const { handleCheckRetirement } = BagValidateDelivery();
	const { handleBagTotal } = BagTotal();
	const {
		isBagData,
		isBagCollapse,
		setBagCalculateModal,
		isElectronicPrescription,
		setBagData,
		setBagTotalCart,
		setBagRetirementPharmacies,
		setBagRetirement,
		setElectronicPrescription,
	} = BagConsumer();

	let location = useLocation();
	let history = useHistory();
	let { token, rut } = useParams();

	const [cartForm] = Form.useForm();
	const [isQuery] = useState(qs.parse(location.search));
	const [isOnDemand] = useState(JSON.parse(localStorage.getItem('onDemandData')));
	const [isLocation] = useState(JSON.parse(localStorage.getItem('locationStore')));

	const [isType, setType] = useState(null);
	const [productsSelected, setProductsSelected] = useState([]);
	const [loading, setLoading] = useState({
		bag: true,
		electronic: true,
	});

	useEffect(() => {
		RemoveLocalStorage();
	}, []);

	useEffect(
		() => {
			let urlParams = new URLSearchParams(location.search);
			electronicQuoteServices({
				token,
				rut,
				type: urlParams.get('type'),
			}).then((response) => {
				if (response) {
					if ('message' in response.data && response.data.message.length > 0) handleShowNotification(response.data.message);
					setElectronicPrescription(response.data);
					setGlobal({ address: isLocation });
				} else {
					setElectronicPrescription(false);
				}
				setLoading((pvSt) => ({ ...pvSt, electronic: false }));
			});
			if (isQuery.voucher) {
				localStorage.setItem('validVoucher', isQuery.voucher);
			}
		}, // eslint-disable-next-line react-hooks/exhaustive-deps
		[location.search, rut, token, setElectronicPrescription, isLocation, history, isQuery.voucher],
	);

	useEffect(() => {
		if (isBag.length > 0) {
			setBagCalculateModal(true);
			BagCalculation('farmacia', false).then(async (response) => {
				setBagCalculateModal(false);
				if (response) {
					const localsMerged = await BagMerge(response.locals_group);
					const responseBag = { ...response, locals_group: localsMerged };

					let checkRetirement = handleCheckRetirement(isBag);
					setBagTotalCart(handleBagTotal(responseBag));
					let pharmacies = {};
					responseBag.locals_group.forEach((item) => {
						if (item.payment.discount !== 0) {
							Notify({
								title: `¡Bien!`,
								colorTitle: 'ph-main-notification-warning-title',
								description: `Estás disfrutando de un descuento en ${item.name_editable}. El monto se refleja al seleccionar el tipo de entrega.`,
								time: 5,
								image: 'warning',
								placement: 'topRight',
							});
						}
						pharmacies = {
							...pharmacies,
							[item.name]: item?.locals.length > 0 ? item : false,
						};
						if (pharmacies[item.name] === false) {
							checkRetirement[item.name] = {
								info: [],
								deliveryStatus: false,
								type_retirement: null,
							};
							let bag = isBag;
							bag.forEach((element) => {
								if (item.name === element.pharmacy) delete element.type_retirement;
							});
							localStorage.setItem('cart', JSON.stringify(bag));
							setBag(bag);
						}
					});
					if (isOnDemand) {
						localStorage.setItem('locationStore', JSON.stringify(isOnDemand.location));
						setGlobal({ address: isOnDemand.location, onDemandCart: true });
						setType('on_demand');
					}
					setBagRetirement({ ...checkRetirement });
					setBagRetirementPharmacies(pharmacies);
					setBagData(responseBag);
				} else setBagData(false);
			});
		}
		setLoading((pvSt) => ({ ...pvSt, bag: false })); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleCheckRetirement, setBagRetirementPharmacies, setBagCalculateModal, setBagData, setBagRetirement, cartForm]);

	if (loading.bag || loading.electronic)
		return (
			<div className="ph-bag-loading-global-container">
				<BagModalCalculate loading={loading.bag || loading.electronic} />
			</div>
		);

	if (isBagData === false)
		return (
			<BagError
				data={{
					title: '¡Ha ocurrido un error!',
					description: 'Comuníquese con nuestro personal.',
				}}
			/>
		);

	if (isElectronicPrescription === false)
		return (
			<BagError
				data={{
					title: '¡Ha ocurrido un error!',
					description: 'Comuníquese con nuestro personal.',
				}}
			/>
		);

	return (
		<>
			<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			<div className="ph-bag-global-container">
				<div className="ph-bag-main-container">
					<CheckoutTimeLine />
					<div className="ph-bag-main-title-container">
						<CheckoutGlobalTitle image={'bag'} title={'Tu Bolsa'} subtitle1={'Total'} subtitle2={'productos'} />
						<div className="ph-bag-countdown-static-container">
							<ClockCircleOutlined className="ph-bag-countdown-static-icon" />
							<span className="ph-bag-countdown-static-description">5:00</span>
						</div>
					</div>
					<Row>
						<Col xs={24} sm={24} md={24} lg={17} xl={17}>
							{isBag.length <= 0 ? (
								<NoProducts />
							) : (
								<>
									{isBagData ? (
										<BagProductsGroup cartForm={cartForm} productsSelected={productsSelected} setProductsSelected={setProductsSelected} />
									) : (
										<Loading />
									)}
								</>
							)}
							<ElectronicProductcs
								cartForm={cartForm}
								isType={isType}
								isAddedCart={isAddedCart}
								isMobile={isMobile}
								products={isElectronicPrescription.products}
								productsSelected={productsSelected}
								setProductsSelected={setProductsSelected}
							/>
						</Col>
						<Col xs={24} sm={24} md={24} lg={7} xl={7}>
							<div className={`${isBagCollapse ? 'ph-bag-main-info-box-collapsed' : 'ph-bag-main-info-box-collapse'} ph-bag-right-side-container`}>
								<BagMainInfo isType={isType} cartForm={cartForm} />
							</div>
							{isMobile && <BagMainInfoMobile cartForm={cartForm} />}
							<BagAlliesInfo />
						</Col>
					</Row>
					<BagBanners />
				</div>
			</div>
		</>
	);
}
