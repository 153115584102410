/** @format */

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Checkbox, Form, Layout, Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import InputField from '../../../components/Commons/Input';
import MetaDescription from '../../../components/Commons/MetaDescription';
import Spacer from '../../../components/Commons/Spacer';
import CustomButton from '../../../v2-components/Antd/Button';
import { userRegisterService } from './services';
import { IMAGES } from '../../../v2-config/constants/images.constant';
import './style.scss';

const Register = (props) => {
	const { t } = useTranslation('global');
	const [isLoading, setLoading] = useState(false);

	const handleRegisterUser = async (item) => {
		setLoading(true);

		await userRegisterService(item).then(async (response) => {
			if (response) {
				setLoading(false);
				props.history.push('/auth/token');
			}
		});
	};

	useEffect(() => {
		if (localStorage.getItem('userEmail') && localStorage.getItem('userToken')) {
			props.history.push('/');
		}
	}, [props.history]);

	return (
		<>
			<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			<Layout className="ph-auth-register-main-container">
				<Row justify="center" className="ph-auth-background">
					<Col md={12} xs={22}>
						<Form name="normal_login" initialValues={{ remember: true }} onFinish={handleRegisterUser} className="ph-auth-register-form-main-container">
							<div className="ph-auth-register-body-container">
								<Row justify="center">
									<img src={IMAGES.LOGO_LIGHT_BACKGROUND} alt="logo" className="logo" height={42} />
								</Row>
								<div className="ph-auth-register-title-container">
									<div>
										<h2 className="ph-auth-register-title">¡Regístrate!</h2>
										<h3 className="ph-auth-register-subtitle">{t('login.subtitle')}</h3>
									</div>
								</div>
								<div className="ph-auth-register-form-container">
									<InputField
										className={'ph-auth-register-field-input'}
										inputName={'first_name'}
										inputNameLabel={'Nombre'}
										inputNameRule={true}
										inputNameMessage={'Ingresa tu nombre'}
										inputNameType={'text'}
										inputNameIcon={''}
										inputNameRules={'rulesFirstName'}
									/>
									<InputField
										className={'ph-auth-register-field-input'}
										inputName={'last_name'}
										inputNameLabel={'Apellido'}
										inputNameRule={true}
										inputNameMessage={'Ingresa tu apellido'}
										inputNameType={'text'}
										inputNameIcon={''}
										inputNameRules={'rulesLastName'}
									/>
									<InputField
										className={'ph-auth-register-field-input'}
										inputName={'email'}
										inputNameLabel={'E-mail'}
										inputNameRule={true}
										inputNameMessage={'E-mail es obligatorio'}
										inputNameType={'text'}
										inputNameIcon={''}
										inputNameRules={'rulesEmail'}
									/>
									<InputField
										className={'ph-auth-register-field-input'}
										inputName={'confirmEmail'}
										inputNameLabel={'Confirmar E-mail'}
										inputNameRule={true}
										inputNameMessage={'Ingrese su E-mail'}
										inputNameType={'text'}
										inputNameIcon={''}
										inputNameRules={'confirmEmail'}
									/>
									<InputField
										className={'ph-auth-register-field-input'}
										inputName={'password'}
										inputNameLabel={'Contraseña'}
										inputNameRule={true}
										inputNameMessage={'Ingresa tu contraseña'}
										inputNameType={'password'}
										inputNameIcon={''}
										inputNameRules={'rulesPassword'}
									/>
									<InputField
										className={'ph-auth-register-field-input'}
										inputName={'confirm'}
										inputNameLabel={'Confirmar Contraseña'}
										inputNameRule={true}
										inputNameMessage={'Ingresa tu contraseña'}
										inputNameType={'password'}
										inputNameIcon={''}
										dependencies={['password']}
										hasFeedback
										inputNameRules={'confirmPassword'}
									/>
								</div>
								<div className="ph-auth-register-checkbox-container">
									<Form.Item
										name="agreement"
										valuePropName="checked"
										rules={[
											{
												validator: (_, value) => (value ? Promise.resolve() : Promise.reject('Acepta las condiciones para continuar.')),
											},
										]}
									>
										<Checkbox>
											<Link className="ph-auth-register-checkbox-description" to={`/${t('register.TyCLink')}`}>
												Acepto los Términos y Condiciones
											</Link>
										</Checkbox>
									</Form.Item>
								</div>
								<div className="ph-auth-register-register-container">
									<h3 className="ph-auth-register-register-description">¿Ya tienes cuenta?</h3>
									<Link to="/auth/login">
										<h5 className="ph-auth-register-register-link-description">{t('login.registerLink')}</h5>
									</Link>
								</div>
							</div>
							<Spacer />
							<div className="ph-auth-register-footer-container">
								<div className="ph-auth-register-footer-button-home-container">
									<Link className="ph-auth-register-footer-button-home-inner-container" to="/">
										<ArrowLeftOutlined className="ph-auth-register-footer-button-home-icon" />
										<h3 className="ph-auth-register-footer-button-home-title">Volver</h3>
									</Link>
								</div>
								<Spacer />
								<div className="ph-auth-register-main-button-container">
									<Form.Item>
										<CustomButton className={'button-primary'} htmlType={'submit'} loadingSubmit={isLoading}>
											Registrarse
										</CustomButton>
									</Form.Item>
								</div>
							</div>
						</Form>
					</Col>
				</Row>
			</Layout>
		</>
	);
};
export default Register;
