import { ENV_ELASTIC_INDEX_SEARCH, ENV_ELASTIC_INDEX } from '../../../Hooks/Variables/Enviroment';
import { Enviroment } from '../../../../../_config/constants/enviroment-v2.constant';
export const Country = {
	CL: {
		handleIndex: () => {
			console.log('handleIndex CL', ENV_ELASTIC_INDEX);
			return ENV_ELASTIC_INDEX;
		},
		handleQuery: (query) => {
			return query;
		},
		handleFilters: (query) => {
			let filters = {
				pharmacy: Enviroment.ROOT_PHARMACY,
			};
			return filters;
		},
	},
	AR: {
		handleIndex: () => {
			return ENV_ELASTIC_INDEX_SEARCH;
		},
		handleQuery: (query) => {
			return query;
		},
		handleFilters: (query) => {
			let filters = {
				all: [
					{
						none: [
							{
								available: 0,
							},
						],
					},
				],
			};
			return filters;
		},
	},
};
