import { LAYOUT } from '../constants';
export const typeAuth = ({ path }) => {
	const routes = [
		{
			path: '/auth/login',
			type: 'auth',
		},
		{
			path: '/auth/register',
			type: 'auth',
		},
		{
			path: '/auth/recover-password',
			type: 'auth',
		},
		{
			path: '/auth/token',
			type: 'auth',
		},
	];

	let isAuth = LAYOUT.MAIN;

	for (let i = 0; i < routes.length; i++) {
		if (path.includes(routes[i].path)) {
			console.log(`La palabra '${routes[i]}' fue encontrada.`);
			isAuth = routes[i].type;
			break; // Rompe el ciclo una vez que se encuentra una coincidencia
		}
	}

	return isAuth;
};
