/** @format */

import React, { useState } from "react";

import { GlobalConsumer } from "../../../../../../../../components/Commons/Context/Global";
import { BagConsumer } from "../../../../../../../../components/Commons/Context/Bag";

import { Row, Col, Button, Modal, Form, Checkbox, Spin } from "antd";

import { LoadingOutlined } from "@ant-design/icons";

import InputField from "../../../../../../../../components/Commons/Input";
import Images from "../../../../../../../../components/Commons/Images";
import InputRut from "../../../../../../../../components/Commons/InputCountry/Rut";
import InputPhone from "../../../../../../../../components/Commons/InputCountry/Phone";
import InputMask from "../../../../../../../../components/Commons/InputMask";

import BagCarouselPBM from "./components/BagCarouselPBM";

import servicesPBM from "./services";

import BagTotal from "../../../../../../functions/BagTotal";

import { handleLocals } from "../../../../../../functions/BagCalculation/components/BagLocals";
import { Calculation } from "../../../../../../functions/BagCalculation/services";
import { handleCountPharmacies } from "../../../../../../functions/BagCountPharmacies";

import { GetUserData } from "../../../../../../../../components/Commons/Hooks/Functions/GetUserData";

import "./style.scss";

export default function BagPBM(props) {
  const { isLocation, isLoginUser, setBag } = GlobalConsumer();
  const {
    isBagData,
    setBagData,
    setBagTotalCart,
    isBagRetirementPharmacies,
    isBagDeliveryPharmacies,
    setBagRetirementPharmacies,
    setBagDeliveryPharmacies,
    isBagRetirement,
    setBagRetirement,
    setLoadingGroup,
    setBagRequest,
  } = BagConsumer();

  const [pbm_form] = Form.useForm();
  const [form_agree] = Form.useForm();
  const [isVisibleModalForm, setVisibleModalForm] = useState(false);
  const [isVisibleModaAgree, setVisibleModalAgree] = useState(false);
  const [isAgreement, setAgreement] = useState([]);
  const [isFields, setFields] = useState(null);
  const [isFormLoading, setFormLoading] = useState(false);

  const { handleBagTotal } = BagTotal();

  const handleSelectPBMAgrement = async (item, data, element, image, link) => {
    setVisibleModalForm(true);
    await servicesPBM.getFormsFields(item).then((response) => {
      if (response) {
        response.name = data;
        response.discount = element;
        response.image = image;
        response.link = link;
        setFields(response);
      } else {
        setVisibleModalForm(false);
      }
      let fields;
      response.campos.map((item) => {
        return (fields = { ...fields, [item.codigo]: "" });
      });
      pbm_form.setFieldsValue(fields);
    });
  };

  const handleCloseModalForm = () => {
    setFields(null);
    setVisibleModalForm(false);
  };

  const handleVerifyVoucherFields = () => {
    const fields = props.cartForm.getFieldsValue(true);
    for (const clave in fields) {
      if (!fields[clave]) delete fields[clave];
    }
    return fields;
  };

  const handleSubmitPBMFields = async (item) => {
    setBagRequest(true);
    setFormLoading(true);
    await servicesPBM
      .getPBMAgreement(item, props.item, isFields)
      .then(async (response) => {
        if (response) {
          setAgreement(response.messajes);
          setVisibleModalForm(false);
          setVisibleModalAgree(true);
          let filterProducts = response.cart.filter(
            (element) => element.pharmacy === props.item.pharmacy
          );
          setLoadingGroup((prevState) => ({
            ...prevState,
            [props.item.pharmacy]: true,
          }));
          filterProducts.forEach((item) => {
            delete item.type_retirement;
          });
          let localGroupPharmacy;

          let userData;
          if (isLoginUser?.email)
            userData = await GetUserData(isLoginUser.email);

          const voucherPharmacies = handleVerifyVoucherFields();

          await Calculation(
            filterProducts,
            isLocation,
            isLoginUser.email,
            isLoginUser.token,
            voucherPharmacies,
            handleLocals(filterProducts),
            "domicilio",
            userData,
            false,
            handleCountPharmacies()
          ).then((response) => {
            let deliveryPharmacies = isBagDeliveryPharmacies;
            deliveryPharmacies[filterProducts[0].pharmacy] =
              response && response.locals_group[0].locals.length > 0
                ? response.locals_group[0]
                : false;
            setBagDeliveryPharmacies({ ...deliveryPharmacies });
          });

          await Calculation(
            filterProducts,
            isLocation,
            isLoginUser.email,
            isLoginUser.token,
            voucherPharmacies,
            handleLocals(filterProducts),
            "farmacia",
            userData,
            false,
            handleCountPharmacies()
          ).then((response) => {
            localGroupPharmacy = response.locals_group[0];
            let retirementPharmacies = isBagRetirementPharmacies;
            retirementPharmacies[filterProducts[0].pharmacy] =
              response && response.locals_group[0].locals.length > 0
                ? response.locals_group[0]
                : false;
            setBagRetirementPharmacies({ ...retirementPharmacies });
          });

          const validRetirement = isBagRetirement;
          let locals_group = isBagData.locals_group.map((item) => {
            if (item.name === filterProducts[0].pharmacy) {
              return localGroupPharmacy;
            }
            return item;
          });
          validRetirement[filterProducts[0].pharmacy] = {
            info: filterProducts,
            deliveryStatus: false,
            type_retirement: null,
          };
          setBagRetirement({ ...validRetirement });
          const bagData = { ...isBagData, locals_group: [...locals_group] };
          setBagData(bagData);
          setBagTotalCart(handleBagTotal(bagData));
          setBag(response.cart);
          setLoadingGroup((prevState) => ({
            ...prevState,
            [props.item.pharmacy]: false,
          }));
        }
      });
    setFormLoading(false);
    setBagRequest(false);
  };

  const handleSubmitAgreePBM = () => {
    setAgreement([]);
    form_agree.resetFields();
    setVisibleModalAgree(false);
  };

  const handleDeletePBM = async (item) => {
    await servicesPBM.deletePBMObject(item).then(async (cart) => {
      if (cart) {
        let filterProducts = cart.filter(
          (element) => element.pharmacy === item.pharmacy
        );
        setLoadingGroup((prevState) => ({
          ...prevState,
          [item.pharmacy]: true,
        }));
        filterProducts.forEach((item) => {
          delete item.type_retirement;
        });
        let localGroupPharmacy;

        let userData;
        if (isLoginUser?.email) userData = await GetUserData(isLoginUser.email);

        const voucherPharmacies = handleVerifyVoucherFields();

        await Calculation(
          filterProducts,
          isLocation,
          isLoginUser.email,
          isLoginUser.token,
          voucherPharmacies,
          handleLocals(filterProducts),
          "domicilio",
          userData,
          false,
          handleCountPharmacies()
        ).then((response) => {
          let deliveryPharmacies = isBagDeliveryPharmacies;
          deliveryPharmacies[filterProducts[0].pharmacy] =
            response && response.locals_group[0].locals.length > 0
              ? response.locals_group[0]
              : false;
          setBagDeliveryPharmacies({ ...deliveryPharmacies });
        });

        await Calculation(
          filterProducts,
          isLocation,
          isLoginUser.email,
          isLoginUser.token,
          voucherPharmacies,
          handleLocals(filterProducts),
          "farmacia",
          userData,
          false,
          handleCountPharmacies()
        ).then((response) => {
          localGroupPharmacy = response.locals_group[0];
          let retirementPharmacies = isBagRetirementPharmacies;
          retirementPharmacies[filterProducts[0].pharmacy] =
            response && response.locals_group[0].locals.length > 0
              ? response.locals_group[0]
              : false;
          setBagRetirementPharmacies({ ...retirementPharmacies });
        });

        const validRetirement = isBagRetirement;
        let locals_group = isBagData.locals_group.map((item) => {
          if (item.name === filterProducts[0].pharmacy) {
            return localGroupPharmacy;
          }
          return item;
        });
        validRetirement[filterProducts[0].pharmacy] = {
          info: filterProducts,
          deliveryStatus: false,
          type_retirement: null,
        };
        setBagRetirement({ ...validRetirement });
        const bagData = { ...isBagData, locals_group: [...locals_group] };
        setBagData(bagData);
        setBagTotalCart(handleBagTotal(bagData));
        setBag(cart);
        setLoadingGroup((prevState) => ({
          ...prevState,
          [item.pharmacy]: false,
        }));
      }
    });
  };

  return (
    <>
      <Row className="ph-bag-pbm-main-container">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h4 className="ph-bag-pbm-main-title">Convenios disponibles:</h4>
          <BagCarouselPBM
            carouselItems={[2, 2, 2, 1, 1]}
            item={props.item}
            handlePBMDelete={(item) => handleDeletePBM(item)}
            handlePBMAgrement={(id, name, discount, image, link) =>
              handleSelectPBMAgrement(id, name, discount, image, link)
            }
          />
        </Col>
      </Row>

      <Modal
        wrapClassName="ph-bag-pbm-form-modal-container"
        maskClosable={false}
        closable={isFields ? true : false}
        centered
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        title={`${
          isFields
            ? isFields.name +
              " (" +
              isFields.convenio +
              ") - " +
              isFields.discount
            : ""
        }`}
        visible={isVisibleModalForm}
        onCancel={() => handleCloseModalForm()}
      >
        <Images
          classContent={"ph-bag-pbm-form-modal-container-main-image-container"}
          classImage={"ph-bag-pbm-form-modal-container-main-image"}
          default={isFields ? isFields.image : ""}
          title={isFields ? isFields.name : ""}
        />
        {isFields ? (
          <>
            <Form
              onFinish={handleSubmitPBMFields}
              name="pbm_form"
              form={pbm_form}
            >
              {isFields.campos.map((item, index) => (
                <div key={index}>
                  <h4 className="ph-bag-pbm-form-modal-main-title">
                    {item.nombre}
                  </h4>
                  {item.codigo === "BENEF_RUT" ||
                  item.codigo === "MEDICO_RUT" ? (
                    <>
                      <InputRut
                        inputRUT={item.codigo}
                        classInput={"ph-retirement-personal-data-field-input"}
                      />
                    </>
                  ) : item.codigo === "BENEF_TEL" ? (
                    <InputPhone
                      inputPhone={item.codigo}
                      classInput={"ph-retirement-personal-data-field-input"}
                    />
                  ) : item.codigo === "FECHA_RECETA" ? (
                    <Form.Item name={item.codigo}>
                      <InputMask
                        maskstyle="ph-retirement-personal-data-field-input"
                        mask="99/99/9999"
                        placeholder="01/01/2021"
                      />
                    </Form.Item>
                  ) : (
                    <InputField
                      className={"ph-retirement-personal-delivery-field-input"}
                      inputName={item.codigo}
                      inputNameLabel={item.nombre}
                      inputNameRule={true}
                      inputNameMessage={"Ingresa un valor"}
                      inputNameType={"text"}
                      inputNameRules={`${
                        item.codigo === "BENEF_EMAI"
                          ? "rulesEmail"
                          : item.codigo === "NUM_CUPOM"
                          ? "rulesCupon"
                          : "rulesFirstName"
                      }`}
                      disabled={false}
                    />
                  )}
                </div>
              ))}
              <h4 className="ph-bag-pbm-covenant-form-benefict-link-title">
                Pulsa{" "}
                <a
                  href={isFields.link}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="ph-bag-pbm-covenant-form-benefict-link"
                >
                  aqui
                </a>{" "}
                para obtener el cupón
              </h4>

              <div className="ph-bag-pbm-covenant-form-button-container">
                <Button
                  className="ph-bag-pbm-covenant-form-button"
                  htmlType={"submit"}
                  loading={isFormLoading}
                >
                  Aceptar
                </Button>
              </div>
            </Form>
          </>
        ) : (
          <div className="ph-bag-pbm-form-modal-loading-container">
            <div className="ph-bag-pbm-form-modal-loading-inner-container">
              <Spin
                indicator={
                  <LoadingOutlined className="ph-bag-pbm-form-modal-loading-image" />
                }
                className="ph-bag-pbm-form-modal-loading-image-container"
              />
              <h4 className="ph-bag-pbm-form-modal-loading-wait-title">
                Consultando el Convenio...
              </h4>
            </div>
          </div>
        )}
      </Modal>
      <Modal
        wrapClassName="ph-bag-pbm-agree-modal-container"
        maskClosable={false}
        closable={false}
        centered
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        title="Condiciones:"
        width={650}
        visible={isVisibleModaAgree}
        onCancel={{ style: { display: "none" } }}
      >
        <Form
          onFinish={handleSubmitAgreePBM}
          name="pbm_agree_form"
          form={form_agree}
        >
          {isAgreement && (
            <div className="ph-bag-pbm-agree-modal-description-container">
              {isAgreement.map((item, index) => (
                <span key={index}>{item.linea}</span>
              ))}
            </div>
          )}
          <div className="ph-bag-pbm-agree-modal-checkbox">
            <Form.Item
              name="agreement_pbm"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          "Acepta las condiciones para continuar."
                        ),
                },
              ]}
            >
              <Checkbox className="ph-bag-pbm-agree-modal-checkbox-description">
                Acepto los Términos y Condiciones
              </Checkbox>
            </Form.Item>
          </div>
          <div className="ph-bag-pbm-agree-modal-button-container">
            <Button
              className="ph-bag-pbm-covenant-form-button"
              htmlType={"submit"}
            >
              Aceptar
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
