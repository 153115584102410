/** @format */

import React, { useEffect, useState } from 'react';
import { Enviroment } from '../../_config/constants/enviroment-v2.constant';
import { PathImage } from './constants/image.constant';

export default function SquareProductImage(props) {
	const [imgSrc, setImgSrc] = useState(props.default);

	const handleImageError = (e) => {
		setImgSrc(PathImage[Enviroment.COUNTRY]);
		return PathImage[Enviroment.COUNTRY];
	};
	useEffect(() => {
		setImgSrc(props.default);
	}, [props.default]);

	return (
		<>
			<img src={imgSrc} onError={handleImageError} alt={props.alt} {...props} />
		</>
	);
}
