/** @format */

import React, { Component } from 'react';

import { formatPrice } from '../../../../../../../../components/Commons/Hooks/Functions/FormatPrice';

import './style.scss';

export default class PriceUnit extends Component {
	render() {
		return (
			<>
				{this.props.item.interface && (
					<div className="ph-order-detail-products-item-price-main-container">
						<h3 className="ph-order-detail-products-item-price-main-title">Precio por unidad:</h3>
						{this.props.item.interface.promotions ? (
							<>
								<p className={this.props.item.interface.promotions.price_promotion ? 'ph-order-detail-products-item-price-before-symbol' : 'ph-order-detail-products-item-price-normal-symbol'}></p>
								<p className={this.props.item.interface.promotions.price_promotion ? 'ph-order-detail-products-item-price-before' : 'ph-order-detail-products-item-price-normal'}>
									{this.props.item.price && <>{formatPrice(this.props.item.price)}</>}
								</p>
							</>
						) : (
							<>
								<p className="ph-order-detail-products-item-price-normal-symbol">$</p>
								<p className="ph-order-detail-products-item-price-normal">{this.props.item.price && <>{formatPrice(this.props.item.price)} </>}</p>
							</>
						)}
						{this.props.item.interface.promotions && (
							<>
								{(this.props.item.interface.promotions.description_promotion || this.props.item.interface.promotions.percentaje_promotion || this.props.item.interface.promotions.price_promotion) && (
									<>
										<div className="ph-order-detail-products-item-price-spacer"></div>
									</>
								)}
								<div className="ph-order-detail-products-item-price-promotion">
									{this.props.item.interface.promotions.description_promotion && <>{this.props.item.interface.promotions.description_promotion}</>}
									{this.props.item.interface.promotions.percentaje_promotion && <>{this.props.item.interface.promotions.percentaje_promotion}</>}
									{this.props.item.interface.promotions.price_promotion && (
										<>
											<div className="ph-order-detail-products-item-price-promotion-symbol">{this.props.item.interface.promotions.price_promotion.symbol}</div>
											{this.props.item.price && <>{formatPrice(this.props.item.interface.promotions.price_promotion.amount)}</>}
										</>
									)}
								</div>
							</>
						)}
					</div>
				)}
			</>
		);
	}
}
