import React from 'react';
import { Input } from 'antd';
import './style.scss';
const { TextArea } = Input;

export default function CustomTextArea(props) {
	return (
		<>
			<TextArea {...props} />
		</>
	);
}
