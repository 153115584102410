/** @format */

import axios from 'axios';
import Notify from '../../../../components/Commons/Notify';
import { ENV_COMMON, ENV_COUNTRY, ENV_ROOT_PHARMACY, ENV_AGREEMENT } from '../../../../components/Commons/Hooks/Variables/Enviroment';
import { ScreenWidth } from '../../../../components/Commons/Hooks/Functions/ScreenWidth';
import { RutFormat } from '../../../../components/Commons/Hooks/Functions/RutFormat';

export const GetProduct = async (item, data) => {
	let returnResponse;
	let metaTitle;
	await axios({
		method: 'GET',
		url: `${ENV_COMMON}/product/${ENV_COUNTRY}/${item}`,
		params: {
			mobile: ScreenWidth(),
			root_pharmacy: ENV_ROOT_PHARMACY,
			lat: data.lat,
			lng: data.lng,
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				if (response.data.data.details[0].pharmacy) {
					metaTitle =
						response.data.data.details[0].meta_title +
						'  - Farmacias ' +
						response.data.data.details[0].pharmacy.charAt(0).toUpperCase() +
						response.data.data.details[0].pharmacy.slice(1);
				} else {
					metaTitle = `${response.data.data.details[0].meta_title}`;
				}
				returnResponse = {
					metaTitle: metaTitle,
					description: response.data.data,
					searchInsight: response.data.data.details[0].id,
				};
			} else {
				Notify({
					title: `Error`,
					colorTitle: 'ph-main-notification-error-title',
					description: '¡Producto no encontrado!',
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			}
		})
		.catch(() => {
			Notify({
				title: `Error`,
				colorTitle: 'ph-main-notification-error-title',
				description: 'Verifique su conexión a internet.',
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
};

export const GetCovenants = async (item, data) => {
	let info = {
		nid: data?.rut ? RutFormat(data.rut) : null,
		product: { ...item },
	};

	return await axios({
		method: 'POST',
		url: `${ENV_AGREEMENT}/aggrement-by-product/${ENV_COUNTRY}`,
		data: info,
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				return response.data.data;
			} else {
				Notify({
					title: `Error`,
					colorTitle: 'ph-main-notification-error-title',
					description: '¡Ha ocurrido un error consultando los convenios!',
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			}
		})
		.catch(() => {
			Notify({
				title: `Error`,
				colorTitle: 'ph-main-notification-error-title',
				description: 'Verifique su conexión a internet.',
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
		});
};
