/** @format */

import { Row, Col, Button, notification } from 'antd';

import './style.scss';

export const DispatchLoginNotify = (item) => {
	const HandleLoginLink = () => {
		notification.close(1);
		item.push('/auth/login');
	};

	const HandleRegisterLink = () => {
		notification.close(1);
		item.push('/auth/register');
	};

	notification.open({
		duration: 6,
		className: 'ph-notification-calculate-delivery-access-global-container',
		description: (
			<>
				<div className="ph-notification-calculate-delivery-access-title-container">
					<h3 className="ph-notification-calculate-delivery-access-title">¿Deseas calcular tu fecha y costo de entrega?</h3>
				</div>
				<div className="ph-notification-calculate-delivery-access-description-container">
					<h3 className="ph-notification-calculate-delivery-access-description">
						Para poder disfrutar de este servicio primero debes iniciar sesión o registrarte:
					</h3>
				</div>
				<Row className="ph-notification-calculate-delivery-access-actions-container">
					<Col span={12} className="ph-notification-calculate-delivery-access-actions-col">
						<Button onClick={() => HandleLoginLink()} className="ph-notification-calculate-delivery-access-button-login">
							Iniciar Sesión
						</Button>
					</Col>
					<Col span={12} className="ph-notification-calculate-delivery-access-actions-col">
						<Button onClick={() => HandleRegisterLink()} className="ph-notification-calculate-delivery-access-button-register">
							Regístrate
						</Button>
					</Col>
				</Row>
			</>
		),
		key: 1,
		placement: 'topRight',
	});
};
