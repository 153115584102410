/** @format */

import React from 'react'
import CountryValidator from '../Commons/CountryValidator'
import './style.scss'

export default function TimelineCategory(props) {
	return (
		<CountryValidator 
			countries={['CL']}
			component={
				<ul className='ph-product-category-timeline-main-container'>
					<li className='ph-product-category-timeline-list-container'>
						<a
							className='ph-product-category-timeline-master-category-title'
							href='/'
							rel='noopener noreferrer'>
							Inicio
						</a>
					</li>
					{props.item.laboratory && (
						<li className='ph-product-category-timeline-list-container'>
							<span className='ph-product-category-timeline-master-category-separator'>{`>`}</span>
							<span className='ph-product-category-timeline-master-category-subtitle'>
								{props.item.laboratory}
							</span>
						</li>
					)}
					{props.item.pharmacy && (
						<li className='ph-product-category-timeline-list-container'>
							<span className='ph-product-category-timeline-master-category-separator'>{`>`}</span>
							<span className='ph-product-category-timeline-master-category-subtitle'>
								{props.item.pharmacy}
							</span>
						</li>
					)}
					{props.item.category && (
						<li className='ph-product-category-timeline-list-container'>
							<span className='ph-product-category-timeline-master-category-separator'>{`>`}</span>
							<span className='ph-product-category-timeline-master-category-subtitle'>
								{props.item.category}
							</span>
						</li>
					)}
					{props.item.subCategory && (
						<li className='ph-product-category-timeline-list-container'>
							<span className='ph-product-category-timeline-master-category-separator'>{`>`}</span>
							<span className='ph-product-category-timeline-master-category-subtitle'>
								{props.item.subCategory}
							</span>
						</li>
					)}
					{props.item.query && (
						<li className='ph-product-category-timeline-list-container'>
							<span className='ph-product-category-timeline-master-category-separator'>{`>`}</span>
							<span className='ph-product-category-timeline-master-category-subtitle'>
								{props.item.query}
							</span>
						</li>
					)}
				</ul>
			}
		/>
	)
}
