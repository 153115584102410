import { notification } from 'antd';
import './style.scss';

export const CustomNotification = ({ type, message, description, duration = 4.5 }) => {
	return notification[type]({
		message: message,
		description: description,
		duration: duration,
	});
};
