/** @format */

import React from 'react';
import { setGlobal } from 'reactn';
import { GlobalConsumer } from '../../../Commons/Context/Global';
import { Button } from 'antd';
import { FilterOutlined } from '@ant-design/icons';

import './style.scss';

export default function Actions() {
	const { isMobile } = GlobalConsumer();

	return (
		<>
			{isMobile && (
				<div className="ph-elastic-order-view-main-container">
					<h3 className="ph-elastic-order-view-title-responsive">Filtrar:</h3>
					<Button
						className="ph-elastic-filter-sidebar-button"
						onClick={() =>
							setGlobal({
								algoliaSidebar: true,
							})
						}
					>
						<FilterOutlined />
					</Button>
				</div>
			)}
		</>
	);
}
