/** @format */

import React from 'react';

import { Card } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';

import Images from '../../../../components/Commons/Images';

import { ENV_IMG } from '../../../../components/Commons/Hooks/Variables/Enviroment';

import './style.scss';

export default function PaymentCard(props) {
	return (
		<div className="ph-payment-card-main-container">
			{(!props.active || (props.isPaymentExcedent && props.isPaymentExcedent.cover_payment)) && <div className="ph-payment-card-disable-container"></div>}
			{!props.available && (
				<div className="ph-payment-card-no-available-container">
					<Images
						classContent={'ph-payment-card-no-available-image-container'}
						classImage={`ph-payment-card-no-available-image`}
						default={`${ENV_IMG}/bag/bolsa-triste.png`}
						alt={'Promotion'}
						title={'Promotion'}
					/>
					<h4 className="ph-payment-card-no-available-title">Deshabilitado temporalmente</h4>
					<h4 className="ph-payment-card-no-available-subtitle">Volvemos en un momento.</h4>
				</div>
			)}
			<Card className="ph-payment-card-container" onClick={() => props.selectPayment(props.paymentMethod)}>
				<Images
					classImage={`${!props.active || (props.isPaymentExcedent && props.isPaymentExcedent.cover_payment) ? 'ph-payment-methods-image-disable' : ''} ph-payment-methods-image`}
					default={props.image}
					alt={props.name}
					title={props.name}
				/>
				{props.paymentMethod === props.isDiscountName && (
					<div className="ph-payment-card-discount-container">
						<Images
							classImage={`ph-payment-card-discount-image`}
							webp={`${ENV_IMG}/react-promotion/promotion-icon.webp`}
							jp2={`${ENV_IMG}/react-promotion/promotion-icon.jp2`}
							jxr={`${ENV_IMG}/react-promotion/promotion-icon.jxr`}
							default={`${ENV_IMG}/react-promotion/promotion-icon.png`}
							alt={'Promotion'}
							title={'Promotion'}
						/>
						<h4 className="ph-payment-card-discount-title">¡Descuento!</h4>
					</div>
				)}
				<CheckCircleFilled className={`${props.paymentSelected === props.paymentMethod ? 'ph-payment-card-icon-active' : 'ph-payment-card-icon-disabled'} ph-payment-card-icon`} />
			</Card>
		</div>
	);
}
