/** @format */

import axios from 'axios'
import Notify from '../../components/Commons/Notify'
//import { useTranslation } from 'react-i18next'

import { ENV_COUNTRY, ENV_TRACKING } from '../../components/Commons/Hooks/Variables/Enviroment'

export default async function SearchOrderService(item) {
	//const { t } = useTranslation('global')
	let returnResponse
	let rutSplitted = item?.rut?.split('-')
	rutSplitted[1] =
		rutSplitted[1] === 'k' || rutSplitted[1] === 'K'
			? rutSplitted[1].toUpperCase()
			: rutSplitted[1]
	item.rut = rutSplitted.join('').replace(/[.]/g, '')
	await axios({
		method: 'POST',
		url: `${ENV_TRACKING}/general/one-tracking/${ENV_COUNTRY}`,
		data: {
			trx_id: item.order,
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				let formatIdNumber = response.data.data.order[0].info.rut.replace(/[.-]/g, "")
				if (item.rut !== formatIdNumber) {
					Notify({
						title: `Advertencia:`,
						colorTitle: 'ph-main-notification-warning-title',
						description:  ENV_COUNTRY === 'AR' ? "El DNI y número de orden no coinciden." :"RUT ingresado y RUT de la orden no coinciden." ,//`${t('tracking.errorDescription')}` PARCHE FABO
						time: 3,
						image: 'error',
						placement: 'topRight',
					})
				} else {
					returnResponse = response.data.data
				}
			} else {
				Notify({
					title: `${response.data.error} - ${response.data.statusCode}`,
					colorTitle: 'ph-main-notification-warning-title',
					description: `${response.data.message}`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				})
			}
		})
		.catch((error) => {
			if (error) {
				if (error.response) {
					if (error.response.status === 404) {
						Notify({
							title: `Error: ${error.response.status}`,
							colorTitle: 'ph-main-notification-error-title',
							description: `Problemas con el servicio: ${error.response.data.message}`,
							time: 3,
							image: 'error',
							placement: 'topRight',
						})
					} else if (error.response.status === 500) {
						Notify({
							title: `No Encontrado:`,
							colorTitle: 'ph-main-notification-warning-title',
							description: `Número de Orden no encontrado`,
							time: 3,
							image: 'error',
							placement: 'topRight',
						})
					} else {
						Notify({
							title: `Error Servicio ${error.response.status}`,
							colorTitle: 'ph-main-notification-error-title',
							description: '¡Problemas con el Servicio!',
							time: 3,
							image: 'error',
							placement: 'topRight',
						})
					}
				} else {
					Notify({
						title: `Error!`,
						colorTitle: 'ph-main-notification-error-title',
						description: 'Consulte su conexión a internet.',
						time: 3,
						image: 'error',
						placement: 'topRight',
					})
				}
			} else {
				Notify({
					title: `Error!`,
					colorTitle: 'ph-main-notification-error-title',
					description: 'Consulte su conexión a internet.',
					time: 3,
					image: 'error',
					placement: 'topRight',
				})
			}
		})
	return returnResponse
}
