/** @format */

import React from 'react'
import { ENV_COUNTRY } from '../../../../../../../../../../../../components/Commons/Hooks/Variables/Enviroment/index'
import './style.scss'

export default function ProductPromotions(props) {
	return (
		<div className={`${props.styleContainer ? props.styleContainer : 'ph-product-detail-quote-promotion-container'}`}>
			<p className={`${props.styleText ? props.styleText : 'ph-product-detail-quote-promotion-title'}`}>
				{props.item.interface.promotions ? (props.item.interface.promotions?.percentaje_promotion ||
					props.item.interface.promotions?.title)+`${(ENV_COUNTRY === "AR" && props.item.promotions.rules.mecanic === "X % Descuento") ? " OFF" : ""}` 
					: "-"
					} 
			</p>
		</div>
	)
}
