import { Events } from './events.constant';
export const ArrayPharmacyProductAdapter = async ({ event = Events.view_item, products }) => {
	const itemsMap = await products.map((iterator, key) => {
		return {
			item_name: iterator.editable_name,
			item_id: `${iterator.pharmacy}-${iterator.code_internal}`,
			item_brand: iterator.laboratory,
			item_category: iterator.master_category,
			item_category2: iterator.category,
			item_category3: iterator.sub_category,
			quantity: iterator.stock,
			price: iterator.price,
			index: key,
		};
	});
	console.log('ArrayPharmacyProductAdapter', { event, products, itemsMap });
	window.dataLayer.push({ ecommerce: null });
	window.dataLayer.push({
		event: event,
		ecommerce: {
			items: itemsMap,
		},
	});
};
