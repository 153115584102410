/** @format */

import React, { Component } from 'react'
import { CheckCircleOutlined, StopOutlined } from '@ant-design/icons'

import './style.scss'

export default class Observations extends Component {
	render() {
		return (
			<>
				<div className='ph-order-detail-products-observation-container'>
					{this.props.item.validation_product === 'RA' && (
						<>
							<h3 className='ph-order-detail-products-observation-title-aproved'>
								Receta Aprobada
							</h3>
							<CheckCircleOutlined className='ph-order-detail-products-observation-icon-aproved' />
						</>
					)}
					{this.props.item.validation_product === 'RI' && (
						<>
							<h3 className='ph-order-detail-products-observation-title-refused'>
								Receta Rechazada
							</h3>
							<StopOutlined className='ph-order-detail-products-observation-icon-refused' />
						</>
					)}
				</div>
			</>
		)
	}
}
