/** @format */
import axios from 'axios'

import { setGlobal } from 'reactn'

import Notify from '../../Notify'

import { ENV_COUNTRY, ENV_COMMON, ENV_ROOT_PHARMACY } from '../../Hooks/Variables/Enviroment'

export const MenuInit = async () => {
	let returnResponse
	const user = JSON.parse(localStorage.getItem('locationStore'))
	await axios({
		method: 'GET',
		url: `${ENV_COMMON}/header/${ENV_COUNTRY}`,
		params: {
			root_pharmacy: ENV_ROOT_PHARMACY,
			lat: user.lat,
			lng: user.lng,
		},
	})
		.then(function (response) {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data
				setGlobal({ mainLogo: response.data.data.logo })
			} else {
				Notify({
					title: `Error ${response.data.statusCode}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `${response.data.message}`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				})
			}
		})
		.catch(() => {
			Notify({
				title: `Error Servicio REACT_APP_FUNCTION_FRONT_COMMON`,
				colorTitle: 'ph-main-notification-error-title',
				description: 'Problemas con el servicio, Header - Init.',
				time: 3,
				image: 'error',
				placement: 'topRight',
			})
		})
	return returnResponse
}
