export const coverageList = [
	{
		label: 'Particular',
		options: [{ label: 'Particular', value: 'particular' }],
	}
];

export const coverageTemp = [
	{
		label: 'Obras Sociales',
		options: [
			{ label: 'OSPIC SANTA FE', value: 'OSPIC SANTA FE' },
			{ label: 'ACTIVA SALUD', value: 'ACTIVA SALUD' },
			{ label: 'ADOS ROSARIO', value: 'ADOS ROSARIO' },
			{ label: 'AGRUPACIÓN SANATORIAL SUR', value: 'AGRUPACIÓN SANATORIAL SUR' },
			{ label: 'ALTA SALUD', value: 'ALTA SALUD' },
			{ label: 'AMPAR', value: 'AMPAR' },
			{ label: 'APDIS', value: 'APDIS' },
			{ label: 'APDJIC', value: 'APDJIC' },
			{ label: 'APSOT', value: 'APSOT' },
			{ label: 'ARBOS', value: 'ARBOS' },
			{ label: 'ASE', value: 'ASE' },
			{ label: 'ASMEPRIV', value: 'ASMEPRIV' },
			{ label: 'ASOC. DE OBRAS SOCIALES DE BELLA VISTA', value: 'ASOC. DE OBRAS SOCIALES DE BELLA VISTA' },
			{ label: 'ASOC. DE OBRAS SOCIALES DE COMODORO RIVADAVIA', value: 'ASOC. DE OBRAS SOCIALES DE COMODORO RIVADAVIA' },
			{ label: 'ASOC. DE OBRAS SOCIALES DE SAN JUAN', value: 'ASOC. DE OBRAS SOCIALES DE SAN JUAN' },
			{ label: 'ASOC. DE OBRAS SOCIALES DE TRELEW', value: 'ASOC. DE OBRAS SOCIALES DE TRELEW' },
			{
				label: 'ASOC. DE PRESTACIONES SOCIALES PARA EMPRESARIOS Y PERSONAL DE DIRECCION DE EMPRESAS DE LA PRODUCCION,INDUSTRIA,COMERCIO Y SERVICIOS',
				value: 'ASOC. DE PRESTACIONES SOCIALES PARA EMPRESARIOS Y PERSONAL DE DIRECCION DE EMPRESAS DE LA PRODUCCION,INDUSTRIA,COMERCIO Y SERVICIOS',
			},
			{ label: 'ASOC. MUTUAL DGI', value: 'ASOC. MUTUAL DGI' },
			{ label: 'ASSPE', value: 'ASSPE' },
			{ label: 'AVALIAN', value: 'AVALIAN' },
			{ label: 'BASA SALUD', value: 'BASA SALUD' },
			{ label: 'BRISTOL', value: 'BRISTOL' },
			{ label: 'CAMPSIC', value: 'CAMPSIC' },
			{ label: 'CASA SALUD', value: 'CASA SALUD' },
			{ label: 'CELIUS SALUD', value: 'CELIUS SALUD' },
			{ label: 'CEMIC', value: 'CEMIC' },
			{ label: 'CENTRO MÉDICO PUEYRREDON', value: 'CENTRO MÉDICO PUEYRREDON' },
			{ label: 'COBERTURA PORTEÑA', value: 'COBERTURA PORTEÑA' },
			{ label: 'COMPIR', value: 'COMPIR' },
			{ label: 'CONSTRUIR SALUD -OSPECON', value: 'CONSTRUIR SALUD -OSPECON' },
			{ label: 'CORPORACION MEDICA ASISTENCIAL', value: 'CORPORACION MEDICA ASISTENCIAL' },
			{ label: 'DAS-CONGRESO', value: 'DAS-CONGRESO' },
			{ label: 'DIRECCION DE O.S.DE LA EMPRESA NAC. DE TELECOMUNICACIONES', value: 'DIRECCION DE O.S.DE LA EMPRESA NAC. DE TELECOMUNICACIONES' },
			{ label: 'DIRECCION GENERAL DE O.S.DEL MINISTERIO DE RELACIONES EXTERIORES Y CULTO', value: 'DIRECCION GENERAL DE O.S.DEL MINISTERIO DE RELACIONES EXTERIORES Y CULTO' },
			{ label: 'DIRECCION GENERAL DE O.S.DEL MINISTERIO DEL INTERIOR', value: 'DIRECCION GENERAL DE O.S.DEL MINISTERIO DEL INTERIOR' },
			{ label: 'DIRECCION GRAL. DE SERVICIOS ASISTENCIALES DE SERVICIOS ELECTRICOS DEL GBA', value: 'DIRECCION GRAL. DE SERVICIOS ASISTENCIALES DE SERVICIOS ELECTRICOS DEL GBA' },
			{ label: 'DITEM', value: 'DITEM' },
			{ label: 'DOSUBA', value: 'DOSUBA' },
			{ label: 'EMERGENCIAS', value: 'EMERGENCIAS' },
			{ label: 'FAA', value: 'FAA' },
			{ label: 'FEDECAMARAS', value: 'FEDECAMARAS' },
			{ label: 'FEDERADA SALUD', value: 'FEDERADA SALUD' },
			{ label: 'FEMEDICA', value: 'FEMEDICA' },
			{ label: 'FRUTOS', value: 'FRUTOS' },
			{ label: 'GALENO', value: 'GALENO' },
			{ label: 'HOMINIS', value: 'HOMINIS' },
			{ label: 'HOSP BRITANICO', value: 'HOSP BRITANICO' },
			{ label: 'HOSPITAL ALEMAN', value: 'HOSPITAL ALEMAN' },
			{ label: 'HOSPITAL ITALIANO', value: 'HOSPITAL ITALIANO' },
			{
				label: 'INS. NAC. DE OO. SS. PARA EL PERSONAL DE LA ADMINISTRACION PUBLICA NAC. Y SUS ENTES AUTARQUICOS Y DESCENTRALIZADOS',
				value: 'INS. NAC. DE OO. SS. PARA EL PERSONAL DE LA ADMINISTRACION PUBLICA NAC. Y SUS ENTES AUTARQUICOS Y DESCENTRALIZADOS',
			},
			{ label: 'INST. DE OBRA SOCIAL', value: 'INST. DE OBRA SOCIAL' },

			{
				label: 'INST. DE OS PARA EL PERSONAL DEL MINISTERIO DE ECONOMIA Y DE OBRAS Y SERVICIOS PÚBLICOS',
				value: 'INST. DE OS PARA EL PERSONAL DEL MINISTERIO DE ECONOMIA Y DE OBRAS Y SERVICIOS PÚBLICOS',
			},
			{ label: 'INST. DE SERVICIOS SOCIALES BANCARIOS', value: 'INST. DE SERVICIOS SOCIALES BANCARIOS' },
			{ label: 'INST. DE SERVICIOS SOCIALES PARA EL  PERSONAL DE LA CONSTRUCCION', value: 'INST. DE SERVICIOS SOCIALES PARA EL  PERSONAL DE LA CONSTRUCCION' },
			{ label: 'AGRUPACIÓN SANATORIAL SUR', value: 'AGRUPACIÓN SANATORIAL SUR' },
			{ label: 'INST. DE SERVICIOS SOCIALES PARA EL PERSONAL DE LA INDUSTRIA DE LA CARNE Y AFINES', value: 'INST. DE SERVICIOS SOCIALES PARA EL PERSONAL DE LA INDUSTRIA DE LA CARNE Y AFINES' },
			{ label: 'INST. DE SERVICIOS SOCIALES PARA EL PERSONAL DE LA INDUSTRIA DEL VIDRIO Y AFINES', value: 'INST. DE SERVICIOS SOCIALES PARA EL PERSONAL DE LA INDUSTRIA DEL VIDRIO Y AFINES' },
			{
				label: 'INST. DE SERVICIOS SOCIALES PARA EL PERSONAL DE SEGUROS,REASEGUROS,CAPITALIZACION Y AHORRO Y PRESTAMO PARA LA VIVIENDA',
				value: 'INST. DE SERVICIOS SOCIALES PARA EL PERSONAL DE SEGUROS,REASEGUROS,CAPITALIZACION Y AHORRO Y PRESTAMO PARA LA VIVIENDA',
			},
			{ label: 'INST. DE SERVICIOS SOCIALES PARA EL PERSONAL FERROVIARIO', value: 'INST. DE SERVICIOS SOCIALES PARA EL PERSONAL FERROVIARIO' },
			{
				label: 'INST. DE SERVICIOS SOCIALES PARA EL TERRITORIO NAC. DE TIERRA DEL FUEGO,ANTARTIDA E ISLAS DEL ATLANTICO SUR',
				value: 'INST. DE SERVICIOS SOCIALES PARA EL TERRITORIO NAC. DE TIERRA DEL FUEGO,ANTARTIDA E ISLAS DEL ATLANTICO SUR',
			},
			{ label: 'INST. DE SERVICIOS SOCIALES PARA LAS ACTIVIDADES RURALES Y AFINES', value: 'INST. DE SERVICIOS SOCIALES PARA LAS ACTIVIDADES RURALES Y AFINES' },
			{ label: 'INST. NAC. DE SERVICIOS SOCIALES PARA JUBILADOS Y PENSIONADOS', value: 'INST. NAC. DE SERVICIOS SOCIALES PARA JUBILADOS Y PENSIONADOS' },
			{ label: 'IOMA', value: 'IOMA' },
			{ label: 'JOHNSON', value: 'JOHNSON' },
			{ label: 'MEDICAL´S', value: 'MEDICAL´S' },
			{ label: 'MEDICUS', value: 'MEDICUS' },
			{ label: 'MEDIFE', value: 'MEDIFE' },
			{ label: 'MEDIN', value: 'MEDIN' },
			{ label: 'MGN SALUD', value: 'MGN SALUD' },
			{ label: 'MPN', value: 'MPN' },
			{ label: 'MUTUAL DEL PERSONAL DE AGUA Y ENERGIA DE MENDOZA', value: 'MUTUAL DEL PERSONAL DE AGUA Y ENERGIA DE MENDOZA' },
			{ label: 'NIVEL SALUD', value: 'NIVEL SALUD' },
			{ label: 'O. S. DE FORD AGENTINA S.C.A', value: 'O. S. DE FORD AGENTINA S.C.A' },
			{ label: 'O. S. DE LA CAMARA DE EMPRESARIOS DE AGENCIAS DE REMISES DE LA PBA', value: 'O. S. DE LA CAMARA DE EMPRESARIOS DE AGENCIAS DE REMISES DE LA PBA' },
			{
				label: 'O. S. DE LAS SECRETARIAS DE INDUSTRIA Y COMERCIO EXTERIOR, COMERCIO INTERIOR Y MINERÍA',
				value: 'O. S. DE LAS SECRETARIAS DE INDUSTRIA Y COMERCIO EXTERIOR, COMERCIO INTERIOR Y MINERÍA',
			},
			{ label: 'O. S. DE SERENOS DE BUQUES', value: 'O. S. DE SERENOS DE BUQUES' },
			{ label: 'O. S. DE TRABAJADORES VIALES Y AFINES DE LA REPUBLICA ARGENTINA', value: 'O. S. DE TRABAJADORES VIALES Y AFINES DE LA REPUBLICA ARGENTINA' },
			{ label: 'O. S. DEL PERSONAL JERARQUICO DEL AGUA Y LA ENERGÍA', value: 'O. S. DEL PERSONAL JERARQUICO DEL AGUA Y LA ENERGÍA' },
			{ label: 'O. S. PERSONAL DE DIRECCION DE LA IND. CURTIDORA ARGENTINA', value: 'O. S. PERSONAL DE DIRECCION DE LA IND. CURTIDORA ARGENTINA' },
			{ label: 'O. S. WITCEL', value: 'O. S. WITCEL' },
			{ label: 'O.S. DE SHELL ARGENTINA', value: 'O.S. DE SHELL ARGENTINA' },
			{ label: 'O.S. DE DIRECCION OSDO', value: 'O.S. DE DIRECCION OSDO' },
			{ label: 'O.S. DE LA POLICIA FEDERAL', value: 'O.S. DE LA POLICIA FEDERAL' },
			{
				label: 'O.S. DEL PERSONAL DE LA FEDERACION DE SINDICATOS DE LA INDUSTRIA QUIMICA Y PETROQUIMICA ',
				value: 'O.S. DEL PERSONAL DE LA FEDERACION DE SINDICATOS DE LA INDUSTRIA QUIMICA Y PETROQUIMICA ',
			},
			{
				label: 'O.S. DEL PERSONAL JERARQUICO DEL TRANSPORTE AUTOMOTOR DE PASAJEROS DE CORDOBA Y AFINES',
				value: 'O.S. DEL PERSONAL JERARQUICO DEL TRANSPORTE AUTOMOTOR DE PASAJEROS DE CORDOBA Y AFINES',
			},
			{ label: 'O.S. MEDICA AVELLANEDA', value: 'O.S. MEDICA AVELLANEDA' },
			{ label: 'O.S. PARA EL PERSONAL DE EMPRESAS DE LIMPIEZA, SERVICIOS Y MAESTRANZA DE MENDOZA', value: 'O.S. PARA EL PERSONAL DE EMPRESAS DE LIMPIEZA, SERVICIOS Y MAESTRANZA DE MENDOZA' },
			{ label: 'O.S.A.M.', value: 'O.S.A.M.' },
			{ label: 'O.S.A.M.O.C', value: 'O.S.A.M.O.C' },
			{ label: 'O.S.A.P.S.E', value: 'O.S.A.P.S.E' },
			{ label: 'O.S.ACEROS PARANA', value: 'O.S.ACEROS PARANA' },
			{ label: 'O.S.ANILSUD', value: 'O.S.ANILSUD' },
			{ label: 'O.S.ATANOR S.A.', value: 'O.S.ATANOR S.A.' },
			{ label: 'O.S.AUTOLATINA ARGENTINA S.A.', value: 'O.S.AUTOLATINA ARGENTINA S.A.' },
			{ label: 'O.S.BOROQUIMICA S.A.M.I.C.A.F.', value: 'O.S.BOROQUIMICA S.A.M.I.C.A.F.' },
			{ label: 'O.S.CABOT ARGENTINA', value: 'O.S.CABOT ARGENTINA' },
			{ label: 'O.S.CALILEGUA S.A.A.I.C.', value: 'O.S.CALILEGUA S.A.A.I.C.' },
			{ label: 'O.S.CIA. EMBOTELLADORA ARGENTINA S.A.I.C', value: 'O.S.CIA. EMBOTELLADORA ARGENTINA S.A.I.C' },
			{ label: 'O.S.COMPAÑÍA MINERA AGULAR S.A.', value: 'O.S.COMPAÑÍA MINERA AGULAR S.A.' },
			{ label: 'O.S.COMPAÑÍA QUIMICA S.A.', value: 'O.S.COMPAÑÍA QUIMICA S.A.' },
			{ label: 'O.S.DE AGENTES DE PROPAGANDA MEDICA DE ROSARIO', value: 'O.S.DE AGENTES DE PROPAGANDA MEDICA DE ROSARIO' },
			{ label: 'O.S.DE ALLIED DOMECQ ARGENTINA. S.A.', value: 'O.S.DE ALLIED DOMECQ ARGENTINA. S.A.' },
			{ label: 'O.S.DE ARBITROS DE LA ASOC. DE FUTBOL ARGENTINO', value: 'O.S.DE ARBITROS DE LA ASOC. DE FUTBOL ARGENTINO' },
			{ label: 'O.S.DE ARBITROS DEL FUTBOL ARG.', value: 'O.S.DE ARBITROS DEL FUTBOL ARG.' },
			{ label: 'O.S.DE ARTISTAS DE VARIEDADES', value: 'O.S.DE ARTISTAS DE VARIEDADES' },
			{ label: 'O.S.DE BANCARIOS', value: 'O.S.DE BANCARIOS' },
			{ label: 'O.S.DE BAÑEROS Y AFINES DEL PARTIDO DE GENERAL PUEYRREDON', value: 'O.S.DE BAÑEROS Y AFINES DEL PARTIDO DE GENERAL PUEYRREDON' },
			{ label: 'O.S.DE CAPITANES,PILOTOS Y PATRONES DE PESCA', value: 'O.S.DE CAPITANES,PILOTOS Y PATRONES DE PESCA' },
			{ label: 'O.S.DE CHOFERES PARTICULARES', value: 'O.S.DE CHOFERES PARTICULARES' },
			{ label: 'O.S.DE COLCHONEROS', value: 'O.S.DE COLCHONEROS' },
			{ label: 'O.S.DE CONDUCTORES CAMIONEROS Y PERSONAL DEL TRANSPORTE AUTOMOTOR DE CARGAS', value: 'O.S.DE CONDUCTORES CAMIONEROS Y PERSONAL DEL TRANSPORTE AUTOMOTOR DE CARGAS' },
			{ label: 'O.S.DE CONDUCTORES DE TRANSPORTE COLECTIVO DE PASAJEROS', value: 'O.S.DE CONDUCTORES DE TRANSPORTE COLECTIVO DE PASAJEROS' },
			{ label: 'O.S.DE EMBALADORES,DESCARTADORES Y ALAMBRADORES  SAN PEDRO', value: 'O.S.DE EMBALADORES,DESCARTADORES Y ALAMBRADORES  SAN PEDRO' },
			{ label: 'O.S.DE EMPLEADOS DE COMERCIO', value: 'O.S.DE EMPLEADOS DE COMERCIO' },
			{ label: 'O.S.DE EMPLEADOS GASTRONOMICOS DE TUCUMAN', value: 'O.S.DE EMPLEADOS GASTRONOMICOS DE TUCUMAN' },
			{ label: 'O.S.DE EMPRESA LINEAS MARITIMAS ARGENTINAS', value: 'O.S.DE EMPRESA LINEAS MARITIMAS ARGENTINAS' },
			{ label: 'O.S.DE FERROVIARIOS', value: 'O.S.DE FERROVIARIOS' },
			{ label: 'O.S.DE GUINCHEROS Y MAQUINISTAS DE GRUAS MOVILES', value: 'O.S.DE GUINCHEROS Y MAQUINISTAS DE GRUAS MOVILES' },
			{ label: 'O.S.DE IPAKO S.A.', value: 'O.S.DE IPAKO S.A.' },
			{ label: 'O.S.DE LA EMPRESA PRIVADA CELULOSA ARGENTINA S.A.', value: 'O.S.DE LA EMPRESA PRIVADA CELULOSA ARGENTINA S.A.' },
			{
				label: 'O.S.DE LA FEDERACION NAC. DE SINDICATOS DE CONDUCTORES DE TAXIS DE LA REPUBLICA ARGENTINA',
				value: 'O.S.DE LA FEDERACION NAC. DE SINDICATOS DE CONDUCTORES DE TAXIS DE LA REPUBLICA ARGENTINA',
			},
			{ label: 'O.S.DE LA INDUSTRIA DE MATERIALES REFRACTARIOS Y AFINES', value: 'O.S.DE LA INDUSTRIA DE MATERIALES REFRACTARIOS Y AFINES' },
			{ label: 'O.S.DE LA SECRETARIA DE AGRICULTURA Y GANADERIA', value: 'O.S.DE LA SECRETARIA DE AGRICULTURA Y GANADERIA' },
			{ label: 'O.S.DE MAQUINISTAS DE TEATRO Y TELEVISION', value: 'O.S.DE MAQUINISTAS DE TEATRO Y TELEVISION' },
			{ label: 'O.S.DE MUSICOS DE CUYO', value: 'O.S.DE MUSICOS DE CUYO' },
			{ label: 'O.S.DE MUSICOS DE MAR DEL PLATA', value: 'O.S.DE MUSICOS DE MAR DEL PLATA' },
			{ label: 'O.S.DE OBREROS EMPACADORES DE FRUTA DE RIO NEGRO Y NEUQUEN', value: 'O.S.DE OBREROS EMPACADORES DE FRUTA DE RIO NEGRO Y NEUQUEN' },
			{ label: 'O.S.DE OFICIALES PELUQUEROS Y PEINADORES', value: 'O.S.DE OFICIALES PELUQUEROS Y PEINADORES' },
			{ label: 'O.S.DE PELETEROS', value: 'O.S.DE PELETEROS' },
			{ label: 'O.S.DE PROFESIONALES  DE LA O.S.DEL PERSONAL DEL PAPEL,CARTON Y QUIMICOS', value: 'O.S.DE PROFESIONALES  DE LA O.S.DEL PERSONAL DEL PAPEL,CARTON Y QUIMICOS' },
			{ label: 'O.S.DE PROFESORES DE ACADEMIAS PITMAN', value: 'O.S.DE PROFESORES DE ACADEMIAS PITMAN' },
			{
				label: 'O.S.DE TECNICOS,PROFESIONALES,EMPLEADOS Y SUPERVISORES DE INFORMATICA Y TELECOM. S.A.Y CIMET S.A. PLANTA SAN ISIDRO',
				value: 'O.S.DE TECNICOS,PROFESIONALES,EMPLEADOS Y SUPERVISORES DE INFORMATICA Y TELECOM. S.A.Y CIMET S.A. PLANTA SAN ISIDRO',
			},
			{ label: 'O.S.DE TINTOREROS,SOMBREREROS Y LAVADEROS', value: 'O.S.DE TINTOREROS,SOMBREREROS Y LAVADEROS' },
			{ label: 'O.S.DE VAREADORES', value: 'O.S.DE VAREADORES' },
			{ label: 'O.S.DE VIAJANTES DE COMERCIO', value: 'O.S.DE VIAJANTES DE COMERCIO' },
			{ label: 'O.S.DE Y.P.F.', value: 'O.S.DE Y.P.F.' },
			{ label: 'O.S.DEL MINISTERIO DE DEFENSA', value: 'O.S.DEL MINISTERIO DE DEFENSA' },
			{ label: 'O.S.DEL MINISTERIO DE JUSTICIA', value: 'O.S.DEL MINISTERIO DE JUSTICIA' },
			{ label: 'O.S.DEL MINISTERIO DE OBRAS Y SERVICIOS PUBLICOS', value: 'O.S.DEL MINISTERIO DE OBRAS Y SERVICIOS PUBLICOS' },
			{ label: 'O.S.DEL PERSONAL DE ARTES GRAFICAS DE SANTA FE', value: 'O.S.DEL PERSONAL DE ARTES GRAFICAS DE SANTA FE' },
			{ label: 'O.S.DEL PERSONAL DE ARTES GRAFICAS DEL CHACO', value: 'O.S.DEL PERSONAL DE ARTES GRAFICAS DEL CHACO' },
			{ label: 'O.S.DEL PERSONAL DE AZUCAR DE VILLA OCAMPO', value: 'O.S.DEL PERSONAL DE AZUCAR DE VILLA OCAMPO' },
			{ label: 'O.S.DEL PERSONAL DE AZUCAR DEL INGENIO RIO GRANDE', value: 'O.S.DEL PERSONAL DE AZUCAR DEL INGENIO RIO GRANDE' },
			{ label: 'O.S.DEL PERSONAL DE AZUCAR DEL INGENIO SAN ISIDRO', value: 'O.S.DEL PERSONAL DE AZUCAR DEL INGENIO SAN ISIDRO' },
			{ label: 'O.S.DEL PERSONAL DE BARRACAS DE LANAS,CUEROS Y ANEXOS', value: 'O.S.DEL PERSONAL DE BARRACAS DE LANAS,CUEROS Y ANEXOS' },
			{ label: 'O.S.DEL PERSONAL DE CERAMICA SAN LORENZO', value: 'O.S.DEL PERSONAL DE CERAMICA SAN LORENZO' },
			{ label: 'O.S.DEL PERSONAL DE DIRECCION ALFREDO FORTABAT', value: 'O.S.DEL PERSONAL DE DIRECCION ALFREDO FORTABAT' },
			{ label: 'O.S.DEL PERSONAL DE LA ACTIVIDAD AZUCARERA TUCUMANA', value: 'O.S.DEL PERSONAL DE LA ACTIVIDAD AZUCARERA TUCUMANA' },
			{ label: 'O.S.DEL PERSONAL DE LA COMPANIA GRAL. DE COMBUSTIBLES S.A.', value: 'O.S.DEL PERSONAL DE LA COMPANIA GRAL. DE COMBUSTIBLES S.A.' },
			{ label: 'O.S.DEL PERSONAL DE LA FERIA INFANTIL', value: 'O.S.DEL PERSONAL DE LA FERIA INFANTIL' },
			{ label: 'O.S.DEL PERSONAL DE LA INDUSTRIA GRAFICA DE LA PROVINCIA DE CORDOBA', value: 'O.S.DEL PERSONAL DE LA INDUSTRIA GRAFICA DE LA PROVINCIA DE CORDOBA' },
			{ label: 'O.S.DEL PERSONAL DE LA INDUSTRIA SALINERA', value: 'O.S.DEL PERSONAL DE LA INDUSTRIA SALINERA' },
			{ label: 'O.S.DEL PERSONAL DE LA JUNTA NAC. DE CARNES', value: 'O.S.DEL PERSONAL DE LA JUNTA NAC. DE CARNES' },
			{ label: 'O.S.DEL PERSONAL DE OBRAS Y SERVICIOS SANITARIOS', value: 'O.S.DEL PERSONAL DE OBRAS Y SERVICIOS SANITARIOS' },
			{ label: 'O.S.DEL PERSONAL DE PBBPOLISUR SRL DE BAHIA BLANCA', value: 'O.S.DEL PERSONAL DE PBBPOLISUR SRL DE BAHIA BLANCA' },
			{ label: 'O.S.DEL PERSONAL DE PRENSA DE BAHIA BLANCA', value: 'O.S.DEL PERSONAL DE PRENSA DE BAHIA BLANCA' },
			{ label: 'O.S.DEL PERSONAL DE PRENSA DE MENDOZA', value: 'O.S.DEL PERSONAL DE PRENSA DE MENDOZA' },
			{ label: 'O.S.DEL PERSONAL DE RECOLECCION Y BARRIDO DE ROSARIO', value: 'O.S.DEL PERSONAL DE RECOLECCION Y BARRIDO DE ROSARIO' },
			{ label: 'O.S.DEL PERSONAL DE REFINERIAS DE MAIZ', value: 'O.S.DEL PERSONAL DE REFINERIAS DE MAIZ' },
			{ label: 'O.S.DEL PERSONAL DE STANDARD ELECTRIC', value: 'O.S.DEL PERSONAL DE STANDARD ELECTRIC' },
			{ label: 'O.S.DEL PERSONAL DE VIALIDAD NAC.', value: 'O.S.DEL PERSONAL DE VIALIDAD NAC.' },
			{ label: 'O.S.DEL PERSONAL DEL AZUCAR DE CALILEGUA', value: 'O.S.DEL PERSONAL DEL AZUCAR DE CALILEGUA' },
			{ label: 'O.S.DEL PERSONAL DEL INGENIO SAN PABLO', value: 'O.S.DEL PERSONAL DEL INGENIO SAN PABLO' },
			{ label: 'O.S.DEL PERSONAL DEL JOCKEY CLUB DE ROSARIO', value: 'O.S.DEL PERSONAL DEL JOCKEY CLUB DE ROSARIO' },
			{ label: 'O.S.DEL PERSONAL DEL SEGURO', value: 'O.S.DEL PERSONAL DEL SEGURO' },
			{ label: 'O.S.DEL PERSONAL GRAFICO DE CORRIENTES', value: 'O.S.DEL PERSONAL GRAFICO DE CORRIENTES' },
			{ label: 'O.S.DEL PERSONAL GUARDAVIDAS Y AFINES DE LA REPUBLICA ARGENTINA', value: 'O.S.DEL PERSONAL GUARDAVIDAS Y AFINES DE LA REPUBLICA ARGENTINA' },
			{ label: 'O.S.DEL PERSONAL JERARQUICO DE LA INDUSTRIA GRAFICA DE LA REPUBLICA ARGENTINA', value: 'O.S.DEL PERSONAL JERARQUICO DE LA INDUSTRIA GRAFICA DE LA REPUBLICA ARGENTINA' },
			{ label: 'O.S.DEL PERSONAL MUNICIPAL DE AVELLANEDA', value: 'O.S.DEL PERSONAL MUNICIPAL DE AVELLANEDA' },
			{ label: 'O.S.DEL PERSONAL MUNICIPAL DE LA MATANZA', value: 'O.S.DEL PERSONAL MUNICIPAL DE LA MATANZA' },
			{ label: 'O.S.DEL PERSONAL MUNICIPAL DE SANTIAGO DEL ESTERO', value: 'O.S.DEL PERSONAL MUNICIPAL DE SANTIAGO DEL ESTERO' },
			{ label: 'O.S.DEL PERSONAL MUNICIPAL DE TRES DE FEBRERO', value: 'O.S.DEL PERSONAL MUNICIPAL DE TRES DE FEBRERO' },
			{ label: 'O.S.DEL PERSONAL SUPERIOR DE GOOD YEAR ARGENTINA', value: 'O.S.DEL PERSONAL SUPERIOR DE GOOD YEAR ARGENTINA' },
			{ label: 'O.S.DEL PERSONAL SUPERIOR DE LA INDUSTRIA DEL GAS Y AFINES', value: 'O.S.DEL PERSONAL SUPERIOR DE LA INDUSTRIA DEL GAS Y AFINES' },
			{
				label: 'O.S.DEL SINDICATO UNIDO DE TRABAJADORES DE LA INDUSTRIA DE AGUAS GASEOSAS DE LA PROVINCIA DE SANTA FE',
				value: 'O.S.DEL SINDICATO UNIDO DE TRABAJADORES DE LA INDUSTRIA DE AGUAS GASEOSAS DE LA PROVINCIA DE SANTA FE',
			},
			{ label: 'O.S.DEL TRANSPORTE  AUTOMOTOR DE ROSARIO', value: 'O.S.DEL TRANSPORTE AUTOMOTOR DE ROSARIO' },
			{ label: 'O.S.DESTILERIAS SAN IGNACIO S.A.I.C.', value: 'O.S.DESTILERIAS SAN IGNACIO S.A.I.C.' },
			{ label: 'O.S.DIRECCION NAC. DE VIALIDAD', value: 'O.S.DIRECCION NAC. DE VIALIDAD' },
			{ label: 'O.S.DUNLOP ARGENTINA LIMITADA', value: 'O.S.DUNLOP ARGENTINA LIMITADA' },
			{ label: 'O.S.ELECTROCLOR S.C.A.', value: 'O.S.ELECTROCLOR S.C.A.' },
			{ label: 'O.S.FEDERACION GREMIAL INDUSTRIA DE LA CARNE Y SUS DERIVADOS', value: 'O.S.FEDERACION GREMIAL INDUSTRIA DE LA CARNE Y SUS DERIVADOS' },
			{ label: 'O.S.FLOTA FLUVIAL DEL ESTADO ARGENTINO', value: 'O.S.FLOTA FLUVIAL DEL ESTADO ARGENTINO' },
			{ label: 'O.S.FORJA ARGENTINA S.A.I.C.', value: 'O.S.FORJA ARGENTINA S.A.I.C.' },
			{ label: 'O.S.GAS DEL ESTADO', value: 'O.S.GAS DEL ESTADO' },
			{ label: 'O.S.LEDESMA S.A.A.I.', value: 'O.S.LEDESMA S.A.A.I.' },
			{ label: 'O.S.MOLINOS RIO DE LA PLATA', value: 'O.S.MOLINOS RIO DE LA PLATA' },
			{ label: 'O.S.MUTUALIDAD EMPLEADOS FIRESTONE', value: 'O.S.MUTUALIDAD EMPLEADOS FIRESTONE' },
			{ label: 'O.S.OLIVETTI S.C.A.', value: 'O.S.OLIVETTI S.C.A.' },
			{ label: 'O.S.PAPEL MISIONERO S.A.I.F.Y C.', value: 'O.S.PAPEL MISIONERO S.A.I.F.Y C.' },
			{ label: 'O.S.PARA EL PERSONAL DE DIRECCION DE LA ACTIVIDAD MINERA', value: 'O.S.PARA EL PERSONAL DE DIRECCION DE LA ACTIVIDAD MINERA' },
			{ label: 'O.S.PARA EL PERSONAL DE OBRAS SANITARIAS DE LA NAC.', value: 'O.S.PARA EL PERSONAL DE OBRAS SANITARIAS DE LA NAC.' },
			{ label: 'O.S.PARA EMPLEADOS DE COMERCIO Y ACTIVIDADES CIVILES', value: 'O.S.PARA EMPLEADOS DE COMERCIO Y ACTIVIDADES CIVILES' },
			{ label: 'O.S.PARA LA ACTIVIDAD DOCENTE', value: 'O.S.PARA LA ACTIVIDAD DOCENTE' },
			{ label: 'O.S.PASA PETROQUIMICA ARGENTINA S.A.', value: 'O.S.PASA PETROQUIMICA ARGENTINA S.A.' },
			{ label: 'O.S.PE.COR', value: 'O.S.PE.COR' },
			{ label: 'O.S.PE.TAX.R', value: 'O.S.PE.TAX.R' },
			{ label: 'O.S.PERS.A.A.M.S.', value: 'O.S.PERS.A.A.M.S.' },
			{ label: 'O.S.PETROQUIMICA GENERAL MOSCONI', value: 'O.S.PETROQUIMICA GENERAL MOSCONI' },
			{ label: 'O.S.PORTUARIOS DE PUERTO SAN MARTIN Y BELLA VISTA', value: 'O.S.PORTUARIOS DE PUERTO SAN MARTIN Y BELLA VISTA' },
			{ label: 'O.S.PORTUARIOS DE ROSARIO', value: 'O.S.PORTUARIOS DE ROSARIO' },
			{ label: 'O.S.PORTUARIOS DE SAN LORENZO', value: 'O.S.PORTUARIOS DE SAN LORENZO' },
			{ label: 'O.S.PORTUARIOS DE SAN PEDRO', value: 'O.S.PORTUARIOS DE SAN PEDRO' },
			{ label: 'O.S.PORTUARIOS DE SANTA FE', value: 'O.S.PORTUARIOS DE SANTA FE' },
			{ label: 'O.S.PORTUARIOS DE VILLA CONSTITUCIÓN', value: 'O.S.PORTUARIOS DE VILLA CONSTITUCIÓN' },
			{ label: 'O.S.PORTUARIOS NECOCHEA Y QUEQUEN', value: 'O.S.PORTUARIOS NECOCHEA Y QUEQUEN' },
			{ label: 'O.S.PORTUARIOS PUERTO SAN  NICOLAS', value: 'O.S.PORTUARIOS PUERTO SAN  NICOLAS' },
			{ label: 'O.S.S.U.R.R.B.A.C', value: 'O.S.S.U.R.R.B.A.C' },
			{ label: 'O.S.SOCIEDAD MINERA HIERROS PATAGONICOS DE SIERRA GRANDE', value: 'O.S.SOCIEDAD MINERA HIERROS PATAGONICOS DE SIERRA GRANDE' },
			{ label: 'O.S.SULFACID S.A.I.F.C.', value: 'O.S.SULFACID S.A.I.F.C.' },
			{ label: 'O.S.SUPERCO', value: 'O.S.SUPERCO' },
			{ label: 'O.S.T.A.C.C.', value: 'O.S.T.A.C.C.' },
			{ label: 'O.S.T.C.A.R.A.', value: 'O.S.T.C.A.R.A.' },
			{ label: 'O.S.TAX.B.A', value: 'O.S.TAX.B.A' },
			{ label: 'O.S.TRABAJADORES DE LA INDUSTRIA AVICOLA Y AFINES DE LA REPUBLICA  ARGENTINA', value: 'O.S.TRABAJADORES DE LA INDUSTRIA AVICOLA Y AFINES DE LA REPUBLICA  ARGENTINA' },
			{ label: 'O.S.TRABAJADORES MUNICIPALES DE GRAL. PUEYRREDON', value: 'O.S.TRABAJADORES MUNICIPALES DE GRAL. PUEYRREDON' },
			{ label: 'O.S.VOLKSWAGEN ARGENTINA S.A.', value: 'O.S.VOLKSWAGEN ARGENTINA S.A.' },
			{
				label: 'O.S.Y SINDICATO DEL PERSONAL MENSUALIZADO DEL JOCKEY CLUB DE BUENOS AIRES Y LOS HIPODROMOS DE PALERMO Y SAN ISIDRO',
				value: 'O.S.Y SINDICATO DEL PERSONAL MENSUALIZADO DEL JOCKEY CLUB DE BUENOS AIRES Y LOS HIPODROMOS DE PALERMO Y SAN ISIDRO',
			},
			{ label: 'O.S.YACIMIENTOS CARBONIFEROS', value: 'O.S.YACIMIENTOS CARBONIFEROS' },
			{ label: 'O.S.YACIMIENTOS CARBONIFEROS FISCALES', value: 'O.S.YACIMIENTOS CARBONIFEROS FISCALES' },
			{ label: 'O.S.YACIMIENTOS PETROLIFEROS FISCALES', value: 'O.S.YACIMIENTOS PETROLIFEROS FISCALES' },
			{ label: 'OBRA SOCIAL DE COMISARIOS NAVALES', value: 'OBRA SOCIAL DE COMISARIOS NAVALES' },
			{ label: 'OBRA SOCIAL DE CONSIGNATARIOS DEL MERCADO GENERAL DE HACIENDA DE AVELLANEDA', value: 'OBRA SOCIAL DE CONSIGNATARIOS DEL MERCADO GENERAL DE HACIENDA DE AVELLANEDA' },
			{ label: 'OBRA SOCIAL DE PATRONES DE CABOTAJE DE RIOS Y PUERTOS', value: 'OBRA SOCIAL DE PATRONES DE CABOTAJE DE RIOS Y PUERTOS' },
			{ label: 'OBSBA', value: 'OBSBA' },
			{ label: 'OMINT', value: 'OMINT' },
			{ label: 'OPDEA', value: 'OPDEA' },
			{ label: 'OPZC', value: 'OPZC' },
			{ label: 'OS DE DIRECCION DE LA ACTIVIDAD AEROCOMERCIAL PRIVADA', value: 'OS DE DIRECCION DE LA ACTIVIDAD AEROCOMERCIAL PRIVADA' },
			{
				label: 'OS DE TRABAJADORES SOCIOS DE LA ASOC. MUTUAL DEL PERSONAL JERARQUICO DE BANCOS OFICIALES - JERARQUICO SALUD',
				value: 'OS DE TRABAJADORES SOCIOS DE LA ASOC. MUTUAL DEL PERSONAL JERARQUICO DE BANCOS OFICIALES - JERARQUICO SALUD',
			},
			{ label: 'OS DEL PERSONAL DE LA ACTIVIDAD AZUCARERA TUCUMANA', value: 'OS DEL PERSONAL DE LA ACTIVIDAD AZUCARERA TUCUMANA' },
			{
				label: 'OS. EMPLEADOS Y PERSONAL JERARQUICO DEL NEUMATICO ARGENTINO DE NEUMATICOS GOOD YEAR S.R.L.',
				value: 'OS. EMPLEADOS Y PERSONAL JERARQUICO DEL NEUMATICO ARGENTINO DE NEUMATICOS GOOD YEAR S.R.L.',
			},
			{ label: 'OS.PE.PRI', value: 'OS.PE.PRI' },
			{ label: 'OSA (ACTORES)', value: 'OSA (ACTORES)' },
			{ label: 'OSA (AERONAVEGANTES)', value: 'OSA (AERONAVEGANTES)' },
			{ label: 'OSADEF', value: 'OSADEF' },
			{ label: 'OSADRA', value: 'OSADRA' },
			{ label: 'OSALARA', value: 'OSALARA' },
			{ label: 'OSAMMVC', value: 'OSAMMVC' },
			{ label: 'OSAPM', value: 'OSAPM' },
			{ label: 'OSAPMCBA', value: 'OSAPMCBA' },
			{ label: 'OSAPMER', value: 'OSAPMER' },
			{ label: 'OSARPYH', value: 'OSARPYH' },
			{ label: 'OSBA', value: 'OSBA' },
			{ label: 'OSBARA', value: 'OSBARA' },
			{ label: 'OSCAMGLYP', value: 'OSCAMGLYP' },
			{ label: 'OSCAPBAQFLU', value: 'OSCAPBAQFLU' },
			{ label: 'OSCCA', value: 'OSCCA' },
			{ label: 'OSCE', value: 'OSCE' },
			{ label: 'OSCEARA', value: 'OSCEARA' },
			{ label: 'OSCEP', value: 'OSCEP' },
			{ label: 'OSCHOCA', value: 'OSCHOCA' },
			{ label: 'OSCOEMA', value: 'OSCOEMA' },
			{ label: 'OSCOMM', value: 'OSCOMM' },
			{ label: 'OSCONARA', value: 'OSCONARA' },
			{ label: 'OSCRAIA', value: 'OSCRAIA' },
			{ label: 'OSDE', value: 'OSDE' },
			{ label: 'OSDEL', value: 'OSDEL' },
			{ label: 'OSDEM', value: 'OSDEM' },
			{ label: 'OSDEPYM', value: 'OSDEPYM' },
			{ label: 'OSDIC', value: 'OSDIC' },
			{ label: 'OSDIPP', value: 'OSDIPP' },
			{ label: 'OSDO', value: 'OSDO' },
			{ label: 'OSDOP', value: 'OSDOP' },
			{ label: 'OSEADI', value: 'OSEADI' },
			{ label: 'OSEAM', value: 'OSEAM' },
			{ label: 'OSECAC', value: 'OSECAC' },
			{ label: 'OSEDA', value: 'OSEDA' },
			{ label: 'OSEDEIV', value: 'OSEDEIV' },
			{ label: 'OSEF', value: 'OSEF' },
			{ label: 'OSEIV', value: 'OSEIV' },
			{ label: 'OSEMM', value: 'OSEMM' },
			{ label: 'OSEN', value: 'OSEN' },
			{ label: 'OSEPC', value: 'OSEPC' },
			{ label: 'OSETRA', value: 'OSETRA' },
			{ label: 'OSETYA', value: 'OSETYA' },
			{ label: 'OSFATLYF', value: 'OSFATLYF' },
			{ label: 'OSFATUN', value: 'OSFATUN' },
			{ label: 'OSFE', value: 'OSFE' },
			{ label: 'OSFFENTOS', value: 'OSFFENTOS' },
			{ label: 'OSFOT', value: 'OSFOT' },
			{ label: 'OSFYB', value: 'OSFYB' },
			{ label: 'OSFYHC', value: 'OSFYHC' },
			{ label: 'OSIAD', value: 'OSIAD' },
			{ label: 'OSIM', value: 'OSIM' },
			{ label: 'OSIPA', value: 'OSIPA' },
			{ label: 'OSITAC', value: 'OSITAC' },
			{ label: 'OSJERA', value: 'OSJERA' },
			{ label: 'OSJOMN', value: 'OSJOMN' },
			{ label: 'OSJONR', value: 'OSJONR' },
			{ label: 'OSJPVYF', value: 'OSJPVYF' },
			{ label: 'OSLERA', value: 'OSLERA' },
			{ label: 'OSLPASTEUR', value: 'OSLPASTEUR' },
			{ label: 'OSLYF', value: 'OSLYF' },
			{ label: 'OSLYF PATAGONIA', value: 'OSLYF PATAGONIA' },
			{ label: 'OSMA', value: 'OSMA' },
			{ label: 'OSMAD', value: 'OSMAD' },
			{ label: 'OSMATA', value: 'OSMATA' },
			{ label: 'OSME - OS MTRIO.ECONOMIA', value: 'OSME - OS MTRIO.ECONOMIA' },
			{ label: 'OSMEDICA', value: 'OSMEDICA' },
			{ label: 'OSMISS', value: 'OSMISS' },
			{ label: 'OSMITA', value: 'OSMITA' },
			{ label: 'OSMMEDT', value: 'OSMMEDT' },
			{ label: 'OSOC', value: 'OSOC' },
			{ label: 'OSOETSYLARA', value: 'OSOETSYLARA' },
			{ label: 'OSOFPP DE ROSARIO', value: 'OSOFPP DE ROSARIO' },
			{ label: 'OSOSS', value: 'OSOSS' },
			{ label: 'OSOSS', value: 'OSOSS' },
			{ label: 'OSPA (AERONAUTICO)', value: 'OSPA (AERONAUTICO)' },
			{ label: 'OSPA (AZUCAR DEL INGENIO SAN MARTIN)', value: 'OSPA (AZUCAR DEL INGENIO SAN MARTIN)' },
			{ label: 'OSPA (AZUCAR INGENIO DE LA ESPERANZA)', value: 'OSPA (AZUCAR INGENIO DE LA ESPERANZA)' },
			{ label: 'OSPA (PORTUARIOS ARG. DE MAR DEL PLATA)', value: 'OSPA (PORTUARIOS ARG. DE MAR DEL PLATA)' },
			{ label: 'OSPA (PORTUARIOS ARG.)', value: 'OSPA (PORTUARIOS ARG.)' },
			{ label: 'OSPACA (ACTIVIDAD CERVECERA Y AFINES)', value: 'OSPACA (ACTIVIDAD CERVECERA Y AFINES)' },
			{ label: 'OSPACA (AUTOMOVOVIL CLUB ARG)', value: 'OSPACA (AUTOMOVOVIL CLUB ARG)' },
			{ label: 'OSPACP', value: 'OSPACP' },
			{ label: 'OSPAD ( PERSONAL ADUANERO DE LA R.A.)', value: 'OSPAD ( PERSONAL ADUANERO DE LA R.A.)' },
			{ label: 'OSPAD (TRABAJADORES DEL PETROLEO Y GAS PRIVADO DEL CHUBUT)', value: 'OSPAD (TRABAJADORES DEL PETROLEO Y GAS PRIVADO DEL CHUBUT)' },
			{ label: 'OSPADEP', value: 'OSPADEP' },
			{ label: 'OSPAF', value: 'OSPAF' },
			{ label: 'OSPAGA', value: 'OSPAGA' },
			{ label: 'OSPAIL', value: 'OSPAIL' },
			{ label: 'OSPAILT', value: 'OSPAILT' },
			{ label: 'OSPAÑA', value: 'OSPAÑA' },
			{ label: 'OSPAP', value: 'OSPAP' },
			{ label: 'OSPAT', value: 'OSPAT' },
			{ label: 'OSPATCA', value: 'OSPATCA' },
			{ label: 'OSPAV', value: 'OSPAV' },
			{ label: 'OSPA-VIAL', value: 'OSPA-VIAL' },
			{ label: 'OSPCN', value: 'OSPCN' },
			{ label: 'OSPCRA', value: 'OSPCRA' },
			{ label: 'OSPCSPMYA', value: 'OSPCSPMYA' },
			{ label: 'OSPCYD', value: 'OSPCYD' },
			{ label: 'OSPDESBA', value: 'OSPDESBA' },
			{ label: 'OSPE (ESCRIBANOS)', value: 'OSPE (ESCRIBANOS)' },
			{ label: 'OSPE (PETROLEROS)', value: 'OSPE (PETROLEROS)' },
			{ label: 'OSPEA', value: 'OSPEA' },
			{ label: 'OSPEC', value: 'OSPEC' },
			{ label: 'OSPECA', value: 'OSPECA' },
			{ label: 'OSPECSYM', value: 'OSPECSYM' },
			{ label: 'OSPEDICI', value: 'OSPEDICI' },
			{ label: 'OSPEDYB', value: 'OSPEDYB' },
			{ label: 'OSPEDYC', value: 'OSPEDYC' },
			{ label: 'OSPEF Y EPCA', value: 'OSPEF Y EPCA' },
			{ label: 'OSPEGAP', value: 'OSPEGAP' },
			{ label: 'OSPEJ', value: 'OSPEJ' },
			{ label: 'OSPEMER', value: 'OSPEMER' },
			{ label: 'OSPEMOM', value: 'OSPEMOM' },
			{ label: 'OSPENA', value: 'OSPENA' },
			{ label: 'OSPEP (ENSEÑANZA PRIVADA)', value: 'OSPEP (ENSEÑANZA PRIVADA)' },
			{ label: 'OSPEP (ESPECTACULO PUBLICO)', value: 'OSPEP (ESPECTACULO PUBLICO)' },
			{ label: 'OSPEP (PERS. PANADERIAS)', value: 'OSPEP (PERS. PANADERIAS)' },
			{ label: 'OSPEPBA', value: 'OSPEPBA' },
			{ label: 'OSPERCIN', value: 'OSPERCIN' },
			{ label: 'OSPERYH', value: 'OSPERYH' },
			{ label: 'OSPERYHRA', value: 'OSPERYHRA' },
			{ label: 'OSPES', value: 'OSPES' },
			{ label: 'OSPESA', value: 'OSPESA' },
			{ label: 'OSPESCA', value: 'OSPESCA' },
			{ label: 'OSPESCHA', value: 'OSPESCHA' },
			{ label: 'OSPESGA', value: 'OSPESGA' },
			{ label: 'OSPESGYPE', value: 'OSPESGYPE' },
			{ label: 'OSPETAX CAPITAL', value: 'OSPETAX CAPITAL' },
			{ label: 'OSPETELCO', value: 'OSPETELCO' },
			{ label: 'OSPEVIC', value: 'OSPEVIC' },
			{ label: 'OSPF', value: 'OSPF' },
			{ label: 'OSPF (FERMOLAC)', value: 'OSPF (FERMOLAC)' },
			{ label: 'OSPG', value: 'OSPG' },
			{ label: 'OSPHGBAYSI', value: 'OSPHGBAYSI' },
			{ label: 'OSPIA', value: 'OSPIA' },
			{ label: 'OSPIA', value: 'OSPIA' },
			{ label: 'OSPIB', value: 'OSPIB' },
			{ label: 'OSPIC (IND. CAUCHO)', value: 'OSPIC (IND. CAUCHO)' },
			{ label: 'OSPIC (IND. CINEMATOGRÁFICA)', value: 'OSPIC (IND. CINEMATOGRÁFICA)' },
			{ label: 'OSPICA', value: 'OSPICA' },
			{ label: 'OSPICAL', value: 'OSPICAL' },
			{ label: 'OSPICHA', value: 'OSPICHA' },
			{ label: 'OSPIDA', value: 'OSPIDA' },
			{ label: 'OSPIF (IND. DEL FÓSFORO, E. Y A.))', value: 'OSPIF (IND. DEL FÓSFORO, E. Y A.))' },
			{ label: 'OSPIF (IND. FIBROCEMENTO)', value: 'OSPIF (IND. FIBROCEMENTO)' },
			{ label: 'OSPIF (IND. FIDEERA)', value: 'OSPIF (IND. FIDEERA)' },
			{ label: 'OSPIFSE', value: 'OSPIFSE' },
			{ label: 'OSPIHMP', value: 'OSPIHMP' },
			{ label: 'OSPIL', value: 'OSPIL' },
			{ label: 'OSPILM', value: 'OSPILM' },
			{ label: 'OSPIM (IND. MADERERA)', value: 'OSPIM (IND. MADERERA)' },
			{ label: 'OSPIM (IND. MOLINERA))', value: 'OSPIM (IND. MOLINERA))' },
			{ label: 'OSPIN (IND. NAVAL)', value: 'OSPIN (IND. NAVAL)' },
			{ label: 'OSPIN (IND. NEUMATICO)', value: 'OSPIN (IND. NEUMATICO)' },
			{ label: 'OSPIP (IND. PESCADO MAR DEL PLATA)', value: 'OSPIP (IND. PESCADO MAR DEL PLATA)' },
			{ label: 'OSPIP (IND. PETROQUIMICA)', value: 'OSPIP (IND. PETROQUIMICA)' },
			{ label: 'OSPIP (IND. PLASTICO)', value: 'OSPIP (IND. PLASTICO)' },
			{ label: 'OSPIQYP', value: 'OSPIQYP' },
			{ label: 'OSPIS', value: 'OSPIS' },
			{ label: 'OSPIT (IND. DEL TRACTOR)', value: 'OSPIT (IND. DEL TRACTOR)' },
			{ label: 'OSPIT (IND. TABACO)', value: 'OSPIT (IND. TABACO)' },
			{ label: 'OSPIT (IND. TEXTIL)', value: 'OSPIT (IND. TEXTIL)' },
			{ label: 'OSPIV (IND.DEL VESTIDO Y A.)', value: 'OSPIV (IND.DEL VESTIDO Y A.)' },
			{ label: 'OSPIV (PERSONAL IND.DEL VIDRIO)', value: 'OSPIV (PERSONAL IND.DEL VIDRIO)' },
			{ label: 'OSPJN', value: 'OSPJN' },
			{ label: 'OSPL', value: 'OSPL' },
			{ label: 'OSPLA', value: 'OSPLA' },
			{ label: 'OSPLAD', value: 'OSPLAD' },
			{ label: 'OSPM', value: 'OSPM' },
			{ label: 'OSPM (MAESTRANZA)', value: 'OSPM (MAESTRANZA)' },
			{ label: 'OSPM (PERS. MARITIMO)', value: 'OSPM (PERS. MARITIMO)' },
			{ label: 'OSPOCE', value: 'OSPOCE' },
			{ label: 'OSPP', value: 'OSPP' },
			{ label: 'OSPPCHA', value: 'OSPPCHA' },
			{ label: 'OSPPCYQ', value: 'OSPPCYQ' },
			{ label: 'OSPPEA', value: 'OSPPEA' },
			{ label: 'OSPPRA', value: 'OSPPRA' },
			{ label: 'OSPREME', value: 'OSPREME' },
			{ label: 'OSPREN', value: 'OSPREN' },
			{ label: 'OSPRENTUC', value: 'OSPRENTUC' },
			{ label: 'OSPRERA', value: 'OSPRERA' },
			{ label: 'OSPRO', value: 'OSPRO' },
			{ label: 'OSPROTURA', value: 'OSPROTURA' },
			{ label: 'OSPS MERCEDES', value: 'OSPS MERCEDES' },
			{ label: 'OSPSA', value: 'OSPSA' },
			{ label: 'OSPSESBA', value: 'OSPSESBA' },
			{ label: 'OSPSIP', value: 'OSPSIP' },
			{ label: 'OSPTA', value: 'OSPTA' },
			{ label: 'OSPTV', value: 'OSPTV' },
			{ label: 'OSPUAYE', value: 'OSPUAYE' },
			{ label: 'OSRGA', value: 'OSRGA' },
			{ label: 'OSRJA', value: 'OSRJA' },
			{ label: 'OSRM', value: 'OSRM' },
			{ label: 'OSSACRA', value: 'OSSACRA' },
			{ label: 'OSSEG', value: 'OSSEG' },
			{ label: 'OSSIMRA', value: 'OSSIMRA' },
			{ label: 'OSSOELSAC', value: 'OSSOELSAC' },
			{ label: 'OSSPYFER', value: 'OSSPYFER' },
			{ label: 'OSTAD', value: 'OSTAD' },
			{ label: 'OSTAMMA', value: 'OSTAMMA' },
			{ label: 'OSTC', value: 'OSTC' },
			{ label: 'OSTECF', value: 'OSTECF' },
			{ label: 'OSTEE', value: 'OSTEE' },
			{ label: 'OSTEL', value: 'OSTEL' },
			{ label: 'OSTEP', value: 'OSTEP' },
			{ label: 'OSTES', value: 'OSTES' },
			{ label: 'OSTIG', value: 'OSTIG' },
			{ label: 'OSTP', value: 'OSTP' },
			{ label: 'OSTPBA', value: 'OSTPBA' },
			{ label: 'OSTPCHPYARA', value: 'OSTPCHPYARA' },
			{ label: 'OSTRAC', value: 'OSTRAC' },
			{ label: 'OSTVENDRA', value: 'OSTVENDRA' },
			{ label: 'OSTVLA', value: 'OSTVLA' },
			{ label: 'OSUCI', value: 'OSUCI' },
			{ label: 'OSUOMRA', value: 'OSUOMRA' },
			{ label: 'OSUTHGRA', value: 'OSUTHGRA' },
			{ label: 'OSUTI', value: 'OSUTI' },
			{ label: 'OSVARA', value: 'OSVARA' },
			{ label: 'OSVVRA', value: 'OSVVRA' },
			{ label: 'OSYPF', value: 'OSYPF' },
			{ label: 'PAMI', value: 'PAMI' },
			{ label: 'PREMEDIC', value: 'PREMEDIC' },
			{ label: 'PREVENCION SALUD', value: 'PREVENCION SALUD' },
			{ label: 'PRIVAMED', value: 'PRIVAMED' },
			{ label: 'SADAIC', value: 'SADAIC' },
			{ label: 'SANCOR SALUD', value: 'SANCOR SALUD' },
			{ label: 'SANIDAD', value: 'SANIDAD' },
			{ label: 'SEMA CIR.SUBOF.FF.AA.', value: 'SEMA CIR.SUBOF.FF.AA.' },
			{ label: 'SERVESALUD', value: 'SERVESALUD' },
			{ label: 'SERVIN LIFE', value: 'SERVIN LIFE' },
			{ label: 'SIMECO', value: 'SIMECO' },
			{ label: 'SMAI', value: 'SMAI' },
			{ label: 'STAFF MEDICO', value: 'STAFF MEDICO' },
			{ label: 'SUPA PTO BBCA', value: 'SUPA PTO BBCA' },
			{ label: 'SWISS MEDICAL', value: 'SWISS MEDICAL' },
			{ label: 'UNION PERSONAL', value: 'UNION PERSONAL' },
			{ label: 'UPFPARA', value: 'UPFPARA' },
			{ label: 'VISITAR', value: 'VISITAR' },
			{ label: 'WHOPE', value: 'WHOPE' },
			{ label: 'WILLIAM HOPE', value: 'WILLIAM HOPE' },
			{ label: 'WITCEL', value: 'WITCEL' },
		],
	},
]
