/** @format */

import axios from 'axios';

import Notify from '../../../Notify';

import { ENV_CALCULATION, ENV_COUNTRY, ENV_ROOT_PHARMACY } from '../../Variables/Enviroment';

let notifyCounter = 0;

export default async function GetDeliveryInfo(item) {
	const userLocation = JSON.parse(localStorage.getItem('locationStore'));
	let returnResponse;
	await axios({
		method: 'POST',
		url: `${ENV_CALCULATION}/delivery`,
		data: {
			product: item,
			country: ENV_COUNTRY,
			root_pharmacy: ENV_ROOT_PHARMACY,
			token_auth: localStorage.getItem('userToken'),
			lat: userLocation.lat,
			lng: userLocation.lng,
			address: userLocation.address,
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = {
					day: response.data.data.schedule_delivery.length > 0 ? response.data.data.schedule_delivery[0].day : '',
					date: response.data.data.schedule_delivery.length > 0 ? response.data.data.schedule_delivery[0].date : '',
					message:
						response.data.data.schedule_delivery.length > 0 ? 'Primer día disponible para despacho' : 'Despacho no disponible para ubicación seleccionada',
					price: response.data.data.shipping_information.price,
				};
			} else {
				if (notifyCounter < 1) {
					Notify({
						title: `Error`,
						colorTitle: 'ph-main-notification-error-title',
						description: '¡Error al calcular costos de envío!',
						time: 5,
						image: 'error',
						placement: 'topRight',
					});
				}
				notifyCounter++;
			}
		})
		.catch((e) => {
			console.log(e);
		});
	return returnResponse;
}
