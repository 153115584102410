/** @format */

import React from 'react'
import { Route, Redirect } from 'react-router-dom'

class PrivateCheckout extends React.Component {
	render() {
		let isCheckout = localStorage.getItem('retirementData')
		if (isCheckout) {
			return <Route {...this.props} />
		}
		return <Redirect to='/' />
	}
}
export default PrivateCheckout
