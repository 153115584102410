/** @format */

export const BagMergeLocals = (item) => {
	const handleBagMergeLocals = (objectOne, objectTwo) => {
		if (objectOne) {
			let returnObject = {}
			let A = Object.keys(objectOne)
			let B = Object.keys(objectTwo)
			if (A.length > B.length) {
				Object.keys(objectOne).forEach((k, i) => {
					if (objectTwo.hasOwnProperty(k) && typeof objectTwo[k] === 'number')
						returnObject[k] = objectTwo[k] + objectOne[k]
					else if (objectTwo.hasOwnProperty(k) && typeof objectTwo[k] !== 'number')
						returnObject[k] = objectTwo[k]
					else if (B[i] !== undefined) {
						returnObject[k] = objectOne[k]
						returnObject[B[i]] = objectTwo[B[i]]
					} else returnObject[k] = objectOne[k]
				})
			} else {
				Object.keys(objectTwo).forEach((k, i) => {
					if (objectOne.hasOwnProperty(k) && typeof objectTwo[k] === 'number')
						returnObject[k] = objectTwo[k] + objectOne[k]
					else if (objectTwo.hasOwnProperty(k) && typeof objectTwo[k] !== 'number')
						returnObject[k] = objectTwo[k]
					else if (A[i] !== undefined) {
						returnObject[k] = objectTwo[k]
						returnObject[A[i]] = objectOne[A[i]]
					} else returnObject[k] = objectTwo[k]
				})
			}
			return returnObject
		} else return objectTwo
	}

	let localsGroup = item[0]
	let locals = []
	let payment
	let products = []
	let shippingInfo
	item.forEach((element) => {
		locals = locals.concat(element.locals)
		products = products.concat(element.products)
		payment = handleBagMergeLocals(payment, element.payment)
		shippingInfo = handleBagMergeLocals(shippingInfo, element.shipping_information)
	})
	localsGroup = {
		...localsGroup,
		locals: locals,
		payment: payment,
		products: products,
		shipping_information: shippingInfo,
	}
	item = localsGroup
	return item
}

export const BagMerge = (item) => {
	const names = item.map((element) => element.name)
	const arrayNames = new Set(names)
	let namefilters = [...arrayNames]
	let arrayFilters = {}
	const localsGroup = []
	for (const name of namefilters)
		arrayFilters[name] = item.filter((element) => element.name === name)
	for (const key in arrayFilters) localsGroup.push(BagMergeLocals(arrayFilters[key]))
	return localsGroup
}
