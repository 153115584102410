/** @format */

export const handleLocals = (cart) => {
	const arrayLocals = []
	cart.forEach((item) => {
		if (item.on_demand === true) arrayLocals.push(item.local_info)
	})
	return arrayLocals.reduce((acc, local) => {
		if (local === undefined) return acc
		if (acc.find((item) => item.pharmacy === local.pharmacy) === undefined) return [...acc, local]
		else return acc
	}, [])
}
