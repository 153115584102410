/** @format */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useHistory, useParams } from 'react-router-dom';
import { setGlobal } from 'reactn';

import { Col, Form, Row } from 'antd';

import InputField from '../../../../components/Commons/Input';
import InputPhone from '../../../../components/Commons/InputCountry/Phone';
import InputRut from '../../../../components/Commons/InputCountry/Rut';
import MainTitlePage from '../../../../components/Commons/MainTitlePage';
import MetaDescription from '../../../../components/Commons/MetaDescription';
import Notify from '../../../../components/Commons/Notify';
import AddressCheckout from '../../../../components/HooksCheckout/components/AddressCheckout';

import KnowLoginUser from '../../../../components/Commons/Hooks/Functions/KnowLoginUser';

import { minsalRecipe, requestRecipe } from '../../../../components/HooksCheckout/QuoteInfo/QuoteTitle';
import UploadImage from '../../../../components/HooksCheckout/components/UploadImage';
import { serviceUploadImage } from '../../components/ServiceUploadImage';

//import QrCodePlugin from './components/qrcodeplugin'
import { electronicQuoteServices } from '../../../../components/HooksCheckout/Functions/MinsalRequest';
import { serviceDigital } from './services';

import CustomButton from '../../../../v2-components/Antd/Button';
import './style.scss';

export default function RecipesDigitalMinsal() {
	const { t } = useTranslation('global');

	let { type_rme } = useParams();
	let history = useHistory();
	const [form] = Form.useForm();
	const [isLoading, setLoading] = useState(false);
	const [isTitleParams, setTitleParams] = useState(null);
	const [isRecipe, setRecipe] = useState(null);
	const [isuserAddress] = useState(JSON.parse(localStorage.getItem('locationStore')));

	useEffect(() => {
		KnowLoginUser();
		if (type_rme === 'minsal') setTitleParams(minsalRecipe);
		else if (type_rme === 'digital') setTitleParams(requestRecipe);
		setGlobal({ address: isuserAddress });
	}, [type_rme, isuserAddress]);

	const handleFinishForm = async () => {
		let formValues = await form
			.validateFields()
			.then((i) => i)
			.catch(() => null);
		if (!!!formValues) return;
		const userAddress = JSON.parse(localStorage.getItem('locationStore'));
		if (userAddress.default) {
			Notify({
				title: '¡Aviso!',
				colorTitle: 'ph-main-notification-warning-title',
				description: '¡Debe ingresar una dirección para proceder!',
				time: 4,
				image: 'warning',
				placement: 'topRight',
			});
			setLoading(false);
			return;
		}
		setLoading(true);
		if (type_rme === 'digital') {
			if (!isRecipe) {
				Notify({
					title: '¡Aviso!',
					colorTitle: 'ph-main-notification-warning-title',
					description: '¡Debe subir la receta para poder procesar su pedido!',
					time: 4,
					image: 'warning',
					placement: 'topRight',
				});
				setLoading(false);
				return;
			}
			let responseImage = await serviceUploadImage(isRecipe);
			let responseDigital = await serviceDigital({
				...formValues,
				...userAddress,
				recipe: responseImage,
			});
			history.push(`/request-preorder/success/${responseDigital.short_code}`);
		} else if ((type_rme = 'minsal')) {
			let tokenToSend = formValues.folio_number; // later add QrCode validation too
			if (!!!tokenToSend) {
				Notify({
					title: '¡Aviso!',
					colorTitle: 'ph-main-notification-warning-title',
					description: '¡Debe insertar un número de folio o escanear un código QR!',
					time: 4,
					image: 'warning',
					placement: 'topRight',
				});
				setLoading(false);
				return;
			}

			let responseMinsal = await electronicQuoteServices({
				token: formValues.folio_number,
				type: 'minsal',
			});

			if (responseMinsal.data?.products.length > 0) {
				history.push(`/electronic-quote/${responseMinsal.data.referal.token}?type=minsal`);
			} else {
				Notify({
					title: '¡Aviso!',
					colorTitle: 'ph-main-notification-warning-title',
					description: '¡El número de folio que ha insertado no posee productos asociados!',
					time: 5,
					image: 'warning',
					placement: 'topRight',
				});
			}
		}
		setLoading(false);
	};

	return (
		<div className="ph-request-quotation-global-container">
			<div className="ph-request-quotation-global-inner-container">
				<div>
					<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
					<MainTitlePage
						rootImage={true}
						title={`Sube tu ${isTitleParams?.title}`}
						descriptionPage={isTitleParams?.subtitle}
						image={`${isTitleParams?.image}`}
					/>
					<div className="ph-request-quotation-main-container">
						{type_rme === 'digital' && (
							<Row>
								<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-request-quotation-form-container">
									<Form name="recipesForm" form={form}>
										<InputField
											className={'ph-request-quotation-field-input'}
											inputName={'first_name'}
											inputNameLabel={'Nombre'}
											inputNameRule={true}
											inputNameMessage={'Ingresa tu nombre'}
											inputNameType={'text'}
											inputNameRules={'rulesFirstName'}
										/>
									</Form>
								</Col>
								<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-request-quotation-form-container">
									<Form name="recipesForm" form={form}>
										<InputField
											className={'ph-request-quotation-field-input'}
											inputName={'last_name'}
											inputNameLabel={'Apellido'}
											inputNameRule={true}
											inputNameMessage={'Ingresa tu apellido'}
											inputNameType={'text'}
											inputNameRules={'rulesLastName'}
										/>
									</Form>
								</Col>
								<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-request-quotation-form-container">
									<Form name="recipesForm" form={form}>
										<InputField
											className={'ph-request-quotation-field-input'}
											inputName={'email'}
											inputNameLabel={'Correo electrónico'}
											inputNameRule={true}
											inputNameMessage={'E-mail es obligatorio'}
											inputNameType={'text'}
											inputNameRules={'rulesEmail'}
										/>
									</Form>
								</Col>
								<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-request-quotation-form-container">
									<Form name="recipesForm" form={form}>
										<InputRut classInput={'ph-request-quotation-field-input'} />
									</Form>
								</Col>
								<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-request-quotation-form-container">
									<Form name="recipesForm" form={form}>
										<InputPhone classInput={'ph-request-quotation-field-input'} />
									</Form>
								</Col>
								<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-request-quotation-form-container">
									<AddressCheckout isPreorder={true} classType={'ph-request-form-address'} />
								</Col>
								<Col span={24}>
									<div className="ph-request-quotation-form-upload-image">
										<UploadImage type={'recipe'} title={'Receta'} addProductImage={(item) => setRecipe(item)} removeProductImage={() => setRecipe(null)} />
									</div>
								</Col>
							</Row>
						)}
						{type_rme === 'minsal' && (
							<Row>
								<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-request-quotation-form-container">
									<Form name="request_form" form={form}>
										<InputField
											className={'ph-request-quotation-field-input'}
											inputName={'folio_number'}
											inputNameLabel={'Número de folio'}
											inputNameRule={true}
											inputNameMessage={'Número de folio'}
											inputNameType={'text'}
										/>
									</Form>
								</Col>
								<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-request-quotation-form-container">
									<AddressCheckout isPreorder={true} classType={'ph-request-form-address'} />
								</Col>
								{/** 
								<Col
									xs={24}
									sm={24}
									md={12}
									lg={12}
									xl={12}
									className='ph-request-quotation-form-container'>
									<div className='ph-request-quotation-recipe-container'>
										<QrCodePlugin
											fps={10}
											qrbox={250}
											disableFlip={false}
											qrCodeSuccessCallback={(item) => setQrCode(item)}
											qrCodeErrorCallback={() => {}}
										/>
									</div>
								</Col>
								*/}
							</Row>
						)}
						<div className="ph-request-quotation-button-container">
							<CustomButton
								form="recipesForm"
								key="submit"
								htmlType="submit"
								className={'button-primary'}
								onClick={() => handleFinishForm()}
								loading={isLoading}
							>
								ENVIAR
							</CustomButton>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
