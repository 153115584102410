export const handleStock = (product, className) => {
	const checkStock = product.available === undefined ? product.stock : product.available;

	if (checkStock > 3) {
		return (
			<label className={className}>
				Stock: <span>Disponible</span>
			</label>
		);
	}

	if (checkStock <= 3) {
		return (
			<label className={className}>
				Stock: <span>Pocas unidades</span>
			</label>
		);
	}

	if (checkStock <= 0) {
		return (
			<label className={className}>
				Stock: <span>No disponible</span>
			</label>
		);
	}

	return null;
};
