/** @format */

import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { setGlobal } from 'reactn';
import { ReactComponent as IconHome } from '../../../../../../../../v2-config/icons-svg/home.svg';
import { ReactComponent as IconLocation } from '../../../../../../../../v2-config/icons-svg/location.svg';
import { ReactComponent as IconUser } from '../../../../../../../../v2-config/icons-svg/user.svg';
import { GlobalConsumer } from '../../../../../../Context/Global';
import './style.scss';
export default function MobileActions() {
	const [isLogin] = useState(localStorage.getItem('userEmail'));

	const history = useHistory();

	const { setModalAddress } = GlobalConsumer();

	const handleProfileLink = () => {
		if (isLogin) {
			history.push('/profile-info');
		} else {
			history.push('/auth/login');
		}
	};

	const handleOpenModalAddress = () => {
		setModalAddress(true);
	};

	useEffect(() => {
		setGlobal(() => ({
			userMainInfo: JSON.parse(localStorage.getItem('userMainInfo')),
		}));
	}, []);

	return (
		<Row justify="center">
			<Col span={8} style={{ textAlign: 'center' }}>
				<Link to="/">
					<IconHome className={window.location.pathname === '/' ? 'icons-theme-mobile-actions active' : 'icons-theme-mobile-actions'} />
				</Link>
			</Col>
			<Col span={8} style={{ textAlign: 'center' }}>
				<div onClick={() => handleOpenModalAddress()}>
					<IconLocation className={'icons-theme-mobile-actions'} />
				</div>
			</Col>
			<Col span={8} style={{ textAlign: 'center' }}>
				<div onClick={() => handleProfileLink()}>
					<IconUser className={'icons-theme-mobile-actions'} />
				</div>
			</Col>
		</Row>
	);
}
