/** @format */

import React, { useEffect, useState } from 'react'

import { useGlobal } from 'reactn'

import { Button, Form } from 'antd'

import Spacer from '../../../../../../components/Commons/Spacer'

import { UnlockOutlined } from '@ant-design/icons'

import './style.scss'

export default function CheckoutButtonRetirement(props) {
	const [isLoading] = useGlobal('retirementServiceButtonLoading')
	const [isMobile, setMobile] = useState(false)

	useEffect(() => {
		if (window.innerWidth < 992) {
			setMobile(true)
		}
	}, [])

	return (
		<Form.Item className='ph-checkout-retirement-service-button-main-container'>
			{isMobile && (
				<>
					<div>
						<h3 className='ph-checkout-retirement-service-button-mobile-price-main-title'>
							Subtotal:
						</h3>
						<h3 className='ph-checkout-retirement-service-button-mobile-price-title'>
							<span className='ph-checkout-retirement-service-button-mobile-price-description'>
								$
							</span>
							{props.item.total}
						</h3>
					</div>
					<Spacer />
				</>
			)}
			<div className='ph-checkout-retirement-service-button-container'>
				<Button
					loading={isLoading}
					icon={<UnlockOutlined />}
					className='ph-checkout-retirement-service-button-1'
					htmlType={'submit'}
				/>
				<Button
					loading={isLoading}
					className='ph-checkout-retirement-service-button-2'
					htmlType={'submit'}>
					SIGUIENTE
				</Button>
			</div>
		</Form.Item>
	)
}
