/** @format */

import React, { Fragment } from 'react'

import { Row, Col } from 'antd'

import Info from './components/Info'

import './style.scss'

export default function ProductDispatch(props) {
	return (
		<>
			<Row>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<h3 className='ph-product-detail-dispatch-main-title'>Tipos de Entrega disponibles:</h3>
				</Col>
				{props.item.details[0].despatch_info ? (
					<>
						{props.item.details[0].despatch_info.map((item, index) => (
							<Fragment key={index}>
								<Col xs={24} sm={24} md={8} lg={8} xl={8}>
									<Info product={props.item.details[0]} title={`${item.title} ${item.description}`} subtitle={item.title} image={item.image} />
								</Col>
							</Fragment>
						))}
					</>
				) : (
					<h3 className='ph-product-detail-dispatch-main-subtitle'>Error al consultar Tipos de Entrega</h3>
				)}
			</Row>
		</>
	)
}
