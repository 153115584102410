/** @format */

import axios from 'axios';

import Notify from '../../../../components/Commons/Notify';

import { ENV_COUNTRY, ENV_ELEC_PRESCRIPTION, ENV_QUOTATION } from '../../../../components/Commons/Hooks/Variables/Enviroment';

export const BagServiceRME = async (item) => {
	let returnResponse;
	await axios({
		method: 'GET',
		url: `${ENV_ELEC_PRESCRIPTION}/quote/products/${ENV_COUNTRY}/${item}`,
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data;
			} else {
				Notify({
					title: `Error: ${response.data.error} - ${response.data.statusCode}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `${response.data.message}`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			}
		})
		.catch(() => {
			Notify({
				title: `Problemas de Conexión`,
				colorTitle: 'ph-main-notification-error-title',
				description: `Revisa tu conexión o ponte en contacto con nosotros.`,
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
};

export const BagServiceOnDemand = async (item) => {
	let returnResponse;
	await axios({
		method: 'GET',
		url: `${ENV_QUOTATION}/get/cart/${item}`,
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data;
			} else {
				Notify({
					title: `Error: ${response.data.error}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Error en servicio RME: ${response.data.message}`,
					time: 8,
					image: 'error',
					placement: 'topRight',
				});
			}
		})
		.catch(() => {
			Notify({
				title: `Problemas de Conexión`,
				colorTitle: 'ph-main-notification-error-title',
				description: `Revisa tu conexión o ponte en contacto con nosotros.`,
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
};
