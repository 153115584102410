/** @format */

import React, { Component } from 'react'

import './style.scss'

export default class CardProfileInfo extends Component {
	render() {
		return (
			<>
				<div className='ph-userdata-info-container'>
					<h3 className='ph-userdata-info-title'>{this.props.title}</h3>
					{this.props.description ? (
						<p className='ph-userdata-info-description'>{this.props.description}</p>
					) : (
						<p className='ph-userdata-info-no-description'>
							Faltan datos, use el botón Editar
						</p>
					)}
				</div>
			</>
		)
	}
}
