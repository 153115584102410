/** @format */

import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import Address from '../../../components/Address';
import SeachButton from './components/SeachButton';
import SideBar from './components/Sidebar';
import CartIcon from './components/CartIcon';
import { IMAGES } from '../../../../../../v2-config/constants/images.constant';
import './style.scss';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const NavBarMobile = ({ navBar, status }) => {
	const [isScroll, setScroll] = useState(0);

	useEffect(() => {
		const handleClickOutside = (e) => {
			setScroll(e.target.documentElement.scrollTop);
		};
		document.addEventListener('scroll', handleClickOutside);
		return () => {
			document.removeEventListener('scroll', handleClickOutside);
		};
	}, []);

	return (
		<>
			<div className="ph-header-mobile-global-spacer"></div>
			<div className={`${isScroll > 0 && 'ph-header-mobile-global-container-shadow'} ph-header-mobile-global-container`}>
				<div>
					<Row className={isScroll > 0 ? 'ph-header-mobile-sticky-container' : 'ph-header-mobile-normal-container'}>
						<Col span={12} className="ph-header-mobile-logo-container">
							<Link to='/'>
								<img src={IMAGES.LOGO_LIGHT_BACKGROUND} title="logo" alt="logo" style={{ height: '25px', marginLeft: '10px' }} />
							</Link>
						</Col>
						<Col span={12} className="ph-header-mobile-address-container">
							<Address />
						</Col>
					</Row>
					<Row className={isScroll > 0 ? 'ph-header-mobile-second-row-sticky-container' : 'ph-header-mobile-second-row-container'}>
						<Col span={3}>
							<div className="ph-header-mobile-sidebar-main-container">
								<SideBar navBar={navBar} status={status}/>
							</div>
						</Col>
						<Col span={18}>
							<SeachButton />
						</Col>
						<Col span={3}>
							<div className="ph-header-mobile-cart-main-container">
								<CartIcon />
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};
export default NavBarMobile;
