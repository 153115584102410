/** @format */

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Form, Layout, Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import InputField from '../../../components/Commons/Input';
import MetaDescription from '../../../components/Commons/MetaDescription';
import Spacer from '../../../components/Commons/Spacer';
import CustomButton from '../../../v2-components/Antd/Button';
import recoverPasswordService from './services';
import { IMAGES } from '../../../v2-config/constants/images.constant';
import './style.scss';

const RecoverPassword = (props) => {
	const { t } = useTranslation('global');
	const [isLoading, setLoading] = useState(false);

	const handleRecoverPassword = async (item) => {
		setLoading(true);
		await recoverPasswordService(item, props.history).then(() => {
			setLoading(false);
		});
	};

	useEffect(() => {
		if (localStorage.getItem('userEmail') && localStorage.getItem('userToken')) {
			props.history.push('/');
		}
	}, [props.history]);

	return (
		<>
			<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			<Layout className="ph-auth-recover-main-container">
				<Row justify="center" className="ph-auth-background">
					<Col md={12} xs={22}>
						<Form name="normal_login" initialValues={{ remember: true }} onFinish={handleRecoverPassword} className="ph-auth-recover-form-main-container">
							<div className="ph-auth-recover-body-container">
								<Row justify="center">
									<img src={IMAGES.LOGO_LIGHT_BACKGROUND} alt="logo" className="logo" height={42} />
								</Row>
								<div className="ph-auth-recover-title-container">
									<div>
										<h2 className="ph-auth-recover-title">Recuperar Contraseña</h2>
										<h3 className="ph-auth-recover-subtitle">Fácil y rápido cuando lo necesitas</h3>
									</div>
								</div>
								<div className="ph-auth-recover-form-container">
									<InputField
										className={'ph-auth-recover-field-input'}
										inputName={'email'}
										inputNameLabel={'Correo electrónico'}
										inputNameRule={true}
										inputNameMessage={'E-mail es obligatorio'}
										inputNameType={'text'}
										inputNameIcon={''}
										inputNameRules={'rulesEmail'}
									/>
								</div>
								<div className="ph-auth-recover-register-container">
									<h3 className="ph-auth-recover-register-description">¿Ya tienes cuenta?</h3>
									<Link to="/auth/login">
										<h5 className="ph-auth-recover-register-link-description">Haz click aquí.</h5>
									</Link>
								</div>
							</div>
							<Spacer />
							<div className="ph-auth-recover-footer-container">
								<div className="ph-auth-recover-footer-button-home-container">
									<Link className="ph-auth-recover-footer-button-home-inner-container" to="/">
										<ArrowLeftOutlined className="ph-auth-recover-footer-button-home-icon" />
										<h3 className="ph-auth-recover-footer-button-home-title">Volver</h3>
									</Link>
								</div>
								<Spacer />
								<div className="ph-auth-recover-main-button-container">
									<Form.Item>
										<CustomButton className={'button-primary'} htmlType={'submit'} loadingSubmit={isLoading}>
											Recuperar
										</CustomButton>
									</Form.Item>
								</div>
							</div>
						</Form>
					</Col>
				</Row>
			</Layout>
		</>
	);
};
export default RecoverPassword;
