/** @format */

import axios from 'axios';

import Notify from '../../../../../../../../components/Commons/Notify';

import { ENV_COMMON, ENV_COUNTRY, ENV_ROOT_PHARMACY } from '../../../../../../../../components/Commons/Hooks/Variables/Enviroment';
import { ScreenWidth } from '../../../../../../../../components/Commons/Hooks/Functions/ScreenWidth';

export const GetListPharmacies = async (item, data) => {
	let returnResponse;
	let userInfo = JSON.parse(localStorage.getItem('userInfo'));
	let dataParams = {
		mobile: ScreenWidth(),
		root_pharmacy: ENV_ROOT_PHARMACY,
		lat: data.lat,
		lng: data.lng,
	};
	if (!!userInfo && !!userInfo?.info && 'rut' in userInfo?.info && userInfo?.info.rut.length > 0) dataParams = { ...dataParams, nid: userInfo.info.rut };

	await axios({
		method: 'GET',
		url: `${ENV_COMMON}/product/${ENV_COUNTRY}/${item}/locals`,
		params: dataParams,
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data;
			} else {
				returnResponse = [];
			}
		})
		.catch(() => {
			Notify({
				title: `Error`,
				colorTitle: 'ph-main-notification-error-title',
				description: 'Verifique tu conexión a internet.',
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
};
