export const dataSteps =  [
  {
    status: 1,
    description: 'Por aprobar',
  },
  {
    status: 101,
    description: 'En mediación',
  },
  {
    status: 2,
    description: 'Aprobada',
  },
  {
    status: 201,
    description: 'Por confirmar',
  },
  {
    status: 3,
    description: 'En Proceso de empacado',
  },
  {
    status: 4,
    description: 'Listo para retiro',
  },
  {
    status: 5,
    description: 'Pedido en camino',
  },
  {
    status: 6,
    description: 'Pedido entregado',
  },
  {
    status: 8,
    description: 'Pedido anulado',
  },
  {
    status: 9,
    description: 'Pedido confirmado',
  },

]