/** @format */

import React, { useEffect } from 'react'
import { setGlobal, useGlobal } from 'reactn'

import Button from 'antd/lib/button'
import Input from 'antd/lib/input'

import Notify from '../../components/Commons/Notify'

import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import './style.scss'

export default function ButtonQuantity(props) {
	const [isQuantity] = useGlobal('quantityProduct')

	useEffect(() => {
		setGlobal({ quantityProduct: 1 })
	}, [props.quantity])

	const handleDeleteQuantity = () => {
		const particularQuantity = props.quantity || isQuantity
		if (particularQuantity > 1) {
			if (typeof props.removeItem === 'function') props.removeItem()
			setGlobal({ quantityProduct: particularQuantity - 1 })
		}
	}

	const handleAddQuantity = (item, quantity) => {
		const particularQuantity = props.quantity || isQuantity
		if (quantity >= item.limit_stock) {
			Notify({
				title: `Advertencia`,
				colorTitle: 'ph-main-notification-warning-title',
				description: `¡Solo podemos dispensar ${item.limit_stock} unidades de este producto!`,
				time: 3,
				image: 'error',
				placement: 'topRight',
			})
		} else {
			if (typeof props.addItem === 'function') props.addItem(1)
			setGlobal({ quantityProduct: particularQuantity + 1 })
		}
	}

	return (
		<>
			<div className='ph-product-detail-button-quantity-main-container'>
				<Button
					className='ph-product-detail-button-add-quantity'
					onClick={() => handleDeleteQuantity()}>
					<LeftOutlined />
				</Button>
				<Input
					className='ph-product-detail-button-quantity-input'
					placeholder={props.quantity || isQuantity}
					disabled
				/>
				<Button
					className='ph-product-detail-button-delete-quantity'
					onClick={() => handleAddQuantity(props.item, isQuantity)}>
					<RightOutlined />
				</Button>
			</div>
		</>
	)
}
