export class AxiosInterceptor {
	constructor(response) {
		this.response = response || [];
	}

	async pipe() {
		try {
			console.log('try pipe', this.response);

			if (this.response.data.statusCode === 200 || this.response.data.statusCode === 201) {
				console.log('done data ->', this.response.data.data);
				return this.response.data
			}

			if (this.response.status === 200) {
				console.log('done data ->', this.response.data);
				return this.response.data;
			}

			if (this.response.status !== 200) {
				console.log('throw data ->', this.response.data);
				return this.response.data;
			}


			throw Error('Respuesta no capturada');
		} catch (e) {
			console.log('cath pipe', e);
			throw e;
		}
	}
}

