/** @format */

import Notify from '../../../../../../components/Commons/Notify'

export const handleNotification = (type, color, info) => {
	return Notify({
		title: `¡${type}!`,
		colorTitle: `ph-main-notification-${color}-title`,
		description: info,
		time: 4,
		image: 'error',
		placement: 'topRight',
	})
}
