/** @format */

import axios from 'axios'

import Notify from '../../../Notify'

import { PixelFacebook } from '../../Functions/PixelFacebook'

import { ENV_ISO_CURRENCY } from '../../Variables/Enviroment'

export const userSusbcribeService = async (item) => {
	let returnResponse
	await axios({
		method: 'POST',
		url: 'https://api.woowup.com/apiv3/users',
		headers: { Authorization: 'Basic ecb7ba44d38f9c5da7ba806f3a2b467b1985df1d68196ad6d8b867b9189abfab' },
		data: {
			email: item.email,
			tags: 'newsletter',
		},
	})
		.then((response) => {
			Notify({
				title: `Subscripción de newsletter`,
				colorTitle: 'ph-main-notification-success-title',
				description: '¡Gracias por suscribirte! Pronto disfrutarás de nuestras promociones y descuentos.',
				time: 3,
				image: 'warning',
				placement: 'topRight',
			})
			PixelFacebook('Subscribe', { currency: ENV_ISO_CURRENCY })
			returnResponse = response
		})
		.catch((error) => {
			returnResponse = error
			Notify({
				title: `¡Ups!`,
				colorTitle: 'ph-main-notification-error-title',
				description: 'Este usuario ya se encuentra suscrito',
				time: 3,
				image: 'error',
				placement: 'topRight',
			})
		})
	return returnResponse
}
