/** @format */

import React from 'react';

import { Form } from 'antd';

import { ENV_COUNTRY } from '../../Hooks/Variables/Enviroment';
import InputMask from '../../InputMask';

import { rulesValidationMask } from '../../InputMask/rules';

export default function InputRut(props) {
	return (
		<>
			{ENV_COUNTRY === 'CL' && (
				<Form.Item label={props.label} name={`${props.inputRUT ? props.inputRUT : 'rut'}`} rules={rulesValidationMask.rulesRutCL}>
					<InputMask maskstyle={props.classInput} mask="99.999.999-*" placeholder="11.111.111-1" onChange={props.onChange} />
				</Form.Item>
			)}
			{ENV_COUNTRY === 'CO' && (
				<Form.Item label={props.label} name={`${props.inputRUT ? props.inputRUT : 'rut'}`} rules={rulesValidationMask.rulesRutCO}>
					<InputMask maskstyle={props.classInput} mask="9.999.999.999" placeholder="1.111.111.111" onChange={props.onChange} />
				</Form.Item>
			)}
			{ENV_COUNTRY === 'MX' && (
				<Form.Item label={props.label} name={`${props.inputRUT ? props.inputRUT : 'rut'}`} rules={rulesValidationMask.rulesRFC}>
					<InputMask maskstyle={props.classInput} mask="aaa*99999****" placeholder="RFC" onChange={props.onChange} />
				</Form.Item>
			)}
			{ENV_COUNTRY === 'AR' && (
				<Form.Item 
				label={props.label} 
				name={`${props.inputRUT ? props.inputRUT : 'rut'}`} 
				rules={rulesValidationMask.rulesDniAR}
				>
					<InputMask 
					maskstyle={props.classInput} 
					mask="99.999.999" 
					placeholder="11.111.111" 
					onChange={props.onChange} 
					/>
				</Form.Item>
			)}
		</>
	);
}
