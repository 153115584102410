/** @format */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Col, Row, Button } from 'antd';

import Loading from '../../../../components/Commons/Loading';
import MainTitlePageAlt from '../../../../components/Commons/MainTitlePageAlt';
import MetaDescription from '../../../../components/Commons/MetaDescription';

import { default as bagIcon } from '../../../../components/Commons/Assets/Icons/Filled/EnabledOpacity/bag.svg';
import { default as bagIconGreen } from '../../../../components/Commons/Assets/Icons/Filled/Enabled/Argentina/bolsa_green.png';

import BoxInfo from './components/BoxInfo';

import PurchaseHistoryServices from './services';

import './style.scss';
import { ENV_COUNTRY } from '../../../../components/Commons/Hooks/Variables/Enviroment';

class PurchaseHistoryList extends Component {
	state = {
		list: null,
		email: localStorage.getItem('userEmail'),
	};
	componentDidMount() {
		PurchaseHistoryServices().then((response) => {
			this.setState({ list: response });
		});
	}

	render() {
		const { t } = this.props;

		if (this.state.list) {
		return (
			<>
				<MetaDescription
					title={'Red de Farmacias Online | Historial de Compras de Usuario'}
					name={'description'}
					content={'Red de Farmacias Online | Busca y compra tus remedios o medicamentos a precios bajos con despacho a domicilio o retiro express...'}
				/>
				<div className="ph-order-history-purchase-global-container">
					<div className="ph-order-history-purchase-main-container">
						<MainTitlePageAlt
							title={'Historial de Compra'}
							descriptionProfile={t('profile.backToAcountLink')}
							image={ENV_COUNTRY === 'AR' ? bagIconGreen : bagIcon}
						/>
						<div className="ph-order-history-purchase-container">
							{this.state.list.map((item, index) => (
								<div className="ph-order-history-purchase-list-container" key={index}>
									<Row>
										<Col span={12}>
											<Row>
												<Col xs={24} sm={24} md={12} lg={12} xl={12}>
													<BoxInfo
														classTitle={'ph-order-history-purchase-list-responsive-date-title'}
														classDescription={'ph-order-history-purchase-list-responsive-date-description'}
														title={'Fecha de Orden:'}
														description={item.payment_info.fecha_aprobacion}
													/>
												</Col>
												<Col xs={24} sm={24} md={12} lg={12} xl={12}>
													<BoxInfo
														title={'Nombre del cliente:'}
														classDescription={'ph-order-history-purchase-list-responsive-name-description'}
														description={item.info.first_name + ' ' + item.info.last_name}
													/>
												</Col>
											</Row>
										</Col>
										<Col span={12}>
											<Row>
												<Col xs={24} sm={24} md={12} lg={12} xl={12}>
													<BoxInfo
														classContent={'ph-order-history-purchase-list-responsive-order-container'}
														classTitle={'ph-order-history-purchase-list-responsive-order-title'}
														classDescription={'ph-order-history-purchase-list-responsive-order-description'}
														titleResponsive={'No.'}
														title={'Número de Orden:'}
														description={item.id_punto_pago}
													/>
												</Col>
												<Col className="ph-order-history-purchase-button-container" xs={24} sm={24} md={12} lg={12} xl={12}>
													<div>
														<Link to={`/tracking/detail/${item.trx_id}-${item.info.rut.replace(/[.-]/g, '')}`}>
															<Button className="ph-order-history-purchase-button">Detalle Orden</Button>
														</Link>
													</div>
												</Col>
											</Row>
										</Col>
									</Row>
								</div>
							))}
						</div>
					</div>
				</div>
			</>
		);
		} else {
			return <Loading />;
		}
	}
}
export default withTranslation('global')(PurchaseHistoryList);
