import { AxiosAdapter } from '../../../../../../_config/v2/adapters/axios/axios.adapter';
import { Http } from '../../../../../../_config/v2/adapters/axios/axios.protocols';
import { Enviroment } from '../../../../../../_config/constants/enviroment-v2.constant';

export class Service {
	async getPurchaseById(id, token) {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${Enviroment.API_PHARMACY}/purchase/purchase-by-id/${id}`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}
	async getReserveByOperationId(id, token) {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${Enviroment.API_PHARMACY}/reserve/reserve-by-operation-id/${id}`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}
	async resendEmail(operationId, country) {
		return new AxiosAdapter({
			method: Http.POST,
			url: `${Enviroment.FUNCTION_EMAIL}/${country}/reserve/send-reserve-info/${operationId}`,
			params: { resend: true },
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				throw e;
			});
	}
}
