/** @format */

import React, { Component } from "react";

import Images from "../../../../../../components/Commons/Images";

import "./style.scss";

export default class MainTitleInfo extends Component {
  render() {
    return (
      <>
        <div className="ph-order-detail-profile-title-container">
          <Images
            classContent={"ph-order-detail-profile-title-image-container"}
            classImage={"ph-order-detail-profile-title-image"}
            default={this.props.image}
            title={this.props.title}
          />
          <h3 className="ph-order-detail-profile-title">{this.props.title}</h3>
        </div>
      </>
    );
  }
}
