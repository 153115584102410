/** @format */
export default function BagTotal() {
	const handleBagTotal = (item) => {
		const delivery = item?.locals_group.map((element) => element.payment.delivery).reduce((prev, curr) => prev + curr, 0);
		const voucher = item?.locals_group.map((element) => element.payment.discount_voucher).reduce((prev, curr) => prev + curr, 0);
		const discount = item?.locals_group.map((element) => element.payment.discount).reduce((prev, curr) => prev + curr, 0);
		const pbm = item?.locals_group.map((element) => element.payment.discount_pbm).reduce((prev, curr) => prev + curr, 0);
		const locals_group = item?.locals_group.map((element) => {
			return element.products.map((e) => e).reduce((sum, item) => sum + item.price * item.quantity, 0);
		});
		const arrayTotal = locals_group.map((element) => element).reduce((prev, curr) => prev + curr, 0);
		const total = arrayTotal + delivery + pbm - voucher + discount;
		return total;
	};
	return {
		handleBagTotal,
	};
}
