/** @format */

import React from 'react'

import Images from '../../../../../../../../../../../../components/Commons/Images'

import './style.scss'

export default function ProductQuoteDispatchInfo(props) {
	return (
		<div className='ph-electronic-quote-dispatch-info-main-container'>
			<Images
				classContent={'ph-electronic-quote-dispatch-info-image-container'}
				classImage={'ph-electronic-quote-dispatch-info-image'}
				webp={props.image.webp}
				jp2={props.image.jp2}
				jxr={props.image.jxr}
				default={props.image.default}
				title={props.title}
				alt={props.title}
			/>
			<div className='ph-electronic-quote-dispatch-info-title-container'>
				<h3 className='ph-electronic-quote-dispatch-info-title'>
					<span className='ph-electronic-quote-dispatch-info-link'>{props.title}</span>
				</h3>
			</div>
		</div>
	)
}
