import { AxiosAdapter } from '../../../../_config/v2/adapters/axios/axios.adapter';
import { Http } from '../../../../_config/v2/adapters/axios/axios.protocols';
import { Enviroment } from '../../../../_config/constants/enviroment-v2.constant';

export class Service {
	async changePassword(item, token) {
		const data = { new_password: item.new_password, old_password: item.old_password };
		return new AxiosAdapter({
			method: Http.POST,
			url: `${Enviroment.API_PHARMACY}/user/change-password`,
			data: data,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}
}
