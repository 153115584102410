/** @format */

import React from 'react'

import { Row, Col } from 'antd'

import './style.scss'

export default function ProductDescriptionInfo(props) {
	return (
		<>
			<Row className='ph-product-detail-quote-description-title-container'>
				<Col xs={8} sm={8} md={8} lg={8} xl={8}>
					<h3 className='ph-product-detail-quote-description-title'>{props.title}</h3>
				</Col>
				<Col xs={16} sm={16} md={16} lg={16} xl={16}>
					<h3 className='ph-product-detail-quote-description-subtitle'>
						{props.description ? (
							props.description
						) : (
							<span className='ph-product-detail-quote-empty-field'>No disponible</span>
						)}
					</h3>
				</Col>
			</Row>
		</>
	)
}
