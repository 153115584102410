import { AxiosAdapter } from '../../../_config/v2/adapters/axios/axios.adapter';
import { Http } from '../../../_config/v2/adapters/axios/axios.protocols';
import { Enviroment } from '../../../_config/constants/enviroment-v2.constant';

export class Service {
	async register(body) {
		return new AxiosAdapter({
			method: Http.POST,
			url: `${Enviroment.API_PHARMACY}/user`,
			data: body,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}
}
