/** @format */

import React from 'react'

import { PagingInfo } from '@elastic/react-search-ui'

import './style.scss'

export default function PageInfo() {
	return (
		<PagingInfo
			view={({ start, end, totalResults }) => (
				<div className='ph-elastic-right-side-paging-info'>
					<span className='ph-elastic-right-side-paging-info-title'>
						Mostrando Productos:{' '}
						<strong className='ph-elastic-right-side-paging-info-subtitle'>
							{start} - {end}
						</strong>{' '}
						de un Total:{' '}
						<strong className='ph-elastic-right-side-paging-info-subtitle'>
							{totalResults}
						</strong>
					</span>
				</div>
			)}
		/>
	)
}
