/** @format */

import { Col, Form, Row } from 'antd';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import InputField from '../../../../components/Commons/Input';
import MainTitlePageAlt from '../../../../components/Commons/MainTitlePageAlt';
import MetaDescription from '../../../../components/Commons/MetaDescription';

import { default as lockIconGreen } from '../../../../components/Commons/Assets/Icons/Filled/Enabled/Argentina/candado_green.png';
import { default as lockIcon } from '../../../../components/Commons/Assets/Icons/Filled/Enabled/lock.svg';

import { ENV_COUNTRY } from '../../../../components/Commons/Hooks/Variables/Enviroment';
import CustomButton from '../../../../v2-components/Antd/Button';
import updatePasswordService from './services';
import './style.scss';

class UpdatePassword extends Component {
	state = {
		email: localStorage.getItem('userEmail'),
		loading: false,
	};

	handleOnChangePassword = async (item) => {
		this.setState({ loading: true });
		await updatePasswordService(this.state.email, item).then((response) => {
			this.setState({ loading: false });
			if (response) {
				this.props.history.push('/profile');
			}
		});
	};

	render() {
		const { t } = this.props;
		return (
			<>
				<MetaDescription
					title={'Red de Farmacias Online | Modificar clave de Usuario'}
					name={'description'}
					content={'Red de Farmacias Online | Busca y compra tus remedios o medicamentos a precios bajos con despacho a domicilio o retiro express...'}
				/>
				<div className="ph-update-password-global-container">
					<div className="ph-update-password-main-container">
						<MainTitlePageAlt
							title={'Modificar Contraseña'}
							descriptionProfile={t('profile.backToAcountLink')}
							image={ENV_COUNTRY === 'AR' ? lockIconGreen : lockIcon}
						/>
						<Row className="ph-update-password-container">
							<Col xs={24} sm={24} md={12} lg={12} xl={12}>
								<div className="ph-update-password-inner-container">
									<Form
										className="ph-update-password-form-container"
										name="normal_login"
										initialValues={{ remember: true }}
										onFinish={this.handleOnChangePassword}
									>
										<InputField
											className={'ph-update-password-field-input'}
											inputName={'old_password'}
											inputNameLabel={'Contraseña actual'}
											inputNameRule={true}
											inputNameMessage={'Ingresa tu contraseña actual'}
											inputNameType={'password'}
											inputNameIcon={''}
											inputNameRules={'rulesPassword'}
										/>
										<InputField
											className={'ph-update-password-field-input'}
											inputName={'new_password'}
											inputNameLabel={'Nueva Contraseña'}
											inputNameRule={true}
											inputNameMessage={'Ingresa una nueva contraseña'}
											inputNameType={'password'}
											inputNameIcon={''}
											dependencies={['password']}
											hasFeedback
											inputNameRules={'rulesPassword'}
										/>
										<Form.Item className="ph-update-password-button-container">
											<CustomButton className={'button-primary'} htmlType={'submit'} loadingSubmit={this.state.loading}>
												Aceptar
											</CustomButton>
										</Form.Item>
									</Form>
								</div>
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={12}>
								<div className="ph-update-password-description-container">
									<h1 className="ph-update-password-description">
										Para mantener la seguridad de tu cuenta, recuerda actualizar tu contraseña periodicamente, así proteges tus datos personales y formas de
										pago usadas.
									</h1>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</>
		);
	}
}
export default withTranslation('global')(UpdatePassword);
