/** @format */

import axios from 'axios';

import Notify from '../../../Notify';

import { ENV_USER } from '../../Variables/Enviroment';

export const updateUserDataService = async (item, data) => {
	console.log('updateUserDataService', item);
	let rutSplitted = item.rut.split('-');
	rutSplitted[1] = rutSplitted[1] === 'k' || rutSplitted[1] === 'K' ? rutSplitted[1].toUpperCase() : rutSplitted[1];
	let returnResponse;
	await axios({
		method: 'POST',
		url: `${ENV_USER}/user/update-info`,
		data: {
			email: item.email,
			info: {
				first_name: item.first_name,
				last_name: item.last_name,
				phone: item.phone.replace(/[+()/\s/]/g, ''),
				sex: item.sex,
				rut: rutSplitted.join('').replace(/[.]/g, ''),
				age: item.age.replace(/\//g, ''),
			},
		},
	})
		.then((response) => {
			if (!data) {
				if (response.data.statusCode === 200) {
					response.data.data.info.age = item.age;
					response.data.data.info.phone = item.phone;
					response.data.data.info.rut = item.rut;
					returnResponse = response.data.data.info;
					Notify({
						title: `¡Buen trabajo! ${response.data.data.info.first_name}`,
						colorTitle: 'ph-main-notification-success-title',
						description: 'Sus datos han sido actualizado correctamente.',
						time: 3,
						image: 'warning',
						placement: 'topRight',
					});
				} else {
					Notify({
						title: `Error: ${response.data.error}`,
						colorTitle: 'ph-main-notification-error-title',
						description: `Problemas con el servicio:   ${response.data.message}`,
						time: 3,
						image: 'error',
						placement: 'topRight',
					});
				}
			}
		})
		.catch(() => {
			Notify({
				title: 'Problemas de Conexión',
				colorTitle: 'ph-main-notification-error-title',
				description: 'Revisa tu conexión o ponte en contacto con nosotros',
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
};
