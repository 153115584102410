import { DATA_STEPS } from '../constants/data-steps.constant';
import { handleDescription } from './description.handle';

export const stepsHandle = (statusReserve) => {
	const filteredStatus = DATA_STEPS.filter((s) => statusReserve?.some((p) => p.status === s.status));
	const steps = filteredStatus.map((item, index) => {
		const description = statusReserve?.filter((item) => item.status === filteredStatus[index].status);
		console.log('description', description);
		return {
			title: item.title,
			description: handleDescription(description),
		};
	});
	return steps;
};
