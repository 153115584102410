/** @format */

import React from 'react'

import './style.scss'

export default class Spacer extends React.Component {
	render() {
		return <div className='ph-navbar-tools-container-spacer'></div>
	}
}
