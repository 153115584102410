/** @format */

import React from 'react';

import Images from '../../../../../../components/Commons/Images';

import { ENV_IMG } from '../../../../../../components/Commons/Hooks/Variables/Enviroment';

import './style.scss';

export default function ProductDispatchServiceInfo(props) {
	return (
		<>
			<div className="ph-product-detail-dispatch-service-info-main-container">
				<Images
					classContent={'ph-product-detail-dispatch-service-info-image-container'}
					classImage={'ph-product-detail-dispatch-service-info-image'}
					webp={`${ENV_IMG}/react-type-delibery/despacho-domicilio.webp`}
					jp2={`${ENV_IMG}/react-type-delibery/despacho-domicilio.jp2`}
					jxr={`${ENV_IMG}/react-type-delibery/despacho-domicilio.jxr`}
					default={`${ENV_IMG}/react-type-delibery/despacho-domicilio.png`}
					title={'servicios a domicilio'}
					alt={'servicios a domicilio'}
				/>
				<div>
					<h3 className="ph-product-detail-dispatch-service-info-title">
						<a className="ph-product-detail-dispatch-service-info-link" href="/type-delivery" rel="noopener noreferrer">
							Servicio exclusivo con despacho a domicilio
						</a>
					</h3>
				</div>
			</div>
			<div>
				<h4 className="ph-product-detail-dispatch-service-info-subtitle">{props.item.details[0].despatch_description}</h4>
			</div>
		</>
	);
}
