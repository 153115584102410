/** @format */

import React from 'react'

import Images from '../../../../../../components/Commons/Images'

import './style.scss'

export default function Description(props) {
	return (
		<div className='ph-product-detail-type-recepit-container'>
			<Images
				classContent='ph-product-detail-type-recepit-image-container'
				classImage={'ph-product-detail-type-recepit-image'}
				default={props.image}
				title={props.title}
				alt={props.title}
			/>
			<div className='ph-product-detail-type-recepit-title'>{props.title}</div>
		</div>
	)
}
