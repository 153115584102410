import { ENV_ELASTIC_INDEX_SEARCH } from '../../components/Commons//Hooks/Variables/Enviroment';
export const Country = {
	CL: {
		handleElasticPrecision: () => {
			return ENV_ELASTIC_INDEX_SEARCH;
		},
		handleFilters: (params) => {
			let filters = [];
			filters.push({
				field: 'laboratory',
				values: [capitalizeFirstLetter(params.laboratory)],
			});
			filters.push({
				field: 'min_price',
				values: [0],
				type: 'none',
			});
			return filters;
		},
		handleSort: () => {
			let sort = [
				{
					validate_recipe: 'asc',
				},
				{
					available: 'desc',
				},
			];
			return sort;
		},
	},
	AR: {
		handleElasticPrecision: () => {
			return ENV_ELASTIC_INDEX_SEARCH;
		},
		handleFilters: (params) => {
			let filters = [];
			filters.push({
				field: 'laboratory',
				values: [capitalizeFirstLetter(params.laboratory)],
			});
			filters.push({
				field: 'min_price',
				values: [0],
				type: 'none',
			});
			return filters;
		},
		handleSort: () => {
			let sort = [
				{
					validate_recipe: 'asc',
				},
				{
					stock: 'desc',
				},
			];
			return sort;
		},
	},
};

const capitalizeFirstLetter = (item) => {
	return item.charAt(0).toUpperCase() + item.slice(1);
};
