/** @format */

import React, { useState } from 'react';

import { Modal, Form, Menu, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import Images from '../../../../../../components/Commons/Images';
import InputField from '../../../../../../components/Commons/Input';
import Notify from '../../../../../../components/Commons/Notify';

import { AddressSearch } from '../../../../../../components/Commons/Hooks/Functions/AddressSearch';
import { AddressSetLocation } from '../../../../../../components/Commons/Hooks/Functions/AddressSetLocation';

import servicesAddress from '../../services';
import './style.scss';

export default function ModalAddAddress(props) {
	const [form] = Form.useForm();
	const [isListGoogleMaps, setListGoogleMaps] = useState(null);
	const [isNewAddress, setNewAddress] = useState(true);
	const [isAlias, setAlias] = useState(null);
	const [isSetAddress, setSetAddress] = useState(false);
	const [isSetLocation, setSetLocation] = useState(false);
	const [isLocation, setLocation] = useState(null);

	const handleSetAddress = async (item) => {
		setLocation(false)
		setSetLocation(true);
		setAlias(item.alias);
		AddressSearch(item.address).then((response) => {
			setListGoogleMaps(response);
			setSetLocation(false);
		});
	};

	const handleSetLocation = (item) => {
		setListGoogleMaps(false);
		AddressSetLocation(item, isAlias).then((response) => {
			if (response) {
				setLocation(response);
			}
			setNewAddress(false);
			form.resetFields(["address"])
		});
	};
	
	const handleUpdateAddress = () => {
		form.validateFields(['alias']).then(values => {
			let verifyAlias = true;
			let location = null;
			props.list.forEach((element) => {
				if (element.alias.toLowerCase() === values.alias.trim().toLowerCase()) {
					verifyAlias = false;
				}
			});
			if (verifyAlias) {
				location = {
					alias: values.alias,
					location: isLocation.location,
				};
				setSetAddress(true);
				servicesAddress.manageAddress(props.email, location, 'add').then(() => {
					setSetAddress(false);
					props.addAddressModal(false);
				});
			} else {
				Notify({
					title: `Error: Alias`,
					colorTitle: 'ph-main-notification-error-title',
					description: `El alias a usar ya se encuentra registrado`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			}
		});
	};

	return (
		<>
			<Modal
				wrapClassName="ph-add-address-modal-container"
				title="Agregar Dirección"
				visible={props.visible}
				onCancel={() => props.addAddressModal(false)}
				onOk={''}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
				width="600px"
			>
				<h3 className="ph-add-address-modal-title">¡Guarda las direcciones que desees para tus próximas búsquedas de productos!</h3>
				<Form onFinish={handleSetAddress} name="add_address" form={form}>
					<InputField
						className={'ph-add-address-field-input'}
						inputName={'alias'}
						inputNameLabel={'Ingresa un alias'}
						inputNameType={'text'}
						inputNameRule={true}
						inputNameMessage={'Ingresa tu alias......'}
						inputNameRules={'rulesAlias'}
					/>
					<div className="ph-add-address-field-container">
						<InputField
							className={'ph-add-address-field-location'}
							inputName={'address'}
							inputNameType={'text'}
							inputNameRule={true}
							inputNameMessage={'Ingresa tu Dirección......'}
							inputNameRules={'rulesAddress'}
						/>
						<Button className={'ph-add-address-field-button'} htmlType="submit" loading={isSetLocation} icon={<SearchOutlined />} />
						<div className="ph-add-address-modal">
							{isListGoogleMaps && (
								<Menu>
									{isListGoogleMaps.map((item, index) => (
										<Menu.Item onClick={() => handleSetLocation(item.name)} className="ph-add-address-modal-description" key={index}>
											<Images classContent={'ph-add-address-modal-image-container'} classImage={'ph-add-address-modal-image'} default={props.locationIcon} title={'address logo gris'} />
											{item.name}
										</Menu.Item>
									))}
								</Menu>
							)}
						</div>
					</div>
					{isLocation &&
						<h3  className="ph-add-address-modal-title" style={{"display":"flex", "flexDirection":"column"}}>
							Dirección seleccionada: 
							<span style={{"padding":"10px 0"}}> 
								{isLocation.location.formatted_address}
							</span> 
						</h3>
					}
				</Form>
				<Button form="myForm" key="submit" htmlType="submit" loading={isSetAddress} className="ph-add-address-field-main-button" disabled={isNewAddress} onClick={() => handleUpdateAddress()}>
					Aceptar
				</Button>
			</Modal>
		</>
	);
}
