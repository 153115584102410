/** @format */

import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

export default class MetaDescription extends Component {
	render() {
		return (
			<>
				<Helmet>
					<meta charSet='utf-8' />
					<title>{this.props.title}</title>
					<meta name={this.props.name} content={this.props.content} />
					{this.props.canonical && (
						<link rel='canonical' href={this.props.canonical} />
					)}
					{this.props.robots && (
						<meta name='robots' content='noindex, nofollow' />
					)}
				</Helmet>
			</>
		)
	}
}
