/** @format */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ENV_IMG } from '../../../../../components/Commons/Hooks/Variables/Enviroment';
import Images from '../../../../../components/Commons/Images';
import MetaDescription from '../../../../../components/Commons/MetaDescription';
import { RemoveLocalStorage } from '../../../../../components/HooksCheckout/Functions/RemoveLocalStorage';
import CustomButton from '../../../../../v2-components/Antd/Button';
import ScrollToTop from '../../../../../v2-components/ScrollToTop';

import './style.scss';

export default function SuccessReserve(props) {
	const { t } = useTranslation('global');
	const history = useHistory();

	RemoveLocalStorage();

	return (
		<section className="ph-success-global-container">
			<ScrollToTop />
			<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			<section className="ph-success-inner-container">
				<div className="ph-success-main-container">
					<Images
						classContent={'ph-succes-img-container'}
						classImage={'ph-succes-img'}
						webp={`${ENV_IMG}/react-checkout/master-recipe/succes.webp`}
						jp2={`${ENV_IMG}/react-checkout/master-recipe/succes.jxr`}
						jxr={`${ENV_IMG}/react-checkout/master-recipe/succes.jp2`}
						default={`${ENV_IMG}/react-checkout/master-recipe/succes.png`}
					/>

					<h1 className="ph-succes-title">
						¡Tu solicitud fue enviada a la farmacia! <br /> <span style={{ display: 'block', textAlign: 'center' }}>Espera su confirmación</span>
					</h1>

					<h4 className="ph-succes-description-span">
						<span className="ph-succes-description-span-i">*</span>
						Nuestro equipo se contactará contigo a la brevedad posible para la confirmación de tu pedido.
					</h4>
					<CustomButton className={'button-primary'} onClick={() => history.push('/')}>
						Continuar
					</CustomButton>
				</div>
			</section>
		</section>
	);
}
