/** @format */

import React, { Fragment } from 'react';

import Info from './components/Info';

export default function ProductQuoteDispatch(props) {
	return (
		<>
			{props.item.nearest_local.despatch_info && (
				<>
					{props.item.nearest_local.despatch_info.map((item, index) => (
						<Fragment key={index}>
							<Info title={item.title} product={props.item} subtitle={item.description} image={item.image} />
						</Fragment>
					))}
				</>
			)}
		</>
	);
}
