/** @format */

import React from 'react'

import DispatchService from './components/DispatchService'
import Mediclic from '../../pages/product/components/DetailSimple/components/ContactRational/components/Mediclic'

import { ENV_COUNTRY } from '../../../../components/Commons/Hooks/Variables/Enviroment'

import './style.scss'

export default function DispatchInfo(props) {
	return (
		<>
			<div className='ph-product-detail-dispatch-main-container'>
				{props.service && <DispatchService item={props.item} />}
				{props.item.details[0].prescription_type !== 'NP' &&
					props.item.details[0].master_category === 'Medicamentos' &&
					ENV_COUNTRY === 'CL' && <Mediclic />}
			</div>
		</>
	)
}
