/** @format */

import React from 'react';

import { useLocation } from 'react-router';

import MobileActions from './components/MobileActions';

import './style.scss';

const NavbarMobileTabs = () => {
	const location = useLocation();

	if (location.pathname !== '/cart') {
		return (
			<div className={'ph-header-mobile-footer-global-container'}>
				<MobileActions />
			</div>
		);
	} else {
		return '';
	}
};
export default NavbarMobileTabs;
