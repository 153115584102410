/** @format */

import React from 'react'

import Spacer from '../../../../../../components/Commons/Spacer'

import PromotionDiscount from './components/PromotionDiscount'
import PriceProduct from './components/PriceProduct'

import './style.scss'

export default function ProductPrice(props) {
	return (
		<>
			<div className='ph-product-detail-price-main-container'>
				<div className='ph-product-detail-price-container'>
					{!props.item.interface.promotions && (
						<h3 className='ph-product-detail-price-container-main-title'>Precio</h3>
					)}
					<PriceProduct item={props.item} />
					{props.item.interface?.promotion && (
						<>
							{(props.item.interface.promotions.description_promotion ||
								props.item.interface.promotions.percentaje_promotion ||
								props.item.interface.promotions.price_promotion) && <Spacer />}
							<PromotionDiscount promotion={props.item.interface.promotions} />
						</>
					)}
				</div>
			</div>
		</>
	)
}
