/** @format */

import React, { useEffect, useState, useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import { Spin } from 'antd'
import { ClockCircleOutlined, LoadingOutlined } from '@ant-design/icons'

import moment from 'moment'

import ExtendStopwatch from './ExtendStopwatch'

import './style.css'

export default function Countdown(props) {
	const location = useLocation()
	const [countdown, setCountdown] = useState({
		minutes: 5,
		seconds: 0,
	})
	const [modal, setModal] = useState(false)
	const [storageTime, setStorageTime] = useState(JSON.parse(localStorage.getItem('datePay')))

	const handleStopwatchStop = useCallback((timer) => {
		setModal(true)
		clearInterval(timer)
	}, [])

	useEffect(() => {
		let timer = null
		if (localStorage.getItem('datePay') !== null) {
			let datePay = storageTime
			let diffTime = moment(datePay).unix() - moment().unix()
			let duration = moment.duration(diffTime * 1000 + 3000, 'milliseconds')
			timer = setInterval(() => {
				duration = moment.duration(duration.asMilliseconds() - 1000, 'milliseconds')
				if (moment().isAfter(datePay)) handleStopwatchStop(timer)
				setCountdown({
					minutes: moment.duration(duration - 1000).minutes(),
					seconds: moment.duration(duration - 1000).seconds(),
				})
			}, 1000)
		}
		return () => clearInterval(timer)
	}, [location.pathname, handleStopwatchStop, storageTime])

	return (
		<div className='ph-checkout-countdown-container'>
			<ClockCircleOutlined className='ph-checkout-countdown-icon' />
			<span className='ph-checkout-countdown-description'>
				{countdown.minutes !== 5 ? (
					<>
						{countdown.minutes}:
						{countdown.seconds.toString().length === 1
							? '0' + countdown.seconds
							: countdown.seconds}
					</>
				) : (
					<Spin indicator={<LoadingOutlined className='ph-checkout-countdown-spin-icon' />} />
				)}
			</span>
			<ExtendStopwatch
				service={props.service}
				modal={modal}
				setModal={setModal}
				setStorageTime={setStorageTime}
			/>
		</div>
	)
}
