/** @format */

import React from 'react'

import Images from '../../../../../../../../components/Commons/Images'

import {
	ENV_COUNTRY,
	ENV_IMG_ROUTE,
} from '../../../../../../../../components/Commons/Hooks/Variables/Enviroment'

import './style.scss'

export default function PanelTitle(props) {
	return (
		<div className='ph-bag-electronic-cart-panel-title-main-container'>
			<Images
				classContent={'ph-bag-electronic-cart-panel-title-image-container'}
				classImage={'ph-bag-electronic-cart-panel-title-image'}
				webp={
					props.item.id_pharol === undefined
						? props.item.image
						: `${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${props.item.id_pharol}.webp`
				}
				default={
					props.item.id_pharol === undefined
						? props.item.image
						: `${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${props.item.id_pharol}.png`
				}
				title={props.item.meta_title}
			/>
			<div className='ph-bag-electronic-cart-panel-main-title-main-container'>
				<h4 className='ph-bag-electronic-cart-panel-main-title'>{props.item.meta_title}</h4>
				<h4 className='ph-bag-electronic-cart-panel-main-description'>
					{props.item.laboratory}
				</h4>
			</div>
		</div>
	)
}
