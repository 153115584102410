import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from 'antd'
import Images from "../../Images";
import { ENV_IMG_STATIC } from "../../Hooks/Variables/Enviroment";
import CountryValidator from "../../CountryValidator";

import './style.scss'

export const ModalTypeRecipes = ({ isModalOpen, handleOk, handleCancel}) => {
  const { t } = useTranslation('global')
  
  return (
    <Modal
      title={`Tipo de recetas`}
      visible={isModalOpen} 
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
        <div className="ph-modal-types-recipe-container">
            <Images
                classContent={'ph-modal-types-recipe-container-i'}
                classImage={'ph-modal-types-recipe-container-i'}
                default={`https://storage.googleapis.com/master-image-bucket/condicion-venta/Venta%20directa.png`}
                title={'Type Recipes icon no receta'}
                alt={'Type Recipes icon no receta'}
            />
            <h3 className="ph-modal-types-recipes-hero-title">{t('modal.typeRecipes.title.NP')}</h3>
            <CountryValidator
                countries={['CL']}
                component={
                    <p className="ph-modal-types-recipes-hero-parra"> Este producto no requiere receta.</p>
                }
            />
        </div>
        <div className="ph-modal-types-recipe-container">
            <Images
                classContent={'ph-modal-types-recipe-container-i'}
                classImage={'ph-modal-types-recipe-container-i'}
                default={`${ENV_IMG_STATIC}/type-recipes/Icon-wp-recipes.png`}
                webp={`${ENV_IMG_STATIC}/type-recipes/Icon-wp-recipes.webp`}
                jp2={`${ENV_IMG_STATIC}/type-recipes/Icon-wp-recipes.jp2`}
                jxr={`${ENV_IMG_STATIC}/type-recipes/Icon-wp-recipes.jxr`}
                title={'Type Recipes icon presentacion de receta'}
                alt={'Type Recipes icon presentacion de receta'}
            />
            <h3 className="ph-modal-types-recipes-hero-title">{t('modal.typeRecipes.title.WP')}</h3>
            <CountryValidator
                countries={['CL']}
                component={
                    <p className="ph-modal-types-recipes-hero-parra">Este documento es el formato más simple, una gran cantidad de medicamentos son regulados bajo esta condición. Se debe adjuntar al momento de la compra y solo presentar al retirar o recibir tu pedido.</p>
                }
            />

        </div>
        <div className="ph-modal-types-recipe-container">
            <Images
                classContent={'ph-modal-types-recipe-container-i'}
                classImage={'ph-modal-types-recipe-container-i'}
                default={`${ENV_IMG_STATIC}/type-recipes/Icon-RMRSCS-recipes.png`}
                webp={`${ENV_IMG_STATIC}/type-recipes/Icon-RMRSCS-recipes.webp`}
                jp2={`${ENV_IMG_STATIC}/type-recipes/Icon-RMRSCS-recipes.jp2`}
                jxr={`${ENV_IMG_STATIC}/type-recipes/Icon-RMRSCS-recipes.jxr`}
                title={'Type Recipes icon receta retenida'}
                alt={'Type Recipes icon receta retenida'}
            />
            <h3 className="ph-modal-types-recipes-hero-title">{t('modal.typeRecipes.title.RMRSCS')}</h3>
            <CountryValidator
                countries={['CL']}
                component={
                    <p className="ph-modal-types-recipes-hero-parra">Regula medicamentos que tienen mayor cuidado en su dispensación. Debe ser retenida por la farmacia, con el fin de evitar problemas sanitarios producto	de la venta indiscriminada, auto-medicación y mal uso.</p>
                }
            />       

        </div>
        <CountryValidator
            countries={['CL']}
            component={
                <div className="ph-modal-types-recipe-container">
                    <Images
                        classContent={'ph-modal-types-recipe-container-i'}
                        classImage={'ph-modal-types-recipe-container-i'}
                        default={`${ENV_IMG_STATIC}/type-recipes/Icon-RCH-recipes.png`}
                        webp={`${ENV_IMG_STATIC}/type-recipes/Icon-RCH-recipes.webp`}
                        jp2={`${ENV_IMG_STATIC}/type-recipes/Icon-RCH-recipes.jp2`}
                        jxr={`${ENV_IMG_STATIC}/type-recipes/Icon-RCH-recipes.jxr`}
                        title={'Type Recipes icon receta cheque'}
                        alt={'Type Recipes icon receta cheque'}
                    />
                    <h3 className="ph-modal-types-recipes-hero-title">{t('modal.typeRecipes.title.RMRCCS')}</h3>
                    <p className="ph-modal-types-recipes-hero-parra">Este tipo de receta tiene un manejo de gran rigurosidad y se debe retener en la farmacia. A diferencia de las otras recetas, su formato es
                                    doble y autocopiativo, posteriormente son remitidas al Instituto de Salud Pública (ISP).</p>
                </div>
            }
        />

    </Modal>
  );
};
