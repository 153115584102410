/** @format */

import React from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Button from 'antd/lib/button';
import MetaDescription from '../../components/Commons/MetaDescription';
import Images from '../../components/Commons/Images';
import { ENV_COUNTRY, ENV_IMG_STATIC } from '../../components/Commons/Hooks/Variables/Enviroment';

import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';

import './style.scss';

import translates from '../../components/Commons/translates';

export default function UploadPharmacy() {
	const { t } = useTranslation('global');

	return (
		<div>
			<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			<div className="ph-upload-pharmacy-header-container">
				<div className="ph-upload-pharmacy-header-contain">
					<Row justify="center">
						<Col>
							<ScrollAnimation animateIn="animate__fadeIn" delay={1000} animateOnce={true}>
								<Images
									classContent={'ph-types-recipe-logo-container'}
									classImage={'ph-types-recipe-logo-pharol'}
									default={`${ENV_IMG_STATIC}/logo-blanco-pharol.png`}
									webp={`${ENV_IMG_STATIC}/logo-blanco-pharol.webp`}
									jp2={`${ENV_IMG_STATIC}/logo-blanco-pharol.jp2`}
									jxr={`${ENV_IMG_STATIC}/logo-blanco-pharol.jxr`}
									title={'Upload pharmacy logo'}
									alt={'Upload pharmacy logo'}
								/>
							</ScrollAnimation>
						</Col>
					</Row>
					<Row justify="center">
						<Col justify="center">
							<ScrollAnimation animateIn="animate__bounceInRight" delay={1500} animateOnce={true}>
								<h1 className="ph-upload-pharmacy-title">¿TIENES UNA {translates.pharmacy[ENV_COUNTRY].toUpperCase()}?</h1>
							</ScrollAnimation>
						</Col>
					</Row>
					<Row justify="center">
						<Col>
							<ScrollAnimation animateIn="animate__bounceInLeft" delay={2000} animateOnce={true}>
								<span className="ph-upload-pharmacy-span">
									Súbela a nuestro Marketplace <a href="www.pharol.cl">Pharol </a>y podrás vender 24/7.
								</span>
							</ScrollAnimation>
						</Col>
					</Row>
				</div>
			</div>
			<div className="ph-upload-pharmacy-global-container">
				<Row justify="center">
					<Col justify="center">
						<h2 className="ph-upload-pharmacy-subtitle">¿Qué necesito?</h2>
					</Col>
				</Row>
				<Row justify="center">
					<Col xs={24} sm={24} md={24} lg={12} xl={12}>
						<ScrollAnimation animateIn="animate__pulse" animateOnce={true}>
							<Images
								classContent={'ph-types-recipe-container-image'}
								classImage={'ph-types-recipe-image-steps'}
								default={`${ENV_IMG_STATIC}/upload-pharmacy/img-step-one.png`}
								webp={`${ENV_IMG_STATIC}/upload-pharmacy/img-step-one.webp`}
								jp2={`${ENV_IMG_STATIC}/upload-pharmacy/img-step-one.jp2`}
								jxr={`${ENV_IMG_STATIC}/upload-pharmacy/img-step-one.jxr`}
								title={'Upload pharmacy step 1'}
								alt={'Upload pharmacy step 1'}
							/>
						</ScrollAnimation>
					</Col>
					<Col xs={24} sm={24} md={24} lg={12} xl={12} className="ph-upload-pharmacy-main-container">
						<Row className="ph-upload-pharmacy-inner-container">
							<Col>
								<ScrollAnimation animateIn="animate__pulse" animateOnce={true}>
									<Images
										classContent={'ph-upload-pharmacy-container-image'}
										classImage={'ph-upload-pharmacy-container-image-main '}
										default={`${ENV_IMG_STATIC}/upload-pharmacy/Icon-step-one.png`}
										webp={`${ENV_IMG_STATIC}/upload-pharmacy/Icon-step-one.webp`}
										jp2={`${ENV_IMG_STATIC}/upload-pharmacy/Icon-step-one.jp2`}
										jxr={`${ENV_IMG_STATIC}/upload-pharmacy/Icon-step-one.jxr`}
										title={'Upload pharmacy step 1'}
										alt={'Upload pharmacy step 1'}
									/>
								</ScrollAnimation>
							</Col>
							<Col>
								<h3 className="ph-upload-pharmacy-hero-title">Paso 1</h3>
							</Col>
						</Row>
						<ScrollAnimation animateIn="animate__bounceInRight" animateOut="animate__bounceOutLeft" animateOnce={true}>
							<h3 className="ph-upload-pharmacy-hero-subtitle">Tener tu {translates.pharmacy[ENV_COUNTRY].charAt(0).toLowerCase() + translates.pharmacy[ENV_COUNTRY].slice(1)} física</h3>
						</ScrollAnimation>
						<p className="ph-upload-pharmacy-hero-pharagraf">
							En Pharol, todo producto farmacéutico debe salir desde una {translates.pharmacy[ENV_COUNTRY].charAt(0).toLowerCase() + translates.pharmacy[ENV_COUNTRY].slice(1)} con resolución de
							instalación y funcionamiento vigente y debidamente autorizado por el ISP y/o Seremi de Salud.
						</p>
					</Col>
				</Row>
				<Row>
					<Col>
						<ScrollAnimation animateIn="animate__pulse" animateOnce={true}>
							<Images
								classContent={'ph-upload-pharmacy-divider-container'}
								classImage={'ph-upload-pharmacy-divider-img'}
								default={`${ENV_IMG_STATIC}/upload-pharmacy/img-divider.png`}
								webp={`${ENV_IMG_STATIC}/upload-pharmacy/img-divider.webp`}
								jp2={`${ENV_IMG_STATIC}/upload-pharmacy/img-divider.jp2`}
								jxr={`${ENV_IMG_STATIC}/upload-pharmacy/img-divider.jxr`}
								title={'Upload pharmacy divider'}
								alt={'Upload pharmacy divider'}
							/>
						</ScrollAnimation>
					</Col>
				</Row>
				<Row justify="center">
					<Col
						xs={{ span: 24, order: 2 }}
						sm={{ span: 24, order: 2 }}
						md={{ span: 24, order: 2 }}
						lg={{ span: 12, order: 1 }}
						xl={{ span: 12, order: 1 }}
						className="ph-upload-pharmacy-main-container"
					>
						<Row className="ph-upload-pharmacy-inner-container">
							<Col>
								<ScrollAnimation animateIn="animate__pulse" animateOnce={true}>
									<Images
										classContent={'ph-upload-pharmacy-container-image'}
										classImage={'ph-upload-pharmacy-container-image-main '}
										default={`${ENV_IMG_STATIC}/upload-pharmacy/Icon-step-two.png`}
										webp={`${ENV_IMG_STATIC}/upload-pharmacy/Icon-step-two.webp`}
										jp2={`${ENV_IMG_STATIC}/upload-pharmacy/Icon-step-two.jp2`}
										jxr={`${ENV_IMG_STATIC}/upload-pharmacy/Icon-step-two.jxr`}
										title={'Upload pharmacy step 2'}
										alt={'Upload pharmacy step 2'}
									/>
								</ScrollAnimation>
							</Col>
							<Col>
								<h3 className="ph-upload-pharmacy-hero-title">Paso 2</h3>
							</Col>
						</Row>
						<ScrollAnimation animateIn="animate__bounceInLeft" animateOut="animate__bounceOutLeft" animateOnce={true}>
							<h3 className="ph-upload-pharmacy-hero-subtitle">Estar dispuesto a vender online</h3>
						</ScrollAnimation>
						<p className="ph-upload-pharmacy-hero-pharagraf">
							El canal digital te da exposición, rompe las barreras geográficas y facilita la compra de tus productos de forma transparente y en simples pasos, con la opción de retiro en{' '}
							{translates.pharmacy[ENV_COUNTRY].charAt(0).toLowerCase() + translates.pharmacy[ENV_COUNTRY].slice(1)} o despacho a domicilio, durante los 365 días del año.
						</p>
					</Col>
					<Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 24, order: 1 }} lg={{ span: 12, order: 2 }} xl={{ span: 12, order: 2 }}>
						<ScrollAnimation animateIn="animate__pulse" animateOnce={true}>
							<Images
								classContent={'ph-types-recipe-container-image'}
								classImage={'ph-types-recipe-image-steps'}
								default={`${ENV_IMG_STATIC}/upload-pharmacy/img-step-two.png`}
								webp={`${ENV_IMG_STATIC}/upload-pharmacy/img-step-two.webp`}
								jp2={`${ENV_IMG_STATIC}/upload-pharmacy/img-step-two.jp2`}
								jxr={`${ENV_IMG_STATIC}/upload-pharmacy/img-step-two.jxr`}
								title={'Upload pharmacy step 2'}
								alt={'Upload pharmacy step 2'}
							/>
						</ScrollAnimation>
					</Col>
				</Row>
				<Row>
					<Col>
						<ScrollAnimation animateIn="animate__pulse" animateOnce={true}>
							<Images
								classContent={'ph-upload-pharmacy-divider-container'}
								classImage={'ph-upload-pharmacy-divider-img'}
								default={`${ENV_IMG_STATIC}/upload-pharmacy/img-divider.png`}
								webp={`${ENV_IMG_STATIC}/upload-pharmacy/img-divider.webp`}
								jp2={`${ENV_IMG_STATIC}/upload-pharmacy/img-divider.jp2`}
								jxr={`${ENV_IMG_STATIC}/upload-pharmacy/img-divider.jxr`}
								title={'Upload pharmacy divider'}
								alt={'Upload pharmacy divider'}
							/>
						</ScrollAnimation>
					</Col>
				</Row>
				<Row justify="center">
					<Col xs={24} sm={24} md={24} lg={12} xl={12}>
						<ScrollAnimation animateIn="animate__pulse" animateOnce={true}>
							<Images
								classContent={'ph-types-recipe-container-image'}
								classImage={'ph-types-recipe-image-steps'}
								default={`${ENV_IMG_STATIC}/upload-pharmacy/img-step-three.png`}
								webp={`${ENV_IMG_STATIC}/upload-pharmacy/img-step-three.webp`}
								jp2={`${ENV_IMG_STATIC}/upload-pharmacy/img-step-three.jp2`}
								jxr={`${ENV_IMG_STATIC}/upload-pharmacy/img-step-three.jxr`}
								title={'Upload pharmacy step 3'}
								alt={'Upload pharmacy step 3'}
							/>
						</ScrollAnimation>
					</Col>
					<Col xs={24} sm={24} md={24} lg={12} xl={12} className="ph-upload-pharmacy-main-container">
						<Row className="ph-upload-pharmacy-inner-container">
							<Col>
								<ScrollAnimation animateIn="animate__pulse" animateOnce={true}>
									<Images
										classContent={'ph-upload-pharmacy-container-image'}
										classImage={'ph-upload-pharmacy-container-image-main '}
										default={`${ENV_IMG_STATIC}/upload-pharmacy/Icon-step-three.png`}
										webp={`${ENV_IMG_STATIC}/upload-pharmacy/Icon-step-three.webp`}
										jp2={`${ENV_IMG_STATIC}/upload-pharmacy/Icon-step-three.jp2`}
										jxr={`${ENV_IMG_STATIC}/upload-pharmacy/Icon-step-three.jxr`}
										title={'Upload pharmacy step 3'}
										alt={'Upload pharmacy step 3'}
									/>
								</ScrollAnimation>
							</Col>
							<Col>
								<h3 className="ph-upload-pharmacy-hero-title">Paso 3</h3>
							</Col>
						</Row>
						<ScrollAnimation animateIn="animate__bounceInRight" animateOut="animate__bounceOutLeft" animateOnce={true}>
							<h3 className="ph-upload-pharmacy-hero-subtitle">Contar con un sistema de punto de venta</h3>
						</ScrollAnimation>
						<p className="ph-upload-pharmacy-hero-pharagraf">
							El cual permitirá ingresar el inventario de tu {translates.pharmacy[ENV_COUNTRY].charAt(0).toLowerCase() + translates.pharmacy[ENV_COUNTRY].slice(1)} en el canal digital, determinar
							precio, promociones, entre otros. ¡Tú decides qué productos farmacéuticos vender! Si no cuentas con un sistema de punto de venta, no hay problemas, ¡Nosotros te daremos soluciones!.
						</p>
					</Col>
				</Row>
				<Row>
					<Col>
						<ScrollAnimation animateIn="animate__pulse" animateOnce={true}>
							<Images
								classContent={'ph-upload-pharmacy-divider-container'}
								classImage={'ph-upload-pharmacy-divider-img'}
								default={`${ENV_IMG_STATIC}/upload-pharmacy/img-divider.png`}
								webp={`${ENV_IMG_STATIC}/upload-pharmacy/img-divider.webp`}
								jp2={`${ENV_IMG_STATIC}/upload-pharmacy/img-divider.jp2`}
								jxr={`${ENV_IMG_STATIC}/upload-pharmacy/img-divider.jxr`}
								title={'Upload pharmacy divider'}
								alt={'Upload pharmacy divider'}
							/>
						</ScrollAnimation>
					</Col>
				</Row>
				<Row justify="center">
					<Col
						xs={{ span: 24, order: 2 }}
						sm={{ span: 24, order: 2 }}
						md={{ span: 24, order: 2 }}
						lg={{ span: 12, order: 1 }}
						xl={{ span: 12, order: 1 }}
						className="ph-upload-pharmacy-main-container"
					>
						<Row className="ph-upload-pharmacy-inner-container">
							<Col>
								<ScrollAnimation animateIn="animate__pulse" animateOnce={true}>
									<Images
										classContent={'ph-upload-pharmacy-container-image'}
										classImage={'ph-upload-pharmacy-container-image-main '}
										default={`${ENV_IMG_STATIC}/upload-pharmacy/Icon-step-four.png`}
										webp={`${ENV_IMG_STATIC}/upload-pharmacy/Icon-step-four.webp`}
										jp2={`${ENV_IMG_STATIC}/upload-pharmacy/Icon-step-four.jp2`}
										jxr={`${ENV_IMG_STATIC}/upload-pharmacy/Icon-step-four.jxr`}
										title={'Upload pharmacy step 4'}
										alt={'Upload pharmacy step 4'}
									/>
								</ScrollAnimation>
							</Col>
							<Col>
								<h3 className="ph-upload-pharmacy-hero-title">Paso 4</h3>
							</Col>
						</Row>
						<p className="ph-upload-pharmacy-hero-pharagraf">Haz click en el siguiente botón y nos pondremos en contacto contigo.</p>
						<Button className="ph-upload-pharmacy-btn" href="https://pharol.cl/contact" target="_blank" rel="noopener noreferrer">
							CONTACTAR
						</Button>
					</Col>
					<Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 24, order: 1 }} lg={{ span: 12, order: 2 }} xl={{ span: 12, order: 2 }}>
						<ScrollAnimation animateIn="animate__pulse" animateOnce={true}>
							<Images
								classContent={'ph-types-recipe-container-image'}
								classImage={'ph-types-recipe-image-steps '}
								default={`${ENV_IMG_STATIC}/upload-pharmacy/img-step-four.png`}
								webp={`${ENV_IMG_STATIC}/upload-pharmacy/img-step-four.webp`}
								jp2={`${ENV_IMG_STATIC}/upload-pharmacy/img-step-four.jp2`}
								jxr={`${ENV_IMG_STATIC}/upload-pharmacy/img-step-four.jxr`}
								title={'Upload pharmacy step 4'}
								alt={'Upload pharmacy step 4'}
							/>
						</ScrollAnimation>
					</Col>
				</Row>
			</div>
		</div>
	);
}
