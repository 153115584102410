/** @format */

import React from 'react';

import { Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import MetaDescription from '../../components/Commons/MetaDescription';
import Images from '../../components/Commons/Images';
import { ENV_IMG_STATIC, ENV_COUNTRY } from '../../components/Commons/Hooks/Variables/Enviroment';

import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';

import './style.scss';

import translates from '../../components/Commons/translates';

export default function TypeRecipes() {
	const { t } = useTranslation('global');

	return (
		<div className="ph-types-recipes-global-container">
			<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			<div className="ph-types-recipes-header">
				<Row justify="center">
					<Col>
						<div className="ph-types-recipes-cont-title">
							<ScrollAnimation animateIn="animate__fadeInUp" delay={1000} animateOnce={true}>
								<h1 className="ph-types-recipes-title">RECETA MÉDICA</h1>
							</ScrollAnimation>
						</div>
					</Col>
				</Row>
				<Row justify="center">
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<div className="ph-types-recipes-cont-title">
							<ScrollAnimation animateIn="animate__fadeInUp" delay={1500} animateOnce={true}>
								<h2 className="ph-types-recipes-titleheader">¿Que es una receta médica?</h2>
							</ScrollAnimation>
						</div>
						<div className="ph-types-recipes-cont-title">
							<ScrollAnimation animateIn="animate__fadeInUp" delay={2000} animateOnce={true}>
								<p className="ph-types-recipes-subtitle">
									Es una orden suscrita por un profesional de la salud legalmente habilitado para hacerlo. La receta debe ser presentada a la{' '}
									{translates.pharmacy[ENV_COUNTRY].charAt(0).toLowerCase() + translates.pharmacy[ENV_COUNTRY].slice(1)} para que nuestros Químicos
									Farmacéuticos puedan verificar, validar y dispensar de manera correcta los medicamentos. Su falsificación, manipulación o mal uso está penada
									por ley.
								</p>
							</ScrollAnimation>
						</div>
					</Col>
				</Row>
			</div>
			<div className="ph-types-recipes-header">
				<Row justify="center">
					<Col>
						<div>
							<ScrollAnimation animateIn="animate__fadeInUp" delay={2500} animateOnce={true}>
								<Images
									classContent={'ph-types-recipe-container-image-principal'}
									classImage={'ph-types-recipe-image-principal'}
									default={`${ENV_IMG_STATIC}/type-recipes/Banner-recipes.png`}
									webp={`${ENV_IMG_STATIC}/type-recipes/Banner-recipes.webp`}
									jp2={`${ENV_IMG_STATIC}/type-recipes/Banner-recipes.jp2`}
									jxr={`${ENV_IMG_STATIC}/type-recipes/Banner-recipes.jxr`}
									title={'Type Recipes image initial'}
									alt={'Type Recipes image initial'}
								/>
							</ScrollAnimation>
						</div>
					</Col>
				</Row>
			</div>
			<div className="ph-types-recipes-main">
				<div className="ph-types-recipes-herosection">
					<Row justify="center">
						<Col>
							<ScrollAnimation animateIn="animate__fadeInUp" delay={3000} animateOnce={true}>
								<h2 className="ph-types-recipes-title-main">Tipos de receta</h2>
							</ScrollAnimation>
						</Col>
					</Row>
					<Row>
						<Col xs={24} sm={24} md={12} lg={12} xl={12}>
							<div className="ph-types-recipes-herosection-col">
								<div className="ph-types-recipes-box-hero">
									<ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
										<Images
											classContent={'ph-types-recipe-container-image-herosection'}
											classImage={'ph-types-recipe-image-herosection'}
											default={`${ENV_IMG_STATIC}/type-recipes/Img-recipe.png`}
											webp={`${ENV_IMG_STATIC}/type-recipes/Img-recipe.webp`}
											jp2={`${ENV_IMG_STATIC}/type-recipes/Img-recipe.jp2`}
											jxr={`${ENV_IMG_STATIC}/type-recipes/Img-recipe.jxr`}
											title={'Type Recipes image yellow secundary'}
											alt={'Type Recipes image yellow secundary'}
										/>
									</ScrollAnimation>
								</div>
							</div>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={12}>
							<div className="ph-types-recipes-herosection-col">
								<div>
									<ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true}>
										<div className="ph-types-recipes-hero">
											<Images
												classContent={'ph-types-recipe-container-i'}
												classImage={'ph-types-recipe-container-i'}
												default={`${ENV_IMG_STATIC}/type-recipes/Icon-wp-recipes.png`}
												webp={`${ENV_IMG_STATIC}/type-recipes/Icon-wp-recipes.webp`}
												jp2={`${ENV_IMG_STATIC}/type-recipes/Icon-wp-recipes.jp2`}
												jxr={`${ENV_IMG_STATIC}/type-recipes/Icon-wp-recipes.jxr`}
												title={'Type Recipes icon presentacion de receta'}
												alt={'Type Recipes icon presentacion de receta'}
											/>
											<h3 className="ph-types-recipes-hero-title">Presentación Receta Médica</h3>
										</div>
									</ScrollAnimation>
									<ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true}>
										<div className="ph-types-recipes-hero">
											<Images
												classContent={'ph-types-recipe-container-i'}
												classImage={'ph-types-recipe-container-i '}
												default={`${ENV_IMG_STATIC}/type-recipes/Icon-RCH-recipes.png`}
												webp={`${ENV_IMG_STATIC}/type-recipes/Icon-RCH-recipes.webp`}
												jp2={`${ENV_IMG_STATIC}/type-recipes/Icon-RCH-recipes.jp2`}
												jxr={`${ENV_IMG_STATIC}/type-recipes/Icon-RCH-recipes.jxr`}
												title={'Type Recipes icon receta cheque '}
												alt={'Type Recipes icon receta cheque'}
											/>
											<h3 className="ph-types-recipes-hero-title">Receta Médica Cheque</h3>
										</div>
									</ScrollAnimation>
									<ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true}>
										<div className="ph-types-recipes-hero">
											<Images
												classContent={'ph-types-recipe-container-i'}
												classImage={'ph-types-recipe-container-i '}
												default={`${ENV_IMG_STATIC}/type-recipes/Icon-RMRSCS-recipes.png`}
												webp={`${ENV_IMG_STATIC}/type-recipes/Icon-RMRSCS-recipes.webp`}
												jp2={`${ENV_IMG_STATIC}/type-recipes/Icon-RMRSCS-recipes.jp2`}
												jxr={`${ENV_IMG_STATIC}/type-recipes/Icon-RMRSCS-recipes.jxr`}
												title={'Type Recipes icon receta retenida '}
												alt={'Type Recipes icon receta retenida'}
											/>
											<h3 className="ph-types-recipes-hero-title">Receta Médica Retenida</h3>
										</div>
									</ScrollAnimation>
								</div>
								<ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true}>
									<div className="ph-types-recipes-hero">
										<Button
											className="ph-types-recipes-btn"
											href="https://pharolblog.com/tipos-de-recetas-medicas-conoce-las-diferencias/"
											target="_blank"
											rel="noopener noreferrer"
										>
											VER MÁS
										</Button>
									</div>
								</ScrollAnimation>
							</div>
						</Col>
					</Row>
				</div>
			</div>
			<div className="ph-types-recipes-header">
				<Row justify="center">
					<Col xs={24} sm={24} md={12} lg={8} xl={8}>
						<div className="ph-types-recipes-typescontainers">
							<div className="ph-types-recipes-hero">
								<ScrollAnimation animateIn="animate__bounce" animateOnce={true}>
									<Images
										classContent={'ph-types-recipe-container-i'}
										classImage={'ph-types-recipe-container-i'}
										default={`${ENV_IMG_STATIC}/type-recipes/Icon-wp-recipes.png`}
										webp={`${ENV_IMG_STATIC}/type-recipes/Icon-wp-recipes.webp`}
										jp2={`${ENV_IMG_STATIC}/type-recipes/Icon-wp-recipes.jp2`}
										jxr={`${ENV_IMG_STATIC}/type-recipes/Icon-wp-recipes.jxr`}
										title={'Type Recipes icon presentacion de receta'}
										alt={'Type Recipes icon presentacion de receta'}
									/>
								</ScrollAnimation>
								<h3 className="ph-types-recipes-hero-title">Presentación Receta Médica</h3>
							</div>
							<p className="ph-types-recipes-hero-parra">
								{' '}
								Este documento es el formato más simple, una gran cantidad de medicamentos son regulados bajo esta condición. Se debe adjuntar al momento de la
								compra y solo presentar al retirar o recibir tu pedido.
							</p>
						</div>
					</Col>
					<Col xs={24} sm={24} md={12} lg={8} xl={8}>
						<div className="ph-types-recipes-typescontainers">
							<div className="ph-types-recipes-hero">
								<ScrollAnimation animateIn="animate__bounce" animateOnce={true}>
									<Images
										classContent={'ph-types-recipe-container-i'}
										classImage={'ph-types-recipe-container-i '}
										default={`${ENV_IMG_STATIC}/type-recipes/Icon-RCH-recipes.png`}
										webp={`${ENV_IMG_STATIC}/type-recipes/Icon-RCH-recipes.webp`}
										jp2={`${ENV_IMG_STATIC}/type-recipes/Icon-RCH-recipes.jp2`}
										jxr={`${ENV_IMG_STATIC}/type-recipes/Icon-RCH-recipes.jxr`}
										title={'Type Recipes icon receta cheque '}
										alt={'Type Recipes icon receta cheque'}
									/>
								</ScrollAnimation>
								<h3 className="ph-types-recipes-hero-title">Receta Médica Cheque</h3>
							</div>
							<p className="ph-types-recipes-hero-parra">
								{' '}
								Este tipo de receta tiene un manejo de gran rigurosidad y se debe retener en la{' '}
								{translates.pharmacy[ENV_COUNTRY].charAt(0).toLowerCase() + translates.pharmacy[ENV_COUNTRY].slice(1)}. A diferencia de las otras recetas, su
								formato es doble y autocopiativo, posteriormente son remitidas al Instituto de Salud Pública (ISP).
							</p>
						</div>
					</Col>
					<Col xs={24} sm={24} md={12} lg={8} xl={8}>
						<div className="ph-types-recipes-typescontainers">
							<div className="ph-types-recipes-hero">
								<ScrollAnimation animateIn="animate__bounce" animateOnce={true}>
									<Images
										classContent={'ph-types-recipe-container-i'}
										classImage={'ph-types-recipe-container-i '}
										default={`${ENV_IMG_STATIC}/type-recipes/Icon-RMRSCS-recipes.png`}
										webp={`${ENV_IMG_STATIC}/type-recipes/Icon-RMRSCS-recipes.webp`}
										jp2={`${ENV_IMG_STATIC}/type-recipes/Icon-RMRSCS-recipes.jp2`}
										jxr={`${ENV_IMG_STATIC}/type-recipes/Icon-RMRSCS-recipes.jxr`}
										title={'Type Recipes icon receta retenida '}
										alt={'Type Recipes icon receta retenida'}
									/>
								</ScrollAnimation>
								<h3 className="ph-types-recipes-hero-title">Receta Médica Retenida</h3>
							</div>
							<p className="ph-types-recipes-hero-parra">
								{' '}
								Regula medicamentos que tienen mayor cuidado en su dispensación.Debe ser retenida por la{' '}
								{translates.pharmacy[ENV_COUNTRY].charAt(0).toLowerCase() + translates.pharmacy[ENV_COUNTRY].slice(1)}, con el fin de evitar problemas
								sanitarios producto de la venta indiscriminada, auto-medicación y mal uso.
							</p>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
}
