/** @format */

import { handleNotification } from "./components/BagNotification";
import { handleLocals } from "./components/BagLocals";
import { handleStock } from "./components/BagStock";
import { handleDispatch } from "./components/BagDispatch";
import { handleCleanDelivery } from "./components/BagCleanDelivery";
import BagVoucher from "./components/BagVoucher";
import { handleCountPharmacies } from "../../functions/BagCountPharmacies";

import { GetUserData } from "../../../../components/Commons/Hooks/Functions/GetUserData";

import { Calculation } from "./services";

export const BagCalculation = async (typeRetirement, saveCart) => {
  const { handleBagVoucher } = BagVoucher();
  let returnResponse;
  const cart = JSON.parse(localStorage.getItem("cart"));
  const location = JSON.parse(localStorage.getItem("locationStore"));
  const email = localStorage.getItem("userEmail");
  const userToken = localStorage.getItem("userToken");
  const locals = await handleLocals(cart);

  const products = await handleCleanDelivery(cart);
  console.log('products',products)
  const voucherPharmacies = await handleBagVoucher();
  let userData;
  if (email) userData = await GetUserData(email);

  await Calculation(
    products,
    location,
    email,
    userToken,
    voucherPharmacies,
    locals,
    typeRetirement,
    userData,
    saveCart,
    handleCountPharmacies()
  ).then((response) => {
    if (response) {
      if (response.valid_code === false)
        handleNotification(
          "Error",
          "error",
          "¡El cupón ingresado no es válido. Intenta nuevamente!"
        );
      if (response.voucher_code)
        localStorage.setItem("voucherCode", response.voucher_code);

      const stock = handleStock(response.products);
      if (stock)
        handleNotification(
          "Error",
          "error",
          "Algunos productos estan sin stock."
        );
      handleDispatch(response.locals_group);
    }
    returnResponse = response;
  });
  return returnResponse;
};
