/** @format */

import React, { Component } from 'react';

import { Col, Row } from 'antd';

import { ENV_PHAROLGO_TRACKING } from '../../../../../../components/Commons/Hooks/Variables/Enviroment';

import Images from '../../../../../../components/Commons/Images';

import ListItemInfo from './components/ListItemInfo';

import './style.scss';

export default class OrderDetailPharmacies extends Component {
	render() {
		console.log(this.props);
		return (
			<>
				<div className="ph-order-detail-pharmacies-main-container">
					{this.props.list.pharmacy.map(function (item, i) {
						return (
							<div className="ph-order-detail-pharmacies-container" key={i}>
								<Row>
									<Col span={24}>
										<ListItemInfo classDescription={'ph-order-detail-pharmacies-list-font-weight'} title={'Farmacia:'} description={item.pharmacy} />
									</Col>
									<Col xs={24} sm={24} md={12} lg={12} xl={12}>
										<ListItemInfo title={'Comuna:'} description={item.commune} />
										<ListItemInfo title={'Región:'} description={item.region} />
										<ListItemInfo title={'Dirección:'} description={item.direction} />
									</Col>
									<Col xs={24} sm={24} md={12} lg={12} xl={12}>
										<ListItemInfo title={'Local:'} description={item.local} />
										<ListItemInfo title={'Distancia:'} description={item.distance} />
										<ListItemInfo title={'Fecha Seleccionada:'} description={item.schedule_selected} />
									</Col>
								</Row>
								{item.pharolgo_key && (
									<>
										<div className="ph-order-detail-pharmacies-map-content">
											<Images
												classContent={'ph-order-detail-pharmacies-map-image-container'}
												classImage={'ph-order-detail-pharmacies-map-image'}
												default={`https://storage.googleapis.com/master-image-bucket/type-delivery/Icono%20Despacho.png`}
												title={'domicilio'}
											/>
											<h3 className="ph-order-detail-pharmacies-map-title">Recorrido del Despachaor</h3>
										</div>
										<iframe
											className="ph-order-detail-pharmacies-map-iframe"
											title="Recorrido en Tiempo real"
											width="100%"
											height="400"
											src={`${ENV_PHAROLGO_TRACKING}/?search=${item.pharolgo_key}&show_only_map=true`}
										></iframe>
									</>
								)}
							</div>
						);
					})}
				</div>
			</>
		);
	}
}
