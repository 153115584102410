/** @format */

import React from 'react';

import './style.scss';

import Button from 'antd/lib/button';

class BtnSubmit extends React.Component {
	render() {
		return (
			<>
				<Button href={this.props.link} loading={this.props.loadingSubmit} htmlType={this.props.htmlType} target={this.props.targetPage} onClick={this.props.onClick} className={this.props.classButton}>
					{this.props.labelName}
				</Button>
			</>
		);
	}
}

export default BtnSubmit;
