/** @format */

import React, { Component } from 'react'

import Description from './components/Description'

export default class CheckoutTypeRecipe extends Component {
	render() {
		return (
			<>
				{(this.props.item.prescription_type === 'WP' ||
					this.props.item.prescription_type === 'APR' ||
					this.props.item.prescription_type === 'MRE') && (
					<Description
						title={'Presentación Receta Médica'}
						description={`${this.props.item.prescription_type}`}
						image={
							'https://storage.googleapis.com/master-image-bucket/condicion-venta/Receta%20me%CC%81dica.png'
						}
					/>
				)}
				{this.props.item.prescription_type === 'RMRCCS' && (
					<Description
						title={'Receta Médica Retenida'}
						description={`${this.props.item.prescription_type}`}
						image={
							'https://storage.googleapis.com/master-image-bucket/condicion-venta/Receta%20me%CC%81dica%20retenida.png'
						}
					/>
				)}
				{this.props.item.prescription_type === 'RMRSCS' && (
					<Description
						title={'Receta Médica Retenida'}
						description={`${this.props.item.prescription_type}`}
						image={
							'https://storage.googleapis.com/master-image-bucket/condicion-venta/Receta%20retenido%20sin%20control%20de%20stock.png'
						}
					/>
				)}
				{this.props.item.prescription_type === 'RCH' && (
					<Description
						title={'Receta Médica Cheque'}
						description={`${this.props.item.prescription_type}`}
						image={
							'https://storage.googleapis.com/master-image-bucket/condicion-venta/Receta%20me%CC%81dica%20cheque.png'
						}
					/>
				)}
				{this.props.item.bioequivalent === 1 && (
					<Description
						title={'Medicamento Bioequivalente'}
						description={'MB'}
						image={
							'https://storage.googleapis.com/master-image-bucket/condicion-venta/Bioequivalente.png'
						}
					/>
				)}
				{this.props.item.cooled === true && (
					<Description
						title={'Cadena de Frío'}
						description={'Condición de almacenaje'}
						image={
							'https://storage.googleapis.com/master-image-bucket/condicion-venta/Iconos%20Recetas%20Pharol_Mesa%20de%20trabajo%201%20copia%208.png'
						}
					/>
				)}
			</>
		)
	}
}
