export const Country = {
	CL: {
		handleValidateButton(customerCoverageType = null, isBagRecipe) {
			if (isBagRecipe) {
				return true;
			} else {
				return false;
			}
		},
	},
	AR: {
		handleValidateButton(customerCoverageType, isBagRecipe) {
			if (customerCoverageType !== 'particular' && isBagRecipe) {
				return true;
			} else {
				return false;
			}
		},
	},
};
