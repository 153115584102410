import { Col, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { GetLocalStorageValue } from '../../../../_config/adapters/local-storage/get-local-storage-value.adapter';
import CustomButton from '../../../../v2-components/Antd/Button';
import CustomCard from '../../../../v2-components/Antd/Card';
import CustomDescriptions from '../../../../v2-components/Antd/Descriptions';
import CustomDrawer from '../../../../v2-components/Antd/Drawer';
import CustomFormItem from '../../../../v2-components/Antd/FormItem';
import CustomInput from '../../../../v2-components/Antd/Input';
import CustomLink from '../../../../v2-components/Antd/Link';
import { CustomNotification } from '../../../../v2-components/Antd/Notification';
import { NOTIFICATION } from '../../../../v2-components/Antd/Notification/constant';
import LoadingModal from '../../../../v2-components/LoadingModal';
import LoadingPage from '../../../../v2-components/LoadingPage';
import { VALIDATION } from '../../../../v2-config/constants/validations.constant';

import { Service } from './service';
import './style.scss';

export default function ProfilePersonalPage() {
	const [form] = Form.useForm();
	const [open, setOpen] = useState(false);
	const [user, setUser] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [openLoadingModal, setOpenLoadingModal] = useState(false);
	const [isLoadingModal, setIsLoadingModal] = useState(false);
	const { token } = GetLocalStorageValue('userMainInfo');

	useEffect(() => {
		new Service()
			.getUser(token)
			.then((response) => {
				setUser(response.data[0]);
				setIsLoading(false);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [token]);

	const showDrawer = () => {
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
		form.resetFields();
	};
	const items = [
		{
			key: '1',
			label: 'Nombre',
			children: user?.first_name,
		},
		{
			key: '2',
			label: 'Apellido',
			children: user?.last_name,
		},
		{
			key: '3',
			label: 'Correo',
			children: user?.email,
		},
		{
			key: '4',
			label: 'Teléfono',
			children: user?.phone,
		},
		{
			key: '5',
			label: 'Rut',
			children: user?.rut,
		},
		{
			key: '6',
			label: 'Fecha de Nacimiento',
			children: user?.birthdate,
		},
	];
	// var info = {
	// 	age: item.age.replace(/[/]/g, ''),
	// 	email: item.email,
	// 	first_name: item.first_name,
	// 	last_name: item.last_name,
	// 	location: `${item.isUserAddress.region}, ${item.isUserAddress.street}, ${item.isUserAddress.commune}`,
	// 	pharmacy: [...item.pharmacy],
	// 	phone: item.phone.replace(/[+()/\s/]/g, ''),
	// 	policies: item.agreement,
	// 	rut: item.rut.replace(/[+()/\s/]/g, ''),
	// 	sex: item.sex,
	// 	type_retirement: item.type_retirement,
	// 	code_voucher: localStorage.getItem('voucherCode') || null,
	// 	coverage: coverage,
	// };

	const handleFinish = (item) => {
		setOpenLoadingModal(true);
		setIsLoadingModal(true);
		const { email, ...itemWithoutEmail } = item;
		new Service()
			.updateUser({ ...itemWithoutEmail }, token)
			.then((response) => {
				setIsLoadingModal(false);
				setOpenLoadingModal(false);
				setOpen(false);
				CustomNotification({ type: NOTIFICATION.TYPE.SUCCESS, message: NOTIFICATION.MESSAGE.SUCCESS, description: 'Usuario actualizado correctamente' });
				setUser(response.data);
				console.log('response', response);

				form.resetFields();
			})
			.catch((error) => {
				console.log(error);
			});
	};

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<>
			<Row justify="center" className="personal-content">
				<Col md={18} xs={22}>
					<Row justify="left">
						<h3>Datos personales</h3>
					</Row>
					<Row justify="left">
						<CustomLink className={'link-primary'} to={'/profile-info'}>
							Volver
						</CustomLink>
					</Row>
					<br></br>
					<CustomCard
						className={'card-primary'}
						title="Mis Datos"
						extra={
							<CustomLink
								className={'link-primary'}
								onClick={() => {
									console.log('aqui');
									showDrawer();
								}}
							>
								Editar
							</CustomLink>
						}
					>
						<CustomDescriptions items={items} column={1} />
					</CustomCard>
				</Col>

				<CustomDrawer title="Editar datos personales" placement="right" onClose={onClose} visible={open} className={'drawer-default'}>
					<Form form={form} layout="vertical" onFinish={handleFinish} initialValues={user}>
						<CustomFormItem className={'form-primary'} label={'Nombre'} name={'first_name'} rules={VALIDATION.min_character}>
							<CustomInput className={'input-primary'} placeholder={'María'} />
						</CustomFormItem>
						<CustomFormItem className={'form-primary'} label={'Apellido'} name={'last_name'} rules={VALIDATION.min_character}>
							<CustomInput className={'input-primary'} placeholder={'Sanchez'} />
						</CustomFormItem>
						<CustomFormItem className={'form-primary'} label={'Correo Electrónico'} name={'email'} rules={VALIDATION.email_format}>
							<CustomInput className={'input-primary'} disabled />
						</CustomFormItem>
						<CustomFormItem className={'form-primary'} label={'Teléfono'} name={'phone'} rules={VALIDATION.phone_number_edition}>
							<CustomInput className={'input-primary'} placeholder={'1 1111 1111'} />
						</CustomFormItem>
						<CustomFormItem className={'form-primary'} label={'Rut'} name={'rut'} rules={VALIDATION.rut_edition}>
							<CustomInput className={'input-primary'} placeholder={'11.111.111-1'} />
						</CustomFormItem>
						<CustomFormItem className={'form-primary'} label={'Fecha de Nacimiento'} name={'birthdate'} rules={VALIDATION.date}>
							<CustomInput className={'input-primary'} placeholder={'11/11/1911'} />
						</CustomFormItem>
						<CustomFormItem>
							<CustomButton className={'button-primary'} htmlType="submit">
								Guardar
							</CustomButton>{' '}
						</CustomFormItem>
					</Form>
				</CustomDrawer>
				<LoadingModal loading={isLoadingModal} visible={openLoadingModal} />
			</Row>
		</>
	);
}
