/** @format */

import { AimOutlined, FieldTimeOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Col, Row, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
// import { GetLocalStorageValue } from '../../_config/adapters/local-storage/get-local-storage-value.adapter';
import { PixelFacebook } from '../../components/Commons/Hooks/Functions/PixelFacebook';
import { ENV_ADDRESS } from '../../components/Commons/Hooks/Variables/Enviroment';
import MapLeaft from '../../components/Commons/MapLeaft';
import MetaDescription from '../../components/Commons/MetaDescription';
import Spacer from '../../components/Commons/Spacer';
import './style.scss';

const { Title, Text } = Typography;

export default function Contact() {
	const { t } = useTranslation('global');
	const [isContactAddress, setContactAddress] = useState(null);
	useEffect(() => {
		PixelFacebook('Contact');
		let address = ENV_ADDRESS.split(',');
		setContactAddress({ lat: parseFloat(address[3]), lng: parseFloat(address[4]) });
	}, []);

	// const locals = GetLocalStorageValue('mapPharmacy');
	// const mainLocal = locals[0]?.locals?.find((local) => local.code_local === '1');

	
	return (
		<>
			<Helmet>
				<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			</Helmet>
			<div className="ph-contact-global-container">
				<div className="ph-contact-container">
					<div className="ph-contact-image-container">
						<div className="ph-contact-description-main-container">
							<div className="ph-contact-description-main-container">
								<div className="ph-contact-description-container">
									<Title className="ph-contact-title">¡Contáctanos!</Title>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div className="ph-contact-info-container">
						<Row>
							<Col xs={24} sm={24} md={8} lg={8} xl={8}>
								<div className="ph-contact-info-inner-container">
									<Space direction="vertical" className="ph-contact-info-text-container">
										<Title className="ph-contact-info-title">Información de Contacto:</Title>
										<Space direction="horizontal">
											<AimOutlined className="ph-contact-info-description-icon" />
											<Text className="ph-contact-info-description">San Pablo 3136, Santiago, Región Metropolitana De Santiago</Text>
										</Space>
										<Space direction="horizontal">
											<PhoneOutlined className="ph-contact-info-description-icon" />
											<Text className="ph-contact-info-description">56964763510</Text>
										</Space>
										<Space direction="horizontal">
											<MailOutlined className="ph-contact-info-description-icon" />
											<Text className="ph-contact-info-description">contactofarmaciajvf@gmail.com</Text>
										</Space>
										<Space direction="horizontal">
											<FieldTimeOutlined className="ph-contact-info-description-icon" />
											<Text className="ph-contact-info-description">
												Lunes a viernes de 9 a 18hs. <br /> Sábados cerrado. <br /> Domingos y festivos cerrado.
											</Text>
										</Space>
									</Space>
								</div>
							</Col>
							<Spacer />
							<Col xs={24} sm={24} md={15} lg={15} xl={15} className="ph-contact-col-custom-container">
								<div className="ph-contact-map-container">
									{isContactAddress && <MapLeaft center={[isContactAddress.lat, isContactAddress.lng]} zoom={15} title={'JVF'} />}
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		</>
	);
}
