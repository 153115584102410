/** @format */

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';

import { PixelFacebook } from '../../../components/Commons/Hooks/Functions/PixelFacebook';
import Images from '../../../components/Commons/Images';
import MetaDescription from '../../../components/Commons/MetaDescription';
import CustomButton from '../../../v2-components/Antd/Button';
import './style.scss';

export default function TokenEmail() {
	const { t } = useTranslation('global');
	const history = useHistory();

	const handleRedirect = () => {
		history.push('/');
	};

	useEffect(() => {
		PixelFacebook('CompleteRegistration');
	}, []);
	return (
		<>
			<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			<div className="ph-token-global-container">
				<section className="ph-token-main-container">
					<div className="ph-token-inner-container">
						<Images
							classContent={'ph-token-img-container'}
							classImage={'ph-token-img'}
							default={'https://storage.googleapis.com/master-image-bucket/404/Iconos%20Banners%20Compra_Mesa%20de%20trabajo%201%20copia%2034%202.jpg'}
						/>
						<h1 className="ph-token-succes-title">¡Hola!</h1>
						<h4 className="ph-token-description">
							¡Estás a solo un paso de disfrutar de todos sus beneficios! Revisa tu correo electrónico, para activar tu cuenta.
						</h4>
						<div className="ph-token-payment-button-container">
							<CustomButton onClick={handleRedirect} className={'button-primary'}>
								Volver al Inicio
							</CustomButton>
						</div>
					</div>
				</section>
			</div>
		</>
	);
}
