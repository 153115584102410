/** @format */

import axios from 'axios'

import Notify from '../../../../components/Commons/Notify'

import { ENV_CHECKOUT, ENV_COUNTRY } from '../../../../components/Commons/Hooks/Variables/Enviroment'

export async function serviceUploadImage(item, recipeOrRut) {
	await Promise.all(
		item.map(async (product, index) => {
			if (!!!product[recipeOrRut] || typeof product[recipeOrRut] === 'string') return
			let data = new FormData()
			data.append('file', product[recipeOrRut])
			await axios({
				method: 'POST',
				url: `${ENV_CHECKOUT}/upload/${ENV_COUNTRY}/${recipeOrRut}`,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				data,
			}).then((response) => {
				if (response.data.statusCode === 200) {
					item[index][recipeOrRut] = response.data.data[0].url
				}
			})
		})
	)
	let validateUpload = true
	item.forEach((element) => {
		if (!!element[recipeOrRut] && typeof element[recipeOrRut] === 'object') {
			validateUpload = false
		}
	})
	if (validateUpload) return item
	else {
		Notify({
			title: `Advertencia!`,
			colorTitle: 'ph-main-notification-warning-title',
			description: `Hubo un error al intentar subir las imágenes, contacte con Pharol si el problema persiste.`,
			time: 3,
			image: 'error',
			placement: 'topRight',
			key: 1,
		})
		return null
	}
}
