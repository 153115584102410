/** @format */

import React from 'react'

import Images from '../../../../../../../../components/Commons/Images'

import './style.scss'

export default function ProductRectangleInfo(props) {
	return (
		<>
			<div className='ph-product-detail-rectangle-info-main-container'>
				<Images
					classContent={'ph-product-detail-rectangle-main-image-container'}
					classImage={'ph-product-detail-rectangle-main-image'}
					default={props.image}
					title={props.title}
					alt={props.title}
				/>
				<div>
					<h3 className='ph-product-detail-rectangle-info-title'>{props.title}</h3>
					<h4 className='ph-product-detail-rectangle-info-subtitle'>{props.subtitle}</h4>
					{props.link ? (
						<a href={`${props.link}`} rel='noopener noreferrer'>
							<h4 className='ph-product-detail-rectangle-info-description-link'>
								{props.description}
							</h4>
						</a>
					) : (
						<h4 className='ph-product-detail-rectangle-info-description'>
							{props.description}
						</h4>
					)}
				</div>
			</div>
		</>
	)
}
