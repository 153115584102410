/** @format */

import React from 'react';

import { Link } from 'react-router-dom';

import Images from '../../../../../../components/Commons/Images';

import favoriteIcon from '../../../../../../components/Commons/Assets/Icons/Filled/EnabledOpacity/favorite.svg';

import './style.scss';

export default function NoFavorites() {
	return (
		<div className="ph-profile-no-favorites-global-container">
			<div className="ph-profile-no-favorites-main-container">
				<Images classContent={'ph-profile-no-favorites-image-container'} classImage={'ph-profile-no-favorites-image'} default={favoriteIcon} title={'bolsa vacia'} />
				<h2 className="ph-profile-no-favorites-title">No hay productos!</h2>
				<h3 className="ph-profile-no-favorites-description">Consulte nuestro catálogo de productos para disfrutar de su lista personalizada...</h3>
				<Link className="ph-profile-no-favorites-link" to="/">
					Volver al inicio
				</Link>
			</div>
		</div>
	);
}
