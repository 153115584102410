/** @format */

import React, { useEffect } from 'react'

import { setGlobal, useGlobal } from 'reactn'

import { Radio, Form, Select, Spin } from 'antd'

import { LoadingOutlined } from '@ant-design/icons'

import Spacer from '../../../../../../components/Commons/Spacer'
import Images from '../../../../../../components/Commons/Images'

import { formatPrice } from '../../../../../../components/Commons/Hooks/Functions/FormatPrice'

import { rulesValidation } from '../../../../../../components/Commons/Input/rules'

import { ServicePrice } from './services'

import './style.scss'

const { Option } = Select

export default function ServicePlans(props) {
	const [isTotalServices] = useGlobal('totalServices')
	const handleSelectServicePlanOption = async () => {
		setGlobal({
			totalServices: null,
		})
		props.serviceForm.validateFields().then((item) => {
			ServicePrice(item, props.item).then((response) => {
				if (response) {
					setGlobal({
						totalServices: response,
					})
				}
			})
		})
	}

	useEffect(() => {
		setTimeout(async () => {
			let data = {}
			props.item.plan_features.map((item) => {
				return (data = { ...data, [item.plan_name]: item.options[0].value })
			})
			if (props.item.delivery_price) {
				data = { ...data, commune: props.item.delivery_price[0].condition.commune }
			}
			props.serviceForm.setFieldsValue(data)
			await ServicePrice(data, props.item).then((response) => {
				if (response) {
					setGlobal({
						totalServices: response,
					})
				}
			})
		}, 300)
	}, [props.item.plan_features, props.serviceForm, props.item.delivery_price, props.item])

	return (
		<div className='ph-service-tabs-plans-forms-main-container'>
			{props.item.plan_features && (
				<>
					<Form name='select_plans' form={props.serviceForm}>
						{props.item.plan_features.map((item, index) => (
							<div key={index} className='ph-service-tabs-plans-options-container'>
								<div className='ph-service-tabs-plans-options-title-container'>
									<h4 className='ph-service-tabs-plans-options-title'>{item.title}</h4>
								</div>
								<Spacer />
								<div className='ph-service-tabs-plans-options-select-container'>
									<Form.Item name={item.plan_name} rules={rulesValidation.rulesRequired}>
										<Radio.Group
											value={6}
											name={item.plan_name}
											onChange={() => handleSelectServicePlanOption()}>
											{item.options.map((element, i) => (
												<Radio key={i} value={element.value} checked>
													<span className='ph-service-tabs-plans-options-select-title-container'>
														{element.title}
													</span>
												</Radio>
											))}
										</Radio.Group>
									</Form.Item>
								</div>
							</div>
						))}
						{props.item.delivery_price && (
							<>
								<div className='ph-service-tabs-plans-options-2-container'>
									<div className='ph-service-tabs-plans-options-2-title-container'>
										<h4 className='ph-service-tabs-plans-options-title'>
											Selecciona Comuna
										</h4>
										{isTotalServices ? (
											<>
												<h4 className='ph-service-tabs-plans-options-commune-title'>
													Costo despacho (mensual):{' '}
													<span className='ph-service-tabs-plans-options-commune-title-price'>
														$ {formatPrice(isTotalServices.price_delivery_unit)}
													</span>
												</h4>
												<h4 className='ph-service-tabs-plans-options-commune-description'>
													Costo despacho total ({isTotalServices.delivery_units}{' '}
													meses):{' '}
													<span className='ph-service-tabs-plans-options-commune-description-price'>
														$ {formatPrice(isTotalServices.price_delivery)}
													</span>
												</h4>
											</>
										) : (
											<div className='ph-service-tabs-plans-options-commune-loading-container'>
												<Spin
													indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />}
												/>
											</div>
										)}
									</div>
									<Spacer />
									<div className='ph-service-tabs-plans-options-select-commune-container'>
										<Form.Item name={'commune'} rules={rulesValidation.rulesRequired}>
											<Select
												showSearch
												className='ph-service-tabs-plans-options-select-commune-selector'
												placeholder='Comuna'
												style={{ width: '100%' }}
												allowClear
												onChange={() => handleSelectServicePlanOption()}
												optionFilterProp='children'
												filterOption={(input, option) =>
													option.children.toLowerCase().indexOf(input.toLowerCase()) >=
													0
												}
												filterSort={(optionA, optionB) =>
													optionA.children
														.toLowerCase()
														.localeCompare(optionB.children.toLowerCase())
												}>
												{props.item.delivery_price.map((item, index) => (
													<Option value={item.condition.commune} key={index}>
														{item.condition.commune}
													</Option>
												))}
											</Select>
										</Form.Item>
									</div>
								</div>
								{props.item.service_provider.aditional_info && (
									<>
										{props.item.service_provider.aditional_info.delivery_description && (
											<div>
												<h4 className='ph-service-tabs-plans-options-aditional-info-title'>
													**{' '}
													{
														props.item.service_provider.aditional_info
															.delivery_description
													}
													.
												</h4>
											</div>
										)}
										{props.item.service_provider.aditional_info.device_description && (
											<div>
												<h4 className='ph-service-tabs-plans-options-aditional-info-title-2'>
													(
													<span className='ph-service-tabs-plans-options-aditional-info-title-2-asterisk'>
														*
													</span>
													){' '}
													{
														props.item.service_provider.aditional_info
															.device_description
													}
													.
												</h4>
											</div>
										)}
									</>
								)}
							</>
						)}
					</Form>
					<div>
						<Images
							classContent={'ph-service-tabs-plans-main-logo-image-container'}
							classImage={'ph-service-tabs-plans-main-logo-image'}
							default={props.item.service_provider.image}
							title={'Accuhealth Logo'}
							alt={'Accuhealth Logo'}
						/>
					</div>
				</>
			)}
		</div>
	)
}
