/** @format */

/** @format */

import React from 'react'
import { ENV_COUNTRY } from '../../components/Commons/Hooks/Variables/Enviroment'
import AboutAR from './components/AR'
import AboutCL from './components/CL'

import './style.scss'

export default function About() {
	const handleControllerCountry = (country) => {
		console.log(country)
		switch (country) {
			case 'CL':
				return <AboutCL />
			case 'AR':
				return <AboutAR />
			default:
				return <AboutCL />
		}
	}
	return <>{handleControllerCountry(ENV_COUNTRY)}</>
}
