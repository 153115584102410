/** @format */

import axios from 'axios'

import Notify from '../../../../components/Commons/Notify'

import { ENV_CHECKOUT, ENV_COUNTRY } from '../../../../components/Commons/Hooks/Variables/Enviroment'

export async function serviceUploadImage(item) {
	let data = new FormData()
	data.append('file', item)
	await axios({
		method: 'POST',
		url: `${ENV_CHECKOUT}/upload/${ENV_COUNTRY}/recipe`,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		data,
	}).then((response) => {
		if (response.data.statusCode === 200) {
			item = response.data.data[0].url
		} else {
			Notify({
				title: `Advertencia!`,
				colorTitle: 'ph-main-notification-warning-title',
				description: `Hubo un error al intentar subir las imágenes, contacte con Pharol si el problema persiste.`,
				time: 3,
				image: 'error',
				placement: 'topRight',
			})
		}
	})
	return item
}
