/** @format */

import React from 'react'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Images from '../../../../components/Commons/Images'

import './style.scss'

export default function ProductDetail(props) {
	return (
		<>
			<Row className='ph-objected-recipe-product-global-container'>
				<Col xs={4}>
					<Images
						classContent={'ph-objected-recipe-container'}
						classImage={'ph-objected-recipe-image'}
						default={props.product.image}
					/>
				</Col>
				<Col xs={16} className='ph-objected-recipe-product-text-container'>
					<h1 className='ph-objected-recipe-product-title'>{props.product.editable_name}</h1>
					<h3 className='ph-objected-recipe-product-pharmacy'>
						Farmacias {props.info.pharmacy[0].name_pharmacy}
					</h3>
				</Col>
				<Col xs={4}>
					<p className='ph-objected-recipe-product-id'>
						<strong>Código:</strong> {props.product.code_internal}
					</p>
				</Col>
			</Row>
		</>
	)
}
