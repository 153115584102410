/** @format */

import React from 'react'

import Info from '../components/Info'

export default function InforDetail(props) {
	return (
		<div>
			{props.item.patient_brochure.adverse_reactions !== ' ' && (
				<Info
					title={'Reacciones adversas'}
					description={props.item.patient_brochure.adverse_reactions}
				/>
			)}
			{props.item.patient_brochure.precautions !== ' ' && (
				<Info title={'Precauciones'} description={props.item.patient_brochure.precautions} />
			)}
		</div>
	)
}
