/** @format */

import React from 'react';

import { Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import MetaDescription from '../../../../components/Commons/MetaDescription';
import Images from '../../../../components/Commons/Images';
import { ENV_IMG_STATIC, ENV_COUNTRY } from '../../../../components/Commons/Hooks/Variables/Enviroment';

import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';

import './style.scss';

import translates from '../../../../components/Commons/translates';

export default function AboutCL() {
	const { t } = useTranslation('global');

	return (
		<div className="ph-about-container">
			<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			<div className="ph-about-header-container">
				<div className="ph-about-header-content">
					<div className="ph-about-header">
						<Row>
							<Col lg={12} xl={12} className="ph-about-header-image-container">
								<ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
									<Images
										classContent={'ph-about-header-image'}
										classImage={'ph-about-header-image'}
										default={`${ENV_IMG_STATIC}/about-us/iphone-one.png`}
										webp={`${ENV_IMG_STATIC}/about-us/iphone-one.webp`}
										jp2={`${ENV_IMG_STATIC}/about-us/iphone-one.jp2`}
										jxr={`${ENV_IMG_STATIC}/about-us/iphone-one.jxr`}
										title={'About-us iphone'}
										alt={'About-us iphone'}
									/>
								</ScrollAnimation>
							</Col>
							<Col md={24} lg={12} xl={12} className="ph-about-header-text-container">
								<Images
									classContent={'ph-how-to-by-header-image '}
									classImage={'ph-how-to-by-header-image'}
									default={`${ENV_IMG_STATIC}/logo-blanco-pharol.png`}
									webp={`${ENV_IMG_STATIC}/logo-blanco-pharol.webp`}
									jp2={`${ENV_IMG_STATIC}/logo-blanco-pharol.jp2`}
									jxr={`${ENV_IMG_STATIC}/logo-blanco-pharol.jxr`}
									title={'logo'}
									alt={'logo'}
								/>
								<ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true}>
									<h1 className="ph-about-header-title">¿Qué es Pharol?</h1>
									<h3 className="ph-about-header-subtitle">
										Somos un Marketplace Farmacéutico con foco en el paciente, que conecta las necesidades de los mismos con la oferta de {translates.pharmacies[ENV_COUNTRY]} legalmente constituidas y
										establecidas.
									</h3>
								</ScrollAnimation>
								<Button className="ph-about-header-btn" href="https://pharol.cl/contact" target="_blank" rel="noopener noreferrer">
									CONTÁCTANOS
								</Button>
							</Col>
						</Row>
					</div>
				</div>
			</div>
			<div className="ph-about-global-container">
				<div className="ph-about-main-container">
					<Row justify="center">
						<Col xs={24} sm={24} md={24} lg={12} className="ph-about-main-img-container">
							<ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
								<Images
									classContent={'ph-about-main-imgs'}
									classImage={'ph-about-main-img'}
									default={`${ENV_IMG_STATIC}/about-us/welcome.png`}
									webp={`${ENV_IMG_STATIC}/about-us/welcome.webp`}
									jp2={`${ENV_IMG_STATIC}/about-us/welcome.jp2`}
									jxr={`${ENV_IMG_STATIC}/about-us/welcome.jxr`}
									title={'About-us Welcome'}
									alt={'About-us Welcome'}
								/>
							</ScrollAnimation>
						</Col>
						<Col xs={24} sm={24} md={24} lg={12} className="ph-about-main-text-container">
							<Row>
								<Col className="ph-about-main-text-container-span">
									<ScrollAnimation animateIn="animate__bounceInRight" animateOnce={true}>
										<span className="ph-about-main-span">Bienvenidos a</span>
									</ScrollAnimation>
								</Col>
							</Row>
							<ScrollAnimation animateIn="animate__bounceInRight" animateOnce={true}>
								<h2 className="ph-about-main-title">Nuestra Tienda Virtual</h2>
							</ScrollAnimation>
							<ScrollAnimation animateIn="animate__bounceInRight" animateOnce={true}>
								<p className="ph-about-main-subtitle">
									A través de Pharol, los pacientes pueden buscar, cotizar, informarse y comprar productos farmacéuticos de forma transparente, cómoda y en simples pasos, recibiendo atención
									personalizada por parte de nuestros profesionales farmacéuticos en caso de que así lo requieran.
								</p>
							</ScrollAnimation>
						</Col>
					</Row>
				</div>
				<div className="ph-about-info-container">
					<Row>
						<Col xs={24} sm={24} md={24} lg={12} className="ph-about-info-mision-container">
							<div className="ph-about-info-mision-content">
								<Images
									classContent={'ph-about-info-mision-img'}
									classImage={'ph-about-info-mision-img'}
									default={`${ENV_IMG_STATIC}/about-us/mision.png`}
									webp={`${ENV_IMG_STATIC}/about-us/mision.webp`}
									jp2={`${ENV_IMG_STATIC}/about-us/mision.jp2`}
									jxr={`${ENV_IMG_STATIC}/about-us/mision.jxr`}
									title={'About-us mision'}
									alt={'About-us mision'}
								/>
							</div>
							<ScrollAnimation animateIn="animate__fadeInTopLeft" animateOnce={true}>
								<div className="ph-about-info-mision-text">
									<h2 className="ph-about-info-mision-title">Misión</h2>
									<p className="ph-about-info-mision-subtitle">
										Convertirnos en aliados del cuidado de la salud de nuestros pacientes, brindándoles acompañamiento en sus tratamientos de la mano de profesionales farmacéuticos.
									</p>
								</div>
							</ScrollAnimation>
							<div className="ph-about-info-mision-img-desktop">
								<ScrollAnimation animateIn="animate__fadeInBottomLeft" animateOnce={true}>
									<Images
										classContent={'ph-about-info-mision-img-desk'}
										classImage={'ph-about-info-mision-img-desk'}
										default={`${ENV_IMG_STATIC}/about-us/mision.png`}
										webp={`${ENV_IMG_STATIC}/about-us/mision.webp`}
										jp2={`${ENV_IMG_STATIC}/about-us/mision.jp2`}
										jxr={`${ENV_IMG_STATIC}/about-us/mision.jxr`}
										title={'About-us mision'}
										alt={'About-us mision'}
									/>
								</ScrollAnimation>
							</div>
						</Col>
						<Col xs={24} sm={24} md={24} lg={12} className="ph-about-info-vision-container">
							<div className="ph-about-info-vision-content">
								<ScrollAnimation animateIn="animate__fadeInTopRight" animateOnce={true}>
									<Images
										classContent={'ph-about-info-vision-img'}
										classImage={'ph-about-info-vision-img'}
										default={`${ENV_IMG_STATIC}/about-us/vision.png`}
										webp={`${ENV_IMG_STATIC}/about-us/vision.webp`}
										jp2={`${ENV_IMG_STATIC}/about-us/vision.jp2`}
										jxr={`${ENV_IMG_STATIC}/about-us/vision.jxr`}
										title={'About-us vision'}
										alt={'About-us vision'}
									/>
								</ScrollAnimation>
							</div>
							<ScrollAnimation animateIn="animate__fadeInBottomRight" animateOnce={true}>
								<div className="ph-about-info-vision-text">
									<h2 className="ph-about-info-mision-title">Visión</h2>
									<p className="ph-about-info-mision-subtitle">
										En Pharol queremos que nuestros pacientes nos vean como una empresa que brinda transparencia, comodidad, accesibilidad, optimización del tiempo e información en línea para
										facilitar la toma de decisiones.
									</p>
								</div>
							</ScrollAnimation>
						</Col>
					</Row>
				</div>
				<div className="ph-about-iphone-container">
					<Row className="ph-about-iphone-content">
						<Col xs={24} sm={24} md={24} lg={8} className="ph-about-iphone-conteiners">
							<ScrollAnimation animateIn="animate__fadeInTopLeft" animateOnce={true}>
								<div className="ph-about-iphone-conteiners-text">
									<Images
										classContent={'ph-about-iphone-conteiners-img'}
										classImage={'ph-about-iphone-conteiners-img'}
										default={`${ENV_IMG_STATIC}/about-us/transparencia.png`}
										webp={`${ENV_IMG_STATIC}/about-us/transparencia.webp`}
										jp2={`${ENV_IMG_STATIC}/about-us/transparencia.jp2`}
										jxr={`${ENV_IMG_STATIC}/about-us/transparencia.jxr`}
										title={'About-us transparency'}
										alt={'About-us transparency'}
									/>
									<h3 className="ph-about-iphone-conteiners-title">Transparencia:</h3>
									<p className="ph-about-iphone-conteiners-paraghraf">Ahorra tiempo y dinero consultando tus productos farmacéuticos a través de nuestras plataformas web y app.</p>
								</div>
							</ScrollAnimation>
							<ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
								<div className="ph-about-iphone-conteiners-text">
									<Images
										classContent={'ph-about-iphone-conteiners-img'}
										classImage={'ph-about-iphone-conteiners-img'}
										default={`${ENV_IMG_STATIC}/about-us/comodidad.png`}
										webp={`${ENV_IMG_STATIC}/about-us/comodidad.webp`}
										jp2={`${ENV_IMG_STATIC}/about-us/comodidad.jp2`}
										jxr={`${ENV_IMG_STATIC}/about-us/comodidad.jxr`}
										title={'About-us comodity'}
										alt={'About-us comodity'}
									/>
									<h3 className="ph-about-iphone-conteiners-title">Comodidad:</h3>
									<p className="ph-about-iphone-conteiners-paraghraf">Agrega tus productos al carro y recíbelos dónde lo requieras con nuestro servicio de despacho programado.</p>
								</div>
							</ScrollAnimation>
							<ScrollAnimation animateIn="animate__fadeInBottomLeft" animateOnce={true}>
								<div className="ph-about-iphone-conteiners-text">
									<Images
										classContent={'ph-about-iphone-conteiners-img'}
										classImage={'ph-about-iphone-conteiners-img'}
										default={`${ENV_IMG_STATIC}/about-us/accesibilidad.png`}
										webp={`${ENV_IMG_STATIC}/about-us/accesibilidad.webp`}
										jp2={`${ENV_IMG_STATIC}/about-us/accesibilidad.jp2`}
										jxr={`${ENV_IMG_STATIC}/about-us/accesibilidad.jxr`}
										title={'About-us accesibility'}
										alt={'About-us accesibility'}
									/>
									<h3 className="ph-about-iphone-conteiners-title">Accesibilidad:</h3>
									<p className="ph-about-iphone-conteiners-paraghraf">
										Disfruta de nuestras plataformas desde el dispositivo de tu preferencia y la experiencia de tener la {translates.pharmacy[ENV_COUNTRY]} en la palma de tu mano.
									</p>
								</div>
							</ScrollAnimation>
						</Col>
						<Col xs={24} sm={24} md={24} lg={8} className="ph-about-iphone-conteiners">
							<ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
								<Images
									classContent={'ph-about-iphone-movil-img'}
									classImage={'ph-about-iphone-movil-img'}
									default={`${ENV_IMG_STATIC}/about-us/iphone-two.png`}
									webp={`${ENV_IMG_STATIC}/about-us/iphone-two.webp`}
									jp2={`${ENV_IMG_STATIC}/about-us/iphone-two.jp2`}
									jxr={`${ENV_IMG_STATIC}/about-us/iphone-two.jxr`}
									title={'About-us Phone'}
									alt={'About-us Phone'}
								/>
							</ScrollAnimation>
						</Col>
						<Col xs={24} sm={24} md={24} lg={8} className="ph-about-iphone-conteiners">
							<ScrollAnimation animateIn="animate__fadeInTopRight" animateOnce={true}>
								<div className="ph-about-iphone-conteiners-text-right">
									<Images
										classContent={'ph-about-iphone-conteiners-img'}
										classImage={'ph-about-iphone-conteiners-img'}
										default={`${ENV_IMG_STATIC}/about-us/comparador.png`}
										webp={`${ENV_IMG_STATIC}/about-us/comparador.webp`}
										jp2={`${ENV_IMG_STATIC}/about-us/comparador.jp2`}
										jxr={`${ENV_IMG_STATIC}/about-us/comparador.jxr`}
										title={'About-us confortable'}
										alt={'About-us confortable'}
									/>
									<h3 className="ph-about-iphone-conteiners-title-right">Confianza:</h3>
									<p className="ph-about-iphone-conteiners-subtitle-right">Te acompañamos en tus tratamientos y te brindamos atención personalizada de la mano de profesionales farmacéuticos.</p>
								</div>
							</ScrollAnimation>
							<ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true}>
								<div className="ph-about-iphone-conteiners-text-right">
									<Images
										classContent={'ph-about-iphone-conteiners-img'}
										classImage={'ph-about-iphone-conteiners-img'}
										default={`${ENV_IMG_STATIC}/about-us/orientacion.png`}
										webp={`${ENV_IMG_STATIC}/about-us/orientacion.webp`}
										jp2={`${ENV_IMG_STATIC}/about-us/orientacion.jp2`}
										jxr={`${ENV_IMG_STATIC}/about-us/orientacion.jxr`}
										title={'About-us quality'}
										alt={'About-us quality'}
									/>
									<h3 className="ph-about-iphone-conteiners-title-right">Calidad:</h3>
									<p className="ph-about-iphone-conteiners-subtitle-right">
										Nuestro compromiso es cuidar de tu salud y mejorar nuestros procesos de forma constante para garantizarte una excelente experiencia de compra.
									</p>
								</div>
							</ScrollAnimation>
							<ScrollAnimation animateIn="animate__fadeInBottomRight" animateOnce={true}>
								<div className="ph-about-iphone-conteiners-text-right">
									<Images
										classContent={'ph-about-iphone-conteiners-img'}
										classImage={'ph-about-iphone-conteiners-img'}
										default={`${ENV_IMG_STATIC}/about-us/compra.png`}
										webp={`${ENV_IMG_STATIC}/about-us/compra.webp`}
										jp2={`${ENV_IMG_STATIC}/about-us/compra.jp2`}
										jxr={`${ENV_IMG_STATIC}/about-us/compra.jxr`}
										title={'About-us availability'}
										alt={'About-us availability'}
									/>
									<h3 className="ph-about-iphone-conteiners-title-right">Disponibilidad:</h3>
									<p className="ph-about-iphone-conteiners-subtitle-right">
										Olvídate de los viajes a la {translates.pharmacy[ENV_COUNTRY]}, a través de Pharol puedes comprar los productos de tu preferencia y nosotros nos encargaremos del resto.
									</p>
								</div>
							</ScrollAnimation>
						</Col>
					</Row>
				</div>
			</div>
			<div className="ph-about-download">
				<div className="ph-about-download-content">
					<Row className="ph-about-download-main-container">
						<Col xs={24} sm={24} className="ph-how-to-by-dowlond-text-content">
							<div className="ph-how-to-by-dowlond-text-contents">
								<ScrollAnimation animateIn="animate__zoomIn" animateOnce={true}>
									<h1 className="ph-about-download-title">Estás a sólo un paso mas</h1>
								</ScrollAnimation>
								<ScrollAnimation animateIn="animate__zoomIn" animateOnce={true}>
									<p className="ph-about-download-paraghraf">De disfrutar nuestros servicios y compra lo que más necesites, donde más lo necesites.</p>
								</ScrollAnimation>
							</div>
						</Col>
					</Row>
					<div className="ph-about-download-inner-container">
						<div className="ph-how-to-by-dowlond-buttons-content">
							<ScrollAnimation animateIn="animate__zoomIn" animateOnce={true}>
								<a href="https://play.google.com/store/apps/details?id=com.mediclic.mediclic1&hl=es" target="_blank" rel="noopener noreferrer">
									<Images
										classContent={'ph-how-to-by-dowlond-content-playstore'}
										classImage={'ph-how-to-by-dowlond-playstore'}
										default={`${ENV_IMG_STATIC}/mediclic/playstore.png`}
										webp={`${ENV_IMG_STATIC}/mediclic/playstore.webp`}
										jp2={`${ENV_IMG_STATIC}/mediclic/playstore.jp2`}
										jxr={`${ENV_IMG_STATIC}/mediclic/playstore.jxr`}
										title={'PlayStore'}
										alt={'PlayStore'}
									/>
								</a>
							</ScrollAnimation>
						</div>
						<div className="ph-how-to-by-dowlond-buttons-content">
							<ScrollAnimation animateIn="animate__zoomIn" animateOnce={true}>
								<a href="https://apps.apple.com/us/app/mediclic/id1143276587" target="_blank" rel="noopener noreferrer">
									<Images
										classContent={'ph-how-to-by-dowlond-content-appstore'}
										classImage={'ph-how-to-by-dowlond-appstore'}
										default={`${ENV_IMG_STATIC}/mediclic/appstore.png`}
										webp={`${ENV_IMG_STATIC}/mediclic/appstore.webp`}
										jp2={`${ENV_IMG_STATIC}/mediclic/appstore.jp2`}
										jxr={`${ENV_IMG_STATIC}/mediclic/appstore.jxr`}
										title={'AppStore'}
										alt={'AppStore'}
									/>
								</a>
							</ScrollAnimation>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
