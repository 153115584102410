/** @format */

import React from 'react'

import { Row, Col } from 'antd'

import './style.scss'

export default function TimeLocal(props) {
	const handleDayWeekLocal = (item) => {
		if (item === 1) {
			return 'Lunes'
		} else if (item === 2) {
			return 'Martes'
		} else if (item === 3) {
			return 'Miercoles'
		} else if (item === 4) {
			return 'Jueves'
		} else if (item === 5) {
			return 'Viernes'
		} else if (item === 6) {
			return 'Sábado'
		} else if (item === 0) {
			return 'Domingo'
		}
	}

	return (
		<div className='ph-withdrawal-pharmacy-list-hours-selected-main-container'>
			<Row>
				<Col span={5} className='ph-withdrawal-pharmacy-list-hours-selected-main-title-container'>
					<h4 className='ph-withdrawal-pharmacy-list-hours-selected-main-title'>Horarios:</h4>
				</Col>
				<Col span={19}>
					<div className='ph-withdrawal-pharmacy-list-hours-selected-container'>
						{props.item.schedule.map((item, index) => (
							<div
								className='ph-withdrawal-pharmacy-list-hours-selected-inner-container'
								key={index}>
								<h3 className='ph-withdrawal-pharmacy-list-hour-title'>
									{handleDayWeekLocal(item.day)}
								</h3>
								{item.status ? (
									<div className='ph-withdrawal-pharmacy-list-hour-subtitle-container'>
										<h3 className='ph-withdrawal-pharmacy-list-hour-subtitle-1'>Abierto</h3>
									</div>
								) : (
									<div className='ph-withdrawal-pharmacy-list-hour-subtitle-container'>
										<h3 className='ph-withdrawal-pharmacy-list-hour-subtitle-2'>Cerrado</h3>
									</div>
								)}
							</div>
						))}
					</div>
				</Col>
			</Row>
		</div>
	)
}
