/** @format */

import axios from 'axios'

import { ENV_PIXELS_MANAGEMENT, ENV_COUNTRY, ENV_ROOT_PHARMACY } from '../../Variables/Enviroment'

export async function GetPixelsManagement(type, value) {
	let returnResponse
	await axios({
		method: 'GET',
		url: `${ENV_PIXELS_MANAGEMENT}/pixel-query/${ENV_COUNTRY}/${type}/${value}`,
		params: {
			root_pharmacy: ENV_ROOT_PHARMACY,
		},
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				let info = response.data.data
				let data
				if (info?.length > 0) {
					info.forEach((item) => {
						data = {
							...data,
							[item.type]: item.pixel,
						}
					})
				}
				returnResponse = data
			} else {
				console.warn(`Advertencia ${response.data.error}: ${response.data.message}`)
			}
		})
		.catch((error) => {
			console.error(`Error: ${error}`)
		})
	return returnResponse
}
