/** @format */

import React, { Component } from 'react'

import ListInfoDetail from '../ListInfoDetail'
import './style.scss'

export default class CardHomeDelivery extends Component {
	render() {
		return (
			<>
				<div className='ph-card-homedelivery-info-main-container'>
					<ListInfoDetail title={'Tipo de Vivienda:'} info={this.props.list.type_house} />
					<ListInfoDetail title={'Nombre de Calle:'} info={this.props.list.street} />
					<ListInfoDetail title={'Núm. Calle:'} info={this.props.list.street_number} />
					<ListInfoDetail title={'Comuna:'} info={this.props.list.commune} />
					<ListInfoDetail title={'Núm. Casa/Apt:'} info={this.props.list.number_dpto_house} />
					<ListInfoDetail title={'Referencia:'} info={this.props.list.reference} />
				</div>
			</>
		)
	}
}
