import { Col, Form, Row } from 'antd';
import React, { useState } from 'react';
import { GetLocalStorageValue } from '../../../../_config/adapters/local-storage/get-local-storage-value.adapter';
import CustomButton from '../../../../v2-components/Antd/Button';
import CustomCard from '../../../../v2-components/Antd/Card';
import CustomFormItem from '../../../../v2-components/Antd/FormItem';
import CustomInput from '../../../../v2-components/Antd/Input';
import CustomLink from '../../../../v2-components/Antd/Link';
import { CustomNotification } from '../../../../v2-components/Antd/Notification';
import LoadingModal from '../../../../v2-components/LoadingModal';
import { Service } from './service';
import { VALIDATION } from '../../../../v2-config/constants/validations.constant';

import './style.scss';

export default function ProfileChangePasswordPage() {
	const [form] = Form.useForm();
	const [openLoadingModal, setOpenLoadingModal] = useState(false);
	const [isLoadingModal, setIsLoadingModal] = useState(false);
	const { token } = GetLocalStorageValue('userMainInfo');
	console.log('token', token);
	const handleFinish = (item) => {
		setOpenLoadingModal(true);
		setIsLoadingModal(true);
		new Service()
			.changePassword({ ...item }, token)
			.then((response) => {
				if (response.statusCode === 409) {
					setIsLoadingModal(false);
					setOpenLoadingModal(false);
					return CustomNotification({ type: 'error', message: 'Error', description: response.message });
				}
				setIsLoadingModal(false);
				setOpenLoadingModal(false);
				CustomNotification({ type: 'success', message: 'Exito', description: 'Contraseña modificada exitosamente' });
				form.resetFields();
			})
			.catch((error) => {
				console.log('error', error);
			});
		console.log('item', item);
	};

	return (
		<>
			<Row justify="center" className="change-password-content">
				<Col md={12} xs={22}>
					<Row justify="left">
						<h3>Modificar contraseña</h3>
					</Row>
					<Row justify="left">
						<CustomLink className={'link-primary'} to="/profile-info">
							Volver
						</CustomLink>
					</Row>
					<br></br>
					<Form form={form} layout="vertical" onFinish={handleFinish}>
						<CustomCard className={'card-primary'}>
							<CustomFormItem className={'form-primary'} label={'Contraseña actual'} name={'old_password'} rules={VALIDATION.required}>
								<CustomInput className={'input-primary'} type={'password'} />
							</CustomFormItem>
							<CustomFormItem className={'form-primary'} label={'Nueva contraseña'} name={'new_password'} rules={VALIDATION.required_length}>
								<CustomInput className={'input-primary'} type={'password'} />
							</CustomFormItem>
							<CustomFormItem className={'form-primary'} label={'Repetir contraseña'} name={'repeat_password'} rules={VALIDATION.repeat_password}>
								<CustomInput className={'input-primary'} type={'password'} />
							</CustomFormItem>
							<CustomFormItem>
								<CustomButton className={'button-primary'} htmlType="submit">
									Modificar
								</CustomButton>
							</CustomFormItem>
						</CustomCard>
					</Form>
					<LoadingModal loading={isLoadingModal} visible={openLoadingModal} />
				</Col>
			</Row>
		</>
	);
}
