import { Col, Form, Row } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import * as moment from 'moment';
import 'moment/locale/es';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import CustomButton from '../../../../v2-components/Antd/Button';
import CustomCard from '../../../../v2-components/Antd/Card';
import DatePicker from '../../../../v2-components/Antd/DatePicker';
import CustomFormItem from '../../../../v2-components/Antd/FormItem';
import CustomLink from '../../../../v2-components/Antd/Link';
import { CustomNotification } from '../../../../v2-components/Antd/Notification';
import CustomTable from '../../../../v2-components/Antd/Table';
import LoadingModal from '../../../../v2-components/LoadingModal';
import { VALIDATION } from '../../../../v2-config/constants/validations.constant';
import { DICTIONARY } from './constants/dictionary.constant';
import { CLAIMS_DICTIONARY } from '../create/constants/dictionary.constant';
import { formatDate } from './handle/format-date.handle';
import { Service } from './service';
import './style.scss';
import { Enviroment } from '../../../../_config/constants/enviroment-v2.constant';

export default function ClaimsSearchPage() {
	const [form] = Form.useForm();
	const [openLoadingModal, setOpenLoadingModal] = useState(false);
	const [isLoadingModal, setIsLoadingModal] = useState(false);
	const [claims, setClaims] = useState();
	const [pagination, setPagination] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [dataToSend, setDataToSend] = useState(null);
	const history = useHistory();
	const location = useLocation();

	const handleFinish = (item) => {
		item.start_date = moment(item.start_date).format('DD-MM-YYYY');
		item.end_date = moment(item.end_date).format('DD-MM-YYYY');
		item.pharmacy = Enviroment.ROOT_PHARMACY;
		item.platform = Enviroment.ROOT_PLATFORM;
		item.country = Enviroment.COUNTRY;
		setDataToSend({ ...item });
	};

	useEffect(() => {
		if (!dataToSend) {
			return;
		}
		const params = queryString.parse(location.search);
		const page = params.page || 1;
		setOpenLoadingModal(true);
		setIsLoadingModal(true);
		new Service()
			.getClaimByDate(dataToSend, page)
			.then((response) => {
				console.log('response', response);
				if (response.data.data.length === 0) {
					setIsLoadingModal(false);
					setOpenLoadingModal(false);
					form.resetFields();
					setClaims(null);
					return CustomNotification({ type: 'error', message: 'Sin resultados', description: 'No se encontraron resultados' });
				}
				setClaims(response.data.data);
				setPagination(response.data);
				setIsLoadingModal(false);
				setOpenLoadingModal(false);
				form.resetFields();
			})
			.catch((error) => {
				console.log(error);
			});
	}, [location.search, form, dataToSend]);

	const isMobile = window.innerWidth < 768;

	console.log('isMobile', isMobile);
	const columns = [
		{
			key: 1,
			title: 'Fecha',
			dataIndex: 'created_at',
			render: (created_at) => formatDate(created_at),
		},
		{
			key: 2,
			title: 'N° de solicitud',
			dataIndex: 'claim_id',
			style: { wrap: 'normal' }, // Esto evita el salto de línea
		},
		{
			key: 3,
			title: 'Estado',
			dataIndex: 'status',
			render: (status) => DICTIONARY[status],
		},
		{
			key: 4,
			title: 'Mensaje',
			dataIndex: 'message',
		},
		{
			key: 5,
			title: 'Respuesta',
			dataIndex: 'response',
			render: (response) => response || '--',
		},
		{
			key: 6,
			title: 'Tipo de solicitud',
			dataIndex: 'type',
			render: (type) => CLAIMS_DICTIONARY[type],
		},
	];

	const handlePageChange = (page) => {
		setCurrentPage(page);
		const queryParams = { page };
		history.push({ search: queryString.stringify(queryParams) });
	};

	const paginationConfig = {
		current: currentPage,
		pageSize: pagination?.itemsPerPage,
		total: pagination?.totalItems,
		showSizeChanger: false,
		onChange: (page) => handlePageChange(page),
	};

	return (
		<>
			<Row justify="center" className="book-content">
				<Col md={18} xs={22}>
					<Row justify="left">
						<CustomLink className={'link-primary'} to="/claims">
							Volver
						</CustomLink>
					</Row>
					<br></br>
					<CustomCard className={'card-primary'} title="Buscar solicitudes">
						<Form form={form} layout="vertical" onFinish={handleFinish}>
							<Row gutter={[16, 16]}>
								<Col md={12} xs={24}>
									<CustomFormItem className={'form-primary'} label={'Desde'} rules={VALIDATION.required} name={'start_date'}>
										<DatePicker className={'date-picker-primary'} locale={locale} format={'DD/MM/YYYY'} />
									</CustomFormItem>
								</Col>
								<Col md={12} xs={24}>
									<CustomFormItem className={'form-primary'} label={'Hasta'} rules={VALIDATION.required} name={'end_date'}>
										<DatePicker className={'date-picker-primary'} locale={locale} format={'DD/MM/YYYY'} />
									</CustomFormItem>
								</Col>
							</Row>
							<Row>
								<CustomButton className={'button-primary'} htmlType="submit">
									Buscar
								</CustomButton>
							</Row>
						</Form>
					</CustomCard>
					<br></br>
					<CustomCard className={'card-primary'} title="Resultado de búsqueda">
						<CustomTable
							columns={columns}
							dataSource={claims}
							pagination={paginationConfig}
							scroll={{
								y: 240,
								x: isMobile,
							}}
						></CustomTable>
					</CustomCard>
					<LoadingModal loading={isLoadingModal} visible={openLoadingModal} />
				</Col>
			</Row>
		</>
	);
}
