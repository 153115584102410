/** @format */

import React from 'react';
import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';
import { ENV_IMG } from '../../../../../components/Commons/Hooks/Variables/Enviroment';
import Images from '../../../../../components/Commons/Images';
import MetaDescription from '../../../../../components/Commons/MetaDescription';
import CustomButton from '../../../../../v2-components/Antd/Button';
import { GetLocalStorageValue } from '../../../../../_config/adapters/local-storage/get-local-storage-value.adapter';
import './style.scss';

export default function ErrorReserve(props) {
	const history = useHistory;
	const { t } = useTranslation('global');
	const emailPharmacy = GetLocalStorageValue('emailPharmacy');

	return (
		<section className="ph-success-global-container">
			<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			<section className="ph-success-inner-container">
				<div className="ph-success-main-container">
					<Images
						classContent={'ph-error-img-container'}
						classImage={'ph-error-img'}
						webp={`${ENV_IMG}/react-checkout/master-recipe/error.webp`}
						jp2={`${ENV_IMG}/react-checkout/master-recipe/error.jxr`}
						jxr={`${ENV_IMG}/react-checkout/master-recipe/error.jp2`}
						default={`${ENV_IMG}/react-checkout/master-recipe/error.png`}
					/>
					<h1 className="ph-succes-title">¡Tu reserva no se procesó correctamente!</h1>

					<h4 className="ph-succes-description-span">
						<span className="ph-succes-description-span-i">*</span>
						Vuelve a intentar tu reserva
						<a href={`mailto:${emailPharmacy}`}>
							<span className="ph-succes-span">{emailPharmacy}</span>
						</a>
					</h4>

					<CustomButton className={'button-primary'} onClick={() => history.push('/')}>
						<span className="ph-succes-payment-button-span">Volver al Inicio</span>
					</CustomButton>
				</div>
			</section>
		</section>
	);
}
