/** @format */
import { React, useMemo, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useGlobal, setGlobal } from 'reactn';
import { Modal, Row, Col, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import Notify from '../../../../../../../../../../components/Commons/Notify';
import Images from '../../../../../../../../../../components/Commons/Images';
import Address from '../../../../../../../../../../components/Commons/Address';
import GetDeliveryInfo from '../../../../../../../../../../components/Commons/Hooks/Functions/GetDeliveryInfo';
import { formatPrice } from '../../../../../../../../../../components/Commons/Hooks/Functions/FormatPrice';

import './style.scss';

export default function ProductQuoteDispatch(props) {
	const [isPharmacies] = useGlobal('pharmaciesList');
	const [isVisible, setVisible] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [isInfo, setInfo] = useState(null);
	const [notifyCounter, setNotifyCounter] = useState(0);

	const handleCloseModal = () => {
		setInfo(null);
		setVisible(false);
	};

	const address = JSON.parse(localStorage.getItem('locationStore'));

	const handleCalculateDelivery = useCallback(async (item) => {
		if (address.default && notifyCounter > 1) {
			Notify({
				title: `Error`,
				colorTitle: 'ph-main-notification-error-title',
				description: '¡Debes ingresar una dirección para pocesar tu posible precio y fecha de entrega!',
				time: 4,
				image: 'error',
				placement: 'topRight',
			});
			setNotifyCounter(notifyCounter + 1);
			return;
		}
		setInfo(null);
		setLoading(true);
		try{
			const response = await GetDeliveryInfo(item)
			if (response) {
				setInfo(response);
				if (response.day !== '' && response.date !== '') {
					let pharmaciesSort = [];
					if (isPharmacies) {
						pharmaciesSort = [...isPharmacies];
						pharmaciesSort.forEach((item) => {
							if (props.item.objectID) {
								if (props.item.objectID === item.objectID) {
									item.delivery_user_info = response;
								}
							} else {
								if (props.item.id === item.id) {
									item.delivery_user_info = response;
								}
							}
						});
						setGlobal(() => ({ pharmaciesList: pharmaciesSort }));
					} else if (props.item) {
						//PARCHE FABO: PARA QUE FUNCIONE EN DETALLE DE PRODUCTO. HAY QUE VALIDAR SI TIENE O NO ENVIO A FUTURO.
						if (props.item.objectID) {
							if (props.item.objectID) {
								item.delivery_user_info = response;
							}
						} else {
							if (props.item.id) {
								item.delivery_user_info = response;
							}
						}
					}
				}
			}
		}catch(error){
			console.error("Error al obtener la información de entrega:", error);
		} finally {
			setLoading(false);
		}
		setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useMemo(() => {
		handleCalculateDelivery(props.item);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{props.item?.nearest_local?.despatch_info[0].title === 'Despacho' || props.pharmacies?.nearest_local?.despatch_info[0].title === 'Despacho' ? (
				<div className="ph-product-detail-quote-dispatch-info-title-container">
					{props.item.stock > 0 && (
						<>
							{props.item.delivery_user_info ? (
								<div className="ph-product-detail-quote-dispatch-info-container fade-in">
									<span className="ph-product-detail-quote-dispatch-info-subtitle-pharmacy-since">desde&nbsp;</span>
									<h4 className="ph-product-detail-quote-dispatch-info-subtitle-pharmacy">{formatPrice(props.item.delivery_user_info.price)}</h4>
								</div>
							) : (
								<>
									<Spin className="ph-repurchase-form-address-info-loading-icon" indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />} />
								</>
							)}
						</>
					)}
				</div>
			) : (
				<div className="ph-product-detail-quote-dispatch-info-unavailable">No disponible</div>
			)}
			<Modal
				wrapClassName="ph-calculate-delivery-modal-container"
				maskClosable={false}
				width="500px"
				title="Calculo de Despacho"
				centered
				visible={isVisible}
				onCancel={() => handleCloseModal()}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
			>
				<Row>
					<Col className="ph-calculate-delivery-modal-title-container" xs={24} sm={24} md={24} lg={24} xl={24}>
						<h3 className="ph-calculate-delivery-modal-title">¡Calcula tu costo y fecha posible de Despacho!</h3>
						<h5 className="ph-calculate-delivery-modal-description">Ingresa tu dirección exacta para una mejor experiencia:</h5>
					</Col>
					<div className="ph-repurchase-form-address-component-container">
						<Address classType={'ph-repurchase-form-address'} />
					</div>
					<div className="ph-repurchase-form-address-link-profile-address-container">
						<Link className="ph-repurchase-form-address-link-profile-address" to="/profile/address">
							Gestionar mis direcciones
						</Link>
					</div>

					{isInfo ? (
						<div className="ph-repurchase-form-address-result-delivery-container">
							<div className="ph-repurchase-form-address-result-delivery-info-container">
								<Images
									classContent={'ph-product-detail-quote-dispatch-info-image-container'}
									classImage={'ph-product-detail-quote-dispatch-info-image'}
									webp={props.item?.nearest_local?.despatch_info[0].image.webp || props.pharmacies?.nearest_local?.despatch_info[0].image.webp}
									jp2={props.item?.nearest_local?.despatch_info[0].image.jp2 || props.pharmacies?.nearest_local?.despatch_info[0].image.jp2}
									jxr={props.item?.nearest_local?.despatch_info[0].image.jxr || props.pharmacies?.nearest_local?.despatch_info[0].image.jxr}
									default={props.item?.nearest_local?.despatch_info[0].image.default || props.pharmacies?.nearest_local?.despatch_info[0].image.default}
									title={props.item?.nearest_local?.despatch_info[0].image.title || props.pharmacies?.nearest_local?.despatch_info[0].image.title}
									alt={props.item?.nearest_local?.despatch_info[0].image.title || props.pharmacies?.nearest_local?.despatch_info[0].image.title}
								/>
								<div>
									<h4 className="ph-repurchase-form-address-result-delivery-info-title">Recibir compra</h4>
									<h4 className="ph-repurchase-form-address-result-delivery-info-message">{isInfo.message}:</h4>
									{isInfo.day && isInfo.date && (
										<>
											<h4 className="ph-repurchase-form-address-result-delivery-info-day">
												{isInfo.day}, {isInfo.date}
											</h4>
											<h4 className="ph-repurchase-form-address-result-delivery-info-date">
												<span className="ph-repurchase-form-address-result-delivery-info-date-span">*</span>
												Lo seleccionas en el checkout
											</h4>
										</>
									)}
								</div>
							</div>
							<div className="ph-repurchase-form-address-result-delivery-price-container">
								{isInfo.price && (
									<>
										<h4 className="ph-repurchase-form-address-result-delivery-price-title">Valor:</h4>
										<h4 className="ph-repurchase-form-address-result-delivery-price-total"> {formatPrice(isInfo.price)}</h4>
									</>
								)}
							</div>
						</div>
					) : (
						<div className="ph-repurchase-form-address-info-preloading-container">
							{isLoading && (
								<div className="ph-repurchase-form-address-info-loading-container">
									<Spin className="ph-repurchase-form-address-info-loading-icon" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
								</div>
							)}
							<h4 className="ph-repurchase-form-address-info-preloading-title">
								Usa el botón <span className="ph-repurchase-form-address-info-preloading-subtitle">Calcular</span> para procesar tu información
							</h4>
						</div>
					)}
				</Row>
				<div className="ph-repurchase-form-calculate-button-container">
					<Button className="ph-repurchase-form-calculate-button" onClick={() => handleCalculateDelivery(props.item)} loading={isLoading}>
						Calcular
					</Button>
				</div>
			</Modal>
		</>
	);
}
