/** @format */

export const handleStock = (data) => {
  let returnResponse = false;
  data.forEach((item) => {
    if (item.stock === 0) {
      returnResponse = true;
    }
  });
  return returnResponse;
};
