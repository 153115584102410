import axios from 'axios';
import { AxiosInterceptor } from './axios.interceptor';

export class AxiosAdapter {
	constructor(config) {
		this.config = config;
	}

	async request() {
		try {
			const response = await axios(this.config);
			return await new AxiosInterceptor(response).pipe();
		} catch (e) {
			throw e.response.data;
		}
	}
}
