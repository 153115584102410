/** @format */

import React from 'react'

import { Button, Form, Checkbox } from 'antd'

import { UnlockOutlined } from '@ant-design/icons'

import Spacer from '../../../../../../components/Commons/Spacer'

import './style.scss'

export default function CheckoutButtonRecipe(props) {
	return (
		<div className='ph-checkout-recipe-button-main-container'>
			{props.isMobile && (
				<Form.Item
					className={`${
						props.recipeAgreement ? '' : 'ph-checkout-recipe-button-checkbox-hidden-container'
					} ph-checkout-recipe-button-checkbox-container`}
					name='recipe_agreement'
					valuePropName='checked'
					rules={[
						{
							validator: (_, value) =>
								value
									? Promise.resolve()
									: Promise.reject('Acepta las condiciones para continuar.'),
						},
					]}>
					<Checkbox>
						<h5 className='ph-checkout-recipe-agree-button-title'>
							La cantidad de unidades quedará sujeta a lo indicado en la receta médica. La
							receta será revisada y validada por un químico farmacéutico una vez realizada
							la compra.
						</h5>
					</Checkbox>
				</Form.Item>
			)}
			<Form.Item className='ph-checkout-recipe-button-inner-container'>
				{props.isMobile && (
					<>
						<div>
							<h3 className='ph-checkout-retirement-button-mobile-price-main-title'>
								Subtotal:
							</h3>
							<h3 className='ph-checkout-retirement-button-mobile-price-title'>
								<span className='ph-checkout-retirement-button-mobile-price-description'>
									$
								</span>
								{props.item.total}
							</h3>
						</div>
						<Spacer />
					</>
				)}

				<div className='ph-checkout-recipe-button-container'>
					<Button
						loading={props.loading}
						icon={<UnlockOutlined />}
						className='ph-checkout-recipe-button-1'
						htmlType={'submit'}
					/>
					<Button
						loading={props.loading}
						className='ph-checkout-recipe-button-2'
						htmlType={'submit'}>
						SIGUIENTE
					</Button>
				</div>
			</Form.Item>
		</div>
	)
}
