// import { AxiosAdapter } from '../axios/axios.adapter';
// import { Http } from '../axios/axios.protocols';
// import { GetIpPublic } from './ip-public.http';
// const ip = require('ip');

export const FindIpAdapter = async () => {
	return false;
	// const { ip } = await GetIpPublic();
	// console.log('myIp', ip);

	// const axiosRequest = new AxiosAdapter({
	// 	method: Http.GET,
	// 	url: `http://ip-api.com/json/${ip}`,
	// });

	// return await axiosRequest
	// 	.request()
	// 	.then((response) => {
	// 		return response;
	// 	})
	// 	.catch((e) => {
	// 		return e;
	// 	});
};
