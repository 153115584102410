/** @format */

import React from 'react';
import { useGlobal } from 'reactn';
import AddProductCart from '../../../../../../../../../../../../components/Commons/Hooks/Functions/AddProductCart';
import CustomButton from '../../../../../../../../../../../../v2-components/Antd/Button';
import './style.scss';

export default function ButtonsDetail(props) {
	const { handleAddProductCart } = AddProductCart();
	const [isQuantity] = useGlobal('quantityProduct');

	return (
		<>
			<>
				{props.item.stock > 0 && (
					<>
						<CustomButton
							className={'button-primary'}
							onClick={() => {
								if (props.pharolPriceCheck) props.handleSubscriptionPharolPrice(props.item.pharmacy);
								handleAddProductCart(props.item, isQuantity);
							}}
						>
							Agregar
						</CustomButton>
					</>
				)}
			</>
		</>
	);
}
