/** @format */

import React from 'react';

import Spacer from '../../../../../../components/Commons/Spacer';

import { formatPrice } from '../../../../../../components/Commons/Hooks/Functions/FormatPrice';

import './style.scss';

export default function DescriptionPrices(props) {
	return (
		<h3 className={`${props.deliveryTotal ? 'ph-checkout-box-service-subtotal-price-delivery' : 'ph-checkout-box-service-subtotal-price'}`}>
			{props.title}
			<Spacer />
			<span
				className={`${props.colorDiscount ? 'ph-checkout-box-service-subtotal-promotion' : ''} ${
					props.colorVoucher ? 'ph-checkout-box-service-subtotal-voucher' : ''
				} ph-checkout-box-service-subtotal-price-info`}
			>
				{formatPrice(props.price)}
			</span>
		</h3>
	);
}
