import React from 'react';
import { Table } from 'antd';
import './style.scss';

export default function CustomTable(props) {
	return (
		<>
			<Table {...props} />
		</>
	);
}
