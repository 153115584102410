/** @format */

import React from 'react'

import Images from '../../../../components/Commons/Images'

import Stock from './components/Stock'
//import Geolocation from './components/GeoLocation'
import Dispatch from './components/Dispatch'
import DescriptionTypeReceipt from './components/DescriptionTypeReceipt'

import './style.scss'

export default function ProductTitle(props) {
	return (
		<>
			<div className='ph-product-detail-main-title-global-container'>
				<div className='ph-product-detail-main-title-pharmacy-container'>
					<Images
						classContent={
							props.service
								? 'ph-product-detail-main-title-service-image-container'
								: 'ph-product-detail-main-title-pharmacy-image-container'
						}
						classImage={
							props.service
								? 'ph-product-detail-main-title-service-image'
								: 'ph-product-detail-main-title-pharmacy-image'
						}
						default={
							props.service
								? props.item.details[0].service_provider.image
								: props.item.details[0].pharmacy_image
						}
						alt={props.item.details[0].name}
						title={props.item.details[0].name}
					/>
					<h3 className='ph-product-detail-main-title-pharmacy-title'>
						{props.item.details[0].name_edited_pharmacy}
					</h3>
				</div>
				<h1 className='ph-product-detail-main-title'>{props.item.details[0].editable_name}</h1>
				{props.service ? (
					<>
						<h3 className='ph-product-detail-main-title-service-title'>
							{props.item.details[0].sale_condition}
						</h3>
						<h3 className='ph-product-detail-main-title-service-subtitle'>
							Revisa más detalle en este
							<a
								className='ph-product-detail-main-title-service-link'
								href={props.item.details[0].video_link}
								rel='noopener noreferrer'
								target='_blank'>
								Link
							</a>
						</h3>
					</>
				) : (
					<h3 className='ph-product-detail-main-title-laboratory-title'>
						Laboratorio {props.item.details[0].laboratory}
					</h3>
				)}
				{props.item.details[0].interface?.prescryption_type && (
					<DescriptionTypeReceipt
						title={props.item.details[0].interface.prescryption_type.title}
						description={props.item.details[0].interface.prescryption_type.type}
						image={props.item.details[0].interface.prescryption_type.image}
					/>
				)}
				{!props.service && <Dispatch item={props.item} />}
				{props.item.details[0].code_internal && (
					<div className='ph-product-detail-main-title-internal-code-container'>
						<h4 className='ph-product-detail-main-title-internal-code-title'>CÓDIGO:</h4>
						<h4 className='ph-product-detail-main-title-internal-code-description'>
							{props.item.details[0].code_internal}
						</h4>
					</div>
				)}
				{props.item.details[0].price && !props.service && <Stock detail={props.item.details[0]} />}
			</div>
		</>
	)
}
