/** @format */

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Spin } from "antd";

import { LoadingOutlined } from "@ant-design/icons";

import Images from "../../../../components/Commons/Images";

import userIcon from "../../../../components/Commons/Assets/Icons/Filled/EnabledOpacity/user.svg";
import favoriteIcon from "../../../../components/Commons/Assets/Icons/Filled/EnabledOpacity/favorite.svg";
import bagIcon from "../../../../components/Commons/Assets/Icons/Filled/EnabledOpacity/bag.svg";
import lockIcon from "../../../../components/Commons/Assets/Icons/Filled/EnabledOpacity/lock.svg";
import locationIcon from "../../../../components/Commons/Assets/Icons/Filled/EnabledOpacity/location.svg";
import recipeIcon from "../../../../components/Commons/Assets/Icons/Filled/EnabledOpacity/recipe.svg";

import "./style.scss";

export default function CardProfileInfo(props) {
  const { t } = useTranslation('global')
  
  const handleCardImage = (item) => {
    if (item === "pharol user") {
      return userIcon;
    } else if (item === "pharol bolsa") {
      return bagIcon;
    } else if (item === "pharol favorites") {
      return favoriteIcon;
    } else if (item === "pharol recipes") {
      return recipeIcon;
    } else if (item === "pharol lock") {
      return lockIcon;
    } else if (item === "pharol location") {
      return locationIcon;
    } else {
      return "";
    }
  };

  useEffect(() => {}, [props.infoCards]);

  return (
    <div className="ph-profile-info-card">
      <div className="ph-profile-info-title-container">
        <h3 className="ph-profile-info-card-title">{props.item.title}</h3>
        <Images
          classContent={"ph-profile-info-image-container"}
          classImage={"ph-profile-info-image"}
          default={handleCardImage(props.item.title_image)}
          title={props.item.titleImage}
        />
      </div>

      <div className="ph-profile-info-description-container">
        {props.infoCards[props.item.image] ? (
          <>
            {props.item.image === "pharol-usuario-login" && (
              <>
                <p className="ph-profile-info-card-description">
                  {props.infoCards[props.item.image].first_name}{" "}
                  {props.infoCards[props.item.image].last_name}
                </p>
                <p className="ph-profile-info-card-description">
                  {props.isEmail}
                </p>
              </>
            )}
            {props.item.image === "pharol-recipes" && (
              <>
                {Array.isArray(props.infoCards[props.item.image]) &&
                props.infoCards[props.item.image].length > 0 ? (
                  <>
                    {props.infoCards[props.item.image].map((item, index) => (
                      <p
                        className="ph-profile-info-card-description-address"
                        key={index}
                      >
                        {item.name} : {item.recipe_id}
                      </p>
                    ))}
                  </>
                ) : (
                  <p className="ph-profile-info-card-description">
                     {t('profile.recipes.description')}
                  </p>
                )}
              </>
            )}
            {props.item.image === "favorites2" && (
              <>
                {Array.isArray(props.infoCards[props.item.image]) &&
                props.infoCards[props.item.image].length > 0 ? (
                  <>
                    {props.infoCards[props.item.image].map((item, index) => (
                      <p
                        className="ph-profile-info-card-description-address"
                        key={index}
                      >
                        {item.editable_name}
                      </p>
                    ))}
                  </>
                ) : (
                  <p className="ph-profile-info-card-description">
                      {t('profile.favorites.description')}
                  </p>
                )}
              </>
            )}
            {props.item.image === "pharol-bolsa-compras" && (
              <>
                {Array.isArray(props.infoCards[props.item.image]) &&
                props.infoCards[props.item.image].length > 0 ? (
                  <>
                    {props.infoCards[props.item.image].map((item, index) => (
                      <p
                        className="ph-profile-info-card-description-address"
                        key={index}
                      >
                        {item.id_punto_pago}:{" "}
                        {moment(item.createdAt).format("DD/MM/YYYY, h:mm:ss a")}
                      </p>
                    ))}
                  </>
                ) : (
                  <p className="ph-profile-info-card-description">
                    {t('profile.purchase.description')}
                  </p>
                )}
              </>
            )}
            {props.item.image === "pharol-location" && (
              <>
                {Array.isArray(props.infoCards[props.item.image]) &&
                props.infoCards[props.item.image].length > 0 ? (
                  <>
                    {props.infoCards[props.item.image].map((item, index) => (
                      <p
                        className="ph-profile-info-card-description-address"
                        key={index}
                      >
                        {item.alias}: {item.location.formatted_address}
                      </p>
                    ))}
                  </>
                ) : (
                  <p className="ph-profile-info-card-description">
                    {t('profile.address.description')}
                  </p>
                )}
              </>
            )}

            {props.item.image === "pharol-lock" && (
              <p className="ph-profile-info-card-description">
                {t('profile.password.description')}
              </p>
            )}
          </>
        ) : (
          <div className="ph-profile-info-card-description-loading">
            <Spin
              className="ph-profile-info-card-description-loading-icon"
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 24,
                  }}
                  spin
                />
              }
            />
          </div>
        )}
      </div>
      <h4 className="ph-profile-info-card-link">Ver más</h4>
    </div>
  );
}
