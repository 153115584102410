/** @format */

import React from 'react'

import Images from '../Commons/Images'

import { amountProductsCart } from '../Commons/Hooks/Functions/AmountProductsCart'
import { ENV_IMG_HEADER } from '../Commons/Hooks/Variables/Enviroment'

import './style.scss'

export default function TitleBox(props) {
	return (
		<div className='ph-checkout-old-title-main-container'>
			<Images
				classContent={'ph-checkout-old-title-image-container'}
				classImage={'ph-checkout-old-title-image'}
				webp={`${ENV_IMG_HEADER}/${props.image}.webp`}
				jp2={`${ENV_IMG_HEADER}/${props.image}.jp2`}
				jxr={`${ENV_IMG_HEADER}/${props.image}.jxr`}
				default={`${ENV_IMG_HEADER}/${props.image}.png`}
				title={props.title}
			/>
			<h1 className='ph-checkout-old-title'>{props.title}</h1>
			<h3 className='ph-checkout-old-subtitle'>
				<>
					{props.subtitle1} {!props.checkout && amountProductsCart()} {props.subtitle2}
				</>
			</h3>
		</div>
	)
}
