/** @format */

import axios from 'axios';

import Notify from '../../components/Commons/Notify';

import { ENV_COUNTRY, ENV_COMMON } from '../../components/Commons/Hooks/Variables/Enviroment';

export default async function profileDataService() {
	let returnResponse;
	await axios({
		method: 'GET',
		url: `${ENV_COMMON}/profile-card/${ENV_COUNTRY}`,
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data;
			} else {
				Notify({
					title: `Error: ${response.data.error}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Problemas con el servicio:   ${response.data.message}`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			}
		})
		.catch((error) => {
			returnResponse = error;
			Notify({
				title: `Problemas de Conexión`,
				colorTitle: 'ph-main-notification-error-title',
				description: `Revisa tu conexión o ponte en contacto con nosotros.`,
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
}
