/** @format */

import axios from 'axios';

import Notify from '../../../Commons/Notify';

import { ENV_SCHEDULE, ENV_COUNTRY } from '../../../Commons/Hooks/Variables/Enviroment';

const DeliveryScheduleFunction = async (item) => {
	let returnResponse;
	await axios({
		method: 'POST',
		url: `${ENV_SCHEDULE}/delivery-schedule/${ENV_COUNTRY}`,
		data: item,
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data;
			} else {
				Notify({
					title: `Error: ${response.data.error}`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Problemas con la Function:  delivery - schedule`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
			}
		})
		.catch(() => {
			Notify({
				title: `Problemas de Conexión`,
				colorTitle: 'ph-main-notification-error-title',
				description: `Revisa tu conexión o ponte en contacto con nosotros.`,
				time: 3,
				image: 'error',
				placement: 'topRight',
			});
		});
	return returnResponse;
};

export default DeliveryScheduleFunction;
