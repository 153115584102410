/** @format */

import React, { useEffect } from 'react'
import { setGlobal } from 'reactn'

import { Select } from 'antd'

import './style.scss'

export default function Quantity(props) {
	useEffect(() => {
		setGlobal({ quantityProduct: 1 })
	}, [props.quantity])

	const handleChangeQuantity = (item) => {
		if (typeof props.addItem === 'function') props.addItem(1)
		setGlobal({ quantityProduct: item })
	}

	return (
		<Select
			className='ph-select-quantity'
			defaultValue={1}
			onChange={handleChangeQuantity}
			disabled={props.item.stock <= 0 && !props.item.on_demand}>
			{Array.from({ length: props.item.stock > 10 ? 10 : props.item.stock }, (_, i) => (
				<Select.Option value={i + 1} key={i + 1}>
					{i + 1} unidades
				</Select.Option>
			))}
		</Select>
	)
}
