/** @format */

import React, { Component } from 'react';

import { formatPrice } from '../../../../../../../../../../components/Commons/Hooks/Functions/FormatPrice';

import './style.scss';

export default class AmountInfo extends Component {
	render() {
		return (
			<>
				{this.props.description && (
					<div className="ph-order-detail-products-total-price-container">
						<h3 className={`ph-order-detail-products-total-price-title ${this.props.classTitle}`}>{this.props.title}</h3>

						<h3 className={`ph-order-detail-products-total-price-description ${this.props.classDescription}`}>{formatPrice(this.props.description)}</h3>
					</div>
				)}
			</>
		);
	}
}
