/** @format */

import React, { useState, useEffect } from 'react'

import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import cartBannersService from './services'

import SliderBanner from '../../../../components/Commons/SliderBanner'

import './style.scss'

export default function BagBanners() {
	const [isVisible, setVisible] = useState(true)
	const [isBanners, setBanners] = useState(null)

	useEffect(() => {
		cartBannersService().then((response) => {
			if (response) setBanners(response)
			else setVisible(false)
		})
	}, [])

	if (!isVisible) return ''

	if (!isBanners) {
		return (
			<div className='ph-bag-banner-loader-main-container'>
				<Spin indicator={<LoadingOutlined />} />
			</div>
		)
	}

	return (
		<div className='ph-bag-banner-main-container'>
			<SliderBanner bannersCart={true} listSliders={isBanners} />
		</div>
	)
}
