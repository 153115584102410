/** @format */

import React, { Component } from 'react'

import Images from '../../../../../../../../components/Commons/Images'

import './style.scss'

export default class TypeRecipeDescription extends Component {
	render() {
		return (
			<div className='ph-checkout-recipe-receipt-main-container'>
				<div className='ph-checkout-recipe-receipt-inner-container'>
					<h3 className='ph-checkout-recipe-receipt-title'>{this.props.title}</h3>
					<h4 className='ph-checkout-recipe-receipt-description-desktop'>
						{this.props.description}
					</h4>
					<h4 className='ph-checkout-recipe-receipt-description-mobile'>
						({this.props.description})
					</h4>
				</div>
				<Images
					classContent={'ph-checkout-recipe-receipt-image-container'}
					classImage={'ph-checkout-recipe-receipt-image'}
					default={this.props.image}
					title={this.props.title}
					alt={this.props.title}
				/>
			</div>
		)
	}
}
