/** @format */

import React, { Component } from 'react'

import { Col, Row } from 'antd'

import CardInfoProfile from './components/CardInfoProfile'
import CardHomeDelivery from './components/CardHomeDelivery'
import './style.scss'

export default class OrderDetailProfile extends Component {
	render() {
		return (
			<>
				<Row className='ph-card-profile-main-container'>
					<Col
						xs={24}
						sm={24}
						md={24}
						lg={this.props.columnsTypeDelivery}
						xl={this.props.columnsTypeDelivery}>
						<CardInfoProfile
							columnsProfileMain={this.props.columnsProfileMain}
							columnsProfileInfo={this.props.columnsProfileInfo}
							list={this.props.list.order[0].info}
						/>
					</Col>
					{this.props.list.type_delivery === 'domicilio' && (
						<Col xs={24} sm={24} md={24} lg={8} xl={8}>
							<CardHomeDelivery list={this.props.list.order[0].info} />
						</Col>
					)}
				</Row>
			</>
		)
	}
}
