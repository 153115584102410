/** @format */

import React from 'react';

import { Link } from 'react-router-dom';

import Images from '../../../../../../components/Commons/Images';

import { ENV_IMG } from '../../../../../../components/Commons/Hooks/Variables/Enviroment';

import './style.scss';
import CustomButton from '../../../../../../v2-components/Antd/Button';

export default function ProductNotFound(props) {
	const hableAlternativeProduct = (item) => {
		let result = item.split('-');
		window.location.href = `/results/${result[0]}`;
	};

	return (
		<div className="ph-not-found-products-main-container">
			<div className="ph-not-found-products-container">
				<div className="ph-not-found-products-inner-container">
					<Images
						classContent={'ph-not-found-products-image-container'}
						classImage={'ph-not-found-products-image'}
						default={`${ENV_IMG}/bag/bolsa-triste.png`}
						title={'bolsa vacia'}
					/>
					<h3 className="ph-not-found-products-title">¡Lo sentimos!</h3>
					<h3 className="ph-not-found-products-subtitle">
						Actualmente tenemos dificultades para encontrar este producto, puedes usar una lista de alternativas al pulsar el botón "Alternativas", o seguir
						navegando por nuestros servicios al darle click "Seguir Comprando"
					</h3>
					<CustomButton className={'button-primary'} onClick={() => hableAlternativeProduct(props.slug)}>
						Alternativas
					</CustomButton>
					<Link to="/">
						<h3 className="ph-not-found-products-link">Seguir Comprando</h3>
					</Link>
				</div>
			</div>
		</div>
	);
}
