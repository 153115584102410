/** @format */

import React, { useState } from 'react'

import { Modal, Button } from 'antd'

import { CheckCircleFilled } from '@ant-design/icons'

import AwesomeSwiper from 'react-awesome-swiper'

import Images from '../../../../../../../../../../components/Commons/Images'

import './style.scss'

const BagCarouselPBM = (props) => {
	const [isVisible, setVisible] = useState(false)
	const config = {
		swiperRef: null,
		spaceBetween: 0,
		loop: false,
		autoplay: false,
		preloadImages: false,
		lazy: true,
		speed: 500,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		breakpoints: {
			1024: {
				slidesPerView: props.carouselItems[0],
				spaceBetween: 0,
			},
			768: {
				slidesPerView: props.carouselItems[1],
				spaceBetween: 10,
			},
			640: {
				slidesPerView: props.carouselItems[2],
				spaceBetween: 0,
			},
			450: {
				slidesPerView: props.carouselItems[3],
				spaceBetween: 0,
			},
			250: {
				slidesPerView: props.carouselItems[4],
				spaceBetween: 0,
			},
		},
	}

	const handleDeletePBMCovenant = (item) => {
		props.handlePBMDelete(item)
		setVisible(false)
	}

	return (
		<>
			<AwesomeSwiper ref={(ref) => (config.swiperRef = ref)} config={config}>
				<div className='swiper-wrapper'>
					{props.item.interface_pbm.map((item, index) => (
						<div className='swiper-slide ph-bag-pbm-carousel-slider-main-container' key={index}>
							{props.item.pbm_data && <div className='ph-bag-pbm-carousel-slider-aproved-container' onClick={() => setVisible(true)}></div>}
							<div
								className={`${
									props.item.pbm_data ? (props.item.pbm_data.pbm_source_input.id_agreement === item.id_agreement ? 'ph-bag-pbm-carousel-slider-agrement-active-container' : '') : ''
								} ph-bag-pbm-carousel-slider-container`}>
								<div
									className='ph-bag-pbm-carousel-slider-inner-container'
									onClick={() => props.handlePBMAgrement(item.id_agreement, item.agreement_name, item.discount, item.image_agreement, item.link_agreement)}>
									<Images
										classContent={'ph-bag-pbm-carousel-slider-image-container'}
										classImage={'ph-bag-pbm-carousel-slider-image'}
										default={item.image_agreement}
										title={item.agreement_name}
									/>
									<div className='ph-bag-pbm-carousel-slider-description-container'>
										<div className='ph-bag-pbm-carousel-slider-description-inner-container'>
											<h3 className='ph-bag-pbm-carousel-slider-description-title'>{item.agreement_name}</h3>
											<h4 className='ph-bag-pbm-carousel-slider-description-subtitle'>{item.description_agreement ? item.description_agreement : 'no data'}</h4>
											<h4 className='ph-bag-pbm-carousel-slider-description-discount' style={{ color: item.color_agreement }}>
												-{item.discount}
											</h4>
										</div>
										<div className='ph-bag-pbm-carousel-slider-description-check-container'>
											{props.item.pbm_data ? (
												props.item.pbm_data.pbm_source_input.id_agreement === item.id_agreement ? (
													<CheckCircleFilled className='ph-bag-pbm-carousel-slider-image-check' />
												) : (
													<div className='ph-bag-pbm-carousel-slider-description-check'></div>
												)
											) : (
												<div className='ph-bag-pbm-carousel-slider-description-check'></div>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
				<div className='swiper-button-prev ph-carousel-prev ph-bag-pbm-carousel-arrow-prev'></div>
				<div className='swiper-button-next ph-carousel-next ph-bag-pbm-carousel-arrow-next'></div>
			</AwesomeSwiper>
			<Modal
				wrapClassName='ph-bag-pbm-list-modal-container'
				maskClosable={true}
				closable={true}
				centered
				width={450}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
				title='Advertencia:'
				visible={isVisible}
				onCancel={() => setVisible(false)}>
				<div className='ph-bag-pbm-carousel-slider-description-modal-container'>
					<h3 className='ph-bag-pbm-carousel-slider-description-modal-main-title'>Para adjudicar otro convenio, debe cancelar el que ya esta actualmente seleccionado</h3>
					{props.item.pbm_data && (
						<div className='ph-bag-pbm-carousel-slider-description-modal-inner-container'>
							<h4 className='ph-bag-pbm-carousel-slider-description-modal-inner-title'>Seleccion actual:</h4>
							<h4 className='ph-bag-pbm-carousel-slider-description-modal-inner-subtitle'>
								{props.item.pbm_data.pbm_source_input.name}({props.item.pbm_data.pbm_source_input.id_agreement})
							</h4>
							<h4 className='ph-bag-pbm-carousel-slider-description-modal-inner-discount'>-{props.item.pbm_data.pbm_source_input.discount}</h4>
						</div>
					)}
					<h3 className='ph-bag-pbm-carousel-slider-description-modal-inner-actions'>Pulse aceptar para anular el convenio o cancelar para mantener su selección</h3>
					<Button className='ph-bag-pbm-carousel-slider-description-modal-cancel-button' onClick={() => setVisible(false)}>
						Cancelar
					</Button>
					<Button className='ph-bag-pbm-carousel-slider-description-modal-aceptar-button' onClick={() => handleDeletePBMCovenant(props.item)}>
						Aceptar
					</Button>
				</div>
			</Modal>
		</>
	)
}
export default BagCarouselPBM
