/** @format */

import axios from 'axios'

import Notify from '../../../../components/Commons/Notify'

import { ENV_COMMON, ENV_COUNTRY } from '../../../../components/Commons/Hooks/Variables/Enviroment'
import { ScreenWidth } from '../../../../components/Commons/Hooks/Functions/ScreenWidth'

export async function ServiceDetail(item, data) {
	let returnResponse
	await axios({
		method: 'GET',
		url: `${ENV_COMMON}/services/${ENV_COUNTRY}/${item}/${data}?mobile=${ScreenWidth()}`,
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				returnResponse = response.data.data
			} else {
				Notify({
					title: `Advertencia:`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Ha ocurrido un error`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				})
			}
		})
		.catch(() => {
			Notify({
				title: `Error Servicio`,
				colorTitle: 'ph-main-notification-error-title',
				description: `Verifique su conexión a Internet.`,
				time: 3,
				image: 'error',
				placement: 'topRight',
			})
		})
	return returnResponse
}
