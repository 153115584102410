/** @format */

import React, { useState, useEffect } from 'react'

import { Row, Col, Button, Modal } from 'antd'

import Images from '../../../../../../../../components/Commons/Images'

import MapRoutes from '../../../ModalLocalSelected/components/MapRoutes'
import TimeLocal from '../../../ModalLocalSelected/components/TimeLocal'
import RoadRoutes from '../../../ModalLocalSelected/components/RoadRoutes'

import { ENV_IMG } from '../../../../../../../../components/Commons/Hooks/Variables/Enviroment'

import './style.scss'

export default function LocalButton(props) {
	const [isVisible, setVisible] = useState(false)
	const [isLocalSelected, setLocalSelected] = useState(null)
	const [isUserLocation] = useState(JSON.parse(localStorage.getItem('locationStore')))

	useEffect(()=> {
		if(props.pharmacy.locals.length === 1){
			setLocalSelected(props.pharmacy.locals[0])
			 props.selectLocal(props.pharmacy.locals[0])
			}
		},[props])

	return (
		<>
			<div className='ph-withdrawal-pharmacy-list-main-container'>
				<Images
					classContent={'ph-withdrawal-pharmacy-image-container'}
					classImage={'ph-withdrawal-pharmacy-image'}
					default={props.pharmacy.image}
					alt={props.pharmacy.name_editable}
					title={props.pharmacy.name_editable}
				/>
				<div>
					<h3 className='ph-withdrawal-pharmacy-list-title'>{props.pharmacy.name_editable}</h3>
					<h3 className='ph-withdrawal-pharmacy-list-description'>
						Elija la sucursal de retiro...
					</h3>
				</div>
			</div>
			{props.pharmacy.locals.map((item, index) => (
				<div className='ph-withdrawal-pharmacy-list-button-container' key={index}>
					<Row className='ph-withdrawal-pharmacy-list-button-info-container'>
						<Col span={24}>
							<Row>
								<Col xs={16} sm={17} md={13} lg={13} xl={13}>
									<div className='ph-withdrawal-pharmacy-list-component-button-info-destiny-container'>
										<Images
											classContent={
												'ph-withdrawal-pharmacy-list-component-button-info-destiny-image-container'
											}
											classImage={'ph-withdrawal-pharmacy-list-component-button-info-destiny-image'}
											webp={`${ENV_IMG}/react-header/pharol-location.webp`}
											jp2={`${ENV_IMG}/react-header/pharol-location.jxr`}
											jxr={`${ENV_IMG}/react-header/pharol-location.jp2`}
											default={`${ENV_IMG}/react-header/pharol-location.png`}
										/>
										<div>
											<h4 className='ph-withdrawal-pharmacy-list-component-button-info-destiny-location-icon-title'>
												{item.direction}, {item.region}
											</h4>
											<div className='ph-withdrawal-pharmacy-list-component-button-subtitle-2'>
												Distancia:{' '}
												<span
													className='ph-withdrawal-pharmacy-list-component-button-subtitle-info-2'
													style={{
														color: `${item.color_distance}`,
													}}>
													{' '}
													{item.distance_text}
												</span>
											</div>
										</div>
									</div>
								</Col>
								{!props.mobile && (
									<Col
										xs={5}
										sm={5}
										md={5}
										lg={5}
										xl={5}
										className='ph-withdrawal-pharmacy-list-button-info-destiny-location-icon-container'>
										<div className='ph-withdrawal-pharmacy-list-button-info-destiny-location-icon-inner-container'>
											<h4 className='ph-withdrawal-pharmacy-list-button-info-destiny-location-icon-inner-title'>
												Local:
											</h4>
											<h4 className='ph-withdrawal-pharmacy-list-button-info-destiny-location-icon-inner-subtitle'>
												{item.name_local}({item.local})
											</h4>
										</div>
									</Col>
								)}
								<Col xs={1} sm={1} md={1} lg={1} xl={1}>
									<div className='ph-withdrawal-pharmacy-list-button-info-destiny-location-separator' />
								</Col>
								<Col
									xs={7}
									sm={6}
									md={5}
									lg={5}
									xl={5}
									className='ph-withdrawal-list-local-button-container'>
									<h4 className='ph-withdrawal-pharmacy-list-button-info-destiny-location-icon-inner-subtitle-mobile'>
										Local:{item.name_local}({item.local})
									</h4>
									<div
										onClick={() => {
											setVisible(true)
											setLocalSelected(item)
										}}>
										<h4 className='ph-withdrawal-list-local-button-title'>
											Seleccionar
											<span className='ph-withdrawal-list-local-button-icon'></span>
										</h4>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			))}
			<Modal
				wrapClassName='ph-local-select-modal-container'
				centered
				width={700}
				maskClosable={false}
				title={`Información del local: ${isLocalSelected && isLocalSelected.name_local}(${
					isLocalSelected && isLocalSelected.pharmacy
				})`}
				visible={isVisible}
				cancelText='Cancelar'
				destroyOnClose={true}
				onCancel={() => {
					setLocalSelected(null)
					setVisible(false)
				}}
				cancelButtonProps={{ style: { display: 'none' } }}>
				{isLocalSelected && (
					<div className='ph-local-select-modal-content-container'>
						<MapRoutes
							pharmacyLocation={{
								lat: isLocalSelected.location.lat,
								lng: isLocalSelected.location.lng,
							}}
							userLocation={isUserLocation}
							center={[isLocalSelected.location.lat, isLocalSelected.location.lng]}
							zoom={12}
							title={'User waypoints'}
							description={'Route.'}
						/>
						<RoadRoutes
							mobile={props.mobile}
							userLocation={isUserLocation}
							item={isLocalSelected}
						/>
						<TimeLocal item={isLocalSelected} />
						<div className='ph-local-select-modal-main-button-container'>
							<Button
								className='ph-local-select-modal-main-button'
								onClick={() => {
									props.selectLocal(isLocalSelected)
									setVisible(false)
								}}>
								Confirmar
							</Button>
						</div>
					</div>
				)}
			</Modal>
		</>
	)
}
