/** @format */

import React from 'react';
import { ENV_COUNTRY } from '../Hooks/Variables/Enviroment';

export default function CountryValidator({ component, countries, condition = false, toFilterCountry = countries }) {
	const handleValidatorCountry = (component, countries) => {
		if (countries.includes(ENV_COUNTRY)) {
			if (condition && toFilterCountry.includes(ENV_COUNTRY)) {
				return <></>;
			} else {
				return <>{component}</>;
			}
		} else {
			return <></>;
		}
	};

	return handleValidatorCountry(component, countries);
}
