/** @format */

import React, { Component } from 'react'

import Images from '../../../Images'

import './style.scss'
import { ENV_COUNTRY } from '../../../Hooks/Variables/Enviroment'

export default class ProductPromotions extends Component {
	render() {
		return (
			<>
			{this.props.detail.pharmacy ? <>
					{this.props.detail.interface && (
						<>
							{this.props.detail.interface.promotions && (
								<div
									className={`${
										this.props.cartProducts
											? 'ph-products-cart-promotion-padding'
											: ''
									} ph-product-promotion-container`}>
									{this.props.detail.interface.promotions.img_promotion && (
										<>
											{(this.props.detail.interface.promotions.img_promotion
												.webp ||
												this.props.detail.interface.promotions.img_promotion
													.jp2 ||
												this.props.detail.interface.promotions.img_promotion
													.jxr ||
												this.props.detail.interface.promotions.img_promotion
													.default) && (
												<Images
													classContent={`${
														this.props.alternatives
															? 'ph-product-promotion-icon-container-alternatives'
															: ''
													} ph-product-promotion-icon-container`}
													classImage={'ph-product-promotion-icon'}
													webp={
														this.props.detail.interface.promotions.img_promotion
															.webp
													}
													jp2={
														this.props.detail.interface.promotions.img_promotion
															.jp2
													}
													jxr={
														this.props.detail.interface.promotions.img_promotion
															.jxr
													}
													default={
														this.props.detail.interface.promotions.img_promotion
															.default
													}
													title={'Promotion icon'}
												/>
											)}
										</>
									)}
									<p
										className={`${
											this.props.classList
												? 'ph-product-list-promotion-title'
												: ''
										} ${
											this.props.alternatives
												? 'ph-product-list-promotion-title-alternatives'
												: ''
										} ph-product-promotion-title`}>
										{this.props.detail.interface.promotions.title}
									</p>
								</div>
							)}
						</>
					)}
				</>
				:<>
					{this.props.detail.promotions && (
						<>
							<div
									className={`${
										this.props.cartProducts
											? 'ph-products-cart-promotion-padding'
											: ''
									} ph-product-promotion-container`}>
									{this.props.detail.promotions.image && (
										<>
											{(this.props.detail.interface.promotions.img_promotion
												.webp ||
												this.props.detail.interface.promotions.img_promotion
													.jp2 ||
												this.props.detail.interface.promotions.img_promotion
													.jxr ||
												this.props.detail.interface.promotions.img_promotion
													.default) && (
												<Images
													classContent={`${
														this.props.alternatives
															? 'ph-product-promotion-icon-container-alternatives'
															: ''
													} ph-product-promotion-icon-container`}
													classImage={'ph-product-promotion-icon'}
													webp={
														this.props.detail.interface.promotions.img_promotion
															.webp
													}
													jp2={
														this.props.detail.interface.promotions.img_promotion
															.jp2
													}
													jxr={
														this.props.detail.interface.promotions.img_promotion
															.jxr
													}
													default={
														this.props.detail.interface.promotions.img_promotion
															.default
													}
													title={'Promotion icon'}
												/>
											)}
										</>
									)}
									<p
										className={`${
											this.props.classList
												? 'ph-product-list-promotion-title'
												: ''
										} ${
											this.props.alternatives
												? 'ph-product-list-promotion-title-alternatives'
												: ''
										} ph-product-promotion-title`}>
										{(this.props.detail.promotions.rules.tag === "X % Descuento" && ENV_COUNTRY === 'AR')
												? (this.props.detail.interface.promotions?.percentaje_promotion || this.props.detail.interface.promotions?.title)+ " OFF"
												: this.props.detail.promotions.rules.tag
											
										}
									</p>
								</div>
						</>
					)}
				</>

		}
			</>
		)
	}
}
