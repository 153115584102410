export const FormatNumber = {
	CL: {
		handleFormat: (number) => {
			const format = new Intl.NumberFormat('es-CL', {
				style: 'currency',
				currency: 'CLP',
			}).format(number);

			return format;
		},
	},
	AR: {
		handleFormat: (number) => {
			const format = new Intl.NumberFormat('es-AR', {
				style: 'currency',
				currency: 'ARS',
			}).format(number);

			return format;
		},
	},
};
