/** @format */

import React from 'react';

import { BagConsumer } from '../../../../../../components/Commons/Context/Bag';

import Spacer from '../../../../../../components/Commons/Spacer';

import { formatPrice } from '../../../../../../components/Commons/Hooks/Functions/FormatPrice';

import './style.scss';

export default function BagPriceInfo(props) {
	const { isBagTotalCart } = BagConsumer();

	const handleTitle = () => {
		if (props.type === 'primary') return 'ph-bag-price-title-primary';
		else if (props.type === 'second') return 'ph-bag-price-title-second';
		else if (props.type === 'third') return 'ph-bag-price-title-third';
		else return 'ph-bag-price-title-color';
	};

	const handlePriceColor = () => {
		if (props.type === 'primary') return 'ph-bag-price-primary';
		else if (props.type === 'second') return 'ph-bag-price-second';
		else if (props.type === 'third') return 'ph-bag-price-third';
		else return 'ph-bag-price-title-color';
	};

	return (
		<div className="ph-bag-price-info-container">
			<h3 className={`${handleTitle()} ph-bag-price-info-total-price`}>
				{props.title}
				<Spacer />
				<span className={`${handlePriceColor()} ph-bag-price-info-total-price-info`}>{formatPrice(isBagTotalCart)}</span>
			</h3>
		</div>
	);
}
