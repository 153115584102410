/** @format */

import React from 'react';
import { Collapse } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { getFacetFields } from '../Config';
import { Facet } from '@elastic/react-search-ui';
import { facetsInfo } from '../Config/data';

import './style.scss';

export default function FacetsInfo(props) {
	const { Panel } = Collapse;
	console.log('FacetsInfo', props.params.facets);
	const handleFacetsFilter = () => {
		const facetsFields = getFacetFields(props.params);
		if (props.category && props.params.facets.findIndex((item) => item === 'category') !== -1) {
			facetsFields.splice(props.category, 1);
		}
		return facetsFields;
	};

	return (
		<div className="ph-elastic-filter-facet-panel-container">
			<div className="ph-elastic-filter-facet-panel-inner-container">
				<Collapse expandIconPosition={'start'}>
					{handleFacetsFilter().map((field, index) => (
						<Panel header={`${facetsInfo[field].title}`} key={index}>
							<div className="ph-elastic-filter-facet-element">
								<SearchOutlined className="ph-elastic-filter-facet-element-icon" />
								<Facet key={field} field={field} label={`${facetsInfo[field].title}`} filterType="any" isFilterable={true} show={100} />
							</div>
						</Panel>
					))}
				</Collapse>
			</div>
		</div>
	);
}
